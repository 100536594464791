import { ArticleLayout } from "@/components/help/ArticleLayout";

export function TeamManagementArticle() {
  return (
    <ArticleLayout>
      <h1 className="text-2xl font-bold mb-4 mt-0">Team Management</h1>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Overview</h2>
      <p className="mb-4">
        The Team Management section of LeadGPT allows you to organize your team members, assign roles and permissions, 
        manage lead assignments, and track team performance. This guide will help you efficiently manage your sales 
        or support team to maximize productivity and results.
      </p>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Accessing Team Management</h2>
      <p className="mb-4">
        You can access the Team Management section by clicking on the "Team" option in the sidebar navigation menu. 
        Note that you need administrative privileges to access and make changes to team settings.
      </p>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Team Structure</h2>
      <p className="mb-4">
        LeadGPT supports a flexible team structure that can adapt to your organization's needs:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Team Members:</strong> Individual users who can be assigned roles and responsibilities</li>
        <li className="mb-1"><strong>Teams:</strong> Groups of members who work together (e.g., Sales Team, Support Team)</li>
        <li className="mb-1"><strong>Roles:</strong> Defined sets of permissions and capabilities (e.g., Admin, Manager, Agent)</li>
        <li className="mb-1"><strong>Hierarchies:</strong> Optional reporting structures defining who reports to whom</li>
      </ul>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Managing Team Members</h2>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Adding Team Members</h3>
      <p className="mb-4">
        To add a new team member:
      </p>
      <ol className="list-decimal pl-6 mb-4">
        <li className="mb-1">Navigate to the Team Management section</li>
        <li className="mb-1">Click the "Add Team Member" button in the top-right corner</li>
        <li className="mb-1">Enter the team member's email address</li>
        <li className="mb-1">Select the appropriate role for the new member</li>
        <li className="mb-1">Assign them to a team (if applicable)</li>
        <li className="mb-1">Click "Send Invitation"</li>
      </ol>
      <p className="mb-4">
        The new team member will receive an email invitation with instructions on how to create their account. 
        They will need to set up their password and complete their profile before accessing the system.
      </p>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Editing Team Member Details</h3>
      <p className="mb-4">
        To edit an existing team member's details:
      </p>
      <ol className="list-decimal pl-6 mb-4">
        <li className="mb-1">Click on the team member's name in the team list</li>
        <li className="mb-1">Click the "Edit" button on their profile</li>
        <li className="mb-1">Update their information (name, contact details, role, team, etc.)</li>
        <li className="mb-1">Click "Save Changes"</li>
      </ol>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Deactivating/Removing Team Members</h3>
      <p className="mb-4">
        To deactivate a team member (temporarily):
      </p>
      <ol className="list-decimal pl-6 mb-4">
        <li className="mb-1">Find the team member in the team list</li>
        <li className="mb-1">Click on the three-dot menu next to their name</li>
        <li className="mb-1">Select "Deactivate Account"</li>
        <li className="mb-1">Confirm the action when prompted</li>
      </ol>
      <p className="mb-4">
        To permanently remove a team member:
      </p>
      <ol className="list-decimal pl-6 mb-4">
        <li className="mb-1">Find the team member in the team list</li>
        <li className="mb-1">Click on the three-dot menu next to their name</li>
        <li className="mb-1">Select "Remove from Company"</li>
        <li className="mb-1">Confirm the action when prompted</li>
      </ol>
      <p className="mb-4">
        <strong>Note:</strong> When a team member is removed, their assigned leads will need to be reassigned to other team members. 
        You'll be prompted to select who should take over their leads during the removal process.
      </p>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Roles and Permissions</h2>
      <p className="mb-4">
        LeadGPT provides several predefined roles with different permission levels:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1">
          <strong>Administrator:</strong> Full access to all features, including system configuration, 
          billing, team management, and all lead data
        </li>
        <li className="mb-1">
          <strong>Manager:</strong> Can manage team members, view team performance, 
          assign leads, and access all lead data
        </li>
        <li className="mb-1">
          <strong>Agent:</strong> Can work with their assigned leads and access basic 
          reporting for their own performance
        </li>
        <li className="mb-1">
          <strong>Viewer:</strong> Read-only access to lead data and reports (useful for 
          executives or consultants who need visibility but shouldn't make changes)
        </li>
      </ul>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Customizing Roles</h3>
      <p className="mb-4">
        You can create custom roles with specific permission sets:
      </p>
      <ol className="list-decimal pl-6 mb-4">
        <li className="mb-1">Go to the "Roles & Permissions" tab in Team Management</li>
        <li className="mb-1">Click "Create New Role"</li>
        <li className="mb-1">Name the role (e.g., "Senior Agent")</li>
        <li className="mb-1">Select the permissions this role should have</li>
        <li className="mb-1">Click "Save Role"</li>
      </ol>
      <p className="mb-4">
        Permission categories include:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Lead Management:</strong> View, create, edit, delete leads</li>
        <li className="mb-1"><strong>Communication:</strong> Make calls, send messages, use AI features</li>
        <li className="mb-1"><strong>Team Management:</strong> Invite users, edit roles, manage teams</li>
        <li className="mb-1"><strong>Reporting:</strong> View personal reports, team reports, company reports</li>
        <li className="mb-1"><strong>Settings:</strong> Modify system settings, billing, integrations</li>
        <li className="mb-1"><strong>Data Export:</strong> Export lead data, reports, communications</li>
      </ul>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Creating and Managing Teams</h2>
      <p className="mb-4">
        If your organization has multiple departments or specialized groups, you can create separate teams:
      </p>
      <ol className="list-decimal pl-6 mb-4">
        <li className="mb-1">Go to the "Teams" tab in Team Management</li>
        <li className="mb-1">Click "Create New Team"</li>
        <li className="mb-1">Enter a team name and optional description</li>
        <li className="mb-1">Select a team leader</li>
        <li className="mb-1">Add team members from your existing user list</li>
        <li className="mb-1">Click "Create Team"</li>
      </ol>
      <p className="mb-4">
        Teams can be used to:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1">Group users logically in the interface</li>
        <li className="mb-1">Restrict certain leads to specific teams</li>
        <li className="mb-1">Generate team-specific performance reports</li>
        <li className="mb-1">Set up automated lead routing to specific teams</li>
        <li className="mb-1">Create team-specific sales goals and KPIs</li>
      </ul>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Lead Assignment and Workload Management</h2>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Manual Lead Assignment</h3>
      <p className="mb-4">
        To manually assign leads to team members:
      </p>
      <ol className="list-decimal pl-6 mb-4">
        <li className="mb-1">Navigate to the Leads section</li>
        <li className="mb-1">Select one or more leads by checking the boxes next to them</li>
        <li className="mb-1">Click the "Assign" button in the action bar</li>
        <li className="mb-1">Select the team member to assign the leads to</li>
        <li className="mb-1">Click "Assign"</li>
      </ol>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Automated Lead Assignment</h3>
      <p className="mb-4">
        LeadGPT can automatically distribute new leads among your team members based on various criteria:
      </p>
      <ol className="list-decimal pl-6 mb-4">
        <li className="mb-1">Go to Settings {'>'} Lead Management {'>'} Lead Assignment</li>
        <li className="mb-1">Select "Enable Automated Assignment"</li>
        <li className="mb-1">Choose an assignment method:
          <ul className="list-disc pl-6 mb-2 mt-2">
            <li className="mb-1"><strong>Round Robin:</strong> Distributes leads evenly among team members</li>
            <li className="mb-1"><strong>Capacity-Based:</strong> Assigns based on each agent's current workload</li>
            <li className="mb-1"><strong>Performance-Based:</strong> Gives more leads to high-performing team members</li>
            <li className="mb-1"><strong>Rule-Based:</strong> Assigns leads based on customizable rules (lead source, location, etc.)</li>
          </ul>
        </li>
        <li className="mb-1">Configure the specific settings for your chosen method</li>
        <li className="mb-1">Click "Save Changes"</li>
      </ol>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Workload Balancing</h3>
      <p className="mb-4">
        To rebalance workloads across your team:
      </p>
      <ol className="list-decimal pl-6 mb-4">
        <li className="mb-1">Go to the "Workload" tab in Team Management</li>
        <li className="mb-1">Review the current lead distribution and active lead counts</li>
        <li className="mb-1">Identify team members who are overloaded or underutilized</li>
        <li className="mb-1">Use the "Rebalance" feature to automatically redistribute leads</li>
        <li className="mb-1">Alternatively, manually reassign leads as needed</li>
      </ol>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Team Performance Monitoring</h2>
      <p className="mb-4">
        The Team Performance section provides detailed metrics about how your team is performing:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Individual Metrics:</strong> Performance statistics for each team member</li>
        <li className="mb-1"><strong>Comparative Analysis:</strong> How team members compare to each other and team averages</li>
        <li className="mb-1"><strong>Team Metrics:</strong> Aggregated performance data for each team</li>
        <li className="mb-1"><strong>Historical Trends:</strong> Performance changes over time</li>
        <li className="mb-1"><strong>Goal Tracking:</strong> Progress toward individual and team sales goals</li>
      </ul>
      <p className="mb-4">
        Key performance indicators include:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Lead Response Time:</strong> How quickly team members respond to new leads</li>
        <li className="mb-1"><strong>Conversion Rate:</strong> Percentage of leads converted to customers</li>
        <li className="mb-1"><strong>Average Deal Size:</strong> The average value of converted deals</li>
        <li className="mb-1"><strong>Activity Metrics:</strong> Calls made, messages sent, meetings scheduled</li>
        <li className="mb-1"><strong>Lead Progression:</strong> How efficiently leads move through the sales pipeline</li>
      </ul>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Best Practices for Team Management</h2>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Clear Roles and Expectations:</strong> Ensure each team member understands their role and responsibilities</li>
        <li className="mb-1"><strong>Regular Performance Reviews:</strong> Schedule weekly or monthly reviews to discuss performance metrics</li>
        <li className="mb-1"><strong>Balanced Workloads:</strong> Monitor workloads to ensure no team member is overwhelmed or underutilized</li>
        <li className="mb-1"><strong>Training and Support:</strong> Provide ongoing training on LeadGPT features and sales techniques</li>
        <li className="mb-1"><strong>Lead Rotation:</strong> Periodically rotate lead assignments to prevent burnout</li>
        <li className="mb-1"><strong>Appropriate Permissions:</strong> Only grant permissions that team members need for their roles</li>
        <li className="mb-1"><strong>Team Collaboration:</strong> Encourage knowledge sharing and collaboration among team members</li>
      </ul>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Troubleshooting</h2>
      <p className="mb-4">
        Common team management issues and their solutions:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1">
          <strong>Invitation emails not received:</strong> Check spam folders, verify email addresses, 
          or resend invitations
        </li>
        <li className="mb-1">
          <strong>Permission issues:</strong> Review role assignments and custom permissions, 
          ensure the user has logged out and back in after permission changes
        </li>
        <li className="mb-1">
          <strong>Lead assignment problems:</strong> Check automated assignment settings, 
          verify team member availability status, and ensure leads meet assignment criteria
        </li>
        <li className="mb-1">
          <strong>Reporting discrepancies:</strong> Verify date ranges, confirm metric definitions, 
          and check for any filters that might be affecting the data
        </li>
      </ul>
      
      <p className="mb-4 mt-8">
        Effective team management is crucial for maximizing the value of your LeadGPT platform. 
        By properly configuring roles, teams, and assignments, you can create an efficient system 
        that helps your sales team convert more leads while providing visibility into performance.
      </p>
    </ArticleLayout>
  );
} 