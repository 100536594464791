import { AnimatePresence, motion } from 'framer-motion';
import { createPortal } from 'react-dom';
import { OnboardingStep as StepEnum, useOnboarding } from './OnboardingContext';
import { OnboardingProgress } from './OnboardingProgress';
import { OnboardingStep } from './OnboardingStep';

export function OnboardingOverlay() {
  const {
    isOnboarding,
    currentStep,
    nextStep,
    prevStep,
    skipOnboarding,
  } = useOnboarding();

  // If not in onboarding mode, don't render anything
  if (!isOnboarding) return null;

  // Define step content
  const steps = [
    {
      step: StepEnum.WELCOME,
      title: 'Welcome to the Metrics Dashboard',
      description: (
        <div>
          <p>This dashboard helps you track your performance metrics and team statistics.</p>
          <p className="mt-2">Let's take a quick tour to help you get the most out of it!</p>
        </div>
      ),
      targetSelector: '.metrics-dashboard-header',
      position: 'bottom' as const,
    },
    {
      step: StepEnum.DATE_RANGE,
      title: 'Select Date Range',
      description: (
        <div>
          <p>Click here to select a custom date range or choose from preset options like "Last 7 days" or "This month".</p>
          <p className="mt-2">This helps you analyze data for specific time periods.</p>
        </div>
      ),
      targetSelector: '.date-range-selector',
      position: 'bottom' as const,
    },
    {
      step: StepEnum.TIMEFRAME,
      title: 'Choose Timeframe',
      description: (
        <div>
          <p>Select how you want your data grouped: daily, weekly, or monthly.</p>
          <p className="mt-2">This changes how the charts display your metrics over time.</p>
        </div>
      ),
      targetSelector: '.timeframe-selector',
      position: 'bottom' as const,
    },
    {
      step: StepEnum.METRICS_FILTER,
      title: 'Filter Metrics',
      description: (
        <div>
          <p>Click here to customize which metrics are displayed in your dashboard.</p>
          <p className="mt-2">You can show or hide specific metrics based on what's most important to you.</p>
        </div>
      ),
      targetSelector: '.metrics-filter',
      position: 'left' as const,
    },
    {
      step: StepEnum.CHART_TABS,
      title: 'Chart Categories',
      description: (
        <div>
          <p>Switch between different chart categories to focus on specific aspects of your performance:</p>
          <ul className="list-disc pl-5 mt-1">
            <li>Leads - Track lead generation and contact rates</li>
            <li>Conversion - Monitor your conversion metrics</li>
            <li>Communication - View messaging and call statistics</li>
            <li>Response Time - Analyze your response efficiency</li>
          </ul>
        </div>
      ),
      targetSelector: '.chart-tabs',
      position: 'top' as const,
    },
    {
      step: StepEnum.TEAM_PERSONAL,
      title: 'Team vs Personal View',
      description: (
        <div>
          <p>Toggle between your personal metrics and your team's overall performance.</p>
          <p className="mt-2">This helps you compare your individual results with the team average. Select "Team" to continue.</p>
        </div>
      ),
      targetSelector: '.team-personal-tabs',
      position: 'bottom' as const,
    },
    {
      step: StepEnum.LEADERBOARD,
      title: 'Team Leaderboard',
      description: (
        <div>
          <p>The leaderboard shows how team members rank based on different metrics.</p>
          <p className="mt-2">You can change the ranking metric using the dropdown menu to see who's performing best in different areas.</p>
        </div>
      ),
      targetSelector: '.leaderboard-section',
      position: 'top' as const,
    },
    {
      step: StepEnum.COMPLETION,
      title: "You're All Set!",
      description: (
        <div>
          <p>You now know how to use the Metrics Dashboard to track and analyze your performance.</p>
          <p className="mt-2">Click "Finish" to start exploring your metrics!</p>
        </div>
      ),
      targetSelector: '.metrics-dashboard-header',
      position: 'bottom' as const,
    },
  ];

  // Calculate current step index
  const currentStepIndex = steps.findIndex(s => s.step === currentStep);
  const isFirstStep = currentStepIndex === 0;
  const isLastStep = currentStepIndex === steps.length - 1;

  return (
    <>
      {/* Overlay backdrop with animation */}
      {createPortal(
        <AnimatePresence>
          {isOnboarding && (
            <motion.div 
              className="fixed inset-0 bg-black/60 z-40 pointer-events-none"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              aria-hidden="true"
            />
          )}
        </AnimatePresence>,
        document.body
      )}
      
      {/* Progress indicator with animation */}
      {createPortal(
        <AnimatePresence>
          {isOnboarding && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{ duration: 0.3, delay: 0.1 }}
            >
              <OnboardingProgress 
                currentStep={currentStepIndex + 1}
                totalSteps={steps.length}
                onSkip={skipOnboarding}
              />
            </motion.div>
          )}
        </AnimatePresence>,
        document.body
      )}
      
      {/* Render all steps (only the active one will be visible) */}
      <AnimatePresence mode="wait">
        {steps.map((step) => (
          step.step === currentStep && (
            <OnboardingStep
              key={step.step}
              step={step.step}
              currentStep={currentStep}
              title={step.title}
              description={step.description}
              targetSelector={step.targetSelector}
              position={step.position}
              onNext={nextStep}
              onPrev={prevStep}
              onSkip={skipOnboarding}
              isFirstStep={isFirstStep}
              isLastStep={isLastStep}
            />
          )
        ))}
      </AnimatePresence>
    </>
  );
} 