import { CheckCircle } from 'lucide-react';
import { Button } from '../../components/ui/button';
import { PlanAction, Product } from './types';
import { formatPrice, getFeatures, isComingSoon } from './utils';

interface AddOnCardProps {
  product: Product;
  planAction: PlanAction;
  onSubscribe: (priceId: string) => void;
}

export function AddOnCard({ product, planAction, onSubscribe }: AddOnCardProps) {
  const defaultPriceId = product.default_price;
  const comingSoon = isComingSoon(product);
  
  return (
    <div className="bg-white rounded-xl border border-gray-200 p-5">
      <h4 className="text-lg font-bold mb-2">{product.name}</h4>
      <div className="flex items-baseline mb-3">
        {product.priceData && (
          <>
            <span className="text-xl font-bold">
              {formatPrice(product.priceData.unit_amount, product.priceData.currency)}
            </span>
            <span className="text-sm text-gray-600 ml-1">
              /{product.priceData.recurring?.interval}
            </span>
          </>
        )}
      </div>
      <p className="text-sm text-gray-600 mb-4">{product.description}</p>
      <ul className="space-y-2 mb-5">
        {getFeatures(product).map((feature, i) => (
          <li key={i} className="flex items-start gap-2">
            <CheckCircle className="h-4 w-4 text-green-500 shrink-0 mt-0.5" />
            <span className="text-sm" dangerouslySetInnerHTML={{ __html: feature }}></span>
          </li>
        ))}
      </ul>
      <Button 
        className={`w-full text-sm py-1.5 h-auto ${
          planAction === 'current' 
            ? "bg-green-600 hover:bg-green-700" 
            : "bg-primary hover:bg-primary/90"
        }`}
        onClick={() => onSubscribe(defaultPriceId)}
        disabled={!defaultPriceId || comingSoon || planAction === 'current'}
        variant={comingSoon ? "outline" : "default"}
      >
        {comingSoon 
          ? "Coming Soon" 
          : planAction === 'current' 
            ? "Current Add-On" 
            : "Add to Subscription"}
      </Button>
    </div>
  );
} 