import { Button } from '@/components/ui/button';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select';
import { useState } from 'react';
import { HiOutlinePlus } from 'react-icons/hi';

interface MetadataField {
  metadataKey: string;
  valueType: 'text' | 'number' | 'boolean';
  useCount: number;
  lastUsed: string;
}

interface FieldMapperProps {
  csvHeaders: string[];
  metadataFields: MetadataField[];
  onMappingComplete: (standardMapping: Record<string, string>, customMapping: Record<string, string>) => void;
  onCreateMetadataField: (metadataKey: string, valueType: 'text' | 'number' | 'boolean') => Promise<boolean>;
  isLoading: boolean;
}

// Standard lead fields that can be mapped
const STANDARD_FIELDS = [
  { key: 'name', label: 'Name', required: true },
  { key: 'phoneNumber', label: 'Phone Number', required: true },
  { key: 'email', label: 'Email', required: false },
  { key: 'businessName', label: 'Business Name', required: false },
  { key: 'title', label: 'Title', required: false },
  { key: 'address', label: 'Address', required: false },
  { key: 'city', label: 'City', required: false },
  { key: 'state', label: 'State', required: false },
  { key: 'zip', label: 'Zip', required: false },
  { key: 'county', label: 'County', required: false },
  { key: 'insCompany', label: 'Insurance Company', required: false },
  { key: 'age', label: 'Age', required: false },
  { key: 'spouseAge', label: 'Spouse Age', required: false },
  { key: 'children', label: 'Children', required: false },
  { key: 'comments', label: 'Comments', required: false },
];

export function FieldMapper({
  csvHeaders,
  metadataFields,
  onMappingComplete,
  onCreateMetadataField,
  isLoading
}: FieldMapperProps) {
  // State for standard field mapping
  const [standardMapping, setStandardMapping] = useState<Record<string, string>>({});
  
  // State for custom metadata field mapping
  const [customMapping, setCustomMapping] = useState<Record<string, string>>({});
  
  // State for new metadata field dialog
  const [isNewFieldDialogOpen, setIsNewFieldDialogOpen] = useState(false);
  const [newFieldName, setNewFieldName] = useState('');
  const [newFieldType, setNewFieldType] = useState<'text' | 'number' | 'boolean'>('text');
  const [isCreatingField, setIsCreatingField] = useState(false);

  // Auto-map fields based on name similarity
  const autoMapFields = () => {
    const newStandardMapping = { ...standardMapping };
    const newCustomMapping = { ...customMapping };
    
    // Try to auto-map standard fields
    STANDARD_FIELDS.forEach(field => {
      if (!standardMapping[field.key]) {
        // Look for exact matches first
        const exactMatch = csvHeaders.find(header => 
          header.toLowerCase() === field.label.toLowerCase() ||
          header.toLowerCase() === field.key.toLowerCase()
        );
        
        if (exactMatch) {
          newStandardMapping[field.key] = exactMatch;
        } else {
          // Look for partial matches
          const partialMatch = csvHeaders.find(header => 
            header.toLowerCase().includes(field.label.toLowerCase()) ||
            header.toLowerCase().includes(field.key.toLowerCase())
          );
          
          if (partialMatch) {
            newStandardMapping[field.key] = partialMatch;
          }
        }
      }
    });
    
    // Try to auto-map custom fields
    metadataFields.forEach(field => {
      if (!customMapping[field.metadataKey]) {
        // Look for exact matches
        const exactMatch = csvHeaders.find(header => 
          header.toLowerCase() === field.metadataKey.toLowerCase()
        );
        
        if (exactMatch) {
          newCustomMapping[field.metadataKey] = exactMatch;
        }
      }
    });
    
    setStandardMapping(newStandardMapping);
    setCustomMapping(newCustomMapping);
  };

  // Handle standard field mapping change
  const handleStandardMappingChange = (fieldKey: string, headerValue: string) => {
    setStandardMapping(prev => ({
      ...prev,
      [fieldKey]: headerValue
    }));
  };

  // Handle custom field mapping change
  const handleCustomMappingChange = (fieldKey: string, headerValue: string) => {
    setCustomMapping(prev => ({
      ...prev,
      [fieldKey]: headerValue
    }));
  };

  // Handle creating a new metadata field
  const handleCreateField = async () => {
    if (!newFieldName.trim()) {
      alert('Please enter a field name');
      return;
    }
    
    setIsCreatingField(true);
    
    try {
      const success = await onCreateMetadataField(
        newFieldName.trim(),
        newFieldType
      );
      
      if (success) {
        setIsNewFieldDialogOpen(false);
        setNewFieldName('');
      }
    } finally {
      setIsCreatingField(false);
    }
  };

  // Check if required fields are mapped
  const areRequiredFieldsMapped = () => {
    return STANDARD_FIELDS.filter(field => field.required)
      .every(field => standardMapping[field.key] && standardMapping[field.key] !== 'not_mapped');
  };

  // Handle completion of mapping
  const handleComplete = () => {
    if (!areRequiredFieldsMapped()) {
      alert('Please map all required fields');
      return;
    }
    
    // Filter out 'not_mapped' values before passing to parent
    const filteredStandardMapping = Object.fromEntries(
      Object.entries(standardMapping).filter(([_, value]) => value !== 'not_mapped')
    );
    
    const filteredCustomMapping = Object.fromEntries(
      Object.entries(customMapping).filter(([_, value]) => value !== 'not_mapped')
    );
    
    onMappingComplete(filteredStandardMapping, filteredCustomMapping);
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h3 className="text-lg font-medium">Map CSV Columns to Lead Fields</h3>
        <Button
          variant="outline"
          onClick={autoMapFields}
          disabled={isLoading}
        >
          Auto-Map Fields
        </Button>
      </div>
      
      {/* Standard Fields */}
      <div className="space-y-4">
        <h4 className="font-medium">Standard Fields</h4>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {STANDARD_FIELDS.map(field => (
            <div key={field.key} className="space-y-2">
              <Label htmlFor={`field-${field.key}`}>
                {field.label} {field.required && <span className="text-red-500">*</span>}
              </Label>
              <Select
                value={standardMapping[field.key] || 'not_mapped'}
                onValueChange={(value) => handleStandardMappingChange(field.key, value)}
                disabled={isLoading}
              >
                <SelectTrigger id={`field-${field.key}`} className="w-full">
                  <SelectValue placeholder="Select a CSV column" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="not_mapped">-- Not Mapped --</SelectItem>
                  {csvHeaders.map(header => (
                    <SelectItem key={header} value={header}>
                      {header}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          ))}
        </div>
      </div>
      
      {/* Custom Metadata Fields */}
      <div className="space-y-4">
        <div className="flex justify-between items-center">
          <h4 className="font-medium">Custom Metadata Fields</h4>
          <Dialog open={isNewFieldDialogOpen} onOpenChange={setIsNewFieldDialogOpen}>
            <DialogTrigger asChild>
              <Button variant="outline" size="sm" disabled={isLoading}>
                <HiOutlinePlus className="mr-2 h-4 w-4" />
                Create New Field
              </Button>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Create New Metadata Field</DialogTitle>
                <DialogDescription>
                  Create a custom field to store additional lead information.
                </DialogDescription>
              </DialogHeader>
              <div className="grid gap-4 py-4">
                <div className="grid grid-cols-4 items-center gap-4">
                  <Label htmlFor="field-name" className="text-right">
                    Field Name
                  </Label>
                  <Input
                    id="field-name"
                    value={newFieldName}
                    onChange={(e) => setNewFieldName(e.target.value)}
                    className="col-span-3"
                  />
                </div>
                <div className="grid grid-cols-4 items-center gap-4">
                  <Label htmlFor="field-type" className="text-right">
                    Field Type
                  </Label>
                  <RadioGroup
                    value={newFieldType}
                    onValueChange={(value) => setNewFieldType(value as 'text' | 'number' | 'boolean')}
                    className="col-span-3 flex space-x-4"
                  >
                    <div className="flex items-center space-x-2">
                      <RadioGroupItem value="text" id="text" />
                      <Label htmlFor="text">Text</Label>
                    </div>
                    <div className="flex items-center space-x-2">
                      <RadioGroupItem value="number" id="number" />
                      <Label htmlFor="number">Number</Label>
                    </div>
                    <div className="flex items-center space-x-2">
                      <RadioGroupItem value="boolean" id="boolean" />
                      <Label htmlFor="boolean">Boolean</Label>
                    </div>
                  </RadioGroup>
                </div>
              </div>
              <DialogFooter>
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => setIsNewFieldDialogOpen(false)}
                  disabled={isCreatingField}
                >
                  Cancel
                </Button>
                <Button
                  type="button"
                  onClick={handleCreateField}
                  disabled={isCreatingField}
                >
                  {isCreatingField ? 'Creating...' : 'Create Field'}
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </div>
        
        {metadataFields.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {metadataFields.map(field => (
              <div key={field.metadataKey} className="space-y-2">
                <Label htmlFor={`metadata-${field.metadataKey}`}>
                  {field.metadataKey} ({field.valueType})
                </Label>
                <Select
                  value={customMapping[field.metadataKey] || 'not_mapped'}
                  onValueChange={(value) => handleCustomMappingChange(field.metadataKey, value)}
                  disabled={isLoading}
                >
                  <SelectTrigger id={`metadata-${field.metadataKey}`} className="w-full">
                    <SelectValue placeholder="Select a CSV column" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="not_mapped">-- Not Mapped --</SelectItem>
                    {csvHeaders.map(header => (
                      <SelectItem key={header} value={header}>
                        {header}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center py-4 text-gray-500">
            No custom metadata fields available. Create one to map additional CSV columns.
          </div>
        )}
      </div>
      
      <div className="pt-4 flex justify-end">
        <Button
          onClick={handleComplete}
          disabled={!areRequiredFieldsMapped() || isLoading}
        >
          {isLoading ? 'Processing...' : 'Continue to Preview'}
        </Button>
      </div>
    </div>
  );
} 