import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { cn } from '@/lib/utils';
import { createMetadataValue, metadataApi } from '@/services/metadata.service';
import { useLeadStore } from '@/store/leadStore';
import { MetadataSuggestion, MetadataValue } from '@/types/metadata';
import { Check, Edit2, GripVertical, Tag, Trash, X } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';
import { formFieldClasses } from './utils';

// Utility function to detect mobile devices
const isMobileDevice = () => {
  return (
    typeof window !== 'undefined' &&
    (window.innerWidth < 768 ||
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      ))
  );
};

interface LeadMetadataSectionProps {
  leadId: number;
  className?: string;
}

export function LeadMetadataSection({ leadId, className }: LeadMetadataSectionProps) {
  const [metadata, setMetadata] = useState<Record<string, MetadataValue>>({});
  const [suggestions, setSuggestions] = useState<MetadataSuggestion[]>([]);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [newKey, setNewKey] = useState('');
  const [newValue, setNewValue] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [focusedSuggestionIndex, setFocusedSuggestionIndex] = useState(-1);
  const [deletingSuggestionIndex, setDeletingSuggestionIndex] = useState<number | null>(null);
  const [editingKey, setEditingKey] = useState<string | null>(null);
  const [editValue, setEditValue] = useState('');
  const [draggedKey, setDraggedKey] = useState<string | null>(null);
  const [isMobile, setIsMobile] = useState(false);
  const [deletingField, setDeletingField] = useState<string | null>(null);
  const fieldKeyRef = useRef<HTMLInputElement>(null);
  const fieldValueRef = useRef<HTMLInputElement>(null);
  const editValueRef = useRef<HTMLInputElement>(null);
  const suggestionsRef = useRef<HTMLDivElement>(null);
  
  // Get the current search term from the lead store
  const searchTerm = useLeadStore(state => state.filter.search?.toLowerCase() || '');

  // Check if we're on a mobile device on component mount
  useEffect(() => {
    setIsMobile(isMobileDevice());
    
    // Also add a resize listener to update if orientation changes
    const handleResize = () => {
      setIsMobile(isMobileDevice());
    };
    
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      // Clean up any pending states
      setDeletingField(null);
      setEditingKey(null);
    };
  }, []);

  // Check if any metadata matches the search term
  const hasMetadataMatches = searchTerm && Object.entries(metadata).some(
    ([key, value]) => 
      key.toLowerCase().includes(searchTerm) || 
      String(value.value).toLowerCase().includes(searchTerm)
  );

  // Fetch metadata and suggestions
  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const [metadataData, suggestionsData] = await Promise.all([
          metadataApi.getLeadMetadata(leadId),
          metadataApi.getMetadataSuggestions()
        ]);
        
        // Ensure order property exists for all metadata values
        const enhancedMetadata: Record<string, MetadataValue> = {};
        
        // Add order property if it doesn't exist
        Object.entries(metadataData).forEach(([key, value], index) => {
          enhancedMetadata[key] = {
            ...value,
            order: value.order ?? index
          };
        });
        
        setMetadata(enhancedMetadata);
        setSuggestions(suggestionsData);
      } catch (error) {
        console.error('Error fetching metadata:', error);
      } finally {
        setLoading(false);
      }
    }

    if (leadId) {
      fetchData();
    }
  }, [leadId]);

  // Add new metadata field
  const handleAddField = async () => {
    if (!newKey.trim() || !newValue.trim()) return;

    try {
      setSaving(true);
      
      // Find the highest order value
      const maxOrder = Object.values(metadata).reduce(
        (max, value) => Math.max(max, value.order ?? 0),
        -1
      );
      
      // Create the new metadata value with order
      const newMetadataValue: MetadataValue = createMetadataValue(
        newValue,
        undefined, // section
        maxOrder + 1 // order
      );
      
      // Add the new field to the metadata
      const updatedMetadata = {
        ...metadata,
        [newKey]: newMetadataValue
      };
      
      // Send to server
      await metadataApi.updateLeadMetadata(leadId, updatedMetadata);
      
      // Update local state
      setMetadata(updatedMetadata);
      setNewKey('');
      setNewValue('');
      setShowSuggestions(false);
    } catch (error) {
      console.error('Error adding metadata field:', error);
    } finally {
      setSaving(false);
    }
  };

  // Delete metadata field with confirmation
  const confirmDeleteField = (key: string) => {
    setDeletingField(key);
  };

  // Cancel field deletion
  const cancelDeleteField = () => {
    setDeletingField(null);
  };

  // Delete metadata field
  const handleDeleteField = async (key: string) => {
    try {
      setSaving(true);
      
      // Create a copy of the metadata without the specified key
      const { [key]: removedField, ...updatedMetadata } = metadata;
      
      // Send to server
      await metadataApi.updateLeadMetadata(leadId, updatedMetadata);
      
      // Update local state
      setMetadata(updatedMetadata);
      setDeletingField(null);
    } catch (error) {
      console.error('Error deleting metadata field:', error);
    } finally {
      setSaving(false);
    }
  };

  // Start editing a field
  const handleStartEdit = (key: string, value: MetadataValue) => {
    // If already editing or saving, don't allow starting a new edit
    if (editingKey !== null || saving) {
      return;
    }
    
    // Cancel any pending delete confirmations
    setDeletingField(null);
    
    setEditingKey(key);
    setEditValue(String(value.value));
    
    // Focus the edit input after a short delay to allow the UI to update
    setTimeout(() => {
      if (editValueRef.current) {
        editValueRef.current.focus();
        // Select all text for easy replacement
        editValueRef.current.select();
      }
    }, 50);
  };

  // Cancel editing
  const handleCancelEdit = () => {
    setEditingKey(null);
    setEditValue('');
  };

  // Save edited field
  const handleSaveEdit = async () => {
    // If already saving or no editing key, don't proceed
    if (saving || !editingKey || !editValue.trim()) {
      if (!saving) {
        handleCancelEdit();
      }
      return;
    }

    try {
      setSaving(true);
      const currentValue = metadata[editingKey];
      
      // Update the value in the metadata, preserving the order
      const updatedMetadata = {
        ...metadata,
        [editingKey]: {
          ...currentValue,
          value: editValue,
          type: createMetadataValue(editValue).type
        }
      };
      
      // Send to server
      await metadataApi.updateLeadMetadata(leadId, updatedMetadata);
      
      // Update local state
      setMetadata(updatedMetadata);
      
      // Clear editing state
      setEditingKey(null);
      setEditValue('');
    } catch (error) {
      console.error('Error updating metadata field:', error);
    } finally {
      setSaving(false);
    }
  };

  // Handle suggestion selection
  const handleSelectSuggestion = (suggestion: string) => {
    setNewKey(suggestion);
    setShowSuggestions(false);
    setFocusedSuggestionIndex(-1);
    // Focus the value input after selecting a suggestion
    fieldValueRef.current?.focus();
  };

  // Delete a suggestion
  const handleDeleteSuggestion = async (suggestionKey: string) => {
    try {
      setSaving(true);
      // Call API to delete the suggestion
      await metadataApi.deleteSuggestion(suggestionKey);
      // Update the local suggestions list
      setSuggestions(prev => prev.filter(s => s.key !== suggestionKey));
      setDeletingSuggestionIndex(null);
      
      // Keep the dropdown open and focus on the input
      setShowSuggestions(true);
      setTimeout(() => {
        fieldKeyRef.current?.focus();
      }, 0);
    } catch (error) {
      console.error('Error deleting suggestion:', error);
    } finally {
      setSaving(false);
    }
  };

  // Drag and drop handlers
  const handleDragStart = (key: string) => {
    setDraggedKey(key);
  };

  const handleDragOver = (e: React.DragEvent, targetKey: string) => {
    e.preventDefault();
    if (draggedKey === null || draggedKey === targetKey) return;
    
    // Get the current order values
    const draggedOrder = metadata[draggedKey].order ?? 0;
    const targetOrder = metadata[targetKey].order ?? 0;
    
    if (draggedOrder === targetOrder) return;
    
    // Create a new metadata object with updated orders
    const updatedMetadata = { ...metadata };
    
    // If dragging down (draggedOrder < targetOrder)
    if (draggedOrder < targetOrder) {
      // Decrease order for items between draggedOrder and targetOrder
      Object.entries(updatedMetadata).forEach(([key, value]) => {
        const order = value.order ?? 0;
        if (key !== draggedKey && order > draggedOrder && order <= targetOrder) {
          updatedMetadata[key] = { ...value, order: order - 1 };
        }
      });
    } 
    // If dragging up (draggedOrder > targetOrder)
    else {
      // Increase order for items between targetOrder and draggedOrder
      Object.entries(updatedMetadata).forEach(([key, value]) => {
        const order = value.order ?? 0;
        if (key !== draggedKey && order >= targetOrder && order < draggedOrder) {
          updatedMetadata[key] = { ...value, order: order + 1 };
        }
      });
    }
    
    // Set the dragged item to the target order
    updatedMetadata[draggedKey] = { ...updatedMetadata[draggedKey], order: targetOrder };
    
    // Update the state
    setMetadata(updatedMetadata);
  };

  const handleDragEnd = async () => {
    if (draggedKey === null) return;
    
    try {
      setSaving(true);
      
      // Send the updated metadata to the server
      await metadataApi.updateLeadMetadata(leadId, metadata);
      
    } catch (error) {
      console.error('Error saving metadata order:', error);
    } finally {
      setDraggedKey(null);
      setSaving(false);
    }
  };

  // Filter suggestions based on input
  const filteredSuggestions = suggestions
    .filter(suggestion => 
      suggestion.key.toLowerCase().includes(inputValue.toLowerCase())
    )
    .sort((a, b) => b.useCount - a.useCount);

  // Handle value input blur to save the field
  const handleValueBlur = () => {
    if (newKey.trim() && newValue.trim()) {
      handleAddField();
    }
  };

  // Handle edit value input blur
  const handleEditValueBlur = () => {
    // Don't do anything if we're already saving
    if (saving) {
      return;
    }
    
    // Check if the click was on one of our action buttons
    const activeElement = document.activeElement;
    const isClickingActionButton = 
      activeElement?.tagName === 'BUTTON' || 
      activeElement?.closest('button') !== null;
    
    // On mobile, don't save on blur if clicking an action button
    // This prevents double-saving when clicking the save button
    if (isMobile && isClickingActionButton) {
      return;
    }
    
    // For desktop, or for mobile when not clicking buttons, save on blur
    if (editValue.trim()) {
      handleSaveEdit();
    } else {
      handleCancelEdit();
    }
  };

  // Handle keyboard events for the field name input
  const handleKeyNameKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      
      if (focusedSuggestionIndex >= 0 && filteredSuggestions[focusedSuggestionIndex]) {
        // If a suggestion is focused, select it
        handleSelectSuggestion(filteredSuggestions[focusedSuggestionIndex].key);
      } else {
        // Otherwise, proceed with the current input
        setShowSuggestions(false);
        
        if (newKey.trim() && newValue.trim()) {
          handleAddField();
        } else if (newKey.trim()) {
          fieldValueRef.current?.focus();
        }
      }
    } else if (e.key === 'ArrowDown') {
      e.preventDefault();
      if (showSuggestions && filteredSuggestions.length > 0) {
        // Move focus to the first suggestion or next suggestion
        setFocusedSuggestionIndex(prev => 
          prev < filteredSuggestions.length - 1 ? prev + 1 : 0
        );
      } else {
        setShowSuggestions(true);
      }
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      if (showSuggestions && filteredSuggestions.length > 0) {
        // Move focus to the previous suggestion or last suggestion
        setFocusedSuggestionIndex(prev => 
          prev > 0 ? prev - 1 : filteredSuggestions.length - 1
        );
      }
    } else if (e.key === 'Escape') {
      setShowSuggestions(false);
      setFocusedSuggestionIndex(-1);
      setDeletingSuggestionIndex(null);
    } else if (e.key === 'Tab' && showSuggestions && filteredSuggestions.length > 0) {
      // Allow tabbing into the suggestions
      if (!e.shiftKey) {
        e.preventDefault();
        setFocusedSuggestionIndex(0);
      }
    }
  };

  // Handle keyboard events for the suggestions
  const handleSuggestionKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>, index: number) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSelectSuggestion(filteredSuggestions[index].key);
    } else if (e.key === 'ArrowDown') {
      e.preventDefault();
      setFocusedSuggestionIndex(prev => 
        prev < filteredSuggestions.length - 1 ? prev + 1 : 0
      );
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setFocusedSuggestionIndex(prev => 
        prev > 0 ? prev - 1 : filteredSuggestions.length - 1
      );
    } else if (e.key === 'Escape') {
      e.preventDefault();
      setShowSuggestions(false);
      setFocusedSuggestionIndex(-1);
      setDeletingSuggestionIndex(null);
      fieldKeyRef.current?.focus();
    } else if (e.key === 'Tab') {
      if (e.shiftKey) {
        e.preventDefault();
        setFocusedSuggestionIndex(-1);
        fieldKeyRef.current?.focus();
      }
    }
  };

  // Handle keyboard events for the value input
  const handleValueKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && newKey.trim() && newValue.trim()) {
      e.preventDefault();
      handleAddField();
    }
  };

  // Handle keyboard events for the edit value input
  const handleEditValueKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (!saving) {
        handleSaveEdit();
      }
    } else if (e.key === 'Escape') {
      e.preventDefault();
      if (!saving) {
        handleCancelEdit();
      }
    }
  };

  // Effect to focus the suggestion when focusedSuggestionIndex changes
  useEffect(() => {
    if (focusedSuggestionIndex >= 0 && suggestionsRef.current) {
      const suggestionElements = suggestionsRef.current.querySelectorAll('.suggestion-item');
      if (suggestionElements[focusedSuggestionIndex]) {
        (suggestionElements[focusedSuggestionIndex] as HTMLElement).focus();
      }
    }
  }, [focusedSuggestionIndex]);

  // Effect to cancel delete confirmation when clicking outside
  useEffect(() => {
    if (!deletingField) return;

    const handleClickOutside = (e: MouseEvent) => {
      // Check if the click was outside the component
      const target = e.target as HTMLElement;
      const isOutsideClick = !target.closest('.metadata-field-item');
      
      if (isOutsideClick) {
        setDeletingField(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [deletingField]);

  // Add a global keyboard handler for delete confirmation
  useEffect(() => {
    if (!deletingField) return;

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        // Confirm deletion with Enter key
        handleDeleteField(deletingField);
      } else if (e.key === 'Escape') {
        // Cancel deletion with Escape key
        cancelDeleteField();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [deletingField]);

  return (
    <div className={cn(formFieldClasses.section, className)}>
      <div className="flex items-center justify-between mb-3">
        <div className="flex items-center gap-2">
          <h3 className="text-[10px] font-normal text-gray-400 dark:text-gray-400 mb-0 block">Custom Data</h3>
          {hasMetadataMatches && (
            <span className="inline-flex items-center px-1.5 py-0.5 rounded-full text-[10px] font-medium bg-yellow-100 text-yellow-800 dark:bg-yellow-900/50 dark:text-yellow-300">
              Search match
            </span>
          )}
        </div>
      </div>
      
      {loading ? (
        <div className="py-2 text-center text-muted-foreground text-sm">Loading metadata...</div>
      ) : Object.keys(metadata).length === 0 ? (
        <div className="py-2 text-center text-muted-foreground text-sm">
          No custom fields added yet. Add your first field below.
        </div>
      ) : (
        <div className="space-y-0 mb-4">
          {Object.entries(metadata)
            .sort(([, a], [, b]) => (a.order ?? 0) - (b.order ?? 0))
            .map(([key, value]) => {
              const isEditing = editingKey === key;
              const isDragging = draggedKey === key;
              
              // Check if this field matches the current search term
              const matchesSearch = searchTerm && (
                key.toLowerCase().includes(searchTerm) || 
                String(value.value).toLowerCase().includes(searchTerm)
              );
              
              return (
                <div 
                  key={key}
                  className={cn(
                    "flex items-center justify-between p-2 mb-1 rounded-md border border-gray-100 dark:border-gray-700 group metadata-field-item",
                    isDragging && "opacity-50 border-dashed",
                    isEditing && "border-blue-300 bg-blue-50/50 dark:bg-blue-900/10",
                    matchesSearch && !isEditing && "bg-yellow-50 dark:bg-yellow-900/10 border-yellow-200 dark:border-yellow-800"
                  )}
                  draggable={editingKey === null}
                  onDragStart={() => handleDragStart(key)}
                  onDragOver={(e) => handleDragOver(e, key)}
                  onDragEnd={handleDragEnd}
                >
                  <div className="flex items-center gap-2 flex-1 min-w-0">
                    <div className="cursor-grab opacity-30 hover:opacity-100">
                      <GripVertical size={16} />
                    </div>
                    
                    <div className="flex-1 min-w-0">
                      <div className="flex items-center gap-1">
                        <Tag size={12} className="text-gray-400" />
                        <span className="font-medium text-sm truncate">{key}</span>
                      </div>
                      
                      {isEditing ? (
                        <div className="mt-1 flex items-center gap-2 w-full">
                          <Input
                            ref={editValueRef}
                            type="text"
                            value={editValue}
                            onChange={(e) => setEditValue(e.target.value)}
                            onBlur={handleEditValueBlur}
                            onKeyDown={handleEditValueKeyDown}
                            className="h-7 text-sm w-full border-blue-300 focus:border-blue-500 focus:ring-blue-500"
                            autoFocus
                          />
                        </div>
                      ) : (
                        <div className="text-sm text-gray-600 dark:text-gray-400 truncate">
                          {String(value.value)}
                        </div>
                      )}
                    </div>
                  </div>
                  
                  <div className="flex items-center gap-1">
                    {isEditing ? (
                      <>
                        {/* Mobile-friendly save/cancel buttons */}
                        <div className="md:hidden flex items-center gap-1">
                          <Button
                            variant="ghost"
                            size="icon"
                            className="h-7 w-7 text-green-600 hover:text-green-700 hover:bg-green-50 dark:hover:bg-green-900/20"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleSaveEdit();
                            }}
                            disabled={saving}
                          >
                            <Check size={14} />
                          </Button>
                          <Button
                            variant="ghost"
                            size="icon"
                            className="h-7 w-7 text-red-500 hover:text-red-600 hover:bg-red-50 dark:hover:bg-red-900/20"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleCancelEdit();
                            }}
                          >
                            <X size={14} />
                          </Button>
                        </div>
                        
                        {/* Desktop keyboard shortcut hint */}
                        <div className="hidden md:flex text-xs text-blue-500 dark:text-blue-400 italic items-center">
                          <span className="mr-1">⏎</span> to save, <span className="mx-1">Esc</span> to cancel
                        </div>
                      </>
                    ) : (
                      <>
                        <Button
                          variant="ghost"
                          size="icon"
                          className="h-7 w-7 opacity-40 hover:opacity-100 transition-opacity cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-800"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleStartEdit(key, value);
                          }}
                        >
                          <Edit2 size={14} />
                        </Button>
                        {deletingField === key ? (
                          // Delete confirmation UI
                          <div className="flex items-center gap-1 bg-red-50 dark:bg-red-900/20 px-2 py-1 rounded-md">
                            <span className="text-xs text-red-500 font-medium mr-1">Delete?</span>
                            <Button
                              variant="ghost"
                              size="icon"
                              className="h-6 w-6 text-green-600 hover:text-green-700 hover:bg-green-50 dark:hover:bg-green-900/20"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDeleteField(key);
                              }}
                              disabled={saving}
                              title="Confirm delete"
                            >
                              <Check size={12} />
                            </Button>
                            <Button
                              variant="ghost"
                              size="icon"
                              className="h-6 w-6 text-red-500 hover:text-red-600 hover:bg-red-50 dark:hover:bg-red-900/20"
                              onClick={(e) => {
                                e.stopPropagation();
                                cancelDeleteField();
                              }}
                              title="Cancel"
                            >
                              <X size={12} />
                            </Button>
                          </div>
                        ) : (
                          <Button
                            variant="ghost"
                            size="icon"
                            className="h-7 w-7 opacity-40 hover:opacity-100 transition-opacity text-red-500 hover:text-red-600 cursor-pointer hover:bg-red-50 dark:hover:bg-red-900/20"
                            onClick={(e) => {
                              e.stopPropagation();
                              confirmDeleteField(key);
                            }}
                          >
                            <Trash size={14} />
                          </Button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      )}
      
      <div className="pt-2">
        <div className="grid grid-cols-2 gap-3">
          <div className={formFieldClasses.field}>
            <label className={formFieldClasses.label}>Field Name</label>
            <div className="relative">
              <Tag className={formFieldClasses.icon} />
              <Input
                ref={fieldKeyRef}
                id="field-key"
                placeholder="Field name"
                value={newKey}
                onChange={(e) => {
                  const value = e.target.value;
                  setNewKey(value);
                  setInputValue(value);
                  setShowSuggestions(!!value);
                  setFocusedSuggestionIndex(-1);
                  setDeletingSuggestionIndex(null);
                }}
                onFocus={() => {
                  if (newKey) setShowSuggestions(true);
                }}
                onBlur={(e) => {
                  // Delay hiding suggestions to allow clicking on them
                  setTimeout(() => {
                    // Only hide suggestions if focus is not on a suggestion or the input
                    if (!suggestionsRef.current?.contains(document.activeElement) && 
                        document.activeElement !== fieldKeyRef.current &&
                        deletingSuggestionIndex === null) {
                      setShowSuggestions(false);
                      setFocusedSuggestionIndex(-1);
                    }
                  }, 300); // Increased timeout for better interaction
                }}
                onKeyDown={handleKeyNameKeyDown}
                className={cn(formFieldClasses.input, formFieldClasses.inputWithIcon)}
                disabled={editingKey !== null}
              />
              {showSuggestions && (
                <div 
                  ref={suggestionsRef}
                  className="absolute z-50 top-full left-0 right-0 mt-1 bg-white dark:bg-gray-800 rounded-md border border-gray-200 dark:border-gray-700 shadow-md overflow-hidden"
                  tabIndex={-1}
                  onMouseDown={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div className="p-0">
                    <div className="max-h-60 overflow-y-auto">
                      {filteredSuggestions.length > 0 ? (
                        <div className="py-1">
                          <div className="px-2 py-1.5 text-xs font-medium text-gray-500 dark:text-gray-400">
                            Suggestions (Tab to navigate)
                          </div>
                          {filteredSuggestions.map((suggestion, index) => (
                            <div 
                              key={suggestion.key}
                              className={cn(
                                "flex items-center w-full px-2 py-1.5 text-sm hover:bg-gray-100 dark:hover:bg-gray-700",
                                focusedSuggestionIndex === index && "bg-gray-100 dark:bg-gray-700"
                              )}
                            >
                              {deletingSuggestionIndex === index ? (
                                // Delete confirmation UI
                                <div className="flex items-center justify-between w-full">
                                  <span className="text-red-500 font-medium">Delete?</span>
                                  <div className="flex items-center gap-2">
                                    <button
                                      className="p-1 rounded-full hover:bg-green-100 dark:hover:bg-green-800 text-green-600 dark:text-green-400"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        handleDeleteSuggestion(suggestion.key);
                                      }}
                                      onMouseDown={(e) => {
                                        e.preventDefault();
                                      }}
                                      disabled={saving}
                                    >
                                      <Check className="h-4 w-4" />
                                    </button>
                                    <button
                                      className="p-1 rounded-full hover:bg-red-100 dark:hover:bg-red-800 text-red-600 dark:text-red-400"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setDeletingSuggestionIndex(null);
                                      }}
                                      onMouseDown={(e) => {
                                        e.preventDefault();
                                      }}
                                    >
                                      <X className="h-4 w-4" />
                                    </button>
                                  </div>
                                </div>
                              ) : (
                                // Normal suggestion UI
                                <>
                                  <button
                                    className="suggestion-item flex-1 flex items-center text-left focus:outline-none"
                                    onMouseDown={(e) => {
                                      e.preventDefault();
                                      handleSelectSuggestion(suggestion.key);
                                    }}
                                    onKeyDown={(e) => handleSuggestionKeyDown(e, index)}
                                    tabIndex={0}
                                  >
                                    <Check
                                      className={cn(
                                        "mr-2 h-4 w-4",
                                        newKey === suggestion.key ? "opacity-100" : "opacity-0"
                                      )}
                                    />
                                    <span className="flex-1">{suggestion.key}</span>
                                    <Badge variant="outline" className="ml-2 text-xs">
                                      {suggestion.useCount}
                                    </Badge>
                                  </button>
                                  <button
                                    className="ml-2 p-1 rounded-full hover:bg-red-100 dark:hover:bg-red-800 text-gray-400 hover:text-red-600 dark:hover:text-red-400"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      setDeletingSuggestionIndex(index);
                                      // Keep focus in the dropdown
                                      setTimeout(() => {
                                        if (suggestionsRef.current) {
                                          suggestionsRef.current.focus();
                                        }
                                      }, 0);
                                    }}
                                    onMouseDown={(e) => {
                                      // Prevent blur event on the input
                                      e.preventDefault();
                                    }}
                                  >
                                    <Trash className="h-3.5 w-3.5" />
                                  </button>
                                </>
                              )}
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="py-6 text-center text-sm text-muted-foreground">
                          No suggestions found. Your custom field name will be used.
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={formFieldClasses.field}>
            <label className={formFieldClasses.label}>Value</label>
            <Input
              ref={fieldValueRef}
              id="field-value"
              placeholder="Value"
              value={newValue}
              onChange={(e) => setNewValue(e.target.value)}
              onBlur={handleValueBlur}
              onKeyDown={handleValueKeyDown}
              className={formFieldClasses.input}
              disabled={editingKey !== null}
            />
          </div>
        </div>
      </div>
    </div>
  );
} 