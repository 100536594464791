import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { useRef, useState } from 'react';
import { HiOutlineCloudUpload, HiOutlineDocumentText } from 'react-icons/hi';

interface FileUploaderProps {
  onFileUpload: (file: File) => void;
  isLoading: boolean;
}

export function FileUploader({ onFileUpload, isLoading }: FileUploaderProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [dragActive, setDragActive] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const file = e.dataTransfer.files[0];
      if (file.type === 'text/csv' || file.name.endsWith('.csv')) {
        setSelectedFile(file);
      } else {
        alert('Please upload a CSV file');
      }
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      if (file.type === 'text/csv' || file.name.endsWith('.csv')) {
        setSelectedFile(file);
      } else {
        alert('Please upload a CSV file');
      }
    }
  };

  const handleUpload = () => {
    if (selectedFile) {
      onFileUpload(selectedFile);
    }
  };

  return (
    <div className="flex flex-col items-center space-y-6">
      <Card
        className={`w-full p-8 border-2 border-dashed ${
          dragActive ? 'border-blue-500 bg-blue-50' : 'border-gray-300'
        } rounded-lg cursor-pointer transition-colors`}
        onDragEnter={handleDrag}
        onDragOver={handleDrag}
        onDragLeave={handleDrag}
        onDrop={handleDrop}
        onClick={() => fileInputRef.current?.click()}
      >
        <div className="flex flex-col items-center justify-center space-y-4">
          <HiOutlineCloudUpload className="w-16 h-16 text-gray-400" />
          <div className="text-center">
            <p className="text-lg font-medium">
              Drag and drop your CSV file here
            </p>
            <p className="text-sm text-gray-500">
              or click to browse your files
            </p>
          </div>
          <input
            ref={fileInputRef}
            type="file"
            accept=".csv"
            className="hidden"
            onChange={handleFileChange}
            disabled={isLoading}
          />
        </div>
      </Card>

      {selectedFile && (
        <div className="w-full">
          <div className="flex items-center p-4 bg-gray-50 rounded-lg">
            <HiOutlineDocumentText className="w-8 h-8 text-blue-500 mr-4" />
            <div className="flex-1 min-w-0">
              <p className="text-sm font-medium text-gray-900 truncate">
                {selectedFile.name}
              </p>
              <p className="text-xs text-gray-500">
                {(selectedFile.size / 1024).toFixed(2)} KB
              </p>
            </div>
            <Button
              onClick={handleUpload}
              disabled={isLoading}
            >
              {isLoading ? 'Uploading...' : 'Upload'}
            </Button>
          </div>
        </div>
      )}

      <div className="w-full max-w-md">
        <h3 className="text-lg font-medium mb-2">CSV Format Guidelines</h3>
        <ul className="list-disc pl-5 space-y-1 text-sm text-gray-600">
          <li>File must be in CSV format</li>
          <li>First row should contain column headers</li>
          <li>Required fields: Name and Phone Number</li>
          <li>Recommended fields: Email, Address, City, State, Zip</li>
          <li>Any additional fields can be mapped to custom metadata</li>
        </ul>
      </div>
    </div>
  );
} 