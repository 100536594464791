import { MainLayout } from '@/components/layout/MainLayout';
import { PageContainer } from '@/components/layout/PageContainer';
import { DIDManager } from '@/components/settings/DIDManager';
import { NotificationSettings } from '@/components/settings/NotificationSettings';
import { ProfileSettings } from '@/components/settings/ProfileSettings';
import { SecuritySettings } from '@/components/settings/SecuritySettings';
import { TeamSettings } from '@/components/settings/TeamSettings';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useRouter, useSearch } from '@tanstack/react-router';
import { Bell, CreditCard, Phone, Shield, User, Users } from 'lucide-react';
import { useState } from 'react';
import SubscriptionPage from './SubscriptionPage';

export function SettingsPage() {
  const router = useRouter();
  const search = useSearch({ from: '/settings' });
  const [activeTab, setActiveTab] = useState(search.tab || 'profile');
  
  // Update URL when tab changes
  const handleTabChange = (value: string) => {
    setActiveTab(value);
    
    // Update URL without causing a navigation
    router.navigate({ 
      to: '/settings',
      search: { tab: value, section: search.section },
      replace: true 
    });
  };

  return (
    <MainLayout pageTitle="Settings" pageDescription="Manage your account settings">
      <PageContainer title="Settings" description="Manage your account settings and preferences">
        <div className="max-w-4xl p-8 py-20 sm:py-6">
          <Tabs value={activeTab} onValueChange={handleTabChange} className="space-y-4">
            <TabsList className="overflow-x-auto w-full justify-start">
              <TabsTrigger value="profile" className="flex items-center gap-2">
                <User className="h-4 w-4" />
                Profile
              </TabsTrigger>
              <TabsTrigger value="teams" className="flex items-center gap-2">
                <Users className="h-4 w-4" />
                Teams
              </TabsTrigger>
              <TabsTrigger value="did" className="flex items-center gap-2">
                <Phone className="h-4 w-4" />
                DID Numbers
              </TabsTrigger>
              <TabsTrigger value="notifications" className="flex items-center gap-2">
                <Bell className="h-4 w-4" />
                Notifications
              </TabsTrigger>
              <TabsTrigger value="security" className="flex items-center gap-2">
                <Shield className="h-4 w-4" />
                Security
              </TabsTrigger>
              <TabsTrigger value="subscription" className="flex items-center gap-2">
                <CreditCard className="h-4 w-4" />
                Billing & Subscription
              </TabsTrigger>
            </TabsList>

            <TabsContent value="profile">
              <ProfileSettings />
            </TabsContent>

            <TabsContent value="teams">
              <TeamSettings />
            </TabsContent>

            <TabsContent value="did">
              <DIDManager />
            </TabsContent>

            <TabsContent value="notifications">
              <NotificationSettings />
            </TabsContent>

            <TabsContent value="security">
              <SecuritySettings />
            </TabsContent>

            <TabsContent value="subscription">
              <SubscriptionPage />
            </TabsContent>
          </Tabs>
        </div>
      </PageContainer>
    </MainLayout>
  );
} 