// Form field styling classes
export const formFieldClasses = {
  label: "text-[10px] font-normal text-gray-400 dark:text-gray-400 mb-0 block",
  input: "border-0 border-b border-gray-200/50 dark:border-gray-700/50 rounded-none px-0 h-7 focus-visible:ring-0 focus-visible:ring-offset-0 focus-visible:border-blue-400 dark:focus-visible:border-blue-500 focus:border-b bg-transparent py-0 leading-7 shadow-none",
  inputWithIcon: "pl-6",
  icon: "h-4 w-4 text-gray-400 dark:text-gray-400 absolute left-0 top-1/2 -translate-y-[45%]",
  section: "space-y-3 border border-gray-100 dark:border-gray-800 rounded-lg p-4 bg-gray-100/50 dark:bg-gray-800/10",
  field: "relative",
  leadName: "md:text-2xl font-semibold py-0 h-10 leading-10",
  phoneNumber: "text-base",
  numberInput: "[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none",
};

// Phone mask helper function for display only
export function formatPhoneNumberForDisplay(value: string): string {
  if (!value) return '';

  // Remove all non-digits
  const numbers = value.replace(/\D/g, '');
  
  // Limit to 10 digits
  const limitedNumbers = numbers.slice(0, 10);
  
  // Format the number
  if (limitedNumbers.length <= 3) {
    return limitedNumbers;
  } else if (limitedNumbers.length <= 6) {
    return `(${limitedNumbers.slice(0, 3)}) ${limitedNumbers.slice(3)}`;
  }
  return `(${limitedNumbers.slice(0, 3)}) ${limitedNumbers.slice(3, 6)}-${limitedNumbers.slice(6)}`;
}

// Define the query key type
export type LeadQueryKey = ['lead', number];

// Define the lead status type
export type LeadStatus = 'new' | 'contacted' | 'qualified' | 'proposal' | 'won' | 'lost'; 