import { Button } from "@/components/ui/button";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger
} from "@/components/ui/dropdown-menu";
import { Skeleton } from "@/components/ui/skeleton";
import { useTeamInvitations } from "@/hooks/useTeamInvitations";
import { useTeams } from "@/hooks/useTeams";
import { useAuthStore } from "@/store/authStore";
import { Link } from "@tanstack/react-router";
import { ChevronDown, Mail, Plus, Settings, UserPlus, Users } from "lucide-react";
import { useEffect, useState } from "react";
import { CreateTeamDialog } from "./CreateTeamDialog";
import { TeamInviteDialog } from "./TeamInviteDialog";

export function TeamSidebarSection() {
  const { 
    teams, 
    currentTeam, 
    selectTeam, 
    isLoadingTeams 
  } = useTeams();
  const { 
    invitations, 
    isLoading: isLoadingInvitations, 
    checkForNewInvitations 
  } = useTeamInvitations();
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [inviteDialogOpen, setInviteDialogOpen] = useState(false);
  const currentUserId = useAuthStore(state => state.user?.id);

  const pendingInvitations = invitations.length;

  // Check for new invitations when the component mounts
  useEffect(() => {
    // Initial check for invitations
    checkForNewInvitations();
    
    // No need for polling as WebSocketDataSync will handle updates
    // The WebSocketDataSync component will invalidate the query when needed
    
    return () => {
      // No cleanup needed
    };
  }, [checkForNewInvitations]);

  if (isLoadingTeams) {
    return (
      <div className="px-3 py-2">
        <Skeleton className="h-9 w-full" />
      </div>
    );
  }

  const isAdmin = currentTeam?.role === 'admin';

  return (
    <div className="px-3 py-2 space-y-2">
      <div className="flex items-center justify-between">
        <h3 className="text-xs font-semibold text-gray-500 uppercase tracking-wider">
          Teams
        </h3>
        <Button 
          variant="ghost" 
          size="icon" 
          className="h-5 w-5" 
          onClick={() => setCreateDialogOpen(true)}
          title="Create new team"
        >
          <Plus className="h-3.5 w-3.5" />
        </Button>
      </div>

      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button 
            variant="outline" 
            className="w-full justify-between font-normal"
            size="sm"
          >
            <div className="flex items-center gap-2 truncate">
              <Users className="h-4 w-4 text-gray-500" />
              <span className="truncate">
                {currentTeam ? currentTeam.name : 'Select a team'}
              </span>
            </div>
            <ChevronDown className="h-4 w-4 opacity-50" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="start" className="w-56">
          <DropdownMenuLabel>Your Teams</DropdownMenuLabel>
          {teams.map((team) => (
            <DropdownMenuItem 
              key={team.id}
              onClick={() => selectTeam(team.id)}
              className={currentTeam?.id === team.id ? 'bg-blue-50 text-blue-700' : ''}
            >
              <div className="flex items-center gap-2 w-full truncate">
                <Users className="h-4 w-4 shrink-0" />
                <span className="truncate">{team.name}</span>
                {team.isPersonal && team.userId === currentUserId && (
                  <span className="ml-auto text-xs text-gray-500 shrink-0">Personal</span>
                )}
              </div>
            </DropdownMenuItem>
          ))}
          
          <DropdownMenuSeparator />
          
          {currentTeam && (
            <DropdownMenuGroup>
              <DropdownMenuLabel className="text-xs text-gray-500">
                Team Options
              </DropdownMenuLabel>
              
              <DropdownMenuItem asChild>
                <Link to="/settings" search={{ tab: 'teams', section: undefined }} params={{}}>
                  <Settings className="h-4 w-4 mr-2 text-gray-500" />
                  <span>Manage Team</span>
                </Link>
              </DropdownMenuItem>
              
              {isAdmin && (
                <DropdownMenuItem onClick={() => setInviteDialogOpen(true)}>
                  <UserPlus className="h-4 w-4 mr-2 text-gray-500" />
                  <span>Invite People</span>
                </DropdownMenuItem>
              )}
            </DropdownMenuGroup>
          )}
          
          {pendingInvitations > 0 && (
            <>
              <DropdownMenuSeparator />
              <DropdownMenuGroup>
                <DropdownMenuLabel className="text-xs text-gray-500">
                  Invitations
                </DropdownMenuLabel>
                <DropdownMenuItem asChild>
                  <Link to="/settings" search={{ tab: 'teams', section: 'invitations' }} params={{}}>
                    <Mail className="h-4 w-4 mr-2 text-gray-500" />
                    <span>Pending Invitations</span>
                    <span className="ml-auto flex h-5 w-5 items-center justify-center rounded-full bg-blue-100 text-xs font-medium text-blue-600">
                      {pendingInvitations}
                    </span>
                  </Link>
                </DropdownMenuItem>
              </DropdownMenuGroup>
            </>
          )}
          
          <DropdownMenuSeparator />
          
          <DropdownMenuItem onClick={() => setCreateDialogOpen(true)}>
            <Plus className="h-4 w-4 mr-2" />
            Create New Team
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      {/* Invitations Badge */}
      {pendingInvitations > 0 && (
        <Link
          to="/settings"
          search={{ tab: 'teams', section: 'invitations' }}
          params={{}}
          className="flex items-center justify-between px-2 py-1.5 text-xs font-medium text-blue-700 bg-blue-50 rounded-md hover:bg-blue-100 transition-colors"
        >
          <div className="flex items-center">
            <Mail className="h-3.5 w-3.5 mr-1.5" />
            <span>Pending Invitations</span>
          </div>
          <span className="flex h-5 w-5 items-center justify-center rounded-full bg-blue-100 text-xs font-medium text-blue-600">
            {pendingInvitations}
          </span>
        </Link>
      )}

      <CreateTeamDialog 
        open={createDialogOpen} 
        onOpenChange={setCreateDialogOpen} 
      />
      
      <TeamInviteDialog 
        open={inviteDialogOpen} 
        onOpenChange={setInviteDialogOpen} 
      />
    </div>
  );
} 