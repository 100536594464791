import * as React from "react"
import { IMaskInput } from "react-imask"

import { cn } from "@/lib/utils"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"

export interface PhoneInputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange"> {
  label?: string
  error?: string
  value?: string
  onChange?: (value: string) => void
}

const PhoneInput = React.forwardRef<HTMLInputElement, PhoneInputProps>(
  ({ className, label, error, value = "", onChange, disabled, ...props }, ref) => {
    const inputRef = React.useRef<HTMLInputElement>(null)

    React.useImperativeHandle(ref, () => inputRef.current!)

    const handleAccept = (value: string) => {
      if (onChange) {
        const formattedValue = value ? `+${value}` : ""
        onChange(formattedValue)
      }
    }

    return (
      <div className="grid w-full gap-1.5">
        {label && <Label htmlFor={props.id}>{label}</Label>}
        <div className="relative">
          <IMaskInput
            mask="+1 (000) 000-0000"
            value={value.startsWith("+") ? value.slice(1) : value}
            unmask={true}
            onAccept={handleAccept}
            disabled={disabled}
            {...props}
            inputRef={inputRef}
            // @ts-ignore - IMaskInput types are not fully compatible with our usage
            component={Input}
            className={cn(
              "flex h-9 w-full rounded-md border border-input bg-transparent px-3 py-1 text-base shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 md:text-sm",
              error && "border-destructive focus-visible:ring-destructive",
              className
            )}
            type="tel"
          />
        </div>
        {error && (
          <p className="text-sm font-medium text-destructive">{error}</p>
        )}
      </div>
    )
  }
)

PhoneInput.displayName = "PhoneInput"

export { PhoneInput }