import { z } from 'zod';

/**
 * Team member role
 */
export type TeamRole = 'admin' | 'member';

export const teamRoleSchema = z.enum(['admin', 'member']);

/**
 * Team member
 */
export interface TeamMember {
  id: number;
  name: string;
  email: string;
  phone?: string | null;
  profilePhotoPath?: string | null;
  role: TeamRole;
}

export const teamMemberSchema = z.object({
  id: z.number(),
  name: z.string(),
  email: z.string().email(),
  phone: z.string().nullable().optional(),
  profilePhotoPath: z.string().nullable().optional(),
  role: teamRoleSchema
});

/**
 * Team invitation
 */
export interface TeamInvitation {
  id: number;
  teamId: number;
  teamName?: string;
  email: string;
  role: TeamRole;
  createdAt: string;
  updatedAt: string;
}

export const teamInvitationSchema = z.object({
  id: z.number(),
  teamId: z.number(),
  teamName: z.string().optional(),
  email: z.string().email(),
  role: teamRoleSchema,
  createdAt: z.string(),
  updatedAt: z.string()
});

/**
 * Team
 */
export interface Team {
  id: number;
  name: string;
  role: TeamRole;
  isPersonal: boolean;
  userId?: number;
  createdAt: string;
  updatedAt: string;
  members?: TeamMember[];
  pendingInvitations?: TeamInvitation[];
}

export const teamSchema = z.object({
  id: z.number(),
  name: z.string()
    .min(1, 'Team name is required')
    .max(50, 'Team name must be 50 characters or less')
    .regex(/^[a-zA-Z0-9\s-_]+$/, 'Team name can only contain letters, numbers, spaces, hyphens, and underscores'),
  role: teamRoleSchema,
  isPersonal: z.boolean(),
  userId: z.number().optional(),
  createdAt: z.string(),
  updatedAt: z.string(),
  members: z.array(teamMemberSchema).optional(),
  pendingInvitations: z.array(teamInvitationSchema).optional()
});

/**
 * Team creation request
 */
export interface CreateTeamRequest {
  name: string;
  isPersonal?: boolean;
}

export const createTeamSchema = z.object({
  name: z.string()
    .min(1, 'Team name is required')
    .max(50, 'Team name must be 50 characters or less')
    .regex(/^[a-zA-Z0-9\s-_]+$/, 'Team name can only contain letters, numbers, spaces, hyphens, and underscores'),
  isPersonal: z.boolean().optional()
});

/**
 * Team update request
 */
export interface UpdateTeamRequest {
  name: string;
}

export const updateTeamSchema = z.object({
  name: z.string()
    .min(1, 'Team name is required')
    .max(50, 'Team name must be 50 characters or less')
    .regex(/^[a-zA-Z0-9\s-_]+$/, 'Team name can only contain letters, numbers, spaces, hyphens, and underscores')
});

/**
 * Team invitation request
 */
export interface InviteToTeamRequest {
  email: string;
  role?: TeamRole;
}

export const inviteToTeamSchema = z.object({
  email: z.string().email('Invalid email address'),
  role: teamRoleSchema.optional().default('member')
}); 