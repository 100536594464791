import { HelpCenterPage } from '@/pages/HelpCenterPage';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/help-center')({
  component: HelpCenterPage,
  validateSearch: (search: Record<string, unknown>) => {
    return {
      tab: search.tab as string | undefined,
      article: search.article as string | undefined,
    };
  }
}); 