import { useAuth } from '@/hooks/useAuth';
import { ConnectionStatus, RoomType, SocketEvents, WebSocketService } from '@/services/websocket.service';
import { useAuthStore } from '@/store/authStore';
import { createContext, ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'sonner';

// Room subscription interface
interface RoomSubscription {
  roomType: RoomType;
  roomId: string | number;
  timestamp: number;
}

// WebSocket context type
interface WebSocketContextType {
  connectionStatus: ConnectionStatus;
  connectionError: string | null;
  onlineUsers: Map<number, { name: string, timestamp: string }>;
  activeLeadViewers: Map<number, Map<number, { userName: string, timestamp: string }>>;
  activeRooms: Map<string, RoomSubscription>;
  connect: () => void;
  disconnect: () => void;
  viewLead: (leadId: number) => void;
  sendTypingIndicator: (conversationId: number, isTyping: boolean) => void;
  joinRoom: (roomType: RoomType, roomId: string | number) => void;
  leaveRoom: (roomType: RoomType, roomId: string | number) => void;
  on: <T>(event: string, callback: (data: T) => void) => () => void;
  emit: (event: string, data: any) => void;
}

// Create context with default values
const WebSocketContext = createContext<WebSocketContextType>({
  connectionStatus: ConnectionStatus.DISCONNECTED,
  connectionError: null,
  onlineUsers: new Map(),
  activeLeadViewers: new Map(),
  activeRooms: new Map(),
  connect: () => {},
  disconnect: () => {},
  viewLead: () => {},
  sendTypingIndicator: () => {},
  joinRoom: () => {},
  leaveRoom: () => {},
  on: () => () => {},
  emit: () => {}
});

// Provider props
interface WebSocketProviderProps {
  children: ReactNode;
}

// WebSocket provider component
export function WebSocketProvider({ children }: WebSocketProviderProps) {
  const websocketService = WebSocketService.getInstance();
  const { user, token, isAuthenticated } = useAuth();
  const authStore = useAuthStore();
  const [connectionStatus, setConnectionStatus] = useState<ConnectionStatus>(websocketService.getConnectionStatus());
  const [connectionError, setConnectionError] = useState<string | null>(null);
  const [lastConnected, setLastConnected] = useState<Date | null>(null);
  const [onlineUsers, setOnlineUsers] = useState<Map<number, { name: string, timestamp: string }>>(new Map());
  const [activeLeadViewers, setActiveLeadViewers] = useState<Map<number, Map<number, { userName: string, timestamp: string }>>>(new Map());
  const [activeRooms, setActiveRooms] = useState<Map<string, RoomSubscription>>(new Map());
  const [connectionAttempts, setConnectionAttempts] = useState<number>(0);
  const MAX_CONNECTION_ATTEMPTS = 5;
  const CONNECTION_RETRY_DELAY = 5000; // 5 seconds
  
  // Refs to track notification state
  const lastNotificationTimeRef = useRef<number>(0);
  const lastConnectionStatusRef = useRef<ConnectionStatus>(ConnectionStatus.DISCONNECTED);
  const isInitialMountRef = useRef<boolean>(true);
  const NOTIFICATION_DEBOUNCE_MS = 3000; // 3 seconds debounce for notifications

  // Check if current route is a public route (landing page or auth)
  const isPublicRoute = () => {
    // We can check the current path directly from window.location
    const currentPath = window.location.pathname;
    return currentPath === '/' || currentPath === '/auth' || currentPath === '/landing';
  };

  // Setup event listeners
  const setupEventListeners = () => {
    console.log('[WebSocketContext] Setting up WebSocket event listeners');

    // Listen for connection status changes
    websocketService.on<{ status: ConnectionStatus; error?: string }>(
      SocketEvents.CONNECTION_STATUS,
      (data) => {
        console.log(`[WebSocketContext] Connection status changed: ${data.status}`, data.error || '');
        setConnectionStatus(data.status);
        setConnectionError(data.error || null);

        // If we just connected, update the last connected time
        if (data.status === ConnectionStatus.CONNECTED) {
          setLastConnected(new Date());
          setConnectionAttempts(0); // Reset connection attempts on successful connection
          
          // Only show toast if this is not the initial mount and if we weren't already connected
          const now = Date.now();
          if (!isInitialMountRef.current && 
              lastConnectionStatusRef.current !== ConnectionStatus.CONNECTED && 
              now - lastNotificationTimeRef.current > NOTIFICATION_DEBOUNCE_MS) {
            toast.success('Connected to real-time server');
            lastNotificationTimeRef.current = now;
          }
        } else if (data.status === ConnectionStatus.ERROR) {
          // Increment connection attempts on error
          setConnectionAttempts(prev => prev + 1);
        }
        
        // Update the last connection status
        lastConnectionStatusRef.current = data.status;
      }
    );

    // Listen for user online events
    websocketService.on<{
      userId: number;
      name: string;
      timestamp: string;
    }>(
      SocketEvents.USER_ONLINE,
      (data) => {
        console.log(`[WebSocketContext] User online: ${data.name} (${data.userId})`);
        setOnlineUsers((prev) => {
          const newMap = new Map(prev);
          newMap.set(data.userId, { name: data.name, timestamp: data.timestamp });
          return newMap;
        });
      }
    );

    // Listen for user offline events
    websocketService.on<{
      userId: number;
      timestamp: string;
    }>(
      SocketEvents.USER_OFFLINE,
      (data) => {
        console.log(`[WebSocketContext] User offline: ${data.userId}`);
        setOnlineUsers((prev) => {
          const newMap = new Map(prev);
          newMap.delete(data.userId);
          return newMap;
        });
      }
    );

    // Listen for lead viewed events
    websocketService.on<{
      userId: number;
      userName: string;
      leadId: number;
      timestamp: string;
    }>(
      SocketEvents.LEAD_VIEWED,
      (data) => {
        console.log(`[WebSocketContext] Lead viewed: ${data.leadId} by ${data.userName} (${data.userId})`);
        setActiveLeadViewers((prev) => {
          const newMap = new Map(prev);
          
          // Get or create the map for this lead
          const leadViewers = newMap.get(data.leadId) || new Map();
          
          // Add this user to the lead viewers
          leadViewers.set(data.userId, { 
            userName: data.userName, 
            timestamp: data.timestamp 
          });
          
          // Update the map
          newMap.set(data.leadId, leadViewers);
          
          return newMap;
        });
      }
    );
    
    // Listen for room joined confirmations
    websocketService.on<{
      roomType: RoomType;
      roomId: string | number;
    }>(
      SocketEvents.ROOM_JOINED,
      (data) => {
        console.log(`[WebSocketContext] Joined room: ${data.roomType}:${data.roomId}`);
        
        // Update active rooms
        setActiveRooms((prev) => {
          const newMap = new Map(prev);
          const roomKey = `${data.roomType}:${data.roomId}`;
          
          newMap.set(roomKey, {
            roomType: data.roomType,
            roomId: data.roomId,
            timestamp: Date.now()
          });
          
          return newMap;
        });
      }
    );
    
    // Listen for room left confirmations
    websocketService.on<{
      roomType: RoomType;
      roomId: string | number;
    }>(
      SocketEvents.ROOM_LEFT,
      (data) => {
        console.log(`[WebSocketContext] Left room: ${data.roomType}:${data.roomId}`);
        
        // Update active rooms
        setActiveRooms((prev) => {
          const newMap = new Map(prev);
          const roomKey = `${data.roomType}:${data.roomId}`;
          
          newMap.delete(roomKey);
          
          return newMap;
        });
      }
    );
  };

  // Initialize event listeners
  useEffect(() => {
    console.log('[WebSocketContext] Initializing event listeners');
    setupEventListeners();
    
    // After first render, set isInitialMount to false
    isInitialMountRef.current = false;
    
    // Cleanup on unmount
    return () => {
      console.log('[WebSocketContext] Cleaning up WebSocket connection on unmount');
      websocketService.disconnect();
    };
  }, []);

  // Handle authentication state changes
  useEffect(() => {
    console.log('[WebSocketContext] Auth state changed');
    console.log('[WebSocketContext] User authenticated:', isAuthenticated);
    console.log('[WebSocketContext] Current path:', window.location.pathname);
    console.log('[WebSocketContext] Is public route:', isPublicRoute());
    
    // Check token from both auth store and localStorage
    const storeToken = authStore.token;
    const localStorageToken = localStorage.getItem('auth_token');
    const effectiveToken = token || storeToken || localStorageToken;
    
    console.log('[WebSocketContext] Token from useAuth:', token ? `${token.substring(0, 15)}...` : 'null');
    console.log('[WebSocketContext] Token from authStore:', storeToken ? `${storeToken.substring(0, 15)}...` : 'null');
    console.log('[WebSocketContext] Token from localStorage:', localStorageToken ? `${localStorageToken.substring(0, 15)}...` : 'null');
    console.log('[WebSocketContext] Effective token available:', !!effectiveToken);
    console.log('[WebSocketContext] User available:', !!user);
    
    // If we have a token but not in the auth store, update it
    if (localStorageToken && !storeToken) {
      console.log('[WebSocketContext] Updating auth store with token from localStorage');
      authStore.setToken(localStorageToken);
    }
    
    // Only connect if authenticated AND not on a public route
    if (effectiveToken && !isPublicRoute()) {
      console.log('[WebSocketContext] Token available and on authenticated route, connecting to WebSocket server');
      websocketService.connect(effectiveToken);
    } else {
      if (!effectiveToken) {
        console.log('[WebSocketContext] No token available, not connecting to WebSocket server');
      } else if (isPublicRoute()) {
        console.log('[WebSocketContext] On public route, not connecting to WebSocket server');
      }
      websocketService.disconnect();
    }
  }, [isAuthenticated, token, authStore.token]);

  // Also listen for location changes to handle route changes
  useEffect(() => {
    const handleLocationChange = () => {
      console.log('[WebSocketContext] Location changed:', window.location.pathname);
      console.log('[WebSocketContext] Is public route:', isPublicRoute());
      
      const storeToken = authStore.token;
      
      // Only connect if authenticated AND not on a public route
      if (storeToken && !isPublicRoute()) {
        console.log('[WebSocketContext] Token available and on authenticated route, connecting to WebSocket server');
        websocketService.connect(storeToken);
      } else if (storeToken && isPublicRoute()) {
        console.log('[WebSocketContext] On public route, disconnecting from WebSocket server');
        websocketService.disconnect();
      }
    };
    
    // Listen for popstate events (browser back/forward)
    window.addEventListener('popstate', handleLocationChange);
    
    return () => {
      window.removeEventListener('popstate', handleLocationChange);
    };
  }, [authStore.token]);

  // Retry connection if in error state
  useEffect(() => {
    let retryTimeout: NodeJS.Timeout | null = null;
    
    if (connectionStatus === ConnectionStatus.ERROR && connectionAttempts < MAX_CONNECTION_ATTEMPTS) {
      console.log(`[WebSocketContext] Connection in error state, retrying in ${CONNECTION_RETRY_DELAY}ms (attempt ${connectionAttempts + 1}/${MAX_CONNECTION_ATTEMPTS})`);
      
      retryTimeout = setTimeout(() => {
        console.log('[WebSocketContext] Retrying connection...');
        connect();
      }, CONNECTION_RETRY_DELAY);
    }
    
    return () => {
      if (retryTimeout) {
        clearTimeout(retryTimeout);
      }
    };
  }, [connectionStatus, connectionAttempts]);

  // Connect to WebSocket server
  const connect = () => {
    console.log('[WebSocketContext] Manual connect requested');
    
    // Check token from both auth store and localStorage
    const storeToken = authStore.token;
    const localStorageToken = localStorage.getItem('auth_token');
    const effectiveToken = token || storeToken || localStorageToken;
    
    if (!effectiveToken) {
      console.error('[WebSocketContext] Cannot connect: No authentication token available');
      setConnectionStatus(ConnectionStatus.ERROR);
      setConnectionError('No authentication token available');
      
      const now = Date.now();
      if (now - lastNotificationTimeRef.current > NOTIFICATION_DEBOUNCE_MS) {
        toast.error('Connection error', {
          description: 'No authentication token available'
        });
        lastNotificationTimeRef.current = now;
      }
      return;
    }
    
    console.log('[WebSocketContext] Connecting with token:', effectiveToken ? `${effectiveToken.substring(0, 15)}...` : 'null');
    console.log('[WebSocketContext] Token length:', effectiveToken?.length || 0);
    
    websocketService.connect(effectiveToken);
  };
  
  // Disconnect from WebSocket
  const disconnect = () => {
    try {
      websocketService.disconnect();
    } catch (error) {
      console.error('Error disconnecting from WebSocket:', error);
    }
  };
  
  // Join a room
  const joinRoom = (roomType: RoomType, roomId: string | number) => {
    if (connectionStatus !== ConnectionStatus.CONNECTED) {
      console.warn(`[WebSocketContext] Cannot join room ${roomType}:${roomId}: Not connected (status: ${connectionStatus})`);
      return;
    }
    
    websocketService.joinRoom(roomType, roomId);
  };
  
  // Leave a room
  const leaveRoom = (roomType: RoomType, roomId: string | number) => {
    if (connectionStatus !== ConnectionStatus.CONNECTED) {
      console.warn(`[WebSocketContext] Cannot leave room ${roomType}:${roomId}: Not connected (status: ${connectionStatus})`);
      return;
    }
    
    websocketService.leaveRoom(roomType, roomId);
  };
  
  // Notify that user is viewing a lead
  const viewLead = (leadId: number) => {
    if (connectionStatus !== ConnectionStatus.CONNECTED) {
      console.warn(`[WebSocketContext] Cannot view lead ${leadId}: Not connected (status: ${connectionStatus})`);
      return;
    }
    
    websocketService.viewLead(leadId);
  };
  
  // Send typing indicator for SMS
  const sendTypingIndicator = (conversationId: number, isTyping: boolean) => {
    if (connectionStatus !== ConnectionStatus.CONNECTED) {
      console.warn(`[WebSocketContext] Cannot send typing indicator: Not connected (status: ${connectionStatus})`);
      return;
    }
    
    websocketService.sendTypingIndicator(conversationId, isTyping);
  };
  
  // Subscribe to an event - now handles connection status gracefully
  const on = <T,>(event: string, callback: (data: T) => void) => {
    // Special handling for connection status to provide immediate feedback
    if (event === SocketEvents.CONNECTION_STATUS && connectionStatus === ConnectionStatus.ERROR) {
      // Immediately notify with current status
      setTimeout(() => {
        callback({ status: connectionStatus, error: connectionError || 'Not connected' } as unknown as T);
      }, 0);
    }
    
    return websocketService.on(event, callback);
  };
  
  // Emit an event - now checks connection status instead of authentication
  const emit = (event: string, data: any) => {
    // Allow queuing events even when not connected
    websocketService.emit(event, data);
  };
  
  // Context value
  const value: WebSocketContextType = {
    connectionStatus,
    connectionError,
    onlineUsers,
    activeLeadViewers,
    activeRooms,
    connect,
    disconnect,
    viewLead,
    sendTypingIndicator,
    joinRoom,
    leaveRoom,
    on,
    emit
  };
  
  return (
    <WebSocketContext.Provider value={value}>
      {children}
    </WebSocketContext.Provider>
  );
}

// Custom hook to use WebSocket context
export function useWebSocket() {
  const context = useContext(WebSocketContext);
  
  if (!context) {
    throw new Error('useWebSocket must be used within a WebSocketProvider');
  }
  
  return context;
} 