import { Product } from './types';

/**
 * Format price from cents to currency format
 */
export function formatPrice(amount: number, currency: string): string {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
  }).format(amount / 100);
}

/**
 * Format date from timestamp
 */
export function formatDate(timestamp: number): string {
  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }).format(new Date(timestamp * 1000));
}

/**
 * Parse features from product metadata
 */
export function getFeatures(product: Product): string[] {
  // If product has features in metadata as a string
  if (product.metadata?.features) {
    return product.metadata.features.split(',').map(feature => feature.trim());
  }
  
  // If product has features as an array (from Stripe)
  if (Array.isArray(product.features)) {
    return product.features;
  }
  
  return [];
}

/**
 * Check if product is marked as popular
 */
export function isPopular(product: Product): boolean {
  return product.metadata?.popular === 'true';
}

/**
 * Check if product is marked as coming soon
 */
export function isComingSoon(product: Product): boolean {
  // If it's a real Stripe product with a valid price ID, it's not coming soon
  if (product.default_price && product.default_price.startsWith('price_')) {
    return false;
  }
  
  // Otherwise, check the metadata
  return product.metadata?.comingSoon === 'true';
}

/**
 * Determine if a plan is the current plan
 */
export function isCurrentPlan(product: Product, currentSubscription: any): boolean {
  if (!currentSubscription) return false;
  
  // Check if the subscription's plan matches this product's price ID
  return currentSubscription.stripePlan === product.default_price;
}

/**
 * Determine if a plan is an upgrade, downgrade, or current
 */
export function getPlanAction(product: Product, currentSubscription: any, allProducts: Product[]): 'current' | 'upgrade' | 'downgrade' | 'subscribe' {
  if (isCurrentPlan(product, currentSubscription)) return 'current';
  
  if (!currentSubscription) return 'subscribe'; // No current plan, so it's a new subscription
  
  // Get the current plan's price
  const currentPlanProduct = allProducts.find(p => p.default_price === currentSubscription.stripePlan);
  const currentPrice = currentPlanProduct?.priceData?.unit_amount || 0;
  
  // Get this plan's price
  const thisPrice = product.priceData?.unit_amount || 0;
  
  // Compare prices to determine if it's an upgrade or downgrade
  if (thisPrice > currentPrice) return 'upgrade';
  if (thisPrice < currentPrice) return 'downgrade';
  
  return 'subscribe'; // Same price or can't determine
} 