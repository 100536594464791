import { VoiceCall } from '../VoiceCall';

interface VoiceCallTabProps {
  phoneNumber: string;
  fromNumber: string | null;
  contactName: string;
  leadId?: number | null;
  isMobile?: boolean;
}

export function VoiceCallTab({
  phoneNumber,
  fromNumber,
  contactName,
  leadId,
  isMobile = false
}: VoiceCallTabProps) {
  return (
    <div className={`flex flex-col items-center justify-center space-y-4 text-gray-600 dark:text-gray-400 ${isMobile ? 'overflow-auto' : ''}`}>
      <div className="text-center space-y-2 max-w-sm">
        <h3 className="font-semibold text-gray-900 dark:text-gray-100">AI Assistant</h3>
        <p className="text-sm">
          Connect with {contactName} while our AI analyzes your conversation, providing insights and lead scoring to help prioritize your follow-ups.
        </p>
      </div>
      <VoiceCall 
        phoneNumber={phoneNumber} 
        fromNumber={fromNumber} 
        contactName={contactName}
        leadId={leadId}
      />
    </div>
  );
} 