import { axiosInstance } from '@/lib/api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';

interface TeamInvitation {
  id: number;
  teamId: number;
  teamName: string;
  email: string;
  role: string;
  createdAt: string;
}

export function useTeamInvitations() {
  const queryClient = useQueryClient();

  const { 
    data: invitations = [], 
    isLoading, 
    error,
    refetch
  } = useQuery<TeamInvitation[]>({
    queryKey: ['teams', 'invitations'],
    queryFn: async () => {
      try {
        const response = await axiosInstance.get('/teams/invitations');
        return response.data.invitations || [];
      } catch (error) {
        console.error('Error fetching team invitations:', error);
        return [];
      }
    },
    // Remove polling and rely on WebSocket events
    // The WebSocketDataSync component will invalidate this query when needed
    staleTime: 5 * 60 * 1000, // 5 minutes
  });

  const acceptInvitationMutation = useMutation({
    mutationFn: async (invitationId: number) => {
      const response = await axiosInstance.post(`/teams/invitations/${invitationId}/accept`);
      return response.data;
    },
    onSuccess: (data, invitationId) => {
      queryClient.invalidateQueries({ queryKey: ['teams', 'invitations'] });
      queryClient.invalidateQueries({ queryKey: ['teams'] });
      
      const invitation = invitations.find((inv: TeamInvitation) => inv.id === invitationId);
      if (invitation) {
        toast.success(`You've joined ${invitation.teamName}!`, {
          description: 'You are now a member of this team.'
        });
      } else {
        toast.success('You have joined the team!');
      }
    },
    onError: (error: any) => {
      toast.error('Failed to accept invitation', {
        description: error.response?.data?.message || 'An error occurred'
      });
    }
  });

  const declineInvitationMutation = useMutation({
    mutationFn: async (invitationId: number) => {
      const response = await axiosInstance.post(`/teams/invitations/${invitationId}/decline`);
      return response.data;
    },
    onSuccess: (data, invitationId) => {
      queryClient.invalidateQueries({ queryKey: ['teams', 'invitations'] });
      
      const invitation = invitations.find((inv: TeamInvitation) => inv.id === invitationId);
      if (invitation) {
        toast.success(`Declined invitation from ${invitation.teamName}`);
      } else {
        toast.success('Invitation declined');
      }
    },
    onError: (error: any) => {
      toast.error('Failed to decline invitation', {
        description: error.response?.data?.message || 'An error occurred'
      });
    }
  });

  const acceptInvitation = (invitationId: number) => {
    acceptInvitationMutation.mutate(invitationId);
  };

  const declineInvitation = (invitationId: number) => {
    declineInvitationMutation.mutate(invitationId);
  };

  // Function to manually check for new invitations
  const checkForNewInvitations = () => {
    refetch();
  };

  return {
    invitations,
    isLoading,
    error,
    acceptInvitation,
    declineInvitation,
    isAccepting: acceptInvitationMutation.isPending,
    isDeclining: declineInvitationMutation.isPending,
    checkForNewInvitations,
  };
} 