import { ArticleLayout } from "@/components/help/ArticleLayout";

export function AnalyticsArticle() {
  return (
    <ArticleLayout>
      <h1 className="text-2xl font-bold mb-4 mt-0">Analytics</h1>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Understanding Your Performance Metrics</h2>
      <p className="mb-4">
        LeadGPT's Metrics Dashboard provides real-time insights into your sales performance, communication activity, and conversion metrics. This guide will help you understand how to use the dashboard effectively to make data-driven decisions and optimize your sales process.
      </p>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Accessing Analytics</h2>
      <p className="mb-4">
        To access the Metrics Dashboard, click on the "Metrics" option in the sidebar navigation. The dashboard provides a comprehensive overview of your key performance indicators, with options to view either your personal metrics or team-wide performance data.
      </p>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Dashboard Overview</h2>
      <p className="mb-4">
        The Metrics Dashboard is divided into several key sections:
      </p>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Switching Between Personal and Team Views</h3>
      <p className="mb-4">
        At the top of the dashboard, you can toggle between two main views:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Personal:</strong> View your individual performance metrics</li>
        <li className="mb-1"><strong>Team:</strong> View aggregated team metrics and performance rankings</li>
      </ul>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Key Performance Cards</h3>
      <p className="mb-4">
        The top section of the dashboard displays a set of key performance metric cards, providing a quick overview of:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Total Leads:</strong> Number of leads created during the selected period</li>
        <li className="mb-1"><strong>Leads Contacted:</strong> Number of leads you've reached out to</li>
        <li className="mb-1"><strong>Calls Made:</strong> Total outbound calls during the period</li>
        <li className="mb-1"><strong>Messages Sent:</strong> Total SMS and email messages sent</li>
        <li className="mb-1"><strong>Average Response Time:</strong> How quickly you respond to lead inquiries</li>
        <li className="mb-1"><strong>Conversion Rate:</strong> Percentage of leads that converted to customers</li>
        <li className="mb-1"><strong>Call Answer Rate:</strong> Percentage of calls that were answered</li>
        <li className="mb-1"><strong>SMS Response Rate:</strong> Percentage of text messages that received a response</li>
      </ul>
      <p className="mb-4">
        Each card shows the current value, plus a percentage change compared to the previous period, with visual indicators for positive or negative trends.
      </p>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Metric Charts</h2>
      <p className="mb-4">
        The dashboard features interactive charts that visualize your performance data. You can switch between different metric categories using the tabs above the chart:
      </p>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Leads Metrics</h3>
      <p className="mb-4">
        The Leads tab displays charts showing:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Leads Created:</strong> Number of new leads added to your system</li>
        <li className="mb-1"><strong>Leads Contacted:</strong> Number of leads you've reached out to</li>
      </ul>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Conversion Metrics</h3>
      <p className="mb-4">
        The Conversion tab displays metrics related to success rates:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Conversion Rate:</strong> Percentage of leads that convert to customers</li>
        <li className="mb-1"><strong>SMS Response Rate:</strong> Percentage of text messages that received a response</li>
        <li className="mb-1"><strong>Call Answer Rate:</strong> Percentage of calls that were answered</li>
      </ul>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Communication Metrics</h3>
      <p className="mb-4">
        The Communication tab shows your outreach activity:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Messages Sent:</strong> Number of text messages and emails sent</li>
        <li className="mb-1"><strong>Calls Made:</strong> Number of outbound calls placed</li>
      </ul>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Response Time Analysis</h3>
      <p className="mb-4">
        The Response Time tab provides detailed analysis of your response speed:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Average Response Time:</strong> How quickly you respond to lead inquiries</li>
        <li className="mb-1"><strong>Trend Line:</strong> Visual representation of changes in response time</li>
        <li className="mb-1"><strong>Period Average:</strong> Reference line showing your average for the entire period</li>
      </ul>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Team Performance Rankings</h2>
      <p className="mb-4">
        When viewing team metrics, the dashboard includes a leaderboard section showing:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Team Member Rankings:</strong> See how team members compare across different metrics</li>
        <li className="mb-1"><strong>Selectable Metrics:</strong> Choose which performance indicator to rank by</li>
        <li className="mb-1"><strong>Performance Values:</strong> View actual metrics for each team member</li>
      </ul>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Filtering and Time Frame Selection</h2>
      <p className="mb-4">
        The dashboard includes powerful filtering options to analyze specific segments of your data:
      </p>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Date Range Selection</h3>
      <p className="mb-4">
        Select the time period for your analysis:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Today:</strong> Show data for the current day</li>
        <li className="mb-1"><strong>Yesterday:</strong> Show data for the previous day</li>
        <li className="mb-1"><strong>Last 7 days:</strong> Show data for the previous week</li>
        <li className="mb-1"><strong>Last 14 days:</strong> Show data for the last two weeks</li>
        <li className="mb-1"><strong>Last 30 days:</strong> Show data for the last month</li>
        <li className="mb-1"><strong>This week:</strong> Show data for the current week</li>
        <li className="mb-1"><strong>Last week:</strong> Show data for the previous week</li>
        <li className="mb-1"><strong>This month:</strong> Show data for the current month</li>
        <li className="mb-1"><strong>Last month:</strong> Show data for the previous month</li>
        <li className="mb-1"><strong>This year:</strong> Show data from the beginning of the year</li>
        <li className="mb-1"><strong>Custom Range:</strong> Select a specific date range using the date picker</li>
      </ul>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Timeframe Granularity</h3>
      <p className="mb-4">
        Choose how to aggregate your data:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Daily:</strong> View metrics broken down by day</li>
        <li className="mb-1"><strong>Weekly:</strong> View metrics aggregated by week</li>
        <li className="mb-1"><strong>Monthly:</strong> View metrics aggregated by month</li>
      </ul>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Metrics Filter</h3>
      <p className="mb-4">
        Customize which metrics are included in your analysis:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1">Click the filter icon in the header section</li>
        <li className="mb-1">Check or uncheck specific metrics to include or exclude</li>
        <li className="mb-1">Apply your selections to update the dashboard</li>
      </ul>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Refreshing Data</h2>
      <p className="mb-4">
        To ensure you're viewing the most current information:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1">Click the "Refresh" button in the dashboard header</li>
        <li className="mb-1">Data will be updated in real-time from the server</li>
        <li className="mb-1">The refresh icon shows a spinning animation while data is being fetched</li>
      </ul>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Chart Interactions</h2>
      <p className="mb-4">
        The dashboard charts are interactive and offer several ways to explore your data:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Hover:</strong> Hover over data points to see exact values</li>
        <li className="mb-1"><strong>Compare:</strong> View multiple metrics side-by-side in the same chart</li>
        <li className="mb-1"><strong>Legend:</strong> Click items in the chart legend to hide or show specific metrics</li>
      </ul>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Understanding Metrics Calculations</h2>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Count Metrics</h3>
      <p className="mb-4">
        Count metrics such as leads created, calls made, and messages sent represent the total number of those activities during the selected period.
      </p>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Rate Metrics</h3>
      <p className="mb-4">
        Rate metrics are displayed as percentages:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Conversion Rate:</strong> (Converted Leads ÷ Total Leads) × 100</li>
        <li className="mb-1"><strong>Call Answer Rate:</strong> (Answered Calls ÷ Total Calls) × 100</li>
        <li className="mb-1"><strong>SMS Response Rate:</strong> (Replied Messages ÷ Total Messages Sent) × 100</li>
      </ul>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Time Metrics</h3>
      <p className="mb-4">
        Response time is measured in seconds but is displayed in a human-readable format (e.g., "2m 45s" for 165 seconds). In charts, response time is converted to minutes for easier visualization.
      </p>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Using Analytics to Improve Performance</h2>
      <p className="mb-4">
        Here are some ways to leverage your metrics data:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Identify Trends:</strong> Look for patterns in your metrics over time</li>
        <li className="mb-1"><strong>Set Goals:</strong> Use current performance as a baseline for improvement targets</li>
        <li className="mb-1"><strong>Compare Performance:</strong> See how you rank against other team members</li>
        <li className="mb-1"><strong>Optimize Response Time:</strong> If your response time is increasing, consider setting up quick reply templates</li>
        <li className="mb-1"><strong>Increase Contact Rate:</strong> If leads contacted is low relative to leads created, consider automating initial outreach</li>
        <li className="mb-1"><strong>Improve Conversion:</strong> If conversion rates are declining, review your sales approach and messaging</li>
      </ul>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Analytics Best Practices</h3>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1">Review your metrics dashboard at least weekly</li>
        <li className="mb-1">Focus on trends rather than isolated data points</li>
        <li className="mb-1">Compare current performance to historical data using different time periods</li>
        <li className="mb-1">Use the team leaderboard for friendly competition to boost performance</li>
        <li className="mb-1">Take specific actions based on metrics insights rather than just observing changes</li>
      </ul>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Troubleshooting and FAQs</h2>
      
      <h3 className="text-lg font-bold mt-6 mb-3">What if my data appears incomplete?</h3>
      <p className="mb-4">
        Metrics data is updated in near real-time, but there may be a slight delay before new activities appear. Use the Refresh button to fetch the latest data from the server.
      </p>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Why do I see "N/A" for some metrics?</h3>
      <p className="mb-4">
        "N/A" appears when there isn't sufficient data to calculate a metric. For example, call answer rate will show as N/A if no calls were made during the selected period.
      </p>
      
      <h3 className="text-lg font-bold mt-6 mb-3">How are percentage changes calculated?</h3>
      <p className="mb-4">
        Percentage changes show the difference between the current period's value and the previous period's value. For example, if you made 10 calls last week and 15 calls this week, the percentage change would be +50%.
      </p>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Can I export the metrics data?</h3>
      <p className="mb-4">
        While the dashboard doesn't currently have a built-in export function, you can capture screenshots of the charts and metrics for sharing or record-keeping purposes.
      </p>
      
      <p className="mb-4 mt-8">
        For more information on lead management and how it relates to the metrics shown in the dashboard, please refer to the Lead Management guide.
      </p>
    </ArticleLayout>
  );
} 