import { Lead } from '@/types';
import { LeadDetailsPane as ModularLeadDetailsPane } from './lead-details';

interface LeadDetailsPaneProps {
  lead: Lead | null;
  isLoading: boolean;
  selectedLeadId: number | null;
  setSelectedLeadId: (id: number | null) => void;
}

export function LeadDetailsPane(props: LeadDetailsPaneProps) {
  return <ModularLeadDetailsPane {...props} />;
} 