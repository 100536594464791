import { CalendarDashboard } from "@/components/calendar/CalendarDashboard";
import { MainLayout } from "@/components/layout/MainLayout";
import { PageContainer } from "@/components/layout/PageContainer";
import { PageHeader } from "@/components/ui/page-header";

export function CalendarDashboardPage() {
  return (
    <MainLayout pageTitle="Calendar Dashboard">
      <PageContainer className="bg-white">
        <div className="container py-6 space-y-6">
          <PageHeader
            title="Calendar Dashboard"
            description="Manage your calendar events and integrate with leads"
          />
          <CalendarDashboard />
        </div>
      </PageContainer>
    </MainLayout>
  );
} 