import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { cn } from '@/lib/utils';
import { CallService } from '@/services/call.service';
import { Hash, Mic, MicOff, PhoneOff, SignalHigh, SignalLow, SignalMedium, Volume2, VolumeX } from 'lucide-react';
import { useEffect, useState } from 'react';
import { toast } from 'sonner';
import { DialPad } from './DialPad';

interface ActiveCallProps {
  call: {
    id: number;
    status: 'connecting' | 'ringing' | 'in-progress' | 'completed' | 'failed';
    direction: 'inbound' | 'outbound';
    contact: {
      name: string;
      phoneNumber: string;
    };
  };
  onHangup: () => void;
}

export function ActiveCall({ call, onHangup }: ActiveCallProps) {
  const [duration, setDuration] = useState(0);
  const [isMuted, setIsMuted] = useState(false);
  const [isAudioEnabled, setIsAudioEnabled] = useState(true);
  const [signalStrength, setSignalStrength] = useState<'high' | 'medium' | 'low'>('high');
  const [isDialPadOpen, setIsDialPadOpen] = useState(false);

  // Check initial mute status
  useEffect(() => {
    if (call.status === 'in-progress') {
      const muteStatus = CallService.isMuted();
      if (muteStatus !== null) {
        setIsMuted(muteStatus);
      }
    }
  }, [call.status]);

  // Handle call duration timer
  useEffect(() => {
    if (call.status === 'in-progress') {
      const timer = setInterval(() => {
        setDuration(prev => prev + 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [call.status]);

  // Format duration as mm:ss
  const formatDuration = (seconds: number) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs.toString().padStart(2, '0')}`;
  };

  // Get status text
  const getStatusText = () => {
    switch (call.status) {
      case 'connecting':
        return 'Connecting...';
      case 'ringing':
        return call.direction === 'outbound' ? 'Ringing...' : 'Incoming Call';
      case 'in-progress':
        return formatDuration(duration);
      case 'completed':
        return 'Call Ended';
      case 'failed':
        return 'Call Failed';
      default:
        return '';
    }
  };

  // Handle mute toggle
  const handleMuteToggle = () => {
    if (isMuted) {
      // Unmute
      const success = CallService.unmuteCall();
      if (success) {
        setIsMuted(false);
        toast.success('Microphone unmuted');
      } else {
        // Try a fallback method - we'll modify our local state even if the service fails
        console.warn('[ActiveCall] Failed to unmute using service, trying fallback');
        
        // Try to find audio element
        const audioElement = document.querySelector('audio');
        if (audioElement) {
          try {
            audioElement.muted = false;
            setIsMuted(false);
            toast.success('Microphone unmuted (fallback)');
          } catch (err) {
            console.error('[ActiveCall] Fallback unmute failed:', err);
            toast.error('Failed to unmute microphone');
          }
        } else {
          toast.error('Failed to unmute microphone');
        }
      }
    } else {
      // Mute
      const success = CallService.muteCall();
      if (success) {
        setIsMuted(true);
        toast.success('Microphone muted');
      } else {
        // Try a fallback method - we'll modify our local state even if the service fails
        console.warn('[ActiveCall] Failed to mute using service, trying fallback');
        
        // Try to find audio element
        const audioElement = document.querySelector('audio');
        if (audioElement) {
          try {
            audioElement.muted = true;
            setIsMuted(true);
            toast.success('Microphone muted (fallback)');
          } catch (err) {
            console.error('[ActiveCall] Fallback mute failed:', err);
            toast.error('Failed to mute microphone');
          }
        } else {
          toast.error('Failed to mute microphone');
        }
      }
    }
  };

  // Handle speaker toggle
  const handleSpeakerToggle = () => {
    const newAudioState = !isAudioEnabled;
    const success = CallService.setSpeaker(newAudioState);
    
    if (success) {
      setIsAudioEnabled(newAudioState);
      toast.success(newAudioState ? 'Speaker enabled' : 'Speaker disabled');
    } else {
      toast.error(newAudioState ? 'Failed to enable speaker' : 'Failed to disable speaker');
    }
  };

  // Toggle dial pad
  const toggleDialPad = () => {
    setIsDialPadOpen(prev => !prev);
  };

  // Get signal strength icon
  const SignalIcon = {
    high: SignalHigh,
    medium: SignalMedium,
    low: SignalLow
  }[signalStrength];

  return (
    <Card className="relative overflow-hidden">
      {/* Status Banner */}
      <div className="absolute top-0 left-0 right-0 h-2 bg-primary/10">
        <div 
          className={cn(
            "h-full bg-primary transition-all duration-700",
            call.status === 'connecting' && "animate-pulse w-3/5",
            call.status === 'ringing' && "animate-pulse w-4/5",
            call.status === 'in-progress' && "w-full",
            call.status === 'completed' && "w-full bg-green-500",
            call.status === 'failed' && "w-full bg-destructive"
          )}
        />
      </div>

      {/* Main Content */}
      <div className="p-6 pt-8">
        {/* Contact Info */}
        <div className="text-center mb-6">
          <h2 className="text-xl font-semibold">{call.contact.name}</h2>
          <p className="text-muted-foreground">{call.contact.phoneNumber}</p>
        </div>

        {/* Call Status */}
        <div className="text-center mb-8">
          <p className="text-3xl font-mono font-medium">
            {getStatusText()}
          </p>
        </div>

        {/* Dial Pad (when open) */}
        {isDialPadOpen && call.status === 'in-progress' && (
          <div className="mb-6">
            <DialPad 
              className="max-w-xs mx-auto"
              onClose={() => setIsDialPadOpen(false)}
            />
          </div>
        )}

        {/* Call Controls */}
        <div className="grid grid-cols-4 gap-4 max-w-xs mx-auto">
          <Button 
            variant="ghost" 
            size="lg" 
            className={cn(
              "aspect-square",
              isMuted && "bg-red-100 dark:bg-red-900/20 text-red-500"
            )}
            onClick={handleMuteToggle}
            disabled={call.status !== 'in-progress'}
          >
            {isMuted ? (
              <MicOff className="h-6 w-6" />
            ) : (
              <Mic className="h-6 w-6" />
            )}
          </Button>

          <Button 
            variant="destructive" 
            size="lg"
            className="aspect-square"
            onClick={onHangup}
            disabled={!['connecting', 'ringing', 'in-progress'].includes(call.status)}
          >
            <PhoneOff className="h-6 w-6" />
          </Button>

          <Button 
            variant="ghost" 
            size="lg"
            className={cn(
              "aspect-square",
              !isAudioEnabled && "bg-red-100 dark:bg-red-900/20 text-red-500"
            )}
            onClick={handleSpeakerToggle}
            disabled={call.status !== 'in-progress'}
          >
            {isAudioEnabled ? (
              <Volume2 className="h-6 w-6" />
            ) : (
              <VolumeX className="h-6 w-6" />
            )}
          </Button>

          <Button
            variant="ghost"
            size="lg"
            className={cn(
              "aspect-square",
              isDialPadOpen && "bg-primary/10 text-primary"
            )}
            onClick={toggleDialPad}
            disabled={call.status !== 'in-progress'}
          >
            <Hash className="h-6 w-6" />
          </Button>
        </div>
      </div>

      {/* Call Quality Indicator */}
      <div className="absolute top-4 right-4 flex items-center gap-2">
        <SignalIcon className={cn(
          "h-4 w-4",
          signalStrength === 'high' && "text-green-500",
          signalStrength === 'medium' && "text-yellow-500",
          signalStrength === 'low' && "text-red-500"
        )} />
      </div>

      {/* Recording Indicator */}
      {call.status === 'in-progress' && (
        <div className="absolute top-4 left-4 flex items-center gap-2">
          <span className="animate-pulse h-2 w-2 rounded-full bg-red-500" />
          <span className="text-sm">Recording</span>
        </div>
      )}
    </Card>
  );
} 