import { Button } from "@/components/ui/button";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useTeams } from "@/hooks/useTeams";
import { useState } from "react";
import { toast } from "sonner";

interface CreateTeamDialogProps {
  trigger?: React.ReactNode;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

export function CreateTeamDialog({ 
  trigger, 
  open: controlledOpen, 
  onOpenChange: setControlledOpen 
}: CreateTeamDialogProps) {
  const [uncontrolledOpen, setUncontrolledOpen] = useState(false);
  const [teamName, setTeamName] = useState("");
  const { createTeam, isCreatingTeam } = useTeams();

  // Determine if the component is controlled or uncontrolled
  const isControlled = controlledOpen !== undefined && setControlledOpen !== undefined;
  const open = isControlled ? controlledOpen : uncontrolledOpen;
  const setOpen = isControlled ? setControlledOpen : setUncontrolledOpen;

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!teamName.trim()) {
      toast.error("Team name is required");
      return;
    }
    
    createTeam(
      { name: teamName, isPersonal: false },
      {
        onSuccess: () => {
          setTeamName("");
          setOpen(false);
        }
      }
    );
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      {trigger && <DialogTrigger asChild>{trigger}</DialogTrigger>}
      <DialogContent className="sm:max-w-[425px] w-[95vw] max-w-[95vw] sm:w-full p-4 sm:p-6">
        <form onSubmit={handleSubmit}>
          <DialogHeader className="mb-4">
            <DialogTitle className="text-xl">Create Team</DialogTitle>
            <DialogDescription className="mt-1.5">
              Create a new team to collaborate with others.
            </DialogDescription>
          </DialogHeader>
          <div className="py-4">
            <div className="space-y-2">
              <Label htmlFor="name" className="text-sm font-medium">
                Team Name
              </Label>
              <Input
                id="name"
                value={teamName}
                onChange={(e) => setTeamName(e.target.value)}
                className="w-full"
                placeholder="Enter team name"
                autoFocus
              />
            </div>
          </div>
          <DialogFooter className="flex flex-col sm:flex-row gap-3 sm:gap-2 mt-4 sm:mt-2">
            <Button 
              type="button" 
              variant="outline" 
              onClick={() => setOpen(false)}
              disabled={isCreatingTeam}
              className="w-full sm:w-auto"
            >
              Cancel
            </Button>
            <Button 
              type="submit" 
              disabled={isCreatingTeam}
              className="w-full sm:w-auto"
            >
              {isCreatingTeam ? "Creating..." : "Create Team"}
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
} 