import { Tabs, TabsContent } from '@/components/ui/tabs';
import { CommunicationHeader } from './CommunicationHeader';
import { SMSTab } from './SMSTab';
import { VoiceCallTab } from './VoiceCallTab';

interface MobileLayoutProps {
  selectedDID: string | null;
  setSelectedDID: (did: string | null) => void;
  phoneNumber: string;
  displayName: string;
  leadId?: number | null;
}

export function MobileLayout({
  selectedDID,
  setSelectedDID,
  phoneNumber,
  displayName,
  leadId
}: MobileLayoutProps) {
  return (
    <div className="flex flex-col h-full lg:hidden">
      <Tabs defaultValue="voice" className="flex flex-col h-full overflow-hidden mt-0">
        <CommunicationHeader
          selectedDID={selectedDID}
          setSelectedDID={setSelectedDID}
          phoneNumber={phoneNumber}
          isMobile={true}
        />
        <TabsContent value="voice" className="flex-1 px-4 py-4">
          <VoiceCallTab
            phoneNumber={phoneNumber}
            fromNumber={selectedDID}
            contactName={displayName}
            leadId={leadId}
            isMobile={true}
          />
        </TabsContent>
        <TabsContent value="sms" className="flex-1 overflow-hidden flex flex-col">
          <SMSTab
            phoneNumber={phoneNumber}
            fromNumber={selectedDID}
            className="h-full"
          />
        </TabsContent>
      </Tabs>
    </div>
  );
} 