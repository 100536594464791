import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Textarea } from '@/components/ui/textarea';
import { useToast } from '@/components/ui/use-toast';
import { api, formatPhoneNumber } from '@/lib/api';
import { useLeadStore } from '@/store/leadStore';
import { CreateLeadInput } from '@/types';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import { useState } from 'react';

interface CreateLeadModalProps {
  isOpen: boolean;
  onClose: () => void;
  embedded?: boolean;
}

export function CreateLeadModal({ isOpen, onClose, embedded = false }: CreateLeadModalProps) {
  const navigate = useNavigate();
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const setSelectedLead = useLeadStore((state) => state.setSelectedLead);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState<Partial<CreateLeadInput>>({
    name: '',
    phoneNumber: '',
    email: '',
    businessName: '',
    comments: '',
  });
  const [phoneError, setPhoneError] = useState<string | null>(null);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    
    if (name === 'phoneNumber') {
      // Clear any previous error
      setPhoneError(null);
      
      // Apply phone number mask
      const cleaned = value.replace(/\D/g, '');
      let formatted = '';
      
      if (cleaned.length <= 3) {
        formatted = cleaned;
      } else if (cleaned.length <= 6) {
        formatted = `(${cleaned.slice(0, 3)}) ${cleaned.slice(3)}`;
      } else {
        formatted = `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6, 10)}`;
      }
      
      setFormData((prev) => ({ ...prev, [name]: formatted }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const validatePhoneNumber = (phone: string): boolean => {
    // Remove all non-digit characters
    const cleaned = phone.replace(/\D/g, '');
    
    // Check if it's a valid US phone number (10 digits, or 11 digits starting with 1)
    if (cleaned.length === 10) {
      return true;
    } else if (cleaned.length === 11 && cleaned.startsWith('1')) {
      return true;
    }
    
    return false;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!formData.name) {
      toast({
        title: 'Missing required field',
        description: 'Name is required.',
        variant: 'destructive',
      });
      return;
    }
    
    if (!formData.phoneNumber) {
      toast({
        title: 'Missing required field',
        description: 'Phone number is required.',
        variant: 'destructive',
      });
      return;
    }
    
    // Validate phone number
    if (!validatePhoneNumber(formData.phoneNumber)) {
      setPhoneError('Please enter a valid 10-digit US phone number');
      return;
    }
    
    try {
      setIsSubmitting(true);
      
      // Create the lead data object, preserving empty strings
      const leadData: CreateLeadInput = {
        name: formData.name,
        phoneNumber: formatPhoneNumber(formData.phoneNumber), // Format phone number
        // Only set to null if undefined, preserve empty strings
        email: formData.email !== undefined ? formData.email : null,
        businessName: formData.businessName !== undefined ? formData.businessName : null,
        comments: formData.comments !== undefined ? formData.comments : null,
        // Add notes field for the server which expects this field name
        notes: formData.comments !== undefined ? formData.comments : null,
      } as CreateLeadInput; // Use type assertion to avoid TypeScript errors
      
      const response = await api.leads.create(leadData);
      
      toast({
        title: 'Lead created',
        description: `Successfully created lead for ${formData.name}`,
      });
      
      // Reset form
      setFormData({
        name: '',
        phoneNumber: '',
        email: '',
        businessName: '',
        comments: '',
      });
      setPhoneError(null);
      
      // Invalidate leads query cache to force a refresh
      queryClient.invalidateQueries({ queryKey: ['leads'] });
      
      // Close modal
      onClose();
      
      // If lead was created successfully, navigate and set it as the selected lead
      if (response.success && response.lead) {
        // Store the lead data we need to set after navigation
        const leadToSelect = {
          id: response.lead.id,
          name: response.lead.name,
          email: response.lead.email,
          phone: response.lead.phoneNumber,
          businessName: response.lead.businessName,
          city: response.lead.city,
          state: response.lead.state,
          county: response.lead.county,
          zip: response.lead.zip,
          title: response.lead.title
        };
        
        // First navigate to the leads page
        console.log(`[CreateLeadModal] Navigating to leads page for new lead ID: ${response.lead.id}`);
        
        // Use navigate with a callback to set the selected lead after navigation completes
        navigate({ 
          to: '/leads',
          // Use replace: true to prevent back button from returning to the previous page
          replace: true
        }).then(async () => {
          // Wait for the navigation to complete
          await new Promise(resolve => setTimeout(resolve, 500));

          // After navigation completes, set the selected lead
          console.log(`[CreateLeadModal] Setting selected lead ID: ${leadToSelect.id}`);
          setSelectedLead(leadToSelect);
        });
      }
    } catch (error) {
      console.error('Error creating lead:', error);
      toast({
        title: 'Error',
        description: error instanceof Error ? error.message : 'Failed to create lead',
        variant: 'destructive',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  // If embedded, render just the form content without the Dialog wrapper
  if (embedded) {
    return (
      <div className="p-6">
        <form onSubmit={handleSubmit}>
          <div className="space-y-4">
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="name" className="text-right">
                Name <span className="text-red-500">*</span>
              </Label>
              <Input
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="col-span-3"
                required
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="phoneNumber" className="text-right">
                Phone <span className="text-red-500">*</span>
              </Label>
              <div className="col-span-3 space-y-1">
                <Input
                  id="phoneNumber"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  className={phoneError ? "border-red-500" : ""}
                  placeholder="(555) 123-4567"
                  required
                />
                {phoneError && (
                  <p className="text-xs text-red-500">{phoneError}</p>
                )}
              </div>
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="email" className="text-right">
                Email
              </Label>
              <Input
                id="email"
                name="email"
                type="email"
                value={formData.email || ''}
                onChange={handleChange}
                className="col-span-3"
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="businessName" className="text-right">
                Business
              </Label>
              <Input
                id="businessName"
                name="businessName"
                value={formData.businessName || ''}
                onChange={handleChange}
                className="col-span-3"
              />
            </div>
            <div className="grid grid-cols-4 items-start gap-4">
              <Label htmlFor="comments" className="text-right pt-2">
                Notes
              </Label>
              <Textarea
                id="comments"
                name="comments"
                value={formData.comments || ''}
                onChange={handleChange}
                className="col-span-3 min-h-[100px]"
                placeholder="Add any notes about this lead..."
              />
            </div>
          </div>
          <div className="mt-6 flex justify-end">
            <Button type="submit" disabled={isSubmitting}>
              {isSubmitting ? 'Creating...' : 'Create Lead'}
            </Button>
          </div>
        </form>
      </div>
    );
  }

  // Otherwise, render with the Dialog wrapper
  return (
    <Dialog open={isOpen} onOpenChange={(open) => !open && onClose()}>
      <DialogContent className="sm:max-w-[425px]">
        <form onSubmit={handleSubmit}>
          <DialogHeader>
            <DialogTitle>Create New Lead</DialogTitle>
            <DialogDescription>
              Enter the lead's information below. Name and phone number are required.
            </DialogDescription>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="name" className="text-right">
                Name <span className="text-red-500">*</span>
              </Label>
              <Input
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="col-span-3"
                required
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="phoneNumber" className="text-right">
                Phone <span className="text-red-500">*</span>
              </Label>
              <div className="col-span-3 space-y-1">
                <Input
                  id="phoneNumber"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  className={phoneError ? "border-red-500" : ""}
                  placeholder="(555) 123-4567"
                  required
                />
                {phoneError && (
                  <p className="text-xs text-red-500">{phoneError}</p>
                )}
              </div>
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="email" className="text-right">
                Email
              </Label>
              <Input
                id="email"
                name="email"
                type="email"
                value={formData.email || ''}
                onChange={handleChange}
                className="col-span-3"
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="businessName" className="text-right">
                Business
              </Label>
              <Input
                id="businessName"
                name="businessName"
                value={formData.businessName || ''}
                onChange={handleChange}
                className="col-span-3"
              />
            </div>
            <div className="grid grid-cols-4 items-start gap-4">
              <Label htmlFor="comments" className="text-right pt-2">
                Notes
              </Label>
              <Textarea
                id="comments"
                name="comments"
                value={formData.comments || ''}
                onChange={handleChange}
                className="col-span-3 min-h-[100px]"
                placeholder="Add any notes about this lead..."
              />
            </div>
          </div>
          <DialogFooter>
            <Button type="button" variant="outline" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit" disabled={isSubmitting}>
              {isSubmitting ? 'Creating...' : 'Create Lead'}
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
} 