import { useWebSocket } from '@/contexts/WebSocketContext';
import { SocketEvents } from '@/services/websocket.service';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Link } from '@tanstack/react-router';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { api, formatPhoneNumber } from '@/lib/api';
import { CalendarService } from '@/services/calendar.service';
import { useCommunicationPaneStore } from '@/store/communicationPaneStore';
import { useLeadStore } from '@/store/leadStore';
import { Lead } from '@/types';
import { ArchiveIcon, Calendar, Settings } from 'lucide-react';
import { toast } from 'sonner';
import { CallLogDialog } from '../CallLogDialog';
import { CommunicationPaneDrawer } from '../CommunicationPane';
import { LeadMeetingsSection } from '../LeadMeetings';
import { ActionItemsSection } from './ActionItemsSection';
import { AddressSection } from './AddressSection';
import { AttachmentsSection } from './AttachmentsSection';
import { ContactInfoSection } from './ContactInfoSection';
import { InsuranceDemographicsSection } from './InsuranceDemographicsSection';
import { LeadMetadataSection } from './LeadMetadataSection';
import { LeadNameHeader } from './LeadNameHeader';
import { LeadScoreSection } from './LeadScoreSection';
import { EmptyState, LoadingState } from './LoadingAndEmptyStates';
import { MobileCallButton } from './MobileCallButton';
import { NotesSection } from './NotesSection';
import { OriginalCallFooter } from './OriginalCallFooter';
import { QuickActionsBar } from './QuickActionsBar';
import { formatPhoneNumberForDisplay, formFieldClasses, LeadStatus } from './utils';

// Define loading state keys
type LoadingStateKey = 'calendar' | 'googleCalendar' | 'callLog' | 'email' | 'saveNote' | 'saving' | 'deleting' | 'reassigning';

interface LeadDetailsPaneProps {
  lead: Lead | null;
  isLoading: boolean;
  selectedLeadId: number | null;
  setSelectedLeadId: (id: number | null) => void;
}

export function LeadDetailsPane({ lead, isLoading, selectedLeadId, setSelectedLeadId }: LeadDetailsPaneProps) {
  const [formData, setFormData] = useState<Partial<Lead>>({ status: 'new' });
  const [isSaving, setIsSaving] = useState(false);
  const [isCallLogOpen, setIsCallLogOpen] = useState(false);
  const [loadingStates, setLoadingStates] = useState<Record<LoadingStateKey, boolean>>({
    calendar: false,
    googleCalendar: false,
    callLog: false,
    email: false,
    saveNote: false,
    saving: false,
    deleting: false,
    reassigning: false
  });
  const [callStatus, setCallStatus] = useState<'none' | 'active' | 'analyzing' | 'analyzed'>('none');
  const emailCooldownRef = useRef<NodeJS.Timeout | undefined>();
  const googleCalendarCooldownRef = useRef<NodeJS.Timeout | undefined>();
  const COOLDOWN_PERIOD = 3000; // 3 seconds cooldown
  const queryClient = useQueryClient();
  const [isCommunicationModalOpen, setIsCommunicationModalOpen] = useState(false);
  const communicationPaneOpen = useCommunicationPaneStore((state) => state.isOpen);
  const { on, viewLead } = useWebSocket();
  const [isArchiveDialogOpen, setIsArchiveDialogOpen] = useState(false);

  // Debug render counter
  const renderCount = useRef(0);
  renderCount.current += 1;

  // Debug re-renders
  const renderCountRef = useRef(0);
  const prevPropsRef = useRef<{
    selectedLeadId: number | null;
    communicationPaneOpen: boolean;
    isCommunicationModalOpen: boolean;
    isLoading: boolean;
    lead: Lead | null;
  }>({
    selectedLeadId: null,
    communicationPaneOpen: false,
    isCommunicationModalOpen: false,
    isLoading: false,
    lead: null
  });

  useEffect(() => {
    const renderCount = renderCountRef.current + 1;
    renderCountRef.current = renderCount;
    
    const currentProps = {
      selectedLeadId,
      communicationPaneOpen,
      isCommunicationModalOpen,
      isLoading,
      lead: lead
    };
    
    // Find what changed from previous render
    const prevProps = prevPropsRef.current || {};
    const changes = Object.keys(currentProps).filter(key => {
      // @ts-ignore - Dynamic property access
      return currentProps[key] !== prevProps[key];
    });
    
    if (changes.length > 0) {
      console.log(`[LeadDetailsPane] Re-render #${renderCount}, changed props:`, changes);
    }
    
    // Update previous props for next render
    prevPropsRef.current = currentProps;
  });

  // Update form data when lead changes
  useEffect(() => {
    if (lead) {
      // Format phone number for display if it exists
      const displayData = {
        ...lead,
        phoneNumber: lead.phoneNumber ? formatPhoneNumberForDisplay(lead.phoneNumber) : lead.phoneNumber,
        altPhone: lead.altPhone ? formatPhoneNumberForDisplay(lead.altPhone) : lead.altPhone
      };
      
      setFormData(displayData);
      
      // Update the selected lead in the store with all relevant information
      // Use the original phone number for the store to ensure consistency
      useLeadStore.getState().setSelectedLead({
        id: lead.id,
        name: lead.name,
        email: lead.email,
        phone: lead.phoneNumber, // Use original format for internal use
        city: lead.city,
        state: lead.state,
        county: lead.county,
        zip: lead.zip,
        businessName: lead.businessName,
        title: lead.title
      });
    } else {
      setFormData({});
      useLeadStore.getState().resetSelectedLead();
    }
  }, [lead]);

  // Listen for call activity and refresh lead data
  useEffect(() => {
    // Set up WebSocket subscriptions for lead updates
    if (selectedLeadId) {
      console.log(`[LeadDetailsPane] Setting up WebSocket subscriptions for lead ID: ${selectedLeadId}`);
      
      // Subscribe to lead updates
      const unsubscribeLead = on<{
        leadId: number;
        action: 'created' | 'updated' | 'deleted';
      }>(SocketEvents.LEAD_UPDATE, (data) => {
        if (data.leadId === selectedLeadId) {
          console.log(`[LeadDetailsPane] Received lead update for lead ID: ${selectedLeadId}, action: ${data.action}`);
          queryClient.invalidateQueries({ queryKey: ['lead', selectedLeadId] });
        }
      });
      
      // Subscribe to call history updates that might affect this lead
      const unsubscribeCall = on<{
        leadId: number;
        callId: number;
        action: 'created' | 'updated' | 'deleted';
      }>(SocketEvents.CALL_HISTORY_UPDATE, (data) => {
        if (data.leadId === selectedLeadId) {
          console.log(`[LeadDetailsPane] Received call history update for lead ID: ${selectedLeadId}`);
          queryClient.invalidateQueries({ queryKey: ['lead', selectedLeadId] });
          queryClient.invalidateQueries({ queryKey: ['callHistory', selectedLeadId] });
        }
      });
      
      // Subscribe to lead note updates
      const unsubscribeNote = on<{
        leadId: number;
        noteId: number;
      }>(SocketEvents.LEAD_NOTE_ADDED, (data) => {
        if (data.leadId === selectedLeadId) {
          console.log(`[LeadDetailsPane] Received lead note update for lead ID: ${selectedLeadId}`);
          queryClient.invalidateQueries({ queryKey: ['lead', selectedLeadId] });
        }
      });
      
      // Subscribe to lead status changes
      const unsubscribeStatus = on<{
        leadId: number;
        status: string;
      }>(SocketEvents.LEAD_STATUS_CHANGE, (data) => {
        if (data.leadId === selectedLeadId) {
          console.log(`[LeadDetailsPane] Received lead status change for lead ID: ${selectedLeadId}, new status: ${data.status}`);
          queryClient.invalidateQueries({ queryKey: ['lead', selectedLeadId] });
        }
      });
      
      // Subscribe to lead assignment changes
      const unsubscribeAssignment = on<{
        leadId: number;
        userId: number;
      }>(SocketEvents.LEAD_ASSIGNMENT, (data) => {
        if (data.leadId === selectedLeadId) {
          console.log(`[LeadDetailsPane] Received lead assignment change for lead ID: ${selectedLeadId}, assigned to user: ${data.userId}`);
          queryClient.invalidateQueries({ queryKey: ['lead', selectedLeadId] });
        }
      });
      
      // Return cleanup function to unsubscribe from all events
      return () => {
        unsubscribeLead();
        unsubscribeCall();
        unsubscribeNote();
        unsubscribeStatus();
        unsubscribeAssignment();
      };
    }
  }, [selectedLeadId, on, queryClient]);
  
  // Create a ref to track the last viewed lead ID to prevent duplicate notifications
  // This will help avoid the infinite loop problem
  const lastViewedLeadRef = useRef<number | null>(null);
  
  // Modified effect to prevent the infinite loop by tracking last viewed lead
  useEffect(() => {
    // Only notify if we have a valid lead ID and it's different from the last one we viewed
    if (selectedLeadId && lastViewedLeadRef.current !== selectedLeadId) {
      // Update the ref to show we're now viewing this lead
      lastViewedLeadRef.current = selectedLeadId;
      
      // Use a small timeout to ensure this doesn't happen too frequently
      const timer = setTimeout(() => {
        console.log(`[LeadDetailsPane] Notifying that we're viewing lead ID: ${selectedLeadId}`);
        viewLead(selectedLeadId);
      }, 300);
      
      return () => clearTimeout(timer);
    }
  }, [selectedLeadId, viewLead]);

  // Listen for call status change events
  useEffect(() => {
    const handleCallStatusChange = (event: Event) => {
      const customEvent = event as CustomEvent<{
        status: 'started' | 'ended' | 'analyzing' | 'analyzed';
        phoneNumber: string;
        leadId: number | null;
      }>;
      
      const { status, leadId: eventLeadId } = customEvent.detail;
      
      // Only process events for this lead
      if (eventLeadId === selectedLeadId) {
        console.log(`[LeadDetailsPane] Call status changed: ${status} for lead ${eventLeadId}`);
        
        switch (status) {
          case 'started':
            setCallStatus('active');
            break;
          case 'analyzing':
            setCallStatus('analyzing');
            break;
          case 'analyzed':
            setCallStatus('analyzed');
            // Refresh lead data
            queryClient.invalidateQueries({ queryKey: ['lead', selectedLeadId] });
            break;
          case 'ended':
            setCallStatus('none');
            // Refresh lead data
            queryClient.invalidateQueries({ queryKey: ['lead', selectedLeadId] });
            break;
        }
      }
    };
    
    window.addEventListener('call-status-change', handleCallStatusChange);
    
    return () => {
      window.removeEventListener('call-status-change', handleCallStatusChange);
    };
  }, [selectedLeadId, queryClient]);

  // Add WebSocket subscription for conversation updates
  useEffect(() => {
    // No need to clear polling intervals as we're using WebSocket events
    
    // Subscribe to WebSocket events for lead updates instead of polling
    if (selectedLeadId) {
      console.log('[LeadDetailsPane] Setting up WebSocket subscription for lead updates');
      
      // Subscribe to conversation updates via WebSocket
      const unsubscribe = on<{
        conversationId: number;
        leadId?: number;
        action: 'created' | 'updated' | 'new-message';
      }>(SocketEvents.CONVERSATION_UPDATE, (data) => {
        if (data.leadId === selectedLeadId) {
          console.log('[LeadDetailsPane] Received conversation update for lead:', selectedLeadId);
          queryClient.invalidateQueries({ queryKey: ['lead', selectedLeadId] });
        }
      });
      
      // Cleanup on unmount or when dependencies change
      return () => {
        console.log('[LeadDetailsPane] Cleaning up WebSocket subscription');
        unsubscribe();
      };
    }
    
    return () => {
      // No additional cleanup needed
    };
  }, [selectedLeadId, queryClient, on]);

  // Cleanup all intervals on unmount
  useEffect(() => {
    return () => {
      if (emailCooldownRef.current) {
        clearTimeout(emailCooldownRef.current);
      }
      if (googleCalendarCooldownRef.current) {
        clearTimeout(googleCalendarCooldownRef.current);
      }
      // We no longer need to clear leadRefreshIntervalRef as we're not using it
    };
  }, []);

  const handleInputChange = (field: keyof Lead, value: string | number | boolean) => {
    setFormData(prev => ({ ...prev, [field]: value }));
  };

  const handleBlur = async (field: keyof Lead) => {
    if (!lead || !formData || formData[field] === lead[field]) return;

    setIsSaving(true);
    try {
      // Format phone numbers for storage when saving to the database
      let valueToSend = formData[field];
      
      if (field === 'phoneNumber' || field === 'altPhone') {
        if (valueToSend) {
          valueToSend = formatPhoneNumber(valueToSend as string);
        }
      }

      const response = await api.leads.update(lead.id, { [field]: valueToSend });
      if (response.success) {
        // For phone numbers, keep the formatted display version in the UI
        const valueForCache = (field === 'phoneNumber' || field === 'altPhone') && formData[field]
          ? formData[field]  // Keep formatted display version for UI
          : valueToSend;    // Use API version for other fields

        // Update the lead in the cache
        queryClient.setQueryData(['lead', lead.id], {
          success: true,
          lead: { ...lead, [field]: valueForCache }
        });

        // Update the lead in the leads list cache
        queryClient.setQueriesData<{ pages: { leads: Lead[] }[] }>({ queryKey: ['leads'] }, (old) => {
          if (!old?.pages) return old;
          return {
            ...old,
            pages: old.pages.map((page) => ({
              ...page,
              leads: page.leads.map((l) =>
                l.id === lead.id ? { ...l, [field]: valueForCache } : l
              )
            }))
          };
        });

        // If it's a phone number field, also update the store with the E.164 format
        if (field === 'phoneNumber') {
          useLeadStore.getState().setSelectedLead({
            ...useLeadStore.getState().selectedLead,
            phone: valueToSend as string
          });
        }

        toast.success(`Updated ${field}`);
      }
    } catch (error) {
      toast.error(`Failed to update ${field}`);
      // Revert to original value on error
      setFormData(prev => ({ ...prev, [field]: lead[field] }));
    }
    setIsSaving(false);
  };

  const handleStatusChange = async (newStatus: string) => {
    if (!lead || !formData || newStatus === formData.status) return;

    setIsSaving(true);
    try {
      const response = await api.leads.update(lead.id, { status: newStatus as LeadStatus });
      if (response.success) {
        // Update the lead in the cache
        queryClient.setQueryData(['lead', lead.id], {
          success: true,
          lead: { ...lead, status: newStatus as LeadStatus }
        });

        // Update the lead in the leads list cache
        queryClient.setQueriesData<{ pages: { leads: Lead[] }[] }>({ queryKey: ['leads'] }, (old) => {
          if (!old?.pages) return old;
          return {
            ...old,
            pages: old.pages.map((page) => ({
              ...page,
              leads: page.leads.map((l) =>
                l.id === lead.id ? { ...l, status: newStatus as LeadStatus } : l
              )
            }))
          };
        });

        setFormData(prev => ({ ...prev, status: newStatus as LeadStatus }));
        toast.success('Updated status');
      }
    } catch (error) {
      toast.error('Failed to update status');
      // Revert to original value on error
      setFormData(prev => ({ ...prev, status: formData.status }));
    }
    setIsSaving(false);
  };

  const setLoadingState = (action: LoadingStateKey, isLoading: boolean) => {
    setLoadingStates(prev => ({ ...prev, [action]: isLoading }));
  };

  const handleEmailClick = useCallback(async () => {
    if (!formData?.email || loadingStates.email || emailCooldownRef.current) return;
    
    setLoadingState('email', true);
    try {
      window.location.href = `mailto:${formData.email}`;
      
      // Start cooldown period
      emailCooldownRef.current = setTimeout(() => {
        emailCooldownRef.current = undefined;
      }, COOLDOWN_PERIOD);
      
    } finally {
      // We'll keep the loading state active during the cooldown
      setTimeout(() => {
        setLoadingState('email', false);
      }, COOLDOWN_PERIOD);
    }
  }, [formData?.email, loadingStates.email]);

  const handleGoogleCalendarClick = useCallback(async () => {
    if (!formData || loadingStates.googleCalendar || googleCalendarCooldownRef.current) return;
    
    setLoadingState('googleCalendar', true);
    
    // Format start date (1 hour from now)
    const startDate = new Date();
    startDate.setHours(startDate.getHours() + 1);
    startDate.setMinutes(0);
    startDate.setSeconds(0);
    
    // Format end date (1 hour after start)
    const endDate = new Date(startDate);
    endDate.setHours(startDate.getHours() + 1);
    
    // Format dates for Google Calendar URL
    const formatDate = (date: Date) => {
      return date.toISOString().replace(/-|:|\.\d+/g, '');
    };

    const details = encodeURIComponent(
      `${formData.comments || ''}\n\nEmail: ${formData.email || ''}`
    );

    const title = encodeURIComponent(
      `${formData.name || ''} TMS ${formData.phoneNumber?.replace(
        /(\d{3})(\d{3})(\d{4})/,
        '($1) $2-$3'
      ) || ''}`
    );

    const location = encodeURIComponent(
      `${formData.address || ''} ${formData.city || ''} ${formData.state || ''} ${formData.zip || ''}`
    );

    const url = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${title}&dates=${formatDate(startDate)}/${formatDate(endDate)}&details=${details}&location=${location}${formData.email ? `&add=${encodeURIComponent(formData.email)}` : ''}`;
    
    // Add a small delay to show loading state
    await new Promise(resolve => setTimeout(resolve, 100));
    
    window.open(url, '_blank');

    // Start cooldown period
    googleCalendarCooldownRef.current = setTimeout(() => {
      googleCalendarCooldownRef.current = undefined;
    }, COOLDOWN_PERIOD);
    
    // Keep loading state active during cooldown
    setTimeout(() => {
      setLoadingState('googleCalendar', false);
    }, COOLDOWN_PERIOD);
  }, [formData, loadingStates.googleCalendar]);

  const handleReassignLead = async (userId: number) => {
    if (!lead) return;

    setLoadingStates(prev => ({ ...prev, reassigning: true }));

    try {
      const response = await api.leads.update(lead.id, { userId });

      if (response.success) {
        // Update the lead in the cache
        queryClient.setQueryData(['lead', lead.id], {
          success: true,
          lead: response.lead
        });

        // Update the leads list cache to reflect the reassignment
        queryClient.invalidateQueries({ queryKey: ['leads'] });

        // Update form data with the new userId
        setFormData(prev => ({ ...prev, userId }));

        // Show success message only to the assigner
        toast.success('Lead reassigned successfully');
        setSelectedLeadId(null);
      }
    } catch (error) {
      console.error('Error reassigning lead:', error);
      toast.error('Failed to reassign lead');
    } finally {
      setLoadingStates(prev => ({ ...prev, reassigning: false }));
    }
  };

  const handleArchiveLead = async () => {
    if (!lead) return;
    
    try {
      setLoadingState('deleting', true);
      
      const response = await api.leads.archive(lead.id);
      
      if (response.success) {
        toast.success('Lead archived successfully');
        
        // Clear the selected lead
        setSelectedLeadId(null);
        
        // Invalidate all relevant queries to ensure UI is updated
        queryClient.invalidateQueries({ queryKey: ['leads'] });
        queryClient.invalidateQueries({ queryKey: ['lead', lead.id] });
        
        // Force refetch of the leads list
        queryClient.refetchQueries({ queryKey: ['leads'] });
      } else {
        toast.error('Failed to archive lead');
      }
    } catch (error) {
      console.error('Error archiving lead:', error);
      let errorMessage = 'An error occurred while archiving the lead';
      
      if (error instanceof Error) {
        errorMessage += ': ' + error.message;
      }
      
      toast.error(errorMessage);
    } finally {
      setLoadingState('deleting', false);
      setIsArchiveDialogOpen(false);
    }
  };

  // Check if user has Google Calendar integration
  const { data: integrations } = useQuery({
    queryKey: ['leadDetailsCalendarIntegrations'],
    queryFn: () => CalendarService.getCalendarIntegrations(),
    refetchOnWindowFocus: false,
    staleTime: 5 * 60 * 1000, // 5 minutes
  });
  
  const hasGoogleCalendar = useMemo(() => {
    return integrations?.some(i => i.provider === 'google' && i.syncEnabled);
  }, [integrations]);

  // Render loading state
  if (isLoading) {
    return <LoadingState isLoading={isLoading} />;
  }

  // Render empty state
  if (!lead || !formData) {
    return <EmptyState />;
  }

  return (
    <div className="h-full overflow-hidden md:border-r md:border-gray-200 bg-white dark:bg-gray-900 dark:md:border-gray-800">
      <div className="flex h-full flex-col divide-y divide-gray-200 dark:divide-gray-700">
        {/* Quick Actions Bar */}
        <QuickActionsBar
          lead={lead}
          formData={formData}
          isSaving={isSaving}
          loadingStates={loadingStates}
          setSelectedLeadId={setSelectedLeadId}
          handleStatusChange={handleStatusChange}
          handleEmailClick={handleEmailClick}
          handleGoogleCalendarClick={handleGoogleCalendarClick}
          setIsCallLogOpen={setIsCallLogOpen}
          setLoadingState={setLoadingState}
          googleCalendarCooldownRef={googleCalendarCooldownRef}
          handleReassignLead={handleReassignLead}
        />

        {/* Main Content - Scrollable */}
        <div className="flex-1 overflow-y-auto [&::-webkit-scrollbar]:hidden sm:[&::-webkit-scrollbar]:block">
          {/* Header Section */}
          <LeadNameHeader
            formData={formData}
            handleInputChange={handleInputChange}
            handleBlur={handleBlur}
          />

          {/* Contact Info */}
          <ContactInfoSection
            formData={formData}
            handleInputChange={handleInputChange}
            handleBlur={handleBlur}
            lead={lead}
          />

          {/* Details Sections */}
          <div className="px-4 py-4 space-y-4">
            {/* Lead Meetings Section - only show if Google Calendar is integrated */}
            {hasGoogleCalendar ? (
              <LeadMeetingsSection
                leadId={lead.id}
                lead={lead}
              />
            ) : (
              <div className={formFieldClasses.section}>
                <div className="space-y-3">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <Calendar className="h-4 w-4 text-blue-500" />
                      <h3 className="text-sm font-medium">Meetings</h3>
                    </div>
                    <Link 
                      to="/integrations"
                      search={{ integration: 'google-calendar' }}
                      params={{}}
                      className="inline-flex items-center gap-1 text-xs bg-primary text-white py-1 px-3 rounded-md hover:bg-primary/90"
                    >
                      <Settings className="h-3 w-3" />
                      Set Up Calendar
                    </Link>
                  </div>
                </div>
              </div>
            )}
            
            {/* Lead Score Section */}
            <LeadScoreSection formData={formData} leadId={lead?.id} />
            
            {/* Action Items Section */}
            <ActionItemsSection formData={formData} />
            
            {/* Insurance & Demographics */}
            <InsuranceDemographicsSection
              formData={formData}
              handleInputChange={handleInputChange}
              handleBlur={handleBlur}
              lead={lead}
              setIsSaving={setIsSaving}
              setFormData={setFormData}
            />

            {/* Address Information */}
            <AddressSection
              formData={formData}
              handleInputChange={handleInputChange}
              handleBlur={handleBlur}
            />

            {/* Custom Fields / Metadata */}
            <LeadMetadataSection leadId={lead.id} />

            {/* Notes Section */}
            <NotesSection
              formData={formData}
              handleInputChange={handleInputChange}
              handleBlur={handleBlur}
            />

            {/* Attachments Section */}
            <AttachmentsSection
              leadId={lead.id}
            />

            {/* Footer - Original Call Info */}
            <OriginalCallFooter formData={formData} />

            {/* Archive Button */}
            <div className="p-4 border-t border-gray-200">
              <Button
                variant="outline"
                className="w-full text-red-600 border-red-200 hover:bg-red-50 hover:text-red-700 hover:border-red-300"
                onClick={() => setIsArchiveDialogOpen(true)}
                disabled={loadingStates.deleting}
              >
                <ArchiveIcon className="w-4 h-4 mr-2" />
                {loadingStates.deleting ? 'Archiving...' : 'Archive Lead'}
              </Button>
            </div>
          </div>
        </div>
      </div>

      {/* Dialogs and Drawers */}
      <CallLogDialog
        lead={lead}
        open={isCallLogOpen}
        onOpenChange={setIsCallLogOpen}
      />

      {/* Replace Dialog with Drawer for mobile communication */}
      <CommunicationPaneDrawer 
        lead={lead} 
        open={isCommunicationModalOpen} 
        onOpenChange={setIsCommunicationModalOpen}
      />

      {/* Mobile Call Button - Fixed at bottom */}
      <MobileCallButton 
        lead={lead}
        setIsCommunicationModalOpen={setIsCommunicationModalOpen}
      />
      
      {/* Archive Confirmation Dialog */}
      <AlertDialog open={isArchiveDialogOpen} onOpenChange={setIsArchiveDialogOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Archive Lead</AlertDialogTitle>
            <AlertDialogDescription>
              Are you sure you want to archive this lead? This will remove it from your active leads list.
              Archived leads can be restored by an administrator if needed.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={handleArchiveLead}
              className="bg-red-600 hover:bg-red-700 text-white"
            >
              Archive
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
} 