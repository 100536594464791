import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Download, FileText, Mail } from "lucide-react";
import { useState } from "react";

interface ExportCallLogsDialogProps {
  onExport: (options: ExportCallLogsOptions) => void;
}

export interface ExportCallLogsOptions {
  startDate: Date | null;
  endDate: Date | null;
  includeTranscripts: boolean;
  emailTo: string;
}

export function ExportCallLogsDialog({ onExport }: ExportCallLogsDialogProps) {
  const [open, setOpen] = useState(false);
  
  // Default to last 30 days
  const defaultStartDate = new Date();
  defaultStartDate.setDate(defaultStartDate.getDate() - 30);
  
  const [startDate, setStartDate] = useState<string>(defaultStartDate.toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState<string>(new Date().toISOString().split('T')[0]);
  const [includeTranscripts, setIncludeTranscripts] = useState(false);
  const [emailTo, setEmailTo] = useState('');
  const [sendEmail, setSendEmail] = useState(false);

  const handleExport = () => {
    // Validate dates
    const parsedStartDate = startDate ? new Date(startDate) : null;
    const parsedEndDate = endDate ? new Date(endDate) : null;
    
    // Check if dates are valid
    if ((parsedStartDate && isNaN(parsedStartDate.getTime())) || 
        (parsedEndDate && isNaN(parsedEndDate.getTime()))) {
      alert("Please enter valid dates");
      return;
    }
    
    // Check if start date is before end date
    if (parsedStartDate && parsedEndDate && parsedStartDate > parsedEndDate) {
      alert("Start date must be before end date");
      return;
    }
    
    onExport({
      startDate: parsedStartDate,
      endDate: parsedEndDate,
      includeTranscripts,
      emailTo: sendEmail ? emailTo : '',
    });
    setOpen(false);
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outline" size="sm" className="h-8 gap-1">
          <Download className="h-4 w-4" />
          <span>Export Data</span>
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[500px]">
        <DialogHeader>
          <DialogTitle>Export AI Receptionist Call Logs</DialogTitle>
          <DialogDescription>
            Configure your export options
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="format" className="text-right">
              Format
            </Label>
            <div className="col-span-3 flex items-center">
              <FileText className="mr-2 h-4 w-4" />
              <span>CSV Spreadsheet</span>
            </div>
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="startDate" className="text-right">
              Start Date
            </Label>
            <div className="col-span-3">
              <Input
                id="startDate"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="endDate" className="text-right">
              End Date
            </Label>
            <div className="col-span-3">
              <Input
                id="endDate"
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label className="text-right">
              Options
            </Label>
            <div className="col-span-3 space-y-2">
              <div className="flex items-center space-x-2">
                <Checkbox 
                  id="transcripts" 
                  checked={includeTranscripts} 
                  onCheckedChange={(checked) => setIncludeTranscripts(!!checked)} 
                />
                <Label htmlFor="transcripts">Include Call Transcripts</Label>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <div className="col-span-4">
              <div className="flex items-center space-x-2">
                <Checkbox 
                  id="send-email" 
                  checked={sendEmail} 
                  onCheckedChange={(checked) => setSendEmail(!!checked)} 
                />
                <Label htmlFor="send-email">Send report via email</Label>
              </div>
            </div>
          </div>
          {sendEmail && (
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="email" className="text-right">
                Email To
              </Label>
              <div className="col-span-3 flex items-center space-x-2">
                <Mail className="h-4 w-4 text-gray-500" />
                <Input
                  id="email"
                  type="email"
                  value={emailTo}
                  onChange={(e) => setEmailTo(e.target.value)}
                  placeholder="Enter email address"
                />
              </div>
            </div>
          )}
        </div>
        <DialogFooter>
          <Button variant="outline" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button onClick={handleExport}>
            <Download className="mr-2 h-4 w-4" />
            Export Report
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
} 