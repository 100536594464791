import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Skeleton } from "@/components/ui/skeleton";
import { toast } from "@/components/ui/use-toast";
import { useDebounce } from "@/hooks/useDebounce";
import { axiosInstance } from "@/lib/api";
import { formatPhoneNumber } from "@/lib/utils";
import { CalendarEvent } from "@/services/calendar.service";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Loader2, MailIcon, PhoneIcon, SearchIcon, UserPlus, X } from "lucide-react";
import { useCallback, useEffect, useState } from "react";
import { LeadStatusBadge } from "../leads/LeadStatusBadge";

interface ConnectLeadDialogProps {
  event: CalendarEvent;
  isOpen: boolean;
  onClose: () => void;
}

// Match lead structure from leads controller
interface Lead {
  id: number;
  name: string;
  email?: string;
  phoneNumber?: string;
  businessName?: string;
  leadScore?: number;
  status?: string;
}

export function ConnectLeadDialog({ event, isOpen, onClose }: ConnectLeadDialogProps) {
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const [selectedLeadId, setSelectedLeadId] = useState<number | null>(null);
  const [showNewLeadForm, setShowNewLeadForm] = useState(false);
  const [newLeadPhone, setNewLeadPhone] = useState('');
  const [newLeadEmail, setNewLeadEmail] = useState('');
  const queryClient = useQueryClient();

  // Extract email from event when showing the new lead form
  useEffect(() => {
    if (showNewLeadForm) {
      const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;
      const emailFromEvent = 
        emailRegex.test(event.title) ? event.title.match(emailRegex)?.[0] : 
        emailRegex.test(event.description || '') ? event.description?.match(emailRegex)?.[0] : '';
      
      setNewLeadEmail(emailFromEvent || '');
    }
  }, [showNewLeadForm, event]);

  // Search leads API endpoint
  const { data, isLoading } = useQuery({
    queryKey: ['leads', 'search', debouncedSearchTerm],
    queryFn: async () => {
      if (!debouncedSearchTerm || debouncedSearchTerm.length < 2) {
        return { leads: [], total: 0, pages: 0 };
      }
      
      const response = await axiosInstance.get('/leads', {
        params: {
          search: debouncedSearchTerm,
          limit: 10,
          page: 1,
        },
      });
      
      return response.data;
    },
    enabled: isOpen && debouncedSearchTerm.length >= 2,
  });

  // Connect lead to event
  const { mutate: connectLead, isPending: isConnecting } = useMutation({
    mutationFn: async () => {
      if (!selectedLeadId) throw new Error('No lead selected');
      
      const selectedLead = data?.leads.find((lead: Lead) => lead.id === selectedLeadId);
      const hasEmail = selectedLead?.email && selectedLead.email.trim() !== '';
      
      // Update the event with the lead ID and specify if we should send an invitation
      return axiosInstance.put(`/calendar/events/${event.id}`, {
        leadId: selectedLeadId.toString(),
        sendInvite: hasEmail, // Only send an invite if the lead has an email
        guestEmail: hasEmail ? selectedLead.email : undefined,
      });
    },
    onSuccess: (response) => {
      const { sentInvite } = response.data || {};
      
      // Invalidate calendar events query
      queryClient.invalidateQueries({ queryKey: ['calendarEvents'], exact: false });
      
      // Invalidate lead events query for the connected lead
      queryClient.invalidateQueries({ queryKey: ['leadEvents', selectedLeadId], exact: true });
      
      // Show different messages based on whether an invite was sent
      if (sentInvite) {
        toast({
          title: "Lead connected with invitation",
          description: "The event has been linked to the lead and a calendar invitation has been sent to their email.",
        });
      } else {
        const selectedLead = data?.leads.find((lead: Lead) => lead.id === selectedLeadId);
        const noEmail = !selectedLead?.email || selectedLead.email.trim() === '';
        
        toast({
          title: "Lead connected",
          description: noEmail 
            ? "The event has been linked to the lead. No invitation was sent because the lead has no email address." 
            : "The event has been linked to the selected lead.",
          variant: noEmail ? "default" : "default",
        });
      }
      
      onClose();
    },
    onError: (error) => {
      console.error("Failed to connect lead:", error);
      toast({
        title: "Connection failed",
        description: "Failed to connect lead to event. Please try again.",
        variant: "destructive",
      });
    },
  });

  // Create new lead
  const { mutate: createLead, isPending: isCreatingLead } = useMutation({
    mutationFn: async () => {
      if (!searchTerm) throw new Error('Lead name required');
      if (!newLeadPhone) throw new Error('Phone number required');
      
      // Create new lead with data from event
      const response = await axiosInstance.post('/leads', {
        name: searchTerm,
        email: newLeadEmail || undefined,
        phoneNumber: newLeadPhone,
        comments: `Created from calendar event: ${event.title}${event.description ? `\n\n${event.description}` : ''}`,
      });
      
      return response.data;
    },
    onSuccess: (data) => {
      setSelectedLeadId(data.lead.id);
      setShowNewLeadForm(false);
      setNewLeadPhone('');
      setNewLeadEmail('');
      toast({
        title: "Lead created",
        description: "New lead has been created.",
      });
      
      // Refresh leads search results and invalidate the main leads query
      queryClient.invalidateQueries({ queryKey: ['leads', 'search'] });
      queryClient.invalidateQueries({ queryKey: ['leads'] });
    },
    onError: (error) => {
      console.error("Failed to create lead:", error);
      toast({
        title: "Lead creation failed",
        description: "Failed to create new lead. Please try again.",
        variant: "destructive",
      });
    },
  });

  const handleConnect = useCallback(() => {
    connectLead();
  }, [connectLead]);

  const handleCreateLead = useCallback(() => {
    if (showNewLeadForm) {
      createLead();
    } else {
      setShowNewLeadForm(true);
    }
  }, [createLead, showNewLeadForm]);

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Keep only numbers for storage
    const numericValue = e.target.value.replace(/\D/g, '');
    setNewLeadPhone(numericValue);
  };

  return (
    <Dialog open={isOpen} onOpenChange={(open) => !open && onClose()}>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Connect Event to Lead</DialogTitle>
          <DialogDescription>
            Link "{event.title}" to an existing lead or create a new one.
          </DialogDescription>
        </DialogHeader>
        
        <div className="space-y-4 py-2">
          <div className="relative">
            <SearchIcon className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
            <Input
              placeholder="Search leads by name, email, or phone..."
              className="pl-9"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            {searchTerm && (
              <Button
                variant="ghost"
                size="sm"
                className="absolute right-1 top-1 h-7 w-7 p-0"
                onClick={() => setSearchTerm('')}
              >
                <X className="h-4 w-4" />
              </Button>
            )}
          </div>
          
          {showNewLeadForm && (
            <div className="border rounded-md p-3 space-y-3">
              <h3 className="text-sm font-medium">Create New Lead</h3>
              <div className="flex items-center gap-2">
                <PhoneIcon className="h-4 w-4 text-muted-foreground" />
                <Input
                  placeholder="Phone number (required)"
                  value={formatPhoneNumber(newLeadPhone)}
                  onChange={handlePhoneChange}
                  className="flex-1"
                />
              </div>
              <div className="flex items-center gap-2">
                <MailIcon className="h-4 w-4 text-muted-foreground" />
                <Input
                  type="email"
                  placeholder="Email address (optional)"
                  value={newLeadEmail}
                  onChange={(e) => setNewLeadEmail(e.target.value)}
                  className="flex-1"
                />
              </div>
            </div>
          )}
          
          <div className="border rounded-md divide-y max-h-[300px] overflow-y-auto">
            {isLoading ? (
              <div className="p-4 space-y-3">
                <Skeleton className="h-12 w-full" />
                <Skeleton className="h-12 w-full" />
                <Skeleton className="h-12 w-full" />
              </div>
            ) : data?.leads && data.leads.length > 0 ? (
              data.leads.map((lead: Lead) => (
                <div 
                  key={lead.id} 
                  className={`p-3 cursor-pointer hover:bg-gray-50 ${
                    selectedLeadId === lead.id ? 'bg-emerald-50 border-emerald-200' : ''
                  }`}
                  onClick={() => setSelectedLeadId(lead.id)}
                >
                  <div className="flex items-center">
                    <div className="flex-1 min-w-0">
                      <div className="flex items-center justify-between">
                        <p className="text-sm font-medium">{lead.name}</p>
                        {lead.status && (
                          <LeadStatusBadge status={lead.status} />
                        )}
                      </div>
                      <div className="flex gap-4 flex-wrap mt-1 justify-between">
                        {lead.phoneNumber && (
                          <p className="text-xs text-gray-500">{formatPhoneNumber(lead.phoneNumber)}</p>
                        )}
                        {lead.email && (
                          <p className="text-xs text-gray-500 truncate">{lead.email}</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : debouncedSearchTerm && debouncedSearchTerm.length >= 2 ? (
              <div className="p-4 text-center">
                <p className="text-sm text-gray-500">No leads found matching "{debouncedSearchTerm}"</p>
                <Button 
                  variant="link" 
                  className="mt-1"
                  onClick={handleCreateLead}
                  disabled={isCreatingLead || (showNewLeadForm && !newLeadPhone)}
                >
                  {isCreatingLead ? (
                    <>
                      <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                      Creating...
                    </>
                  ) : showNewLeadForm ? (
                    <>
                      Create lead
                    </>
                  ) : (
                    <>
                      Create new lead "{searchTerm}"
                    </>
                  )}
                </Button>
              </div>
            ) : (
              <div className="p-4 text-center">
                <p className="text-sm text-gray-500">
                  {searchTerm.length > 0 && searchTerm.length < 2 
                    ? "Type at least 2 characters to search" 
                    : "Search for a lead to connect"}
                </p>
              </div>
            )}
          </div>
        </div>

        <DialogFooter className="flex space-x-2 justify-end">
          <Button variant="outline" onClick={onClose}>Cancel</Button>
          <Button 
            onClick={handleConnect} 
            disabled={!selectedLeadId || isConnecting}
          >
            {isConnecting ? (
              <>
                <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                Connecting...
              </>
            ) : (
              <>
                <UserPlus className="h-4 w-4 mr-2" />
                Connect
              </>
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
} 