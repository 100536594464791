import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useTeams } from "@/hooks/useTeams";
import { cn } from "@/lib/utils";
import { Shield, Users } from "lucide-react";
import { useState } from "react";
import { toast } from "sonner";

interface TeamInviteDialogProps {
  trigger?: React.ReactNode;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

interface RoleOptionProps {
  value: "admin" | "member";
  title: string;
  description: string;
  icon: React.ReactNode;
  selected: boolean;
  disabled?: boolean;
  onClick: () => void;
}

function RoleOption({ value, title, description, icon, selected, disabled, onClick }: RoleOptionProps) {
  return (
    <div
      onClick={disabled ? undefined : onClick}
      className={cn(
        "relative flex items-start space-x-3 rounded-lg border p-4 transition-colors",
        selected && "border-primary bg-primary/5",
        !selected && "hover:border-primary/50",
        disabled && "opacity-50 cursor-not-allowed",
        !disabled && "cursor-pointer"
      )}
    >
      <div className="flex h-5 items-center">
        <input
          type="checkbox"
          checked={selected}
          onChange={onClick}
          disabled={disabled}
          className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
        />
      </div>
      <div className="min-w-0 flex-1">
        <div className="flex items-center gap-2">
          {icon}
          <h3 className="font-medium">{title}</h3>
        </div>
        <p className="mt-1 text-sm text-muted-foreground">
          {description}
        </p>
      </div>
    </div>
  );
}

export function TeamInviteDialog({ 
  trigger, 
  open: controlledOpen, 
  onOpenChange: setControlledOpen 
}: TeamInviteDialogProps) {
  const [uncontrolledOpen, setUncontrolledOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [role, setRole] = useState<"admin" | "member">("member");
  const { currentTeam, inviteToTeam, isInviting } = useTeams();

  // Determine if the component is controlled or uncontrolled
  const isControlled = controlledOpen !== undefined && setControlledOpen !== undefined;
  const open = isControlled ? controlledOpen : uncontrolledOpen;
  const setOpen = isControlled ? setControlledOpen : setUncontrolledOpen;

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!currentTeam) {
      toast.error("No team selected");
      return;
    }
    
    if (!email.trim()) {
      toast.error("Email is required");
      return;
    }
    
    // Basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast.error("Please enter a valid email address");
      return;
    }
    
    inviteToTeam(
      {
        teamId: currentTeam.id,
        data: { email, role }
      },
      {
        onSuccess: () => {
          setEmail("");
          setRole("member");
          setOpen(false);
        }
      }
    );
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      {trigger && <DialogTrigger asChild>{trigger}</DialogTrigger>}
      <DialogContent className="sm:max-w-[525px]">
        <form onSubmit={handleSubmit}>
          <DialogHeader>
            <DialogTitle>Invite to Team</DialogTitle>
            <DialogDescription>
              {currentTeam 
                ? `Invite someone to join ${currentTeam.name}`
                : "Please select a team first"}
            </DialogDescription>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="email" className="text-right">
                Email
              </Label>
              <Input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="col-span-3"
                placeholder="Enter email address"
                autoFocus
                disabled={!currentTeam || isInviting}
              />
            </div>
            <div className="grid grid-cols-4 items-start gap-4">
              <Label className="text-right pt-4">
                Role
              </Label>
              <div className="col-span-3 space-y-3">
                <RoleOption
                  value="member"
                  title="Team Member"
                  description="Can view and edit team content, but cannot manage team settings or members."
                  icon={<Users className="h-5 w-5 text-primary" />}
                  selected={role === "member"}
                  disabled={!currentTeam || isInviting}
                  onClick={() => setRole("member")}
                />
                <RoleOption
                  value="admin"
                  title="Team Admin"
                  description="Full access to manage team settings, members, and content."
                  icon={<Shield className="h-5 w-5 text-primary" />}
                  selected={role === "admin"}
                disabled={!currentTeam || isInviting}
                  onClick={() => setRole("admin")}
                />
                </div>
            </div>
          </div>
          <DialogFooter>
            <Button 
              type="button" 
              variant="outline" 
              onClick={() => setOpen(false)}
              disabled={isInviting}
            >
              Cancel
            </Button>
            <Button 
              type="submit" 
              disabled={!currentTeam || isInviting}
            >
              {isInviting ? "Sending Invitation..." : "Send Invitation"}
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
} 