import { GlobalSearch } from '@/components/search/GlobalSearch';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { cn } from '@/lib/utils';
import { AIAssistantService, ChatMessage } from '@/services/ai-assistant.service';
import { useCommunicationPaneStore } from '@/store/communicationPaneStore';
import { useRouter } from '@tanstack/react-router';
import { Bell, Loader2, MessageSquare, Sparkles, X } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';
import { Drawer } from 'vaul';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { WebSocketNotifications, useNotificationStore } from '../websocket/WebSocketNotifications';

// Create a store for the AI Chat Support pane
interface AIChatSupportStore {
  isOpen: boolean;
  toggle: () => void;
  open: () => void;
  close: () => void;
  chatHistory: ChatMessage[];
  addMessage: (message: ChatMessage) => void;
  clearHistory: () => void;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}

export const useAIChatSupportStore = create<AIChatSupportStore>()(
  persist(
    (set) => ({
      isOpen: false,
      toggle: () => set((state) => ({ isOpen: !state.isOpen })),
      open: () => set({ isOpen: true }),
      close: () => set({ isOpen: false }),
      chatHistory: [],
      addMessage: (message) => set((state) => ({ 
        chatHistory: [...state.chatHistory, message] 
      })),
      clearHistory: () => set({ chatHistory: [] }),
      isLoading: false,
      setIsLoading: (isLoading) => set({ isLoading }),
    }),
    {
      name: 'ai-chat-support-storage',
    }
  )
);

interface PageContainerProps {
  children: React.ReactNode;
  title?: string;
  description?: string;
  actions?: React.ReactNode;
  className?: string;
}

// Function to safely render HTML content
function SafeHTML({ html }: { html: string }) {
  return (
    <div 
      dangerouslySetInnerHTML={{ __html: html }} 
      className="chat-content"
      style={{
        '--link-color': 'rgb(37, 99, 235)',
        '--link-hover-color': 'rgb(30, 64, 175)',
      } as React.CSSProperties}
    />
  );
}

export function PageContainer({ 
  children, 
  actions,
  className
}: PageContainerProps) {
  const toggle = useCommunicationPaneStore((state) => state.toggle);
  const isOpen = useCommunicationPaneStore((state) => state.isOpen);
  const unreadCount = useNotificationStore((state) => state.unreadCount);
  const router = useRouter();
  const [isMobile, setIsMobile] = useState(false);
  
  // AI Chat Support state
  const { 
    isOpen: isAIChatOpen, 
    toggle: toggleAIChat,
    chatHistory,
    addMessage,
    isLoading,
    setIsLoading,
  } = useAIChatSupportStore();
  
  // Check if we're on the leads page
  const isLeadsPage = router.state.location.pathname.startsWith('/leads');

  // Check if we're on mobile
  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth < 768); // md breakpoint
    };
    
    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);
    
    return () => {
      window.removeEventListener('resize', checkIfMobile);
    };
  }, []);

  // Function to handle sending a message to the AI assistant
  const handleSendMessage = async (message: string) => {
    if (!message.trim()) return;
    
    // Add user message to chat history
    addMessage({ role: 'user', content: message });
    
    // Set loading state
    setIsLoading(true);
    
    try {
      // Call our AI Assistant service
      const response = await AIAssistantService.sendMessage({
        message,
        // We don't need to send history anymore since we're using threads
        // history: chatHistory,
      });
      
      if (response.success && response.response) {
        addMessage({ 
          role: 'assistant', 
          content: response.response,
          isFormatted: response.isFormatted 
        });
      } else {
        // Handle error response
        addMessage({ 
          role: 'assistant', 
          content: response.message || "I'm sorry, I encountered an error processing your request. Please try again later." 
        });
      }
    } catch (error) {
      console.error('Error sending message to AI assistant:', error);
      addMessage({ 
        role: 'assistant', 
        content: "I'm sorry, I encountered an error processing your request. Please try again later." 
      });
    } finally {
      // Clear loading state
      setIsLoading(false);
    }
  };

  // AI Chat Support Component
  const AIChatSupport = () => {
    const [inputValue, setInputValue] = useState('');
    const messagesEndRef = useRef<HTMLDivElement>(null);
    
    const handleSubmit = (e: React.FormEvent) => {
      e.preventDefault();
      handleSendMessage(inputValue);
      setInputValue('');
    };
    
    // Handle clearing the conversation
    const handleClearConversation = () => {
      // Clear the chat history in the store
      useAIChatSupportStore.getState().clearHistory();
      
      // Clear the thread ID to start a new conversation
      AIAssistantService.clearThread();
    };
    
    // Scroll to bottom when chat history changes or when chat is opened
    useEffect(() => {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [chatHistory, isAIChatOpen]);
    
    return (
      <div className="flex flex-col h-full bg-gradient-to-br from-blue-50/50 to-indigo-50/50 dark:from-gray-900 dark:to-gray-900">
        <div className="flex items-center justify-between p-4 border-b">
          <div className="flex items-center gap-2">
            <Sparkles className="h-5 w-5 text-indigo-500" />
            <h2 className="font-semibold text-lg">Support</h2>
          </div>
          <div className="flex items-center gap-2">
            {chatHistory.length > 0 && (
              <Button
                variant="ghost"
                size="sm"
                onClick={handleClearConversation}
                className="text-xs text-gray-500 hover:text-indigo-600"
                title="Clear conversation"
              >
                Clear
              </Button>
            )}
            <Button 
              variant="ghost" 
              size="icon" 
              onClick={toggleAIChat}
              className="h-8 w-8"
            >
              <X className="h-4 w-4" />
            </Button>
          </div>
        </div>
        
        <div className="flex-1 overflow-y-auto p-4 space-y-4">
          {chatHistory.length === 0 ? (
            <div className="flex flex-col items-center justify-center h-full text-center text-muted-foreground">
              <Sparkles className="h-12 w-12 mb-4 text-indigo-500" />
              <h3 className="text-lg font-medium">Welcome to Support</h3>
              <p className="max-w-md mt-2">
                I can help you navigate the platform, understand features, and solve common issues.
                What can I help you with today?
              </p>
            </div>
          ) : (
            <>
              {chatHistory.map((message, index) => (
                <div 
                  key={index} 
                  className={cn(
                    "flex",
                    message.role === 'user' ? "justify-end" : "justify-start"
                  )}
                >
                  <div 
                    className={cn(
                      "max-w-[80%] rounded-lg p-3",
                      message.role === 'user' 
                        ? "bg-gradient-to-r from-blue-500 to-indigo-600 text-white rounded-br-none" 
                        : "bg-white dark:bg-gray-800 shadow-sm rounded-bl-none"
                    )}
                  >
                    {message.isFormatted ? (
                      <SafeHTML html={message.content} />
                    ) : (
                      <p className="text-sm">{message.content}</p>
                    )}
                  </div>
                </div>
              ))}
              {isLoading && (
                <div className="flex justify-start">
                  <div className="bg-white dark:bg-gray-800 shadow-sm rounded-lg rounded-bl-none p-3 flex items-center space-x-2">
                    <Loader2 className="h-4 w-4 animate-spin text-indigo-500" />
                    <span className="text-sm text-gray-500">Thinking...</span>
                  </div>
                </div>
              )}
              <div ref={messagesEndRef} />
            </>
          )}
        </div>
        
        <form onSubmit={handleSubmit} className="p-4 border-t">
          <div className="flex gap-2">
            <input
              type="text"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder="Ask a question..."
              className="flex-1 px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
              disabled={isLoading}
            />
            <Button 
              type="submit" 
              className="bg-gradient-to-r from-blue-500 to-indigo-600 hover:from-blue-600 hover:to-indigo-700 text-white"
              disabled={isLoading || !inputValue.trim()}
            >
              {isLoading ? <Loader2 className="h-4 w-4 animate-spin" /> : 'Send'}
            </Button>
          </div>
        </form>
      </div>
    );
  };

  return (
    <div className="flex-1 flex flex-col min-h-0">
      {/* Page header - hidden on mobile */}
      <header className="hidden lg:block h-16 border-b border-gray-200 sm:mt-0 sm:!flex sm:!flex-col sm:!justify-center">
        <div className="px-4 pt-0 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between">
            <div>
              <GlobalSearch className="w-full" />
            </div>
            <div className="flex items-center gap-4">
              {/* Notifications Popover */}
              <Popover>
                <PopoverTrigger asChild>
                  <Button
                    variant="ghost"
                    size="icon"
                    className="p-0 h-9 w-9 relative"
                    title="Notifications"
                  >
                    <Bell className="h-5 w-5" />
                    {unreadCount > 0 && (
                      <Badge 
                        variant="destructive" 
                        className="absolute -top-1 -right-1 h-4 w-4 p-0 flex items-center justify-center text-[10px]"
                      >
                        {unreadCount > 9 ? '9+' : unreadCount}
                      </Badge>
                    )}
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-80 p-0" align="end">
                  <WebSocketNotifications />
                </PopoverContent>
              </Popover>
              
              {actions}
              
              {/* Communication Pane Toggle - Only show on leads page */}
              {isLeadsPage && (
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={toggle}
                  className="p-0 h-9 w-9"
                  title={isOpen ? "Close Contact Pane" : "Open Contact Pane"}
                >
                  <MessageSquare className="h-5 w-5" />
                </Button>
              )}

              {/* AI Chat Support Button */}
              <Button
                onClick={toggleAIChat}
                className="flex items-center gap-2 px-3 py-2 rounded-md text-sm font-medium text-indigo-600 hover:text-indigo-700 bg-indigo-50/50 hover:bg-indigo-100/60 border border-indigo-500/50 transition-colors duration-200 shadow-none"
                title="Support Chat"
              >
                <Sparkles className="h-4 w-4" />
                <span>Support</span>
              </Button>
            </div>
          </div>
        </div>
      </header>

      {/* Page content */}
      <main className={cn("h-[calc(100vh-4rem)]", className)}>
        <div className="h-full">{children}</div>
      </main>

      {/* AI Chat Support - Desktop (Slide-in from right) */}
      {!isMobile && (
        <div 
          className={cn(
            "fixed top-0 right-0 w-[400px] h-full bg-white dark:bg-gray-900 shadow-lg z-50 transition-transform duration-300 ease-in-out",
            isAIChatOpen ? "translate-x-0" : "translate-x-full"
          )}
        >
          <AIChatSupport />
        </div>
      )}

      {/* AI Chat Support - Mobile (Drawer from bottom) */}
      {isMobile && (
        <Drawer.Root open={isAIChatOpen} onOpenChange={toggleAIChat}>
          <Drawer.Portal>
            <Drawer.Overlay className="fixed inset-0 bg-black/40 z-50" />
            <Drawer.Content className="bg-white dark:bg-gray-900 flex flex-col rounded-t-[10px] h-[90%] mt-24 fixed bottom-0 left-0 right-0 z-50">
              <div className="p-4 bg-white dark:bg-gray-900 rounded-t-[10px] flex-1 overflow-hidden">
                <div className="mx-auto w-12 h-1.5 flex-shrink-0 rounded-full bg-gray-300 dark:bg-gray-700 mb-4" />
                <div className="h-full">
                  <AIChatSupport />
                </div>
              </div>
            </Drawer.Content>
          </Drawer.Portal>
        </Drawer.Root>
      )}
    </div>
  );
} 