import { TabsList, TabsTrigger } from '@/components/ui/tabs';
import { DIDSelector } from '../DIDSelector';

interface CommunicationHeaderProps {
  selectedDID: string | null;
  setSelectedDID: (did: string | null) => void;
  phoneNumber: string;
  isMobile?: boolean;
}

export function CommunicationHeader({
  selectedDID,
  setSelectedDID,
  phoneNumber,
  isMobile = false
}: CommunicationHeaderProps) {
  return (
    <div className="flex-shrink-0 sticky top-0 z-10 bg-white dark:bg-gray-900">
      <div className={`flex items-center justify-between ${isMobile ? 'p-4' : 'p-2'} border-b border-gray-200 dark:border-gray-800`}>
        <h2 className="text-lg font-semibold text-gray-900 dark:text-gray-100 pl-2">Communication</h2>
        <TabsList className="grid grid-cols-2 w-[200px]">
          <TabsTrigger value="voice">Voice</TabsTrigger>
          <TabsTrigger value="sms">Text</TabsTrigger>
        </TabsList>
      </div>
      <div className="p-4 border-b border-gray-200 dark:border-gray-800">
        <DIDSelector
          selectedNumber={selectedDID}
          onSelect={setSelectedDID}
          phoneNumber={phoneNumber}
        />
      </div>
    </div>
  );
} 