import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";
import { useToast } from "@/components/ui/use-toast";
import { useUserNumbers } from "@/hooks/did";
import { useAuth } from '@/hooks/useAuth';
import { formatPhoneNumber } from "@/lib/utils";
import {
  AssistantTemplate,
  ReceptionistSettings,
  aiReceptionistKeys,
  useCreateAssistantTemplate,
  useUpdateReceptionistSettings
} from "@/services/ai-receptionist";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { CheckCircle, Headphones, Loader2, Phone } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { DIDManager } from "../settings/DIDManager";

// Define the schema for the assistant template
const assistantTemplateSchema = z.object({
  name: z.string().min(3, "Name must be at least 3 characters"),
  description: z.string().min(10, "Description must be at least 10 characters"),
  greeting: z.string().min(10, "Greeting must be at least 10 characters"),
  capabilities: z.array(z.string()).min(1, "Select at least one capability"),
});

// Define the schema for call forwarding settings
const callForwardingSchema = z.object({
  forwardToNumber: z.string().min(10, "Please enter a valid phone number"),
  afterRings: z.number().min(1).max(10).default(3),
});

type WizardStep = "create-assistant" | "buy-number" | "configure-settings" | "complete";

interface AIReceptionistWizardProps {
  onComplete: () => void;
  hasTemplates: boolean;
}

export function AIReceptionistWizard({ onComplete, hasTemplates }: AIReceptionistWizardProps) {
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const [currentStep, setCurrentStep] = useState<WizardStep>("create-assistant");
  const [createdTemplate, setCreatedTemplate] = useState<AssistantTemplate | null>(null);
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [selectedNumber, setSelectedNumber] = useState<string | null>(null);
  const { user } = useAuth();
  
  // Fetch user's phone numbers
  const { data: userNumbers } = useUserNumbers();
  
  // Mutations
  const createTemplate = useCreateAssistantTemplate();
  const updateSettings = useUpdateReceptionistSettings();
  
  // Initialize wizard state from local storage
  useEffect(() => {
    try {
      // If user already has templates, we should start at the buy-number step
      if (hasTemplates && (!userNumbers || userNumbers.length === 0)) {
        setCurrentStep("buy-number");
        return;
      }
      
      // If user already has templates, we don't need to show the wizard
      if (hasTemplates && userNumbers && userNumbers.length > 0) {
        return;
      }
      
      if (userNumbers && userNumbers.length > 0) {
        // User already has phone numbers, so we can skip the buy-number step
        setSelectedNumber(userNumbers[0].phoneNumber);
      }
    } catch (error) {
      console.error("Error restoring wizard state:", error);
    }
  }, [hasTemplates, userNumbers]);
  
  // Save wizard state to local storage whenever it changes
  useEffect(() => {
    // Don't save if user already has templates
    if (hasTemplates) {
      return;
    }
    
    // Don't save if we're on the complete step
    if (currentStep === "complete") {
      // Clear the saved state instead
      return;
    }

  }, [currentStep, createdTemplate, selectedNumber, hasTemplates]);
  
  // Form for creating an assistant template
  const templateForm = useForm<z.infer<typeof assistantTemplateSchema>>({
    resolver: zodResolver(assistantTemplateSchema),
    defaultValues: {
      name: `${user?.name}'s Receptionist`,
      description: "You are an AI receptionist that handles calls, qualifies leads, and schedules appointments. You also manage callbacks for warm leads we have called who missed our outreach and are returning the call, whether or not they know the purpose of the call. Your aim is to assist professionally and efficiently.",
      greeting: `${user?.name}'s office, this is Sofia, how can I help you?`,
      capabilities: ["appointment_scheduling", "lead_qualification", "information_collection"],
    }
  });
  
  // Form for call forwarding settings
  const forwardingForm = useForm<z.infer<typeof callForwardingSchema>>({
    resolver: zodResolver(callForwardingSchema),
    defaultValues: {
      forwardToNumber: "",
      afterRings: 3,
    }
  });
  
  // Handle creating an assistant template
  const onCreateTemplate = (data: z.infer<typeof assistantTemplateSchema>) => {
    // Create a complete template with all required fields
    const completeTemplate: AssistantTemplate = {
      ...data,
      fallbackMessage: "I'm sorry, I couldn't understand that. Could you please repeat?",
      leadQualificationQuestions: [
        "What type of insurance are you interested in?",
        "When are you looking to get coverage?",
        "Have you had insurance before?"
      ],
      appointmentSettings: {
        canScheduleAppointments: true,
        availabilityMessage: "I can schedule an appointment with one of our agents.",
        confirmationMessage: "Great! Your appointment has been scheduled."
      }
    };
    
    createTemplate.mutate(completeTemplate, {
      onSuccess: (createdTemplate) => {
        // Store the created template to use it later when enabling the receptionist
        setCreatedTemplate(createdTemplate);
        console.log("Created template:", createdTemplate);
         // Invalidate relevant queries to refresh the UI
        queryClient.invalidateQueries({ queryKey: aiReceptionistKeys.settings() });
        queryClient.invalidateQueries({ queryKey: aiReceptionistKeys.templates() });
        
        toast({
          title: "Template created",
          description: "Your AI Assistant template has been created successfully.",
        });
        
        // If user already has phone numbers, skip the buy number step
        if (userNumbers && userNumbers.length > 0) {
          setSelectedNumber(userNumbers[0].phoneNumber);
          setCurrentStep("configure-settings");
        } else {
          setCurrentStep("buy-number");
        }
      },
      onError: (error) => {
        toast({
          title: "Error creating template",
          description: "There was an error creating your template. Please try again.",
          variant: "destructive"
        });
        console.error("Error creating template:", error);
      }
    });
  };
  
  // Handle buying a phone number
  const handleNumberSelected = (number: string) => {
    setSelectedNumber(number);
    setShowAddDialog(false);
    
    // If user already has templates, we can complete the wizard
    if (hasTemplates) {
      // Invalidate relevant queries to refresh the UI
      queryClient.invalidateQueries({ queryKey: aiReceptionistKeys.settings() });
      queryClient.invalidateQueries({ queryKey: aiReceptionistKeys.templates() });
      
      toast({
        title: "Phone number added",
        description: "Your phone number has been added successfully. You can now receive calls.",
      });
      
      // Complete the wizard
      onComplete();
    } else {
      // Otherwise, proceed to the configure-settings step
      setCurrentStep("configure-settings");
    }
  };
  
  // Handle configuring settings and enabling the receptionist
  const onConfigureSettings = (data: z.infer<typeof callForwardingSchema>) => {
    if (!createdTemplate?.id) {
      toast({
        title: "Error",
        description: "No template was created. Please try again.",
        variant: "destructive"
      });
      return;
    }
    
    // Create the complete settings object
    const settings: ReceptionistSettings = {
      isEnabled: true, // Enable the receptionist
      activeTemplateId: createdTemplate.id, // Set the active template to the newly created one
      workingHours: {
        enabled: false,
        schedule: []
      },
      callForwarding: {
        enabled: true,
        afterRings: data.afterRings,
        forwardToNumber: data.forwardToNumber
      },
      voiceSettings: {
        voiceId: "nova",
        voiceName: "Nova",
        gender: "female",
        style: "professional",
        speed: 1.0
      }
    };
    
    console.log("Activating receptionist with settings:", settings);
    
    updateSettings.mutate(settings, {
      onSuccess: () => {
        // Invalidate relevant queries to refresh the UI
        queryClient.invalidateQueries({ queryKey: aiReceptionistKeys.settings() });
        queryClient.invalidateQueries({ queryKey: aiReceptionistKeys.templates() });
        
        toast({
          title: "Setup complete",
          description: "Your AI Receptionist is now active and ready to handle calls.",
        });
        
        setCurrentStep("complete");
      },
      onError: (error) => {
        toast({
          title: "Error saving settings",
          description: "There was an error saving your settings. Please try again.",
          variant: "destructive"
        });
        console.error("Error saving settings:", error);
      }
    });
  };
  
  // Render the current step
  const renderStep = () => {
    switch (currentStep) {
      case "create-assistant":
        return (
          <Card>
            <CardHeader>
              <CardTitle>Step 1: Create Your AI Assistant</CardTitle>
              <CardDescription>
                Let's create your first AI assistant template to handle your calls
              </CardDescription>
            </CardHeader>
            <CardContent>
              <Form {...templateForm}>
                <form onSubmit={templateForm.handleSubmit(onCreateTemplate)} className="space-y-4">
                  <FormField
                    control={templateForm.control}
                    name="name"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Assistant Name</FormLabel>
                        <FormControl>
                          <Input placeholder="My AI Receptionist" {...field} />
                        </FormControl>
                        <FormDescription>
                          Give your assistant a name for easy identification
                        </FormDescription>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  
                  <FormField
                    control={templateForm.control}
                    name="description"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Description</FormLabel>
                        <FormControl>
                          <Textarea 
                            placeholder="A professional AI receptionist that handles calls, qualifies leads, and schedules appointments." 
                            {...field} 
                          />
                        </FormControl>
                        <FormDescription>
                          Describe what your assistant does and how it helps callers
                        </FormDescription>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  
                  <FormField
                    control={templateForm.control}
                    name="greeting"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Initial Greeting</FormLabel>
                        <FormControl>
                          <Textarea 
                            placeholder="Hello, thank you for calling. This is your AI receptionist. How may I assist you today?" 
                            {...field} 
                          />
                        </FormControl>
                        <FormDescription>
                          This is what the AI will say when it first answers the call
                        </FormDescription>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  
                  <FormField
                    control={templateForm.control}
                    name="capabilities"
                    render={() => (
                      <FormItem>
                        <div className="mb-4">
                          <FormLabel className="text-base">Capabilities</FormLabel>
                          <FormDescription>
                            Select what your AI assistant can do for callers
                          </FormDescription>
                        </div>
                        <div className="grid grid-cols-2 gap-2">
                          {[
                            { id: "appointment_scheduling", label: "Schedule Appointments" },
                            { id: "lead_qualification", label: "Qualify Leads" },
                            { id: "information_collection", label: "Collect Information" },
                            { id: "faq_answering", label: "Answer FAQs" },
                            { id: "small_talk", label: "Small Talk" },
                            { id: "transfer_call", label: "Transfer Calls" },
                            { id: "take_message", label: "Take Messages" },
                            { id: "product_information", label: "Provide Product Info" },
                          ].map((capability) => (
                            <FormField
                              key={capability.id}
                              control={templateForm.control}
                              name="capabilities"
                              render={({ field }) => {
                                return (
                                  <FormItem
                                    key={capability.id}
                                    className="flex flex-row items-start space-x-3 space-y-0"
                                  >
                                    <FormControl>
                                      <input
                                        type="checkbox"
                                        className="h-4 w-4 mt-1"
                                        checked={field.value?.includes(capability.id)}
                                        onChange={(e) => {
                                          const checked = e.target.checked;
                                          const currentValue = field.value || [];
                                          field.onChange(
                                            checked
                                              ? [...currentValue, capability.id]
                                              : currentValue.filter((value) => value !== capability.id)
                                          );
                                        }}
                                      />
                                    </FormControl>
                                    <FormLabel className="font-normal">
                                      {capability.label}
                                    </FormLabel>
                                  </FormItem>
                                );
                              }}
                            />
                          ))}
                        </div>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  
                  <Button 
                    type="submit"
                    disabled={createTemplate.isPending}
                    className="w-full"
                  >
                    {createTemplate.isPending ? (
                      <>
                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                        Creating...
                      </>
                    ) : (
                      "Create Assistant"
                    )}
                  </Button>
                </form>
              </Form>
            </CardContent>
          </Card>
        );
        
      case "buy-number":
        return (
          <Card>
            <CardHeader>
              <CardTitle>Step 2: Get a Phone Number</CardTitle>
              <CardDescription>
                Your AI Receptionist needs a phone number to receive calls
              </CardDescription>
            </CardHeader>
            <CardContent className="space-y-4">
              <div className="bg-blue-50 p-4 rounded-md">
                <p className="text-sm text-blue-700">
                  You need to purchase a phone number for your AI Receptionist to receive calls.
                  Numbers cost $2 per month and can be canceled at any time.
                </p>
              </div>
              
              {selectedNumber ? (
                <div className="p-4 bg-green-50 rounded-md flex items-center gap-3">
                  <CheckCircle className="h-5 w-5 text-green-500" />
                  <div>
                    <p className="font-medium">Number Selected</p>
                    <p className="text-lg">{formatPhoneNumber(selectedNumber)}</p>
                  </div>
                </div>
              ) : (
                <Button 
                  onClick={() => setShowAddDialog(true)}
                  className="w-full"
                >
                  <Phone className="h-4 w-4 mr-2" />
                  Buy a Phone Number
                </Button>
              )}
            </CardContent>
            <CardFooter className="flex justify-between">
              {selectedNumber && (
                <Button onClick={() => setCurrentStep("configure-settings")}>
                  Continue
                </Button>
              )}
            </CardFooter>
          </Card>
        );
        
      case "configure-settings":
        return (
          <Card>
            <CardHeader>
              <CardTitle>Step 3: Configure Call Forwarding</CardTitle>
              <CardDescription>
                Set up call forwarding to handle calls when the AI can't
              </CardDescription>
            </CardHeader>
            <CardContent>
              <Form {...forwardingForm}>
                <form onSubmit={forwardingForm.handleSubmit(onConfigureSettings)} className="space-y-4">
                  <FormField
                    control={forwardingForm.control}
                    name="forwardToNumber"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Forward To Number</FormLabel>
                        <FormControl>
                          <Input 
                            placeholder="e.g., +1 (555) 123-4567" 
                            {...field} 
                          />
                        </FormControl>
                        <FormDescription>
                          Calls will be forwarded to this number if the AI cannot handle them
                        </FormDescription>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  
                  <FormField
                    control={forwardingForm.control}
                    name="afterRings"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Forward After Rings</FormLabel>
                        <Select 
                          onValueChange={(value) => field.onChange(parseInt(value))} 
                          value={field.value?.toString() || "3"}
                        >
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue placeholder="Select number of rings" />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((num) => (
                              <SelectItem key={num} value={num.toString()}>
                                {num} {num === 1 ? 'ring' : 'rings'}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                        <FormDescription>
                          Number of rings before forwarding to your number
                        </FormDescription>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  
                  <div className="pt-4">
                    <div className="flex justify-between">
                      <Button 
                        variant="outline" 
                        onClick={() => setCurrentStep(userNumbers && userNumbers.length > 0 ? "create-assistant" : "buy-number")}
                      >
                        Back
                      </Button>
                      <Button 
                        type="submit"
                        disabled={updateSettings.isPending}
                      >
                        {updateSettings.isPending ? (
                          <>
                            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                            Activating...
                          </>
                        ) : (
                          "Activate Receptionist"
                        )}
                      </Button>
                    </div>
                  </div>
                </form>
              </Form>
            </CardContent>
          </Card>
        );
        
      case "complete":
        return (
          <Card>
            <CardHeader>
              <CardTitle>Setup Complete!</CardTitle>
              <CardDescription>
                Your AI Receptionist is now active and ready to handle calls
              </CardDescription>
            </CardHeader>
            <CardContent className="space-y-4">
              <div className="flex items-center gap-4 p-4 bg-green-50 rounded-md">
                <div className="w-12 h-12 rounded-full flex items-center justify-center bg-green-100">
                  <Headphones className="h-6 w-6 text-green-600" />
                </div>
                <div>
                  <h3 className="font-medium">Active and Ready</h3>
                  <p className="text-sm text-gray-500">
                    Your receptionist is currently active and ready to handle calls
                  </p>
                </div>
              </div>
              
              {selectedNumber && (
                <div className="p-4 bg-gray-50 rounded-lg">
                  <p className="text-xs font-medium text-gray-500">Speak to the receptionist</p>
                  <p className="text-xl">{formatPhoneNumber(selectedNumber)}</p>
                </div>
              )}
              
              {createdTemplate && (
                <div className="p-4 bg-blue-50 rounded-lg">
                  <p className="text-xs font-medium text-gray-500">Active Template</p>
                  <p className="text-sm font-medium">{createdTemplate.name}</p>
                  <p className="text-xs text-gray-600 mt-1">{createdTemplate.description}</p>
                </div>
              )}
              
              <div className="p-4 bg-blue-50 rounded-md">
                <p className="text-sm text-blue-700">
                  You can customize your receptionist further in the Settings tab.
                </p>
              </div>
            </CardContent>
            <CardFooter>
              <Button 
                onClick={() => {
                  onComplete();
                }}
                className="w-full"
              >
                Continue to Dashboard
              </Button>
            </CardFooter>
          </Card>
        );
    }
  };
  
  return (
    <>
      {renderStep()}
      
      {/* Add Number Dialog */}
      <Dialog open={showAddDialog} onOpenChange={setShowAddDialog}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Add New Number</DialogTitle>
            <DialogDescription>
              Search and purchase a new phone number for your account. Numbers are billed at $2 per month.
            </DialogDescription>
          </DialogHeader>
          <div className="py-4">
            <DIDManager
              mode="compact"
              onNumberSelected={handleNumberSelected}
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
} 