import { useState } from 'react';
import { Button } from '../../components/ui/button';
import { Product } from './types';
import { getPlanAction } from './utils';
import { VipPlanCard } from './VipPlanCard';

interface TMSLeadsPlansSectionProps {
  featuredVipPlans: Product[];
  moreVipPlans: Product[];
  currentSubscription: any;
  allProducts: Product[];
  isLoadingProducts: boolean;
  isLoadingSubscription: boolean;
  onSubscribe: (priceId: string) => void;
}

export function TMSLeadsPlansSection({
  featuredVipPlans,
  moreVipPlans,
  currentSubscription,
  allProducts,
  isLoadingProducts,
  isLoadingSubscription,
  onSubscribe
}: TMSLeadsPlansSectionProps) {
  const [showAllVipPlans, setShowAllVipPlans] = useState(false);

  return (
    <>
      <div className="mb-6">
        <h2 className="text-xl font-semibold">Exclusive Packages</h2>
        <p className="text-sm text-muted-foreground mt-1">
          Enhance your lead generation with premium TMS Leads packages. Choose from Bronze, Silver, Gold, or specialized packages.
        </p>
      </div>

      {isLoadingProducts || isLoadingSubscription ? (
        <div className="grid md:grid-cols-3 gap-6 max-w-5xl mx-auto">
          {[1, 2, 3].map((i) => (
            <div key={i} className="h-80 bg-muted rounded-lg animate-pulse"></div>
          ))}
        </div>
      ) : (
        <div className="grid md:grid-cols-3 gap-6 max-w-5xl mx-auto">
          {featuredVipPlans.map(product => (
            <VipPlanCard 
              key={product.id}
              product={product}
              planAction={getPlanAction(product, currentSubscription, allProducts)}
              onSubscribe={onSubscribe}
            />
          ))}
        </div>
      )}
        
      {moreVipPlans.length > 0 && !isLoadingProducts && !isLoadingSubscription && (
        <div className="mt-8 text-center">
          <Button 
            variant="outline" 
            onClick={() => setShowAllVipPlans(!showAllVipPlans)}
            className="px-6 py-2 h-auto text-sm"
          >
            {showAllVipPlans ? "Show Less Plans" : `Explore ${moreVipPlans.length} More Plans`}
          </Button>
        </div>
      )}
        
      {showAllVipPlans && moreVipPlans.length > 0 && !isLoadingProducts && !isLoadingSubscription && (
        <div className="grid md:grid-cols-3 gap-6 max-w-5xl mx-auto mt-8">
          {moreVipPlans.map(product => (
            <VipPlanCard 
              key={product.id}
              product={product}
              planAction={getPlanAction(product, currentSubscription, allProducts)}
              onSubscribe={onSubscribe}
            />
          ))}
        </div>
      )}
    </>
  );
} 