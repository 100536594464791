import { Button } from "@/components/ui/button";
import { ArrowLeft, Brain, CheckCircle, Globe, Heart, Target } from "lucide-react";

export function AboutUs() {
  return (
    <div className="min-h-screen bg-gray-50">
      <div className="container mx-auto px-4 py-12">
        {/* Back Button */}
        <a href="https://leadgpt.com" className="inline-block mb-8">
          <Button variant="ghost" className="gap-2">
            <ArrowLeft className="h-4 w-4" />
            Back to Home
          </Button>
        </a>

        <div className="max-w-4xl mx-auto">
          {/* Hero Section */}
          <div className="bg-white rounded-xl shadow-sm p-8 md:p-12 mb-8">
            <div className="flex items-center gap-4 mb-6">
              <div className="h-12 w-12 rounded-full bg-primary/10 flex items-center justify-center">
                <Brain className="h-6 w-6 text-primary" />
              </div>
              <div>
                <h1 className="text-3xl md:text-4xl font-bold">About LeadGPT</h1>
                <p className="text-gray-600">Transforming Insurance Sales with AI</p>
              </div>
            </div>
            
            <p className="text-lg text-gray-600 mb-6">
              LeadGPT was founded with a simple mission: to empower insurance agents with AI technology that makes their work more efficient, effective, and enjoyable.
            </p>

            <div className="grid md:grid-cols-3 gap-4">
              {[
                {
                  title: "Founded",
                  value: "2023",
                  description: "San Francisco, CA"
                },
                {
                  title: "Agents Served",
                  value: "1,000+",
                  description: "Across the US"
                },
                {
                  title: "Policies Closed",
                  value: "$10M+",
                  description: "In premiums"
                }
              ].map((stat, index) => (
                <div key={index} className="bg-gray-50 p-4 rounded-lg">
                  <p className="text-sm text-gray-600">{stat.title}</p>
                  <p className="text-2xl font-bold mb-1">{stat.value}</p>
                  <p className="text-sm text-gray-600">{stat.description}</p>
                </div>
              ))}
            </div>
          </div>

          {/* Main Content */}
          <div className="space-y-8">
            {/* Our Story */}
            <div className="bg-white rounded-xl shadow-sm p-8 md:p-12">
              <h2 className="text-2xl font-bold mb-6">Our Story</h2>
              <div className="prose prose-gray max-w-none">
                <p className="mb-4">
                  LeadGPT began when our founders, experienced insurance industry veterans, recognized a critical gap in the market. While insurance agents were drowning in administrative tasks and struggling to keep up with leads, AI technology was revolutionizing other industries.
                </p>
                <p className="mb-4">
                  We assembled a team of AI experts and insurance professionals to build a platform that would address the unique challenges faced by insurance agents. Our goal was to create a solution that would not just automate tasks, but provide intelligent insights that help agents close more deals.
                </p>
                <p>
                  Today, LeadGPT is trusted by insurance agents across the country, helping them work smarter, not harder. Our platform continues to evolve with new features and capabilities, always guided by our commitment to our users' success.
                </p>
              </div>
            </div>

            {/* Mission & Values */}
            <div className="bg-white rounded-xl shadow-sm p-8 md:p-12">
              <h2 className="text-2xl font-bold mb-6">Mission & Values</h2>
              
              <div className="mb-8">
                <div className="flex items-center gap-3 mb-4">
                  <div className="w-10 h-10 rounded-lg bg-primary/10 flex items-center justify-center">
                    <Target className="h-5 w-5 text-primary" />
                  </div>
                  <h3 className="text-xl font-bold">Our Mission</h3>
                </div>
                <p className="text-gray-600">
                  To empower insurance agents with AI technology that enhances their productivity, improves client relationships, and drives business growth.
                </p>
              </div>

              <h3 className="text-xl font-bold mb-6">Our Values</h3>
              <div className="grid md:grid-cols-3 gap-6">
                {[
                  {
                    icon: <Heart className="h-6 w-6 text-red-500" />,
                    title: "Customer First",
                    description: "Every feature and decision is guided by our users' needs and success."
                  },
                  {
                    icon: <Brain className="h-6 w-6 text-purple-500" />,
                    title: "Innovation",
                    description: "We continuously push the boundaries of what's possible with AI technology."
                  },
                  {
                    icon: <Globe className="h-6 w-6 text-blue-500" />,
                    title: "Impact",
                    description: "We measure our success by the positive change we create in the insurance industry."
                  }
                ].map((value, index) => (
                  <div key={index} className="bg-gray-50 p-6 rounded-lg">
                    <div className="w-12 h-12 rounded-lg bg-white flex items-center justify-center mb-4">
                      {value.icon}
                    </div>
                    <h4 className="font-bold mb-2">{value.title}</h4>
                    <p className="text-sm text-gray-600">{value.description}</p>
                  </div>
                ))}
              </div>
            </div>

            {/* Leadership Team */}
            <div className="bg-white rounded-xl shadow-sm p-8 md:p-12">
              <h2 className="text-2xl font-bold mb-6">Leadership Team</h2>
              <div className="grid md:grid-cols-3 gap-6">
                {[
                  {
                    name: "Sarah Johnson",
                    role: "CEO & Co-founder",
                    bio: "15+ years in insurtech, former VP at a leading insurance carrier"
                  },
                  {
                    name: "Michael Chen",
                    role: "CTO & Co-founder",
                    bio: "AI researcher and engineer, previously led ML teams at top tech companies"
                  },
                  {
                    name: "Emily Rodriguez",
                    role: "Head of Product",
                    bio: "Product leader with extensive experience in B2B SaaS and insurance"
                  }
                ].map((member, index) => (
                  <div key={index} className="bg-gray-50 p-6 rounded-lg">
                    <div className="w-20 h-20 bg-gray-200 rounded-full mb-4"></div>
                    <h4 className="font-bold mb-1">{member.name}</h4>
                    <p className="text-sm text-primary mb-2">{member.role}</p>
                    <p className="text-sm text-gray-600">{member.bio}</p>
                  </div>
                ))}
              </div>
            </div>

            {/* Company Culture */}
            <div className="bg-white rounded-xl shadow-sm p-8 md:p-12">
              <h2 className="text-2xl font-bold mb-6">Our Culture</h2>
              <div className="prose prose-gray max-w-none mb-8">
                <p>
                  At LeadGPT, we foster a culture of innovation, collaboration, and continuous learning. Our team is passionate about using technology to solve real-world problems and make a difference in the insurance industry.
                </p>
              </div>

              <div className="grid md:grid-cols-2 gap-6">
                {[
                  {
                    title: "Work Environment",
                    items: [
                      "Remote-first culture",
                      "Flexible work hours",
                      "Regular team events",
                      "Continuous learning opportunities"
                    ]
                  },
                  {
                    title: "Benefits & Perks",
                    items: [
                      "Competitive compensation",
                      "Health and wellness benefits",
                      "Professional development budget",
                      "Stock options"
                    ]
                  }
                ].map((section, index) => (
                  <div key={index} className="bg-gray-50 p-6 rounded-lg">
                    <h3 className="text-xl font-bold mb-4">{section.title}</h3>
                    <ul className="space-y-3">
                      {section.items.map((item, i) => (
                        <li key={i} className="flex items-start gap-3">
                          <CheckCircle className="h-5 w-5 text-green-500 mt-0.5 flex-shrink-0" />
                          <span>{item}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>

            {/* Contact Section */}
            <div className="bg-white rounded-xl shadow-sm p-8 md:p-12">
              <h2 className="text-2xl font-bold mb-6">Get in Touch</h2>
              <div className="grid md:grid-cols-2 gap-6">
                <div>
                  <h3 className="text-xl font-bold mb-4">Office Location</h3>
                  <p className="text-gray-600 mb-2">123 Innovation Street</p>
                  <p className="text-gray-600 mb-2">Suite 200</p>
                  <p className="text-gray-600 mb-4">San Francisco, CA 94105</p>
                  <p className="text-gray-600">Email: hello@leadgpt.com</p>
                  <p className="text-gray-600">Phone: (800) 123-4567</p>
                </div>
                <div>
                  <h3 className="text-xl font-bold mb-4">Follow Us</h3>
                  <div className="space-y-2">
                    <a href="#" className="text-primary hover:underline block">LinkedIn</a>
                    <a href="#" className="text-primary hover:underline block">Twitter</a>
                    <a href="#" className="text-primary hover:underline block">Facebook</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
} 