import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { ArrowLeft, Building2, Clock, Globe2, Headphones, Mail, MapPin, MessageSquare, Phone } from "lucide-react";

export function Contact() {
  return (
    <div className="min-h-screen bg-gray-50">
      <div className="container mx-auto px-4 py-12">
        {/* Back Button */}
        <a href="https://leadgpt.com" className="inline-block mb-8">
          <Button variant="ghost" className="gap-2">
            <ArrowLeft className="h-4 w-4" />
            Back to Home
          </Button>
        </a>

        <div className="max-w-6xl mx-auto">
          {/* Hero Section */}
          <div className="text-center mb-12">
            <h1 className="text-4xl font-bold mb-4">Get in Touch</h1>
            <p className="text-lg text-gray-600 max-w-2xl mx-auto">
              Have questions about LeadGPT? We're here to help. Choose your preferred way to reach us below.
            </p>
          </div>

          {/* Contact Methods Grid */}
          <div className="grid md:grid-cols-3 gap-6 mb-12">
            {[
              {
                icon: <Phone className="h-6 w-6 text-primary" />,
                title: "Call Us",
                description: "Speak directly with our team",
                contact: "(800) 123-4567",
                action: "Call now"
              },
              {
                icon: <Mail className="h-6 w-6 text-primary" />,
                title: "Email Us",
                description: "Get in touch via email",
                contact: "support@leadgpt.com",
                action: "Send email"
              },
              {
                icon: <MessageSquare className="h-6 w-6 text-primary" />,
                title: "Live Chat",
                description: "Chat with our support team",
                contact: "Available 24/7",
                action: "Start chat"
              }
            ].map((method, index) => (
              <div key={index} className="bg-white p-6 rounded-xl shadow-sm">
                <div className="w-12 h-12 rounded-lg bg-primary/10 flex items-center justify-center mb-4">
                  {method.icon}
                </div>
                <h3 className="text-xl font-bold mb-2">{method.title}</h3>
                <p className="text-gray-600 mb-4">{method.description}</p>
                <p className="font-medium mb-4">{method.contact}</p>
                <Button variant="outline" className="w-full">
                  {method.action}
                </Button>
              </div>
            ))}
          </div>

          <div className="grid md:grid-cols-2 gap-8">
            {/* Contact Form */}
            <div className="bg-white p-8 rounded-xl shadow-sm">
              <h2 className="text-2xl font-bold mb-6">Send Us a Message</h2>
              <form className="space-y-4">
                <div className="grid md:grid-cols-2 gap-4">
                  <div>
                    <label htmlFor="firstName" className="block text-sm font-medium text-gray-700 mb-1">
                      First Name
                    </label>
                    <Input id="firstName" placeholder="John" />
                  </div>
                  <div>
                    <label htmlFor="lastName" className="block text-sm font-medium text-gray-700 mb-1">
                      Last Name
                    </label>
                    <Input id="lastName" placeholder="Doe" />
                  </div>
                </div>
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                    Email
                  </label>
                  <Input id="email" type="email" placeholder="john@example.com" />
                </div>
                <div>
                  <label htmlFor="subject" className="block text-sm font-medium text-gray-700 mb-1">
                    Subject
                  </label>
                  <Input id="subject" placeholder="How can we help?" />
                </div>
                <div>
                  <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-1">
                    Message
                  </label>
                  <Textarea
                    id="message"
                    placeholder="Tell us more about your inquiry..."
                    className="min-h-[120px]"
                  />
                </div>
                <Button className="w-full">Send Message</Button>
              </form>
            </div>

            {/* Office Locations */}
            <div>
              <h2 className="text-2xl font-bold mb-6">Our Offices</h2>
              <div className="space-y-6">
                {[
                  {
                    city: "San Francisco",
                    country: "United States",
                    address: "123 Market Street, Suite 100",
                    postal: "CA 94105",
                    phone: "+1 (800) 123-4567",
                    email: "sf@leadgpt.com",
                    hours: "Mon-Fri: 9:00 AM - 6:00 PM PT"
                  },
                  {
                    city: "London",
                    country: "United Kingdom",
                    address: "456 Oxford Street",
                    postal: "W1C 1AP",
                    phone: "+44 20 7123 4567",
                    email: "london@leadgpt.com",
                    hours: "Mon-Fri: 9:00 AM - 6:00 PM GMT"
                  },
                  {
                    city: "Singapore",
                    country: "Singapore",
                    address: "789 Marina Bay",
                    postal: "018956",
                    phone: "+65 6789 0123",
                    email: "singapore@leadgpt.com",
                    hours: "Mon-Fri: 9:00 AM - 6:00 PM SGT"
                  }
                ].map((office, index) => (
                  <div key={index} className="bg-white p-6 rounded-xl shadow-sm">
                    <div className="flex items-start gap-4">
                      <div className="w-10 h-10 rounded-lg bg-primary/10 flex items-center justify-center flex-shrink-0">
                        <Building2 className="h-5 w-5 text-primary" />
                      </div>
                      <div>
                        <h3 className="text-lg font-bold mb-2">{office.city}</h3>
                        <div className="space-y-2 text-sm text-gray-600">
                          <p className="flex items-center gap-2">
                            <Globe2 className="h-4 w-4" />
                            {office.country}
                          </p>
                          <p className="flex items-center gap-2">
                            <MapPin className="h-4 w-4" />
                            {office.address}, {office.postal}
                          </p>
                          <p className="flex items-center gap-2">
                            <Phone className="h-4 w-4" />
                            {office.phone}
                          </p>
                          <p className="flex items-center gap-2">
                            <Mail className="h-4 w-4" />
                            {office.email}
                          </p>
                          <p className="flex items-center gap-2">
                            <Clock className="h-4 w-4" />
                            {office.hours}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Support Resources */}
          <div className="mt-12 bg-white rounded-xl shadow-sm p-8">
            <h2 className="text-2xl font-bold mb-6">Additional Support Resources</h2>
            <div className="grid md:grid-cols-3 gap-6">
              {[
                {
                  icon: <Headphones className="h-6 w-6 text-primary" />,
                  title: "Help Center",
                  description: "Find answers to frequently asked questions and detailed product guides.",
                  action: "Visit Help Center"
                },
                {
                  icon: <MessageSquare className="h-6 w-6 text-primary" />,
                  title: "Community Forum",
                  description: "Connect with other users and share experiences in our community.",
                  action: "Join Community"
                },
                {
                  icon: <Globe2 className="h-6 w-6 text-primary" />,
                  title: "Developer Portal",
                  description: "Access API documentation and integration resources.",
                  action: "View Documentation"
                }
              ].map((resource, index) => (
                <div key={index} className="p-6 bg-gray-50 rounded-lg">
                  <div className="w-12 h-12 rounded-lg bg-white flex items-center justify-center mb-4">
                    {resource.icon}
                  </div>
                  <h3 className="font-bold mb-2">{resource.title}</h3>
                  <p className="text-sm text-gray-600 mb-4">{resource.description}</p>
                  <Button variant="ghost" className="w-full">
                    {resource.action}
                  </Button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
} 