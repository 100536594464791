import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { api } from "@/lib/api";
import { Call, Lead } from "@/types";
import { useQuery } from "@tanstack/react-query";
import { format, formatDistanceToNow, formatISO } from "date-fns";
import { AlertCircle, CheckCircle, Clock, MessageSquare, ThumbsDown, ThumbsUp } from "lucide-react";
import { useEffect, useState } from "react";
import { toast } from "sonner";

interface CallLogDialogProps {
  lead: Lead;
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export function CallLogDialog({ lead, open, onOpenChange }: CallLogDialogProps) {
  const [notes, setNotes] = useState("");
  const [datetime, setDatetime] = useState(() => {
    const now = new Date();
    return format(now, "yyyy-MM-dd'T'HH:mm");
  });

  // Reset form when dialog opens
  useEffect(() => {
    if (open) {
      setNotes("");
      setDatetime(format(new Date(), "yyyy-MM-dd'T'HH:mm"));
    }
  }, [open]);

  // Get today's date without time component
  const minDate = format(new Date(), "yyyy-MM-dd");

  const { data: callsData, refetch } = useQuery({
    queryKey: ["calls", lead.id],
    queryFn: async () => {
      const response = await api.calls.list(lead.id);
      return response;
    },
    enabled: open,
  });

  const handleSubmit = async () => {
    if (!notes.trim()) {
      toast.error("Please enter call notes");
      return;
    }

    try {
      const datetimeISO = formatISO(new Date(datetime));

      const response = await api.calls.create({
        leadId: lead.id,
        notes,
        datetime: datetimeISO,
      });

      if (response.success) {
        toast.success("Call log added successfully");
        setNotes("");
        setDatetime(format(new Date(), "yyyy-MM-dd'T'HH:mm"));
        refetch();
      }
    } catch (error) {
      toast.error("Failed to add call log");
    }
  };

  // Helper function to render sentiment score
  const renderSentimentIcon = (score: number) => {
    if (score >= 0.5) return <ThumbsUp className="h-4 w-4 text-green-500" />;
    if (score <= -0.5) return <ThumbsDown className="h-4 w-4 text-red-500" />;
    return null;
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-2xl max-h-[90vh] sm:max-h-[85vh] p-0 overflow-hidden flex flex-col">
        <DialogHeader className="px-6 pt-6 pb-2 sticky top-0 bg-white z-10 border-b">
          <DialogTitle className="text-xl">Add External Call - {lead.name}</DialogTitle>
        </DialogHeader>

        <div className="flex-1 overflow-y-auto px-6 pb-4">
          <div className="grid gap-6">
            {/* New Call Entry */}
            <div className="space-y-4">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div className="space-y-2">
                  <label className="text-sm font-medium">Date & Time</label>
                  <Input
                    className="w-[250px]"
                    type="datetime-local"
                    value={datetime}
                    min={minDate}
                    onChange={(e) => setDatetime(e.target.value)}
                  />
                </div>
              </div>
              <div className="space-y-2">
                <label className="text-sm font-medium">Notes</label>
                <Textarea
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  placeholder="Enter call notes..."
                  className="h-24"
                />
              </div>
              <Button onClick={handleSubmit} className="w-full">
                Add External Call
              </Button>
            </div>

            {/* Call History */}
            {callsData?.calls?.length > 0 && (
              <div className="space-y-4">
                <h3 className="font-semibold text-lg">Call History</h3>
                <div className="space-y-4">
                  {callsData.calls.map((call: Call & { notes?: string }) => (
                    <div
                      key={call.id}
                      className="rounded-lg border p-4 space-y-2"
                    >
                      <div className="flex items-start justify-between">
                        <div className="space-y-2 w-full">
                          {/* Display notes or summary based on what's available */}
                          {call.notes ? (
                            <p className="text-sm whitespace-pre-wrap break-words">{call.notes}</p>
                          ) : call.analytics && call.analytics.length > 0 && call.analytics[0].summary ? (
                            <div className="flex gap-2">
                              <MessageSquare className="h-4 w-4 text-blue-500 mt-0.5 flex-shrink-0" />
                              <p className="text-sm text-gray-700 break-words">{call.analytics[0].summary}</p>
                            </div>
                          ) : (
                            <p className="text-sm text-gray-500 italic">No notes available</p>
                          )}
                          
                          {/* Call Analytics - Only show key points and action items if notes exist */}
                          {call.analytics && call.analytics.length > 0 && (
                            <div className={`mt-3 space-y-2 ${call.notes ? 'bg-gray-50 p-3 rounded-md' : ''}`}>
                              {/* Don't repeat summary if we're already showing it above */}
                              {call.notes && call.analytics[0].summary && (
                                <div className="flex gap-2">
                                  <MessageSquare className="h-4 w-4 text-blue-500 mt-0.5 flex-shrink-0" />
                                  <p className="text-xs text-gray-700 break-words">{call.analytics[0].summary}</p>
                                </div>
                              )}
                              
                              {call.analytics[0].keyPoints && call.analytics[0].keyPoints.length > 0 && (
                                <div className="flex gap-2">
                                  <CheckCircle className="h-4 w-4 text-green-500 mt-0.5 flex-shrink-0" />
                                  <div className="flex-1 min-w-0">
                                    <p className="text-xs font-medium text-gray-700 mb-1">Key Points:</p>
                                    <ul className="list-disc pl-4 space-y-1">
                                      {call.analytics[0].keyPoints.slice(0, 3).map((point, idx) => (
                                        <li key={idx} className="text-xs text-gray-600 break-words">{point}</li>
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                              )}
                              
                              {call.analytics[0].actionItems && call.analytics[0].actionItems.length > 0 && (
                                <div className="flex gap-2">
                                  <AlertCircle className="h-4 w-4 text-amber-500 mt-0.5 flex-shrink-0" />
                                  <div className="flex-1 min-w-0">
                                    <p className="text-xs font-medium text-gray-700 mb-1">Action Items:</p>
                                    <ul className="list-disc pl-4 space-y-1">
                                      {call.analytics[0].actionItems.slice(0, 2).map((item, idx) => (
                                        <li key={idx} className="text-xs text-gray-600 break-words">{item}</li>
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                              )}
                              
                              <div className="flex flex-wrap items-center justify-between gap-2 pt-1">
                                {call.analytics[0].sentimentScore !== undefined && (
                                  <div className="flex items-center gap-1">
                                    {renderSentimentIcon(call.analytics[0].sentimentScore)}
                                    <span className="text-xs text-gray-500">
                                      Sentiment: {call.analytics[0].sentimentScore > 0 ? 'Positive' : 
                                                  call.analytics[0].sentimentScore < 0 ? 'Negative' : 'Neutral'}
                                    </span>
                                  </div>
                                )}
                                
                                {call.analytics[0].leadScore !== undefined && (
                                  <Badge variant="outline" className="text-xs">
                                    Lead Score: {Math.round(call.analytics[0].leadScore * 100)}
                                  </Badge>
                                )}
                              </div>
                            </div>
                          )}
                          
                          <p className="text-xs text-gray-500 mt-2">
                            {call.direction && (
                              <span className="capitalize">{call.direction} · </span>
                            )}
                            {formatDistanceToNow(new Date(call.datetime))} ago ·{" "}
                            {format(new Date(call.datetime), "MMM d 'at' h:mm a")}
                          </p>
                        </div>
                        {call.lengthInSec && (
                          <div className="flex items-center text-xs text-gray-500 ml-2 flex-shrink-0">
                            <Clock className="h-3 w-3 mr-1" />
                            <span>
                              {call.lengthInSec > 3600
                                ? format(new Date(call.lengthInSec * 1000), "H:mm:ss")
                                : format(new Date(call.lengthInSec * 1000), "m:ss")}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}

                  {/* Lead Notes */}
                  {lead.comments && (
                    <div className="rounded-lg border p-4 space-y-2 bg-gray-50">
                      <div>
                        <p className="text-sm whitespace-pre-wrap break-words">{lead.comments}</p>
                        <p className="text-xs text-gray-500 mt-2">
                          Lead Notes ·{" "}
                          {formatDistanceToNow(
                            new Date(lead.callDate || lead.createdAt)
                          )}{" "}
                          ago ·{" "}
                          {format(
                            new Date(lead.callDate || lead.createdAt),
                            "MMM d 'at' h:mm a"
                          )}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
} 