import { FeedbackButton } from '@/components/feedback/FeedbackButton';
import { HelpCenterButton } from '@/components/help/HelpCenterButton';
import { CreateLeadModal } from '@/components/leads/CreateLeadModal';
import { LeadImportModal } from '@/components/leads/LeadImportModal';
import { TeamSidebarSection } from '@/components/teams/TeamSidebarSection';
import { Button } from '@/components/ui/button';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { CalendarService } from '@/services/calendar.service';
import { useAuthStore } from '@/store/authStore';
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
import { useQuery } from '@tanstack/react-query';
import { Link, useMatches, useNavigate } from '@tanstack/react-router';
import { Brain } from 'lucide-react';
import { useMemo, useState } from 'react';
import { IconType } from 'react-icons';
import {
  HiOutlineCalendar,
  HiOutlineChartBar,
  HiOutlineCog,
  HiOutlineHome,
  HiOutlineLockClosed,
  HiOutlineLogout,
  HiOutlinePhone,
  HiOutlinePlus,
  HiOutlinePuzzle,
  HiOutlineUsers,
  HiOutlineX
} from 'react-icons/hi';
import { Avatar } from '../ui/avatar';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../ui/dialog';
import { Logo } from '../ui/logo';

interface SidebarProps {
  isOpen: boolean;
  onClose: () => void;
}

interface NavItem {
  name: string;
  path: string;
  icon: IconType;
  disabled?: boolean;
}

interface LinkStateProps {
  isActive: boolean;
}

// Main navigation items
const mainNavigationItems: NavItem[] = [
  { name: 'Dashboard', path: '/dashboard', icon: HiOutlineHome },
  { name: 'Leads', path: '/leads', icon: HiOutlineUsers },
  { name: 'Receptionist', path: '/ai-receptionist', icon: HiOutlinePhone },
  { name: 'Analytics', path: '/metrics', icon: HiOutlineChartBar },
  // { name: 'Analytics', path: '/analytics', icon: HiOutlineChartBar, disabled: true },
];

// Utility navigation items
const utilityNavigationItems: NavItem[] = [
  { name: 'Integrations', path: '/integrations', icon: HiOutlinePuzzle },
  { name: 'Settings', path: '/settings', icon: HiOutlineCog },
];

const baseNavItemStyles = "group flex items-center rounded-lg px-3 py-2.5 text-sm font-medium transition-colors";
const activeNavItemStyles = "bg-blue-50 text-blue-700";
const inactiveNavItemStyles = "text-gray-700 hover:bg-gray-50 hover:text-gray-900";

const baseIconStyles = "mr-3 h-5 w-5 shrink-0";
const activeIconStyles = "text-blue-700";
const inactiveIconStyles = "text-gray-400 group-hover:text-gray-900";

const getIconClass = ({ isActive }: LinkStateProps): string => {
  return `${baseIconStyles} ${isActive ? activeIconStyles : inactiveIconStyles}`;
};

// Helper function to render navigation items
const renderNavItem = (item: NavItem) => {
  const Icon = item.icon;
  
  // If the item is disabled, render it differently
  if (item.disabled) {
    return (
      <TooltipProvider key={item.path}>
        <Tooltip>
          <TooltipTrigger asChild>
            <div
              className={`${baseNavItemStyles} text-gray-400 cursor-not-allowed`}
            >
              <Icon className={`${baseIconStyles} text-gray-300`} />
              {item.name}
              <HiOutlineLockClosed className="ml-auto h-4 w-4 text-gray-300" />
            </div>
          </TooltipTrigger>
          <TooltipContent>
            <p>Coming soon!</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  }
  
  // Otherwise, render the normal link
  return (
    <Link
      key={item.path}
      to={item.path}
      search={{}}
      params={{}}
      preload="intent"
      activeProps={{
        className: `${baseNavItemStyles} ${activeNavItemStyles}`
      }}
      inactiveProps={{
        className: `${baseNavItemStyles} ${inactiveNavItemStyles}`
      }}
    >
      {({ isActive }) => (
        <>
          <Icon className={getIconClass({ isActive })} />
          {item.name}
        </>
      )}
    </Link>
  );
};

export function Sidebar({ isOpen, onClose }: SidebarProps) {
  const navigate = useNavigate();
  const logout = useAuthStore((state) => state.logout);
  const user = useAuthStore((state) => state.user);
  const [isAddLeadsModalOpen, setIsAddLeadsModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState<string>("create");
  const matches = useMatches();
  
  // Check if user has Google Calendar integration
  const { data: integrations } = useQuery({
    queryKey: ['sidebarCalendarIntegrations'],
    queryFn: () => CalendarService.getCalendarIntegrations(),
    refetchOnWindowFocus: false,
    staleTime: 5 * 60 * 1000, // 5 minutes
  });
  
  const hasGoogleCalendar = useMemo(() => {
    return integrations?.some(i => i.provider === 'google' && i.syncEnabled);
  }, [integrations]);
  const handleLogout = () => {
    logout();
    navigate({ 
      to: '/auth',
      search: { 
        redirect: '/dashboard',
        tab: undefined,
        invitation: undefined
      },
      replace: true 
    });
  };

  const handleAddLeadsClick = () => {
    setIsAddLeadsModalOpen(true);
  };

  return (
    <>
      {/* Backdrop */}
      {isOpen && (
        <div
          className="fixed inset-0 z-40 bg-gray-600 bg-opacity-75 transition-opacity md:hidden"
          onClick={onClose}
        />
      )}

      {/* Sidebar */}
      <div
        className={`fixed inset-y-0 left-0 z-50 flex w-64 flex-col bg-white shadow-lg transition-transform duration-300 ease-in-out lg:relative lg:translate-x-0 ${
          isOpen ? 'translate-x-0' : '-translate-x-full'
        }`}
      >
        {/* Logo Section */}
        <div className="flex h-16 shrink-0 items-center justify-between border-b border-gray-200 px-4">
          <div className="flex items-center gap-2">
            <Avatar className="h-8 w-8 rounded-lg">
              <Brain className="h-8 w-8 text-sky-200 bg-black p-1 rounded-lg" />
            </Avatar>
            <Logo className="w-28" />
          </div>
          <button
            onClick={onClose}
            className="rounded-lg p-1 hover:bg-gray-100 md:hidden"
            aria-label="Close sidebar"
          >
            <HiOutlineX className="h-6 w-6 text-gray-500" />
          </button>
        </div>

        {/* Team Section */}
        <div className="border-b border-gray-200">
          <TeamSidebarSection />
        </div>

        {/* Add Leads Button */}
        <div className="px-3 py-3 border-b border-gray-200">
          <Button 
            onClick={handleAddLeadsClick}
            className="w-full flex items-center justify-center gap-2 bg-primary hover:bg-blue-600 text-white"
          >
            <HiOutlinePlus className="h-5 w-5" />
            Add Leads
          </Button>
        </div>

        {/* Navigation */}
        <div className="flex-1 overflow-y-auto px-3 py-4">
          <nav className="flex flex-col gap-1">
            {/* Main Navigation Items */}
            {mainNavigationItems.map(renderNavItem)}
            
            {/* Calendar Dashboard link - only shown if Google Calendar is integrated */}
            {hasGoogleCalendar && (
              <Link
                to="/calendar-dashboard"
                search={{}}
                params={{}}
                preload="intent"
                activeProps={{
                  className: `${baseNavItemStyles} ${activeNavItemStyles}`
                }}
                inactiveProps={{
                  className: `${baseNavItemStyles} ${inactiveNavItemStyles}`
                }}
              >
                {({ isActive }) => (
                  <>
                    <HiOutlineCalendar className={getIconClass({ isActive })} />
                    Calendar
                  </>
                )}
              </Link>
            )}

            {/* Divider */}
            <div className="my-3 h-px bg-gray-200" />
            
            {/* Utility Navigation Items */}
            {utilityNavigationItems.map(renderNavItem)}
          </nav>
        </div>

        {/* Help Center Button */}
        <div className="px-3 pb-2">
          <HelpCenterButton variant="sidebar" />
        </div>

        {/* Feedback Button */}
        <div className="px-3 pb-2">
          <FeedbackButton variant="sidebar" />
        </div>

        {/* User Profile */}
        <div className="shrink-0 border-t border-gray-200 p-4">
          <div className="flex items-center gap-3">
            <img
              src={user?.profilePhotoPath || "https://ui-avatars.com/api/?background=c7d2fe&color=3730a3&name=" + user?.name}
              alt={user?.name || 'User Name'}
              className="h-9 w-9 rounded-full bg-gray-50"
            />
            <div className="min-w-0 flex-1">
              <p className="truncate text-sm font-medium text-gray-900">{user?.name || 'User'}</p>
              <p className="truncate text-xs text-gray-500">{user?.email || 'user@example.com'}</p>
            </div>
            <button
              onClick={handleLogout}
              className="rounded-lg p-2 text-gray-500 hover:bg-gray-100 hover:text-gray-700"
              aria-label="Logout"
            >
              <HiOutlineLogout className="h-5 w-5" />
            </button>
          </div>
        </div>
      </div>

      {/* Add Leads Modal with Tabs */}
      <Dialog open={isAddLeadsModalOpen} onOpenChange={setIsAddLeadsModalOpen}>
        <DialogContent className="sm:max-w-[800px] max-h-[90vh] overflow-y-auto p-0">
          <VisuallyHidden asChild><DialogHeader><DialogTitle>Add Leads</DialogTitle></DialogHeader></VisuallyHidden>
          <Tabs defaultValue="create" value={activeTab} onValueChange={setActiveTab} className="w-full">
            <div className="border-b border-gray-200">
              <TabsList className="w-full h-auto p-0 bg-transparent">
                <TabsTrigger 
                  value="create" 
                  className="flex-1 py-3 rounded-none data-[state=active]:border-b-2 data-[state=active]:border-blue-600 data-[state=active]:shadow-none"
                >
                  Create Single Lead
                </TabsTrigger>
                <TabsTrigger 
                  value="import" 
                  className="flex-1 py-3 rounded-none data-[state=active]:border-b-2 data-[state=active]:border-blue-600 data-[state=active]:shadow-none"
                >
                  Import Leads
                </TabsTrigger>
              </TabsList>
            </div>
            
            <TabsContent value="create" className="p-0 m-0">
              <CreateLeadModal 
                isOpen={isAddLeadsModalOpen && activeTab === "create"} 
                onClose={() => setIsAddLeadsModalOpen(false)}
                embedded={true}
              />
            </TabsContent>
            
            <TabsContent value="import" className="p-0 m-0">
              <LeadImportModal 
                isOpen={isAddLeadsModalOpen && activeTab === "import"} 
                onClose={() => setIsAddLeadsModalOpen(false)}
                embedded={true}
              />
            </TabsContent>
          </Tabs>
        </DialogContent>
      </Dialog>
    </>
  );
} 