import { Logo } from '@/components/ui/logo';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Download, X } from 'lucide-react';
import { useState } from 'react';
import { StripeEmbeddedCheckout } from '../components/checkout/StripeEmbeddedCheckout';
import { Button } from '../components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle
} from '../components/ui/dialog';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from '../components/ui/table';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger
} from '../components/ui/tabs';
import { useAuth } from '../hooks/useAuth';
import { axiosInstance } from '../lib/api';
import {
  BillingHistorySection,
  LeadGPTPlansSection,
  PaymentMethodSection,
  Product,
  TMSLeadsPlansSection
} from './subscription';

interface Price {
  id: string;
  unit_amount: number;
  currency: string;
  recurring: {
    interval: string;
    interval_count: number;
  };
}

// Template plans for when Stripe plans don't exist yet
const leadGPTPlans = [
  {
    id: 'lead_scout_plan',
    name: 'Lead Scout',
    description: 'Perfect for individual agents looking to boost their lead conversion.',
    default_price: '', // Empty price ID to indicate it's not available yet
    priceData: {
      id: '',
      unit_amount: 14900,
      currency: 'usd',
      recurring: {
        interval: 'month',
        interval_count: 1
      }
    },
    metadata: {
      features: 'Up to 200 total leads,100 AI Receptionist minutes <span class="text-xs text-muted-foreground italic">($0.50/min after)</span>,Local phone numbers,Unlimited Calls & Texts,Standard analytics dashboard,Email & chat support,All AI features included',
      category: 'main',
      comingSoon: 'true'
    }
  },
  {
    id: 'lead_hunter_plan',
    name: 'Lead Hunter',
    description: 'For growing agents who need more capacity and advanced analytics.',
    default_price: '', // Empty price ID to indicate it's not available yet
    priceData: {
      id: '',
      unit_amount: 29900,
      currency: 'usd',
      recurring: {
        interval: 'month',
        interval_count: 1
      }
    },
    metadata: {
      features: 'Up to 500 total leads,250 AI Receptionist minutes <span class="text-xs text-muted-foreground italic">($0.40/min after)</span>,Local phone numbers,Unlimited Calls & Texts,Advanced analytics & reporting,Priority support,Team performance tracking,All AI features included',
      popular: 'true',
      category: 'main',
      comingSoon: 'true'
    }
  },
  {
    id: 'lead_master_plan',
    name: 'Lead Master',
    description: 'For power users who demand the highest capacity and best rates.',
    default_price: '', // Empty price ID to indicate it's not available yet
    priceData: {
      id: '',
      unit_amount: 49900,
      currency: 'usd',
      recurring: {
        interval: 'month',
        interval_count: 1
      }
    },
    metadata: {
      features: 'Unlimited leads,600 AI Receptionist minutes <span class="text-xs text-muted-foreground italic">($0.25/min after)</span>,Local phone numbers,Unlimited Calls & Texts,Custom analytics & reporting,Dedicated account manager,White-labeled client portal,All AI features included',
      category: 'main',
      comingSoon: 'true'
    }
  }
];

// Add-on plans for additional capacity
const addOnPlans = [
  {
    id: 'minutes_booster_plan',
    name: 'Minutes Booster Pack',
    description: 'Add more AI Receptionist minutes to any plan at a discounted rate.',
    default_price: '', // Empty price ID to indicate it's not available yet
    priceData: {
      id: '',
      unit_amount: 4900,
      currency: 'usd',
      recurring: {
        interval: 'month',
        interval_count: 1
      }
    },
    metadata: {
      features: '100 additional AI Receptionist minutes,Rolls over unused minutes (up to 200),Priority routing for calls,Detailed call analytics',
      category: 'addon',
      comingSoon: 'true'
    }
  },
  {
    id: 'leads_booster_plan',
    name: 'Leads Expansion Pack',
    description: 'Increase your total lead capacity without upgrading your plan.',
    default_price: '', // Empty price ID to indicate it's not available yet
    priceData: {
      id: '',
      unit_amount: 3900,
      currency: 'usd',
      recurring: {
        interval: 'month',
        interval_count: 1
      }
    },
    metadata: {
      features: '100 additional leads capacity,Lead scoring prioritization,Enhanced lead analytics,Lead source tracking',
      category: 'addon',
      comingSoon: 'true'
    }
  }
];

// New interface for invoice data
interface Invoice {
  id: string;
  number: string;
  status: string;
  created: number;
  amount_paid: number;
  currency: string;
  invoice_pdf: string;
  lines: {
    data: Array<{
      description: string;
      amount: number;
      period: {
        start: number;
        end: number;
      };
    }>;
  };
}

export default function SubscriptionPage() {
  const { user } = useAuth();
  
  const [isCheckoutOpen, setIsCheckoutOpen] = useState(false);
  const [checkoutPriceId, setCheckoutPriceId] = useState<string | null>(null);
  const [activeSubscriptionTab, setActiveSubscriptionTab] = useState('lead-gpt');

  // State for the selected invoice to view details
  const [selectedInvoice, setSelectedInvoice] = useState<Invoice | null>(null);
  const [isInvoiceDetailsOpen, setIsInvoiceDetailsOpen] = useState(false);

  // Fetch products with their default prices
  const { data: fetchedProducts, isLoading: isLoadingProducts } = useQuery({
    queryKey: ['products'],
    queryFn: async () => {
      const response = await axiosInstance.get('/stripe/products');
      
      // For each product, fetch its default price
      const productsWithPrices = await Promise.all(
        response.data.products.map(async (product: Product) => {
          if (product.default_price) {
            const priceResponse = await axiosInstance.get(`/stripe/products/${product.id}/prices`);
            const prices = priceResponse.data.prices;
            return {
              ...product,
              priceData: prices.find((p: Price) => p.id === product.default_price) || null
            };
          }
          return product;
        })
      );
      
      return productsWithPrices;
    },
  });

  // Combine fetched products with our defined plans
  const allProducts = isLoadingProducts 
    ? [] 
    : (() => {
        const stripeProducts = fetchedProducts || [];
        const templatePlans = [...leadGPTPlans, ...addOnPlans];
        
        console.log('Stripe Products:', stripeProducts.map(p => p.name));
        console.log('Template Plans:', templatePlans.map(p => p.name));
        
        // Use template plans only if corresponding Stripe plans don't exist
        const mergedProducts = [...stripeProducts];
        
        // Enhance Stripe products with template metadata if available
        mergedProducts.forEach(product => {
          // Find matching template plan
          const templatePlan = templatePlans.find(
            tp => tp.name.toLowerCase() === product.name.toLowerCase()
          );
          
          // If a matching template exists, enhance the product with template metadata
          if (templatePlan && templatePlan.metadata) {
            console.log(`Enhancing ${product.name} with template metadata`);
            
            // For Stripe products, ensure they're not marked as "Coming Soon"
            const enhancedMetadata = { ...templatePlan.metadata };
            if (enhancedMetadata.comingSoon) {
              enhancedMetadata.comingSoon = 'false'; // Set comingSoon to false instead of deleting
            }
            
            // Only add metadata that doesn't already exist
            product.metadata = {
              ...enhancedMetadata,
              ...product.metadata
            };
          }
        });
        
        // For each template plan, check if a corresponding Stripe plan exists
        templatePlans.forEach(templatePlan => {
          // Check if a plan with the same name already exists in Stripe products
          const existingPlan = stripeProducts.find(
            p => p.name.toLowerCase() === templatePlan.name.toLowerCase()
          );
          
          // If no matching plan exists in Stripe, add the template plan
          if (!existingPlan) {
            console.log(`Adding template plan ${templatePlan.name} (no matching Stripe plan found)`);
            mergedProducts.push(templatePlan);
          }
        });
        
        console.log('Final merged products:', mergedProducts.map(p => p.name));
        return mergedProducts;
      })();

  // Filter products by category
  const mainPlans = allProducts.filter(product => 
    (!product.metadata?.category || product.metadata.category === 'main') &&
    !product.name.startsWith('VIP')
  ).sort((a, b) => {
    // Define the desired order
    const planOrder: Record<string, number> = {
      'Lead Scout': 1,
      'Lead Hunter': 2,
      'Lead Master': 3
    };
    
    // Get the order value for each plan (default to 999 if not in the order list)
    const orderA = a.name in planOrder ? planOrder[a.name] : 999;
    const orderB = b.name in planOrder ? planOrder[b.name] : 999;
    
    // Sort by the defined order
    return orderA - orderB;
  });
  
  // Get all VIP plans
  const allVipPlans = allProducts.filter(product => product.name.startsWith('VIP') && product.priceData?.id !== 'price_1QhzckFyHOUXNkUP3XtzTFya' && product.priceData?.id !== 'price_1QhzVOFyHOUXNkUPlUVgT5XI');
  
  // Get the featured VIP plans (Bronze, Silver, Gold)
  const featuredVipPlans = allVipPlans
    .filter(product => 
      product.name.startsWith('VIP Bronze') || 
      product.name.startsWith('VIP Silver') || 
      product.name.startsWith('VIP Gold')
    )
    .sort((a, b) => {
      // Define the desired order for VIP plans
      const vipOrder: Record<string, number> = {
        'VIP Bronze': 1,
        'VIP Silver': 2,
        'VIP Gold': 3
      };
      
      // Get the order value based on the plan name prefix
      const getOrderValue = (name: string) => {
        if (name.startsWith('VIP Bronze')) return vipOrder['VIP Bronze'];
        if (name.startsWith('VIP Silver')) return vipOrder['VIP Silver'];
        if (name.startsWith('VIP Gold')) return vipOrder['VIP Gold'];
        return 999;
      };
      
      return getOrderValue(a.name) - getOrderValue(b.name);
    });
  
  // Get the remaining VIP plans
  const moreVipPlans = allVipPlans.filter(product => 
    !product.name.startsWith('VIP Bronze') && 
    !product.name.startsWith('VIP Silver') && 
    !product.name.startsWith('VIP Gold')
  ).sort((a, b) => {
    // Sort by price in ascending order
    const priceA = a.priceData?.unit_amount || 0;
    const priceB = b.priceData?.unit_amount || 0;
    return priceA - priceB;
  });

  const addonPlans = allProducts.filter(product => 
    product.metadata?.category === 'addon'
  );

  // Fetch invoices for billing history
  const { 
    data: invoices, 
    isLoading: isLoadingInvoices, 
    error: invoicesError,
    refetch: refetchInvoices
  } = useQuery({
    queryKey: ['invoices'],
    queryFn: async () => {
      try {
        const response = await axiosInstance.get('/stripe/invoices');
        return response.data.invoices || [];
      } catch (error) {
        console.error('Error fetching invoices:', error);
        throw error;
      }
    },
  });

  // Fetch the user's current subscription
  const { data: currentSubscription, isLoading: isLoadingSubscription } = useQuery({
    queryKey: ['currentSubscription'],
    queryFn: async () => {
      if (!user?.id) return null;
      try {
        const response = await axiosInstance.get(`/stripe/users/${user.id}/subscriptions/current`);
        return response.data.subscription;
      } catch (error) {
        console.error('Error fetching current subscription:', error);
        return null;
      }
    },
  });

  // Handle subscription purchase
  const handleSubscribe = (priceId: string) => {
    setCheckoutPriceId(priceId);
    setIsCheckoutOpen(true);
  };

  // Handle checkout success
  const handleCheckoutSuccess = (sessionId: string) => {
    console.log('Checkout successful:', sessionId);
    // Refetch subscription data and invoices
    refetchInvoices();
    
    // Refetch the current subscription
    const queryClient = useQueryClient();
    queryClient.invalidateQueries({ queryKey: ['currentSubscription'] });
    queryClient.invalidateQueries({ queryKey: ['products'] });
  };

  // Format date from timestamp
  const formatDate = (timestamp: number) => {
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    }).format(new Date(timestamp * 1000));
  };

  // Format price
  const formatPrice = (amount: number, currency: string) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
    }).format(amount / 100);
  };

  // Close invoice details modal
  const handleCloseInvoiceDetails = () => {
    setIsInvoiceDetailsOpen(false);
    setSelectedInvoice(null);
  };

  return (
    <div className="max-w-7xl">
      <Tabs 
        defaultValue="lead-gpt" 
        value={activeSubscriptionTab}
        onValueChange={setActiveSubscriptionTab}
        className="w-full"
      >

        <div className="w-full flex justify-center h-[64px]">
          <TabsList className="grid w-full grid-cols-2 h-[64px]">
            <TabsTrigger value="lead-gpt" className="h-[54px] flex flex-col justify-center items-center data-[state=active]:bg-primary data-[state=active]:text-primary-foreground">
              <Logo className="w-[7rem]" color={activeSubscriptionTab === 'lead-gpt' ? '#ffffff' : '#000000'} />
              <p className="font-light text-xs break-words flex-wrap whitespace-break-spaces">
                Our flagship AI lead conversion tool.
              </p>
            </TabsTrigger>
            <TabsTrigger value="tms-leads" className="h-[54px] flex flex-col justify-center items-center">
              <span className="flex items-center">
                <img src="https://tmsleads.com/images/tmslogo.png" alt="TMS Leads" className="w-[4rem]" />
                <span className="text-sm font-bold text-green-800" style={{ fontStretch: 'expanded', transform: 'scaleX(1.2)' }}>LEADS</span>
              </span>
              <p className="font-light text-xs break-words flex-wrap whitespace-break-spaces">
                Automate lead generation with our TMS integration.
              </p>
            </TabsTrigger>
          </TabsList>
        </div>
              
        {/* Subscription Plans Section */}
        <div className="mb-12">
          <TabsContent value="lead-gpt" className="mt-6 px-0">
            <LeadGPTPlansSection 
              mainPlans={mainPlans}
              addonPlans={addonPlans}
              currentSubscription={currentSubscription}
              allProducts={allProducts}
              isLoadingProducts={isLoadingProducts}
              isLoadingSubscription={isLoadingSubscription}
              onSubscribe={handleSubscribe}
            />
          </TabsContent>

          <TabsContent value="tms-leads" className="mt-6 px-0">
            <TMSLeadsPlansSection 
              featuredVipPlans={featuredVipPlans}
              moreVipPlans={moreVipPlans}
              currentSubscription={currentSubscription}
              allProducts={allProducts}
              isLoadingProducts={isLoadingProducts}
              isLoadingSubscription={isLoadingSubscription}
              onSubscribe={handleSubscribe}
            />
          </TabsContent>
                </div>
      </Tabs>

      {/* Payment Method Section */}
      <PaymentMethodSection user={user} />

      {/* Billing History Section */}
      <BillingHistorySection 
        invoices={invoices}
        isLoadingInvoices={isLoadingInvoices}
        invoicesError={invoicesError}
        refetchInvoices={refetchInvoices}
      />

      {/* Embedded Checkout */}
      {checkoutPriceId && (
        <StripeEmbeddedCheckout
          isOpen={isCheckoutOpen}
          onClose={() => setIsCheckoutOpen(false)}
          priceId={checkoutPriceId}
          onSuccess={handleCheckoutSuccess}
        />
      )}

      {/* Invoice Details Modal */}
      <Dialog open={isInvoiceDetailsOpen} onOpenChange={setIsInvoiceDetailsOpen}>
        <DialogContent className="sm:max-w-[600px]">
          <DialogHeader>
            <DialogTitle className="flex items-center justify-between">
              <span>Invoice Details</span>
              <Button 
                variant="ghost" 
                size="sm" 
                className="h-8 w-8 p-0" 
                onClick={handleCloseInvoiceDetails}
              >
                <X className="h-4 w-4" />
              </Button>
            </DialogTitle>
            <DialogDescription>
              {selectedInvoice && (
                <span>Invoice #{selectedInvoice.number || selectedInvoice.id.slice(-8)}</span>
              )}
            </DialogDescription>
          </DialogHeader>
          
          {selectedInvoice && (
            <div className="space-y-6">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <h4 className="text-sm font-medium text-muted-foreground mb-1">Date</h4>
                  <p>{formatDate(selectedInvoice.created)}</p>
                </div>
                <div>
                  <h4 className="text-sm font-medium text-muted-foreground mb-1">Status</h4>
                  <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                    selectedInvoice.status === 'paid' 
                      ? 'bg-green-100 text-green-800' 
                      : selectedInvoice.status === 'open' 
                        ? 'bg-blue-100 text-blue-800' 
                        : 'bg-gray-100 text-gray-800'
                  }`}>
                    {selectedInvoice.status.charAt(0).toUpperCase() + selectedInvoice.status.slice(1)}
                  </span>
                </div>
              </div>
              
              <div>
                <h4 className="text-sm font-medium text-muted-foreground mb-2">Items</h4>
                <div className="border rounded-md">
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead>Description</TableHead>
                        <TableHead className="text-right">Amount</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {selectedInvoice.lines.data.map((line, index) => (
                        <TableRow key={index}>
                          <TableCell>{line.description}</TableCell>
                          <TableCell className="text-right">
                            {formatPrice(line.amount, selectedInvoice.currency)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </div>
              
              <div className="flex justify-between items-center pt-4 border-t">
                <span className="font-medium">Total</span>
                <span className="font-bold text-lg">
                  {formatPrice(selectedInvoice.amount_paid, selectedInvoice.currency)}
                </span>
              </div>
              
              <div className="flex justify-end space-x-2 pt-4">
                {selectedInvoice.invoice_pdf && (
                  <Button 
                    variant="outline" 
                    className="flex items-center"
                    asChild
                  >
                    <a 
                      href={selectedInvoice.invoice_pdf} 
                      target="_blank" 
                      rel="noopener noreferrer"
                    >
                      <Download className="h-4 w-4 mr-2" />
                      Download PDF
                    </a>
                  </Button>
                )}
                <Button onClick={handleCloseInvoiceDetails}>Close</Button>
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
} 