import { Button } from "@/components/ui/button";
import { AlertTriangle, ArrowLeft, CheckCircle, Database, Key, Lock, Shield, Users } from "lucide-react";

export function Security() {
  const lastUpdated = "March 18, 2024";

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="container mx-auto px-4 py-12">
        {/* Back Button */}
        <a href="https://leadgpt.com" className="inline-block mb-8">
          <Button variant="ghost" className="gap-2">
            <ArrowLeft className="h-4 w-4" />
            Back to Home
          </Button>
        </a>

        <div className="max-w-4xl mx-auto">
          {/* Hero Section */}
          <div className="bg-white rounded-xl shadow-sm p-8 md:p-12 mb-8">
            <div className="flex items-center gap-4 mb-6">
              <div className="h-12 w-12 rounded-full bg-primary/10 flex items-center justify-center">
                <Lock className="h-6 w-6 text-primary" />
              </div>
              <div>
                <h1 className="text-3xl md:text-4xl font-bold">Security</h1>
                <p className="text-gray-600">Last Updated: {lastUpdated}</p>
              </div>
            </div>
            
            <p className="text-lg text-gray-600 mb-6">
              At LeadGPT, security is our top priority. We employ industry-leading security measures to protect your data and maintain the trust of our insurance agent partners.
            </p>

            <div className="grid md:grid-cols-3 gap-4">
              {[
                {
                  title: "SOC 2 Type II",
                  description: "Certified security controls and processes"
                },
                {
                  title: "HIPAA Compliant",
                  description: "Protected health information safeguards"
                },
                {
                  title: "ISO 27001",
                  description: "Information security management"
                }
              ].map((cert, index) => (
                <div key={index} className="bg-gray-50 p-4 rounded-lg">
                  <h3 className="font-bold mb-2">{cert.title}</h3>
                  <p className="text-sm text-gray-600">{cert.description}</p>
                </div>
              ))}
            </div>
          </div>

          {/* Main Content */}
          <div className="bg-white rounded-xl shadow-sm p-8 md:p-12">
            <div className="prose prose-gray max-w-none">
              <section className="mb-12">
                <h2 className="text-2xl font-bold mb-6">Security Features</h2>
                <div className="grid md:grid-cols-2 gap-6">
                  {[
                    {
                      icon: <Key className="h-6 w-6 text-blue-600" />,
                      title: "Authentication & Access",
                      features: [
                        "Multi-factor authentication",
                        "Single sign-on (SSO)",
                        "Role-based access control",
                        "Session management",
                        "Password policies"
                      ]
                    },
                    {
                      icon: <Database className="h-6 w-6 text-green-600" />,
                      title: "Data Protection",
                      features: [
                        "AES-256 encryption at rest",
                        "TLS 1.3 encryption in transit",
                        "Regular backups",
                        "Data redundancy",
                        "Secure key management"
                      ]
                    },
                    {
                      icon: <Shield className="h-6 w-6 text-purple-600" />,
                      title: "Infrastructure Security",
                      features: [
                        "DDoS protection",
                        "Web application firewall",
                        "Network monitoring",
                        "Vulnerability scanning",
                        "Intrusion detection"
                      ]
                    },
                    {
                      icon: <Users className="h-6 w-6 text-orange-600" />,
                      title: "Operational Security",
                      features: [
                        "Security training",
                        "Access reviews",
                        "Change management",
                        "Incident response",
                        "Audit logging"
                      ]
                    }
                  ].map((category, index) => (
                    <div key={index} className="bg-gray-50 p-6 rounded-lg">
                      <div className="flex items-center gap-3 mb-4">
                        <div className="w-10 h-10 rounded-lg bg-white flex items-center justify-center">
                          {category.icon}
                        </div>
                        <h3 className="text-xl font-bold">{category.title}</h3>
                      </div>
                      <ul className="space-y-2">
                        {category.features.map((feature, i) => (
                          <li key={i} className="flex items-start gap-2">
                            <CheckCircle className="h-5 w-5 text-green-500 mt-0.5 flex-shrink-0" />
                            <span>{feature}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              </section>

              <section className="mb-12">
                <h2 className="text-2xl font-bold mb-6">Compliance & Certifications</h2>
                <div className="grid md:grid-cols-2 gap-6">
                  <div className="bg-gray-50 p-6 rounded-lg">
                    <h3 className="text-xl font-bold mb-4">Industry Standards</h3>
                    <ul className="space-y-3">
                      {[
                        "SOC 2 Type II certified",
                        "HIPAA compliant",
                        "ISO 27001 certified",
                        "NIST Cybersecurity Framework",
                        "GDPR compliant"
                      ].map((item, i) => (
                        <li key={i} className="flex items-start gap-3">
                          <CheckCircle className="h-5 w-5 text-green-500 mt-0.5 flex-shrink-0" />
                          <span>{item}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="bg-gray-50 p-6 rounded-lg">
                    <h3 className="text-xl font-bold mb-4">Regular Assessments</h3>
                    <ul className="space-y-3">
                      {[
                        "Penetration testing",
                        "Vulnerability assessments",
                        "Third-party security audits",
                        "Compliance reviews",
                        "Risk assessments"
                      ].map((item, i) => (
                        <li key={i} className="flex items-start gap-3">
                          <CheckCircle className="h-5 w-5 text-green-500 mt-0.5 flex-shrink-0" />
                          <span>{item}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </section>

              <section className="mb-12">
                <h2 className="text-2xl font-bold mb-6">Security Best Practices</h2>
                <div className="space-y-6">
                  <div className="flex items-start gap-4">
                    <div className="w-12 h-12 rounded-lg bg-blue-100 flex items-center justify-center flex-shrink-0">
                      <AlertTriangle className="h-6 w-6 text-blue-600" />
                    </div>
                    <div>
                      <h3 className="text-xl font-bold mb-2">Incident Response</h3>
                      <p className="text-gray-600">
                        Our dedicated security team monitors systems 24/7 and follows documented incident response procedures to quickly address any security events.
                      </p>
                    </div>
                  </div>

                  <div className="flex items-start gap-4">
                    <div className="w-12 h-12 rounded-lg bg-green-100 flex items-center justify-center flex-shrink-0">
                      <Users className="h-6 w-6 text-green-600" />
                    </div>
                    <div>
                      <h3 className="text-xl font-bold mb-2">Employee Security</h3>
                      <p className="text-gray-600">
                        All employees undergo background checks and regular security training to ensure they understand and follow our security policies.
                      </p>
                    </div>
                  </div>

                  <div className="flex items-start gap-4">
                    <div className="w-12 h-12 rounded-lg bg-purple-100 flex items-center justify-center flex-shrink-0">
                      <Database className="h-6 w-6 text-purple-600" />
                    </div>
                    <div>
                      <h3 className="text-xl font-bold mb-2">Data Management</h3>
                      <p className="text-gray-600">
                        We maintain strict data handling procedures and regularly review our data retention and destruction policies.
                      </p>
                    </div>
                  </div>
                </div>
              </section>

              <section>
                <h2 className="text-2xl font-bold mb-4">Contact Security Team</h2>
                <p className="mb-4">
                  For security-related inquiries or to report a security concern:
                </p>
                <div className="bg-gray-50 p-4 rounded-lg">
                  <p>Email: security@leadgpt.com</p>
                  <p className="mt-2 text-sm text-gray-600">
                    For responsible disclosure of security vulnerabilities, please encrypt your communications.
                  </p>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
} 