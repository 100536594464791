import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from "@/components/ui/alert-dialog";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Calendar as CalendarComponent } from '@/components/ui/calendar';
import { Input } from "@/components/ui/input";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Separator } from "@/components/ui/separator";
import { Skeleton } from "@/components/ui/skeleton";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { toast } from "@/components/ui/use-toast";
import { CalendarEvent, CalendarService } from "@/services/calendar.service";
import { useLeadStore } from "@/store/leadStore";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { format, getDay, isToday } from "date-fns";
import { Calendar, ChevronLeft, ChevronRight, Clock, Edit, ExternalLink, Eye, Filter, Info, LinkIcon, Loader2, MapPin, Plus, RefreshCw, Trash, UserPlus, Video, X } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { ConnectLeadDialog } from "./ConnectLeadDialog";
import { CreateEventDialog } from "./CreateEventDialog";
import { EditEventDialog } from "./EditEventDialog";
import { EventOutcomeDialog } from "./EventOutcomeDialog";

// Local storage key for calendar preferences
const CALENDAR_PREFS_KEY = 'calendar_dashboard_preferences';

interface CalendarPreferences {
  selectedDate?: string;
  viewMode: 'day' | 'week' | 'month';
  activeTab: string;
  showPastEvents: boolean;
}

// Function to load preferences from localStorage
function loadPreferences(): CalendarPreferences {
  try {
    const savedPrefs = localStorage.getItem(CALENDAR_PREFS_KEY);
    
    if (savedPrefs) {
      const parsedPrefs = JSON.parse(savedPrefs) as CalendarPreferences;
      
      // Ensure boolean values are properly handled
      const showPastEvents = typeof parsedPrefs.showPastEvents === 'boolean' 
        ? parsedPrefs.showPastEvents 
        : false;
        
      return {
        selectedDate: parsedPrefs.selectedDate,
        viewMode: parsedPrefs.viewMode || 'day',
        activeTab: parsedPrefs.activeTab || 'upcoming',
        showPastEvents
      };
    }
  } catch (e) {
    console.error('Failed to parse calendar preferences', e);
  }
  
  // Default values if nothing in localStorage or error occurred
  return {
    viewMode: 'day',
    activeTab: 'upcoming',
    showPastEvents: false
  };
}

// Function to save preferences to localStorage
function savePreferences(preferences: CalendarPreferences): void {
  localStorage.setItem(CALENDAR_PREFS_KEY, JSON.stringify(preferences));
}

export function CalendarDashboard() {
  // Load preferences from localStorage
  const savedPrefs = useMemo(() => loadPreferences(), []);
  
  // Fix date initialization to handle timezone correctly
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(() => {
    if (savedPrefs.selectedDate) {
      // Create date and set it to midnight in local timezone
      const date = new Date(savedPrefs.selectedDate);
      // Reset the time to start of day to avoid timezone issues
      return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    }
    // Reset today to midnight in local timezone
    const today = new Date();
    return new Date(today.getFullYear(), today.getMonth(), today.getDate());
  });
  const [viewMode, setViewMode] = useState<'day' | 'week' | 'month'>(savedPrefs.viewMode);
  const [activeTab, setActiveTab] = useState(savedPrefs.activeTab);
  const [showPastEvents, setShowPastEvents] = useState<boolean>(savedPrefs.showPastEvents ?? false);
  const [searchTerm, setSearchTerm] = useState('');
  const [showCreateEventDialog, setShowCreateEventDialog] = useState(false);
  const queryClient = useQueryClient();

  // Save preferences to localStorage whenever they change
  useEffect(() => {
    if (selectedDate || viewMode || activeTab || showPastEvents !== undefined) {
      savePreferences({
        selectedDate: selectedDate ? selectedDate.toISOString() : undefined,
        viewMode,
        activeTab,
        showPastEvents
      });
    }
  }, [selectedDate, viewMode, activeTab, showPastEvents]);

  // Get connected calendar integrations
  const { 
    data: integrations
  } = useQuery({
    queryKey: ['calendarIntegrations'],
    queryFn: () => CalendarService.getCalendarIntegrations(),
    refetchOnWindowFocus: false,
  });

  // Get the Google integration if available
  const googleIntegration = useMemo(() => 
    integrations?.find(i => i.provider === 'google' && i.syncEnabled), 
    [integrations]
  );

  // Get events from the Google Calendar
  const {
    data: calendarEvents,
    isLoading: isLoadingEvents,
    error: eventsError,
    refetch: refetchEvents
  } = useQuery<CalendarEvent[]>({
    queryKey: ['calendarEvents', googleIntegration?.id],
    queryFn: async () => {
      if (!googleIntegration?.id) return Promise.resolve([]);
      return CalendarService.getSyncedEvents(googleIntegration.id);
    },
    enabled: !!googleIntegration?.id && googleIntegration.syncEnabled,
  });

  // Manual sync
  const {
    mutate: syncNow,
    isPending: isSyncingNow
  } = useMutation({
    mutationFn: (integrationId: string) => 
      CalendarService.syncCalendarNow(integrationId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['calendarEvents'] });
      toast({
        title: "Sync completed",
        description: "Your calendar has been synchronized."
      });
    },
    onError: (error: Error) => {
      toast({
        title: "Sync failed",
        description: "Failed to synchronize your calendar.",
        variant: "destructive"
      });
    }
  });

  const handleSyncNow = () => {
    if (googleIntegration?.id) {
      syncNow(googleIntegration.id);
    }
  };

  const handleCreateEvent = () => {
    setShowCreateEventDialog(true);
  };

  // Handle change of selected date with localStorage update
  const handleDateChange = (date: Date | undefined) => {
    if (date) {
      // Normalize to start of day in local timezone to avoid timezone issues
      const normalizedDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      setSelectedDate(normalizedDate);
    } else {
      setSelectedDate(undefined);
    }
  };

  // Handle change of view mode with localStorage update
  const handleViewModeChange = (mode: 'day' | 'week' | 'month') => {
    setViewMode(mode);
  };

  // Handle change of active tab with localStorage update
  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
  };

  // Handle toggling of past events with localStorage update
  const handlePastEventsToggle = () => {
    // Immediately update state with the new value
    const newValue = !showPastEvents;
    setShowPastEvents(newValue);
    
    // The useEffect will handle saving to localStorage
  };

  // Filter and sort events based on date, search term, and showPastEvents
  const filteredEvents = useMemo(() => {
    if (!calendarEvents) return [];

    // Get current time for accurate comparison with event times
    const now = new Date();
    
    const filteredResults = calendarEvents
      .filter(event => {
        // Parse event timestamps
        const eventStartTime = new Date(event.startTime);
        const eventEndTime = new Date(event.endTime);
        
        // Filter based on past events preference
        if (!showPastEvents) {
          // Check if the event is already over (end time is in the past)
          const isPastEvent = eventEndTime < now;
          if (isPastEvent) {
            return false;
          }
        }
        
        // Filter based on search term
        if (searchTerm && !event.title.toLowerCase().includes(searchTerm.toLowerCase())) {
          return false;
        }
        
        // When in day view, filter events for the selected date
        if (viewMode === 'day' && selectedDate) {
          // Compare year, month, and day of event with selected date in local time
          const eventDateLocal = new Date(eventStartTime);
          const eventYear = eventDateLocal.getFullYear();
          const eventMonth = eventDateLocal.getMonth();
          const eventDay = eventDateLocal.getDate();
          
          // Then compare with selected date
          const selectedYear = selectedDate.getFullYear();
          const selectedMonth = selectedDate.getMonth();
          const selectedDay = selectedDate.getDate();
          
          const isOnSelectedDate = 
            eventYear === selectedYear && 
            eventMonth === selectedMonth && 
            eventDay === selectedDay;
            
          if (!isOnSelectedDate) {
            return false;
          }
          return true;
        }

        // For week view, filter events within the week
        if (viewMode === 'week' && selectedDate) {
          // Get day of week (0-6, 0 is Sunday)
          const currentDay = getDay(selectedDate);
          
          // Create week boundaries in local time
          const weekStart = new Date(
            selectedDate.getFullYear(),
            selectedDate.getMonth(),
            selectedDate.getDate() - currentDay
          );
          
          const weekEnd = new Date(
            weekStart.getFullYear(),
            weekStart.getMonth(),
            weekStart.getDate() + 6,
            23, 59, 59 // End of day
          );
          
          // Convert event time to local for week comparison
          const eventTimeLocal = new Date(eventStartTime);
          const isInSelectedWeek = eventTimeLocal >= weekStart && eventTimeLocal <= weekEnd;
          
          if (!isInSelectedWeek) {
            return false;
          }
          return true;
        }

        return true;
      })
      .sort((a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime());
    
    return filteredResults;
  }, [calendarEvents, selectedDate, viewMode, showPastEvents, searchTerm]);

  // Group events by date for display
  const groupedEvents = useMemo(() => {
    // Get current time for accurate comparison
    const now = new Date();
    
    return filteredEvents.reduce<Record<string, { past: CalendarEvent[], upcoming: CalendarEvent[] }>>((groups, event) => {
      // Get the event date in local timezone for display grouping
      const eventTime = new Date(event.startTime);
      const eventEndTime = new Date(event.endTime);
      
      // Format the date as yyyy-MM-dd based on local time display
      const date = format(eventTime, 'yyyy-MM-dd');
      
      if (!groups[date]) {
        groups[date] = { past: [], upcoming: [] };
      }
      
      // Check if the event is in the past
      const isPastEvent = eventEndTime < now;
      
      // Add event to appropriate category
      if (isPastEvent) {
        groups[date].past.push(event);
      } else {
        groups[date].upcoming.push(event);
      }
      
      return groups;
    }, {});
  }, [filteredEvents]);

  // Handle date navigation
  const navigateDate = (direction: 'prev' | 'next') => {
    if (!selectedDate) return;
    
    if (viewMode === 'day') {
      setSelectedDate(prevDate => {
        if (!prevDate) return new Date();
        // Create a new date in local timezone with proper offset
        const newDate = new Date(prevDate);
        newDate.setDate(prevDate.getDate() + (direction === 'next' ? 1 : -1));
        // Normalize to start of day to prevent timezone issues
        return new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate());
      });
    } else if (viewMode === 'week') {
      setSelectedDate(prevDate => {
        if (!prevDate) return new Date();
        // Create a new date in local timezone with proper offset
        const newDate = new Date(prevDate);
        newDate.setDate(prevDate.getDate() + (direction === 'next' ? 7 : -7));
        // Normalize to start of day to prevent timezone issues
        return new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate());
      });
    }
  };

  if (!googleIntegration) {
    return (
      <div className="flex flex-col items-center justify-center py-12 text-center">
        <Calendar className="h-16 w-16 text-gray-300 mb-4" />
        <h3 className="text-lg font-medium">No Calendar Integration Found</h3>
        <p className="text-sm text-muted-foreground max-w-md mt-2">
          To use the Calendar Dashboard, please connect your Google Calendar in the Integrations page.
        </p>
        <Button className="mt-6" asChild>
          <a href="/integrations?integration=google-calendar">Set Up Calendar Integration</a>
        </Button>
      </div>
    );
  }

  const handleRefetchEvents = () => {
    refetchEvents();
  };

  return (
    <div className="space-y-6">
      <div className="flex flex-col sm:flex-row justify-between gap-4">
        <Tabs value={activeTab} onValueChange={handleTabChange} className="w-full">
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-3 sm:gap-0">
            <TabsList className="mb-4 w-full sm:w-auto">
              <TabsTrigger value="upcoming">Upcoming</TabsTrigger>
              <TabsTrigger value="calendar">Calendar View</TabsTrigger>
              <TabsTrigger value="leads">Lead Connections</TabsTrigger>
            </TabsList>
            
            <div className="flex items-center gap-2 w-full sm:w-auto mb-4 sm:mb-0">
              <Button 
                size="sm" 
                variant="outline" 
                onClick={handleSyncNow}
                disabled={isSyncingNow}
                className="h-9 text-xs sm:text-sm flex-1 sm:flex-none"
              >
                {isSyncingNow ? (
                  <>
                    <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                    Syncing...
                  </>
                ) : (
                  <>
                    <RefreshCw className="h-4 w-4 mr-2" />
                    Sync Now
                  </>
                )}
              </Button>
              <Button 
                size="sm" 
                variant="default" 
                className="h-9 text-xs sm:text-sm flex-1 sm:flex-none"
                onClick={handleCreateEvent}
              >
                <Plus className="h-4 w-4 mr-2" />
                New Event
              </Button>
            </div>
          </div>

          <div className="border rounded-lg shadow-sm bg-white">
            <div className="p-3 sm:p-4 border-b flex flex-col sm:flex-row justify-between gap-3 sm:gap-4">
              <div className="flex flex-wrap items-center gap-2">
                {activeTab !== 'leads' && (
                  <>
                    <Button 
                      size="sm" 
                      variant="ghost" 
                      className="h-9 w-9 p-0" 
                      onClick={() => navigateDate('prev')}
                    >
                      <ChevronLeft className="h-4 w-4" />
                    </Button>
                    
                    <Popover>
                      <PopoverTrigger asChild>
                        <Button variant="outline" className="min-w-[calc(100%-88px)] sm:min-w-[240px] justify-start text-left font-normal h-9 text-xs sm:text-sm">
                          <Calendar className="mr-2 h-4 w-4" />
                          {selectedDate ? (
                            viewMode === 'day' ? (
                              format(selectedDate, 'MMM d, yyyy')
                            ) : (
                              `Week of ${format(selectedDate, 'MMM d, yyyy')}`
                            )
                          ) : (
                            <span>Pick a date</span>
                          )}
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent className="w-auto p-0">
                        <CalendarComponent
                          mode="single"
                          selected={selectedDate}
                          onSelect={handleDateChange}
                          defaultMonth={selectedDate}
                          initialFocus
                        />
                      </PopoverContent>
                    </Popover>
                    
                    <Button 
                      size="sm" 
                      variant="ghost" 
                      className="h-9 w-9 p-0" 
                      onClick={() => navigateDate('next')}
                    >
                      <ChevronRight className="h-4 w-4" />
                    </Button>
                    
                    <Separator orientation="vertical" className="h-8 mx-2 hidden sm:block" />
                    
                    <Select value={viewMode} onValueChange={(value) => handleViewModeChange(value as 'day' | 'week' | 'month')}>
                      <SelectTrigger className="w-full sm:w-[120px] h-9 text-xs sm:text-sm">
                        <SelectValue placeholder="View mode" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="day">Day View</SelectItem>
                        <SelectItem value="week">Week View</SelectItem>
                      </SelectContent>
                    </Select>
                  </>
                )}
              </div>
              
              <div className="flex items-center gap-2 w-full sm:w-auto">
                <div className="relative flex-1 sm:flex-none">
                  <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
                  <Input 
                    placeholder="Search events..." 
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    className="pl-8 h-9 w-full sm:w-[200px] text-xs sm:text-sm"
                  />
                  {searchTerm && (
                    <Button 
                      variant="ghost" 
                      size="sm" 
                      className="absolute right-1 top-1 h-7 w-7 p-0" 
                      onClick={() => setSearchTerm('')}
                    >
                      <X className="h-4 w-4" />
                    </Button>
                  )}
                </div>
                <Button 
                  variant={"outline"}
                  size="sm" 
                  className="h-9 flex gap-2 items-center whitespace-nowrap text-xs sm:text-sm"
                  onClick={handlePastEventsToggle}
                  title={showPastEvents ? "Hide events that have already ended" : "Show all events, including those that have already ended"}
                >
                  <Filter className="h-4 w-4" />
                  {showPastEvents ? 'Hide Past' : 'Show Past'}
                  {!showPastEvents && (
                    <Badge variant="outline" className="ml-1 text-[10px] font-normal bg-red-500 text-white px-1.5 py-0">
                       1
                     </Badge>
                  )}
                </Button>
              </div>
            </div>
            
            <TabsContent value="upcoming" className="px-4 sm:px-6 py-4">
              {isLoadingEvents ? (
                <div className="space-y-4">
                  <Skeleton className="h-16 w-full" />
                  <Skeleton className="h-16 w-full" />
                  <Skeleton className="h-16 w-full" />
                </div>
              ) : eventsError ? (
                <div className="text-center py-8">
                  <p className="text-red-500">Failed to load calendar events</p>
                  <Button onClick={handleRefetchEvents} className="mt-2" variant="outline">Try Again</Button>
                </div>
              ) : filteredEvents.length === 0 ? (
                <div className="text-center py-12">
                  <Calendar className="h-12 w-12 mx-auto text-gray-300 mb-4" />
                  <h3 className="text-lg font-medium">No events found</h3>
                  <p className="text-sm text-muted-foreground mt-1">
                    {showPastEvents 
                      ? "No events match your search criteria." 
                      : "No upcoming events found. Past events are hidden."}
                  </p>
                  {!showPastEvents && (
                    <Button 
                      variant="link" 
                      onClick={handlePastEventsToggle}
                      className="mt-2"
                    >
                      Show past
                    </Button>
                  )}
                </div>
              ) : (
                <div className="space-y-6">
                  {showPastEvents && (
                    <div className="bg-blue-50 p-3 rounded-md border border-blue-100 text-sm text-blue-700 flex items-center">
                      <Info className="h-4 w-4 mr-2 text-blue-500 hidden sm:block" />
                      <p>
                        Past events are displayed with reduced opacity. You can add outcomes to past events with leads.
                      </p>
                    </div>
                  )}
                  {Object.entries(groupedEvents).map(([date, events]) => {
                    // Parse date string to create a normalized Date at midnight
                    const [year, month, day] = date.split('-').map(part => parseInt(part));
                    const dateObj = new Date(year, month - 1, day); // month is 0-indexed in JS Date
                    
                    return (
                      <div key={date} className="space-y-3">
                        <div className="sticky top-0 bg-white py-2 z-10">
                          <h3 className="text-sm font-medium flex items-center">
                            <span className="mr-2">
                              {format(dateObj, 'EEEE, MMMM d, yyyy')}
                            </span>
                            {isToday(dateObj) && (
                              <Badge variant="outline" className="bg-blue-50 text-blue-700 border-blue-200 text-xs px-1.5 py-0 h-4 rounded-sm">
                                Today
                              </Badge>
                            )}
                          </h3>
                        </div>
                        
                        {/* Upcoming (non-past) events */}
                        {events.upcoming.length > 0 && (
                          <div className="space-y-3">
                            {events.upcoming.length > 0 && events.past.length > 0 && (
                              <h4 className="text-xs font-medium text-gray-500 pl-1">Upcoming</h4>
                            )}
                            <div className="grid gap-3 grid-cols-1 xl:grid-cols-2">
                              {events.upcoming.map((event) => (
                                <EventCard key={event.id} event={event} />
                              ))}
                            </div>
                          </div>
                        )}
                        
                        {/* Past events */}
                        {events.past.length > 0 && showPastEvents && (
                          <div className="space-y-3 mt-5">
                            {events.upcoming.length > 0 && (
                              <h4 className="text-xs font-medium text-gray-500 pl-1">Past</h4>
                            )}
                            <div className="grid gap-3 grid-cols-1 xl:grid-cols-2">
                              {events.past.map((event) => (
                                <EventCard key={event.id} event={event} />
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </TabsContent>
            
            <TabsContent value="calendar" className="p-4 sm:p-6">
              <CalendarView 
                events={filteredEvents} 
                selectedDate={selectedDate} 
                viewMode={viewMode}
                isLoading={isLoadingEvents}
              />
            </TabsContent>
            
            <TabsContent value="leads" className="p-4 sm:p-6">
              <LeadConnectionsView 
                events={filteredEvents} 
                isLoading={isLoadingEvents}
              />
            </TabsContent>
          </div>
        </Tabs>
      </div>

      {/* Create Event Dialog */}
      {googleIntegration && showCreateEventDialog && (
        <CreateEventDialog 
          isOpen={showCreateEventDialog} 
          onClose={() => setShowCreateEventDialog(false)}
          calendarIntegrationId={googleIntegration.id}
        />
      )}
    </div>
  );
}

function EventCard({ event }: { event: CalendarEvent }) {
  const [showConnectLeadDialog, setShowConnectLeadDialog] = useState(false);
  const [showEditEventDialog, setShowEditEventDialog] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [showOutcomeDialog, setShowOutcomeDialog] = useState(false);
  const [timeSince, setTimeSince] = useState(0);
  const [timeUntil, setTimeUntil] = useState(0);
  const navigate = useNavigate();
  const { setSelectedLead } = useLeadStore();
  const queryClient = useQueryClient();

  // Check if the event is in the past
  const isPastEvent = useMemo(() => {
    const now = new Date();
    const eventEndTime = new Date(event.endTime);
    return eventEndTime < now;
  }, [event.endTime]);

  // Calculate time since/until event - updated every minute
  useEffect(() => {
    // Function to update time calculations
    const updateTimeCalculations = () => {
      const now = new Date();
      const endTime = new Date(event.endTime);
      const startTime = new Date(event.startTime);
      
      // Calculate time since end (for past events)
      const newTimeSince = Math.floor((now.getTime() - endTime.getTime()) / (1000 * 60));
      
      // Calculate time until start (for future events)
      const newTimeUntil = Math.floor((startTime.getTime() - now.getTime()) / (1000 * 60));
      
      setTimeSince(newTimeSince);
      setTimeUntil(newTimeUntil);
    };
    
    // Initial calculation
    updateTimeCalculations();
    
    // Set up interval to recalculate every minute
    const intervalId = setInterval(updateTimeCalculations, 60000);
    
    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, [event.startTime, event.endTime]);

  // Delete mutation
  const { mutate: deleteEvent, isPending: isDeleting } = useMutation({
    mutationFn: () => CalendarService.deleteEvent(event.id),
    onSuccess: () => {
      toast({
        title: "Event deleted",
        description: "Your calendar event has been deleted.",
      });
      // Invalidate calendar events query
      queryClient.invalidateQueries({ queryKey: ['calendarEvents'], exact: false });
      
      // Also invalidate leadEvents query if this event is connected to a lead
      if (event.leadId) {
        queryClient.invalidateQueries({ queryKey: ['leadEvents', event.leadId], exact: true });
      }
    },
    onError: (error) => {
      console.error("Error deleting event:", error);
      toast({
        title: "Failed to delete event",
        description: "There was an error deleting your event. Please try again.",
        variant: "destructive",
      });
    },
  });

  // Handle add Google Meet link mutation
  const { mutate: addMeetLink, isPending: isAddingMeetLinkPending } = useMutation({
    mutationFn: async () => {
      // Update event with Google Meet link request
      return await CalendarService.updateEventWithMeetLink(event.id);
    },
    onSuccess: (updatedEvent) => {
      // Check if the event has a lead with an email
      const hasLeadWithEmail = event.leadId && updatedEvent.meetingLink;
      
      toast({
        title: "Google Meet link added",
        description: hasLeadWithEmail
          ? "A Google Meet link has been added to your event and an invitation has been sent to the lead."
          : "A Google Meet link has been added to your event.",
      });
      
      // Invalidate calendar events query
      queryClient.invalidateQueries({ queryKey: ['calendarEvents'], exact: false });
      
      // Also invalidate leadEvents query if this event is connected to a lead
      if (event.leadId) {
        queryClient.invalidateQueries({ queryKey: ['leadEvents', event.leadId], exact: true });
      }
    },
    onError: (error) => {
      console.error("Error adding Google Meet link:", error);
      toast({
        title: "Failed to add Google Meet link",
        description: "There was an error adding a Google Meet link. Please try again.",
        variant: "destructive",
      });
    },
  });

  const handleViewLead = () => {
    if (event.leadId) {
      // First set the selected lead ID in the store
      setSelectedLead({ id: event.leadId });
      
      // Then navigate to the leads page
      navigate({ to: '/leads' });
    }
  };

  const handleAddMeetLink = () => {
    addMeetLink();
  };

  const handleAddOutcome = () => {
    setShowOutcomeDialog(true);
  };

  // Get event start and end times formatted
  const startTime = new Date(event.startTime);
  const endTime = new Date(event.endTime);
  const formattedStartTime = startTime.getMinutes() === 0 
    ? format(startTime, 'h a') 
    : format(startTime, 'h:mm a');
  // Calculate meeting duration
  const durationMinutes = Math.round((endTime.getTime() - startTime.getTime()) / (1000 * 60));
  const durationText = durationMinutes < 60 
    ? `${durationMinutes}m` 
    : durationMinutes % 60 === 0 
      ? `${Math.floor(durationMinutes / 60)}h` 
      : `${Math.floor(durationMinutes / 60)}h ${durationMinutes % 60}m`;
  
  const isToday = new Date().toDateString() === startTime.toDateString();
  const eventDate = format(startTime, 'EEE, MMM d');
  
  return (
    <>
      <div className={`rounded-md border overflow-hidden bg-white transition-all ${isPastEvent ? 'opacity-50' : 'shadow-sm '}`}>
        {/* Status indicator bar at the top */}
        <div className="flex items-center">
          <div 
            className={`w-1.5 h-full self-stretch ${isPastEvent 
              ? 'bg-gray-300' 
              : event.leadId ? 'bg-green-500' : 'bg-blue-500'}`} 
          ></div>
          <div className="flex-1">
            {/* Content */}
            <div className="p-3 sm:p-4">
              <div className="flex items-start justify-between mb-2 gap-2">
                <div className="flex-1 min-w-0">
                  <h3 className="font-medium text-sm sm:text-base line-clamp-1">{event.title}</h3>
                  <div className="flex items-center text-xs text-muted-foreground mt-1">
                    {!isToday && (
                      <span className="inline-flex items-center text-xs text-muted-foreground mr-2">
                        <Calendar className="h-3 w-3 mr-1" />
                        {eventDate}
                      </span>
                    )}
                    <Clock className="h-3 w-3 mr-1 flex-shrink-0" />
                    <span className="mr-2">{durationText}</span>
                    {event.location && (
                      <div className="items-center text-muted-foreground hidden sm:inline-flex mr-2">
                        <MapPin className="h-3 w-3 mr-1 flex-shrink-0" />
                        <span className="truncate max-w-[150px]">{event.location}</span>
                      </div>
                    )}
                    {/* Status badges */}
                    <div className="flex items-center gap-1 flex-shrink-0">
                      {isPastEvent ? (
                        <Badge variant="outline" className="text-[10px] rounded-sm px-1.5 py-0 h-4 bg-gray-100 text-gray-500 border-gray-200">
                          {timeSince < 60 
                            ? `${timeSince}m ago` 
                            : timeSince < 1440 
                              ? `${Math.floor(timeSince / 60)}h ago` 
                              : timeSince < 10080 // 7 days
                                ? `${Math.floor(timeSince / 1440)}d ago`
                                : `${Math.floor(timeSince / 10080)}w ago`}
                        </Badge>
                      ) : timeUntil <= 60 ? (
                        <Badge variant="outline" className="text-[10px] rounded-sm px-1.5 py-0 h-4 bg-green-50 text-green-700 border-green-200">
                          {timeUntil <= 0 ? 'Now' : `In ${timeUntil}m`}
                        </Badge>
                      ) : timeUntil <= 180 ? (
                        <Badge variant="outline" className="text-[10px] rounded-sm px-1.5 py-0 h-4 bg-amber-50 text-amber-700 border-amber-200">
                          {`In ${Math.floor(timeUntil / 60)}h ${timeUntil % 60 > 0 ? timeUntil % 60 + 'm' : ''}`}
                        </Badge>
                      ) : null}
                      {isToday && !isPastEvent && timeUntil > 60 && (
                        <Badge variant="outline" className="text-[10px] rounded-sm px-1.5 py-0 h-4 bg-blue-50 text-blue-700 border-blue-100">
                          Today
                        </Badge>
                      )}
                    </div>
                  </div>
                </div>
                
                <div className="flex items-center gap-1 flex-shrink-0">
                  <div className="flex flex-col items-center">
                    <span className={`mr-2 text-3xl ${
                      isPastEvent 
                        ? 'text-gray-400' 
                        : timeUntil <= 60 
                          ? 'text-green-500' // Starting within 30 minutes
                          : timeUntil <= 180 
                            ? 'text-amber-500' // Starting within 3 hours
                            : timeUntil <= 1440 
                              ? 'text-blue-500' // Starting within 24 hours
                              : 'text-primary' // Default color for events further away
                    }`}>{formattedStartTime}</span>
                    
                  </div>
                </div>
              </div>
              
              {/* Details section */}
              <div className="space-y-1.5 mb-3">
                {event.description && (
                  <p className="text-xs text-muted-foreground line-clamp-5 mt-1">
                    {event.description}
                  </p>
                )}
              </div>
              
              {/* Action buttons */}
              <div className="flex flex-wrap items-center gap-1.5 border-t pt-2 mt-2">
                <div className="flex-1 flex gap-1.5">
                  <Button 
                    variant="ghost" 
                    size="sm" 
                    onClick={() => setShowEditEventDialog(true)}
                    className="h-7 px-2 text-xs text-gray-600 hover:text-gray-800"
                  >
                    <Edit className="h-3 w-3 mr-1" />
                    <span className="sm:inline hidden">Edit</span>
                  </Button>
                  
                  <Button 
                    variant="ghost" 
                    size="sm"
                    className="h-7 px-2 text-xs text-red-600 hover:text-red-700 hover:bg-red-50"
                    onClick={() => setDeleteConfirmOpen(true)}
                  >
                    <Trash className="h-3 w-3 mr-1" />
                    <span className="sm:inline hidden">Delete</span>
                  </Button>
                  
                  {isPastEvent && event.leadId && (
                    <Button 
                      variant="ghost" 
                      size="sm" 
                      className="h-7 px-2 text-xs text-purple-600 hover:text-purple-700 hover:bg-purple-50"
                      onClick={handleAddOutcome}
                    >
                      <Edit className="h-3 w-3 mr-1" />
                      <span className="sm:inline hidden">Outcome</span>
                    </Button>
                  )}
                </div>
                
                <div className="flex items-center gap-1.5">
                  {event.meetingLink && (
                    <Button 
                      variant="ghost" 
                      size="sm" 
                      onClick={() => {
                        if (event.meetingLink) {
                          window.open(event.meetingLink, '_blank');
                        }
                      }}
                      className="h-7 px-2 text-xs text-blue-600 hover:text-blue-700 hover:bg-blue-50"
                    >
                      <ExternalLink className="h-3 w-3 mr-1" />
                      <span className="sm:inline hidden">Join Meeting</span>
                    </Button>
                  )}

                  {event.leadId && !event.meetingLink && (
                    <Button 
                      variant="ghost" 
                      size="sm" 
                      onClick={handleAddMeetLink}
                      disabled={isAddingMeetLinkPending}
                      className="h-7 px-2 text-xs text-green-600 hover:text-green-700 hover:bg-green-50"
                    >
                      {isAddingMeetLinkPending ? (
                        <>
                          <Loader2 className="h-3 w-3 mr-1 animate-spin" />
                          <span className="sm:inline hidden">Adding...</span>
                        </>
                      ) : (
                        <>
                          <Video className="h-3 w-3 mr-1" />
                          <span className="sm:inline hidden">Add Meet</span>
                        </>
                      )}
                    </Button>
                  )}
                  
                  {event.leadId ? (
                    <Button 
                      variant="outline" 
                      size="sm" 
                      className="h-7 px-2 text-xs bg-white"
                      onClick={handleViewLead}
                    >
                      <Eye className="h-3 w-3 mr-1" />
                      <span className="sm:inline hidden">View Lead</span>
                    </Button>
                  ) : (
                    <Button 
                      variant="default" 
                      size="sm" 
                      className="h-7 px-2 text-xs"
                      onClick={() => setShowConnectLeadDialog(true)}
                    >
                      <UserPlus className="h-3 w-3 mr-1" />
                      <span className="sm:inline hidden">Connect Lead</span>
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Delete Confirmation Dialog */}
      <AlertDialog open={deleteConfirmOpen} onOpenChange={setDeleteConfirmOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This will permanently delete the event "{event.title}" from your calendar.
              This action cannot be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              disabled={isDeleting}
              onClick={(e) => {
                e.preventDefault();
                deleteEvent();
              }}
              className="bg-red-600 text-white hover:bg-red-700"
            >
              {isDeleting ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Deleting...
                </>
              ) : (
                "Delete Event"
              )}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <ConnectLeadDialog 
        event={event} 
        isOpen={showConnectLeadDialog} 
        onClose={() => setShowConnectLeadDialog(false)} 
      />
      
      {showEditEventDialog && (
        <EditEventDialog
          event={event}
          isOpen={showEditEventDialog}
          onClose={() => setShowEditEventDialog(false)}
        />
      )}
      
      {/* Add Outcome Dialog */}
      <EventOutcomeDialog
        event={event}
        isOpen={showOutcomeDialog}
        onClose={() => setShowOutcomeDialog(false)}
      />
    </>
  );
}

function CalendarView({ 
  events, 
  selectedDate, 
  viewMode,
  isLoading
}: { 
  events: CalendarEvent[], 
  selectedDate?: Date,
  viewMode: 'day' | 'week' | 'month',
  isLoading: boolean
}) {
  // Implementation would be more complex in a real app
  return (
    <div className="min-h-[400px]">
      {isLoading ? (
        <div className="flex items-center justify-center h-[400px]">
          <Loader2 className="h-8 w-8 animate-spin text-muted-foreground" />
        </div>
      ) : (
        <div className="text-center py-12">
          <Calendar className="h-12 w-12 mx-auto text-gray-300 mb-4" />
          <h3 className="text-lg font-medium">Calendar View Coming Soon</h3>
          <p className="text-sm text-muted-foreground mt-1">
            A more advanced calendar view is under development.
          </p>
        </div>
      )}
    </div>
  );
}

function LeadConnectionsView({ 
  events,
  isLoading
}: {
  events: CalendarEvent[],
  isLoading: boolean
}) {
  const [selectedEvent, setSelectedEvent] = useState<CalendarEvent | null>(null);
  const [showConnectLeadDialog, setShowConnectLeadDialog] = useState(false);
  const navigate = useNavigate();
  const { setSelectedLead } = useLeadStore();

  // Count events with and without leads
  const { withLeads, withoutLeads } = useMemo(() => {
    return events.reduce((acc, event) => {
      if (event.leadId) {
        acc.withLeads.push(event);
      } else {
        acc.withoutLeads.push(event);
      }
      return acc;
    }, { withLeads: [] as CalendarEvent[], withoutLeads: [] as CalendarEvent[] });
  }, [events]);

  const handleConnectLead = (event: CalendarEvent) => {
    setSelectedEvent(event);
    setShowConnectLeadDialog(true);
  };

  const handleViewLead = (leadId: number) => {
    // First set the selected lead ID in the store
    setSelectedLead({ id: leadId });
    
    // Then navigate to the leads page
    navigate({ to: '/leads' });
  };
  
  return (
    <div className="space-y-6">
      {isLoading ? (
        <div className="space-y-4">
          <Skeleton className="h-16 w-full" />
          <Skeleton className="h-16 w-full" />
          <Skeleton className="h-16 w-full" />
        </div>
      ) : (
        <>
          <div>
            <h3 className="text-lg font-medium mb-4">Events Without Lead Connections</h3>
            {withoutLeads.length > 0 ? (
              <div className="grid gap-4 grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
                {withoutLeads.slice(0, 6).map(event => {
                  const startTime = new Date(event.startTime);
                  const formattedTime = format(startTime, 'h:mm a');
                  const formattedDate = format(startTime, 'EEE, MMM d');
                  
                  return (
                    <div key={event.id} className="rounded-md border overflow-hidden bg-white shadow-sm hover:shadow-md">
                      <div className="flex items-center">
                        <div className="w-1.5 h-full self-stretch bg-amber-400" />
                        <div className="flex-1 p-3 sm:p-4">
                          <div className="flex items-start justify-between gap-2 mb-2">
                            <div className="min-w-0 flex-1">
                              <h3 className="font-medium text-sm line-clamp-1">{event.title}</h3>
                              <div className="flex items-center text-xs text-muted-foreground mt-1">
                                <Clock className="h-3 w-3 mr-1 flex-shrink-0" />
                                <span>{formattedTime}</span>
                                <span className="mx-1.5">•</span>
                                <Calendar className="h-3 w-3 mr-1" />
                                <span>{formattedDate}</span>
                              </div>
                            </div>
                            <Badge variant="outline" className="text-[10px] rounded-sm px-1.5 py-0 h-4 bg-amber-50 text-amber-700 border-amber-200 flex-shrink-0">
                              No Lead
                            </Badge>
                          </div>
                          
                          {event.location && (
                            <div className="text-xs text-muted-foreground mb-3">
                              <div className="inline-flex items-center">
                                <MapPin className="h-3 w-3 mr-1 flex-shrink-0" />
                                <span className="truncate max-w-[200px]">{event.location}</span>
                              </div>
                            </div>
                          )}
                          
                          <div className="mt-2 flex justify-end border-t pt-2">
                            <Button 
                              variant="default" 
                              size="sm" 
                              className="h-7 px-2 text-xs"
                              onClick={() => handleConnectLead(event)}
                            >
                              <UserPlus className="h-3 w-3 mr-1" />
                              <span>Connect Lead</span>
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="text-center border rounded-lg py-12">
                <LinkIcon className="h-12 w-12 mx-auto text-gray-300 mb-4" />
                <h3 className="text-lg font-medium">All Events Connected</h3>
                <p className="text-sm text-muted-foreground mt-1">
                  Great job! All your events are connected to leads.
                </p>
              </div>
            )}
          </div>
          
          <div>
            <h3 className="text-lg font-medium mb-4">Lead-Connected Events</h3>
            {withLeads.length > 0 ? (
              <div className="grid gap-4 grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
                {withLeads.slice(0, 6).map(event => {
                  const startTime = new Date(event.startTime);
                  const formattedTime = format(startTime, 'h:mm a');
                  const formattedDate = format(startTime, 'EEE, MMM d');
                  
                  return (
                    <div key={event.id} className="rounded-md border overflow-hidden bg-white shadow-sm hover:shadow-md">
                      <div className="flex items-center">
                        <div className="w-1.5 h-full self-stretch bg-green-500" />
                        <div className="flex-1 p-3 sm:p-4">
                          <div className="flex items-start justify-between gap-2 mb-2">
                            <div className="min-w-0 flex-1">
                              <h3 className="font-medium text-sm line-clamp-1">{event.title}</h3>
                              <div className="flex items-center text-xs text-muted-foreground mt-1">
                                <Clock className="h-3 w-3 mr-1 flex-shrink-0" />
                                <span>{formattedTime}</span>
                                <span className="mx-1.5">•</span>
                                <Calendar className="h-3 w-3 mr-1" />
                                <span>{formattedDate}</span>
                              </div>
                            </div>
                            <Badge variant="outline" className="text-[10px] rounded-sm px-1.5 py-0 h-4 bg-green-50 text-green-700 border-green-200 flex-shrink-0">
                              Lead Connected
                            </Badge>
                          </div>
                          
                          {event.location && (
                            <div className="text-xs text-muted-foreground mb-3">
                              <div className="inline-flex items-center">
                                <MapPin className="h-3 w-3 mr-1 flex-shrink-0" />
                                <span className="truncate max-w-[200px]">{event.location}</span>
                              </div>
                            </div>
                          )}
                          
                          <div className="mt-2 flex justify-end border-t pt-2">
                            <Button 
                              variant="outline" 
                              size="sm" 
                              className="h-7 px-2 text-xs"
                              onClick={() => event.leadId && handleViewLead(event.leadId)}
                            >
                              <Eye className="h-3 w-3 mr-1" />
                              <span>View Lead</span>
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="text-center border rounded-lg py-12">
                <UserPlus className="h-12 w-12 mx-auto text-gray-300 mb-4" />
                <h3 className="text-lg font-medium">No Connected Events</h3>
                <p className="text-sm text-muted-foreground mt-1">
                  Connect your calendar events to leads to track meetings and conversations.
                </p>
              </div>
            )}
          </div>

          {selectedEvent && (
            <ConnectLeadDialog 
              event={selectedEvent}
              isOpen={showConnectLeadDialog}
              onClose={() => setShowConnectLeadDialog(false)}
            />
          )}
        </>
      )}
    </div>
  );
}

function Search(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="11" cy="11" r="8" />
      <path d="m21 21-4.3-4.3" />
    </svg>
  );
}