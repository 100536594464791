import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Switch } from "@/components/ui/switch";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Textarea } from "@/components/ui/textarea";
import { useToast } from "@/components/ui/use-toast";
import {
  AssistantTemplate,
  ReceptionistSettings,
  aiReceptionistKeys,
  useAssistantTemplates,
  useCreateAssistantTemplate,
  useDeleteAssistantTemplate,
  useReceptionistSettings,
  useUpdateAssistantTemplate,
  useUpdateReceptionistSettings
} from "@/services/ai-receptionist";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { AlertTriangle, CheckCircle, Loader2, PlusCircle, Trash2 } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

// Define the schema for AI Receptionist settings
const receptionistSettingsSchema = z.object({
  isEnabled: z.boolean().default(false),
  workingHours: z.object({
    enabled: z.boolean().default(true),
    schedule: z.array(
      z.object({
        day: z.enum(["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]),
        isActive: z.boolean(),
        startTime: z.string(),
        endTime: z.string()
      })
    ).default([])
  }),
  callForwarding: z.object({
    enabled: z.boolean().default(true),
    afterRings: z.number().min(1).max(10).default(3),
    forwardToNumber: z.string().optional()
  }),
  voiceSettings: z.object({
    voiceId: z.string().min(1, "Please select a voice"),
    voiceName: z.string().default("Sofia"),
    gender: z.enum(["male", "female"]).default("female"),
    style: z.enum(["professional", "friendly", "casual"]).default("professional"),
    speed: z.number().min(0.5).max(2.0).default(1.0)
  }),
  activeTemplateId: z.string().optional()
}).refine(
  (data) => {
    // If receptionist is disabled and call forwarding is enabled, forwardToNumber is required
    if (!data.isEnabled && data.callForwarding.enabled) {
      return !!data.callForwarding.forwardToNumber && data.callForwarding.forwardToNumber.trim() !== "";
    }
    return true;
  },
  {
    message: "Forward To Number is required when the receptionist is disabled and call forwarding is enabled",
    path: ["callForwarding", "forwardToNumber"]
  }
);

// Define the schema for AI Assistant templates
const assistantTemplateSchema = z.object({
  id: z.string().optional(),
  name: z.string().min(3, "Name must be at least 3 characters"),
  description: z.string().min(10, "Description must be at least 10 characters"),
  greeting: z.string().min(10, "Greeting must be at least 10 characters"),
  capabilities: z.array(z.string()).min(1, "Select at least one capability"),
  fallbackMessage: z.string().min(10, "Fallback message must be at least 10 characters").default("I'm sorry, I couldn't understand that. Could you please repeat?"),
  leadQualificationQuestions: z.array(z.string()).default([]),
  appointmentSettings: z.object({
    canScheduleAppointments: z.boolean().default(true),
    availabilityMessage: z.string().optional(),
    confirmationMessage: z.string().optional()
  }).default({
    canScheduleAppointments: true,
    availabilityMessage: "",
    confirmationMessage: ""
  }),
  customFields: z.array(
    z.object({
      name: z.string(),
      required: z.boolean()
    })
  ).optional()
});

export function AIReceptionistSettings() {
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const [activeTab, setActiveTab] = useState("general");
  const [editingTemplate, setEditingTemplate] = useState<AssistantTemplate | null>(null);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [newlyCreatedTemplate, setNewlyCreatedTemplate] = useState<AssistantTemplate | null>(null);

  // Fetch settings and templates from the server
  const { data: settings, isLoading: isLoadingSettings } = useReceptionistSettings();
  const { data: templates, isLoading: isLoadingTemplates } = useAssistantTemplates();
  const updateSettings = useUpdateReceptionistSettings();
  const createTemplate = useCreateAssistantTemplate();
  const updateTemplate = useUpdateAssistantTemplate();
  const deleteTemplate = useDeleteAssistantTemplate();

  // Form for general settings
  const settingsForm = useForm<ReceptionistSettings>({
    resolver: zodResolver(receptionistSettingsSchema),
    defaultValues: {
      isEnabled: false,
      workingHours: {
        enabled: true,
        schedule: []
      },
      callForwarding: {
        enabled: true,
        afterRings: 3,
        forwardToNumber: ""
      },
      voiceSettings: {
        voiceId: "nova",
        voiceName: "Nova",
        gender: "female",
        style: "professional",
        speed: 1.0
      },
      activeTemplateId: undefined
    }
  });

  // Update form when settings are loaded
  useEffect(() => {
    if (settings) {
      settingsForm.reset(settings);
    }
  }, [settings, settingsForm]);

  // Form for assistant template
  const templateForm = useForm<AssistantTemplate>({
    resolver: zodResolver(assistantTemplateSchema),
    defaultValues: {
      name: "",
      description: "",
      greeting: "",
      capabilities: [],
      fallbackMessage: "I'm sorry, I couldn't understand that. Could you please repeat?",
      leadQualificationQuestions: [],
      appointmentSettings: {
        canScheduleAppointments: true,
        availabilityMessage: "",
        confirmationMessage: ""
      }
    }
  });

  // Handle saving general settings
  const onSaveSettings = (data: ReceptionistSettings) => {
    console.log("Saving settings:", data);
    
    // Validate that when receptionist is disabled and call forwarding is enabled, a forward to number is required
    if (!data.isEnabled && data.callForwarding.enabled && (!data.callForwarding.forwardToNumber || data.callForwarding.forwardToNumber.trim() === "")) {
      toast({
        title: "Validation Error",
        description: "When the receptionist is disabled and call forwarding is enabled, a forward to number is required.",
        variant: "destructive",
      });
      return;
    }
    
    updateSettings.mutate(data, {
      onSuccess: () => {
        // Explicitly invalidate the settings query
        queryClient.invalidateQueries({ queryKey: aiReceptionistKeys.settings() });
        
        toast({
          title: "Settings saved",
          description: "Your Receptionist settings have been updated.",
        });
      },
      onError: (error) => {
        console.error("Error saving settings:", error);
        toast({
          title: "Error saving settings",
          description: "There was an error saving your settings. Please try again.",
          variant: "destructive",
        });
      }
    });
  };

  // Handle saving assistant template
  const onSaveTemplate = (data: AssistantTemplate) => {
    if (editingTemplate?.id) {
      // Update existing template
      updateTemplate.mutate({
        ...data,
        id: editingTemplate.id
      }, {
        onSuccess: () => {
          // Explicitly invalidate the templates query
          queryClient.invalidateQueries({ queryKey: aiReceptionistKeys.templates() });
          
          toast({
            title: "Template updated",
            description: "Your AI Assistant template has been updated.",
          });
          templateForm.reset();
          setEditingTemplate(null);
        },
        onError: (error) => {
          toast({
            title: "Error updating template",
            description: "There was an error updating your template. Please try again.",
            variant: "destructive"
          });
          console.error("Error updating template:", error);
        }
      });
    } else {
      // Create new template
      createTemplate.mutate(data, {
        onSuccess: (createdTemplate) => {
          // Explicitly invalidate the templates query
          queryClient.invalidateQueries({ queryKey: aiReceptionistKeys.templates() });
          
          toast({
            title: "Template created",
            description: "Your AI Assistant template has been created.",
          });
          templateForm.reset();
          
          // Store the newly created template for the success alert
          setNewlyCreatedTemplate(createdTemplate);
          
          // Automatically enable the receptionist and set this template as active
          if (settings && createdTemplate.id) {
            const updatedSettings = {
              ...settings,
              isEnabled: true,
              activeTemplateId: createdTemplate.id
            };
            
            updateSettings.mutate(updatedSettings, {
              onSuccess: () => {
                // Explicitly invalidate the settings query
                queryClient.invalidateQueries({ queryKey: aiReceptionistKeys.settings() });
                
                // Show success alert instead of toast
                setShowSuccessAlert(true);
                
                // Switch to general settings tab to show the updated settings
                setActiveTab("general");
                
                // Hide the alert after 10 seconds
                setTimeout(() => {
                  setShowSuccessAlert(false);
                }, 10000);
              },
              onError: (error) => {
                toast({
                  title: "Error enabling receptionist",
                  description: "The template was created, but there was an error enabling the receptionist.",
                  variant: "destructive"
                });
                console.error("Error enabling receptionist:", error);
              }
            });
          }
        },
        onError: (error) => {
          toast({
            title: "Error creating template",
            description: "There was an error creating your template. Please try again.",
            variant: "destructive"
          });
          console.error("Error creating template:", error);
        }
      });
    }
  };

  // Handle editing a template
  const handleEditTemplate = (template: AssistantTemplate) => {
    setEditingTemplate(template);
    templateForm.reset(template);
    setActiveTab("templates");
  };

  // Handle deleting a template
  const handleDeleteTemplate = (templateId: string) => {
    deleteTemplate.mutate(templateId, {
      onSuccess: () => {
        // Explicitly invalidate the templates and settings queries
        queryClient.invalidateQueries({ queryKey: aiReceptionistKeys.templates() });
        queryClient.invalidateQueries({ queryKey: aiReceptionistKeys.settings() });
        
        toast({
          title: "Template deleted",
          description: "Your AI Assistant template has been deleted.",
        });
      },
      onError: (error) => {
        toast({
          title: "Error deleting template",
          description: "There was an error deleting your template. Please try again.",
          variant: "destructive"
        });
        console.error("Error deleting template:", error);
      }
    });
  };

  // Handle creating a new template
  const handleNewTemplate = () => {
    setEditingTemplate(null);
    templateForm.reset({
      name: "",
      description: "",
      greeting: "",
      capabilities: [],
      fallbackMessage: "I'm sorry, I couldn't understand that. Could you please repeat?",
      leadQualificationQuestions: [],
      appointmentSettings: {
        canScheduleAppointments: true,
        availabilityMessage: "",
        confirmationMessage: ""
      }
    });
    setActiveTab("templates");
  };

  // Show loading state
  if (isLoadingSettings || isLoadingTemplates) {
    return (
      <Card className="w-full">
        <CardHeader>
          <CardTitle>Receptionist Settings</CardTitle>
          <CardDescription>
            Loading settings...
          </CardDescription>
        </CardHeader>
        <CardContent className="flex justify-center items-center py-8">
          <Loader2 className="h-8 w-8 animate-spin text-primary" />
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className="w-full">
      <CardHeader>
        <CardTitle>Receptionist Settings</CardTitle>
        <CardDescription>
          Configure your Receptionist to handle calls when you're unavailable
        </CardDescription>
      </CardHeader>
      <CardContent>
        {showSuccessAlert && newlyCreatedTemplate && (
          <Alert className="mb-6 bg-green-50 border-green-200">
            <CheckCircle className="h-4 w-4 text-green-600" />
            <AlertTitle className="text-green-800">Receptionist Enabled</AlertTitle>
            <AlertDescription className="text-green-700">
              Your AI Receptionist has been enabled with the new template "{newlyCreatedTemplate.name}".
              You can now receive calls at your configured phone number.
            </AlertDescription>
          </Alert>
        )}
        
        <Tabs value={activeTab} onValueChange={setActiveTab}>
          <TabsList className="grid w-full grid-cols-2">
            <TabsTrigger value="general">General Settings</TabsTrigger>
            <TabsTrigger value="templates">Assistant Templates</TabsTrigger>
          </TabsList>
          
          {/* General Settings Tab */}
          <TabsContent value="general">
            <Form {...settingsForm}>
              <form onSubmit={settingsForm.handleSubmit(onSaveSettings)} className="space-y-6">
                <FormField
                  control={settingsForm.control}
                  name="isEnabled"
                  render={({ field }) => (
                    <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4">
                      <div className="space-y-0.5">
                        <FormLabel className="text-base">Enable Receptionist</FormLabel>
                        <FormDescription>
                          When enabled, the Receptionist will handle calls based on your forwarding rules
                        </FormDescription>
                      </div>
                      <FormControl>
                        <Switch
                          checked={field.value}
                          onCheckedChange={field.onChange}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />

                <div className="space-y-4">
                  <h3 className="text-lg font-medium">Active Template</h3>
                  
                  <FormField
                    control={settingsForm.control}
                    name="activeTemplateId"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Select Active Template</FormLabel>
                        <Select 
                          onValueChange={field.onChange} 
                          value={field.value || ""}
                          disabled={isLoadingTemplates || !templates || templates.length === 0}
                        >
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue placeholder="Select a template" />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {templates && templates.map((template) => (
                              <SelectItem key={template.id} value={template.id || ""}>
                                {template.name}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                        <FormDescription>
                          Choose which assistant template to use for your Receptionist
                        </FormDescription>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  
                  {(!templates || templates.length === 0) && (
                    <div className="rounded-md bg-yellow-50 p-4">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <AlertTriangle className="h-5 w-5 text-yellow-400" />
                        </div>
                        <div className="ml-3">
                          <h3 className="text-sm font-medium text-yellow-800">No templates available</h3>
                          <div className="mt-2 text-sm text-yellow-700">
                            <p>
                              You need to create at least one assistant template before you can enable the Receptionist.
                              Switch to the "Assistant Templates" tab to create one.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                
                <div className="space-y-4">
                  <h3 className="text-lg font-medium">Call Forwarding Rules</h3>
                  
                  <FormField
                    control={settingsForm.control}
                    name="callForwarding.enabled"
                    render={({ field }) => (
                      <FormItem className="flex flex-row items-center justify-between rounded-lg border p-3">
                        <div className="space-y-0.5">
                          <FormLabel>Enable Call Forwarding</FormLabel>
                          <FormDescription>
                            {!settingsForm.watch("isEnabled") 
                              ? "When this is enabled calls will be forwarded to the number below" 
                              : "Disable the receptionist to activate call forwarding"}
                          </FormDescription>
                        </div>
                        <FormControl>
                          <Switch
                            checked={field.value}
                            onCheckedChange={field.onChange}
                            disabled={settingsForm.watch("isEnabled")}
                          />
                        </FormControl>
                      </FormItem>
                    )}
                  />
                  
                  <FormField
                    control={settingsForm.control}
                    name="callForwarding.afterRings"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Forward After Rings</FormLabel>
                        <Select 
                          onValueChange={(value) => field.onChange(parseInt(value))} 
                          value={field.value?.toString() || "3"}
                          disabled={settingsForm.watch("isEnabled") || !settingsForm.watch("callForwarding.enabled")}
                        >
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue placeholder="Select number of rings" />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((num) => (
                              <SelectItem key={num} value={num.toString()}>
                                {num} {num === 1 ? 'ring' : 'rings'}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                        <FormDescription>
                          {!settingsForm.watch("isEnabled") 
                            ? "Number of rings before forwarding to the number below" 
                            : "Number of rings before forwarding to Receptionist"}
                        </FormDescription>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  
                  <FormField
                    control={settingsForm.control}
                    name="callForwarding.forwardToNumber"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Forward To Number {!settingsForm.watch("isEnabled") ? "(Required)" : "(Optional)"}</FormLabel>
                        <FormControl>
                          <Input 
                            placeholder="e.g., +1 (555) 123-4567" 
                            {...field} 
                            value={field.value || ""}
                            disabled={settingsForm.watch("isEnabled") || !settingsForm.watch("callForwarding.enabled")}
                          />
                        </FormControl>
                        <FormDescription>
                          {!settingsForm.watch("isEnabled") 
                            ? "When the receptionist is disabled, calls will be forwarded to this number" 
                            : "If provided, calls will be forwarded to this number if the AI cannot handle them"}
                        </FormDescription>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                
                <Button type="submit" disabled={updateSettings.isPending}>
                  {updateSettings.isPending ? (
                    <>
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      Saving...
                    </>
                  ) : (
                    "Save Settings"
                  )}
                </Button>
              </form>
            </Form>
          </TabsContent>
          
          {/* Assistant Templates Tab */}
          <TabsContent value="templates">
            <div className="space-y-6">
              {/* List of existing templates */}
              {templates && templates.length > 0 && (
                <div className="space-y-4">
                  <div className="flex justify-between items-center">
                    <h3 className="text-lg font-medium">Your Assistant Templates</h3>
                    <Button variant="outline" onClick={handleNewTemplate}>
                      <PlusCircle className="h-4 w-4 mr-2" />
                      New Template
                    </Button>
                  </div>
                  
                  <div className="grid gap-4">
                    {templates.map((template) => (
                      <Card key={template.id} className="overflow-hidden">
                        <CardHeader className="pb-2">
                          <div className="flex justify-between items-start">
                            <CardTitle>{template.name}</CardTitle>
                            <div className="flex gap-2">
                              <Button 
                                variant="ghost" 
                                size="sm" 
                                onClick={() => handleEditTemplate(template)}
                              >
                                Edit
                              </Button>
                              <Button 
                                variant="ghost" 
                                size="sm"
                                onClick={() => handleDeleteTemplate(template.id!)}
                                disabled={deleteTemplate.isPending}
                              >
                                <Trash2 className="h-4 w-4 text-red-500" />
                              </Button>
                            </div>
                          </div>
                          <CardDescription>{template.description}</CardDescription>
                        </CardHeader>
                        <CardContent>
                          <div className="space-y-2">
                            <div>
                              <Label className="text-xs text-gray-500">Greeting</Label>
                              <p className="text-sm italic">"{template.greeting}"</p>
                            </div>
                            {template.capabilities && (
                              <div>
                                <Label className="text-xs text-gray-500">Capabilities</Label>
                                <div className="flex flex-wrap gap-1 mt-1">
                                  {template.capabilities.map((capability) => (
                                    <span 
                                      key={capability} 
                                      className="px-2 py-1 bg-primary/10 text-primary text-xs rounded-full"
                                    >
                                      {capability.replace('_', ' ')}
                                    </span>
                                  ))}
                                </div>
                              </div>
                            )}
                          </div>
                        </CardContent>
                      </Card>
                    ))}
                  </div>
                </div>
              )}
              
              {/* Template form */}
              <Form {...templateForm}>
                <form onSubmit={templateForm.handleSubmit(onSaveTemplate)} className="space-y-4">
                  <div className="flex justify-between items-center">
                    <h3 className="text-lg font-medium">
                      {editingTemplate ? "Edit Template" : "Create New Template"}
                    </h3>
                  </div>
                  
                  <FormField
                    control={templateForm.control}
                    name="name"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Template Name</FormLabel>
                        <FormControl>
                          <Input placeholder="e.g., Appointment Scheduler" {...field} />
                        </FormControl>
                        <FormDescription>
                          Give your assistant template a descriptive name
                        </FormDescription>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  
                  <FormField
                    control={templateForm.control}
                    name="description"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Description</FormLabel>
                        <FormControl>
                          <Textarea 
                            placeholder="Describe what this assistant does and how it helps callers" 
                            {...field} 
                          />
                        </FormControl>
                        <FormDescription>
                          This description will guide the AI's behavior during calls
                        </FormDescription>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  
                  <FormField
                    control={templateForm.control}
                    name="greeting"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Initial Greeting</FormLabel>
                        <FormControl>
                          <Textarea 
                            placeholder="Hello, thank you for calling. How can I help you today?" 
                            {...field} 
                          />
                        </FormControl>
                        <FormDescription>
                          This is what the AI will say when it first answers the call
                        </FormDescription>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  
                  <FormField
                    control={templateForm.control}
                    name="capabilities"
                    render={() => (
                      <FormItem>
                        <div className="mb-4">
                          <FormLabel className="text-base">Capabilities</FormLabel>
                          <FormDescription>
                            Select what your AI assistant can do for callers
                          </FormDescription>
                        </div>
                        <div className="grid grid-cols-2 gap-2">
                          {[
                            { id: "appointment_scheduling", label: "Schedule Appointments" },
                            { id: "lead_qualification", label: "Qualify Leads" },
                            { id: "information_collection", label: "Collect Information" },
                            { id: "faq_answering", label: "Answer FAQs" },
                            { id: "small_talk", label: "Small Talk" },
                            { id: "transfer_call", label: "Transfer Calls" },
                            { id: "take_message", label: "Take Messages" },
                            { id: "product_information", label: "Provide Product Info" },
                          ].map((capability) => (
                            <FormField
                              key={capability.id}
                              control={templateForm.control}
                              name="capabilities"
                              render={({ field }) => {
                                return (
                                  <FormItem
                                    key={capability.id}
                                    className="flex flex-row items-start space-x-3 space-y-0"
                                  >
                                    <FormControl>
                                      <input
                                        type="checkbox"
                                        className="h-4 w-4 mt-1"
                                        checked={field.value?.includes(capability.id)}
                                        onChange={(e) => {
                                          const checked = e.target.checked;
                                          const currentValue = field.value || [];
                                          field.onChange(
                                            checked
                                              ? [...currentValue, capability.id]
                                              : currentValue.filter((value) => value !== capability.id)
                                          );
                                        }}
                                      />
                                    </FormControl>
                                    <FormLabel className="font-normal">
                                      {capability.label}
                                    </FormLabel>
                                  </FormItem>
                                );
                              }}
                            />
                          ))}
                        </div>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  
                  <div className="flex justify-end gap-2">
                    <Button 
                      type="button" 
                      variant="outline" 
                      onClick={() => {
                        templateForm.reset();
                        setEditingTemplate(null);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button 
                      type="submit"
                      disabled={createTemplate.isPending || updateTemplate.isPending}
                    >
                      {(createTemplate.isPending || updateTemplate.isPending) ? (
                        <>
                          <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                          {editingTemplate ? "Updating..." : "Creating..."}
                        </>
                      ) : (
                        editingTemplate ? "Update Template" : "Create Template"
                      )}
                    </Button>
                  </div>
                </form>
              </Form>
            </div>
          </TabsContent>
        </Tabs>
      </CardContent>
    </Card>
  );
} 