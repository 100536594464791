import { AIReceptionistDashboard } from "@/components/ai-receptionist/AIReceptionistDashboard";
import { MainLayout } from "@/components/layout/MainLayout";
import { PageContainer } from "@/components/layout/PageContainer";
import { WebSocketAIReceptionistNotification } from "@/components/websocket/WebSocketAIReceptionistNotification";
import { useNavigate, useSearch } from "@tanstack/react-router";
import { useEffect } from "react";

export function AIReceptionistPage() {
  const navigate = useNavigate();
  const { tab } = useSearch({ from: '/ai-receptionist' });
  
  // Handle tab from URL
  useEffect(() => {
    // If no tab is specified, default to "overview"
    if (!tab) {
      navigate({
        to: '/ai-receptionist',
        search: (prev) => ({ ...prev, tab: "overview" }),
        replace: true
      });
    }
  }, [tab, navigate]);

  return (
    <MainLayout pageTitle="AI Receptionist">
      <PageContainer>
        <div className="container py-20 sm:py-6">
          <AIReceptionistDashboard />
          <WebSocketAIReceptionistNotification />
        </div>
      </PageContainer>
    </MainLayout>
  );
} 