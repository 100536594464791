import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Download, FileText, Mail } from "lucide-react";
import { useEffect, useState } from "react";
import { DateRange } from "react-day-picker";
import { DateRangeSelector, DateRangeValue } from "./DateRangeSelector";

interface ExportReportDialogProps {
  timeframe: DateRangeValue;
  customDateRange: { startDate: Date; endDate: Date } | null;
  onExport: (options: ExportOptions) => void;
}

export interface ExportOptions {
  format: 'csv';
  timeframe: DateRangeValue;
  customDateRange: { startDate: Date; endDate: Date } | null;
  includeMetrics: boolean;
  includeActions: boolean;
  includeActivity: boolean;
  includeTeam: boolean;
  emailTo: string;
}

export function ExportReportDialog({ 
  timeframe, 
  customDateRange, 
  onExport 
}: ExportReportDialogProps) {
  const [open, setOpen] = useState(false);
  const [currentTimeframe, setCurrentTimeframe] = useState<DateRangeValue>(timeframe);
  const [currentCustomDateRange, setCurrentCustomDateRange] = useState(customDateRange);
  const [dateRange, setDateRange] = useState<DateRange | undefined>(() => {
    if (customDateRange) {
      return { from: customDateRange.startDate, to: customDateRange.endDate };
    }
    return undefined;
  });
  const [includeMetrics, setIncludeMetrics] = useState(true);
  const [includeActions, setIncludeActions] = useState(true);
  const [includeActivity, setIncludeActivity] = useState(true);
  const [includeTeam, setIncludeTeam] = useState(true);
  const [emailTo, setEmailTo] = useState('');
  const [sendEmail, setSendEmail] = useState(false);
  const [emailError, setEmailError] = useState('');

  // Update internal state when props change
  useEffect(() => {
    setCurrentTimeframe(timeframe);
    setCurrentCustomDateRange(customDateRange);
    
    if (customDateRange) {
      setDateRange({ from: customDateRange.startDate, to: customDateRange.endDate });
    } else {
      setDateRange(undefined);
    }
  }, [timeframe, customDateRange]);

  // Validate email
  const validateEmail = (email: string): boolean => {
    if (!email) return false;
    
    // Simple email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Check if at least one section is selected
  const isAnySectionSelected = includeMetrics || includeActions || includeActivity || includeTeam;

  const handleExport = () => {
    // Clear previous errors
    setEmailError('');
    
    // Validate email if sending via email
    if (sendEmail) {
      if (!emailTo) {
        setEmailError('Email address is required');
        return;
      }
      
      if (!validateEmail(emailTo)) {
        setEmailError('Please enter a valid email address');
        return;
      }
    }
    
    // Validate that at least one section is selected
    if (!isAnySectionSelected) {
      return;
    }
    
    onExport({
      format: 'csv',
      timeframe: currentTimeframe,
      customDateRange: currentCustomDateRange,
      includeMetrics,
      includeActions,
      includeActivity,
      includeTeam,
      emailTo: sendEmail ? emailTo : '',
    });
    setOpen(false);
  };

  const handleTimeframeChange = (value: DateRangeValue) => {
    setCurrentTimeframe(value);
    if (value !== 'custom') {
      setCurrentCustomDateRange(null);
      setDateRange(undefined);
    }
  };

  const handleCustomDateChange = (startDate: Date, endDate: Date) => {
    setCurrentCustomDateRange({ startDate, endDate });
  };

  // Handle date range picker changes
  const handleDateRangeChange = (range: DateRange | undefined) => {
    setDateRange(range);
    if (range?.from && range?.to) {
      setCurrentTimeframe('custom');
      setCurrentCustomDateRange({ 
        startDate: range.from, 
        endDate: range.to 
      });
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button>
          <Download className="mr-2 h-4 w-4" />
          Export Report
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[500px]">
        <DialogHeader>
          <DialogTitle>Export Dashboard Report</DialogTitle>
          <DialogDescription>
            Configure your report export options
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="format" className="text-right">
              Format
            </Label>
            <div className="col-span-3 flex items-center">
              <FileText className="mr-2 h-4 w-4" />
              <span>CSV Spreadsheet</span>
            </div>
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label className="text-right">
              Date Range
            </Label>
            <div className="col-span-3">
              <DateRangeSelector
                mode="export"
                initialValue={currentTimeframe}
                onChange={handleTimeframeChange}
                onCustomDateChange={handleCustomDateChange}
                initialCustomRange={dateRange}
                onDateRangeChange={handleDateRangeChange}
              />
            </div>
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label className="text-right">
              Include
            </Label>
            <div className="col-span-3 space-y-2">
              <div className="flex items-center space-x-2">
                <Checkbox 
                  id="metrics" 
                  checked={includeMetrics} 
                  onCheckedChange={(checked) => setIncludeMetrics(!!checked)} 
                />
                <Label htmlFor="metrics">Performance Metrics</Label>
              </div>
              <div className="flex items-center space-x-2">
                <Checkbox 
                  id="actions" 
                  checked={includeActions} 
                  onCheckedChange={(checked) => setIncludeActions(!!checked)} 
                />
                <Label htmlFor="actions">Action Items</Label>
              </div>
              <div className="flex items-center space-x-2">
                <Checkbox 
                  id="activity" 
                  checked={includeActivity} 
                  onCheckedChange={(checked) => setIncludeActivity(!!checked)} 
                />
                <Label htmlFor="activity">Activity Feed</Label>
              </div>
              <div className="flex items-center space-x-2">
                <Checkbox 
                  id="team" 
                  checked={includeTeam} 
                  onCheckedChange={(checked) => setIncludeTeam(!!checked)} 
                />
                <Label htmlFor="team">Team Comparison</Label>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <div className="col-span-4">
              <div className="flex items-center space-x-2">
                <Checkbox 
                  id="send-email" 
                  checked={sendEmail} 
                  onCheckedChange={(checked) => setSendEmail(!!checked)} 
                />
                <Label htmlFor="send-email">Send report via email</Label>
              </div>
            </div>
          </div>
          {sendEmail && (
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="email" className="text-right">
                Email To
              </Label>
              <div className="col-span-3 flex flex-col space-y-1">
                <div className="flex items-center space-x-2">
                  <Mail className="h-4 w-4 text-gray-500" />
                  <Input
                    id="email"
                    type="email"
                    value={emailTo}
                    onChange={(e) => {
                      setEmailTo(e.target.value);
                      // Clear error when user types
                      if (emailError) setEmailError('');
                    }}
                    placeholder="Enter email address"
                    className={emailError ? "border-red-500" : ""}
                  />
                </div>
                {emailError && (
                  <p className="text-red-500 text-sm ml-6">{emailError}</p>
                )}
              </div>
            </div>
          )}
        </div>
        <DialogFooter>
          <Button variant="outline" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button 
            onClick={handleExport} 
            disabled={!isAnySectionSelected}
            title={!isAnySectionSelected ? "Please select at least one section to include" : ""}
          >
            <Download className="mr-2 h-4 w-4" />
            Export Report
          </Button>
        </DialogFooter>
        {!isAnySectionSelected && (
          <p className="text-red-500 text-sm text-center mt-2">
            Please select at least one section to include in the report
          </p>
        )}
      </DialogContent>
    </Dialog>
  );
} 