import { axiosInstance } from '@/lib/api';
import { CreateTeamRequest, InviteToTeamRequest, Team, TeamInvitation, UpdateTeamRequest } from '@/types/team';

/**
 * Get all teams for the authenticated user
 */
export const getTeams = async (): Promise<Team[]> => {
  const response = await axiosInstance.get('/teams');
  return response.data.teams;
};

/**
 * Get team details
 */
export const getTeamDetails = async (teamId: number): Promise<Team> => {
  const response = await axiosInstance.get(`/teams/${teamId}`);
  return response.data.team;
};

/**
 * Create a new team
 */
export const createTeam = async (data: CreateTeamRequest): Promise<Team> => {
  const response = await axiosInstance.post('/teams', data);
  return response.data.team;
};

/**
 * Update a team
 */
export const updateTeam = async (teamId: number, data: UpdateTeamRequest): Promise<Team> => {
  const response = await axiosInstance.put(`/teams/${teamId}`, data);
  return response.data.team;
};

/**
 * Delete a team
 */
export const deleteTeam = async (teamId: number): Promise<{ message: string }> => {
  const response = await axiosInstance.delete(`/teams/${teamId}`);
  return response.data;
};

/**
 * Invite a user to a team
 */
export const inviteToTeam = async (teamId: number, data: InviteToTeamRequest): Promise<{ 
  message: string; 
  invitation?: { 
    teamId: number;
    email: string;
    role: string;
    createdAt: Date;
    updatedAt: Date;
  }
}> => {
  const response = await axiosInstance.post(`/teams/${teamId}/invite`, data);
  return response.data;
};

/**
 * Get all invitations for the authenticated user
 */
export const getMyInvitations = async (): Promise<TeamInvitation[]> => {
  const response = await axiosInstance.get('/teams/invitations');
  return response.data.invitations;
};

/**
 * Accept a team invitation
 */
export const acceptInvitation = async (invitationId: number): Promise<{ message: string; team: Team }> => {
  const response = await axiosInstance.post(`/teams/invitations/${invitationId}/accept`);
  return response.data;
};

/**
 * Decline a team invitation
 */
export const declineInvitation = async (invitationId: number): Promise<{ message: string }> => {
  const response = await axiosInstance.post(`/teams/invitations/${invitationId}/decline`);
  return response.data;
};

/**
 * Update a team member's role
 */
export const updateMemberRole = async (
  teamId: number, 
  memberId: number, 
  role: 'admin' | 'member'
): Promise<{ message: string }> => {
  const response = await axiosInstance.put(`/teams/${teamId}/members/${memberId}/role`, { role });
  return response.data;
};

/**
 * Remove a member from a team
 */
export const removeMember = async (teamId: number, memberId: number): Promise<{ message: string }> => {
  const response = await axiosInstance.delete(`/teams/${teamId}/members/${memberId}`);
  return response.data;
};

/**
 * Switch to a different team (updates current_team_id in the database)
 */
export const switchCurrentTeam = async (teamId: number | null): Promise<{ message: string }> => {
  const response = await axiosInstance.post('/teams/switch', { teamId });
  return response.data;
}; 