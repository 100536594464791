import { Input } from '@/components/ui/input';
import { cn } from '@/lib/utils';
import { Lead } from '@/types';
import { formFieldClasses } from './utils';

interface LeadNameHeaderProps {
  formData: Partial<Lead>;
  handleInputChange: (field: keyof Lead, value: string | number | boolean) => void;
  handleBlur: (field: keyof Lead) => void;
}

export function LeadNameHeader({
  formData,
  handleInputChange,
  handleBlur
}: LeadNameHeaderProps) {
  return (
    <div className="px-4 mt-3 bg-white dark:bg-gray-900">
      <div className="space-y-4">
        <div className="space-y-0.5">
          <label className={formFieldClasses.label}>Lead Name</label>
          <Input
            value={formData.name || ''}
            onChange={(e) => handleInputChange('name', e.target.value)}
            onBlur={() => handleBlur('name')}
            className={cn(formFieldClasses.input, formFieldClasses.leadName)}
            placeholder="Enter lead name"
          />
        </div>
      </div>
    </div>
  );
} 