import { Button } from '@/components/ui/button';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { useWebSocket } from '@/contexts/WebSocketContext';
import { axiosInstance } from '@/lib/api';
import { SocketEvents } from '@/services/websocket.service';
import { useAuthStore } from '@/store/authStore';
import { useLeadStore } from '@/store/leadStore';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import { MessageSquare, X } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'sonner';

interface TextMessage {
  id: string;
  from: string;
  body: string;
  timestamp: string;
  conversationId: number;
  leadId?: number;
  leadName?: string;
  direction: 'inbound' | 'outbound';
}

interface Lead {
  id: number;
  name: string | null;
  phoneNumber?: string;
  [key: string]: any;
}

// Helper function to normalize phone numbers by removing +1 prefix and any non-digit characters
function normalizePhoneNumber(phone: string): string {
  if (!phone) return '';
  
  // Remove all non-digit characters first
  const digitsOnly = phone.replace(/\D/g, '');
  
  // If it starts with 1 and has 11 digits (US format with country code), remove the 1
  if (digitsOnly.length === 11 && digitsOnly.startsWith('1')) {
    return digitsOnly.substring(1);
  }
  
  return digitsOnly;
}

// Cache for phone number to lead ID mapping to avoid redundant API calls
const phoneToLeadCache = new Map<string, { id: number; name: string | null; timestamp: number }>();

// Cache expiration time (30 minutes)
const CACHE_EXPIRATION_MS = 30 * 60 * 1000;

export function WebSocketTextMessageNotification() {
  const { on } = useWebSocket();
  const [incomingMessage, setIncomingMessage] = useState<TextMessage | null>(null);
  const [showNotification, setShowNotification] = useState(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const navigate = useNavigate();
  const setSelectedLead = useLeadStore((state) => state.setSelectedLead);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const currentUser = useAuthStore((state) => state.user);
  const [isLookingUpLead, setIsLookingUpLead] = useState(false);
  const queryClient = useQueryClient();
  const leadLookupTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  // Query to find lead by phone number when needed - with better caching strategy
  const { data: leadData, refetch: lookupLead } = useQuery<Lead | null>({
    queryKey: ['lead-by-phone', incomingMessage?.from],
    queryFn: async () => {
      if (!incomingMessage?.from) return null;
      
      const normalizedPhone = normalizePhoneNumber(incomingMessage.from);
      console.log(`[WebSocketTextMessageNotification] Looking up lead with phone: ${normalizedPhone}`);
      
      // Check cache first
      const cachedLead = phoneToLeadCache.get(normalizedPhone);
      if (cachedLead && (Date.now() - cachedLead.timestamp) < CACHE_EXPIRATION_MS) {
        console.log(`[WebSocketTextMessageNotification] Using cached lead data for ${normalizedPhone}`);
        return { id: cachedLead.id, name: cachedLead.name } as Lead;
      }
      
      try {
        const response = await axiosInstance.get(`/leads/phone/${normalizedPhone}`);
        const lead = response.data.lead as Lead;
        
        // Update cache
        if (lead) {
          phoneToLeadCache.set(normalizedPhone, { 
            id: lead.id, 
            name: lead.name,
            timestamp: Date.now()
          });
        }
        
        return lead;
      } catch (error) {
        console.error('[WebSocketTextMessageNotification] Error looking up lead:', error);
        return null;
      }
    },
    enabled: false, // Don't run automatically
    staleTime: CACHE_EXPIRATION_MS, // Consider data fresh for 30 minutes
    gcTime: CACHE_EXPIRATION_MS // Keep in cache for 30 minutes (renamed from cacheTime)
  });

  // Initialize audio element
  useEffect(() => {
    audioRef.current = new Audio('/sounds/message.mp3');
    
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }
    };
  }, []);

  // Subscribe to new message events
  useEffect(() => {
    console.log('[WebSocketTextMessageNotification] Setting up message notification listener');
    
    const unsubscribe = on<{
      from: string;
      to: string;
      body: string;
      timestamp: string;
      conversationId: number;
      leadId?: number;
      leadName?: string;
      isNewConversation?: boolean;
      direction: 'inbound' | 'outbound';
      userId?: number;
    }>(SocketEvents.NEW_MESSAGE, (data) => {
      console.log('[WebSocketTextMessageNotification] New message received:', data);
      
      // Skip outbound messages (messages sent by users)
      if (data.direction === 'outbound') {
        console.log('[WebSocketTextMessageNotification] Skipping outbound message');
        return;
      }
      
      // Skip messages sent by the current user
      if (data.userId && currentUser && data.userId === currentUser.id) {
        console.log('[WebSocketTextMessageNotification] Skipping message sent by current user');
        return;
      }
      
      // Create a unique ID for this message
      const messageId = `msg-${Date.now()}`;
      
      // Set the incoming message
      setIncomingMessage({
        id: messageId,
        from: data.from,
        body: data.body,
        timestamp: data.timestamp,
        conversationId: data.conversationId,
        leadId: data.leadId,
        leadName: data.leadName,
        direction: data.direction
      });
      
      // Show the notification
      setShowNotification(true);
      
      // Play notification sound
      if (audioRef.current) {
        audioRef.current.play().catch(error => {
          console.error('Error playing notification sound:', error);
        });
      }
      
      // Auto-hide notification after 10 seconds
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      
      timeoutRef.current = setTimeout(() => {
        setShowNotification(false);
      }, 10000);
      
      // If we don't have a lead ID, try to look it up with debouncing
      if (!data.leadId) {
        // Clear any existing timeout
        if (leadLookupTimeoutRef.current) {
          clearTimeout(leadLookupTimeoutRef.current);
        }
        
        // Set a new timeout to debounce the lookup
        leadLookupTimeoutRef.current = setTimeout(() => {
          setIsLookingUpLead(true);
          lookupLead().then(() => {
            setIsLookingUpLead(false);
          });
        }, 300); // 300ms debounce
      } else if (data.leadId) {
        // If we have a lead ID, update the cache
        const normalizedPhone = normalizePhoneNumber(data.from);
        phoneToLeadCache.set(normalizedPhone, {
          id: data.leadId,
          name: data.leadName || null,
          timestamp: Date.now()
        });
      }
    });
    
    return () => {
      unsubscribe();
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      if (leadLookupTimeoutRef.current) {
        clearTimeout(leadLookupTimeoutRef.current);
      }
    };
  }, [on, currentUser, lookupLead]);

  // Handle dismiss notification
  const handleDismiss = () => {
    setShowNotification(false);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  };

  // Handle view lead
  const handleViewLead = () => {
    if (!incomingMessage) return;
    
    // Normalize the phone number
    const normalizedPhone = normalizePhoneNumber(incomingMessage.from);
    console.log(`[WebSocketTextMessageNotification] Normalized phone: ${normalizedPhone}`);
    
    // Check cache first for the lead ID
    const cachedLead = phoneToLeadCache.get(normalizedPhone);
    
    // If we have a lead ID from the message, our lookup, or the cache
    const effectiveLeadId = incomingMessage.leadId || (leadData?.id) || (cachedLead ? cachedLead.id : undefined);
    const effectiveLeadName = incomingMessage.leadName || (leadData?.name) || (cachedLead ? cachedLead.name : null);
    
    if (effectiveLeadId) {
      // First close the notification to prevent state updates during navigation
      handleDismiss();
      
      // Update the lead store with the selected lead ID
      setSelectedLead({
        id: effectiveLeadId,
        name: effectiveLeadName,
        phone: normalizedPhone
      });
      
      // Use setTimeout to ensure state updates complete before navigation
      setTimeout(() => {
        // Navigate to the leads page
        navigate({ to: '/leads' });
        console.log(`[WebSocketTextMessageNotification] Navigated to lead ID: ${effectiveLeadId}`);
        toast.success('Navigating to lead details');
      }, 50);
    } else {
      // Try to find the lead by normalized phone number
      console.log(`[WebSocketTextMessageNotification] No lead ID found, searching by phone: ${normalizedPhone}`);
      
      // Dismiss the notification
      handleDismiss();
      
      // Navigate to the leads page with the phone number as a search parameter
      navigate({ 
        to: '/leads',
        search: { phone: normalizedPhone }
      });
      
      toast.info('Searching for lead by phone number');
    }
  };

  // If no notification to show, return null
  if (!showNotification || !incomingMessage) {
    return null;
  }

  // Determine if we have a lead ID (either from the message, our lookup, or the cache)
  const normalizedPhone = normalizePhoneNumber(incomingMessage.from);
  const cachedLead = phoneToLeadCache.get(normalizedPhone);
  const hasLeadId = !!(incomingMessage.leadId || (leadData?.id) || (cachedLead?.id));

  return (
    <div className="fixed bottom-4 right-4 z-50 max-w-sm w-full shadow-lg">
      <Card className="border-2 border-green-500">
        <CardHeader className="bg-green-50 dark:bg-green-900/20 pb-2 flex flex-row items-center justify-between">
          <CardTitle className="text-sm font-medium flex items-center">
            <MessageSquare className="h-4 w-4 text-green-500 mr-2" />
            New Message
          </CardTitle>
          <Button 
            variant="ghost" 
            size="icon" 
            className="h-6 w-6" 
            onClick={handleDismiss}
          >
            <X className="h-4 w-4" />
          </Button>
        </CardHeader>
        <CardContent className="pt-3">
          <div className="space-y-1">
            <div className="flex items-center justify-between">
              <p className="text-xs font-medium text-muted-foreground">
                From: {incomingMessage.leadName || (leadData?.name) || (cachedLead?.name) || incomingMessage.from}
              </p>
              <p className="text-xs text-muted-foreground">
                {new Date(incomingMessage.timestamp).toLocaleTimeString()}
              </p>
            </div>
            <p className="text-sm">
              {incomingMessage.body.length > 100 
                ? `${incomingMessage.body.substring(0, 100)}...` 
                : incomingMessage.body}
            </p>
          </div>
        </CardContent>
        <CardFooter className="pt-0">
          <div className="flex justify-end w-full space-x-2">
            <Button 
              variant="outline" 
              size="sm" 
              onClick={handleDismiss}
            >
              Dismiss
            </Button>
            <Button 
              variant="default" 
              size="sm" 
              onClick={handleViewLead}
              disabled={isLookingUpLead}
            >
              {isLookingUpLead ? 'Looking up lead...' : hasLeadId ? 'View Lead' : 'Search for Lead'}
            </Button>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
} 