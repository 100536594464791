import { Input } from '@/components/ui/input';
import { cn } from '@/lib/utils';
import { Lead } from '@/types';
import { Clock, Mail, Phone } from 'lucide-react';
import { formFieldClasses, formatPhoneNumberForDisplay } from './utils';

interface ContactInfoSectionProps {
  formData: Partial<Lead>;
  handleInputChange: (field: keyof Lead, value: string | number | boolean) => void;
  handleBlur: (field: keyof Lead) => void;
  lead: Lead | null;
}

export function ContactInfoSection({
  formData,
  handleInputChange,
  handleBlur,
  lead
}: ContactInfoSectionProps) {
  return (
    <div className={cn(formFieldClasses.section, "mt-4 mx-4")}>
      <div className="space-y-3">
        <div className="grid grid-cols-3 gap-3">
          <div className={cn(formFieldClasses.field, "col-span-2")}>
            <label className={formFieldClasses.label}>Phone Number</label>
            <div className="relative">
              <Phone className={formFieldClasses.icon} />
              <Input
                value={formData.phoneNumber ? formData.phoneNumber : ''}
                onChange={(e) => {
                  const formatted = formatPhoneNumberForDisplay(e.target.value);
                  handleInputChange('phoneNumber', formatted);
                }}
                onBlur={() => {
                  if (!lead || !formData.phoneNumber) return;
                  // Check if the phone number has changed by comparing the cleaned versions
                  const currentFormatted = lead.phoneNumber?.replace(/\D/g, '') || '';
                  const newFormatted = formData.phoneNumber?.replace(/\D/g, '') || '';
                  if (newFormatted !== currentFormatted) {
                    handleBlur('phoneNumber');
                  }
                }}
                className={cn(formFieldClasses.input, formFieldClasses.inputWithIcon, formFieldClasses.phoneNumber)}
                placeholder="(555) 555-5555"
              />
            </div>
          </div>
          <div className={formFieldClasses.field}>
            <label className={formFieldClasses.label}>Best Time</label>
            <div className="relative">
              <Clock className={formFieldClasses.icon} />
              <Input
                value={formData.bestTimeOfCall || ''}
                onChange={(e) => handleInputChange('bestTimeOfCall', e.target.value)}
                onBlur={() => handleBlur('bestTimeOfCall')}
                className={cn(formFieldClasses.input, formFieldClasses.inputWithIcon)}
                placeholder="Best time"
              />
            </div>
          </div>
        </div>
        <div className={formFieldClasses.field}>
          <label className={formFieldClasses.label}>Email Address</label>
          <div className="relative">
            <Mail className={formFieldClasses.icon} />
            <Input
              value={formData.email || ''}
              onChange={(e) => handleInputChange('email', e.target.value)}
              onBlur={() => handleBlur('email')}
              className={cn(formFieldClasses.input, formFieldClasses.inputWithIcon)}
              placeholder="Email address"
              type="email"
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-3">
          <div className={formFieldClasses.field}>
            <label className={formFieldClasses.label}>Title</label>
            <Input
              value={formData.title || ''}
              onChange={(e) => handleInputChange('title', e.target.value)}
              onBlur={() => handleBlur('title')}
              className={formFieldClasses.input}
              placeholder="Job title"
            />
          </div>
          <div className={formFieldClasses.field}>
            <label className={formFieldClasses.label}>Business</label>
            <Input
              value={formData.businessName || ''}
              onChange={(e) => handleInputChange('businessName', e.target.value)}
              onBlur={() => handleBlur('businessName')}
              className={formFieldClasses.input}
              placeholder="Business name"
            />
          </div>
        </div>
      </div>
    </div>
  );
} 