import { CallService } from '@/services/call.service';
import { useAuthStore } from '@/store/authStore';
import { ReactNode, createContext, useContext, useEffect, useState } from 'react';

interface CallServiceContextType {
  initialized: boolean;
}

const CallServiceContext = createContext<CallServiceContextType>({
  initialized: false
});

export function useCallService() {
  return useContext(CallServiceContext);
}

interface CallServiceProviderProps {
  children: ReactNode;
}

// Don't initialize immediately - wait for auth check
let initPromise: Promise<void> | null = null;

// Check if current route is a public route (landing page or auth)
const isPublicRoute = () => {
  const currentPath = window.location.pathname;
  return currentPath === '/' || currentPath === '/auth' || currentPath === '/landing';
};

export function CallServiceProvider({ children }: CallServiceProviderProps) {
  const [initialized, setInitialized] = useState(false);
  const { token } = useAuthStore();
  
  useEffect(() => {
    // Only initialize if user is authenticated and not on a public route
    if (token && !isPublicRoute()) {
      console.log('[CallServiceContext] User is authenticated and on protected route, initializing CallService');
      
      // Initialize if not already initialized
      if (!initPromise) {
        initPromise = CallService.initializeDevice().catch(error => {
          console.error('[CallServiceContext] Failed to initialize CallService:', error);
        });
      }
      
      // Wait for the initialization to complete
      initPromise.then(() => {
        setInitialized(true);
        console.log('[CallServiceContext] CallService initialized');
      });
      
      // Re-initialize every hour to ensure the token stays fresh
      const refreshInterval = setInterval(() => {
        console.log('[CallServiceContext] Re-initializing CallService');
        CallService.initializeDevice().catch(error => {
          console.error('[CallServiceContext] Failed to re-initialize CallService:', error);
        });
      }, 1000 * 60 * 60); // Every hour
      
      return () => {
        clearInterval(refreshInterval);
      };
    } else {
      if (!token) {
        console.log('[CallServiceContext] User is not authenticated, skipping CallService initialization');
      } else if (isPublicRoute()) {
        console.log('[CallServiceContext] On public route, skipping CallService initialization');
      }
      setInitialized(false);
      initPromise = null;
    }
  }, [token]);
  
  // Also listen for location changes to handle route changes
  useEffect(() => {
    const handleLocationChange = () => {
      console.log('[CallServiceContext] Location changed:', window.location.pathname);
      console.log('[CallServiceContext] Is public route:', isPublicRoute());
      
      const storeToken = useAuthStore.getState().token;
      
      // Only initialize if authenticated AND not on a public route
      if (storeToken && !isPublicRoute()) {
        console.log('[CallServiceContext] Token available and on authenticated route, initializing CallService');
        if (!initPromise) {
          initPromise = CallService.initializeDevice().catch(error => {
            console.error('[CallServiceContext] Failed to initialize CallService:', error);
          });
          
          initPromise.then(() => {
            setInitialized(true);
            console.log('[CallServiceContext] CallService initialized');
          });
        }
      } else if (storeToken && isPublicRoute()) {
        console.log('[CallServiceContext] On public route, not initializing CallService');
        setInitialized(false);
      }
    };
    
    // Listen for popstate events (browser back/forward)
    window.addEventListener('popstate', handleLocationChange);
    
    return () => {
      window.removeEventListener('popstate', handleLocationChange);
    };
  }, []);
  
  return (
    <CallServiceContext.Provider value={{ initialized }}>
      {children}
    </CallServiceContext.Provider>
  );
}