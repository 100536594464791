import { Badge } from "@/components/ui/badge";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";
import { useToast } from "@/components/ui/use-toast";
import { formatDuration } from "@/lib/utils";
import { useActiveCalls } from "@/services/ai-receptionist";
import { Phone, PhoneCall, PhoneForwarded, PhoneOff } from "lucide-react";
import { useEffect, useState } from "react";

interface RealTimeCallStatusProps {
  userId: string;
}

export function RealTimeCallStatus({ userId }: RealTimeCallStatusProps) {
  const { toast } = useToast();
  const { data, isLoading, error: queryError } = useActiveCalls();
  const [elapsedTime, setElapsedTime] = useState<Record<string, number>>({});
  const [error, setError] = useState<string | null>(null);

  // Set error message if query fails
  useEffect(() => {
    if (queryError) {
      setError("Failed to load active calls");
      toast({
        title: "Error",
        description: "Failed to load active calls. Please try again.",
        variant: "destructive",
      });
    } else {
      setError(null);
    }
  }, [queryError, toast]);

  // Initialize elapsed time for each call
  useEffect(() => {
    if (!data?.activeCalls) return;
    
    const initialElapsedTime: Record<string, number> = {};
    data.activeCalls.forEach(call => {
      initialElapsedTime[call.id] = call.duration;
    });
    setElapsedTime(initialElapsedTime);
  }, [data?.activeCalls]);

  // Update elapsed time for active calls
  useEffect(() => {
    if (!data?.activeCalls || data.activeCalls.length === 0) return;
    
    const timer = setInterval(() => {
      setElapsedTime(prev => {
        const updated = { ...prev };
        data.activeCalls.forEach(call => {
          if (call.status === "in-progress") {
            updated[call.id] = (updated[call.id] || 0) + 1;
          }
        });
        return updated;
      });
    }, 1000);
    
    return () => clearInterval(timer);
  }, [data?.activeCalls]);

  const getStatusIcon = (status: string) => {
    switch (status) {
      case "connecting":
        return <PhoneCall className="h-4 w-4 text-yellow-500" />;
      case "in-progress":
        return <PhoneForwarded className="h-4 w-4 text-green-500" />;
      case "completed":
        return <Phone className="h-4 w-4 text-blue-500" />;
      case "failed":
        return <PhoneOff className="h-4 w-4 text-red-500" />;
      default:
        return <Phone className="h-4 w-4 text-gray-500" />;
    }
  };

  const getStatusBadge = (status: string) => {
    switch (status) {
      case "connecting":
        return <Badge variant="outline" className="bg-yellow-50 text-yellow-700 border-yellow-200">Connecting</Badge>;
      case "in-progress":
        return <Badge variant="outline" className="bg-green-50 text-green-700 border-green-200">In Progress</Badge>;
      case "completed":
        return <Badge variant="outline" className="bg-blue-50 text-blue-700 border-blue-200">Completed</Badge>;
      case "failed":
        return <Badge variant="outline" className="bg-red-50 text-red-700 border-red-200">Failed</Badge>;
      default:
        return <Badge variant="outline">Unknown</Badge>;
    }
  };

  if (isLoading) {
    return (
      <Card>
        <CardHeader>
          <CardTitle>Active Calls</CardTitle>
          <CardDescription>Real-time status of calls being handled by your receptionist</CardDescription>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            <div className="flex items-center gap-4 p-4 border rounded-lg">
              <Skeleton className="h-12 w-12 rounded-full" />
              <div className="space-y-2 flex-1">
                <Skeleton className="h-4 w-40" />
                <Skeleton className="h-4 w-24" />
              </div>
              <Skeleton className="h-8 w-24" />
            </div>
          </div>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Active Calls</CardTitle>
        <CardDescription>Real-time status of calls being handled by your receptionist</CardDescription>
      </CardHeader>
      <CardContent>
        {error && (
          <div className="p-4 mb-4 text-sm text-red-700 bg-red-50 rounded-lg">
            {error}
          </div>
        )}
        
        <div className="space-y-4">
          {!data?.activeCalls || data.activeCalls.length === 0 ? (
            <div className="text-center py-8 text-gray-500">
              <Phone className="h-8 w-8 mx-auto mb-2 text-gray-400" />
              <p>No active calls at the moment</p>
              <p className="text-sm">When your receptionist answers a call, you'll see it here</p>
            </div>
          ) : (
            data.activeCalls.map((call) => (
              <div key={call.id} className="flex items-center gap-4 p-4 border rounded-lg">
                <div className="h-12 w-12 bg-blue-100 rounded-full flex items-center justify-center">
                  {getStatusIcon(call.status)}
                </div>
                <div className="flex-1 min-w-0">
                  <div className="flex flex-col sm:flex-row sm:items-center gap-1">
                    <h4 className="font-medium">
                      {call.callerName || "Unknown Caller"}
                    </h4>
                    <span className="text-sm text-gray-500 sm:ml-2">{call.callerNumber}</span>
                  </div>
                  <div className="flex items-center gap-2 mt-1">
                    {getStatusBadge(call.status)}
                    <span className="text-sm text-gray-500">
                      {formatDuration(elapsedTime[call.id] || 0)}
                    </span>
                  </div>
                </div>
                {call.status === "in-progress" && (
                  <div className="relative h-2 w-2">
                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
                    <span className="relative inline-flex rounded-full h-2 w-2 bg-green-500"></span>
                  </div>
                )}
              </div>
            ))
          )}
        </div>
      </CardContent>
    </Card>
  );
} 