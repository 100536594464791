import { Logo } from '@/components/ui/logo';
import { PhoneInput } from '@/components/ui/phone-input';
import { axiosInstance } from '@/lib/api';
import { useAuthStore } from '@/store/authStore';
import { Avatar } from '@radix-ui/react-avatar';
import { Link, useNavigate, useSearch } from '@tanstack/react-router';
import { Brain } from 'lucide-react';
import { useEffect, useState } from 'react';
import { HiOutlineLockClosed, HiOutlineMail, HiOutlineUser } from 'react-icons/hi';
import { toast } from 'sonner';

interface LoginFormData {
  email: string;
  password: string;
}

interface RegisterFormData {
  name: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  phone: string;
}

interface ForgotPasswordFormData {
  email: string;
}

interface InvitationData {
  teamId: number;
  teamName: string;
  email: string;
  role: string;
}

type AuthTab = 'login' | 'register' | 'forgotPassword';

export function AuthPage() {
  const [activeTab, setActiveTab] = useState<AuthTab>('login');
  const [loginData, setLoginData] = useState<LoginFormData>({
    email: '',
    password: '',
  });
  const [registerData, setRegisterData] = useState<RegisterFormData>({
    name: '',
    email: '',
    password: '',
    passwordConfirmation: '',
    phone: '',
  });
  const [forgotPasswordData, setForgotPasswordData] = useState<ForgotPasswordFormData>({
    email: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [forgotPasswordSuccess, setForgotPasswordSuccess] = useState(false);
  const [invitation, setInvitation] = useState<InvitationData | null>(null);
  const [isLoadingInvitation, setIsLoadingInvitation] = useState(false);
  const navigate = useNavigate();
  const search = useSearch({ from: '/auth' });

  // Navigate to a specific tab
  const navigateToTab = (tab: AuthTab) => {
    setActiveTab(tab);
    // Use window.history to update the URL without triggering a navigation
    const url = new URL(window.location.href);
    url.searchParams.set('tab', tab);
    window.history.pushState({}, '', url);
  };

  // Fetch invitation details if token is provided
  useEffect(() => {
    const invitationToken = search.invitation;
    if (invitationToken) {
      fetchInvitationDetails(invitationToken);
    }
  }, [search.invitation]);

  const fetchInvitationDetails = async (token: string) => {
    setIsLoadingInvitation(true);
    try {
      const response = await axiosInstance.get(`/teams/invitation/${token}`);
      const invitationData = response.data.invitation;
      
      setInvitation(invitationData);
      
      // Pre-fill email field in both login and register forms
      if (invitationData.email) {
        setLoginData(prev => ({ ...prev, email: invitationData.email }));
        setRegisterData(prev => ({ ...prev, email: invitationData.email }));
      }
      
      // Show a toast notification about the invitation
      toast.info(`You've been invited to join ${invitationData.teamName}`, {
        description: `You've been invited as a ${invitationData.role}. Please sign in or create an account to accept.`,
        duration: 6000,
      });
      
    } catch (error) {
      console.error('Error fetching invitation details:', error);
      toast.error('Invalid or expired invitation link', {
        description: 'The invitation link you clicked is invalid or has expired.'
      });
    } finally {
      setIsLoadingInvitation(false);
    }
  };

  const handleAuthSuccess = (token: string, user: any) => {
    console.log('[AuthPage] Auth success, setting token and user');
    useAuthStore.getState().setAuth(token, user);
    
    localStorage.setItem('auth_token', token);
    
    // If there's an invitation, accept it automatically
    if (invitation?.teamId) {
      acceptInvitation(invitation.teamId);
    } else {
      // Otherwise, navigate to the dashboard or redirect URL
      navigate({ 
        to: search.redirect || '/dashboard',
        replace: true,
      });
      
      toast.success('Authentication successful');
    }
  };

  const acceptInvitation = async (teamId: number) => {
    try {
      // Find the invitation ID from the user's invitations
      const invitationsResponse = await axiosInstance.get('/teams/invitations');
      const invitations = invitationsResponse.data.invitations;
      
      const matchingInvitation = invitations.find((inv: any) => inv.teamId === teamId);
      
      if (matchingInvitation) {
        // Accept the invitation
        await axiosInstance.post(`/teams/invitations/${matchingInvitation.id}/accept`);
        
        toast.success(`You've joined ${invitation?.teamName}!`, {
          description: 'You are now a member of this team.'
        });
      }
      
      // Navigate to the teams page
      navigate({ 
        to: '/settings',
        search: { tab: 'teams', section: undefined },
        replace: true,
      });
    } catch (error) {
      console.error('Error accepting invitation:', error);
      
      // Still navigate to dashboard even if accepting invitation fails
      navigate({ 
        to: '/dashboard',
        replace: true,
      });
      
      toast.error('Failed to join team automatically', {
        description: 'Please check your invitations in the Teams section.'
      });
    }
  };

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    
    try {
      console.log('[AuthPage] Attempting login for:', loginData.email);
      
      const response = await axiosInstance.post('/auth/login', loginData);
      const data = response.data;
      
      console.log('[AuthPage] Login successful, token received');
      
      if (!data.token) {
        throw new Error('No token received from server');
      }
      
      handleAuthSuccess(data.token, data.user);
    } catch (err: any) {
      console.error('[AuthPage] Login error:', err);
      
      if (err.response) {
        setError(err.response.data.message || 'Authentication failed');
      } else if (err.request) {
        setError('No response from server. Please try again later.');
      } else {
        setError(err.message || 'An error occurred during login');
      }
      
      toast.error('Login failed', {
        description: err.response?.data?.message || 'Authentication failed'
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleRegister = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    
    // Validate name
    if (!registerData.name.trim()) {
      setError('Name is required');
      setIsLoading(false);
      return;
    }
    
    // Validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(registerData.email)) {
      setError('Please enter a valid email address');
      setIsLoading(false);
      return;
    }
    
    if (registerData.password !== registerData.passwordConfirmation) {
      setError('Passwords do not match');
      setIsLoading(false);
      return;
    }
    
    try {
      console.log('[AuthPage] Attempting registration for:', registerData.email);
      
      const response = await axiosInstance.post('/auth/register', {
        name: registerData.name,
        email: registerData.email,
        password: registerData.password,
        phone: registerData.phone || undefined,
      });
      
      const data = response.data;
      
      console.log('[AuthPage] Registration successful');
      
      if (!data.token) {
        throw new Error('No token received from server');
      }
      
      handleAuthSuccess(data.token, data.user);
      toast.success('Account created successfully');
    } catch (err: any) {
      console.error('[AuthPage] Registration error:', err);
      
      if (err.response) {
        setError(err.response.data.message || 'Registration failed');
      } else if (err.request) {
        setError('No response from server. Please try again later.');
      } else {
        setError(err.message || 'An error occurred during registration');
      }
      
      toast.error('Registration failed', {
        description: err.response?.data?.message || 'Could not create account'
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleForgotPassword = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    setForgotPasswordSuccess(false);
    
    // Validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(forgotPasswordData.email)) {
      setError('Please enter a valid email address');
      setIsLoading(false);
      return;
    }
    
    try {
      console.log('[AuthPage] Attempting password reset for:', forgotPasswordData.email);
      
      const response = await axiosInstance.post('/auth/forgot-password', {
        email: forgotPasswordData.email,
      });
      
      console.log('[AuthPage] Password reset email sent');
      
      setForgotPasswordSuccess(true);
      toast.success('Password reset link sent', {
        description: 'Please check your email for instructions to reset your password.'
      });
    } catch (err: any) {
      console.error('[AuthPage] Forgot password error:', err);
      
      if (err.response) {
        setError(err.response.data.message || 'Failed to send password reset email');
      } else if (err.request) {
        setError('No response from server. Please try again later.');
      } else {
        setError(err.message || 'An error occurred while processing your request');
      }
      
      toast.error('Failed to send password reset email', {
        description: err.response?.data?.message || 'Please try again later'
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen w-full bg-gray-50 lg:flex">
      {/* Auth Form Section */}
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:flex-none lg:w-1/2">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <Link to="/" className="hover:opacity-80">
            <div className="flex items-center gap-2 mb-8">
              <Avatar className="h-8 w-8 rounded-lg">
                <Brain className="h-8 w-8 text-sky-200 bg-black p-1 rounded-lg" />
              </Avatar>
              <Logo className="w-28" />
            </div>
          </Link>
          {/* Invitation Banner */}
          {invitation && (
            <div className="mb-6 rounded-lg bg-blue-50 p-4 border border-blue-200">
              <h3 className="text-sm font-medium text-blue-800">Team Invitation</h3>
              <p className="mt-1 text-sm text-blue-700">
                You've been invited to join <strong>{invitation.teamName}</strong> as a {invitation.role}.
              </p>
              <p className="mt-1 text-xs text-blue-600">
                Sign in or create an account to accept this invitation.
              </p>
            </div>
          )}

          {/* Auth Tabs */}
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex gap-6" aria-label="Tabs">
              <button
                onClick={() => {
                  navigateToTab('login');
                  setError(null);
                }}
                className={`shrink-0 border-b-2 px-1 pb-4 text-sm font-medium transition-colors ${
                  activeTab === 'login'
                    ? 'border-blue-600 text-blue-600'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                }`}
              >
                Sign In
              </button>
              <button
                onClick={() => {
                  navigateToTab('register');
                  setError(null);
                }}
                className={`shrink-0 border-b-2 px-1 pb-4 text-sm font-medium transition-colors ${
                  activeTab === 'register'
                    ? 'border-blue-600 text-blue-600'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                }`}
              >
                Create Account
              </button>
            </nav>
          </div>

          <div className="mt-10">
            {isLoadingInvitation ? (
              <div className="flex justify-center items-center py-8">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
                <span className="ml-2 text-sm text-gray-600">Loading invitation details...</span>
              </div>
            ) : activeTab === 'login' ? (
              <form onSubmit={handleLogin} className="space-y-6">
                {error && (
                  <div className="rounded-md bg-red-50 p-4">
                    <p className="text-sm text-red-700">{error}</p>
                  </div>
                )}
                
                <div>
                  <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                    Email address
                  </label>
                  <div className="relative mt-2">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <HiOutlineMail className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      value={loginData.email}
                      onChange={(e) => setLoginData({ ...loginData, email: e.target.value })}
                      className="block w-full rounded-lg border-0 py-2.5 pl-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      placeholder="you@example.com"
                    />
                  </div>
                </div>

                <div>
                  <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                    Password
                  </label>
                  <div className="relative mt-2">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <HiOutlineLockClosed className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      value={loginData.password}
                      onChange={(e) => setLoginData({ ...loginData, password: e.target.value })}
                      className="block w-full rounded-lg border-0 py-2.5 pl-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <input
                      id="remember-me"
                      name="remember-me"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                    />
                    <label htmlFor="remember-me" className="ml-3 block text-sm leading-6 text-gray-700">
                      Remember me
                    </label>
                  </div>

                  <div className="text-sm leading-6">
                    <button 
                      type="button"
                      onClick={() => {
                        navigateToTab('forgotPassword');
                        setError(null);
                        setForgotPasswordData({ email: loginData.email });
                      }}
                      className="font-semibold text-blue-600 hover:text-blue-500"
                    >
                      Forgot password?
                    </button>
                  </div>
                </div>

                <button
                  type="submit"
                  disabled={isLoading}
                  className="flex w-full justify-center rounded-lg bg-blue-600 px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {isLoading ? 'Signing in...' : 'Sign in'}
                </button>
              </form>
            ) : activeTab === 'register' ? (
              <form onSubmit={handleRegister} className="space-y-6">
                {error && (
                  <div className="rounded-md bg-red-50 p-4">
                    <p className="text-sm text-red-700">{error}</p>
                  </div>
                )}
                
                <div>
                  <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                    Full name
                  </label>
                  <div className="relative mt-2">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <HiOutlineUser className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                      id="name"
                      name="name"
                      type="text"
                      autoComplete="name"
                      required
                      value={registerData.name}
                      onChange={(e) => setRegisterData({ ...registerData, name: e.target.value })}
                      className="block w-full rounded-lg border-0 py-2.5 pl-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      placeholder="John Doe"
                    />
                  </div>
                </div>

                <div>
                  <label htmlFor="register-email" className="block text-sm font-medium leading-6 text-gray-900">
                    Email address
                  </label>
                  <div className="relative mt-2">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <HiOutlineMail className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                      id="register-email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      value={registerData.email}
                      onChange={(e) => setRegisterData({ ...registerData, email: e.target.value })}
                      className="block w-full rounded-lg border-0 py-2.5 pl-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      placeholder="you@example.com"
                    />
                  </div>
                </div>

                <PhoneInput
                  id="phone"
                  label="Phone Number (optional)"
                  value={registerData.phone}
                  onChange={(value) => setRegisterData({ ...registerData, phone: value })}
                  placeholder="+1 (555) 000-0000"
                />

                <div>
                  <label htmlFor="register-password" className="block text-sm font-medium leading-6 text-gray-900">
                    Password
                  </label>
                  <div className="relative mt-2">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <HiOutlineLockClosed className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                      id="register-password"
                      name="password"
                      type="password"
                      autoComplete="new-password"
                      required
                      value={registerData.password}
                      onChange={(e) => setRegisterData({ ...registerData, password: e.target.value })}
                      className="block w-full rounded-lg border-0 py-2.5 pl-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div>
                  <label htmlFor="passwordConfirmation" className="block text-sm font-medium leading-6 text-gray-900">
                    Confirm password
                  </label>
                  <div className="relative mt-2">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <HiOutlineLockClosed className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                      id="passwordConfirmation"
                      name="passwordConfirmation"
                      type="password"
                      autoComplete="new-password"
                      required
                      value={registerData.passwordConfirmation}
                      onChange={(e) => setRegisterData({ ...registerData, passwordConfirmation: e.target.value })}
                      className="block w-full rounded-lg border-0 py-2.5 pl-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <button
                  type="submit"
                  disabled={isLoading}
                  className="flex w-full justify-center rounded-lg bg-blue-600 px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {isLoading ? 'Creating account...' : 'Create account'}
                </button>
              </form>
            ) : (
              <div>
                <div className="mb-6">
                  <h2 className="text-lg font-semibold text-gray-900">Forgot your password?</h2>
                  <p className="mt-1 text-sm text-gray-600">
                    No problem. Just let us know your email address and we'll email you a password reset link.
                  </p>
                </div>
                
                {forgotPasswordSuccess ? (
                  <div className="rounded-md bg-green-50 p-4 mb-6">
                    <p className="text-sm text-green-700">
                      We've emailed your password reset link. Please check your inbox and follow the instructions to reset your password.
                    </p>
                  </div>
                ) : null}
                
                <form onSubmit={handleForgotPassword} className="space-y-6">
                  {error && (
                    <div className="rounded-md bg-red-50 p-4">
                      <p className="text-sm text-red-700">{error}</p>
                    </div>
                  )}
                  
                  <div>
                    <label htmlFor="forgot-password-email" className="block text-sm font-medium leading-6 text-gray-900">
                      Email address
                    </label>
                    <div className="relative mt-2">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <HiOutlineMail className="h-5 w-5 text-gray-400" />
                      </div>
                      <input
                        id="forgot-password-email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        value={forgotPasswordData.email}
                        onChange={(e) => setForgotPasswordData({ email: e.target.value })}
                        className="block w-full rounded-lg border-0 py-2.5 pl-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        placeholder="you@example.com"
                      />
                    </div>
                  </div>
                  
                  <div className="flex items-center justify-between">
                    <button
                      type="button"
                      onClick={() => {
                        navigateToTab('login');
                        setError(null);
                      }}
                      className="text-sm font-medium text-blue-600 hover:text-blue-500"
                    >
                      Back to login
                    </button>
                    
                    <button
                      type="submit"
                      disabled={isLoading}
                      className="flex justify-center rounded-lg bg-blue-600 px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      {isLoading ? 'Sending...' : 'Email Password Reset Link'}
                    </button>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Right Side Content - Hidden on Mobile */}
      <div className="relative hidden w-0 flex-1 lg:block lg:w-1/2">
        <div className="absolute inset-0">
          <img
            className="h-full w-full object-cover"
            src="https://images.unsplash.com/photo-1551434678-e076c223a692?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8MHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80"
            alt="Team working on analytics"
          />
          <div className="absolute inset-0 bg-blue-600 mix-blend-multiply" />
        </div>
        <div className="absolute inset-0 flex items-center justify-center px-12">
          <div className="max-w-xl text-center">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
              {invitation 
                ? `Join ${invitation.teamName} on LeadGPT`
                : activeTab === 'login' 
                  ? 'Transform Your Lead Management'
                  : activeTab === 'register'
                    ? 'Start Growing Your Business Today'
                    : 'Recover Your Account Access'
              }
            </h2>
            <p className="mt-4 text-lg leading-8 text-gray-100">
              {invitation
                ? `You've been invited to join as a ${invitation.role}. Sign in or create an account to get started with team collaboration.`
                : activeTab === 'login'
                  ? 'LeadGPT helps you streamline your lead generation, tracking, and conversion process. Get real-time insights, automated follow-ups, and powerful analytics to grow your business.'
                  : activeTab === 'register'
                    ? 'Join thousands of businesses using LeadGPT to manage their leads more effectively. Our platform helps you convert more prospects into customers with powerful automation and insights.'
                    : 'We understand that access issues can happen. Our secure password reset process will help you regain access to your account quickly and safely.'
              }
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}