import { Button } from "@/components/ui/button";
import { useNavigate } from "@tanstack/react-router";
import { HelpCircle } from "lucide-react";

interface HelpCenterButtonProps {
  className?: string;
  variant?: "default" | "sidebar";
}

export function HelpCenterButton({ className, variant = "default" }: HelpCenterButtonProps) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate({ to: "/help-center" as any });
  };

  if (variant === "sidebar") {
    return (
      <button
        onClick={handleClick}
        className="group flex w-full items-center justify-start gap-3 rounded-lg border border-dashed border-gray-300 px-3 py-2.5 text-sm font-medium text-gray-700 transition-colors hover:bg-gray-50 hover:text-gray-900"
      >
        <HelpCircle className="h-5 w-5 text-gray-500 group-hover:text-gray-900" />
        Help Center
      </button>
    );
  }

  return (
    <Button
      onClick={handleClick}
      className={`fixed bottom-20 right-6 z-50 flex h-12 w-12 items-center justify-center rounded-full p-0 shadow-lg ${className}`}
      size="icon"
    >
      <HelpCircle className="h-5 w-5" />
      <span className="sr-only">Help Center</span>
    </Button>
  );
} 