import { Button } from "@/components/ui/button";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { api } from "@/lib/api";
import { Status } from "@/types";
import { useRef, useState } from "react";
import { toast } from "sonner";

interface CreateStatusDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onStatusCreated: (status: Status) => void;
}

export function CreateStatusDialog({
  open,
  onOpenChange,
  onStatusCreated,
}: CreateStatusDialogProps) {
  const [name, setName] = useState("");
  const [color, setColor] = useState("#6366F1"); // Default to indigo
  const [description, setDescription] = useState("");
  const [isCreating, setIsCreating] = useState(false);
  const colorInputRef = useRef<HTMLInputElement>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!name.trim()) {
      toast.error("Please enter a status name");
      return;
    }

    if (!description.trim()) {
      toast.error("Please enter a description to help the AI understand when to use this status");
      return;
    }

    setIsCreating(true);
    try {
      const response = await api.statuses.create({
        name: name.trim().toLowerCase(),
        color,
        description: description.trim()
      });

      if (response.success) {
        toast.success("Status created successfully");
        onStatusCreated(response.status);
        onOpenChange(false);
        setName("");
        setColor("#6366F1");
        setDescription("");
      }
    } catch (error) {
      toast.error("Failed to create status");
    } finally {
      setIsCreating(false);
    }
  };

  const handleColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setColor(e.target.value);
    // Manually trigger a blur after color selection
    e.target.blur();
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Create Custom Status</DialogTitle>
          <DialogDescription>
            Create a new status for your leads. The description helps our AI automatically update lead statuses after calls.
          </DialogDescription>
        </DialogHeader>
        <form onSubmit={handleSubmit} className="grid gap-4 py-4">
          <div className="grid gap-2">
            <Label htmlFor="name">Status Name</Label>
            <Input
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter status name..."
              className="col-span-3"
            />
          </div>
          <div className="grid gap-2">
            <Label htmlFor="color">Status Color</Label>
            <div className="flex gap-4">
              <Input
                ref={colorInputRef}
                id="color"
                type="color"
                value={color}
                onChange={handleColorChange}
                onBlur={() => colorInputRef.current?.blur()}
                className="h-10 w-20"
              />
              <div 
                className="flex-1 rounded-md border"
                style={{ backgroundColor: color }}
              />
            </div>
          </div>
          <div className="grid gap-2">
            <Label htmlFor="description" className="flex items-center justify-between">
              <span>Description</span>
              <span className="text-xs text-muted-foreground">Helps AI understand when to use this status</span>
            </Label>
            <Textarea
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Describe when this status should be used (e.g., 'Use this status when the lead expresses strong interest and requests a follow-up call')"
              className="min-h-[100px]"
            />
          </div>
          <DialogFooter>
            <Button
              type="submit"
              disabled={isCreating || !name.trim() || !description.trim()}
            >
              Create Status
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
} 