import { Button } from '@/components/ui/button';
import { Card, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Drawer, DrawerContent, DrawerHeader, DrawerTitle, DrawerTrigger } from '@/components/ui/drawer';
import { Switch } from '@/components/ui/switch';
import { ActionItem as ActionItemType } from '@/services/dashboard';
import { FetchNextPageOptions, InfiniteQueryObserverResult } from '@tanstack/react-query';
import { Loader2 } from 'lucide-react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { ActionItem } from './ActionItem';

interface ActionCenterProps {
  items: ActionItemType[];
  hasCompletedItems: boolean;
  onItemClick: (leadId: number) => void;
  onViewAll: () => void;
  onToggleShowCompleted: (show: boolean) => void;
  showCompleted: boolean;
  fetchNextPage: (options?: FetchNextPageOptions) => Promise<InfiniteQueryObserverResult>;
  hasNextPage: boolean;
  isFetchingNextPage: boolean;
  isLoading: boolean;
}

export function ActionCenter({ 
  items, 
  hasCompletedItems,
  onItemClick, 
  onViewAll,
  onToggleShowCompleted,
  showCompleted,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  isLoading
}: ActionCenterProps) {
  const [isMobile, setIsMobile] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const observerTarget = useRef<HTMLDivElement>(null);
  const drawerObserverTarget = useRef<HTMLDivElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const drawerScrollContainerRef = useRef<HTMLDivElement>(null);
  
  // Check if we're on mobile
  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth < 768); // md breakpoint
    };
    
    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);
    
    return () => {
      window.removeEventListener('resize', checkIfMobile);
    };
  }, []);
  
  // Set up intersection observer for infinite scroll - only for desktop or mobile drawer
  useEffect(() => {
    if (!hasNextPage || isFetchingNextPage) return;
    
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          console.log("Intersection observer triggered fetchNextPage");
          fetchNextPage();
        }
      },
      { threshold: 0.1 } // Lower threshold to trigger earlier
    );
    
    // For desktop view
    if (!isMobile && observerTarget.current) {
      observer.observe(observerTarget.current);
    }
    
    // For mobile drawer view
    if (isMobile && isDrawerOpen && drawerObserverTarget.current) {
      observer.observe(drawerObserverTarget.current);
    }
    
    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
      if (drawerObserverTarget.current) {
        observer.unobserve(drawerObserverTarget.current);
      }
    };
  }, [fetchNextPage, hasNextPage, isFetchingNextPage, isMobile, isDrawerOpen]);
  
  // Set up scroll event listener for desktop view
  useEffect(() => {
    const scrollElement = scrollContainerRef.current;
    if (!scrollElement || !hasNextPage || isFetchingNextPage || isMobile) return;

    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = scrollElement;
      // Trigger earlier - when within 300px of the bottom
      if (scrollTop + clientHeight >= scrollHeight - 300 && !isFetchingNextPage) {
        console.log("Desktop scroll triggered fetchNextPage");
        fetchNextPage();
      }
    };

    scrollElement.addEventListener("scroll", handleScroll);
    return () => scrollElement.removeEventListener("scroll", handleScroll);
  }, [hasNextPage, isFetchingNextPage, fetchNextPage, isMobile]);
  
  // Set up scroll event listener for mobile drawer
  useEffect(() => {
    if (!isMobile || !isDrawerOpen || !hasNextPage || isFetchingNextPage) return;
    
    const scrollElement = drawerScrollContainerRef.current;
    if (!scrollElement) return;

    const handleDrawerScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = scrollElement;
      // Trigger earlier - when within 300px of the bottom
      if (scrollTop + clientHeight >= scrollHeight - 300) {
        console.log("Mobile drawer scroll triggered fetchNextPage");
        fetchNextPage();
      }
    };

    scrollElement.addEventListener("scroll", handleDrawerScroll);
    
    // Initial check in case the content doesn't fill the drawer
    setTimeout(() => {
      if (scrollElement.scrollHeight <= scrollElement.clientHeight && hasNextPage && !isFetchingNextPage) {
        console.log("Initial drawer check triggered fetchNextPage");
        fetchNextPage();
      }
    }, 100);
    
    return () => scrollElement.removeEventListener("scroll", handleDrawerScroll);
  }, [hasNextPage, isFetchingNextPage, fetchNextPage, isMobile, isDrawerOpen]);
  
  // Effect to check if we need to load more items when the drawer opens
  useEffect(() => {
    if (isMobile && isDrawerOpen && hasNextPage && !isFetchingNextPage) {
      const scrollElement = drawerScrollContainerRef.current;
      if (scrollElement && scrollElement.scrollHeight <= scrollElement.clientHeight) {
        console.log("Drawer open check triggered fetchNextPage");
        fetchNextPage();
      }
    }
  }, [isDrawerOpen, isMobile, hasNextPage, isFetchingNextPage, fetchNextPage]);
  
  // Filter out messages that have been responded to
  // We only want to show messages that require attention (unread/unanswered)
  // Other item types (calls, tasks) are shown as normal
  const filteredItems = useMemo(() => {
    return items.filter(item => {
      // Always show non-message items
      if (item.type !== 'message') {
        return true;
      }
      
      // For messages, only show those that haven't been responded to
      // Messages that need attention will have "New message:" in the content
      return item.content.startsWith('New message:');
    });
  }, [items]);
  
  // Render the action items list
  const renderActionItems = (items: ActionItemType[], isDrawerView = false) => {
    if (isLoading && items.length === 0) {
      return (
        <div className="flex justify-center py-8">
          <Loader2 className="h-8 w-8 animate-spin text-gray-400" />
        </div>
      );
    }
    
    if (items.length === 0) {
      return (
        <div className="text-center py-8 text-gray-500">
          No action items at the moment
        </div>
      );
    }
    
    // For mobile main view, limit to 5 items without infinite scroll
    const displayItems = isMobile && !isDrawerView ? items.slice(0, 5) : items;
    
    return (
      <div className="space-y-4">
        {displayItems.map((item) => (
          <ActionItem 
            key={item.id} 
            item={item} 
            onClick={onItemClick} 
          />
        ))}
        
        {/* Always show observer target in drawer view */}
        {isDrawerView && (
          <div 
            ref={drawerObserverTarget} 
            className="h-24 flex items-center justify-center my-4 bg-gray-50 rounded-md"
          >
            {isFetchingNextPage ? (
              <Loader2 className="h-6 w-6 animate-spin text-gray-400" />
            ) : hasNextPage ? (
              <p className="text-sm text-gray-500">Scroll for more</p>
            ) : (
              <p className="text-sm text-gray-500">No more items</p>
            )}
          </div>
        )}
        
        {/* Only show observer target in desktop view */}
        {!isMobile && !isDrawerView && hasNextPage && (
          <div 
            ref={observerTarget} 
            className="h-20 flex items-center justify-center"
          >
            {isFetchingNextPage && (
              <Loader2 className="h-6 w-6 animate-spin text-gray-400" />
            )}
          </div>
        )}
      </div>
    );
  };
  
  // Handle drawer open state change
  const handleDrawerOpenChange = (open: boolean) => {
    setIsDrawerOpen(open);
    
    // If opening the drawer and there are more items to load, fetch them
    if (open && hasNextPage && !isFetchingNextPage) {
      console.log("Drawer opening triggered fetchNextPage");
      fetchNextPage();
    }
  };
  
  return (
    <Card className="flex flex-col h-full rounded-md shadow-sm overflow-hidden">
      <CardHeader className="pb-4 flex-shrink-0 pt-4 px-4">
        <div className="flex justify-between items-center">
          <div>
            <CardTitle className="text-md font-semibold text-gray-700">Action Center</CardTitle>
            <CardDescription className="text-xs text-gray-400">Items requiring your attention</CardDescription>
          </div>
          {hasCompletedItems && (
            <div className="flex items-center space-x-2">
              <label htmlFor="show-completed" className="text-xs text-gray-500">
                Show completed
              </label>
              <Switch 
                id="show-completed" 
                checked={showCompleted} 
                onCheckedChange={onToggleShowCompleted}
              />
            </div>
          )}
        </div>
      </CardHeader>
      
      <div 
        ref={scrollContainerRef}
        className="flex-grow overflow-auto p-4"
        style={{ 
          height: isMobile ? 'auto' : 0, // Use auto height for mobile, 0 for desktop (flex-grow)
          minHeight: isMobile ? 'auto' : 0
        }}
      >
        {renderActionItems(filteredItems)}
      </div>
      
      {/* Mobile View: Footer with View All button that opens drawer */}
      {isMobile && (
        <CardFooter className="bg-white border-t border-gray-100 flex justify-center py-3 mt-auto flex-shrink-0">
          <Drawer open={isDrawerOpen} onOpenChange={handleDrawerOpenChange}>
            <DrawerTrigger asChild>
              <Button variant="ghost" size="sm" className="text-xs">
                View All Action Items
              </Button>
            </DrawerTrigger>
            <DrawerContent>
              <DrawerHeader>
                <DrawerTitle>Action Items</DrawerTitle>
              </DrawerHeader>
              <div 
                ref={drawerScrollContainerRef}
                className="max-h-[80vh] overflow-auto p-4"
              >
                {hasCompletedItems && (
                  <div className="flex items-center justify-end space-x-2 mb-4">
                    <label htmlFor="drawer-show-completed" className="text-xs text-gray-500">
                      Show completed
                    </label>
                    <Switch 
                      id="drawer-show-completed" 
                      checked={showCompleted} 
                      onCheckedChange={onToggleShowCompleted}
                    />
                  </div>
                )}
                {renderActionItems(filteredItems, true)}
              </div>
            </DrawerContent>
          </Drawer>
        </CardFooter>
      )}
    </Card>
  );
} 