import { EventOutcomeDialog } from '@/components/calendar/EventOutcomeDialog';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { toast } from '@/components/ui/use-toast';
import { cn } from '@/lib/utils';
import { CalendarEvent, CalendarService } from '@/services/calendar.service';
import { Lead } from '@/types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { format, isAfter, parseISO } from 'date-fns';
import { Calendar, Clock, Edit, Loader2, MapPin, Plus, Trash2, Video } from 'lucide-react';
import { useState } from 'react';
import { formFieldClasses } from './lead-details/utils';
import ScheduleMeeting from './ScheduleMeeting';

interface LeadMeetingsSectionProps {
  lead: Lead | null;
  leadId: number;
}

export function LeadMeetingsSection({ lead, leadId }: LeadMeetingsSectionProps) {
  const queryClient = useQueryClient();
  const [showScheduler, setShowScheduler] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // Get lead meetings
  const {
    data: meetings,
    isLoading: isLoadingMeetings,
    error: meetingsError
  } = useQuery({
    queryKey: ['leadEvents', leadId],
    queryFn: () => CalendarService.getLeadEvents(leadId),
    refetchOnWindowFocus: false,
  });

  // Delete meeting
  const {
    mutate: deleteMeeting,
    isPending: isDeletingMeeting
  } = useMutation({
    mutationFn: (eventId: string) => CalendarService.deleteEvent(eventId),
    onSuccess: () => {
      // Invalidate lead events query
      queryClient.invalidateQueries({ queryKey: ['leadEvents', leadId], exact: true });
      
      // Also invalidate general calendar events query
      queryClient.invalidateQueries({ queryKey: ['calendarEvents'], exact: false });
      
      toast({
        title: "Meeting Deleted",
        description: "The meeting has been successfully removed from your calendar."
      });
    },
    onError: (error: Error) => {
      setError(error.message);
      toast({
        title: "Error",
        description: "Failed to delete meeting. Please try again.",
        variant: "destructive"
      });
    },
  });

  // Add Google Meet to an event
  const {
    mutate: addMeetLink,
    isPending: isAddingMeetLink
  } = useMutation({
    mutationFn: (eventId: string) => CalendarService.updateEventWithMeetLink(eventId),
    onSuccess: () => {
      // Invalidate lead events query
      queryClient.invalidateQueries({ queryKey: ['leadEvents', leadId], exact: true });
      
      // Also invalidate general calendar events query
      queryClient.invalidateQueries({ queryKey: ['calendarEvents'], exact: false });
      
      toast({
        title: "Google Meet Added",
        description: "A Google Meet link has been added to the meeting and the lead will receive an invitation if they have an email address."
      });
    },
    onError: (error: Error) => {
      setError(error.message);
      toast({
        title: "Error",
        description: "Failed to add Google Meet. Please make sure the lead has an email address and try again.",
        variant: "destructive"
      });
    },
  });

  const handleDeleteMeeting = (eventId: string) => {
    if (window.confirm('Are you sure you want to delete this meeting?')) {
      deleteMeeting(eventId);
    }
  };

  const handleAddMeetLink = (eventId: string) => {
    addMeetLink(eventId);
  };

  // Sort meetings by start time, upcoming first
  const sortedMeetings = meetings?.slice().sort((a, b) => 
    new Date(a.startTime).getTime() - new Date(b.startTime).getTime()
  );

  // Filter upcoming vs past meetings
  const now = new Date();
  const upcomingMeetings = sortedMeetings?.filter(meeting => 
    isAfter(parseISO(meeting.startTime), now)
  );
  const pastMeetings = sortedMeetings?.filter(meeting => 
    !isAfter(parseISO(meeting.startTime), now)
  );

  return (
    <div className={formFieldClasses.section}>
      <div className="space-y-3">
        <div className="flex items-center justify-between mb-2">
          <div className="flex items-center gap-2">
            <Calendar className="h-4 w-4 text-blue-500" />
            <h3 className="text-sm font-medium">Meetings</h3>
          </div>
          <Button
            size="sm"
            variant="outline"
            className="h-7 text-xs"
            onClick={() => setShowScheduler(true)}
            disabled={showScheduler}
          >
            <Plus className="h-3 w-3 mr-1" />
            Schedule Meeting
          </Button>
        </div>

        {/* Schedule Meeting Dialog */}
        {showScheduler && (
          <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 !mt-0">
            <ScheduleMeeting
              leadId={leadId}
              leadName={lead ? lead.name || `Lead #${leadId}` : `Lead #${leadId}`}
              onClose={() => setShowScheduler(false)}
              onScheduled={() => {
                setShowScheduler(false);
                queryClient.invalidateQueries({ queryKey: ['leadEvents', leadId] });
                toast({
                  title: "Meeting Scheduled",
                  description: "The meeting has been added to your calendar."
                });
              }}
            />
          </div>
        )}

        {error && (
          <Alert variant="destructive" className="mb-3">
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}

        {/* Meetings List */}
        {isLoadingMeetings ? (
          <div className="space-y-2">
            {[1, 2].map((i) => (
              <div
                key={i}
                className="p-3 rounded-md border bg-muted/30 animate-pulse"
              >
                <div className="h-5 bg-muted rounded w-1/3 mb-3"></div>
                <div className="space-y-2">
                  <div className="flex gap-2">
                    <div className="h-4 w-4 rounded-full bg-muted"></div>
                    <div className="h-4 bg-muted rounded w-1/4"></div>
                  </div>
                  <div className="flex gap-2">
                    <div className="h-4 w-4 rounded-full bg-muted"></div>
                    <div className="h-4 bg-muted rounded w-1/3"></div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : meetingsError ? (
          <Alert variant="destructive">
            <AlertDescription>Failed to load meetings. Please try again later.</AlertDescription>
          </Alert>
        ) : !sortedMeetings?.length ? (
           <div className="flex flex-col items-center justify-center py-4 text-center">
              <Calendar className="h-8 w-8 text-gray-300 dark:text-gray-600" />
              <p className="mt-2 text-xs text-gray-500 dark:text-gray-400 font-medium">No meetings scheduled</p>
              <p className="text-xs text-gray-400">
                Schedule a meeting with this lead to get started.
              </p>
            </div>
        ) : (
          <div className="space-y-4">
            {/* Upcoming Meetings */}
            {upcomingMeetings?.length ? (
              <div className="space-y-2">
                <h4 className="text-xs font-medium text-muted-foreground">Upcoming Meetings</h4>
                <div className="space-y-2">
                  {upcomingMeetings.map((meeting) => (
                    <MeetingCard
                      key={meeting.id}
                      meeting={meeting}
                      onDelete={() => handleDeleteMeeting(meeting.id)}
                      onAddMeet={!meeting.meetingLink ? () => handleAddMeetLink(meeting.id) : undefined}
                      isDeleting={isDeletingMeeting}
                      isAddingMeet={isAddingMeetLink}
                      variant="upcoming"
                      leadName={lead ? lead.name || `Lead #${leadId}` : `Lead #${leadId}`}
                    />
                  ))}
                </div>
              </div>
            ) : null}

            {/* Past Meetings */}
            {pastMeetings?.length ? (
              <div className="space-y-2">
                <h4 className="text-xs font-medium text-muted-foreground">Past Meetings</h4>
                <div className="space-y-2">
                  {pastMeetings.map((meeting) => (
                    <MeetingCard
                      key={meeting.id}
                      meeting={meeting}
                      onDelete={() => handleDeleteMeeting(meeting.id)}
                      isDeleting={isDeletingMeeting}
                      variant="past"
                      leadName={lead ? lead.name || `Lead #${leadId}` : `Lead #${leadId}`}
                    />
                  ))}
                </div>
              </div>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
}

interface MeetingCardProps {
  meeting: CalendarEvent;
  onDelete: () => void;
  onAddMeet?: () => void;
  isDeleting: boolean;
  isAddingMeet?: boolean;
  variant: 'upcoming' | 'past';
  leadName: string;
}

function MeetingCard({ meeting, onDelete, onAddMeet, isDeleting, isAddingMeet, variant, leadName }: MeetingCardProps) {
  const startTime = parseISO(meeting.startTime);
  const endTime = parseISO(meeting.endTime);
  const [showOutcomeDialog, setShowOutcomeDialog] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const queryClient = useQueryClient();

  const handleOutcomeUpdated = () => {
    // Invalidate the leadEvents query to refresh the meetings list
    if (meeting.leadId) {
      queryClient.invalidateQueries({ queryKey: ['leadEvents', meeting.leadId] });
    }
    setShowOutcomeDialog(false);
  };
  
  const handleCardClick = (e: React.MouseEvent) => {
    // Don't open edit dialog if clicking on a button
    if ((e.target as HTMLElement).closest('button')) {
      return;
    }
    
    setShowEditDialog(true);
  };

  const handleEditComplete = () => {
    setShowEditDialog(false);
  };
  
  return (
    <>
      <div 
        className={cn(
          "border rounded-md p-3 text-sm cursor-pointer",
          variant === 'past' ? "bg-muted/20 text-muted-foreground hover:bg-blue-50/50" : "bg-card hover:bg-blue-50",
          "hover:border-blue-200 transition-colors relative"
        )}
        onClick={handleCardClick}
      >
        <div className="flex items-start justify-between">
          <div className="space-y-2">
            <p className="font-medium">{variant === 'upcoming' && (
              <Badge variant="outline" className="mt-1 bg-green-50 text-green-700 border-green-200 text-[10px] font-normal px-1.5 py-0">
                Upcoming
              </Badge>
            )} {meeting.title}</p>
            <div className="flex flex-wrap gap-x-3 gap-y-1 text-xs text-muted-foreground">
              <div className="flex items-center gap-1">
                <Calendar className="h-3 w-3" />
                {format(startTime, 'MMMM d, yyyy')}
              </div>
              <div className="flex items-center gap-1">
                <Clock className="h-3 w-3" />
                {format(startTime, 'h:mm a')} - {format(endTime, 'h:mm a')}
              </div>
              {meeting.location && (
                <div className="flex items-center gap-1">
                  <MapPin className="h-3 w-3" />
                  {meeting.location}
                </div>
              )}
            </div>

            {meeting.description && (
              <div className="mt-1 text-xs text-muted-foreground">
                {meeting.description}
              </div>
            )}
            
            {meeting.outcome && (
              <div className="mt-2">
                <div className="text-xs font-medium text-muted-foreground flex items-center gap-1">
                  <Edit className="h-3 w-3" />
                  Meeting Outcome:
                </div>
                <div className="mt-1 text-xs text-muted-foreground border-l-2 border-blue-300 pl-2 py-1">
                  {meeting.outcome}
                </div>
              </div>
            )}
            
            {meeting.meetingLink && (
              <div className="mt-2">
                <Button
                  variant="outline"
                  size="sm"
                  className="h-6 text-xs"
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(meeting.meetingLink!, '_blank');
                  }}
                >
                  <Video className="h-3 w-3 mr-1" />
                  Join Google Meet
                </Button>
              </div>
            )}
          </div>

          <div className="flex gap-1">
            {/* Show Add Meet button for upcoming meetings without a meeting link */}
            {variant === 'upcoming' && !meeting.meetingLink && onAddMeet && (
              <Button
                variant="ghost"
                size="icon"
                className="h-6 w-6"
                onClick={(e) => {
                  e.stopPropagation();
                  onAddMeet();
                }}
                disabled={isAddingMeet}
                title="Add Google Meet"
              >
                {isAddingMeet ? (
                  <Loader2 className="h-3 w-3 animate-spin" />
                ) : (
                  <Video className="h-3 w-3" />
                )}
              </Button>
            )}
            
            {/* Add Outcome button for past meetings */}
            {variant === 'past' && (
              <Button
                variant="ghost"
                size="icon"
                className="h-6 w-6"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowOutcomeDialog(true);
                }}
                title={meeting.outcome ? "Edit Meeting Outcome" : "Add Meeting Outcome"}
              >
                <Edit className="h-3 w-3" />
              </Button>
            )}
            
            <Button
              variant="ghost"
              size="icon"
              className="h-6 w-6 text-muted-foreground hover:text-destructive"
              onClick={(e) => {
                e.stopPropagation();
                onDelete();
              }}
              disabled={isDeleting}
              title="Delete meeting"
            >
              {isDeleting ? (
                <Loader2 className="h-3 w-3 animate-spin" />
              ) : (
                <Trash2 className="h-3 w-3" />
              )}
            </Button>
          </div>
        </div>
        
        {/* Clickable indicator */}
        <div className="absolute bottom-1 right-2 text-[10px] text-muted-foreground/70 flex items-center">
          <Edit className="h-2.5 w-2.5 mr-0.5" />
          Click to edit
        </div>
      </div>
      
      {/* Outcome Dialog */}
      <EventOutcomeDialog
        event={meeting}
        isOpen={showOutcomeDialog}
        onClose={handleOutcomeUpdated}
      />
      
      {/* Edit Meeting Dialog */}
      {showEditDialog && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 !mt-0">
          <ScheduleMeeting
            leadId={meeting.leadId || 0}
            leadName={leadName}
            onClose={() => setShowEditDialog(false)}
            onScheduled={handleEditComplete}
            existingEvent={meeting}
          />
        </div>
      )}
    </>
  );
} 