/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as TermsImport } from './routes/terms'
import { Route as SupportImport } from './routes/support'
import { Route as SubscriptionImport } from './routes/subscription'
import { Route as SettingsImport } from './routes/settings'
import { Route as SecurityImport } from './routes/security'
import { Route as ResetPasswordImport } from './routes/reset-password'
import { Route as PrivacyImport } from './routes/privacy'
import { Route as PressImport } from './routes/press'
import { Route as MetricsImport } from './routes/metrics'
import { Route as LeadsImport } from './routes/leads'
import { Route as LandingImport } from './routes/landing'
import { Route as IntegrationsImport } from './routes/integrations'
import { Route as HipaaImport } from './routes/hipaa'
import { Route as HelpCenterImport } from './routes/help-center'
import { Route as FeedbackImport } from './routes/feedback'
import { Route as DocumentationImport } from './routes/documentation'
import { Route as DashboardImport } from './routes/dashboard'
import { Route as ContactImport } from './routes/contact'
import { Route as CareersImport } from './routes/careers'
import { Route as CalendarDashboardImport } from './routes/calendar-dashboard'
import { Route as BlogImport } from './routes/blog'
import { Route as AuthImport } from './routes/auth'
import { Route as AnalyticsImport } from './routes/analytics'
import { Route as AiReceptionistImport } from './routes/ai-receptionist'
import { Route as AboutImport } from './routes/about'
import { Route as IndexImport } from './routes/index'
import { Route as SettingsCalendarImport } from './routes/settings.calendar'
import { Route as InvitationTokenImport } from './routes/invitation.$token'
import { Route as CalendarAuthSuccessImport } from './routes/calendar/auth-success'
import { Route as CalendarAuthErrorImport } from './routes/calendar/auth-error'
import { Route as SettingsCalendarIntegrationIdImport } from './routes/settings.calendar.$integrationId'

// Create/Update Routes

const TermsRoute = TermsImport.update({
  id: '/terms',
  path: '/terms',
  getParentRoute: () => rootRoute,
} as any)

const SupportRoute = SupportImport.update({
  id: '/support',
  path: '/support',
  getParentRoute: () => rootRoute,
} as any)

const SubscriptionRoute = SubscriptionImport.update({
  id: '/subscription',
  path: '/subscription',
  getParentRoute: () => rootRoute,
} as any)

const SettingsRoute = SettingsImport.update({
  id: '/settings',
  path: '/settings',
  getParentRoute: () => rootRoute,
} as any)

const SecurityRoute = SecurityImport.update({
  id: '/security',
  path: '/security',
  getParentRoute: () => rootRoute,
} as any)

const ResetPasswordRoute = ResetPasswordImport.update({
  id: '/reset-password',
  path: '/reset-password',
  getParentRoute: () => rootRoute,
} as any)

const PrivacyRoute = PrivacyImport.update({
  id: '/privacy',
  path: '/privacy',
  getParentRoute: () => rootRoute,
} as any)

const PressRoute = PressImport.update({
  id: '/press',
  path: '/press',
  getParentRoute: () => rootRoute,
} as any)

const MetricsRoute = MetricsImport.update({
  id: '/metrics',
  path: '/metrics',
  getParentRoute: () => rootRoute,
} as any)

const LeadsRoute = LeadsImport.update({
  id: '/leads',
  path: '/leads',
  getParentRoute: () => rootRoute,
} as any)

const LandingRoute = LandingImport.update({
  id: '/landing',
  path: '/landing',
  getParentRoute: () => rootRoute,
} as any)

const IntegrationsRoute = IntegrationsImport.update({
  id: '/integrations',
  path: '/integrations',
  getParentRoute: () => rootRoute,
} as any)

const HipaaRoute = HipaaImport.update({
  id: '/hipaa',
  path: '/hipaa',
  getParentRoute: () => rootRoute,
} as any)

const HelpCenterRoute = HelpCenterImport.update({
  id: '/help-center',
  path: '/help-center',
  getParentRoute: () => rootRoute,
} as any)

const FeedbackRoute = FeedbackImport.update({
  id: '/feedback',
  path: '/feedback',
  getParentRoute: () => rootRoute,
} as any)

const DocumentationRoute = DocumentationImport.update({
  id: '/documentation',
  path: '/documentation',
  getParentRoute: () => rootRoute,
} as any)

const DashboardRoute = DashboardImport.update({
  id: '/dashboard',
  path: '/dashboard',
  getParentRoute: () => rootRoute,
} as any)

const ContactRoute = ContactImport.update({
  id: '/contact',
  path: '/contact',
  getParentRoute: () => rootRoute,
} as any)

const CareersRoute = CareersImport.update({
  id: '/careers',
  path: '/careers',
  getParentRoute: () => rootRoute,
} as any)

const CalendarDashboardRoute = CalendarDashboardImport.update({
  id: '/calendar-dashboard',
  path: '/calendar-dashboard',
  getParentRoute: () => rootRoute,
} as any)

const BlogRoute = BlogImport.update({
  id: '/blog',
  path: '/blog',
  getParentRoute: () => rootRoute,
} as any)

const AuthRoute = AuthImport.update({
  id: '/auth',
  path: '/auth',
  getParentRoute: () => rootRoute,
} as any)

const AnalyticsRoute = AnalyticsImport.update({
  id: '/analytics',
  path: '/analytics',
  getParentRoute: () => rootRoute,
} as any)

const AiReceptionistRoute = AiReceptionistImport.update({
  id: '/ai-receptionist',
  path: '/ai-receptionist',
  getParentRoute: () => rootRoute,
} as any)

const AboutRoute = AboutImport.update({
  id: '/about',
  path: '/about',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const SettingsCalendarRoute = SettingsCalendarImport.update({
  id: '/calendar',
  path: '/calendar',
  getParentRoute: () => SettingsRoute,
} as any)

const InvitationTokenRoute = InvitationTokenImport.update({
  id: '/invitation/$token',
  path: '/invitation/$token',
  getParentRoute: () => rootRoute,
} as any)

const CalendarAuthSuccessRoute = CalendarAuthSuccessImport.update({
  id: '/calendar/auth-success',
  path: '/calendar/auth-success',
  getParentRoute: () => rootRoute,
} as any)

const CalendarAuthErrorRoute = CalendarAuthErrorImport.update({
  id: '/calendar/auth-error',
  path: '/calendar/auth-error',
  getParentRoute: () => rootRoute,
} as any)

const SettingsCalendarIntegrationIdRoute =
  SettingsCalendarIntegrationIdImport.update({
    id: '/$integrationId',
    path: '/$integrationId',
    getParentRoute: () => SettingsCalendarRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/about': {
      id: '/about'
      path: '/about'
      fullPath: '/about'
      preLoaderRoute: typeof AboutImport
      parentRoute: typeof rootRoute
    }
    '/ai-receptionist': {
      id: '/ai-receptionist'
      path: '/ai-receptionist'
      fullPath: '/ai-receptionist'
      preLoaderRoute: typeof AiReceptionistImport
      parentRoute: typeof rootRoute
    }
    '/analytics': {
      id: '/analytics'
      path: '/analytics'
      fullPath: '/analytics'
      preLoaderRoute: typeof AnalyticsImport
      parentRoute: typeof rootRoute
    }
    '/auth': {
      id: '/auth'
      path: '/auth'
      fullPath: '/auth'
      preLoaderRoute: typeof AuthImport
      parentRoute: typeof rootRoute
    }
    '/blog': {
      id: '/blog'
      path: '/blog'
      fullPath: '/blog'
      preLoaderRoute: typeof BlogImport
      parentRoute: typeof rootRoute
    }
    '/calendar-dashboard': {
      id: '/calendar-dashboard'
      path: '/calendar-dashboard'
      fullPath: '/calendar-dashboard'
      preLoaderRoute: typeof CalendarDashboardImport
      parentRoute: typeof rootRoute
    }
    '/careers': {
      id: '/careers'
      path: '/careers'
      fullPath: '/careers'
      preLoaderRoute: typeof CareersImport
      parentRoute: typeof rootRoute
    }
    '/contact': {
      id: '/contact'
      path: '/contact'
      fullPath: '/contact'
      preLoaderRoute: typeof ContactImport
      parentRoute: typeof rootRoute
    }
    '/dashboard': {
      id: '/dashboard'
      path: '/dashboard'
      fullPath: '/dashboard'
      preLoaderRoute: typeof DashboardImport
      parentRoute: typeof rootRoute
    }
    '/documentation': {
      id: '/documentation'
      path: '/documentation'
      fullPath: '/documentation'
      preLoaderRoute: typeof DocumentationImport
      parentRoute: typeof rootRoute
    }
    '/feedback': {
      id: '/feedback'
      path: '/feedback'
      fullPath: '/feedback'
      preLoaderRoute: typeof FeedbackImport
      parentRoute: typeof rootRoute
    }
    '/help-center': {
      id: '/help-center'
      path: '/help-center'
      fullPath: '/help-center'
      preLoaderRoute: typeof HelpCenterImport
      parentRoute: typeof rootRoute
    }
    '/hipaa': {
      id: '/hipaa'
      path: '/hipaa'
      fullPath: '/hipaa'
      preLoaderRoute: typeof HipaaImport
      parentRoute: typeof rootRoute
    }
    '/integrations': {
      id: '/integrations'
      path: '/integrations'
      fullPath: '/integrations'
      preLoaderRoute: typeof IntegrationsImport
      parentRoute: typeof rootRoute
    }
    '/landing': {
      id: '/landing'
      path: '/landing'
      fullPath: '/landing'
      preLoaderRoute: typeof LandingImport
      parentRoute: typeof rootRoute
    }
    '/leads': {
      id: '/leads'
      path: '/leads'
      fullPath: '/leads'
      preLoaderRoute: typeof LeadsImport
      parentRoute: typeof rootRoute
    }
    '/metrics': {
      id: '/metrics'
      path: '/metrics'
      fullPath: '/metrics'
      preLoaderRoute: typeof MetricsImport
      parentRoute: typeof rootRoute
    }
    '/press': {
      id: '/press'
      path: '/press'
      fullPath: '/press'
      preLoaderRoute: typeof PressImport
      parentRoute: typeof rootRoute
    }
    '/privacy': {
      id: '/privacy'
      path: '/privacy'
      fullPath: '/privacy'
      preLoaderRoute: typeof PrivacyImport
      parentRoute: typeof rootRoute
    }
    '/reset-password': {
      id: '/reset-password'
      path: '/reset-password'
      fullPath: '/reset-password'
      preLoaderRoute: typeof ResetPasswordImport
      parentRoute: typeof rootRoute
    }
    '/security': {
      id: '/security'
      path: '/security'
      fullPath: '/security'
      preLoaderRoute: typeof SecurityImport
      parentRoute: typeof rootRoute
    }
    '/settings': {
      id: '/settings'
      path: '/settings'
      fullPath: '/settings'
      preLoaderRoute: typeof SettingsImport
      parentRoute: typeof rootRoute
    }
    '/subscription': {
      id: '/subscription'
      path: '/subscription'
      fullPath: '/subscription'
      preLoaderRoute: typeof SubscriptionImport
      parentRoute: typeof rootRoute
    }
    '/support': {
      id: '/support'
      path: '/support'
      fullPath: '/support'
      preLoaderRoute: typeof SupportImport
      parentRoute: typeof rootRoute
    }
    '/terms': {
      id: '/terms'
      path: '/terms'
      fullPath: '/terms'
      preLoaderRoute: typeof TermsImport
      parentRoute: typeof rootRoute
    }
    '/calendar/auth-error': {
      id: '/calendar/auth-error'
      path: '/calendar/auth-error'
      fullPath: '/calendar/auth-error'
      preLoaderRoute: typeof CalendarAuthErrorImport
      parentRoute: typeof rootRoute
    }
    '/calendar/auth-success': {
      id: '/calendar/auth-success'
      path: '/calendar/auth-success'
      fullPath: '/calendar/auth-success'
      preLoaderRoute: typeof CalendarAuthSuccessImport
      parentRoute: typeof rootRoute
    }
    '/invitation/$token': {
      id: '/invitation/$token'
      path: '/invitation/$token'
      fullPath: '/invitation/$token'
      preLoaderRoute: typeof InvitationTokenImport
      parentRoute: typeof rootRoute
    }
    '/settings/calendar': {
      id: '/settings/calendar'
      path: '/calendar'
      fullPath: '/settings/calendar'
      preLoaderRoute: typeof SettingsCalendarImport
      parentRoute: typeof SettingsImport
    }
    '/settings/calendar/$integrationId': {
      id: '/settings/calendar/$integrationId'
      path: '/$integrationId'
      fullPath: '/settings/calendar/$integrationId'
      preLoaderRoute: typeof SettingsCalendarIntegrationIdImport
      parentRoute: typeof SettingsCalendarImport
    }
  }
}

// Create and export the route tree

interface SettingsCalendarRouteChildren {
  SettingsCalendarIntegrationIdRoute: typeof SettingsCalendarIntegrationIdRoute
}

const SettingsCalendarRouteChildren: SettingsCalendarRouteChildren = {
  SettingsCalendarIntegrationIdRoute: SettingsCalendarIntegrationIdRoute,
}

const SettingsCalendarRouteWithChildren =
  SettingsCalendarRoute._addFileChildren(SettingsCalendarRouteChildren)

interface SettingsRouteChildren {
  SettingsCalendarRoute: typeof SettingsCalendarRouteWithChildren
}

const SettingsRouteChildren: SettingsRouteChildren = {
  SettingsCalendarRoute: SettingsCalendarRouteWithChildren,
}

const SettingsRouteWithChildren = SettingsRoute._addFileChildren(
  SettingsRouteChildren,
)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '/about': typeof AboutRoute
  '/ai-receptionist': typeof AiReceptionistRoute
  '/analytics': typeof AnalyticsRoute
  '/auth': typeof AuthRoute
  '/blog': typeof BlogRoute
  '/calendar-dashboard': typeof CalendarDashboardRoute
  '/careers': typeof CareersRoute
  '/contact': typeof ContactRoute
  '/dashboard': typeof DashboardRoute
  '/documentation': typeof DocumentationRoute
  '/feedback': typeof FeedbackRoute
  '/help-center': typeof HelpCenterRoute
  '/hipaa': typeof HipaaRoute
  '/integrations': typeof IntegrationsRoute
  '/landing': typeof LandingRoute
  '/leads': typeof LeadsRoute
  '/metrics': typeof MetricsRoute
  '/press': typeof PressRoute
  '/privacy': typeof PrivacyRoute
  '/reset-password': typeof ResetPasswordRoute
  '/security': typeof SecurityRoute
  '/settings': typeof SettingsRouteWithChildren
  '/subscription': typeof SubscriptionRoute
  '/support': typeof SupportRoute
  '/terms': typeof TermsRoute
  '/calendar/auth-error': typeof CalendarAuthErrorRoute
  '/calendar/auth-success': typeof CalendarAuthSuccessRoute
  '/invitation/$token': typeof InvitationTokenRoute
  '/settings/calendar': typeof SettingsCalendarRouteWithChildren
  '/settings/calendar/$integrationId': typeof SettingsCalendarIntegrationIdRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '/about': typeof AboutRoute
  '/ai-receptionist': typeof AiReceptionistRoute
  '/analytics': typeof AnalyticsRoute
  '/auth': typeof AuthRoute
  '/blog': typeof BlogRoute
  '/calendar-dashboard': typeof CalendarDashboardRoute
  '/careers': typeof CareersRoute
  '/contact': typeof ContactRoute
  '/dashboard': typeof DashboardRoute
  '/documentation': typeof DocumentationRoute
  '/feedback': typeof FeedbackRoute
  '/help-center': typeof HelpCenterRoute
  '/hipaa': typeof HipaaRoute
  '/integrations': typeof IntegrationsRoute
  '/landing': typeof LandingRoute
  '/leads': typeof LeadsRoute
  '/metrics': typeof MetricsRoute
  '/press': typeof PressRoute
  '/privacy': typeof PrivacyRoute
  '/reset-password': typeof ResetPasswordRoute
  '/security': typeof SecurityRoute
  '/settings': typeof SettingsRouteWithChildren
  '/subscription': typeof SubscriptionRoute
  '/support': typeof SupportRoute
  '/terms': typeof TermsRoute
  '/calendar/auth-error': typeof CalendarAuthErrorRoute
  '/calendar/auth-success': typeof CalendarAuthSuccessRoute
  '/invitation/$token': typeof InvitationTokenRoute
  '/settings/calendar': typeof SettingsCalendarRouteWithChildren
  '/settings/calendar/$integrationId': typeof SettingsCalendarIntegrationIdRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/about': typeof AboutRoute
  '/ai-receptionist': typeof AiReceptionistRoute
  '/analytics': typeof AnalyticsRoute
  '/auth': typeof AuthRoute
  '/blog': typeof BlogRoute
  '/calendar-dashboard': typeof CalendarDashboardRoute
  '/careers': typeof CareersRoute
  '/contact': typeof ContactRoute
  '/dashboard': typeof DashboardRoute
  '/documentation': typeof DocumentationRoute
  '/feedback': typeof FeedbackRoute
  '/help-center': typeof HelpCenterRoute
  '/hipaa': typeof HipaaRoute
  '/integrations': typeof IntegrationsRoute
  '/landing': typeof LandingRoute
  '/leads': typeof LeadsRoute
  '/metrics': typeof MetricsRoute
  '/press': typeof PressRoute
  '/privacy': typeof PrivacyRoute
  '/reset-password': typeof ResetPasswordRoute
  '/security': typeof SecurityRoute
  '/settings': typeof SettingsRouteWithChildren
  '/subscription': typeof SubscriptionRoute
  '/support': typeof SupportRoute
  '/terms': typeof TermsRoute
  '/calendar/auth-error': typeof CalendarAuthErrorRoute
  '/calendar/auth-success': typeof CalendarAuthSuccessRoute
  '/invitation/$token': typeof InvitationTokenRoute
  '/settings/calendar': typeof SettingsCalendarRouteWithChildren
  '/settings/calendar/$integrationId': typeof SettingsCalendarIntegrationIdRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/about'
    | '/ai-receptionist'
    | '/analytics'
    | '/auth'
    | '/blog'
    | '/calendar-dashboard'
    | '/careers'
    | '/contact'
    | '/dashboard'
    | '/documentation'
    | '/feedback'
    | '/help-center'
    | '/hipaa'
    | '/integrations'
    | '/landing'
    | '/leads'
    | '/metrics'
    | '/press'
    | '/privacy'
    | '/reset-password'
    | '/security'
    | '/settings'
    | '/subscription'
    | '/support'
    | '/terms'
    | '/calendar/auth-error'
    | '/calendar/auth-success'
    | '/invitation/$token'
    | '/settings/calendar'
    | '/settings/calendar/$integrationId'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/about'
    | '/ai-receptionist'
    | '/analytics'
    | '/auth'
    | '/blog'
    | '/calendar-dashboard'
    | '/careers'
    | '/contact'
    | '/dashboard'
    | '/documentation'
    | '/feedback'
    | '/help-center'
    | '/hipaa'
    | '/integrations'
    | '/landing'
    | '/leads'
    | '/metrics'
    | '/press'
    | '/privacy'
    | '/reset-password'
    | '/security'
    | '/settings'
    | '/subscription'
    | '/support'
    | '/terms'
    | '/calendar/auth-error'
    | '/calendar/auth-success'
    | '/invitation/$token'
    | '/settings/calendar'
    | '/settings/calendar/$integrationId'
  id:
    | '__root__'
    | '/'
    | '/about'
    | '/ai-receptionist'
    | '/analytics'
    | '/auth'
    | '/blog'
    | '/calendar-dashboard'
    | '/careers'
    | '/contact'
    | '/dashboard'
    | '/documentation'
    | '/feedback'
    | '/help-center'
    | '/hipaa'
    | '/integrations'
    | '/landing'
    | '/leads'
    | '/metrics'
    | '/press'
    | '/privacy'
    | '/reset-password'
    | '/security'
    | '/settings'
    | '/subscription'
    | '/support'
    | '/terms'
    | '/calendar/auth-error'
    | '/calendar/auth-success'
    | '/invitation/$token'
    | '/settings/calendar'
    | '/settings/calendar/$integrationId'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  AboutRoute: typeof AboutRoute
  AiReceptionistRoute: typeof AiReceptionistRoute
  AnalyticsRoute: typeof AnalyticsRoute
  AuthRoute: typeof AuthRoute
  BlogRoute: typeof BlogRoute
  CalendarDashboardRoute: typeof CalendarDashboardRoute
  CareersRoute: typeof CareersRoute
  ContactRoute: typeof ContactRoute
  DashboardRoute: typeof DashboardRoute
  DocumentationRoute: typeof DocumentationRoute
  FeedbackRoute: typeof FeedbackRoute
  HelpCenterRoute: typeof HelpCenterRoute
  HipaaRoute: typeof HipaaRoute
  IntegrationsRoute: typeof IntegrationsRoute
  LandingRoute: typeof LandingRoute
  LeadsRoute: typeof LeadsRoute
  MetricsRoute: typeof MetricsRoute
  PressRoute: typeof PressRoute
  PrivacyRoute: typeof PrivacyRoute
  ResetPasswordRoute: typeof ResetPasswordRoute
  SecurityRoute: typeof SecurityRoute
  SettingsRoute: typeof SettingsRouteWithChildren
  SubscriptionRoute: typeof SubscriptionRoute
  SupportRoute: typeof SupportRoute
  TermsRoute: typeof TermsRoute
  CalendarAuthErrorRoute: typeof CalendarAuthErrorRoute
  CalendarAuthSuccessRoute: typeof CalendarAuthSuccessRoute
  InvitationTokenRoute: typeof InvitationTokenRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  AboutRoute: AboutRoute,
  AiReceptionistRoute: AiReceptionistRoute,
  AnalyticsRoute: AnalyticsRoute,
  AuthRoute: AuthRoute,
  BlogRoute: BlogRoute,
  CalendarDashboardRoute: CalendarDashboardRoute,
  CareersRoute: CareersRoute,
  ContactRoute: ContactRoute,
  DashboardRoute: DashboardRoute,
  DocumentationRoute: DocumentationRoute,
  FeedbackRoute: FeedbackRoute,
  HelpCenterRoute: HelpCenterRoute,
  HipaaRoute: HipaaRoute,
  IntegrationsRoute: IntegrationsRoute,
  LandingRoute: LandingRoute,
  LeadsRoute: LeadsRoute,
  MetricsRoute: MetricsRoute,
  PressRoute: PressRoute,
  PrivacyRoute: PrivacyRoute,
  ResetPasswordRoute: ResetPasswordRoute,
  SecurityRoute: SecurityRoute,
  SettingsRoute: SettingsRouteWithChildren,
  SubscriptionRoute: SubscriptionRoute,
  SupportRoute: SupportRoute,
  TermsRoute: TermsRoute,
  CalendarAuthErrorRoute: CalendarAuthErrorRoute,
  CalendarAuthSuccessRoute: CalendarAuthSuccessRoute,
  InvitationTokenRoute: InvitationTokenRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/about",
        "/ai-receptionist",
        "/analytics",
        "/auth",
        "/blog",
        "/calendar-dashboard",
        "/careers",
        "/contact",
        "/dashboard",
        "/documentation",
        "/feedback",
        "/help-center",
        "/hipaa",
        "/integrations",
        "/landing",
        "/leads",
        "/metrics",
        "/press",
        "/privacy",
        "/reset-password",
        "/security",
        "/settings",
        "/subscription",
        "/support",
        "/terms",
        "/calendar/auth-error",
        "/calendar/auth-success",
        "/invitation/$token"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/about": {
      "filePath": "about.tsx"
    },
    "/ai-receptionist": {
      "filePath": "ai-receptionist.tsx"
    },
    "/analytics": {
      "filePath": "analytics.tsx"
    },
    "/auth": {
      "filePath": "auth.tsx"
    },
    "/blog": {
      "filePath": "blog.tsx"
    },
    "/calendar-dashboard": {
      "filePath": "calendar-dashboard.tsx"
    },
    "/careers": {
      "filePath": "careers.tsx"
    },
    "/contact": {
      "filePath": "contact.tsx"
    },
    "/dashboard": {
      "filePath": "dashboard.tsx"
    },
    "/documentation": {
      "filePath": "documentation.tsx"
    },
    "/feedback": {
      "filePath": "feedback.tsx"
    },
    "/help-center": {
      "filePath": "help-center.tsx"
    },
    "/hipaa": {
      "filePath": "hipaa.tsx"
    },
    "/integrations": {
      "filePath": "integrations.tsx"
    },
    "/landing": {
      "filePath": "landing.tsx"
    },
    "/leads": {
      "filePath": "leads.tsx"
    },
    "/metrics": {
      "filePath": "metrics.tsx"
    },
    "/press": {
      "filePath": "press.tsx"
    },
    "/privacy": {
      "filePath": "privacy.tsx"
    },
    "/reset-password": {
      "filePath": "reset-password.tsx"
    },
    "/security": {
      "filePath": "security.tsx"
    },
    "/settings": {
      "filePath": "settings.tsx",
      "children": [
        "/settings/calendar"
      ]
    },
    "/subscription": {
      "filePath": "subscription.tsx"
    },
    "/support": {
      "filePath": "support.tsx"
    },
    "/terms": {
      "filePath": "terms.tsx"
    },
    "/calendar/auth-error": {
      "filePath": "calendar/auth-error.tsx"
    },
    "/calendar/auth-success": {
      "filePath": "calendar/auth-success.tsx"
    },
    "/invitation/$token": {
      "filePath": "invitation.$token.tsx"
    },
    "/settings/calendar": {
      "filePath": "settings.calendar.tsx",
      "parent": "/settings",
      "children": [
        "/settings/calendar/$integrationId"
      ]
    },
    "/settings/calendar/$integrationId": {
      "filePath": "settings.calendar.$integrationId.tsx",
      "parent": "/settings/calendar"
    }
  }
}
ROUTE_MANIFEST_END */
