import type { Call } from '@/components/leads/CallHistory';
import { useAuthStore } from '@/store/authStore';
import { CreateLeadInput, Lead, LeadsResponse, LeadStatus, Status, UpdateLeadInput } from '@/types';
import type { User } from '@/types/auth';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { z } from 'zod';

// Get the API URL from environment variables or use localhost as fallback
const API_URL = import.meta.env.VITE_API_URL || 'http://localhost:3001/api';

// Create axios instance with base URL
export const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Utility function to format phone numbers consistently
export function formatPhoneNumber(phone: string | null | undefined): string {
  if (!phone) return '';
  
  // Remove all non-digit characters
  const cleaned = phone.replace(/\D/g, '');
  
  // Handle different formats
  if (cleaned.length === 10) {
    // Standard US number (10 digits)
    return cleaned;
  } else if (cleaned.length === 11 && cleaned.startsWith('1')) {
    // US number with country code (11 digits starting with 1)
    return cleaned.substring(1);
  } else if (cleaned.startsWith('+1') || cleaned.startsWith('1')) {
    // Handle +1 prefix or 1 prefix with any length
    const digits = cleaned.replace(/^\+?1/, '');
    if (digits.length === 10) {
      return digits;
    }
  }
  
  // If we can't determine the format, return the cleaned digits
  // The backend will handle further validation
  return cleaned;
}

// Format phone number to E.164 format for storage
export function formatPhoneNumberForTwilio(phone: string | null | undefined): string {
  if (!phone) return '';
  
  // Use the shared formatPhoneNumber function to clean the number
  const cleaned = formatPhoneNumber(phone);
  
  // Add +1 prefix for US numbers if it's a valid 10-digit number
  if (cleaned.length === 10) {
    return `+1${cleaned}`;
  }
  
  // If it's already in E.164 format or we can't determine the format,
  // return as is and let the backend handle it
  return cleaned.startsWith('+') ? cleaned : `+1${cleaned}`;
}

interface Message {
  id: number;
  conversationId: number;
  body: string;
  sentByUser: number;
  twilioMessageSid: string;
  createdAt: string | null;
  updatedAt: string | null;
  notified: number;
  analytics?: {
    id: number;
    conversationId: number;
    messageId: number;
    summary: string;
    keyPoints: string[];
    sentimentScore: string;
    leadScore: string;
    createdAt: string | null;
    updatedAt: string | null;
  } | null;
}

export interface Conversation {
  id: number;
  userId: number;
  leadPhoneNumber: string;
  twilioPhoneNumber: string;
  twilioConversationSid: string;
  lastInteractionAt: string;
  createdAt: string;
  updatedAt: string;
  state?: ConversationState | null;
}

export interface ConversationResponse {
  conversation: Conversation | null;
  messages: Message[];
}

export interface MessageTemplate {
  id: string;
  label: string;
  text: string;
  createdAt: string;
  updatedAt: string;
}

// DID Types
export interface AvailablePhoneNumber {
  phoneNumber: string;
  friendlyName: string;
  locality: string | null;
  region: string | null;
  price: string;
}

export interface AvailableNumbersResponse {
  numbers: AvailablePhoneNumber[];
  message?: string;
}

export interface PurchasedPhoneNumber {
  phoneNumber: string;
  friendlyName: string;
  sid: string;
  locality: string | null;
  region: string | null;
}

export interface DIDNumber {
  id: number;
  userId: number | null;
  phoneNumber: string;
  areaCode: string;
  locality: string | null;
  region: string | null;
  price: string;
  createdAt: string;
  updatedAt: string;
}

interface PhoneNumber {
  phoneNumber: string;
  locality: string | null;
  region: string | null;
  areaCode: string;
  price: number | null;
  createdAt: string;
  updatedAt: string;
  userId: number | null;
}

interface SearchNumbersParams {
  areaCode?: string;
  state?: string;
  city?: string;
}

interface DIDResponse {
  numbers: DIDNumber[];
}

// Add auth token to requests
axiosInstance.interceptors.request.use((config) => {
  const token = useAuthStore.getState().token;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Handle auth errors
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401 || error.response?.status === 403) {
      useAuthStore.getState().logout();
    }
    return Promise.reject(error);
  }
);

// Auth schemas
export const loginSchema = z.object({
  email: z.string().email('Invalid email address'),
  password: z.string().min(6, 'Password must be at least 6 characters'),
});

export const registerSchema = z.object({
  email: z.string().email('Invalid email address'),
  password: z.string().min(6, 'Password must be at least 6 characters'),
  firstName: z.string().min(1, 'First name is required'),
  lastName: z.string().min(1, 'Last name is required'),
});

// Lead schemas
export const leadSchema = z.object({
    id: z.number(),
    orderId: z.number().nullable(),
    userId: z.number(),
    name: z.string().max(50),
    title: z.string().max(25).nullable(),
    businessName: z.string().max(50).nullable(),
    phoneNumber: z.string().max(18),
    altPhone: z.string().max(18).nullable(),
    email: z.string().max(255).nullable(),
    address: z.string().max(100).nullable(),
    addressNew: z.string().max(100).nullable(),
    city: z.string().max(25).nullable(),
    state: z.string().max(2).nullable(),
    zip: z.string().max(5).nullable(),
    county: z.string().max(50).nullable(),
    insCompany: z.string().max(50).nullable(),
    age: z.string().max(2).nullable(),
    spouseAge: z.string().max(2).nullable(),
    children: z.string().max(2).nullable(),
    smoker: z.number().default(0),
    callDate: z.string().nullable(),
    tsr: z.string().max(255).default('Self'),
    bestTimeOfCall: z.string().max(255).nullable(),
    comments: z.string().nullable(),
    createdAt: z.string(),
    updatedAt: z.string().nullable(),
    deletedAt: z.string().nullable(),
    status: z.enum(['new', 'contacted', 'qualified', 'proposal', 'won', 'lost']).default('new'),
    notified: z.number().default(0),
    lastContacted: z.string().nullable(),
    agedLead: z.number().default(0),
    phoneType: z.string().max(1).nullable()
});

export const leadsResponseSchema = z.object({
  success: z.boolean(),
  leads: z.array(leadSchema),
  pages: z.number(),
  total: z.number()
});

// Message schema
export const messageSchema = z.object({
  id: z.number(),
  userId: z.number(),
  phoneNumber: z.string(),
  message: z.string(),
  direction: z.enum(['inbound', 'outbound']),
  twilioSid: z.string(),
  createdAt: z.string(),
  updatedAt: z.string()
});

async function handleResponse<T>(response: Response): Promise<T> {
  if (!response.ok) {
    const error = await response.json().catch(() => ({ message: 'An error occurred' }));
    throw new Error(error.message || 'An error occurred');
  }
  return response.json();
}

export interface SMSAnalysis {
  summary: string;
  keyPoints: string[];
  sentimentScore: number;
  leadScore: number;
}

export interface ConversationState {
  summary: string;
  keyPoints: string[];
  sentimentTrajectory: number[];
  leadScoreTrajectory: number[];
  messageCount: number;
  lastUpdated?: string;
}

export interface ConversationAnalytics {
  conversation: {
    id: number;
    leadPhoneNumber: string;
    twilioPhoneNumber: string;
    lastInteractionAt: string;
  };
  conversationState: ConversationState | null;
  analytics: Array<{
    messageId: number;
    body: string;
    sentByUser: boolean;
    createdAt: string | null;
    analytics: SMSAnalysis | null;
  }>;
  summary: {
    messageCount: number;
    analyzedMessageCount: number;
    averageSentiment: number;
    averageLeadScore: number;
  };
}
// Add interfaces for action items
export interface CompletedActionItem {
  id: number;
  leadId: number;
  userId: number;
  itemText: string;
  source: string;
  sourceDate: string;
  completedAt: string;
  createdAt: string;
  updatedAt: string;
}

export interface ActionItemInput {
  itemText: string;
  source: string;
  sourceDate: string;
}

// API endpoints
export const api = {
  auth: {
    login: async (data: z.infer<typeof loginSchema>) => {
      const response = await axiosInstance.post<{ token: string }>('/auth/login', data);
      return response.data;
    },
    register: async (data: z.infer<typeof registerSchema>) => {
      const response = await axiosInstance.post<{ token: string }>('/auth/register', data);
      return response.data;
    },
  },
  leads: {
    list: async (params: {
      page?: number;
      limit?: number;
      search?: string;
      type?: 'all' | 'aged' | 'prime';
      status?: LeadStatus;
      sortBy?: 'leadScore' | 'lastContacted' | 'createdAt' | 'updatedAt' | 'name';
      sortOrder?: 'asc' | 'desc';
      includeArchived?: boolean;
    } = {}) => {
      const { data } = await axiosInstance.get<{ leads: Lead[]; total: number }>('/leads', {
        params: {
          page: params.page ?? 1,
          limit: params.limit ?? 50,
          ...(params.search ? { search: params.search } : {}),
          ...(params.type ? { type: params.type } : {}),
          ...(params.status ? { status: params.status } : {}),
          ...(params.sortBy ? { sortBy: params.sortBy } : {}),
          ...(params.sortOrder ? { sortOrder: params.sortOrder } : {}),
          ...(params.includeArchived ? { includeArchived: params.includeArchived } : {}),
        }
      });
      
      return {
        success: true,
        leads: data.leads,
        total: data.total,
        pages: Math.ceil(data.total / (params.limit ?? 50))
      };
    },
    getById: async (id: number) => {
      const { data } = await axiosInstance.get<{ success: boolean; lead: Lead }>(`/leads/${id}`);
      return data;
    },
    create: async (lead: CreateLeadInput) => {
      // Format phone numbers before sending to API
      const formattedLead = {
        ...lead,
        phoneNumber: lead.phoneNumber ? formatPhoneNumber(lead.phoneNumber) : lead.phoneNumber,
        altPhone: lead.altPhone ? formatPhoneNumber(lead.altPhone) : lead.altPhone
      };
      
      const { data } = await axiosInstance.post<{ success: boolean; lead: Lead }>('/leads', formattedLead);
      return data;
    },
    update: async (id: number, updates: UpdateLeadInput) => {
      // If updates contain phone numbers, format them
      const formattedUpdates = { ...updates };
      
      if ('phoneNumber' in updates && updates.phoneNumber) {
        formattedUpdates.phoneNumber = formatPhoneNumber(updates.phoneNumber);
      }
      
      if ('altPhone' in updates && updates.altPhone) {
        formattedUpdates.altPhone = formatPhoneNumber(updates.altPhone);
      }
      
      const { data } = await axiosInstance.put<{ success: boolean; lead: Lead }>(`/leads/${id}`, formattedUpdates);
      return data;
    },
    archive: async (id: number) => {
      // Archive a lead by setting the deletedAt timestamp
      // Use a simple string 'now' instead of a Date object to let the server handle the timestamp
      const { data } = await axiosInstance.put<{ success: boolean; lead: Lead }>(`/leads/${id}`, {
        deletedAt: 'now'
      });
      return data;
    },
    getByPhone: async (phone: string) => {
      // Format the phone number before sending to API
      const formattedPhone = formatPhoneNumber(phone);
      const { data } = await axiosInstance.get<{ success: boolean; lead: Lead }>(`/leads/phone/${formattedPhone}`);
      return data;
    },
    uploadCsv: async (file: File) => {
      const formData = new FormData();
      formData.append('file', file);
      
      const { data } = await axiosInstance.post<{
        success: boolean;
        headers: string[];
        metadataFields: Array<{
          metadataKey: string;
          valueType: 'text' | 'number' | 'boolean';
          useCount: number;
          lastUsed: string;
        }>;
        filePath: string;
      }>('/leads/import/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      
      return data;
    },
    previewCsvData: async (params: {
      filePath: string;
      mapping: Record<string, string>;
      limit?: number;
    }) => {
      const { data } = await axiosInstance.post<{
        success: boolean;
        previewData: any[];
      }>('/leads/import/preview', params);
      
      return data;
    },
    importCsvData: async (params: {
      filePath: string;
      mapping: Record<string, string>;
      metadataMapping?: Record<string, string>;
    }) => {
      const { data } = await axiosInstance.post<{
        success: boolean;
        totalRecords: number;
        importedRecords: number;
        failedRecords: number;
        errors?: string[];
      }>('/leads/import/process', params);
      
      return data;
    },
    createMetadataField: async (params: {
      metadataKey: string;
      valueType: 'text' | 'number' | 'boolean';
    }) => {
      const { data } = await axiosInstance.post<{
        success: boolean;
        message: string;
        metadataFields: Array<{
          id: number;
          metadataKey: string;
          valueType: 'text' | 'number' | 'boolean';
          useCount: number;
          lastUsed: string;
        }>;
      }>('/leads/metadata/field', params);
      
      return data;
    }
  },
  statuses: {
    create: async (status: { name: string; color: string; description: string }) => {
      const { data } = await axiosInstance.post<{ success: boolean; status: Status }>('/statuses', status);
      return data;
    },
    list: async () => {
      const { data } = await axiosInstance.get<{ success: boolean; statuses: Status[] }>('/statuses');
      return data;
    },
    delete: async (id: number) => {
      const { data } = await axiosInstance.delete<{ success: boolean }>(`/statuses/${id}`);
      return data;
    },
  },
  calls: {
    list: async (leadId: number) => {
      const response = await axiosInstance.get(`/calls?lead_id=${leadId}`);
      return response.data;
    },
    create: async (data: { leadId: number; notes: string; datetime: string }) => {
      const response = await axiosInstance.post('/calls', {
        lead_id: data.leadId,
        notes: data.notes,
        datetime: data.datetime,
      });
      return response.data;
    },
    delete: async (id: number) => {
      const response = await axiosInstance.delete(`/calls/${id}`);
      return response.data;
    },
    generateAnalysis: async (callId: number) => {
      const response = await axiosInstance.post<{ analysis: string }>(`/calls/${callId}/analyze`);
      return response.data.analysis;
    },
  },
  twilio: {
    getVoiceToken: async () => {
      try {
        console.log('[API] Requesting Twilio voice token');
        const response = await axiosInstance.get<{ token: string; clientId: string }>('/twilio/token');
        console.log('[API] Received Twilio voice token');
        return response.data;
      } catch (error) {
        console.error('[API] Error getting Twilio voice token:', error);
        if (axios.isAxiosError(error) && error.response) {
          throw new Error(`Failed to get voice token: ${error.response.data?.error || error.message}`);
        }
        throw new Error('Failed to get voice token. Please check your network connection.');
      }
    },
    sendSMS: async (phoneNumber: string, message: string, fromNumber?: string | null, skipLookup?: boolean) => {
      try {
        // Format to E.164 for Twilio
        const formattedNumber = formatPhoneNumberForTwilio(phoneNumber);
        console.log('[API] Sending SMS to:', formattedNumber);
        const response = await axiosInstance.post<{ success: boolean; twilioSid: string }>('/twilio/sms', {
          to: formattedNumber,
          message,
          from: fromNumber ? formatPhoneNumberForTwilio(fromNumber) : undefined,
          skipLookup
        });
        console.log('[API] SMS sent successfully:', response.data.twilioSid);
        return response.data;
      } catch (error) {
        console.error('[API] Error sending SMS:', error);
        if (axios.isAxiosError(error) && error.response) {
          throw new Error(`Failed to send SMS: ${error.response.data?.error || error.message}`);
        }
        throw new Error('Failed to send SMS. Please check your network connection.');
      }
    },
    getConversation: async (phoneNumber: string): Promise<ConversationResponse> => {
      try {
        // Format to E.164 for Twilio
        const formattedNumber = formatPhoneNumber(phoneNumber);
        console.log('[API] Fetching conversation for:', formattedNumber);
        const response = await axiosInstance.get<ConversationResponse>(`/twilio/conversation/${encodeURIComponent(formattedNumber)}`);
        console.log('[API] Conversation fetched successfully');
        return response.data;
      } catch (error) {
        console.error('[API] Error fetching conversation:', error);
        if (axios.isAxiosError(error) && error.response) {
          throw new Error(`Failed to fetch conversation: ${error.response.data?.error || error.message}`);
        }
        throw new Error('Failed to fetch conversation. Please check your network connection.');
      }
    },
    markMessagesAsRead: async (params: { messageId?: number; conversationId?: number }): Promise<{ success: boolean }> => {
      try {
        console.log('[API] Marking messages as read:', params);
        const response = await axiosInstance.post<{ success: boolean }>('/twilio/messages/mark-as-read', params);
        console.log('[API] Messages marked as read');
        return response.data;
      } catch (error) {
        console.error('[API] Error marking messages as read:', error);
        if (axios.isAxiosError(error) && error.response) {
          throw new Error(`Failed to mark messages as read: ${error.response.data?.error || error.message}`);
        }
        throw new Error('Failed to mark messages as read. Please check your network connection.');
      }
    },
    getCallHistory: async (phoneNumber: string) => {
      try {
        // Format to E.164 for Twilio
        const formattedNumber = formatPhoneNumber(phoneNumber);
        console.log('[API] Fetching call history for:', formattedNumber);
        const response = await axiosInstance.get<{ calls: Call[] }>(`/twilio/calls/${encodeURIComponent(formattedNumber)}`);
        console.log('[API] Call history fetched successfully');
        return response.data;
      } catch (error) {
        console.error('[API] Error fetching call history:', error);
        if (axios.isAxiosError(error) && error.response) {
          throw new Error(`Failed to fetch call history: ${error.response.data?.error || error.message}`);
        }
        throw new Error('Failed to fetch call history. Please check your network connection.');
        
        // Return empty array to prevent UI errors
        return { calls: [] };
      }
    },
    /**
     * Check if a phone number can receive SMS messages
     */
    checkPhoneLineType: async (phoneNumber: string): Promise<PhoneLookupResult> => {
      try {
        // Format phone number for lookup
        const formattedNumber = formatPhoneNumberForTwilio(phoneNumber);
        console.log('[API] Checking phone line type for:', formattedNumber);
        
        const response = await axiosInstance.get<PhoneLookupResult>(`/twilio/lookup/${encodeURIComponent(formattedNumber)}`);
        console.log('[API] Phone type lookup result:', response.data);
        
        return response.data;
      } catch (error) {
        console.error('[API] Error checking phone line type:', error);
        if (axios.isAxiosError(error) && error.response) {
          throw new Error(`Failed to check phone line type: ${error.response.data?.error || error.message}`);
        }
        throw new Error('Failed to check phone line type. Please check your network connection.');
      }
    },
  },
  settings: {
    updateProfile: async (data: { name: string; email: string; phone: string | null }) => {
      const response = await axiosInstance.put<User>('/users/profile', data);
      return response.data;
    },
    updateNotificationPreferences: async (preferences: { notifyRealTime: boolean; emailLeads: boolean }) => {
      const response = await axiosInstance.put<User>('/users/notifications', preferences);
      return response.data;
    },
    updatePassword: async (data: { currentPassword: string; newPassword: string }) => {
      const response = await axiosInstance.put<{ message: string }>('/users/security/password', data);
      return response.data;
    },
    deleteAccount: async () => {
      const response = await axiosInstance.delete<{ message: string }>('/users/security/account');
      return response.data;
    },
  },
  templates: {
    list: async () => {
      const response = await axiosInstance.get<MessageTemplate[]>('/templates');
      return response.data;
    },
    create: async (template: { label: string; text: string }) => {
      const response = await axiosInstance.post<MessageTemplate>('/templates', template);
      return response.data;
    },
    update: async (id: number, template: { label: string; text: string }) => {
      const response = await axiosInstance.put<MessageTemplate>(`/templates/${id}`, template);
      return response.data;
    },
    delete: async (id: number) => {
      const response = await axiosInstance.delete<{ success: boolean }>(`/templates/${id}`);
      return response.data;
    },
    reorder: async (templateIds: number[]) => {
      const response = await axiosInstance.post<{ success: boolean }>('/templates/reorder', { templateIds });
      return response.data;
    },
  },
  did: {
    searchNumbers: async (params: SearchNumbersParams) => {
      const searchParams = new URLSearchParams();
      if (params.areaCode) searchParams.append('areaCode', params.areaCode);
      if (params.state) searchParams.append('state', params.state);
      if (params.city) searchParams.append('city', params.city);
      const response = await axiosInstance.get<AvailableNumbersResponse>(`/did/search?${searchParams.toString()}`);
      return response.data;
    },
    listNumbers: async () => {
      const response = await axiosInstance.get<DIDResponse>('/did');
      return response.data.numbers;
    },
    purchaseNumber: async (phoneNumber: string, locality: string | null, region: string | null) => {
      const formattedNumber = formatPhoneNumber(phoneNumber);
      const response = await axiosInstance.post<{ number: PurchasedPhoneNumber }>('/did/purchase', { 
        phoneNumber: formattedNumber,
        locality,
        region
      });
      return response.data.number;
    },
    releaseNumber: async (phoneNumber: string) => {
      const formattedNumber = formatPhoneNumber(phoneNumber);
      const response = await axiosInstance.delete<{ success: boolean }>(`/did/${encodeURIComponent(formattedNumber)}`);
      return response.data;
    },
  },
  smsAnalytics: {
    analyzeMessage: async (messageId: number) => {
      try {
        console.log('[API] Analyzing SMS message:', messageId);
        const response = await axiosInstance.post<{ success: boolean; analysis: SMSAnalysis }>(`/sms-analytics/message/${messageId}`);
        console.log('[API] SMS analysis complete');
        return response.data.analysis;
      } catch (error) {
        console.error('[API] Error analyzing SMS message:', error);
        if (axios.isAxiosError(error) && error.response) {
          throw new Error(`Failed to analyze SMS message: ${error.response.data?.error || error.message}`);
        }
        throw new Error('Failed to analyze SMS message. Please check your network connection.');
      }
    },
    
    analyzeConversation: async (conversationId: number) => {
      try {
        console.log('[API] Analyzing SMS conversation:', conversationId);
        const response = await axiosInstance.post<{ success: boolean; results: SMSAnalysis[]; count: number }>(`/sms-analytics/conversation/${conversationId}`);
        console.log('[API] SMS conversation analysis complete');
        return response.data.results;
      } catch (error) {
        console.error('[API] Error analyzing SMS conversation:', error);
        if (axios.isAxiosError(error) && error.response) {
          throw new Error(`Failed to analyze SMS conversation: ${error.response.data?.error || error.message}`);
        }
        throw new Error('Failed to analyze SMS conversation. Please check your network connection.');
      }
    },
    
    getConversationAnalytics: async (conversationId: number) => {
      try {
        console.log('[API] Fetching SMS conversation analytics:', conversationId);
        const response = await axiosInstance.get<{ success: boolean } & ConversationAnalytics>(`/sms-analytics/conversation/${conversationId}`);
        console.log('[API] SMS conversation analytics fetched');
        return response.data;
      } catch (error) {
        console.error('[API] Error fetching SMS conversation analytics:', error);
        if (axios.isAxiosError(error) && error.response) {
          throw new Error(`Failed to fetch SMS conversation analytics: ${error.response.data?.error || error.message}`);
        }
        throw new Error('Failed to fetch SMS conversation analytics. Please check your network connection.');
      }
    }
  },
  // Action Items
  actionItems: {
    getCompleted: async (leadId: number): Promise<{ success: boolean; items: CompletedActionItem[] }> => {
      const { data } = await axiosInstance.get(`/leads/${leadId}/action-items/completed`);
      return data;
    },
    
    markComplete: async (leadId: number, item: ActionItemInput): Promise<{ success: boolean; message: string }> => {
      const { data } = await axiosInstance.post(`/leads/${leadId}/action-items/complete`, item);
      return data;
    },
    
    markIncomplete: async (leadId: number, itemText: string): Promise<{ success: boolean; message: string }> => {
      const { data } = await axiosInstance.post(`/leads/${leadId}/action-items/incomplete`, { itemText });
      return data;
    },
    
    markAllComplete: async (leadId: number, items: ActionItemInput[]): Promise<{ success: boolean; message: string }> => {
      const { data } = await axiosInstance.post(`/leads/${leadId}/action-items/complete-all`, { items });
      return data;
    }
  },
  dashboard: {
    exportReport: async (options: {
      format: 'csv';
      timeframe: string;
      customDateRange: { startDate: Date; endDate: Date } | null;
      includeMetrics: boolean;
      includeActions: boolean;
      includeActivity: boolean;
      includeTeam: boolean;
      emailTo: string;
    }) => {
      try {
        // If email is provided, use POST to send the report via email
        if (options.emailTo) {
          console.log(`Sending report to email: ${options.emailTo}`);
          const { data } = await axiosInstance.post('/dashboard/export', options);
          console.log('Email export response:', data);
          return data;
        }
        
        // For direct file download, use axios with responseType blob
        try {
          console.log('Exporting report with options:', options);
          const response = await axiosInstance.post('/dashboard/export', options, {
            responseType: 'blob'
          });
          
          console.log('Export response received:', response);
          
          // Check if the response is an error message in JSON format
          if (response.data.type === 'application/json') {
            // Convert blob to text to read the error message
            const errorText = await response.data.text();
            const errorData = JSON.parse(errorText);
            console.error('Error in export response:', errorData);
            return { success: false, message: errorData.message || 'Export failed' };
          }
          
          // Check if the response is empty or invalid
          if (!response.data || response.data.size === 0) {
            console.error('Empty or invalid response received');
            return { success: false, message: 'Received empty or invalid response' };
          }
          
          // Create a download link for the blob
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          
          // Generate filename based on timeframe
          let filename = 'dashboard-report';
          
          // Add date range to filename
          if (options.customDateRange) {
            const startDate = options.customDateRange.startDate.toISOString().split('T')[0];
            const endDate = options.customDateRange.endDate.toISOString().split('T')[0];
            filename += `-${startDate}-to-${endDate}`;
          } else {
            filename += `-${options.timeframe}`;
          }
          
          // Add timestamp and extension
          filename += `-${new Date().toISOString().replace(/[:.]/g, '-')}.csv`;
          
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          
          // Clean up
          window.URL.revokeObjectURL(url);
          document.body.removeChild(link);
          
          return { success: true, message: 'Report downloaded successfully' };
        } catch (error: any) {
          console.error('Error downloading report:', error);
          
          // Check if the error response contains a message
          if (error.response && error.response.data) {
            // Try to read the error message from the blob
            try {
              const blob = error.response.data;
              const text = await blob.text();
              
              try {
                const errorData = JSON.parse(text);
                return { success: false, message: errorData.message || 'Export failed' };
              } catch (e) {
                // If it's not JSON, just return the text
                return { success: false, message: text || 'Export failed' };
              }
            } catch (e) {
              // If we can't read the blob, return a generic error
              return { success: false, message: 'Failed to download report' };
            }
          }
          
          return { success: false, message: error.message || 'Failed to download report' };
        }
      } catch (error: any) {
        console.error('Error in exportReport:', error);
        return { success: false, message: error.message || 'Export failed' };
      }
    }
  },
};

// React Query hooks
export const useLeads = (params?: { search?: string; page?: number; limit?: number }) => {
  return useQuery<LeadsResponse>({
    queryKey: ['leads', params],
    queryFn: () => api.leads.list(params),
  });
};

export const useLead = (id: number | null, options?: { activeConversation?: boolean }) => {
  return useQuery({
    queryKey: ['lead', id],
    queryFn: () => {
      if (!id) throw new Error('Lead ID is required');
      return api.leads.getById(id);
    },
    enabled: !!id,
    staleTime: options?.activeConversation ? 1000 * 5 : 1000 * 60 * 5, // 5 seconds if in active conversation, otherwise 5 minutes
  });
};

// React Query hooks for DID management
export const useAvailableNumbers = (areaCode: string | null, count?: number) => {
  return useQuery({
    queryKey: ['availableNumbers', areaCode, count],
    queryFn: () => {
      if (!areaCode) throw new Error('Area code is required');
      return api.did.searchNumbers({ areaCode });
    },
    enabled: !!areaCode
  });
};

export const useUserNumbers = () => {
  return useQuery({
    queryKey: ['userNumbers'],
    queryFn: () => api.did.listNumbers()
  });
};

// Avatar functions
export async function getAvatarUploadUrl(fileName: string, fileType: string) {
  const response = await axiosInstance.post('/users/avatar/upload-url', {
    fileName,
    fileType
  });
  return response.data;
}

export async function updateAvatar(fileKey: string, directS3Url: string) {
  const response = await axiosInstance.post('/users/avatar', {
    fileKey,
    directS3Url
  });
  return response.data.user;
}

// Helper function to resize an image before upload
export async function resizeImage(file: File, maxWidth = 300, maxHeight = 300): Promise<File> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);
    
    img.onload = () => {
      // If the image is already smaller than the max dimensions, return the original
      if (img.width <= maxWidth && img.height <= maxHeight) {
        resolve(file);
        return;
      }
      
      // Create a canvas to resize the image
      const canvas = document.createElement('canvas');
      let width = img.width;
      let height = img.height;
      
      // Calculate the new dimensions while maintaining aspect ratio
      if (width > height) {
        if (width > maxWidth) {
          height = Math.round(height * (maxWidth / width));
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width = Math.round(width * (maxHeight / height));
          height = maxHeight;
        }
      }
      
      canvas.width = width;
      canvas.height = height;
      
      // Draw the resized image
      const ctx = canvas.getContext('2d');
      if (!ctx) {
        reject(new Error('Could not get canvas context'));
        return;
      }
      
      ctx.drawImage(img, 0, 0, width, height);
      
      // Convert the canvas to a Blob
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            reject(new Error('Could not create blob from canvas'));
            return;
          }
          
          // Create a new File from the Blob
          const resizedFile = new File([blob], file.name, {
            type: file.type,
            lastModified: Date.now()
          });
          
          resolve(resizedFile);
        },
        file.type,
        0.9 // Quality
      );
    };
    
    img.onerror = () => {
      reject(new Error('Error loading image'));
    };
  });
}

// Helper function to upload a file to S3 using a pre-signed URL
export async function uploadFileToS3(
  uploadUrl: string,
  file: File,
  onProgress?: (progress: number) => void
) {
  try {
    // Use XMLHttpRequest for upload progress tracking
    await new Promise<void>((resolve, reject) => {
      const xhr = new XMLHttpRequest();

      // Track upload progress
      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable && onProgress) {
          const percentComplete = (event.loaded / event.total) * 100;
          onProgress(percentComplete);
        }
      };

      // Handle response
      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve();
        } else {
          reject(new Error(`Upload failed: ${xhr.statusText}`));
        }
      };

      // Handle network errors
      xhr.onerror = () => {
        reject(new Error('Network error during upload'));
      };

      // Open and send the request
      xhr.open('PUT', uploadUrl);
      xhr.setRequestHeader('Content-Type', file.type);
      xhr.send(file);
    });

    return { success: true };
  } catch (error) {
    console.error('Error uploading file to S3:', error);
    throw error;
  }
}

// Metrics Types
export type MetricType = 
  | 'leads_created_count'
  | 'leads_contacted_count'
  | 'messages_sent_count'
  | 'calls_made_count'
  | 'avg_response_time_seconds'
  | 'conversion_rate_percent'
  | 'sms_response_rate_percent'
  | 'call_answer_rate_percent';

export type TimeframeType = 'daily' | 'weekly' | 'monthly';

// Types for Metrics API responses
export interface MetricsInfoResponse {
  success: boolean;
  metricTypes: Record<MetricType, string>;
  timeframes: Record<TimeframeType, string>;
}

export interface MetricsResponse {
  success: boolean;
  metrics: Record<MetricType, number[]>;
  comparisons: Record<MetricType, number>;
  timeframe: TimeframeType;
  startDate: string;
  endDate: string;
}

export interface LeaderboardResponse {
  success: boolean;
  leaderboard: Array<{
    userId: number;
    name: string;
    value: number;
  }>;
  metricType: MetricType;
  timeframe: TimeframeType;
  date: string;
}

// Parameters for Metrics API requests
export interface MetricsParams {
  metrics: string;
  timeframe: TimeframeType;
  startDate: string;
  endDate: string;
}

export interface LeaderboardParams {
  metric: MetricType;
  timeframe: TimeframeType;
  startDate: string;
  endDate: string;
}

/**
 * Fetch available metrics and timeframes
 */
export async function fetchMetricsInfo(): Promise<MetricsInfoResponse> {
  const response = await axiosInstance.get('/metrics/info');
  return response.data;
}

/**
 * Fetch metrics for the current user
 */
export async function fetchUserMetrics(params: MetricsParams): Promise<MetricsResponse> {
  const response = await axiosInstance.get('/metrics/user', { params });
  return response.data;
}

/**
 * Fetch team-wide metrics
 */
export async function fetchTeamMetrics(params: MetricsParams): Promise<MetricsResponse> {
  const response = await axiosInstance.get('/metrics/team', { params });
  return response.data;
}

/**
 * Fetch leaderboard data
 */
export async function fetchLeaderboard(params: LeaderboardParams): Promise<LeaderboardResponse> {
  const response = await axiosInstance.get('/metrics/leaderboard', { params });
  return response.data;
}

export type LineType = 'mobile' | 'landline' | 'fixedVoip' | 'nonFixedVoip' | 'personal' | 'tollFree' | 'premium' | 'sharedCost' | 'uan' | 'voicemail' | 'pager' | 'unknown';

export interface LineTypeIntelligence {
  errorCode: string | null;
  mobileCountryCode: string | null;
  mobileNetworkCode: string | null;
  carrierName: string | null;
  type: LineType | null;
}

export interface PhoneLookupResult {
  success: boolean;
  phoneNumber: string;
  valid: boolean;
  lineTypeIntelligence: LineTypeIntelligence | null;
  canReceiveSMS: boolean;
  error?: string;
} 