import { Alert, AlertDescription } from '@/components/ui/alert';
import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import { Card } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Textarea } from '@/components/ui/textarea';
import { cn } from '@/lib/utils';
import { CalendarEvent, CalendarService } from '@/services/calendar.service';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { addMinutes, compareAsc, format, set } from 'date-fns';
import { AlertCircle, Calendar as CalendarIcon, Clock, Loader2, X } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';

interface ScheduleMeetingProps {
  leadId: number;
  leadName: string;
  onClose: () => void;
  onScheduled: () => void;
  existingEvent?: CalendarEvent;
}

export default function ScheduleMeeting({ leadId, leadName, onClose, onScheduled, existingEvent }: ScheduleMeetingProps) {
  const queryClient = useQueryClient();
  const [error, setError] = useState<string | null>(null);
  const [meetingTitle, setMeetingTitle] = useState(existingEvent?.title || `Meeting with ${leadName}`);
  const [meetingLocation, setMeetingLocation] = useState(existingEvent?.location || '');
  const [meetingNotes, setMeetingNotes] = useState(existingEvent?.description || '');
  const [selectedIntegrationId, setSelectedIntegrationId] = useState(existingEvent?.calendarIntegrationId || '');

  // Initialize date values based on whether we're editing or creating
  const [selectedDate, setSelectedDate] = useState<Date>(() => {
    if (existingEvent) {
      const startTime = new Date(existingEvent.startTime);
      return startTime;
    }
    return new Date();
  });

  // Initialize time values
  const [startHour, setStartHour] = useState<string>(() => {
    if (existingEvent) {
      const startTime = new Date(existingEvent.startTime);
      return (startTime.getHours() % 12 || 12).toString();
    }
    const now = new Date();
    const currentHour = now.getHours();
    const currentMinute = now.getMinutes();
    const roundedMinute = Math.floor(currentMinute / 15) * 15;
    return roundedMinute > 45 ? ((currentHour + 1) % 12 || 12).toString() : (currentHour % 12 || 12).toString();
  });

  const [startMinute, setStartMinute] = useState<string>(() => {
    if (existingEvent) {
      const startTime = new Date(existingEvent.startTime);
      const minutes = startTime.getMinutes();
      return minutes === 0 ? '00' : minutes === 15 ? '15' : minutes === 30 ? '30' : '45';
    }
    const now = new Date();
    const currentMinute = now.getMinutes();
    const roundedMinute = Math.floor(currentMinute / 15) * 15;
    return roundedMinute === 0 ? '00' : roundedMinute.toString();
  });

  const [startPeriod, setStartPeriod] = useState<'AM' | 'PM'>(() => {
    if (existingEvent) {
      const startTime = new Date(existingEvent.startTime);
      return startTime.getHours() >= 12 ? 'PM' : 'AM';
    }
    const now = new Date();
    const currentHour = now.getHours();
    const currentMinute = now.getMinutes();
    const roundedMinute = Math.floor(currentMinute / 15) * 15;
    return (roundedMinute > 45 ? (currentHour + 1) : currentHour) >= 12 ? 'PM' : 'AM';
  });

  // Calculate meeting duration in minutes
  const [meetingDuration, setMeetingDuration] = useState(() => {
    if (existingEvent) {
      const startTime = new Date(existingEvent.startTime);
      const endTime = new Date(existingEvent.endTime);
      const durationMinutes = (endTime.getTime() - startTime.getTime()) / (1000 * 60);
      
      // Map to closest standard duration
      if (durationMinutes <= 15) return '15';
      if (durationMinutes <= 30) return '30';
      if (durationMinutes <= 60) return '60';
      if (durationMinutes <= 90) return '90';
      return '120';
    }
    return '60';
  });

  // Get calendar integrations
  const { 
    data: integrations,
    isLoading: isLoadingIntegrations,
    error: integrationsError
  } = useQuery({
    queryKey: ['calendarIntegrations'],
    queryFn: () => CalendarService.getCalendarIntegrations(),
    refetchOnWindowFocus: false,
  });

  // Check if we have any Google Calendar integrations
  const googleIntegrations = integrations?.filter(i => i.provider === 'google' && i.calendarId);

  // Auto-select the first Google Calendar integration when data is loaded
  useEffect(() => {
    if (googleIntegrations?.length && !selectedIntegrationId) {
      setSelectedIntegrationId(googleIntegrations[0].id);
    }
  }, [googleIntegrations, selectedIntegrationId]);
  
  // Fetch existing calendar events for conflict checking
  const { data: calendarEvents } = useQuery<CalendarEvent[]>({
    queryKey: ['calendarEvents', selectedIntegrationId],
    queryFn: async () => {
      if (!selectedIntegrationId) return [];
      return CalendarService.getSyncedEvents(selectedIntegrationId);
    },
    enabled: !!selectedIntegrationId,
  });

  // Filter events for the selected date
  const eventsOnSelectedDate = useMemo(() => {
    if (!calendarEvents || !selectedDate) return [];
    
    const formattedSelectedDate = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth(),
      selectedDate.getDate()
    );
    
    return calendarEvents.filter(event => {
      const eventStartTime = new Date(event.startTime);
      const eventDate = new Date(
        eventStartTime.getFullYear(),
        eventStartTime.getMonth(),
        eventStartTime.getDate()
      );
      
      return compareAsc(eventDate, formattedSelectedDate) === 0;
    });
  }, [calendarEvents, selectedDate]);

  // Check if a time conflicts with existing events
  const isTimeConflicting = (hour: string, minute: string, period: 'AM' | 'PM') => {
    if (!eventsOnSelectedDate.length) return false;
    
    // Convert to 24-hour format
    let selectedHour = parseInt(hour);
    if (period === "PM" && selectedHour !== 12) {
      selectedHour += 12;
    } else if (period === "AM" && selectedHour === 12) {
      selectedHour = 0;
    }
    
    const minutes = parseInt(minute);
    if (isNaN(selectedHour) || isNaN(minutes)) return false;
    
    // Create start and end time for the proposed slot
    const proposedStartTime = set(selectedDate, {
      hours: selectedHour,
      minutes: minutes,
      seconds: 0,
      milliseconds: 0
    });
    
    // Calculate end time based on duration - make sure to parse as int
    const durationMinutes = parseInt(meetingDuration);
    const proposedEndTime = addMinutes(proposedStartTime, durationMinutes);
    
    // Check if it conflicts with any existing event
    // Skip checking against the existing event if we're editing
    return eventsOnSelectedDate.some(event => {
      // Skip checking this event if it's the one we're editing
      if (existingEvent && existingEvent.id === event.id) return false;
      
      const eventStartTime = new Date(event.startTime);
      const eventEndTime = new Date(event.endTime);
      
      // Add a small buffer (1 second) to avoid marking back-to-back meetings as conflicts
      const bufferedEventEndTime = new Date(eventEndTime.getTime() - 1000);
      const bufferedProposedEndTime = new Date(proposedEndTime.getTime() - 1000);
      
      // Check for proper overlap (not just end time = start time of another)
      return (
        // Check if proposed start time is during another event
        (proposedStartTime >= eventStartTime && proposedStartTime < bufferedEventEndTime) ||
        // Check if proposed end time is during another event
        (bufferedProposedEndTime > eventStartTime && bufferedProposedEndTime <= bufferedEventEndTime) ||
        // Check if proposed event completely contains another event
        (proposedStartTime <= eventStartTime && proposedEndTime >= eventEndTime)
      );
    });
  };
  
  // Create or update calendar event
  const { 
    mutate: saveEvent,
    isPending: isSavingEvent
  } = useMutation({
    mutationFn: () => {
      // Calculate start time from component values
      let hours = parseInt(startHour);
      if (startPeriod === "PM" && hours !== 12) {
        hours += 12;
      } else if (startPeriod === "AM" && hours === 12) {
        hours = 0;
      }
      
      const minutes = parseInt(startMinute);
      
      // Create start time by combining date and time
      const startTime = set(selectedDate, {
        hours,
        minutes,
        seconds: 0,
        milliseconds: 0
      });

      // Calculate end time based on duration
      const endTime = addMinutes(startTime, parseInt(meetingDuration));
      
      const eventData = {
        leadId,
        calendarIntegrationId: selectedIntegrationId,
        title: meetingTitle,
        description: meetingNotes,
        startTime: startTime.toISOString(),
        endTime: endTime.toISOString(),
        location: meetingLocation,
        meetingLink: existingEvent?.meetingLink || null,
        outcome: existingEvent?.outcome || null,
      };

      if (existingEvent) {
        // Update existing event
        return CalendarService.updateEvent(existingEvent.id, eventData);
      } else {
        // Create new event
        return CalendarService.createEvent(eventData);
      }
    },
    onSuccess: () => {
      // Always invalidate leadEvents query for the specific lead
      queryClient.invalidateQueries({ queryKey: ['leadEvents', leadId], exact: true });
      
      // Always invalidate calendarEvents query to refresh the dashboard
      queryClient.invalidateQueries({ queryKey: ['calendarEvents'], exact: false });
      
      // If this is an update, also invalidate the specific event query
      if (existingEvent) {
        queryClient.invalidateQueries({ queryKey: ['calendarEvent', existingEvent.id], exact: true });
      }
      
      onScheduled();
    },
    onError: (error: Error) => {
      setError(error.message);
    },
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    
    if (!selectedIntegrationId) {
      setError('Please select a calendar');
      return;
    }
    
    // Check for time conflicts again before submitting
    if (isTimeConflicting(startHour, startMinute, startPeriod)) {
      // Ask for confirmation if there's a conflict
      if (!window.confirm('This time conflicts with an existing event. Do you want to schedule anyway?')) {
        return;
      }
    }
    
    saveEvent();
  };

  return (
    <Card className="p-4 sm:p-6 w-full max-w-md mx-auto">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold flex items-center gap-2">
          <CalendarIcon className="h-5 w-5" />
          {existingEvent ? 'Update Meeting' : 'Schedule Meeting'}
        </h2>
        <Button variant="ghost" size="icon" onClick={onClose}>
          <X className="h-5 w-5" />
        </Button>
      </div>
      
      {error && (
        <Alert variant="destructive" className="mb-4">
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}
      
      <form onSubmit={handleSubmit} className="space-y-4">
        {/* Calendar Selection */}
        <div className="space-y-2">
          <Label htmlFor="calendar">Calendar</Label>
          {isLoadingIntegrations ? (
            <div className="flex items-center gap-2">
              <Loader2 className="h-4 w-4 animate-spin" />
              Loading calendars...
            </div>
          ) : !googleIntegrations?.length ? (
            <div className="text-sm text-amber-600">
              No calendars connected. Please connect a calendar in settings first.
            </div>
          ) : (
            <Select
              value={selectedIntegrationId}
              onValueChange={setSelectedIntegrationId}
              disabled={existingEvent !== undefined}
            >
              <SelectTrigger>
                <SelectValue placeholder="Select a calendar" />
              </SelectTrigger>
              <SelectContent>
                {googleIntegrations.map((integration) => (
                  <SelectItem key={integration.id} value={integration.id}>
                    Google Calendar
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          )}
        </div>
        
        {/* Meeting Title */}
        <div className="space-y-2">
          <Label htmlFor="title">Title</Label>
          <Input
            id="title"
            value={meetingTitle}
            onChange={(e) => setMeetingTitle(e.target.value)}
            required
          />
        </div>
        
        {/* Date and Time */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div className="space-y-2">
            <Label htmlFor="date">Date</Label>
            <Popover>
              <PopoverTrigger asChild>
                <Button
                  id="date"
                  variant={"outline"}
                  className={cn(
                    "w-full pl-3 text-left font-normal",
                    !selectedDate && "text-muted-foreground"
                  )}
                >
                  {selectedDate ? (
                    format(selectedDate, "EEEE, MMMM d, yyyy")
                  ) : (
                    <span>Pick a date</span>
                  )}
                  <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-auto p-0" align="start">
                <Calendar
                  mode="single"
                  selected={selectedDate}
                  onSelect={(date) => date && setSelectedDate(date)}
                  disabled={(date) => date < new Date(new Date().setHours(0, 0, 0, 0))}
                  initialFocus
                />
              </PopoverContent>
            </Popover>
          </div>
          <div className="space-y-2">
            <Label htmlFor="time">Start Time</Label>
            <Popover>
              <PopoverTrigger asChild>
                <Button
                  id="time"
                  variant="outline"
                  className={cn(
                    "w-full flex justify-between",
                    isTimeConflicting(startHour, startMinute, startPeriod) && "border-amber-400 bg-amber-50"
                  )}
                >
                  <div className="flex items-center">
                    <Clock className="mr-2 h-4 w-4" />
                    <span>
                      {startHour}:{startMinute} {startPeriod}
                    </span>
                  </div>
                </Button>
              </PopoverTrigger>
              <PopoverContent className="p-0 w-[280px] sm:w-[350px] md:w-fit" align="start">
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 p-3 sm:p-4">
                  <div>
                    <p className="text-sm font-medium mb-2">Hour</p>
                    <div className="grid grid-cols-4 gap-2">
                      {["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"].map((hour) => {
                        const isConflicting = ["00", "15", "30", "45"].some(minute => 
                          isTimeConflicting(hour, minute, startPeriod)
                        );
                        
                        return (
                          <Button
                            key={hour}
                            type="button"
                            variant={startHour === hour ? "default" : "outline"}
                            className={cn(
                              "h-10 w-10 sm:h-9 sm:w-9 p-0",
                              isConflicting && "bg-gray-100 hover:bg-gray-200 text-gray-500"
                            )}
                            onClick={() => setStartHour(hour)}
                          >
                            {hour}
                          </Button>
                        );
                      })}
                    </div>
                  </div>
                  <div>
                    <p className="text-sm font-medium mb-2">Minute</p>
                    <div className="grid grid-cols-2 gap-2">
                      {["00", "15", "30", "45"].map((minute) => {
                        const isConflicting = isTimeConflicting(
                          startHour, 
                          minute, 
                          startPeriod
                        );
                        
                        return (
                          <Button
                            key={minute}
                            type="button"
                            variant={startMinute === minute ? "default" : "outline"}
                            className={cn(
                              "h-10 w-12 sm:h-9 sm:w-9 p-0",
                              isConflicting && "bg-gray-100 hover:bg-gray-200 text-gray-500"
                            )}
                            onClick={() => setStartMinute(minute)}
                          >
                            {minute}
                          </Button>
                        );
                      })}
                    </div>
                  </div>
                  <div>
                    <p className="text-sm font-medium mb-2">AM/PM</p>
                    <div className="grid grid-cols-2 sm:grid-cols-1 gap-2">
                      {["AM", "PM"].map((period) => (
                        <Button
                          key={period}
                          type="button"
                          variant={startPeriod === period ? "default" : "outline"}
                          className="h-10 sm:h-9"
                          onClick={() => setStartPeriod(period as 'AM' | 'PM')}
                        >
                          {period}
                        </Button>
                      ))}
                    </div>
                  </div>
                </div>
              </PopoverContent>
            </Popover>
            {isTimeConflicting(startHour, startMinute, startPeriod) && (
              <div className="flex items-center gap-2 text-amber-600 text-xs mt-1">
                <AlertCircle className="h-3 w-3 flex-shrink-0" />
                <span>Conflicts with an existing event</span>
              </div>
            )}
          </div>
        </div>
        
        {/* Duration */}
        <div className="space-y-2">
          <Label htmlFor="duration">Duration (minutes)</Label>
          <Select
            value={meetingDuration}
            onValueChange={setMeetingDuration}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select duration" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="15">15 minutes</SelectItem>
              <SelectItem value="30">30 minutes</SelectItem>
              <SelectItem value="60">1 hour</SelectItem>
              <SelectItem value="90">1.5 hours</SelectItem>
              <SelectItem value="120">2 hours</SelectItem>
            </SelectContent>
          </Select>
        </div>
        
        {/* Location */}
        <div className="space-y-2">
          <Label htmlFor="location">Location (optional)</Label>
          <Input
            id="location"
            value={meetingLocation}
            onChange={(e) => setMeetingLocation(e.target.value)}
            placeholder="Virtual or physical location"
          />
        </div>
        
        {/* Notes */}
        <div className="space-y-2">
          <Label htmlFor="notes">Notes (optional)</Label>
          <Textarea
            id="notes"
            value={meetingNotes}
            onChange={(e) => setMeetingNotes(e.target.value)}
            placeholder="Any details or preparation notes"
            rows={3}
          />
          <p className="text-xs text-muted-foreground mt-1">
            {leadName} will be automatically added as an attendee if they have an email address on record.
          </p>
        </div>
        
        {/* Actions */}
        <div className="flex justify-end gap-2 pt-2">
          <Button
            type="button"
            variant="outline"
            onClick={onClose}
            disabled={isSavingEvent}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={isSavingEvent || !googleIntegrations?.length}
          >
            {isSavingEvent ? (
              <>
                <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                {existingEvent ? 'Updating...' : 'Scheduling...'}
              </>
            ) : (
              <>{existingEvent ? 'Update Meeting' : 'Schedule Meeting'}</>
            )}
          </Button>
        </div>
      </form>
    </Card>
  );
} 