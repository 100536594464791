import { CheckCircle } from 'lucide-react';
import { Button } from '../../components/ui/button';
import { PlanAction, Product } from './types';
import { formatPrice, getFeatures, isComingSoon, isPopular } from './utils';

interface PlanCardProps {
  product: Product;
  planAction: PlanAction;
  onSubscribe: (priceId: string) => void;
}

export function PlanCard({ product, planAction, onSubscribe }: PlanCardProps) {
  const defaultPriceId = product.default_price;
  const comingSoon = isComingSoon(product);
  const popular = isPopular(product);
  
  return (
    <div 
      className={`rounded-xl overflow-hidden ${
        planAction === 'current' 
          ? "border-2 border-primary shadow-lg relative" 
          : popular 
          ? "border-2 border-primary shadow-lg relative" 
          : "border border-gray-200"
      }`}
    >
      {popular && planAction !== 'current' && (
        <div className="bg-primary text-white text-center py-1 text-sm font-medium">
          Most Popular
        </div>
      )}
      {planAction === 'current' && (
        <div className="bg-green-600 text-white text-center py-1 text-sm font-medium">
          Current Plan
        </div>
      )}
      <div className="bg-white p-5">
        <h3 className="text-lg font-bold mb-2">{product.name}</h3>
        <div className="flex items-baseline mb-3">
          {product.priceData && (
            <>
              <span className="text-2xl font-bold">
                {formatPrice(product.priceData.unit_amount, product.priceData.currency)}
              </span>
              <span className="text-sm text-gray-600 ml-1">
                /{product.priceData.recurring?.interval}
              </span>
            </>
          )}
        </div>
        <p className="text-sm text-gray-600 mb-4">{product.description}</p>
        <Button 
          className={`w-full text-sm py-1.5 h-auto ${
            planAction === 'current' 
              ? "bg-green-600 hover:bg-green-700" 
              : planAction === 'upgrade' 
                ? "bg-blue-600 hover:bg-blue-700" 
                : planAction === 'downgrade' 
                  ? "bg-orange-600 hover:bg-orange-700" 
                  : popular 
                    ? "" 
                    : "bg-gray-800 hover:bg-gray-700"
          }`}
          onClick={() => onSubscribe(defaultPriceId)}
          disabled={!defaultPriceId || comingSoon || planAction === 'current'}
          variant={comingSoon ? "outline" : "default"}
        >
          {comingSoon 
            ? "Coming Soon" 
            : planAction === 'current' 
              ? "Current Plan" 
              : planAction === 'upgrade' 
                ? "Upgrade Plan" 
                : planAction === 'downgrade' 
                  ? "Downgrade Plan" 
                  : "Subscribe Now"}
        </Button>
      </div>
      <div className="bg-gray-50 p-5">
        <p className="text-sm font-medium mb-3">What's included:</p>
        <ul className="space-y-2">
          {getFeatures(product).map((feature, i) => (
            <li key={i} className="flex items-start gap-2">
              <CheckCircle className="h-4 w-4 text-green-500 shrink-0 mt-0.5" />
              <span className="text-sm" dangerouslySetInnerHTML={{ __html: feature }}></span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
} 