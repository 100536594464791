import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/settings/calendar')({
  beforeLoad: () => {
    throw redirect({
      to: '/integrations', 
      search: { integration: 'google-calendar' },
      replace: true
    });
  }
}); 