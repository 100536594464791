import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useAuthStore } from "@/store/authStore";
import { ChevronUp, Clock } from "lucide-react";
import { useEffect, useState } from "react";

export interface RoadmapItem {
  id: string;
  title: string;
  description: string;
  type: "feature" | "bug" | "improvement" | "other";
  status: "planned" | "in-progress" | "completed";
  votes: number;
  hasVoted: boolean;
  createdAt: string;
}

interface RoadmapListProps {
  items: RoadmapItem[];
  onVote: (id: string) => void;
}

export function RoadmapList({ items, onVote }: RoadmapListProps) {
  const [filter, setFilter] = useState<string>("all");
  const [isMobile, setIsMobile] = useState(false);
  const user = useAuthStore((state) => state.user);

  // Check if we're on mobile
  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth < 640); // sm breakpoint
    };
    
    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);
    
    return () => {
      window.removeEventListener('resize', checkIfMobile);
    };
  }, []);

  const filteredItems = items.filter((item) => {
    if (filter === "all") return true;
    if (filter === "voted" && item.hasVoted) return true;
    if (filter === "planned" && item.status === "planned") return true;
    if (filter === "in-progress" && item.status === "in-progress") return true;
    if (filter === "completed" && item.status === "completed") return true;
    return false;
  });

  // Sort by votes (descending)
  const sortedItems = [...filteredItems].sort((a, b) => b.votes - a.votes);

  const getStatusColor = (status: string) => {
    switch (status) {
      case "planned":
        return "bg-blue-100 text-blue-800";
      case "in-progress":
        return "bg-yellow-100 text-yellow-800";
      case "completed":
        return "bg-green-100 text-green-800";
      default:
        return "bg-gray-100 text-gray-800";
    }
  };

  const getTypeLabel = (type: string) => {
    switch (type) {
      case "feature":
        return "Feature Request";
      case "bug":
        return "Bug Report";
      case "improvement":
        return "Improvement";
      default:
        return "Other";
    }
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  return (
    <div className="space-y-0">
      <Tabs defaultValue="all" onValueChange={setFilter}>
        <TabsList className={`grid w-full ${isMobile ? 'grid-cols-3 gap-1' : 'grid-cols-5'}`}>
          <TabsTrigger value="all" className="text-xs sm:text-sm">All</TabsTrigger>
          <TabsTrigger value="voted" className="text-xs sm:text-sm">My Votes</TabsTrigger>
          <TabsTrigger value="planned" className="text-xs sm:text-sm">Planned</TabsTrigger>
          {isMobile ? null : (
            <TabsTrigger value="in-progress" className="text-xs sm:text-sm">In Progress</TabsTrigger>
          )}
          {isMobile ? null : (
            <TabsTrigger value="completed" className="text-xs sm:text-sm">Completed</TabsTrigger>
          )}
        </TabsList>
        {isMobile && (
          <TabsList className="grid w-full grid-cols-2 mt-1">
            <TabsTrigger value="in-progress" className="text-xs sm:text-sm">In Progress</TabsTrigger>
            <TabsTrigger value="completed" className="text-xs sm:text-sm">Completed</TabsTrigger>
          </TabsList>
        )}
      </Tabs>

      {sortedItems.length === 0 ? (
        <div className="text-center py-12">
          <p className="text-gray-500">No items found</p>
        </div>
      ) : (
        <ScrollArea className="h-[calc(100vh-300px)]">
          <div className="grid gap-4 mt-4">
            {sortedItems.map((item) => (
              <Card key={item.id} className="overflow-hidden">
                <div className="flex flex-col sm:flex-row">
                  <div className="p-3 sm:p-4 flex flex-row sm:flex-col items-center justify-between sm:justify-center bg-gray-50 border-b sm:border-r sm:border-b-0">
                    <div className="flex items-center gap-2 sm:flex-col">
                      <Button
                        variant="ghost"
                        size="sm"
                        className={`flex flex-col items-center p-1 sm:p-2 ${
                          item.hasVoted ? "text-primary" : "text-gray-500"
                        }`}
                        onClick={() => onVote(item.id)}
                        disabled={item.status === "completed"}
                        title={item.hasVoted ? "Remove vote" : "Vote for this item"}
                      >
                        <ChevronUp className="h-4 w-4 sm:h-5 sm:w-5" />
                        <span className="text-xs sm:text-sm font-bold">{item.votes}</span>
                      </Button>
                    </div>
                    <div className="flex sm:hidden items-center gap-1">
                      <Badge className={getStatusColor(item.status)}>
                        {item.status.charAt(0).toUpperCase() + item.status.slice(1).replace("-", " ")}
                      </Badge>
                    </div>
                  </div>
                  <div className="flex-1">
                    <CardHeader className="pb-2">
                      <div className="flex flex-col sm:flex-row sm:justify-between sm:items-start gap-2">
                        <div>
                          <CardTitle className="text-base sm:text-lg">{item.title}</CardTitle>
                          <CardDescription className="flex items-center mt-1 text-xs">
                            <Clock className="h-3 w-3 mr-1" />
                            {formatDate(item.createdAt)}
                          </CardDescription>
                        </div>
                        <div className="hidden sm:flex gap-2">
                          <Badge variant="outline">{getTypeLabel(item.type)}</Badge>
                          <Badge className={getStatusColor(item.status)}>
                            {item.status.charAt(0).toUpperCase() + item.status.slice(1).replace("-", " ")}
                          </Badge>
                        </div>
                      </div>
                    </CardHeader>
                    <CardContent className="pb-4">
                      <div className="flex sm:hidden mb-2">
                        <Badge variant="outline">{getTypeLabel(item.type)}</Badge>
                      </div>
                      <p className="text-xs sm:text-sm text-gray-600">{item.description}</p>
                    </CardContent>
                  </div>
                </div>
              </Card>
            ))}
          </div>
        </ScrollArea>
      )}
    </div>
  );
}