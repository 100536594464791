import { ArticleLayout } from "@/components/help/ArticleLayout";

export function GettingStartedArticle() {
  return (
    <ArticleLayout>
      <h1 className="text-2xl font-bold mb-4 mt-0">Getting Started with LeadGPT</h1>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Welcome to LeadGPT!</h2>
      <p className="mb-4">
        LeadGPT is a powerful AI-driven platform designed to help you streamline your lead management and conversion process. This guide will help you get up and running quickly with your new LeadGPT account.
      </p>
      
      <h2 className="text-xl font-bold mt-8 mb-4">What is LeadGPT?</h2>
      <p className="mb-4">
        LeadGPT is a comprehensive lead management system that combines AI technology with powerful communication tools to help you:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1">Capture and organize leads efficiently</li>
        <li className="mb-1">Automate follow-ups and administrative tasks</li>
        <li className="mb-1">Analyze conversations for insights and coaching opportunities</li>
        <li className="mb-1">Score leads based on interest and buying signals</li>
        <li className="mb-1">Never miss a lead with the AI Receptionist</li>
        <li className="mb-1">Track team performance and optimize your sales process</li>
      </ul>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Five-Minute Setup Guide</h2>
      <p className="mb-4">
        Get started with LeadGPT quickly by following these essential steps:
      </p>
      <ol className="list-decimal pl-6 mb-4">
        <li className="mb-1">
          <strong>Complete your profile:</strong> Click your avatar in the top-right corner, select "My Account," and fill in your basic information
        </li>
        <li className="mb-1">
          <strong>Set up your business details:</strong> Go to Settings {'>'} Business to configure your company information
        </li>
        <li className="mb-1">
          <strong>Configure your AI Receptionist:</strong> Navigate to the Receptionist section and follow the setup wizard
        </li>
        <li className="mb-1">
          <strong>Import or add your first leads:</strong> Go to the Leads section and use the "Add Lead" or "Import Leads" option
        </li>
        <li className="mb-1">
          <strong>Connect your calendar:</strong> Go to Settings {'>'} Integrations to connect your Google or Outlook calendar
        </li>
      </ol>
      <p className="mb-4">
        With these five steps completed, you'll be ready to start managing leads more effectively immediately.
      </p>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Navigating the Interface</h2>
      <p className="mb-4">
        LeadGPT features a clean, intuitive interface designed for efficiency:
      </p>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Sidebar Navigation</h3>
      <p className="mb-4">
        The left sidebar provides access to all major sections:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Dashboard:</strong> Performance overview and action center</li>
        <li className="mb-1"><strong>Leads:</strong> Your complete lead database and management tools</li>
        <li className="mb-1"><strong>Receptionist:</strong> Configure your AI assistant for handling calls</li>
        <li className="mb-1"><strong>Analytics:</strong> Detailed reports and performance insights</li>
        <li className="mb-1"><strong>Team:</strong> Manage team members and track performance</li>
        <li className="mb-1"><strong>Settings:</strong> Configure your account and system preferences</li>
        <li className="mb-1"><strong>Help Center:</strong> Access guides and tutorials</li>
      </ul>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Top Navigation Bar</h3>
      <p className="mb-4">
        The top bar contains:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Quick Add Button:</strong> Quickly add new leads or activities</li>
        <li className="mb-1"><strong>Search Bar:</strong> Search across your entire account</li>
        <li className="mb-1"><strong>Notifications:</strong> View system notifications and alerts</li>
        <li className="mb-1"><strong>User Menu:</strong> Access your profile, account settings, and logout</li>
      </ul>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Key Features Overview</h2>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Dashboard</h3>
      <p className="mb-4">
        Your Dashboard provides:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Key Performance Metrics:</strong> Lead metrics, communication stats, and team comparisons</li>
        <li className="mb-1"><strong>Action Center:</strong> Upcoming tasks, follow-ups, and action items</li>
        <li className="mb-1"><strong>Activity Feed:</strong> Recent interactions with leads</li>
        <li className="mb-1"><strong>Time Frame Selector:</strong> Filter data for different time periods</li>
      </ul>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Lead Management</h3>
      <p className="mb-4">
        The Leads section offers:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Lead List:</strong> View, filter, and sort your leads</li>
        <li className="mb-1"><strong>Lead Details:</strong> Comprehensive lead information and communication history</li>
        <li className="mb-1"><strong>Communication Pane:</strong> Call, message, and view communication history (select a lead first, then use the communication pane on the right)</li>
        <li className="mb-1"><strong>Pipeline View:</strong> Track leads through your sales stages</li>
        <li className="mb-1"><strong>Lead Scoring:</strong> AI-powered scoring to prioritize your leads</li>
      </ul>
      
      <h3 className="text-lg font-bold mt-6 mb-3">AI Receptionist</h3>
      <p className="mb-4">
        Your AI-powered assistant can:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Answer Calls:</strong> Respond when you're unavailable</li>
        <li className="mb-1"><strong>Qualify Leads:</strong> Gather key information from callers</li>
        <li className="mb-1"><strong>Schedule Meetings:</strong> Book time on your calendar</li>
        <li className="mb-1"><strong>Answer Questions:</strong> Provide information about your business</li>
        <li className="mb-1"><strong>Create Lead Records:</strong> Automatically add new leads to the system</li>
      </ul>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Analytics</h3>
      <p className="mb-4">
        The Analytics section provides:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Sales Pipeline Visualization:</strong> Visual representation of your sales funnel</li>
        <li className="mb-1"><strong>Conversion Metrics:</strong> Track lead-to-customer conversion rates</li>
        <li className="mb-1"><strong>Activity Analysis:</strong> Measure call and message effectiveness</li>
        <li className="mb-1"><strong>Source Performance:</strong> Identify which lead sources perform best</li>
        <li className="mb-1"><strong>Custom Reports:</strong> Build reports tailored to your specific needs</li>
      </ul>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Daily Workflow Recommendations</h2>
      <p className="mb-4">
        For optimal results with LeadGPT, we recommend this daily routine:
      </p>
      <ol className="list-decimal pl-6 mb-4">
        <li className="mb-1">
          <strong>Morning:</strong> Check your Dashboard for an overview of the day's priorities and upcoming tasks
        </li>
        <li className="mb-1">
          <strong>Follow-ups:</strong> Address action items in your Action Center, prioritizing high-value leads
        </li>
        <li className="mb-1">
          <strong>New Leads:</strong> Review and respond to new leads captured by your AI Receptionist
        </li>
        <li className="mb-1">
          <strong>Afternoon Check-in:</strong> Review communication metrics and make any necessary adjustments
        </li>
        <li className="mb-1">
          <strong>End of Day:</strong> Schedule tasks for tomorrow and review your performance metrics
        </li>
      </ol>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Next Steps After Setup</h2>
      <p className="mb-4">
        Once you've completed the initial setup, consider these next steps:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1">
          <strong>Customize Your Pipeline:</strong> Go to Settings {'>'} Lead Management to configure your sales stages
        </li>
        <li className="mb-1">
          <strong>Set Up Team Members:</strong> Invite your team if you have a multi-user account
        </li>
        <li className="mb-1">
          <strong>Connect Integrations:</strong> Link LeadGPT with your other business tools
        </li>
        <li className="mb-1">
          <strong>Create Email Templates:</strong> Build reusable email templates for common scenarios
        </li>
        <li className="mb-1">
          <strong>Explore Advanced Features:</strong> Discover automation rules, custom fields, and more
        </li>
      </ul>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Getting Help</h2>
      <p className="mb-4">
        If you need assistance with LeadGPT:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1">
          <strong>Help Center:</strong> Browse more detailed articles on specific features
        </li>
        <li className="mb-1">
          <strong>Live Chat Support:</strong> Click the chat icon in the bottom-right corner to speak with our support team
        </li>
        <li className="mb-1">
          <strong>Video Tutorials:</strong> Access our library of how-to videos through the Help Center
        </li>
        <li className="mb-1">
          <strong>Scheduled Training:</strong> Enterprise customers can book personalized training sessions
        </li>
      </ul>
      
      <p className="mb-4 mt-8">
        Welcome aboard! By following this guide, you'll be well on your way to maximizing the value of LeadGPT 
        and improving your lead conversion rates. Remember that the system becomes more powerful as you use it, 
        with AI features adapting to your specific sales process over time.
      </p>
    </ArticleLayout>
  );
} 