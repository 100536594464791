import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { useWebSocket } from '@/contexts/WebSocketContext';
import { api, CompletedActionItem } from '@/lib/api';
import { cn } from '@/lib/utils';
import { SocketEvents } from '@/services/websocket.service';
import { Conversation, Lead } from '@/types';
import { useQueryClient } from '@tanstack/react-query';
import { CheckCheck, CheckCircle2, Clock, Loader2, MessageSquare, Sparkles } from 'lucide-react';
import { useEffect, useState } from 'react';
import { toast } from 'sonner';
import { formFieldClasses } from './utils';

interface ActionItemsSectionProps {
  formData: Partial<Lead>;
}

export function ActionItemsSection({ formData }: ActionItemsSectionProps) {
  const [completedItems, setCompletedItems] = useState<Record<string, boolean>>({});
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isMarkingAll, setIsMarkingAll] = useState(false);
  const [animatingItems, setAnimatingItems] = useState<Record<string, boolean>>({});
  const queryClient = useQueryClient();
  const { on } = useWebSocket();
  
  // Listen for call status changes to refresh action items data
  useEffect(() => {
    if (!formData.id) return;

    const handleCallStatusChange = (event: CustomEvent) => {
      const { status, leadId: eventLeadId } = event.detail;
      
      // Only respond to events for this lead and when analysis is complete
      if (eventLeadId === formData.id && status === 'analyzed') {
        console.log('[ActionItemsSection] Call analysis completed, refreshing action items data');
        queryClient.invalidateQueries({ queryKey: ['lead', formData.id] });
        
        // Also invalidate dashboard action items to keep them in sync
        queryClient.invalidateQueries({ queryKey: ['dashboard', 'action-items'] });
      }
    };

    // Add event listener
    window.addEventListener('call-status-change', handleCallStatusChange as EventListener);
    
    // Clean up
    return () => {
      window.removeEventListener('call-status-change', handleCallStatusChange as EventListener);
    };
  }, [formData.id, queryClient]);
  
  // Listen for SMS analysis completion events
  useEffect(() => {
    if (!formData.id) return;
    
    const unsubscribeAnalysis = on<{
      conversationId: number;
      messageId?: number;
      messageCount?: number;
      userId: number;
    }>(SocketEvents.SMS_ANALYSIS_COMPLETED, (data) => {
      // The event doesn't directly contain the leadId, but it's related to a conversation
      // We refresh the lead data when we receive any SMS analysis event to ensure we have the latest action items
      console.log('[ActionItemsSection] SMS analysis completed, refreshing action items data');
      queryClient.invalidateQueries({ queryKey: ['lead', formData.id] });
      
      // Also invalidate dashboard action items to keep them in sync
      queryClient.invalidateQueries({ queryKey: ['dashboard', 'action-items'] });
    });
    
    return () => {
      unsubscribeAnalysis();
    };
  }, [formData.id, queryClient, on]);
  
  // Extract action items from conversation state
  const actionItems: { text: string; source: string; date: Date; icon: JSX.Element }[] = [];
  
  // Find the most recent conversation
  const conversations = formData.conversations as Conversation[] | undefined;
  const latestConversation = conversations && conversations.length > 0 ? conversations[0] : undefined;
  
  // Get action items from conversation state
  if (latestConversation?.state?.actionItems) {
    latestConversation.state.actionItems.forEach((item: string) => {
      // Skip empty items
      if (!item || item.trim() === '') return;
      
      actionItems.push({
        text: item,
        source: 'SMS', // Default to SMS since these come from conversation state
        date: new Date(latestConversation.lastInteractionAt || latestConversation.updatedAt || new Date()),
        icon: <MessageSquare className="h-3 w-3 text-green-500" />
      });
    });
  }
  
  // Sort action items by date (newest first)
  actionItems.sort((a, b) => b.date.getTime() - a.date.getTime());
  
  // Debug log to inspect action items
  console.log('Action items:', actionItems);
  console.log('Conversation state:', latestConversation?.state);
  
  // Add debug logging for when conversation data changes
  useEffect(() => {
    if (conversations && conversations.length > 0) {
      console.log('[ActionItemsSection] Received updated conversation data:', 
        conversations[0].id, 
        conversations[0].lastInteractionAt,
        conversations[0].state?.actionItems?.length || 0
      );
    }
  }, [conversations]);
  
  // Load completed items when lead changes
  useEffect(() => {
    if (!formData.id) {
      setIsInitialLoading(false);
      return;
    }
    
    const fetchCompletedItems = async () => {
      try {
        const response = await api.actionItems.getCompleted(formData.id as number);
        if (response.success) {
          const completed: Record<string, boolean> = {};
          response.items.forEach((item: CompletedActionItem) => {
            completed[item.itemText] = true;
          });
          setCompletedItems(completed);
        }
      } catch (error) {
        console.error('Error fetching completed items:', error);
      } finally {
        setIsInitialLoading(false);
      }
    };
    
    fetchCompletedItems();
  }, [formData.id]);
  
  const toggleItemCompletion = async (item: { text: string; source: string; date: Date }) => {
    if (!formData.id) return;
    
    const isCompleted = completedItems[item.text];
    
    // Set animating state
    setAnimatingItems(prev => ({ ...prev, [item.text]: true }));
    
    // Optimistically update UI
    setCompletedItems(prev => ({
      ...prev,
      [item.text]: !isCompleted
    }));
    
    try {
      if (isCompleted) {
        // Mark as incomplete
        await api.actionItems.markIncomplete(formData.id as number, item.text);
      } else {
        // Mark as complete
        await api.actionItems.markComplete(formData.id as number, {
          itemText: item.text,
          source: item.source,
          sourceDate: item.date.toISOString()
        });
      }
      
      // Invalidate lead query to refresh data
      queryClient.invalidateQueries({ queryKey: ['lead', formData.id] });
      queryClient.invalidateQueries({ queryKey: ['dashboard', 'action-items'] });
      
    } catch (error) {
      // Revert on error
      setCompletedItems(prev => ({
        ...prev,
        [item.text]: isCompleted
      }));
      toast.error(`Failed to mark item as ${isCompleted ? 'incomplete' : 'complete'}`);
    } finally {
      // Remove animating state after a short delay
      setTimeout(() => {
        setAnimatingItems(prev => ({ ...prev, [item.text]: false }));
      }, 300);
    }
  };
  
  const allCompleted = actionItems.length > 0 && 
    actionItems.every(item => completedItems[item.text]);
  
  const markAllCompleted = async () => {
    if (!formData.id) return;
    
    // Create a filtered list of incomplete action items
    const incompleteItems = actionItems.filter(item => !completedItems[item.text]);
    
    if (incompleteItems.length === 0) {
      toast.info('All action items are already completed');
      return;
    }
    
    try {
      setIsLoading(true);
      setIsMarkingAll(true);
      
      // Set all items as animating
      const animating: Record<string, boolean> = {};
      incompleteItems.forEach(item => {
        animating[item.text] = true;
      });
      setAnimatingItems(prev => ({ ...prev, ...animating }));
      
      // Optimistically update UI
      const newCompleted: Record<string, boolean> = { ...completedItems };
      incompleteItems.forEach(item => {
        newCompleted[item.text] = true;
      });
      setCompletedItems(newCompleted);
      
      await api.actionItems.markAllComplete(formData.id, incompleteItems.map(item => ({
        itemText: item.text,
        source: item.source,
        sourceDate: item.date.toISOString()
      })));
      
      // Refresh the completed items
      await queryClient.invalidateQueries({ queryKey: ['lead', formData.id] });
      
      toast.success(`${incompleteItems.length} action item${incompleteItems.length > 1 ? 's' : ''} marked as complete`);
    } catch (error) {
      console.error('Failed to mark all items as completed:', error);
      
      // Only revert items that weren't already completed
      const revertedItems: Record<string, boolean> = { ...completedItems };
      incompleteItems.forEach(item => {
        revertedItems[item.text] = false;
      });
      setCompletedItems(revertedItems);
    } finally {
      setIsLoading(false);
      setIsMarkingAll(false);
      
      // Remove animating state after a short delay
      setTimeout(() => {
        const resetAnimating: Record<string, boolean> = {};
        incompleteItems.forEach(item => {
          resetAnimating[item.text] = false;
        });
        setAnimatingItems(prev => ({ ...prev, ...resetAnimating }));
      }, 300);
    }
  };
  
  // If there are no action items, show a placeholder
  if (actionItems.length === 0) {
    return (
      <div className={formFieldClasses.section}>
        <div className="space-y-3">
          <div className="flex items-center gap-2 mb-2">
            <Sparkles className="h-4 w-4 text-indigo-500" />
            <h3 className="text-sm font-medium">AI Action Items</h3>
          </div>
          <div className="text-center py-4 text-sm text-muted-foreground">
            No AI action items available. Action items will appear here after calls or SMS messages are analyzed.
          </div>
        </div>
      </div>
    );
  }
  
  if (isInitialLoading) {
    return (
      <div className={formFieldClasses.section}>
        <div className="space-y-3">
          <div className="flex items-center gap-2 mb-2">
            <Sparkles className="h-4 w-4 text-indigo-500" />
            <h3 className="text-sm font-medium">AI Action Items</h3>
          </div>
          <div className="space-y-2">
            {actionItems.map((_, i) => (
              <div 
                key={i}
                className="flex items-start gap-2 p-2 rounded-md text-sm bg-muted/30 animate-pulse"
              >
                <div className="h-4 w-4 mt-0.5 rounded-sm bg-muted"></div>
                <div className="flex-1 space-y-2">
                  <div className="h-4 bg-muted rounded w-3/4"></div>
                  <div className="flex items-center gap-2">
                    <div className="h-3 bg-muted rounded w-16"></div>
                    <div className="h-3 bg-muted rounded w-12"></div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
  
  if (isLoading) {
    return (
      <div className={formFieldClasses.section}>
        <div className="space-y-3">
          <div className="flex items-center gap-2 mb-2">
            <Sparkles className="h-4 w-4 text-indigo-500" />
            <h3 className="text-sm font-medium">AI Action Items</h3>
          </div>
          <div className="space-y-2">
            {[1, 2, 3].map((i) => (
              <div 
                key={i}
                className="flex items-start gap-2 p-2 rounded-md text-sm bg-muted/30 animate-pulse"
              >
                <div className="h-4 w-4 mt-0.5 rounded-sm bg-muted"></div>
                <div className="flex-1 space-y-2">
                  <div className="h-4 bg-muted rounded w-3/4"></div>
                  <div className="flex items-center gap-2">
                    <div className="h-3 bg-muted rounded w-16"></div>
                    <div className="h-3 bg-muted rounded w-12"></div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
  
  return (
    <TooltipProvider>
      <div className={formFieldClasses.section}>
        <div className="space-y-3">
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center gap-2">
              <Sparkles className="h-4 w-4 text-indigo-500" />
              <h3 className="text-sm font-medium">AI Action Items</h3>
            </div>
            <Button 
              variant="ghost" 
              size="sm" 
              className="h-7 text-xs"
              onClick={markAllCompleted}
              disabled={allCompleted || isMarkingAll}
            >
              {isMarkingAll ? (
                <Loader2 className="h-3 w-3 animate-spin mr-1" />
              ) : (
                <CheckCheck className="h-3 w-3 mr-1" />
              )}
              Mark all complete
            </Button>
          </div>
          
          <div className="space-y-2">
            {actionItems.map((item, index) => (
              <div 
                key={index} 
                className={cn(
                  "flex items-start gap-2 p-2 rounded-md text-sm cursor-pointer transition-all duration-200",
                  completedItems[item.text] 
                    ? "bg-muted/30 text-muted-foreground hover:bg-muted/40" 
                    : "bg-muted/50 hover:bg-muted/60",
                  "focus:outline-none focus:ring-2 focus:ring-primary/50 focus:ring-offset-1",
                  animatingItems[item.text] && (completedItems[item.text] 
                    ? "scale-[0.98] bg-green-100/10 dark:bg-green-900/10" 
                    : "scale-[0.98] bg-muted/70")
                )}
                onClick={() => toggleItemCompletion(item)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    e.preventDefault();
                    toggleItemCompletion(item);
                  }
                }}
                tabIndex={0}
                role="checkbox"
                aria-checked={completedItems[item.text] || false}
              >
                <Checkbox 
                  checked={completedItems[item.text] || false}
                  onCheckedChange={() => toggleItemCompletion(item)}
                  className={cn(
                    "mt-0.5",
                    animatingItems[item.text] && "scale-110"
                  )}
                  onClick={(e) => e.stopPropagation()} // Prevent double triggering when clicking checkbox directly
                />
                <div className="flex-1">
                  {item.text.length > 100 ? (
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <p className={cn(
                          "line-clamp-2",
                          completedItems[item.text] && "line-through text-muted-foreground"
                        )}>
                          {item.text}
                        </p>
                      </TooltipTrigger>
                      <TooltipContent className="max-w-md">
                        <p>{item.text}</p>
                      </TooltipContent>
                    </Tooltip>
                  ) : (
                    <p className={cn(
                      completedItems[item.text] && "line-through text-muted-foreground"
                    )}>
                      {item.text}
                    </p>
                  )}
                  <div className="flex items-center gap-2 mt-1 text-xs text-muted-foreground">
                    <span className="flex items-center gap-1">
                      <Clock className="h-3 w-3" />
                      {item.date instanceof Date && !isNaN(item.date.getTime()) 
                        ? item.date.toLocaleDateString(undefined, { 
                            year: 'numeric', 
                            month: 'short', 
                            day: 'numeric' 
                          }) 
                        : 'Invalid date'}
                    </span>
                    <span className="flex items-center gap-1">
                      <span className="h-1 w-1 rounded-full bg-muted-foreground"></span>
                      {item.icon}
                      {item.source}
                    </span>
                    {completedItems[item.text] && (
                      <span className="flex items-center gap-1">
                        <span className="h-1 w-1 rounded-full bg-muted-foreground"></span>
                        <CheckCircle2 className={cn(
                          "h-3 w-3 text-green-500",
                          animatingItems[item.text] && "scale-110"
                        )} />
                        Completed
                      </span>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </TooltipProvider>
  );
} 