import { Button } from '@/components/ui/button';
import {
  Command,
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandList,
  CommandSeparator
} from '@/components/ui/command';
import { useDebounce } from '@/hooks/useDebounce';
import { axiosInstance } from '@/lib/api';
import { useLeadStore } from '@/store/leadStore';
import { useNavigate } from '@tanstack/react-router';
import { Briefcase, Calendar, FileText, Loader2, Search, User } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';

interface SearchResult {
  id: string;
  title: string;
  description?: string;
  type: 'lead' | 'task' | 'appointment' | 'document';
  leadId?: number; // Add leadId for lead navigation
  url: string;
  metadata?: Record<string, any>; // Add metadata field
}

interface SearchResponse {
  leads: SearchResult[];
  tasks: SearchResult[];
  appointments: SearchResult[];
  documents: SearchResult[];
}

interface GlobalSearchProps {
  className?: string;
}

export function GlobalSearch({ className }: GlobalSearchProps) {
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 300);
  const [results, setResults] = useState<SearchResponse>({
    leads: [],
    tasks: [],
    appointments: [],
    documents: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);
  const { setSelectedLead } = useLeadStore();

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setOpen((open) => !open);
      }
    };

    document.addEventListener('keydown', down);
    return () => document.removeEventListener('keydown', down);
  }, []);

  // Fetch search results when the debounced query changes
  useEffect(() => {
    if (!debouncedQuery) {
      setResults({
        leads: [],
        tasks: [],
        appointments: [],
        documents: [],
      });
      setIsLoading(false);
      setError(null);
      return;
    }

    const fetchSearchResults = async () => {
      setIsLoading(true);
      setError(null);
      
      try {
        console.log('Fetching search results for:', debouncedQuery);
        const response = await axiosInstance.get<SearchResponse>(`/search?query=${encodeURIComponent(debouncedQuery)}`);
        console.log('Search API response:', response.data);
        
        // Ensure we're getting the expected structure
        if (response.data && typeof response.data === 'object') {
          const searchData: SearchResponse = {
            leads: Array.isArray(response.data.leads) ? response.data.leads : [],
            tasks: Array.isArray(response.data.tasks) ? response.data.tasks : [],
            appointments: Array.isArray(response.data.appointments) ? response.data.appointments : [],
            documents: Array.isArray(response.data.documents) ? response.data.documents : [],
          };
          
          console.log('Normalized search data:', searchData);
          setResults(searchData);
        } else {
          console.error('Invalid search response format:', response.data);
          setError('Invalid search response format');
          setResults({
            leads: [],
            tasks: [],
            appointments: [],
            documents: [],
          });
        }
      } catch (err) {
        console.error('Error fetching search results:', err);
        setError('Failed to fetch search results. Please try again.');
        setResults({
          leads: [],
          tasks: [],
          appointments: [],
          documents: [],
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchSearchResults();
  }, [debouncedQuery]);

  const handleSelect = (item: SearchResult) => {
    console.log('Item selected:', item);
    setOpen(false);
    
    // Use setTimeout to ensure the dialog is closed before navigation
    setTimeout(() => {
      // For leads, use the leadId to update the lead store and navigate
      if (item.type === 'lead' && item.leadId) {
        // Update the lead store with the selected lead ID
        setSelectedLead({ id: item.leadId });
        
        // Navigate to the leads page
        navigate({ to: '/leads' });
        console.log(`[GlobalSearch] Navigated to lead ID: ${item.leadId}`);
      } else {
        // For other types, use the URL directly
        navigate({ to: item.url });
      }
    }, 50);
  };

  const getIcon = (type: string) => {
    switch (type) {
      case 'lead':
        return <User className="mr-2 h-4 w-4" />;
      case 'task':
        return <Briefcase className="mr-2 h-4 w-4" />;
      case 'appointment':
        return <Calendar className="mr-2 h-4 w-4" />;
      case 'document':
        return <FileText className="mr-2 h-4 w-4" />;
      default:
        return null;
    }
  };

  const hasResults = Object.values(results).some(group => Array.isArray(group) && group.length > 0);
  
  // Focus the input when the dialog opens
  useEffect(() => {
    if (open && inputRef.current) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
  }, [open]);

  return (
    <div className={className}>
      <Button
        variant="outline"
        className="relative h-9 w-full justify-start rounded-[0.5rem] bg-background text-sm font-normal text-muted-foreground shadow-none sm:pr-12 md:w-40 lg:w-64"
        onClick={() => {
          setOpen(true);
          // Clear any previous search when opening
          if (!open) {
            setQuery('');
            setResults({
              leads: [],
              tasks: [],
              appointments: [],
              documents: [],
            });
          }
        }}
      >
        <span className="inline-flex">
          <Search className="mr-3 mt-0.5 h-4 w-4" />
          Search...
        </span>
        <kbd className="pointer-events-none absolute right-1.5 top-1.5 hidden h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium opacity-100 sm:flex">
          <span className="text-xs">⌘</span>K
        </kbd>
      </Button>
      <CommandDialog 
        open={open} 
        onOpenChange={(isOpen) => {
          console.log('CommandDialog onOpenChange:', isOpen);
          // If closing, reset the state
          if (!isOpen) {
            setQuery('');
            setResults({
              leads: [],
              tasks: [],
              appointments: [],
              documents: [],
            });
          }
          setOpen(isOpen);
        }}
      >
        <Command filter={() => 1} shouldFilter={false}>
          <div className="flex items-center px-3">
            <Search className="mr-2 h-4 w-4 shrink-0 opacity-50" />
            <input
              ref={inputRef}
              placeholder="Search for anything..."
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              className="flex h-10 w-full rounded-md bg-transparent py-3 text-sm outline-none placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50"
              autoFocus
            />
          </div>
          <CommandList className="max-h-[500px] overflow-y-auto">
            {isLoading && (
              <div className="py-6 text-center">
                <Loader2 className="h-6 w-6 animate-spin mx-auto text-muted-foreground" />
                <p className="text-sm text-muted-foreground mt-2">Searching...</p>
              </div>
            )}
            
            {error && !isLoading && (
              <div className="py-6 text-center">
                <p className="text-sm text-destructive">{error}</p>
                <Button 
                  variant="link" 
                  size="sm" 
                  className="mt-2"
                  onClick={() => {
                    setError(null);
                    if (debouncedQuery) {
                      setQuery(debouncedQuery); // Retry the search
                    }
                  }}
                >
                  Retry
                </Button>
              </div>
            )}
            
            {!isLoading && !error && !hasResults && query && (
              <CommandEmpty>No results found for "{query}"</CommandEmpty>
            )}
            
            {/* Render leads results */}
            {!isLoading && !error && results.leads && results.leads.length > 0 && (
              <CommandGroup heading="Leads">
                {results.leads.map((lead) => (
                  <CommandItem
                    key={lead.id}
                    onSelect={() => {
                      console.log('Lead selected:', lead);
                      handleSelect(lead);
                    }}
                    className="flex items-center cursor-pointer"
                    value={`lead-${lead.id}-${lead.title}`}
                  >
                    {getIcon(lead.type)}
                    <div>
                      <p>{lead.title}</p>
                      {lead.description && (
                        <p className="text-xs text-muted-foreground">{lead.description}</p>
                      )}
                      {/* Display metadata if available */}
                      {lead.metadata && Object.keys(lead.metadata).length > 0 && (
                        <p className="text-xs text-muted-foreground">
                          {Object.entries(lead.metadata)
                            .map(([key, value]) => `${key}: ${typeof value === 'object' && value.value ? value.value : value}`)
                            .join(' • ')}
                        </p>
                      )}
                    </div>
                  </CommandItem>
                ))}
              </CommandGroup>
            )}

            {/* Render tasks results */}
            {!isLoading && !error && results.tasks && results.tasks.length > 0 && (
              <>
                {results.leads.length > 0 && <CommandSeparator />}
                <CommandGroup heading="Tasks">
                  {results.tasks.map((task) => (
                    <CommandItem
                      key={task.id}
                      onSelect={() => {
                        console.log('Task selected:', task);
                        handleSelect(task);
                      }}
                      className="flex items-center cursor-pointer"
                      value={`task-${task.id}-${task.title}`}
                    >
                      {getIcon(task.type)}
                      <div>
                        <p>{task.title}</p>
                        {task.description && (
                          <p className="text-xs text-muted-foreground">{task.description}</p>
                        )}
                      </div>
                    </CommandItem>
                  ))}
                </CommandGroup>
              </>
            )}

            {/* Render appointments results */}
            {!isLoading && !error && results.appointments && results.appointments.length > 0 && (
              <>
                {(results.leads.length > 0 || results.tasks.length > 0) && <CommandSeparator />}
                <CommandGroup heading="Appointments">
                  {results.appointments.map((appointment) => (
                    <CommandItem
                      key={appointment.id}
                      onSelect={() => {
                        console.log('Appointment selected:', appointment);
                        handleSelect(appointment);
                      }}
                      className="flex items-center cursor-pointer"
                      value={`appointment-${appointment.id}-${appointment.title}`}
                    >
                      {getIcon(appointment.type)}
                      <div>
                        <p>{appointment.title}</p>
                        {appointment.description && (
                          <p className="text-xs text-muted-foreground">{appointment.description}</p>
                        )}
                      </div>
                    </CommandItem>
                  ))}
                </CommandGroup>
              </>
            )}

            {/* Render documents results */}
            {!isLoading && !error && results.documents && results.documents.length > 0 && (
              <>
                {(results.leads.length > 0 || results.tasks.length > 0 || results.appointments.length > 0) && <CommandSeparator />}
                <CommandGroup heading="Documents">
                  {results.documents.map((document) => (
                    <CommandItem
                      key={document.id}
                      onSelect={() => {
                        console.log('Document selected:', document);
                        handleSelect(document);
                      }}
                      className="flex items-center cursor-pointer"
                      value={`document-${document.id}-${document.title}`}
                    >
                      {getIcon(document.type)}
                      <div>
                        <p>{document.title}</p>
                        {document.description && (
                          <p className="text-xs text-muted-foreground">{document.description}</p>
                        )}
                      </div>
                    </CommandItem>
                  ))}
                </CommandGroup>
              </>
            )}
          </CommandList>
        </Command>
      </CommandDialog>
    </div>
  );
} 