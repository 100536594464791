import { ArticleLayout } from "@/components/help/ArticleLayout";

export function SettingsConfigurationArticle() {
  return (
    <ArticleLayout>
      <h1 className="text-2xl font-bold mb-4 mt-0">Settings and Configuration</h1>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Overview</h2>
      <p className="mb-4">
        LeadGPT offers extensive customization options to tailor the platform to your specific business needs. This guide will walk you through all available settings and help you configure your workspace optimally.
      </p>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Accessing Settings</h2>
      <p className="mb-4">
        To access the settings area:
      </p>
      <ol className="list-decimal pl-6 mb-4">
        <li className="mb-1">Click on the "Settings" option in the sidebar navigation</li>
        <li className="mb-1">This will take you to the Settings page, which is organized into several tabs for different types of settings</li>
      </ol>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Profile Settings</h2>
      <h3 className="text-lg font-bold mt-6 mb-3">Personal Information</h3>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Profile Photo:</strong> Upload a professional photo</li>
        <li className="mb-1"><strong>Name:</strong> Update your name as it appears to team members and in communications</li>
        <li className="mb-1"><strong>Email:</strong> Update your email address (requires verification)</li>
        <li className="mb-1"><strong>Phone Number:</strong> Add or change your contact phone</li>
        <li className="mb-1"><strong>Time Zone:</strong> Set your preferred time zone for accurate scheduling</li>
      </ul>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Account Security</h3>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Password:</strong> Change your account password</li>
        <li className="mb-1"><strong>Two-Factor Authentication:</strong> Enable additional security</li>
        <li className="mb-1"><strong>Login History:</strong> View a log of recent logins to your account</li>
        <li className="mb-1"><strong>Connected Devices:</strong> Manage devices that are logged into your account</li>
      </ul>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Notification Preferences</h3>
      <p className="mb-4">
        Control how and when you receive notifications:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Email Notifications:</strong> Choose which events trigger email notifications</li>
        <li className="mb-1"><strong>In-App Notifications:</strong> Configure desktop notifications within the app</li>
        <li className="mb-1"><strong>Notification Schedule:</strong> Set quiet hours when notifications are suppressed</li>
        <li className="mb-1"><strong>Theme:</strong> Select light or dark mode</li>
      </ul>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Team Settings</h2>
      <h3 className="text-lg font-bold mt-6 mb-3">Team Members</h3>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>View Team:</strong> See a list of all team members and their roles</li>
        <li className="mb-1"><strong>Invite Members:</strong> Send invitations to new team members</li>
        <li className="mb-1"><strong>Edit Roles:</strong> Modify team members' roles and permissions</li>
        <li className="mb-1"><strong>Remove Members:</strong> Remove users from your team</li>
      </ul>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Roles and Permissions</h3>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>View Roles:</strong> See the available roles and their associated permissions</li>
        <li className="mb-1"><strong>Create Custom Roles:</strong> Define custom roles with specific permission sets</li>
        <li className="mb-1"><strong>Edit Permissions:</strong> Modify the permissions associated with each role</li>
        <li className="mb-1"><strong>Assign Roles:</strong> Assign roles to team members</li>
      </ul>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Team Hierarchy</h3>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Reporting Structure:</strong> Define the reporting hierarchy within your team</li>
        <li className="mb-1"><strong>Lead Assignment Rules:</strong> Set up rules for how leads are assigned to team members</li>
        <li className="mb-1"><strong>Territory Management:</strong> Define geographic territories for team members</li>
      </ul>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Communication Settings</h2>
      <h3 className="text-lg font-bold mt-6 mb-3">Phone Settings</h3>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Business Phone:</strong> View and manage your LeadGPT phone numbers</li>
        <li className="mb-1"><strong>Call Forwarding:</strong> Set up call forwarding rules</li>
        <li className="mb-1"><strong>Voicemail:</strong> Configure your voicemail greeting and settings</li>
        <li className="mb-1"><strong>Call Recording:</strong> Enable or disable call recording and set retention policies</li>
      </ul>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Messaging Settings</h3>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Message Templates:</strong> Create and manage templates for common messages</li>
        <li className="mb-1"><strong>Auto-Responses:</strong> Set up automatic responses for specific situations</li>
        <li className="mb-1"><strong>Scheduled Messages:</strong> Configure rules for scheduling messages</li>
        <li className="mb-1"><strong>Message Signatures:</strong> Create and manage your message signatures</li>
      </ul>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Email Settings</h3>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Email Templates:</strong> Create and manage email templates</li>
        <li className="mb-1"><strong>Email Signature:</strong> Configure your email signature</li>
        <li className="mb-1"><strong>Email Tracking:</strong> Enable or disable tracking for email opens and clicks</li>
        <li className="mb-1"><strong>Email Scheduling:</strong> Set up rules for scheduling emails</li>
      </ul>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Lead Management Configuration</h2>
      <h3 className="text-lg font-bold mt-6 mb-3">Lead Pipeline</h3>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Pipeline Stages:</strong> Add, edit, or remove pipeline stages</li>
        <li className="mb-1"><strong>Stage Colors:</strong> Customize the color coding of pipeline stages</li>
        <li className="mb-1"><strong>Stage Requirements:</strong> Define criteria for moving leads between stages</li>
        <li className="mb-1"><strong>Automation Rules:</strong> Create rules for automatic lead movement</li>
      </ul>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Custom Fields</h3>
      <p className="mb-4">
        Add additional fields to your lead records:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Field Types:</strong> Text, number, date, dropdown, checkbox, etc.</li>
        <li className="mb-1"><strong>Required Fields:</strong> Mark fields as mandatory</li>
        <li className="mb-1"><strong>Field Visibility:</strong> Control which team members can see specific fields</li>
        <li className="mb-1"><strong>Field Groups:</strong> Organize custom fields into logical groups</li>
      </ul>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Lead Sources</h3>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Source Management:</strong> Add, edit, or remove lead sources</li>
        <li className="mb-1"><strong>Source Tracking:</strong> Configure how lead sources are tracked and attributed</li>
        <li className="mb-1"><strong>UTM Parameters:</strong> Set up tracking for UTM parameters in digital marketing</li>
        <li className="mb-1"><strong>Source Reporting:</strong> Configure reporting for lead source performance</li>
      </ul>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Lead Scoring</h3>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Scoring Criteria:</strong> Define what factors affect lead scores</li>
        <li className="mb-1"><strong>Weight Assignment:</strong> Set importance of different criteria</li>
        <li className="mb-1"><strong>Score Thresholds:</strong> Define categories of lead quality</li>
        <li className="mb-1"><strong>Score Decay:</strong> Configure how scores change over time</li>
      </ul>
      
      <h2 className="text-xl font-bold mt-8 mb-4">AI Receptionist Settings</h2>
      <h3 className="text-lg font-bold mt-6 mb-3">Assistant Configuration</h3>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Voice Selection:</strong> Choose the voice for your AI Receptionist</li>
        <li className="mb-1"><strong>Greeting:</strong> Customize the greeting your AI Receptionist uses</li>
        <li className="mb-1"><strong>Conversation Flow:</strong> Define the conversation flow for different call types</li>
        <li className="mb-1"><strong>Fallback Options:</strong> Set up fallback options when the AI can't handle a call</li>
      </ul>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Call Handling Rules</h3>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Availability Schedule:</strong> Set when the AI Receptionist should answer calls</li>
        <li className="mb-1"><strong>Call Forwarding:</strong> Configure when calls should be forwarded to the AI</li>
        <li className="mb-1"><strong>Call Routing:</strong> Set up rules for routing calls after the AI interaction</li>
        <li className="mb-1"><strong>After-Hours Handling:</strong> Define how calls are handled outside business hours</li>
      </ul>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Information Collection</h3>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Required Information:</strong> Specify what information the AI should collect from callers</li>
        <li className="mb-1"><strong>Question Sequence:</strong> Define the sequence of questions the AI should ask</li>
        <li className="mb-1"><strong>Custom Questions:</strong> Add custom questions for specific scenarios</li>
        <li className="mb-1"><strong>Response Handling:</strong> Configure how the AI handles different types of responses</li>
      </ul>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Integrations</h2>
      <p className="mb-4">
        Connect LeadGPT with your other business tools:
      </p>
      <h3 className="text-lg font-bold mt-6 mb-3">Available Integrations</h3>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Calendar:</strong> Google Calendar, Outlook, etc.</li>
        <li className="mb-1"><strong>Email:</strong> Gmail, Outlook, custom SMTP</li>
        <li className="mb-1"><strong>CRM:</strong> Salesforce, HubSpot, etc.</li>
        <li className="mb-1"><strong>Marketing:</strong> Mailchimp, Constant Contact, etc.</li>
        <li className="mb-1"><strong>Website:</strong> WordPress, Webflow, custom site integration</li>
        <li className="mb-1"><strong>Payment Processors:</strong> Connect payment processing services</li>
      </ul>
      
      <h3 className="text-lg font-bold mt-6 mb-3">API Access</h3>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>API Keys:</strong> Generate and manage API keys for custom integrations</li>
        <li className="mb-1"><strong>Webhooks:</strong> Set up webhooks to trigger actions in other systems</li>
        <li className="mb-1"><strong>OAuth Applications:</strong> Manage OAuth applications connected to your account</li>
        <li className="mb-1"><strong>Usage Limits:</strong> View your API usage and limits</li>
      </ul>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Billing and Subscription</h2>
      <h3 className="text-lg font-bold mt-6 mb-3">Subscription Management</h3>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Current Plan:</strong> View details of your current subscription plan</li>
        <li className="mb-1"><strong>Plan Comparison:</strong> Compare different subscription plans</li>
        <li className="mb-1"><strong>Upgrade/Downgrade:</strong> Change your subscription plan</li>
        <li className="mb-1"><strong>Add-ons:</strong> Purchase additional features or capacity</li>
      </ul>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Payment Information</h3>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Payment Methods:</strong> Add or update your payment methods</li>
        <li className="mb-1"><strong>Billing Address:</strong> Update your billing address information</li>
        <li className="mb-1"><strong>Tax Information:</strong> Manage your tax information and settings</li>
        <li className="mb-1"><strong>Billing Contacts:</strong> Specify who should receive billing notifications</li>
      </ul>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Billing History</h3>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Invoices:</strong> View and download past invoices</li>
        <li className="mb-1"><strong>Payment History:</strong> See a record of past payments</li>
        <li className="mb-1"><strong>Usage Reports:</strong> View detailed reports of your platform usage</li>
        <li className="mb-1"><strong>Upcoming Charges:</strong> See estimates of upcoming charges</li>
      </ul>
      
      <h2 className="text-xl font-bold mt-8 mb-4">System Settings</h2>
      <h3 className="text-lg font-bold mt-6 mb-3">Data Management</h3>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Data Retention:</strong> Configure how long different types of data are retained</li>
        <li className="mb-1"><strong>Data Export:</strong> Export your data for backup or analysis</li>
        <li className="mb-1"><strong>Data Import:</strong> Import data from other systems</li>
        <li className="mb-1"><strong>Duplicate Management:</strong> Set rules for handling duplicate leads</li>
        <li className="mb-1"><strong>Data Cleanup:</strong> Run tools to clean up and optimize your data</li>
      </ul>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Security Settings</h3>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>IP Restrictions:</strong> Restrict access to specific IP addresses</li>
        <li className="mb-1"><strong>Session Settings:</strong> Configure session timeout and security policies</li>
        <li className="mb-1"><strong>Audit Logs:</strong> View logs of system activities and changes</li>
        <li className="mb-1"><strong>Compliance Settings:</strong> Configure settings related to regulatory compliance</li>
      </ul>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Appearance</h3>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Theme:</strong> Choose between light and dark themes</li>
        <li className="mb-1"><strong>Branding:</strong> Add your company logo and customize colors</li>
        <li className="mb-1"><strong>Layout Options:</strong> Adjust the layout of certain screens</li>
        <li className="mb-1"><strong>Default Views:</strong> Set default views for different sections of the app</li>
      </ul>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Automation</h3>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Workflow Automation:</strong> Create automated processes</li>
        <li className="mb-1"><strong>Triggers:</strong> Define events that start automated workflows</li>
        <li className="mb-1"><strong>Actions:</strong> Configure what happens when triggers fire</li>
        <li className="mb-1"><strong>Schedules:</strong> Set up time-based automation</li>
      </ul>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Best Practices for Settings Configuration</h2>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Review regularly:</strong> Periodically review your settings to ensure they still match your needs</li>
        <li className="mb-1"><strong>Document changes:</strong> Keep a record of significant settings changes</li>
        <li className="mb-1"><strong>Test thoroughly:</strong> Test new configurations before rolling them out to your entire team</li>
        <li className="mb-1"><strong>Use templates:</strong> Create templates for common configurations to ensure consistency</li>
        <li className="mb-1"><strong>Train your team:</strong> Ensure team members understand the settings that affect their work</li>
        <li className="mb-1"><strong>Start simple:</strong> Begin with basic configurations and add complexity as needed</li>
        <li className="mb-1"><strong>Leverage automation:</strong> Use automation rules to reduce manual work</li>
      </ul>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Troubleshooting Common Settings Issues</h2>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Settings not saving</h3>
      <p className="mb-4">
        If settings aren't saving properly:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1">Clear your browser cache and try again</li>
        <li className="mb-1">Ensure you have the necessary permissions to modify the settings</li>
        <li className="mb-1">Check your internet connection and try again</li>
      </ul>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Integration issues</h3>
      <p className="mb-4">
        If you're having trouble with integrations:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1">Verify that your API keys and credentials are correct</li>
        <li className="mb-1">Check that the integrated service is operational</li>
        <li className="mb-1">Review the integration logs for specific error messages</li>
      </ul>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Permission problems</h3>
      <p className="mb-4">
        If team members can't access certain features:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1">Review their role and permissions</li>
        <li className="mb-1">Check if the feature is included in their subscription plan</li>
        <li className="mb-1">Ensure they have completed any required setup steps</li>
      </ul>
      
      <p className="mb-4 mt-8">
        Remember to save your changes after modifying settings. Most settings take effect immediately, though some may require you to refresh your browser.
      </p>
    </ArticleLayout>
  );
} 