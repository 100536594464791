import { MetricItem } from '@/services/dashboard';
import { ArrowDownRight, ArrowRight, ArrowUpRight } from 'lucide-react';

interface MetricCardProps {
  metric: MetricItem;
}

export function MetricCard({ metric }: MetricCardProps) {
  const Icon = metric.icon;
  
  // Determine arrow and color based on trend
  let Arrow;
  let trendColor;
  
  if (metric.trend === 'up') {
    Arrow = ArrowUpRight;
    trendColor = metric.name === 'Avg. Response Time' ? 'text-red-600' : 'text-green-600';
  } else if (metric.trend === 'down') {
    Arrow = ArrowDownRight;
    trendColor = metric.name === 'Avg. Response Time' ? 'text-green-600' : 'text-red-600';
  } else {
    // Neutral trend
    Arrow = ArrowRight;
    trendColor = 'text-gray-500';
  }

  return (
    <div className="border rounded-md p-4 bg-white shadow-sm">
      <div className="text-sm font-medium">{metric.name}</div>
      <div className="text-2xl font-bold mt-2">{metric.value}</div>
      <div className="text-xs text-gray-500 mt-1"><span className={trendColor}>{parseFloat(metric.change).toFixed(0)} {metric.name === 'Conversion Rate' ? '%' : ''}</span>
<span className="ml-2 text-gray-500">from previous period</span></div>
    </div>
  );
} 