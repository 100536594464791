import * as teamsService from '@/services/teams.service';
import { useAuthStore } from '@/store/authStore';
import { useTeamStore } from '@/store/teamStore';
import { CreateTeamRequest, InviteToTeamRequest, Team, TeamInvitation } from '@/types/team';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { toast } from 'sonner';

interface AcceptInvitationResponse {
  message: string;
  team?: Team;
}

/**
 * Hook for managing teams
 */
export function useTeams() {
  const queryClient = useQueryClient();
  const { currentTeamId, currentTeam, setCurrentTeam, setCurrentTeamId, clearCurrentTeam } = useTeamStore();
  
  // Get all teams
  const { 
    data: teams = [] as Team[], 
    isLoading: isLoadingTeams,
    isError: isTeamsError,
    error: teamsError,
    refetch: refetchTeams
  } = useQuery({
    queryKey: ['teams'],
    queryFn: teamsService.getTeams
  });
  
  // Get team details
  const getTeamDetails = (teamId: number) => {
    const query = useQuery<Team, Error>({
      queryKey: ['teams', teamId],
      queryFn: () => teamsService.getTeamDetails(teamId)
    });
    
    // Update current team if this is the current team
    useEffect(() => {
      if (query.data && currentTeamId === teamId) {
        setCurrentTeam(query.data);
      }
    }, [query.data, teamId]);
    
    return query;
  };
  
  // Create team mutation
  const createTeamMutation = useMutation({
    mutationFn: (data: CreateTeamRequest) => teamsService.createTeam(data),
    onSuccess: (data: Team) => {
      queryClient.invalidateQueries({ queryKey: ['teams'] });
      toast.success('Team created successfully');
      
      // Set as current team if no current team is selected
      if (!currentTeamId) {
        setCurrentTeam(data);
      }
    },
    onError: (error: any) => {
      toast.error('Failed to create team', {
        description: error.response?.data?.message || 'An error occurred'
      });
    }
  });
  
  // Delete team mutation
  const deleteTeamMutation = useMutation({
    mutationFn: (teamId: number) => teamsService.deleteTeam(teamId),
    onMutate: async (teamId) => {
      // Cancel any outgoing refetches
      await queryClient.cancelQueries({ queryKey: ['teams'] });

      // Snapshot the previous value
      const previousTeams = queryClient.getQueryData<Team[]>(['teams']);

      // Optimistically remove the team from the list
      if (previousTeams) {
        queryClient.setQueryData(['teams'], 
          previousTeams.filter(team => team.id !== teamId)
        );
      }

      // If this was the current team, clear it
      if (currentTeamId === teamId) {
        clearCurrentTeam();
      }

      // Return a context with the previous value
      return { previousTeams };
    },
    onError: (error: unknown, teamId, context) => {
      // Revert back to the previous value if there's an error
      if (context?.previousTeams) {
        queryClient.setQueryData(['teams'], context.previousTeams);
      }

      // Handle error message
      const errorMessage = error instanceof Error 
        ? error.message 
        : 'An error occurred';

      toast.error('Failed to delete team', {
        description: errorMessage
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['teams'] });
      toast.success('Team deleted successfully');
    }
  });
  
  // Update team mutation
  const updateTeamMutation = useMutation({
    mutationFn: ({ teamId, data }: { teamId: number; data: { name: string } }) => 
      teamsService.updateTeam(teamId, data),
    onMutate: async ({ teamId, data }) => {
      // Cancel any outgoing refetches
      await queryClient.cancelQueries({ queryKey: ['teams'] });
      await queryClient.cancelQueries({ queryKey: ['teams', teamId] });

      // Snapshot the previous value
      const previousTeams = queryClient.getQueryData<Team[]>(['teams']);
      const previousTeam = queryClient.getQueryData<Team>(['teams', teamId]);

      // Optimistically update the teams list
      if (previousTeams) {
        queryClient.setQueryData(['teams'], 
          previousTeams.map(team => 
            team.id === teamId 
              ? { ...team, ...data }
              : team
          )
        );
      }

      // Optimistically update the team details
      if (previousTeam) {
        queryClient.setQueryData(['teams', teamId], {
          ...previousTeam,
          ...data
        });
      }

      // If this is the current team, update it in the store
      if (currentTeamId === teamId) {
        const updatedTeam = currentTeam ? { ...currentTeam, ...data } : null;
        setCurrentTeam(updatedTeam);
      }

      // Return a context object with the snapshotted value
      return { previousTeams, previousTeam };
    },
    onError: (error: unknown, { teamId }, context) => {
      // Revert back to the previous values if there's an error
      if (context?.previousTeams) {
        queryClient.setQueryData(['teams'], context.previousTeams);
      }
      if (context?.previousTeam) {
        queryClient.setQueryData(['teams', teamId], context.previousTeam);
      }
      if (currentTeamId === teamId && context?.previousTeam) {
        setCurrentTeam(context.previousTeam);
      }

      // Handle error message
      const errorMessage = error instanceof Error 
        ? error.message 
        : 'An error occurred';
      
      toast.error('Failed to update team', {
        description: errorMessage
      });
    },
    onSuccess: (data, { teamId }) => {
      // Invalidate and refetch to ensure we have the latest data
      queryClient.invalidateQueries({ queryKey: ['teams'] });
      queryClient.invalidateQueries({ queryKey: ['teams', teamId] });
      toast.success('Team updated successfully');
    }
  });
  
  // Invite to team mutation
  const inviteToTeamMutation = useMutation({
    mutationFn: ({ teamId, data }: { teamId: number; data: InviteToTeamRequest }) => 
      teamsService.inviteToTeam(teamId, data),
    onMutate: async ({ teamId, data }) => {
      // Cancel any outgoing refetches
      await queryClient.cancelQueries({ queryKey: ['teams', teamId] });

      // Snapshot the previous value
      const previousTeam = queryClient.getQueryData<Team>(['teams', teamId]);

      // Optimistically add the invitation to the team
      if (previousTeam) {
        const newInvitation: TeamInvitation = {
          id: Date.now(), // Temporary ID
          teamId,
          email: data.email,
          role: data.role || 'member',
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString()
        };

        queryClient.setQueryData(['teams', teamId], {
          ...previousTeam,
          pendingInvitations: [
            ...(previousTeam.pendingInvitations || []),
            newInvitation
          ]
        });
      }

      return { previousTeam };
    },
    onError: (error: unknown, { teamId }, context) => {
      // Revert back to the previous value if there's an error
      if (context?.previousTeam) {
        queryClient.setQueryData(['teams', teamId], context.previousTeam);
      }

      // Handle error message
      const errorMessage = error instanceof Error 
        ? error.message 
        : 'An error occurred';

      toast.error('Failed to send invitation', {
        description: errorMessage
      });
    },
    onSuccess: (data, { teamId }) => {
      queryClient.invalidateQueries({ queryKey: ['teams', teamId] });
      toast.success(data.message || 'Invitation sent successfully');
    }
  });
  
  // Get my invitations
  const { 
    data: invitations = [] as TeamInvitation[], 
    isLoading: isLoadingInvitations,
    isError: isInvitationsError,
    error: invitationsError,
    refetch: refetchInvitations
  } = useQuery({
    queryKey: ['teamInvitations'],
    queryFn: teamsService.getMyInvitations
  });
  
  // Accept invitation mutation
  const acceptInvitationMutation = useMutation({
    mutationFn: (invitationId: number) => teamsService.acceptInvitation(invitationId),
    onSuccess: (data: AcceptInvitationResponse) => {
      queryClient.invalidateQueries({ queryKey: ['teams'] });
      queryClient.invalidateQueries({ queryKey: ['teamInvitations'] });
      toast.success('Invitation accepted successfully');
      
      // Set as current team if no current team is selected
      if (!currentTeamId && data.team) {
        setCurrentTeam(data.team);
      }
    },
    onError: (error: any) => {
      toast.error('Failed to accept invitation', {
        description: error.response?.data?.message || 'An error occurred'
      });
    }
  });
  
  // Decline invitation mutation
  const declineInvitationMutation = useMutation({
    mutationFn: (invitationId: number) => teamsService.declineInvitation(invitationId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['teamInvitations'] });
      toast.success('Invitation declined');
    },
    onError: (error: any) => {
      toast.error('Failed to decline invitation', {
        description: error.response?.data?.message || 'An error occurred'
      });
    }
  });
  
  // Update member role mutation
  const updateMemberRoleMutation = useMutation({
    mutationFn: ({ teamId, memberId, role }: { teamId: number; memberId: number; role: 'admin' | 'member' }) => 
      teamsService.updateMemberRole(teamId, memberId, role),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['teams', variables.teamId] });
      toast.success('Member role updated successfully');
    },
    onError: (error: any) => {
      toast.error('Failed to update member role', {
        description: error.response?.data?.message || 'An error occurred'
      });
    }
  });
  
  // Remove member mutation
  const removeMemberMutation = useMutation({
    mutationFn: ({ teamId, memberId }: { teamId: number; memberId: number }) => 
      teamsService.removeMember(teamId, memberId),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['teams', variables.teamId] });
      toast.success('Member removed successfully');
    },
    onError: (error: any) => {
      toast.error('Failed to remove member', {
        description: error.response?.data?.message || 'An error occurred'
      });
    }
  });
  
  // Set current team by ID
  const selectTeam = async (teamId: number | null) => {
    try {
      console.log('[useTeams] Selecting team:', teamId);
      
      if (teamId === null) {
        console.log('[useTeams] Clearing current team (null teamId)');
        clearCurrentTeam();
        await teamsService.switchCurrentTeam(null);
        return;
      }
      
      // Find the team in the list of teams
      const teamsArray = teams as Team[];
      const team = teamsArray.find(t => t.id === teamId);
      
      if (team) {
        console.log('[useTeams] Found team in existing teams:', team.name);
        
        // Update the server's record of current team
        await teamsService.switchCurrentTeam(teamId);
        
        // Update local state
        setCurrentTeam(team);
        setCurrentTeamId(teamId);
        return;
      }
      
      // If we don't have the team details in our list, fetch them
      console.log('[useTeams] Team not in current list, fetching details');
      try {
        const teamDetails = await teamsService.getTeamDetails(teamId);
        console.log('[useTeams] Fetched team details:', teamDetails.name);
        
        // Update the server's record of current team
        await teamsService.switchCurrentTeam(teamId);
        
        // Update local state
        setCurrentTeam(teamDetails);
        setCurrentTeamId(teamId);
      } catch (error) {
        console.error('[useTeams] Error fetching team details:', error);
        toast.error('Failed to fetch team details');
        
        // If fetching fails, look for a personal team to fall back to
        const currentUser = useAuthStore.getState().user;
        const personalTeam = teamsArray.find(t => t.isPersonal && t.userId === currentUser?.id);
        
        if (personalTeam) {
          console.log('[useTeams] Falling back to personal team:', personalTeam.name);
          await teamsService.switchCurrentTeam(personalTeam.id);
          setCurrentTeam(personalTeam);
          setCurrentTeamId(personalTeam.id);
        } else {
          console.log('[useTeams] No personal team found, clearing current team');
          clearCurrentTeam();
          await teamsService.switchCurrentTeam(null);
        }
      }
    } catch (error) {
      console.error('[useTeams] Error switching team:', error);
      toast.error('Failed to switch team');
    }
  };
  
  // Initialize current team if needed
  useEffect(() => {
    const teamsArray = teams as Team[];
    
    // If we have a currentTeamId but no currentTeam, fetch the team details
    if (currentTeamId && !currentTeam && teamsArray.length > 0) {
      const team = teamsArray.find(t => t.id === currentTeamId);
      if (team) {
        setCurrentTeam(team);
      } else {
        // If the team is not in the list, fetch it
        selectTeam(currentTeamId);
      }
    }
    
    // If we have no currentTeamId but have teams, set the first team as current
    if (!currentTeamId && teamsArray.length > 0 && !isLoadingTeams) {
      setCurrentTeam(teamsArray[0]);
    }
  }, [currentTeamId, currentTeam, teams, isLoadingTeams]);
  
  return {
    // Current team
    currentTeamId,
    currentTeam,
    selectTeam,
    clearCurrentTeam,
    
    // Queries
    teams,
    isLoadingTeams,
    isTeamsError,
    teamsError,
    refetchTeams,
    getTeamDetails,
    
    // Invitations
    invitations,
    isLoadingInvitations,
    isInvitationsError,
    invitationsError,
    refetchInvitations,
    
    // Mutations
    createTeam: createTeamMutation.mutate,
    isCreatingTeam: createTeamMutation.isPending,
    
    deleteTeam: deleteTeamMutation.mutate,
    isDeletingTeam: deleteTeamMutation.isPending,
    
    updateTeam: updateTeamMutation.mutate,
    isUpdatingTeam: updateTeamMutation.isPending,
    
    inviteToTeam: inviteToTeamMutation.mutate,
    isInviting: inviteToTeamMutation.isPending,
    
    acceptInvitation: acceptInvitationMutation.mutate,
    isAcceptingInvitation: acceptInvitationMutation.isPending,
    
    declineInvitation: declineInvitationMutation.mutate,
    isDecliningInvitation: declineInvitationMutation.isPending,
    
    updateMemberRole: updateMemberRoleMutation.mutate,
    isUpdatingRole: updateMemberRoleMutation.isPending,
    
    removeMember: removeMemberMutation.mutate,
    isRemovingMember: removeMemberMutation.isPending
  };
} 