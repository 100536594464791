import { AIReceptionistPage } from '@/pages/AIReceptionistPage';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/ai-receptionist')({
  component: AIReceptionistPage,
  validateSearch: (search: Record<string, unknown>) => {
    return {
      tab: search.tab as string | undefined,
    };
  },
}); 