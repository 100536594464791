import { Button } from '@/components/ui/button';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { HiOutlineExclamationCircle } from 'react-icons/hi';

interface ImportPreviewProps {
  previewData: any[];
  onBack: () => void;
  onImport: () => void;
  isLoading: boolean;
}

export function ImportPreview({ previewData, onBack, onImport, isLoading }: ImportPreviewProps) {
  // Get all unique keys from the preview data
  const allKeys = Array.from(
    new Set(
      previewData.flatMap(item => Object.keys(item))
    )
  );

  // Check if required fields are present
  const hasRequiredFields = previewData.length > 0 && 
    previewData.every(item => item.name && item.phoneNumber);

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h3 className="text-lg font-medium">Preview Import Data</h3>
        <div className="text-sm text-gray-500">
          Showing {previewData.length} of {previewData.length} records
        </div>
      </div>

      {!hasRequiredFields && (
        <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mb-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <HiOutlineExclamationCircle className="h-5 w-5 text-yellow-400" />
            </div>
            <div className="ml-3">
              <p className="text-sm text-yellow-700">
                Warning: Some records are missing required fields (Name or Phone Number).
                These records will be skipped during import.
              </p>
            </div>
          </div>
        </div>
      )}

      {/* Table container with horizontal scroll */}
      <div className="border rounded-md max-h-[400px] max-w-[89vw] sm:max-w-[750px] overflow-y-auto">
        <div className="overflow-x-auto w-full">
          <Table className="w-full min-w-max">
            <TableHeader>
              <TableRow>
                {allKeys.map(key => (
                  <TableHead key={key} className="whitespace-nowrap px-4 py-3">
                    {key}
                  </TableHead>
                ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {previewData.map((item, index) => (
                <TableRow key={index}>
                  {allKeys.map(key => (
                    <TableCell key={`${index}-${key}`} className="whitespace-nowrap px-4 py-2">
                      {item[key] !== undefined ? String(item[key]) : ''}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>

      <div className="flex justify-between pt-4">
        <Button
          type="button"
          variant="outline"
          onClick={onBack}
          disabled={isLoading}
        >
          Back to Mapping
        </Button>
        <Button
          onClick={onImport}
          disabled={isLoading}
        >
          {isLoading ? 'Processing...' : 'Import Leads'}
        </Button>
      </div>
    </div>
  );
} 