import { MainLayout } from '@/components/layout/MainLayout';
import { PageContainer } from '@/components/layout/PageContainer';

const metrics = [
  {
    name: 'Lead Growth',
    value: '+12.3%',
    description: 'vs. last month',
  },
  {
    name: 'Conversion Rate',
    value: '24.5%',
    description: '132 conversions',
  },
  {
    name: 'Avg. Response Time',
    value: '2.4h',
    description: '↓ 0.5h from last week',
  },
  {
    name: 'Active Leads',
    value: '1,234',
    description: '89 new this week',
  },
];

export function AnalyticsPage() {
  return (
    <MainLayout>
      <PageContainer
        title="Analytics"
        description="Track your lead generation metrics"
      >
        {/* Analytics content will go here */}
        <div>Analytics content coming soon...</div>
      </PageContainer>
    </MainLayout>
  );
} 