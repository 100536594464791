import { MainLayout } from '@/components/layout/MainLayout';
import { PageContainer } from '@/components/layout/PageContainer';
import { CommunicationPane } from '@/components/leads/CommunicationPane';
import { LeadDetailsPane } from '@/components/leads/LeadDetailsPane';
import { LeadsList } from '@/components/leads/LeadsList';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { useDebounce } from '@/hooks/useDebounce';
import { api, useLead } from '@/lib/api';
import { cn } from '@/lib/utils';
import { useCommunicationPaneStore } from '@/store/communicationPaneStore';
import { useLeadStore } from '@/store/leadStore';
import { useQuery } from '@tanstack/react-query';
import { ArrowDownIcon, ArrowUpIcon, ChevronsUpDown, Filter, Search, SortAsc, X } from 'lucide-react';
import { useEffect, useState } from 'react';

const SORT_OPTIONS = {
  leadScore: 'Lead Score',
  lastContacted: 'Last Contact',
  createdAt: 'Date Added',
  updatedAt: 'Date Updated',
  name: 'Name',
} as const;

const STATUS_OPTIONS = {
  all: 'All Statuses',
  new: 'New',
  contacted: 'Contacted',
  qualified: 'Qualified',
  proposal: 'Proposal',
  won: 'Won',
  lost: 'Lost',
} as const;

export function LeadsPage() {
  const { filter, setFilter, selectedLead } = useLeadStore();
  const debouncedSearch = useDebounce(filter.search, 500);
  const [selectedLeadId, setSelectedLeadId] = useState<number | null>(null);
  const communicationPaneOpen = useCommunicationPaneStore((state) => state.isOpen);

  // Fetch custom statuses
  const { data: statusesData } = useQuery({
    queryKey: ['statuses'],
    queryFn: api.statuses.list,
    staleTime: 30000,
    retry: 2
  });

  // Get custom statuses
  const customStatuses = statusesData?.success ? statusesData.statuses.filter(s => !s.isDefault) : [];

  // Effect to sync with the lead store when it changes
  useEffect(() => {
    if (selectedLead.id) {
      console.log(`[LeadsPage] Updating selected lead from store: ${selectedLead.id}`);
      setSelectedLeadId(selectedLead.id);
    }
  }, [selectedLead.id]);

  const { data: leadData, isLoading: isLoadingLead } = useLead(selectedLeadId, {
    activeConversation: communicationPaneOpen
  });
  const lead = leadData?.success ? leadData.lead : null;

  // Reset selected lead on unmount
  useEffect(() => {
    return () => setSelectedLeadId(null);
  }, []);

  const handleLeadSelect = (id: number) => setSelectedLeadId(id);

  // Calculate if any filters are active
  const hasActiveFilters = filter.type !== 'all' || filter.status !== 'all';
  const activeFiltersCount = (filter.type !== 'all' && filter.status !== 'all') ? 2 : 1;

  return (
    <MainLayout pageTitle="Leads" pageDescription="Manage and track your leads">
      <PageContainer title="Leads" description="Manage and track your leads">
        <div className="flex flex-col lg:flex-row h-screen lg:h-full">
          {/* Lead List Pane */}
          <div className={cn(
            "flex flex-col overflow-hidden md:border-r md:border-gray-200 md:bg-white bg-white h-[calc(100vh-4rem)] mt-[4rem] lg:mt-0 lg:h-full lg:max-w-[20rem] lg:min-w-[20rem]",
            selectedLeadId ? "hidden lg:flex lg:w-1/3" : "w-full lg:w-1/3"
          )}>
            <div className="border-b border-gray-200 p-4 flex-shrink-0">
              <div className="relative flex flex-col">
                <div className="relative flex items-center w-full">
                  <div className="absolute left-3 top-0 bottom-0 flex items-center pointer-events-none z-10">
                    <Search className="h-4 w-4 text-gray-500" />
                  </div>
                  <input
                    type="search"
                    placeholder="Search leads..."
                    className={cn(
                      "h-10 w-full rounded-md border border-gray-300 pl-10 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 text-sm",
                      filter.search ? "pr-10" : "pr-4"
                    )}
                    value={filter.search}
                    onChange={(e) => setFilter({ search: e.target.value })}
                  />
                  {filter.search && (
                    <div className="absolute right-3 top-0 bottom-0 flex items-center z-10">
                      <button 
                        className="text-gray-400 hover:text-gray-600 focus:outline-none"
                        onClick={() => setFilter({ search: '' })}
                        aria-label="Clear search"
                      >
                        <X className="h-4 w-4" />
                      </button>
                    </div>
                  )}
                </div>
                {filter.search && (
                  <p className="mt-1 text-xs text-gray-500 italic flex items-center">
                    <span className="inline-block w-2 h-2 bg-blue-400 rounded-full mr-1.5"></span>
                    Searching in names, contact info, and metadata values
                  </p>
                )}
              </div>
              <div className="mt-4 flex flex-wrap items-center gap-2">
                <div className="flex items-center gap-2 overflow-x-auto flex-grow">
                  {/* Filter Dropdown */}
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button variant="outline" size="sm" className="gap-2 whitespace-nowrap focus-visible:ring-0 focus-visible:ring-offset-0 focus-visible:ring-transparent">
                        <Filter className="h-4 w-4" />
                        <span className="flex items-center gap-2">
                          Filters
                          {hasActiveFilters && (
                            <Badge variant="destructive" className="ml-1 px-2">
                              {activeFiltersCount}
                            </Badge>
                          )}
                        </span>
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="start" className="w-56 max-h-[60vh] overflow-y-auto">
                      <DropdownMenuLabel>Lead Type</DropdownMenuLabel>
                      {hasActiveFilters && (
                        <>
                          <DropdownMenuSeparator />
                          <DropdownMenuItem
                            onSelect={() => {
                              useLeadStore.getState().resetFilter();
                            }}
                            className="text-red-600 dark:text-red-400"
                          >
                            Reset Filters
                          </DropdownMenuItem>
                        </>
                      )}
                      <DropdownMenuSeparator />
                      <DropdownMenuRadioGroup
                        value={filter.type}
                        onValueChange={(value) =>
                          setFilter({ type: value as 'all' | 'aged' | 'prime' })
                        }
                      >
                        <DropdownMenuRadioItem value="all">
                          All Leads
                        </DropdownMenuRadioItem>
                        <DropdownMenuRadioItem value="aged">
                          Aged Leads
                        </DropdownMenuRadioItem>
                        <DropdownMenuRadioItem value="prime">
                          Prime Leads
                        </DropdownMenuRadioItem>
                      </DropdownMenuRadioGroup>

                      {customStatuses.length > 0 && (
                          <DropdownMenuRadioGroup
                            value={filter.status}
                            onValueChange={(value) =>
                              setFilter({ status: value as typeof filter.status })
                            }
                          >
                            <DropdownMenuSeparator />
                            <DropdownMenuLabel>Custom Statuses</DropdownMenuLabel>
                            <DropdownMenuSeparator />
                            {customStatuses.map((status) => (
                              <DropdownMenuRadioItem 
                                key={status.id} 
                                value={status.name}
                                style={{
                                  color: status.color
                                }}
                              >
                                {status.name.charAt(0).toUpperCase() + status.name.slice(1)}
                              </DropdownMenuRadioItem>
                            ))}
                          </DropdownMenuRadioGroup>
                        )}
                      
                      <DropdownMenuSeparator />
                      <DropdownMenuLabel>Status</DropdownMenuLabel>
                      <DropdownMenuSeparator />
                      <DropdownMenuRadioGroup
                        value={filter.status}
                        onValueChange={(value) =>
                          setFilter({ status: value as typeof filter.status })
                        }
                      >
                        {Object.entries(STATUS_OPTIONS).map(([value, label]) => (
                          <DropdownMenuRadioItem key={value} value={value}>
                            {label}
                          </DropdownMenuRadioItem>
                        ))}
                      </DropdownMenuRadioGroup>
                    </DropdownMenuContent>
                  </DropdownMenu>

                  {/* Sort Dropdown */}
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button variant="outline" size="sm" className="gap-2 whitespace-nowrap focus-visible:ring-0 focus-visible:ring-offset-0 focus-visible:ring-transparent">
                        <SortAsc className="h-4 w-4" />
                        {SORT_OPTIONS[filter.sortBy]}
                        {filter.sortOrder === 'asc' ? (
                          <ArrowUpIcon className="h-3 w-3" />
                        ) : (
                          <ArrowDownIcon className="h-3 w-3" />
                        )}
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end" className="w-48">
                      <DropdownMenuLabel>Sort by</DropdownMenuLabel>
                      <DropdownMenuSeparator />
                      <DropdownMenuRadioGroup
                        value={filter.sortBy}
                        onValueChange={(value) =>
                          setFilter({ sortBy: value as typeof filter.sortBy })
                        }
                      >
                        {Object.entries(SORT_OPTIONS).map(([value, label]) => (
                          <DropdownMenuRadioItem key={value} value={value}>
                            {label}
                          </DropdownMenuRadioItem>
                        ))}
                      </DropdownMenuRadioGroup>
                      <DropdownMenuSeparator />
                      <DropdownMenuRadioGroup
                        value={filter.sortOrder}
                        onValueChange={(value) =>
                          setFilter({ sortOrder: value as 'asc' | 'desc' })
                        }
                      >
                        <DropdownMenuRadioItem value="asc">
                          Ascending
                        </DropdownMenuRadioItem>
                        <DropdownMenuRadioItem value="desc">
                          Descending
                        </DropdownMenuRadioItem>
                      </DropdownMenuRadioGroup>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
              </div>
            </div>
            <div className="flex-1 min-h-0">
              <LeadsList
                search={debouncedSearch}
                selectedLeadId={selectedLeadId}
                onLeadSelect={handleLeadSelect}
                filter={filter}
                className="sm:[&::-webkit-scrollbar]:block [&::-webkit-scrollbar]:hidden"
              />
            </div>
          </div>

          {/* Lead Details Pane */}
          <div className={cn(
            "flex-1 lg:flex-none lg:w-[calc(100%-20rem)] xl:w-[calc(100%-45rem)] lg:min-w-[25rem] h-[calc(100vh-9rem)] mt-[4rem] lg:mt-0 lg:h-full pb-20 lg:pb-0",
            !selectedLeadId && "hidden lg:block",
          )}>
            <LeadDetailsPane lead={lead} isLoading={isLoadingLead} selectedLeadId={selectedLeadId} setSelectedLeadId={setSelectedLeadId} />
          </div>

          {/* Communication Pane - Desktop Only */}
          <div className={cn(
            "w-full h-full hidden lg:block lg:w-[25rem]",
            !selectedLeadId && "hidden xl:block"
          )}>
            {!lead &&
                <div className="flex h-full items-center justify-center">
                  <div className="text-center text-gray-500">
                    <div className="mb-2">
                      <ChevronsUpDown className="h-8 w-8 mx-auto opacity-50" />
                    </div>
                    <p>Select a lead to view details</p>
                  </div>
                </div>
            }
            {lead && <CommunicationPane leadId={lead.id} phoneNumber={lead.phoneNumber} name={lead.name} className="h-full rounded-none" />}
          </div>
        </div>
      </PageContainer>
    </MainLayout>
  );
} 