import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { Bell } from 'lucide-react';
import { HiOutlineMenu } from 'react-icons/hi';
import { WebSocketNotifications, useNotificationStore } from '../websocket/WebSocketNotifications';

interface MobileHeaderProps {
  onMenuClick: () => void;
  pageTitle?: string;
  pageDescription?: string;
}

export function MobileHeader({ onMenuClick, pageTitle, pageDescription }: MobileHeaderProps) {
  const unreadCount = useNotificationStore((state) => state.unreadCount);
  
  return (
    <header className="fixed top-0 z-20 w-full border-b bg-white lg:hidden">
      <div className="flex h-16 items-center justify-between px-4">
        <button
          onClick={onMenuClick}
          className="rounded p-1 hover:bg-gray-100"
          aria-label="Open menu"
        >
          <HiOutlineMenu className="h-6 w-6 text-gray-500" />
        </button>
        
        <div className="flex flex-col items-center flex-1 mx-4 truncate">
          <h1 className="text-lg font-semibold text-gray-900 truncate">
            {pageTitle || "LeadGPT"}
          </h1>
          {pageDescription && (
            <p className="text-xs text-gray-500 truncate">{pageDescription}</p>
          )}
        </div>
        
        {/* Notifications Popover */}
        <Popover>
          <PopoverTrigger asChild>
            <Button
              variant="ghost"
              size="icon"
              className="p-0 h-9 w-9 relative"
              title="Notifications"
            >
              <Bell className="h-5 w-5 text-gray-500" />
              {unreadCount > 0 && (
                <Badge 
                  variant="destructive" 
                  className="absolute -top-1 -right-1 h-4 w-4 p-0 flex items-center justify-center text-[10px]"
                >
                  {unreadCount > 9 ? '9+' : unreadCount}
                </Badge>
              )}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-80 p-0" align="end">
            <WebSocketNotifications />
          </PopoverContent>
        </Popover>
      </div>
    </header>
  );
} 