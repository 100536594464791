import { axiosInstance } from '@/lib/api';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { BarChart3, Calendar, CheckCircle2, Clock, LucideIcon, MessageSquare, Phone, PhoneCall, PhoneIncoming, PhoneOff, Users } from 'lucide-react';

// Icon mapping
const iconMap: Record<string, LucideIcon> = {
  'Users': Users,
  'PhoneCall': PhoneCall,
  'BarChart3': BarChart3,
  'Clock': Clock,
  'MessageSquare': MessageSquare,
  'Phone': Phone,
  'PhoneIncoming': PhoneIncoming,
  'PhoneOff': PhoneOff,
  'Calendar': Calendar,
  'CheckCircle2': CheckCircle2
};

// Types for dashboard data
export interface DashboardMetric {
  name: string;
  value: string;
  change: string;
  trend: 'up' | 'down' | 'neutral';
  icon: LucideIcon;
}

export interface ActionItem {
  id: number | string;
  type: 'message' | 'call' | 'followup' | 'task';
  leadName: string;
  leadId: number;
  content: string;
  time: string;
  priority: 'high' | 'medium' | 'low';
  icon: LucideIcon;
  isCompleted?: boolean;
  messageId?: number;
  conversationId?: number;
}

export interface ActivityItem {
  id: number;
  type: 'message' | 'call' | 'status' | 'assignment';
  leadName: string;
  leadId: number;
  content: string;
  time: string;
  icon: LucideIcon;
  status?: 'inbound' | 'outbound' | 'missed' | 'no-answer' | 'completed' | 'in-progress';
}

export interface MetricItem {
  name: string;
  value: string;
  change: string;
  trend: 'up' | 'down' | 'neutral';
  icon: LucideIcon;
}

export interface CommunicationMetrics {
  smsResponseRate: {
    percentage: number;
    avgResponseTime: string;
  };
  callAnswerRate: {
    percentage: number;
    avgDuration: string;
  };
  emailOpenRate: {
    percentage: number;
    avgResponseTime: string;
  };
}

export interface TeamMember {
  id: number;
  name: string;
  avatar?: string;
  conversionRate: number;
  responseTime: string;
  leadsProcessed: number;
}

export interface DashboardData {
  todayMetrics: MetricItem[];
  actionItems: ActionItem[];
  recentActivity: ActivityItem[];
  communicationMetrics: CommunicationMetrics;
  teamMembers: TeamMember[];
  currentUserId: number;
}

// API functions
export const dashboardApi = {
  getDashboardData: async (timeframe: string = 'today'): Promise<DashboardData> => {
    try {
      const response = await axiosInstance.get(`/dashboard?timeframe=${timeframe}`);
      
      // Map string icon names to actual Lucide icon components
      const data = response.data;
      
      // Map icons for metrics
      if (data.todayMetrics) {
        data.todayMetrics = data.todayMetrics.map((metric: any) => ({
          ...metric,
          icon: iconMap[metric.icon] || Users
        }));
      }
      
      // Map icons for action items
      if (data.actionItems) {
        data.actionItems = data.actionItems.map((item: any) => ({
          ...item,
          icon: iconMap[item.icon] || MessageSquare
        }));
      }
      
      // Map icons for activity items
      if (data.recentActivity) {
        data.recentActivity = data.recentActivity.map((item: any) => ({
          ...item,
          icon: iconMap[item.icon] || MessageSquare
        }));
      }
      
      return data;
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
      // Return mock data for now
      return getMockDashboardData(timeframe);
    }
  },
  
  getActionItems: async (showCompleted: boolean = false, page: number = 1, pageSize: number = 10): Promise<{ 
    actionItems: ActionItem[], 
    hasCompletedItems: boolean,
    totalItems: number,
    totalPages: number
  }> => {
    try {
      const response = await axiosInstance.get(`/dashboard/action-items`, {
        params: {
          showCompleted,
          page,
          pageSize
        }
      });
      
      // Map string icon names to actual Lucide icon components
      const actionItems = response.data.actionItems.map((item: any) => ({
        ...item,
        icon: iconMap[item.icon] || MessageSquare
      }));
      
      return {
        actionItems,
        hasCompletedItems: response.data.hasCompletedItems,
        totalItems: response.data.totalItems || actionItems.length,
        totalPages: response.data.totalPages || 1
      };
    } catch (error) {
      console.error('Error fetching action items:', error);
      // Return mock data for now
      const mockData = getMockDashboardData();
      const start = (page - 1) * pageSize;
      const end = start + pageSize;
      const paginatedItems = mockData.actionItems.slice(start, end);
      
      return {
        actionItems: paginatedItems,
        hasCompletedItems: false,
        totalItems: mockData.actionItems.length,
        totalPages: Math.ceil(mockData.actionItems.length / pageSize)
      };
    }
  },
  
  getRecentActivity: async (page: number = 1, pageSize: number = 10): Promise<{ 
    recentActivity: ActivityItem[],
    totalItems: number,
    totalPages: number
  }> => {
    try {
      const response = await axiosInstance.get('/dashboard/recent-activity', {
        params: {
          page,
          pageSize
        }
      });
      
      // Map string icon names to actual Lucide icon components
      const recentActivity = response.data.recentActivity.map((item: any) => ({
        ...item,
        icon: iconMap[item.icon] || MessageSquare
      }));
      
      return {
        recentActivity,
        totalItems: response.data.totalItems || recentActivity.length,
        totalPages: response.data.totalPages || 1
      };
    } catch (error) {
      console.error('Error fetching recent activity:', error);
      // Return mock data for now
      const mockData = getMockDashboardData();
      const start = (page - 1) * pageSize;
      const end = start + pageSize;
      const paginatedItems = mockData.recentActivity.slice(start, end);
      
      return {
        recentActivity: paginatedItems,
        totalItems: mockData.recentActivity.length,
        totalPages: Math.ceil(mockData.recentActivity.length / pageSize)
      };
    }
  },
  
  getTeamData: async (): Promise<{ members: TeamMember[], currentUserId: number }> => {
    try {
      const response = await axiosInstance.get('/dashboard/team');
      return {
        members: response.data.members,
        currentUserId: response.data.currentUserId
      };
    } catch (error) {
      console.error('Error fetching team data:', error);
      // Return mock data for now
      return {
        members: getMockDashboardData().teamMembers,
        currentUserId: getMockDashboardData().currentUserId
      };
    }
  }
};

// React Query hooks
export function useDashboardData(timeframe: string = 'today') {
  return useQuery({
    queryKey: ['dashboard', timeframe],
    queryFn: () => dashboardApi.getDashboardData(timeframe),
    staleTime: 5 * 60 * 1000, // 5 minutes
  });
}

export function useActionItems(showCompleted: boolean = false) {
  return useInfiniteQuery({
    queryKey: ['dashboard', 'action-items', showCompleted],
    queryFn: async ({ pageParam = 1 }) => {
      console.log(`Fetching action items page ${pageParam}`);
      const result = await dashboardApi.getActionItems(showCompleted, pageParam);
      console.log(`Received ${result.actionItems.length} action items, total pages: ${result.totalPages}`);
      return {
        actionItems: result.actionItems,
        hasCompletedItems: result.hasCompletedItems,
        totalItems: result.totalItems,
        totalPages: result.totalPages,
        nextPage: pageParam < result.totalPages ? pageParam + 1 : undefined
      };
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      console.log(`Next page: ${lastPage.nextPage}`);
      return lastPage.nextPage;
    },
    staleTime: 1 * 60 * 1000, // 1 minute
    refetchInterval: 2 * 60 * 1000, // Refetch every 2 minutes
  });
}

export function useRecentActivity() {
  return useInfiniteQuery({
    queryKey: ['dashboard', 'recent-activity'],
    queryFn: async ({ pageParam = 1 }) => {
      console.log(`Fetching recent activity page ${pageParam}`);
      const result = await dashboardApi.getRecentActivity(pageParam);
      console.log(`Received ${result.recentActivity.length} activity items, total pages: ${result.totalPages}`);
      return {
        recentActivity: result.recentActivity,
        totalItems: result.totalItems,
        totalPages: result.totalPages,
        nextPage: pageParam < result.totalPages ? pageParam + 1 : undefined
      };
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      console.log(`Next activity page: ${lastPage.nextPage}`);
      return lastPage.nextPage;
    },
    staleTime: 1 * 60 * 1000, // 1 minute
    refetchInterval: 2 * 60 * 1000, // Refetch every 2 minutes
  });
}

export function useTeamData() {
  return useQuery({
    queryKey: ['dashboard', 'team'],
    queryFn: dashboardApi.getTeamData,
    staleTime: 10 * 60 * 1000, // 10 minutes
  });
}

// Mock data function (temporary until API is implemented)
function getMockDashboardData(timeframe: string = 'today'): DashboardData {
  return {
    todayMetrics: [
      {
        name: 'New Leads',
        value: timeframe === 'today' ? '12' : timeframe === 'week' ? '68' : '245',
        change: '+3',
        trend: 'up',
        icon: Users,
      },
      {
        name: 'Leads Contacted',
        value: timeframe === 'today' ? '18' : timeframe === 'week' ? '92' : '312',
        change: '+5',
        trend: 'up',
        icon: PhoneCall,
      },
      {
        name: 'Conversion Rate',
        value: '24.5%',
        change: '+4.3%',
        trend: 'up',
        icon: BarChart3,
      },
      {
        name: 'Avg. Response Time',
        value: '2.4m',
        change: '-0.5m',
        trend: 'down',
        icon: Clock,
      },
    ],
    actionItems: [
      {
        id: 1,
        type: 'message',
        leadName: 'John Smith',
        leadId: 123,
        content: 'New message about insurance options',
        time: '10 minutes ago',
        priority: 'high',
        icon: MessageSquare,
      },
      {
        id: 2,
        type: 'call',
        leadName: 'Sarah Johnson',
        leadId: 456,
        content: 'Missed call (2:34)',
        time: '1 hour ago',
        priority: 'high',
        icon: PhoneIncoming,
      },
      {
        id: 3,
        type: 'followup',
        leadName: 'Michael Brown',
        leadId: 789,
        content: 'Follow up on quote request',
        time: 'Due today',
        priority: 'medium',
        icon: Calendar,
      },
      {
        id: 4,
        type: 'task',
        leadName: 'Emily Davis',
        leadId: 101,
        content: 'Send policy information',
        time: 'Overdue by 1 day',
        priority: 'high',
        icon: CheckCircle2,
      },
    ],
    recentActivity: [
      {
        id: 1,
        type: 'message',
        leadName: 'Robert Wilson',
        leadId: 202,
        content: 'Sent: "Thanks for your interest in our services..."',
        time: '15 minutes ago',
        icon: MessageSquare,
      },
      {
        id: 2,
        type: 'call',
        leadName: 'Jennifer Lee',
        leadId: 303,
        content: 'Outbound call (4:12)',
        time: '30 minutes ago',
        icon: Phone,
      },
      {
        id: 3,
        type: 'status',
        leadName: 'David Martinez',
        leadId: 404,
        content: 'Status changed from "New" to "Contacted"',
        time: '1 hour ago',
        icon: CheckCircle2,
      },
      {
        id: 4,
        type: 'assignment',
        leadName: 'Lisa Thompson',
        leadId: 505,
        content: 'New lead assigned to you',
        time: '2 hours ago',
        icon: Users,
      },
      {
        id: 5,
        type: 'call',
        leadName: 'James Anderson',
        leadId: 606,
        content: 'Missed call (0:45)',
        time: '3 hours ago',
        icon: PhoneOff,
      },
    ],
    communicationMetrics: {
      smsResponseRate: {
        percentage: 92,
        avgResponseTime: '2.4 min'
      },
      callAnswerRate: {
        percentage: 78,
        avgDuration: '4:12'
      },
      emailOpenRate: {
        percentage: 64,
        avgResponseTime: '3.2 hrs'
      }
    },
    teamMembers: [
      {
        id: 1,
        name: 'You',
        conversionRate: 28.5,
        responseTime: '2.4 min',
        leadsProcessed: 45
      },
      {
        id: 2,
        name: 'Alex Johnson',
        conversionRate: 32.1,
        responseTime: '1.8 min',
        leadsProcessed: 52
      },
      {
        id: 3,
        name: 'Maria Garcia',
        conversionRate: 29.7,
        responseTime: '2.1 min',
        leadsProcessed: 48
      },
      {
        id: 4,
        name: 'David Kim',
        conversionRate: 25.3,
        responseTime: '3.2 min',
        leadsProcessed: 39
      },
      {
        id: 5,
        name: 'Sarah Williams',
        conversionRate: 27.8,
        responseTime: '2.7 min',
        leadsProcessed: 43
      },
      {
        id: 6,
        name: 'James Taylor',
        conversionRate: 24.2,
        responseTime: '3.5 min',
        leadsProcessed: 37
      }
    ],
    currentUserId: 1
  };
} 