import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { CheckCircle2 } from 'lucide-react';
import { useEffect } from 'react';

export const Route = createFileRoute('/calendar/auth-success')({
  component: CalendarAuthSuccess,
  validateSearch: (search: Record<string, unknown>) => {
    return {
      integration_id: search.integration_id as string | undefined,
    };
  }
});

function CalendarAuthSuccess() {
  const { integration_id } = Route.useSearch();
  const navigate = useNavigate();

  useEffect(() => {
    // If no integration_id is provided, redirect to integrations page
    if (!integration_id) {
      navigate({ to: '/integrations', search: { integration: 'google-calendar' } });
    }
  }, [integration_id, navigate]);

  const handleContinue = () => {
    navigate({ to: '/integrations', search: { integration: 'google-calendar' } });
  };

  return (
    <div className="container max-w-md py-12">
      <Card>
        <CardHeader className="text-center">
          <div className="flex justify-center mb-4">
            <CheckCircle2 className="h-16 w-16 text-green-500" />
          </div>
          <CardTitle className="text-2xl">Calendar Connected!</CardTitle>
          <CardDescription>
            Your Google Calendar has been successfully connected to LeadGPT.
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-4">
          <Alert className="bg-green-50 border-green-200">
            <AlertTitle>Integration ID: {integration_id}</AlertTitle>
            <AlertDescription>
              You can now schedule meetings with leads directly from LeadGPT.
            </AlertDescription>
          </Alert>
          <Button className="w-full" onClick={handleContinue}>
            Continue to Integrations
          </Button>
        </CardContent>
      </Card>
    </div>
  );
} 