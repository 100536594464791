import { ChevronsUpDown } from 'lucide-react';

interface LoadingStateProps {
  isLoading: boolean;
}

export function LoadingState({ isLoading }: LoadingStateProps) {
  if (!isLoading) return null;
  
  return (
    <div className="flex h-full items-center justify-center">
      <div className="animate-spin rounded-full h-8 w-8 border-2 border-gray-300 border-t-gray-900"></div>
    </div>
  );
}

export function EmptyState() {
  return (
    <div className="flex h-full items-center justify-center">
      <div className="text-center text-gray-500">
        <div className="mb-2">
          <ChevronsUpDown className="h-8 w-8 mx-auto opacity-50" />
        </div>
        <p>Select a lead to view details</p>
      </div>
    </div>
  );
} 