import { axiosInstance } from '@/lib/api';

export interface Attachment {
  id: number;
  leadId: number;
  userId: number;
  fileName: string;
  fileKey: string;
  fileType: string;
  fileSize: number;
  originalName: string;
  description?: string;
  createdAt: string;
  updatedAt: string;
  downloadUrl?: string;
}

export interface UploadUrlResponse {
  success: boolean;
  uploadUrl: string;
  fileKey: string;
}

export const attachmentsApi = {
  getLeadAttachments: async (leadId: number) => {
    const { data } = await axiosInstance.get<{ success: boolean; attachments: Attachment[] }>(
      `/attachments/lead/${leadId}`
    );
    return data.attachments;
  },

  getUploadUrl: async (leadId: number, fileName: string, fileType: string) => {
    const { data } = await axiosInstance.post<UploadUrlResponse>(`/attachments/lead/${leadId}/upload-url`, {
      fileName,
      fileType
    });
    return data;
  },

  createAttachment: async (leadId: number, data: {
    fileName: string;
    fileKey: string;
    fileType: string;
    fileSize: number;
    originalName: string;
    description?: string;
  }) => {
    const response = await axiosInstance.post<{ success: boolean; attachment: Attachment }>(
      `/attachments/lead/${leadId}`,
      data
    );
    return response.data.attachment;
  },

  deleteAttachment: async (attachmentId: number) => {
    const { data } = await axiosInstance.delete<{ success: boolean }>(
      `/attachments/${attachmentId}`
    );
    return data.success;
  },

  uploadFile: async (
    uploadUrl: string,
    file: File,
    onProgress?: (progress: number) => void
  ) => {
    console.log('[attachmentsApi] Starting S3 upload:', { 
      uploadUrl, 
      fileName: file.name,
      fileType: file.type,
      fileSize: file.size
    });
    
    try {
      // Log the request details
      console.log('[attachmentsApi] Making PUT request to S3 with headers:', {
        'Content-Type': file.type
      });

      // Use XMLHttpRequest for upload progress tracking
      await new Promise<void>((resolve, reject) => {
        const xhr = new XMLHttpRequest();

        // Track upload progress
        xhr.upload.onprogress = (event) => {
          if (event.lengthComputable && onProgress) {
            const percentComplete = (event.loaded / event.total) * 100;
            onProgress(percentComplete);
          }
        };

        // Handle response
        xhr.onload = () => {
          if (xhr.status >= 200 && xhr.status < 300) {
            console.log('[attachmentsApi] S3 upload successful');
            resolve();
          } else {
            console.error('[attachmentsApi] S3 upload failed:', {
              status: xhr.status,
              statusText: xhr.statusText,
              response: xhr.responseText
            });
            reject(new Error(`Upload failed: ${xhr.statusText}`));
          }
        };

        // Handle network errors
        xhr.onerror = () => {
          console.error('[attachmentsApi] Network error during upload');
          reject(new Error('Network error during upload'));
        };

        // Open and send the request
        xhr.open('PUT', uploadUrl);
        xhr.setRequestHeader('Content-Type', file.type);
        xhr.send(file);
      });

      return { success: true };
    } catch (error) {
      // Log any errors
      console.error('[attachmentsApi] Error during S3 upload:', {
        error,
        errorMessage: error instanceof Error ? error.message : 'Unknown error',
        errorStack: error instanceof Error ? error.stack : undefined
      });
      throw error;
    }
  }
}; 