import { AddOnCard } from './AddOnCard';
import { PlanCard } from './PlanCard';
import { Product } from './types';
import { getPlanAction } from './utils';

interface LeadGPTPlansSectionProps {
  mainPlans: Product[];
  addonPlans: Product[];
  currentSubscription: any;
  allProducts: Product[];
  isLoadingProducts: boolean;
  isLoadingSubscription: boolean;
  onSubscribe: (priceId: string) => void;
}

export function LeadGPTPlansSection({
  mainPlans,
  addonPlans,
  currentSubscription,
  allProducts,
  isLoadingProducts,
  isLoadingSubscription,
  onSubscribe
}: LeadGPTPlansSectionProps) {
  return (
    <>
      <div className="mb-6">
        <h2 className="text-xl font-semibold">Standard Plans</h2>
        <p className="text-sm text-muted-foreground mt-1">
          Choose the plan that fits your needs. All plans include full access to AI features with different capacity limits.
        </p>
      </div>

      {isLoadingProducts || isLoadingSubscription ? (
        <div className="grid md:grid-cols-3 gap-6 max-w-5xl mx-auto">
          {[1, 2, 3].map((i) => (
            <div key={i} className="h-80 bg-muted rounded-lg animate-pulse"></div>
          ))}
        </div>
      ) : (
        <div className="grid md:grid-cols-3 gap-6 max-w-5xl mx-auto">
          {mainPlans.map(product => (
            <PlanCard 
              key={product.id}
              product={product}
              planAction={getPlanAction(product, currentSubscription, allProducts)}
              onSubscribe={onSubscribe}
            />
          ))}
        </div>
      )}

      {/* Add-on Packages */}
      {addonPlans.length > 0 && !isLoadingProducts && !isLoadingSubscription && (
        <div className="mt-12 max-w-5xl mx-auto">
          <div className="mb-6">
            <h2 className="text-xl font-semibold">Add-On Packages</h2>
            <p className="text-sm text-muted-foreground mt-1">
              Enhance your subscription with these additional packages to boost your capacity.
            </p>
          </div>
          
          <div className="grid md:grid-cols-2 gap-6">
            {addonPlans.map(product => (
              <AddOnCard 
                key={product.id}
                product={product}
                planAction={getPlanAction(product, currentSubscription, allProducts)}
                onSubscribe={onSubscribe}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
} 