import { User } from '@/types/user';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export interface AuthState {
  token: string | null;
  user: User | null;
  setToken: (token: string | null) => void;
  setUser: (user: User | null) => void;
  setAuth: (token: string | null, user: User | null) => void;
  logout: () => void;
}

// Helper function to log token operations
const logToken = (action: string, token: string | null) => {
  console.log(`[AuthStore] ${action} token:`, token ? `${token.substring(0, 15)}...` : 'null');
  console.log(`[AuthStore] Token length:`, token?.length || 0);
};

export const useAuthStore = create<AuthState>()(
  persist(
    (set) => ({
      token: null,
      user: null,
      
      setToken: (token: string | null) => {
        logToken('Setting', token);
        set({ token });
        
        // Update axios headers if needed
        if (token) {
          console.log('[AuthStore] Updating localStorage with token');
          localStorage.setItem('auth_token', token);
        } else {
          console.log('[AuthStore] Removing token from localStorage');
          localStorage.removeItem('auth_token');
          
          // Reset team storage when token is cleared
          console.log('[AuthStore] Clearing team storage due to token reset');
          localStorage.removeItem('team-storage-no-user');
          // Note: We don't remove user-specific team storage to preserve it for future logins
        }
      },
      
      setUser: (user: User | null) => {
        console.log('[AuthStore] Setting user:', user ? `${user.name} (${user.id})` : 'null');
        
        // If the user changed, we need to update our stores accordingly
        const prevUser = useAuthStore.getState().user;
        const userChanged = (!prevUser && user) || (prevUser && !user) || 
          (prevUser && user && prevUser.id !== user.id);
        
        if (userChanged) {
          console.log('[AuthStore] User changed, resetting team-storage-no-user');
          localStorage.removeItem('team-storage-no-user');
        }
        
        set({ user });
      },
      
      setAuth: (token: string | null, user: User | null) => {
        console.log('[AuthStore] Setting auth state');
        logToken('Setting', token);
        console.log('[AuthStore] Setting user:', user ? `${user.name} (${user.id})` : 'null');
        
        // Get previous user to determine if user changed
        const prevUser = useAuthStore.getState().user;
        const userChanged = (!prevUser && user) || (prevUser && !user) || 
          (prevUser && user && prevUser.id !== user.id);
        
        if (userChanged) {
          console.log('[AuthStore] User changed, resetting team-storage-no-user');
          localStorage.removeItem('team-storage-no-user');
        }
        
        // Update localStorage
        if (token) {
          console.log('[AuthStore] Updating localStorage with token');
          localStorage.setItem('auth_token', token);
        } else {
          console.log('[AuthStore] Removing token from localStorage');
          localStorage.removeItem('auth_token');
          
          // When logging out or changing users, we should clear non-user-specific team storage
          console.log('[AuthStore] Clearing team storage due to auth change');
          localStorage.removeItem('team-storage-no-user');
        }
        
        // Update state
        set({ token, user });
      },
      
      logout: () => {
        console.log('[AuthStore] Logging out, clearing token and user');
        
        // Clear auth token
        localStorage.removeItem('auth_token');
        
        // Clear non-user-specific team storage
        console.log('[AuthStore] Clearing team storage due to logout');
        localStorage.removeItem('team-storage-no-user');
        
        // Clear auth state
        set({ token: null, user: null });
      }
    }),
    {
      name: 'auth-storage',
      onRehydrateStorage: () => {
        return (state) => {
          if (state) {
            logToken('Rehydrated', state.token);
            
            // Ensure token is also in localStorage
            if (state.token) {
              const localStorageToken = localStorage.getItem('auth_token');
              if (!localStorageToken) {
                console.log('[AuthStore] Updating localStorage with rehydrated token');
                localStorage.setItem('auth_token', state.token);
              } else if (localStorageToken !== state.token) {
                console.log('[AuthStore] Token in localStorage differs from rehydrated token, updating localStorage');
                localStorage.setItem('auth_token', state.token);
              }
            }
          }
        };
      }
    }
  )
); 