import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Textarea } from "@/components/ui/textarea";
import { toast } from "@/components/ui/use-toast";
import { CalendarEvent, CalendarService } from "@/services/calendar.service";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Loader2 } from "lucide-react";
import { useEffect, useState } from "react";

interface EventOutcomeDialogProps {
  event: CalendarEvent;
  isOpen: boolean;
  onClose: () => void;
}

export function EventOutcomeDialog({ event, isOpen, onClose }: EventOutcomeDialogProps) {
  const [outcome, setOutcome] = useState(event.outcome || '');
  const queryClient = useQueryClient();
  
  // Update outcome state when event prop changes or dialog opens
  useEffect(() => {
    if (isOpen) {
      setOutcome(event.outcome || '');
    }
  }, [event.outcome, isOpen]);
  
  // Update event outcome mutation
  const { mutate: updateOutcome, isPending: isSubmitting } = useMutation({
    mutationFn: async () => {
      // Update the event with the new outcome
      return await CalendarService.updateEvent(event.id, { outcome });
    },
    onSuccess: () => {
      // Invalidate relevant queries with exact: false to ensure complete refresh
      queryClient.invalidateQueries({ 
        queryKey: ['calendarEvents'],
        exact: false
      });
      
      // Also invalidate the specific event queries
      queryClient.invalidateQueries({ 
        queryKey: ['calendarEvent', event.id],
        exact: true
      });
      
      // Also invalidate leadEvents query if this event is connected to a lead
      if (event.leadId) {
        queryClient.invalidateQueries({ 
          queryKey: ['leadEvents', event.leadId],
          exact: true
        });
        queryClient.invalidateQueries({ 
          queryKey: ['lead', event.leadId],
          exact: false
        });
      }
      
      toast({
        title: "Outcome recorded",
        description: "The meeting outcome has been saved successfully.",
      });
      
      onClose();
    },
    onError: (error) => {
      console.error("Error updating event outcome:", error);
      toast({
        title: "Failed to record outcome",
        description: "There was an error saving the meeting outcome. Please try again.",
        variant: "destructive",
      });
    }
  });
  
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    updateOutcome();
  };
  
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[500px]">
        <DialogHeader>
          <DialogTitle>Record Meeting Outcome</DialogTitle>
          <DialogDescription>
            Document what happened in your meeting with {event.leadId ? 'this lead' : 'this contact'}.
          </DialogDescription>
        </DialogHeader>
        
        <form onSubmit={handleSubmit} className="space-y-4 pt-4">
          <div className="space-y-2">
            <label htmlFor="outcome" className="text-sm font-medium">
              Meeting Outcome
            </label>
            <Textarea
              id="outcome"
              placeholder="What was discussed? What was the result of this meeting?"
              value={outcome}
              onChange={(e) => setOutcome(e.target.value)}
              className="min-h-[150px]"
              required
            />
          </div>
          
          <DialogFooter>
            <Button variant="outline" onClick={onClose} type="button">
              Cancel
            </Button>
            <Button type="submit" disabled={isSubmitting}>
              {isSubmitting ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Saving...
                </>
              ) : (
                "Save Outcome"
              )}
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
} 