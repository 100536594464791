import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import {
  ArrowLeft,
  ArrowRight,
  Book,
  ChevronRight,
  Headphones,
  HelpCircle,
  LayoutGrid,
  LifeBuoy,
  Mail,
  MessageSquare,
  Phone,
  Search,
  Settings,
  Sparkles,
  Users,
  Video
} from "lucide-react";

export function Support() {
  const categories = [
    {
      icon: <Settings className="h-6 w-6" />,
      title: "Getting Started",
      description: "Learn the basics of LeadGPT and set up your account",
      articles: ["Account Setup", "Team Configuration", "First Steps"]
    },
    {
      icon: <Sparkles className="h-6 w-6" />,
      title: "AI Features",
      description: "Understand and optimize AI-powered features",
      articles: ["Call Analysis", "Lead Scoring", "Task Automation"]
    },
    {
      icon: <Phone className="h-6 w-6" />,
      title: "Phone System",
      description: "Configure and manage your phone system",
      articles: ["Local Numbers", "Call Routing", "Voicemail Setup"]
    },
    {
      icon: <Users className="h-6 w-6" />,
      title: "Team Management",
      description: "Manage your team and permissions",
      articles: ["User Roles", "Access Control", "Team Analytics"]
    },
    {
      icon: <MessageSquare className="h-6 w-6" />,
      title: "Communication",
      description: "Set up and manage communication channels",
      articles: ["Email Integration", "SMS Setup", "Chat Configuration"]
    },
    {
      icon: <LayoutGrid className="h-6 w-6" />,
      title: "Integrations",
      description: "Connect with your existing tools",
      articles: ["CRM Integration", "Calendar Sync", "Custom APIs"]
    }
  ];

  const faqs = [
    {
      question: "How do I get started with LeadGPT?",
      answer: "Getting started is easy! First, sign up for a free trial, then follow our quick start guide to set up your account, configure your team, and start using our AI features."
    },
    {
      question: "What phone systems do you integrate with?",
      answer: "We integrate with most major VoIP providers including RingCentral, Vonage, and Twilio. We also support custom integrations through our API."
    },
    {
      question: "How does the AI call analysis work?",
      answer: "Our AI analyzes your calls in real-time, identifying key discussion points, sentiment, and action items. It then provides insights and suggestions for follow-up actions."
    },
    {
      question: "Is LeadGPT HIPAA compliant?",
      answer: "Yes, LeadGPT is fully HIPAA compliant. We maintain strict security protocols and sign Business Associate Agreements (BAA) with all customers."
    }
  ];

  const contactMethods = [
    {
      icon: <MessageSquare className="h-6 w-6" />,
      title: "Live Chat",
      description: "Chat with our support team",
      availability: "24/7 Support",
      action: "Start Chat",
      variant: "default" as const
    },
    {
      icon: <Mail className="h-6 w-6" />,
      title: "Email Support",
      description: "Send us an email",
      availability: "Response within 24h",
      action: "Send Email",
      variant: "outline" as const
    },
    {
      icon: <Phone className="h-6 w-6" />,
      title: "Phone Support",
      description: "Call our support team",
      availability: "Mon-Fri, 9am-5pm EST",
      action: "Call Now",
      variant: "outline" as const
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="container mx-auto px-4 py-12">
        {/* Back Button */}
        <a href="https://leadgpt.com" className="inline-block mb-8">
          <Button variant="ghost" className="gap-2">
            <ArrowLeft className="h-4 w-4" />
            Back to Home
          </Button>
        </a>

        <div className="max-w-7xl mx-auto">
          {/* Hero Section */}
          <div className="text-center mb-12">
            <h1 className="text-4xl font-bold mb-4">How Can We Help?</h1>
            <p className="text-lg text-gray-600 mb-8">
              Find answers, documentation, and connect with our support team
            </p>
            <div className="max-w-2xl mx-auto relative">
              <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
              <Input 
                className="pl-12 h-12 text-lg" 
                placeholder="Search our knowledge base..." 
              />
            </div>
          </div>

          {/* Help Categories */}
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6 mb-16">
            {categories.map((category, index) => (
              <div key={index} className="bg-white rounded-xl p-6 border border-gray-200 hover:shadow-md transition-shadow">
                <div className="w-12 h-12 rounded-lg bg-primary/10 flex items-center justify-center text-primary mb-4">
                  {category.icon}
                </div>
                <h3 className="text-xl font-bold mb-2">{category.title}</h3>
                <p className="text-gray-600 mb-4">{category.description}</p>
                <ul className="space-y-2">
                  {category.articles.map((article, articleIndex) => (
                    <li key={articleIndex}>
                      <a href="#" className="flex items-center text-sm text-gray-600 hover:text-primary">
                        <ChevronRight className="h-4 w-4 mr-1" />
                        {article}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>

          {/* Contact Options */}
          <div className="mb-16">
            <h2 className="text-2xl font-bold mb-8 text-center">Get in Touch</h2>
            <div className="grid md:grid-cols-3 gap-6">
              {contactMethods.map((method, index) => (
                <div key={index} className="bg-white rounded-xl p-6 border border-gray-200">
                  <div className="w-12 h-12 rounded-lg bg-primary/10 flex items-center justify-center text-primary mb-4">
                    {method.icon}
                  </div>
                  <h3 className="text-xl font-bold mb-2">{method.title}</h3>
                  <p className="text-gray-600 mb-1">{method.description}</p>
                  <p className="text-sm text-gray-500 mb-4">{method.availability}</p>
                  <Button variant={method.variant} className="w-full gap-2">
                    {method.action}
                    <ArrowRight className="h-4 w-4" />
                  </Button>
                </div>
              ))}
            </div>
          </div>

          {/* Support Resources */}
          <div className="grid md:grid-cols-2 gap-8 mb-16">
            {/* Submit Ticket */}
            <div className="bg-white rounded-xl p-6 border border-gray-200">
              <div className="w-12 h-12 rounded-lg bg-primary/10 flex items-center justify-center text-primary mb-4">
                <LifeBuoy className="h-6 w-6" />
              </div>
              <h3 className="text-xl font-bold mb-4">Submit a Support Ticket</h3>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Subject</label>
                  <Input placeholder="Brief description of your issue" />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Description</label>
                  <Textarea 
                    placeholder="Please provide details about your issue..." 
                    className="min-h-[120px]"
                  />
                </div>
                <Button className="w-full">Submit Ticket</Button>
              </div>
            </div>

            {/* Additional Resources */}
            <div className="space-y-6">
              <div className="bg-white rounded-xl p-6 border border-gray-200">
                <div className="flex gap-4">
                  <div className="w-12 h-12 rounded-lg bg-primary/10 flex items-center justify-center text-primary flex-shrink-0">
                    <Video className="h-6 w-6" />
                  </div>
                  <div>
                    <h3 className="text-xl font-bold mb-2">Video Tutorials</h3>
                    <p className="text-gray-600 mb-4">
                      Watch step-by-step guides on how to use LeadGPT's features
                    </p>
                    <Button variant="outline" className="gap-2">
                      Watch Tutorials
                      <ArrowRight className="h-4 w-4" />
                    </Button>
                  </div>
                </div>
              </div>

              <div className="bg-white rounded-xl p-6 border border-gray-200">
                <div className="flex gap-4">
                  <div className="w-12 h-12 rounded-lg bg-primary/10 flex items-center justify-center text-primary flex-shrink-0">
                    <Book className="h-6 w-6" />
                  </div>
                  <div>
                    <h3 className="text-xl font-bold mb-2">Documentation</h3>
                    <p className="text-gray-600 mb-4">
                      Browse our detailed documentation and API references
                    </p>
                    <Button variant="outline" className="gap-2">
                      View Docs
                      <ArrowRight className="h-4 w-4" />
                    </Button>
                  </div>
                </div>
              </div>

              <div className="bg-white rounded-xl p-6 border border-gray-200">
                <div className="flex gap-4">
                  <div className="w-12 h-12 rounded-lg bg-primary/10 flex items-center justify-center text-primary flex-shrink-0">
                    <Headphones className="h-6 w-6" />
                  </div>
                  <div>
                    <h3 className="text-xl font-bold mb-2">Schedule a Call</h3>
                    <p className="text-gray-600 mb-4">
                      Book a call with our support team for personalized help
                    </p>
                    <Button variant="outline" className="gap-2">
                      Book Call
                      <ArrowRight className="h-4 w-4" />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* FAQ Section */}
          <div>
            <h2 className="text-2xl font-bold mb-8 text-center">Frequently Asked Questions</h2>
            <div className="max-w-3xl mx-auto space-y-6">
              {faqs.map((faq, index) => (
                <div key={index} className="bg-white rounded-xl p-6 border border-gray-200">
                  <div className="flex gap-4">
                    <div className="flex-shrink-0">
                      <HelpCircle className="h-6 w-6 text-primary" />
                    </div>
                    <div>
                      <h3 className="text-lg font-bold mb-2">{faq.question}</h3>
                      <p className="text-gray-600">{faq.answer}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
} 