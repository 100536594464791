import { Button } from '@/components/ui/button';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Textarea } from '@/components/ui/textarea';
import { api, type MessageTemplate } from '@/lib/api';
import { useLeadStore } from '@/store/leadStore';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { toast } from 'sonner';

interface TemplateEditorProps {
  template?: MessageTemplate;
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export function TemplateEditor({ template, open, onOpenChange }: TemplateEditorProps) {
  const [label, setLabel] = useState(template?.label || '');
  const [text, setText] = useState(template?.text || '');
  const queryClient = useQueryClient();
  
  // Get lead name utilities from the store
  const getFirstName = useLeadStore((state) => state.getFirstName);
  const getLastName = useLeadStore((state) => state.getLastName);
  const selectedLead = useLeadStore((state) => state.selectedLead);

  // Update form state when template changes or modal opens
  useEffect(() => {
    if (open) {
      setLabel(template?.label || '');
      setText(template?.text || '');
    }
  }, [template, open]);

  const { mutate: saveTemplate, isPending } = useMutation({
    mutationFn: async () => {
      if (template?.id) {
        // Convert string id to number if needed
        const templateId = typeof template.id === 'string' 
          ? parseInt(template.id, 10) 
          : template.id;
          
        return api.templates.update(templateId, { label, text });
      } else {
        return api.templates.create({ label, text });
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['templates'] });
      toast.success(template?.id ? 'Template updated' : 'Template created');
      onOpenChange(false);
    },
    onError: (error) => {
      toast.error(error instanceof Error ? error.message : 'Failed to save template');
    },
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!label.trim() || !text.trim()) {
      toast.error('Please fill in all fields');
      return;
    }
    saveTemplate();
  };

  // Insert template variables
  const insertVariable = (variable: string) => {
    setText((currentText) => currentText + variable);
  };

  // Get preview text with variables replaced
  const getPreviewText = (templateText: string): string => {
    return templateText
      .replace(/\{firstName\}/g, getFirstName() || '[First Name]')
      .replace(/\{lastName\}/g, getLastName() || '[Last Name]')
      .replace(/\{fullName\}/g, selectedLead.name || '[Full Name]');
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="bg-white dark:bg-gray-900 border-gray-200 dark:border-gray-800 text-gray-900 dark:text-gray-100 sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{template?.id ? 'Edit Template' : 'New Template'}</DialogTitle>
          <DialogDescription className="text-gray-500 dark:text-gray-400">
            Create or edit a message template. Templates can be used to quickly send common messages.
          </DialogDescription>
        </DialogHeader>
        <form onSubmit={handleSubmit} className="space-y-4 py-4">
          <div className="space-y-2">
            <Label htmlFor="label" className="text-gray-700 dark:text-gray-200">Template Name</Label>
            <Input
              id="label"
              value={label}
              onChange={(e) => setLabel(e.target.value)}
              placeholder="e.g., Introduction"
              className="bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-700 text-gray-900 dark:text-gray-100 placeholder:text-gray-400 dark:placeholder:text-gray-500 focus-visible:ring-blue-500 focus-visible:ring-offset-0"
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="text" className="text-gray-700 dark:text-gray-200">Message Text</Label>
            <Textarea
              id="text"
              value={text}
              onChange={(e) => setText(e.target.value)}
              placeholder="Enter your message template..."
              className="min-h-[100px] bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-700 text-gray-900 dark:text-gray-100 placeholder:text-gray-400 dark:placeholder:text-gray-500 focus-visible:ring-blue-500 focus-visible:ring-offset-0"
            />
            <div className="flex flex-wrap gap-2 mt-2">
              <Button 
                type="button" 
                variant="outline" 
                size="sm"
                onClick={() => insertVariable('{firstName}')}
              >
                Insert First Name
              </Button>
              <Button 
                type="button" 
                variant="outline" 
                size="sm"
                onClick={() => insertVariable('{lastName}')}
              >
                Insert Last Name
              </Button>
              <Button 
                type="button" 
                variant="outline" 
                size="sm"
                onClick={() => insertVariable('{fullName}')}
              >
                Insert Full Name
              </Button>
            </div>
          </div>
          
          {text && (
            <div className="space-y-2 p-3 border border-gray-200 dark:border-gray-700 rounded-md">
              <Label className="text-gray-700 dark:text-gray-200">Preview</Label>
              <p className="text-sm text-gray-600 dark:text-gray-400">
                {getPreviewText(text)}
              </p>
            </div>
          )}
          
          <DialogFooter className="gap-2 sm:gap-0">
            <Button
              type="button"
              variant="ghost"
              onClick={() => onOpenChange(false)}
              className="text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-800"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={isPending}
              className="bg-blue-600 hover:bg-blue-700 text-white"
            >
              {isPending ? 'Saving...' : template?.id ? 'Save Changes' : 'Create Template'}
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
} 