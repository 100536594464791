import { Card, CardContent } from '@/components/ui/card';
import { ReactNode } from 'react';

interface ArticleLayoutProps {
  children: ReactNode;
}

export function ArticleLayout({ children }: ArticleLayoutProps) {
  return (
    <Card>
      <CardContent className="py-6 prose prose-blue max-w-none">
        <div className="article-content">
          {children}
        </div>
      </CardContent>
    </Card>
  );
} 