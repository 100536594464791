import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useToast } from "@/components/ui/use-toast";
import { useUserNumbers } from "@/hooks/did";
import { formatDuration, formatPhoneNumber } from "@/lib/utils";
import {
  aiReceptionistKeys,
  useAssistantTemplates,
  useCallLogs,
  useExportCallLogs,
  useReceptionistMetrics,
  useReceptionistSettings,
  useUpdateReceptionistSettings
} from "@/services/ai-receptionist";
import { useLeadStore } from "@/store/leadStore";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate, useSearch } from "@tanstack/react-router";
import {
  BarChart3,
  Calendar,
  CheckCircle,
  Clock,
  Headphones,
  Loader2,
  MessageSquare,
  Phone,
  Settings,
  UserCircle,
  UserPlus,
  XCircle
} from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { DIDManager } from "../settings/DIDManager";
import { AIReceptionistSettings } from "./AIReceptionistSettings";
import { AIReceptionistWizard } from "./AIReceptionistWizard";
import { CallLogs } from "./CallLogs";
import { CallTranscriptViewer } from "./CallTranscriptViewer";
import { ExportCallLogsDialog, ExportCallLogsOptions } from "./ExportCallLogsDialog";
import { RealTimeCallStatus } from "./RealTimeCallStatus";

interface CallMetric {
  title: string;
  value: string | number;
  change: string;
  trend: "up" | "down" | "neutral";
  icon: React.ReactNode;
  color: string;
}

export function AIReceptionistDashboard() {
  const { toast } = useToast();
  const navigate = useNavigate();
  const { tab } = useSearch({ from: '/ai-receptionist' });
  const [activeTab, setActiveTab] = useState("overview");
  const [selectedCallId, setSelectedCallId] = useState<string | null>(null);
  const [isTranscriptOpen, setIsTranscriptOpen] = useState(false);
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState<string | null>(null);
  const [isChangingTemplate, setIsChangingTemplate] = useState(false);
  // Fetch data from API
  const { data: settings } = useReceptionistSettings();
  const { data: metricsData } = useReceptionistMetrics();
  const { data: callLogsData } = useCallLogs({ limit: 5 });
  const { data: userNumbers } = useUserNumbers();
  const { data: templates } = useAssistantTemplates();
  const { setSelectedLead } = useLeadStore();
  const updateSettings = useUpdateReceptionistSettings();
  const exportCallLogs = useExportCallLogs();
  const queryClient = useQueryClient();
  
  // Log when metrics data changes
  useEffect(() => {
    console.log('[AIReceptionistDashboard] Metrics data updated:', metricsData);
  }, [metricsData]);
  
  // Handle tab from URL
  useEffect(() => {
    // If no tab is specified, default to "overview"
    if (!tab) {
      navigate({
        to: '/ai-receptionist',
        search: (prev) => ({ ...prev, tab: "overview" }),
        replace: true
      });
    } else {
      setActiveTab(tab);
    }
  }, [tab, navigate]);

  // Check if user has any assistant templates and phone numbers
  const hasTemplates = templates && templates.length > 0;
  const hasPhoneNumbers = userNumbers && userNumbers.length > 0;

  // Determine if we should show the wizard
  const shouldShowWizard = (!hasTemplates || !hasPhoneNumbers) ? true : false;
  
  // Find the active template name
  const activeTemplateName = useMemo(() => {
    if (!templates || !settings?.activeTemplateId) return "None";
    const activeTemplate = templates.find(t => t.id === settings.activeTemplateId);
    return activeTemplate ? activeTemplate.name : "None";
  }, [templates, settings?.activeTemplateId]);

  // Generate metrics based on call logs data
  const callMetrics: CallMetric[] = useMemo(() => [
    {
      title: "Total Calls Handled",
      value: callLogsData?.pagination?.totalCount || 0,
      change: metricsData?.callsChange || "+0%",
      trend: metricsData?.callsTrend || "neutral",
      icon: <Phone className="h-4 w-4" />,
      color: "text-blue-500"
    },
    {
      title: "Appointments Set",
      value: metricsData?.appointmentsSet || 0,
      change: metricsData?.appointmentsChange || "+0%",
      trend: metricsData?.appointmentsTrend || "neutral",
      icon: <Calendar className="h-4 w-4" />,
      color: "text-green-500"
    },
    {
      title: "Avg. Call Duration",
      value: metricsData?.avgCallDuration ? formatDuration(metricsData.avgCallDuration) : "0:00",
      change: metricsData?.durationChange || "0:00",
      trend: metricsData?.durationTrend || "neutral",
      icon: <Clock className="h-4 w-4" />,
      color: "text-purple-500"
    },
    {
      title: "New Leads Captured",
      value: metricsData?.leadsCaptures || 0,
      change: metricsData?.leadsChange || "+0%",
      trend: metricsData?.leadsTrend || "neutral",
      icon: <UserPlus className="h-4 w-4" />,
      color: "text-orange-500"
    }
  ], [metricsData, callLogsData?.pagination?.totalCount]);

  const getStatusIcon = (status: string) => {
    switch (status) {
      case "completed":
        return <CheckCircle className="h-4 w-4 text-green-500" />;
      case "missed":
        return <XCircle className="h-4 w-4 text-red-500" />;
      case "scheduled":
        return <Calendar className="h-4 w-4 text-blue-500" />;
      default:
        return <Phone className="h-4 w-4 text-gray-500" />;
    }
  };

  const getLeadScoreColor = (score: number | string) => {
    const numScore = typeof score === 'string' ? parseFloat(score) * 100 : score * 100;
    if (numScore >= 80) return "text-green-500";
    if (numScore >= 60) return "text-yellow-500";
    return "text-red-500";
  };
  
  const handleViewTranscript = (callId: string) => {
    setSelectedCallId(callId);
    setIsTranscriptOpen(true);
  };

  const handleNavigateToLead = (leadId: number) => {
    if (leadId) {
      // Update the lead store with the selected lead ID
      setSelectedLead({ id: leadId });
      
      // Navigate to the leads page
      navigate({ to: '/leads' });
    }
  };

  // Handle changing the active template
  const handleChangeTemplate = (templateId: string) => {
    if (!settings) return;
    
    setIsChangingTemplate(true);
    
    const updatedSettings = {
      ...settings,
      activeTemplateId: templateId
    };
    
    updateSettings.mutate(updatedSettings, {
      onSuccess: () => {
        toast({
          title: "Template updated",
          description: "Your active AI Assistant template has been updated.",
        });
        setIsChangingTemplate(false);
      },
      onError: (error) => {
        toast({
          title: "Error updating template",
          description: "There was an error updating your active template. Please try again.",
          variant: "destructive"
        });
        console.error("Error updating template:", error);
        setIsChangingTemplate(false);
      }
    });
  };

  // Handle exporting call logs
  const handleExportCallLogs = (options: ExportCallLogsOptions) => {
    exportCallLogs.mutate(options, {
      onSuccess: (result) => {
        if (result.success) {
          if (options.emailTo) {
            toast({
              title: "Report sent",
              description: `The report has been sent to ${options.emailTo}`,
            });
          } else {
            toast({
              title: "Report downloaded",
              description: "The report has been downloaded successfully",
            });
          }
        } else {
          toast({
            title: "Export failed",
            description: result.message || "Failed to export call logs",
            variant: "destructive"
          });
        }
      },
      onError: (error) => {
        toast({
          title: "Export failed",
          description: "There was an error exporting the call logs. Please try again.",
          variant: "destructive"
        });
        console.error("Error exporting call logs:", error);
      }
    });
  };

  return (
    <div className="space-y-6">
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4">
        <div className="space-y-2">
          <h1 className="text-3xl font-bold tracking-tight">Receptionist</h1>
          <p className="text-lg text-muted-foreground">
            Manage your virtual assistant that handles calls when you're unavailable. Speak to an example receptionist at (325) 422 1750
          </p>
        </div>
        <div className="flex items-center gap-2">
          <ExportCallLogsDialog onExport={handleExportCallLogs} />
          <Button 
            size="sm" 
            className="h-8 gap-1"
            onClick={() => setActiveTab("settings")}
          >
            <Settings className="h-4 w-4" />
            <span>Settings</span>
          </Button>
        </div>
      </div>

      <Tabs value={activeTab} onValueChange={setActiveTab} className="space-y-4">
        <TabsList>
          <TabsTrigger value="overview" className="text-sm">
            Overview
          </TabsTrigger>
          <TabsTrigger value="calls" className="text-sm">
            Call Logs
          </TabsTrigger>
          <TabsTrigger value="settings" className="text-sm">
            Settings
          </TabsTrigger>
        </TabsList>
        
        <TabsContent value="overview" className="space-y-4">

          {/* AI Receptionist Status */}
          {shouldShowWizard ? (
            <AIReceptionistWizard 
              onComplete={() => {
                // Refresh the data instead of reloading the page
                queryClient.invalidateQueries({ queryKey: aiReceptionistKeys.settings() });
                queryClient.invalidateQueries({ queryKey: aiReceptionistKeys.templates() });
              }} 
              hasTemplates={hasTemplates || false}
            />
          ) : (
            <Card>
              <CardHeader>
                <CardTitle>Receptionist Status</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="flex flex-col md:flex-row items-start md:items-center gap-4">
                  <div className="flex items-center gap-4">
                    <div className={`w-12 h-12 rounded-full flex items-center justify-center ${
                      settings?.isEnabled ? "bg-green-100" : "bg-red-100"
                    }`}>
                      <Headphones className={`h-6 w-6 ${
                        settings?.isEnabled ? "text-green-600" : "text-red-600"
                      }`} />
                    </div>
                    <div>
                      <h3 className="font-medium">
                        {settings?.isEnabled ? "Active and Ready" : "Inactive"}
                      </h3>
                      <p className="text-sm text-gray-500">
                        {settings?.isEnabled 
                          ? "Your receptionist is currently active and ready to handle calls" 
                          : "Your receptionist is currently disabled"}
                      </p>
                    </div>
                  </div>
                  <div className="mt-3 md:mt-0 md:ml-auto">
                    {userNumbers && userNumbers.length > 0 && settings?.isEnabled ? (
                      <div className="p-3 bg-gray-50 rounded-lg">
                        <p className="text-xs font-medium text-gray-500">Speak to the receptionist</p>
                        <p className="text-xl">{formatPhoneNumber(phoneNumber || userNumbers[0].phoneNumber)}</p>
                      </div>
                    ) : settings?.isEnabled ? (
                      <p className="text-sm text-gray-600">
                        <Button variant="outline" size="sm" onClick={() => setShowAddDialog(true)}>
                          Buy a new number
                        </Button>
                      </p>
                    ) : (
                      <p className="text-sm text-gray-600">
                        Enable the receptionist in settings to start handling calls
                      </p>
                    )}
                  </div>
                </div>
                
                <div className="mt-6">
                  <h4 className="font-medium text-sm mb-2">Current Configuration</h4>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="p-3 bg-gray-50 rounded-lg">
                      <p className="text-xs font-medium text-gray-500">Active Template</p>
                      {templates && templates.length > 0 ? (
                        <div className="mt-1">
                          <Select
                            value={settings?.activeTemplateId || ""}
                            onValueChange={handleChangeTemplate}
                            disabled={isChangingTemplate}
                          >
                            <SelectTrigger className="w-full h-8 text-sm">
                              <SelectValue placeholder="Select a template" />
                            </SelectTrigger>
                            <SelectContent>
                              {templates.map((template) => (
                                <SelectItem key={template.id} value={template.id || ""}>
                                  {template.name}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                          {isChangingTemplate && (
                            <div className="flex items-center mt-1 text-xs text-muted-foreground">
                              <Loader2 className="h-3 w-3 animate-spin mr-1" />
                              Updating...
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="flex justify-between items-center">
                          <p className="text-sm">{activeTemplateName}</p>
                          <Button 
                            variant="ghost" 
                            size="sm" 
                            onClick={() => setActiveTab("settings")}
                            className="text-xs"
                          >
                            Change
                          </Button>
                        </div>
                      )}
                    </div>
                    <div className="p-3 bg-gray-50 rounded-lg">
                      <p className="text-xs font-medium text-gray-500">Voice</p>
                      <p className="text-sm">
                        {settings?.voiceSettings?.voiceName || "Default"} 
                        ({settings?.voiceSettings?.gender || "Unknown"}, {settings?.voiceSettings?.style || "Standard"})
                      </p>
                    </div>
                    <div className="p-3 bg-gray-50 rounded-lg">
                      <p className="text-xs font-medium text-gray-500">Working Hours</p>
                      <p className="text-sm">
                        {settings?.workingHours?.enabled 
                          ? "Enabled (24/7)" 
                          : "Always Available"}
                      </p>
                    </div>
                    <div className="p-3 bg-gray-50 rounded-lg">
                      <p className="text-xs font-medium text-gray-500">Call Forwarding</p>
                      <p className="text-sm">
                        {settings?.callForwarding?.enabled 
                          ? `Enabled (After ${settings.callForwarding.afterRings} rings)` 
                          : "Disabled"}
                      </p>
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          )}

          {/* Real-time Call Status */}
          <RealTimeCallStatus userId="current-user" />
          
          {/* Metrics Cards */}
          <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
            {callMetrics.map((metric, i) => (
              <Card key={i}>
                <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                  <CardTitle className="text-sm font-medium">
                    {metric.title}
                  </CardTitle>
                  <div className={`${metric.color} bg-opacity-10 p-2 rounded-full`}>
                    {metric.icon}
                  </div>
                </CardHeader>
                <CardContent>
                  <div className="text-2xl font-bold">{metric.value}</div>
                  <p className={`text-xs ${
                    metric.trend === "up" 
                      ? "text-green-500" 
                      : metric.trend === "down" 
                        ? "text-red-500" 
                        : "text-gray-500"
                  }`}>
                    {metric.change} from last month
                  </p>
                </CardContent>
              </Card>
            ))}
          </div>

          {/* Recent Calls */}
          <Card>
            <CardHeader>
              <CardTitle>Recent Calls</CardTitle>
              <CardDescription>
                Your receptionist has handled {callLogsData?.pagination?.totalCount || 0} calls recently
              </CardDescription>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                {callLogsData?.calls && callLogsData.calls.length > 0 ? (
                  callLogsData.calls.slice(0, 1).map((call: any) => (
                    <div key={call.id} className="flex items-start gap-4 p-3 rounded-lg border border-gray-100 hover:bg-gray-50 transition-colors">
                      <div className="mt-1">
                        {getStatusIcon(call.status)}
                      </div>
                      <div className="flex-1 min-w-0">
                        <div className="flex flex-col sm:flex-row sm:justify-between sm:items-start gap-1">
                          <div>
                            <h4 className="font-medium text-sm">
                              {call.leadName || call.phoneNumber}
                              {call.leadId && (
                                <span className="ml-2 text-xs text-blue-600">(Lead)</span>
                              )}
                            </h4>
                            <p className="text-xs text-gray-500">{call.phoneNumber}</p>
                          </div>
                          <div className="flex items-center gap-2 text-xs text-gray-500">
                            <span>{new Date(call.startTime).toLocaleString()}</span>
                            <span>•</span>
                            <span>{call.duration ? formatDuration(call.duration) : "0:00"}</span>
                          </div>
                        </div>
                        <p className="text-sm mt-1 text-gray-700 line-clamp-2">{call.summary || "No summary available"}</p>
                        <div className="flex items-center gap-2 mt-2">
                          <span className="text-xs bg-gray-100 px-2 py-0.5 rounded-full">
                            {call.status === "completed" ? "Completed" : 
                             call.status === "missed" ? "Missed Call" : 
                             call.status === "in-progress" ? "In Progress" : "Failed"}
                          </span>
                          {call.leadScore && (
                            <span className={`text-xs font-medium ${getLeadScoreColor(call.leadScore)}`}>
                              Score: {typeof call.leadScore === 'string' 
                                ? (parseFloat(call.leadScore) * 100).toFixed(0) 
                                : (call.leadScore * 100).toFixed(0)}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="flex gap-2">
                        {call.leadId && (
                          <Button 
                            variant="ghost" 
                            size="sm" 
                            className="shrink-0"
                            onClick={() => handleNavigateToLead(call.leadId)}
                          >
                            <UserCircle className="h-4 w-4" />
                            <span className="sr-only">View Lead</span>
                          </Button>
                        )}
                        <Button 
                          variant="ghost" 
                          size="sm" 
                          className="shrink-0"
                          onClick={() => handleViewTranscript(call.id)}
                        >
                          <MessageSquare className="h-4 w-4" />
                          <span className="sr-only">View Transcript</span>
                        </Button>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="text-center py-8 text-gray-500">
                    <p>No recent calls found</p>
                  </div>
                )}
                <div className="text-center">
                  <Button 
                    variant="outline" 
                    size="sm"
                    onClick={() => setActiveTab("calls")}
                  >
                    View All Calls
                  </Button>
                </div>
              </div>
            </CardContent>
          </Card>

          {/* Performance Insights */}
          <Card>
            <CardHeader>
              <CardTitle>Performance Insights</CardTitle>
              <CardDescription>
                AI-generated insights based on call analysis
              </CardDescription>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                <div className="flex items-start gap-3">
                  <BarChart3 className="h-5 w-5 text-blue-500 mt-0.5" />
                  <div>
                    <h4 className="font-medium text-sm">Conversion Rate Improvement</h4>
                    <p className="text-sm text-gray-600">
                      {metricsData?.insights?.conversionRate || 
                        "Calls handled by the receptionist have a 28% higher callback rate compared to missed calls without AI handling."}
                    </p>
                  </div>
                </div>
                <div className="flex items-start gap-3">
                  <Clock className="h-5 w-5 text-purple-500 mt-0.5" />
                  <div>
                    <h4 className="font-medium text-sm">Peak Call Times</h4>
                    <p className="text-sm text-gray-600">
                      {metricsData?.insights?.peakTimes || 
                        "Most calls are received between 11 AM and 2 PM. Consider adjusting your availability during these hours for direct engagement."}
                    </p>
                  </div>
                </div>
                <div className="flex items-start gap-3">
                  <MessageSquare className="h-5 w-5 text-green-500 mt-0.5" />
                  <div>
                    <h4 className="font-medium text-sm">Common Inquiries</h4>
                    <p className="text-sm text-gray-600">
                      {metricsData?.insights?.commonInquiries || 
                        "The most frequent topics are premium costs (42%), coverage details (35%), and enrollment periods (23%)."}
                    </p>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </TabsContent>
        
        <TabsContent value="calls">
          <CallLogs onViewTranscript={handleViewTranscript} />
        </TabsContent>
        
        <TabsContent value="settings">
          <AIReceptionistSettings />
        </TabsContent>
      </Tabs>
      
      {/* Call Transcript Dialog */}
      <Dialog open={isTranscriptOpen} onOpenChange={setIsTranscriptOpen}>
        <DialogContent className="max-w-4xl p-0">
          {selectedCallId && (
            <CallTranscriptViewer 
              callId={selectedCallId} 
              onClose={() => setIsTranscriptOpen(false)} 
            />
          )}
        </DialogContent>
      </Dialog>

      {/* Add Number Dialog */}
      <Dialog open={showAddDialog} onOpenChange={setShowAddDialog}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Add New Number</DialogTitle>
            <DialogDescription>
              Search and purchase a new phone number for your account. Numbers are billed at $2 per month.
            </DialogDescription>
          </DialogHeader>
          <div className="py-4">
            <DIDManager
              mode="compact"
              onNumberSelected={(number) => {
                setShowAddDialog(false);
                setPhoneNumber(number);
              }}
            />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
} 