import { cn } from '@/lib/utils';
import { Lead } from '@/types';
import { useQueryClient } from '@tanstack/react-query';
import { Brain, Clock, MessageSquare, Phone, Sparkles } from 'lucide-react';
import { useEffect, useState } from 'react';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '../../ui/collapsible';
import { formFieldClasses } from './utils';

interface LeadScoreSectionProps {
  formData: Partial<Lead>;
  leadId?: number;
}

export function LeadScoreSection({ formData, leadId }: LeadScoreSectionProps) {
  const [isOpen, setIsOpen] = useState(false);
  const queryClient = useQueryClient();

  // Listen for call status changes to refresh lead score data
  useEffect(() => {
    if (!leadId) return;

    const handleCallStatusChange = (event: CustomEvent) => {
      const { status, leadId: eventLeadId } = event.detail;
      
      // Only respond to events for this lead and when analysis is complete
      if (eventLeadId === leadId && status === 'analyzed') {
        console.log('[LeadScoreSection] Call analysis completed, refreshing lead score data');
        queryClient.invalidateQueries({ queryKey: ['lead', leadId] });
      }
    };

    // Add event listener
    window.addEventListener('call-status-change', handleCallStatusChange as EventListener);
    
    // Clean up
    return () => {
      window.removeEventListener('call-status-change', handleCallStatusChange as EventListener);
    };
  }, [leadId, queryClient]);

  if (formData.leadScore === undefined) return null;

  return (
    <Collapsible
      open={isOpen}
      onOpenChange={setIsOpen}
      className={cn(formFieldClasses.section, "transition-all cursor-pointer")}
    >
      <CollapsibleTrigger asChild>
        <div className="space-y-4">
          {/* Always visible content */}
          <div className="grid grid-cols-2 gap-4">
            <div>
              <div className="flex items-center justify-between mb-1">
                <p className="text-sm font-medium">Lead Score</p>
                <Brain className="h-3 w-3 text-indigo-500" />
              </div>
              <div className="h-2 bg-muted rounded-full overflow-hidden">
                <div 
                  className={cn(
                    "h-full transition-all",
                    formData.leadScore > 0.7 ? "bg-green-500" :
                    formData.leadScore > 0.4 ? "bg-yellow-500" :
                    "bg-red-500"
                  )}
                  style={{ width: `${formData.leadScore * 100}%` }}
                />
              </div>
              <p className="text-xs text-muted-foreground mt-1">
                {Math.round(formData.leadScore * 100)}% Qualified
              </p>
            </div>
            {formData.sentimentScore !== undefined && (
              <div>
                <div className="flex items-center justify-between mb-1">
                  <p className="text-sm font-medium">Sentiment</p>
                  <Sparkles className="h-3 w-3 text-yellow-500" />
                </div>
                <div className="h-2 bg-muted rounded-full overflow-hidden">
                  <div 
                    className={cn(
                      "h-full transition-all",
                      formData.sentimentScore > 0.3 ? "bg-green-500" :
                      formData.sentimentScore > -0.3 ? "bg-yellow-500" :
                      "bg-red-500"
                    )}
                    style={{ width: `${(formData.sentimentScore + 1) * 50}%` }}
                  />
                </div>
                <p className="text-xs text-muted-foreground mt-1">
                  {formData.sentimentScore > 0.3 ? "Positive" :
                   formData.sentimentScore > -0.3 ? "Neutral" :
                   "Negative"} Sentiment
                </p>
              </div>
            )}
          </div>
        </div>
      </CollapsibleTrigger>

      {/* Collapsible content */}
      <CollapsibleContent className="space-y-4 !mt-0 data-[state=closed]:animate-collapsible-up data-[state=open]:animate-collapsible-down">
        <div className="flex flex-wrap items-center gap-x-4 gap-y-2 pt-4 text-sm text-muted-foreground">
          {formData.callCount !== undefined && (
            <div className="flex items-center gap-2">
              <Phone className="h-4 w-4" />
              <span>{formData.callCount} call{formData.callCount !== 1 ? 's' : ''} analyzed</span>
            </div>
          )}
          {formData.metadata?.smsMessageCount !== undefined && (
            <div className="flex items-center gap-2">
              <MessageSquare className="h-4 w-4" />
              <span>{formData.metadata.smsMessageCount} message{formData.metadata.smsMessageCount !== 1 ? 's' : ''} analyzed</span>
            </div>
          )}
          {formData.metadata?.lastCalculated && (
            <div className="flex items-center gap-2 text-xs">
              <Clock className="h-3 w-3" />
              <span>Updated {new Date(formData.metadata.lastCalculated).toLocaleString()}</span>
            </div>
          )}
        </div>
        {formData.metadata && (
          <div className="text-xs text-muted-foreground bg-muted/30 p-2 rounded">
            <div className="font-medium mb-1">Score Breakdown:</div>
            <div className="grid grid-cols-2 gap-x-4 gap-y-1">
              {formData.metadata.recentCallScore !== undefined && (
                <div className="flex justify-between">
                  <span>Recent Call:</span>
                  <span>{Math.round(formData.metadata.recentCallScore * 100)}%</span>
                </div>
              )}
              {formData.metadata.historicalScore !== undefined && (
                <div className="flex justify-between">
                  <span>Historical Calls:</span>
                  <span>{Math.round(formData.metadata.historicalScore * 100)}%</span>
                </div>
              )}
              {formData.metadata.callCountScore !== undefined && (
                <div className="flex justify-between">
                  <span>Call Frequency:</span>
                  <span>{Math.round(formData.metadata.callCountScore * 100)}%</span>
                </div>
              )}
              {formData.metadata.smsScore !== undefined && (
                <div className="flex justify-between">
                  <span>SMS Analysis:</span>
                  <span>{Math.round(formData.metadata.smsScore * 100)}%</span>
                </div>
              )}
            </div>
          </div>
        )}
      </CollapsibleContent>
    </Collapsible>
  );
} 