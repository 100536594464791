import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Link } from "@tanstack/react-router";
import {
  ArrowLeft,
  ArrowRight,
  Book,
  Code2,
  Copy,
  FileText,
  Laptop,
  LayoutGrid,
  Lock,
  MessageSquare,
  Phone,
  Search,
  Settings,
  Sparkles,
  Users,
  Webhook
} from "lucide-react";

export function Documentation() {
  const sections = [
    {
      title: "Getting Started",
      items: [
        { title: "Introduction", href: "#introduction" },
        { title: "Quick Start Guide", href: "#quick-start" },
        { title: "Installation", href: "#installation" },
        { title: "Authentication", href: "#authentication" }
      ]
    },
    {
      title: "Core Features",
      items: [
        { title: "AI Call Analysis", href: "#call-analysis" },
        { title: "Lead Scoring", href: "#lead-scoring" },
        { title: "Task Automation", href: "#task-automation" },
        { title: "Team Management", href: "#team-management" }
      ]
    },
    {
      title: "API Reference",
      items: [
        { title: "REST API Overview", href: "#api-overview" },
        { title: "Authentication", href: "#api-auth" },
        { title: "Endpoints", href: "#endpoints" },
        { title: "Webhooks", href: "#webhooks" }
      ]
    },
    {
      title: "Integrations",
      items: [
        { title: "CRM Systems", href: "#crm-integration" },
        { title: "Phone Systems", href: "#phone-integration" },
        { title: "Email Providers", href: "#email-integration" },
        { title: "Calendar Apps", href: "#calendar-integration" }
      ]
    }
  ];

  const quickLinks = [
    { icon: <Sparkles className="h-4 w-4" />, title: "AI Features Guide" },
    { icon: <Code2 className="h-4 w-4" />, title: "API Documentation" },
    { icon: <Users className="h-4 w-4" />, title: "Team Setup" },
    { icon: <Webhook className="h-4 w-4" />, title: "Webhooks" }
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Top Navigation */}
      <header className="sticky top-0 z-50 bg-white border-b border-gray-200">
        <div className="container mx-auto px-4">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center gap-4">
              <Link to="/">
                <Button variant="ghost" size="icon">
                  <ArrowLeft className="h-4 w-4" />
                </Button>
              </Link>
              <h1 className="text-xl font-bold">Documentation</h1>
            </div>
            <div className="flex items-center gap-4">
              <div className="relative w-64">
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
                <Input className="pl-10" placeholder="Search documentation..." />
              </div>
              <select className="px-3 py-2 rounded-md border border-gray-200 text-sm">
                <option>v2.0.0 (Latest)</option>
                <option>v1.9.0</option>
                <option>v1.8.0</option>
              </select>
            </div>
          </div>
        </div>
      </header>

      <div className="container mx-auto px-4 py-8">
        <div className="flex gap-8">
          {/* Sidebar Navigation */}
          <div className="w-64 flex-shrink-0">
            <div className="sticky top-24 space-y-8">
              {/* Quick Links */}
              <div>
                <h3 className="font-medium mb-3 text-sm text-gray-500 uppercase tracking-wider">Quick Links</h3>
                <div className="grid grid-cols-2 gap-2">
                  {quickLinks.map((link, index) => (
                    <Button
                      key={index}
                      variant="outline"
                      className="h-auto py-3 px-3 flex flex-col items-center gap-2 text-xs font-normal"
                    >
                      {link.icon}
                      {link.title}
                    </Button>
                  ))}
                </div>
              </div>

              {/* Section Navigation */}
              <nav className="space-y-8">
                {sections.map((section, index) => (
                  <div key={index}>
                    <h3 className="font-medium mb-3 text-sm text-gray-500 uppercase tracking-wider">
                      {section.title}
                    </h3>
                    <ul className="space-y-2">
                      {section.items.map((item, itemIndex) => (
                        <li key={itemIndex}>
                          <a
                            href={item.href}
                            className="block px-3 py-2 text-gray-700 hover:text-primary hover:bg-primary/5 rounded-md transition-colors"
                          >
                            {item.title}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </nav>
            </div>
          </div>

          {/* Main Content */}
          <div className="flex-1 max-w-3xl">
            {/* Introduction */}
            <section id="introduction" className="mb-12">
              <h2 className="text-3xl font-bold mb-6">Introduction to LeadGPT</h2>
              <p className="text-gray-600 mb-6">
                LeadGPT is an AI-powered platform designed to help insurance agents streamline their sales process,
                automate routine tasks, and close more deals. This documentation will help you get started and
                make the most of our features.
              </p>
              <div className="grid sm:grid-cols-2 gap-4">
                <div className="bg-white p-4 rounded-lg border border-gray-200">
                  <div className="w-10 h-10 rounded-lg bg-primary/10 flex items-center justify-center mb-3">
                    <Laptop className="h-5 w-5 text-primary" />
                  </div>
                  <h3 className="font-bold mb-2">Quick Start Guide</h3>
                  <p className="text-sm text-gray-600 mb-3">
                    Get up and running with LeadGPT in less than 10 minutes.
                  </p>
                  <Button variant="ghost" className="gap-2 text-primary">
                    Start Here
                    <ArrowRight className="h-4 w-4" />
                  </Button>
                </div>
                <div className="bg-white p-4 rounded-lg border border-gray-200">
                  <div className="w-10 h-10 rounded-lg bg-primary/10 flex items-center justify-center mb-3">
                    <Book className="h-5 w-5 text-primary" />
                  </div>
                  <h3 className="font-bold mb-2">API Documentation</h3>
                  <p className="text-sm text-gray-600 mb-3">
                    Integrate LeadGPT with your existing systems.
                  </p>
                  <Button variant="ghost" className="gap-2 text-primary">
                    View API Docs
                    <ArrowRight className="h-4 w-4" />
                  </Button>
                </div>
              </div>
            </section>

            {/* Quick Start */}
            <section id="quick-start" className="mb-12">
              <h2 className="text-2xl font-bold mb-6">Quick Start Guide</h2>
              <div className="space-y-6">
                <div className="bg-white p-6 rounded-lg border border-gray-200">
                  <h3 className="text-lg font-bold mb-4">1. Create Your Account</h3>
                  <p className="text-gray-600 mb-4">
                    Sign up for LeadGPT and choose a plan that fits your needs. All plans come with a 14-day free trial.
                  </p>
                  <div className="bg-gray-50 rounded-lg p-4">
                    <pre className="text-sm overflow-x-auto">
                      <code>
                        curl -X POST https://api.leadgpt.com/v2/signup \{'\n'}
                        {'  '}-H "Content-Type: application/json" \{'\n'}
                        {'  '}-d '{'{'}{'\n'}
                        {'    '}"email": "agent@example.com",{'\n'}
                        {'    '}"password": "your-password",{'\n'}
                        {'    '}"plan": "professional"{'\n'}
                        {'  '}{'}'}'
                      </code>
                    </pre>
                    <Button variant="ghost" size="sm" className="mt-2 gap-2">
                      <Copy className="h-4 w-4" />
                      Copy Code
                    </Button>
                  </div>
                </div>

                <div className="bg-white p-6 rounded-lg border border-gray-200">
                  <h3 className="text-lg font-bold mb-4">2. Set Up Your Team</h3>
                  <p className="text-gray-600 mb-4">
                    Invite team members and configure roles and permissions for your organization.
                  </p>
                  <div className="grid sm:grid-cols-2 gap-4">
                    <div className="flex gap-3">
                      <div className="w-10 h-10 rounded-lg bg-primary/10 flex items-center justify-center flex-shrink-0">
                        <Users className="h-5 w-5 text-primary" />
                      </div>
                      <div>
                        <h4 className="font-medium mb-1">Team Management</h4>
                        <p className="text-sm text-gray-600">Learn how to manage team members and roles</p>
                      </div>
                    </div>
                    <div className="flex gap-3">
                      <div className="w-10 h-10 rounded-lg bg-primary/10 flex items-center justify-center flex-shrink-0">
                        <Lock className="h-5 w-5 text-primary" />
                      </div>
                      <div>
                        <h4 className="font-medium mb-1">Access Control</h4>
                        <p className="text-sm text-gray-600">Configure permissions and security settings</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bg-white p-6 rounded-lg border border-gray-200">
                  <h3 className="text-lg font-bold mb-4">3. Connect Your Systems</h3>
                  <p className="text-gray-600 mb-4">
                    Integrate LeadGPT with your existing CRM, phone system, and other tools.
                  </p>
                  <div className="grid sm:grid-cols-3 gap-4">
                    <div className="flex items-center gap-2 p-3 bg-gray-50 rounded-lg">
                      <Phone className="h-5 w-5 text-primary" />
                      <span className="text-sm">Phone System</span>
                    </div>
                    <div className="flex items-center gap-2 p-3 bg-gray-50 rounded-lg">
                      <MessageSquare className="h-5 w-5 text-primary" />
                      <span className="text-sm">CRM Integration</span>
                    </div>
                    <div className="flex items-center gap-2 p-3 bg-gray-50 rounded-lg">
                      <Settings className="h-5 w-5 text-primary" />
                      <span className="text-sm">Custom Setup</span>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/* Next Steps */}
            <section className="mb-12">
              <h2 className="text-2xl font-bold mb-6">Next Steps</h2>
              <div className="grid sm:grid-cols-2 gap-4">
                <div className="bg-white p-6 rounded-lg border border-gray-200">
                  <div className="w-12 h-12 rounded-lg bg-primary/10 flex items-center justify-center mb-4">
                    <LayoutGrid className="h-6 w-6 text-primary" />
                  </div>
                  <h3 className="font-bold mb-2">Explore Features</h3>
                  <p className="text-gray-600 mb-4">
                    Learn about all the powerful features LeadGPT offers to boost your sales.
                  </p>
                  <Button variant="outline" className="gap-2">
                    View Features
                    <ArrowRight className="h-4 w-4" />
                  </Button>
                </div>
                <div className="bg-white p-6 rounded-lg border border-gray-200">
                  <div className="w-12 h-12 rounded-lg bg-primary/10 flex items-center justify-center mb-4">
                    <FileText className="h-6 w-6 text-primary" />
                  </div>
                  <h3 className="font-bold mb-2">Read Guides</h3>
                  <p className="text-gray-600 mb-4">
                    Check out our detailed guides and best practices for insurance sales.
                  </p>
                  <Button variant="outline" className="gap-2">
                    View Guides
                    <ArrowRight className="h-4 w-4" />
                  </Button>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
} 