import { Input } from '@/components/ui/input';
import { api } from '@/lib/api';
import { cn } from '@/lib/utils';
import { Lead } from '@/types';
import { useQueryClient } from '@tanstack/react-query';
import { Cigarette, Heart, Shield, User, Users } from 'lucide-react';
import { toast } from 'sonner';
import { formFieldClasses, LeadQueryKey } from './utils';

interface InsuranceDemographicsSectionProps {
  formData: Partial<Lead>;
  handleInputChange: (field: keyof Lead, value: string | number | boolean) => void;
  handleBlur: (field: keyof Lead) => void;
  lead: Lead | null;
  setIsSaving: (isSaving: boolean) => void;
  setFormData: React.Dispatch<React.SetStateAction<Partial<Lead>>>;
}

export function InsuranceDemographicsSection({
  formData,
  handleInputChange,
  handleBlur,
  lead,
  setIsSaving,
  setFormData
}: InsuranceDemographicsSectionProps) {
  const queryClient = useQueryClient();

  return (
    <div className={formFieldClasses.section}>
      <div className="space-y-3">
        <div className={formFieldClasses.field}>
          <label className={formFieldClasses.label}>Insurance Company</label>
          <div className="relative">
            <Shield className={formFieldClasses.icon} />
            <Input
              value={formData.insCompany || ''}
              onChange={(e) => handleInputChange('insCompany', e.target.value)}
              onBlur={() => handleBlur('insCompany')}
              className={cn(formFieldClasses.input, formFieldClasses.inputWithIcon)}
              placeholder="Insurance company"
            />
          </div>
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-4 gap-3">
          <div className={formFieldClasses.field}>
            <label className={formFieldClasses.label}>Age</label>
            <div className="relative">
              <User className={formFieldClasses.icon} />
              <Input
                value={formData.age || ''}
                onChange={(e) => handleInputChange('age', e.target.value)}
                onBlur={() => handleBlur('age')}
                className={cn(formFieldClasses.input, formFieldClasses.inputWithIcon, formFieldClasses.numberInput)}
                placeholder="Age"
                type="number"
              />
            </div>
          </div>
          <div className={formFieldClasses.field}>
            <label className={formFieldClasses.label}>Spouse Age</label>
            <div className="relative">
              <Heart className={formFieldClasses.icon} />
              <Input
                value={formData.spouseAge || ''}
                onChange={(e) => handleInputChange('spouseAge', e.target.value)}
                onBlur={() => handleBlur('spouseAge')}
                className={cn(formFieldClasses.input, formFieldClasses.inputWithIcon, formFieldClasses.numberInput)}
                placeholder="Spouse age"
                type="number"
              />
            </div>
          </div>
          <div className={formFieldClasses.field}>
            <label className={formFieldClasses.label}>Children</label>
            <div className="relative">
              <Users className={formFieldClasses.icon} />
              <Input
                value={formData.children || ''}
                onChange={(e) => handleInputChange('children', e.target.value)}
                onBlur={() => handleBlur('children')}
                className={cn(formFieldClasses.input, formFieldClasses.inputWithIcon, formFieldClasses.numberInput)}
                placeholder="# of children"
                type="number"
              />
            </div>
          </div>
          <div className={formFieldClasses.field}>
            <label className={formFieldClasses.label}>Smoker</label>
            <div className={cn("relative flex items-center h-8", formFieldClasses.input)}>
              <Cigarette className={formFieldClasses.icon} />
              <select
                value={formData.smoker === 1 ? 'yes' : 'no'}
                onChange={async (e) => {
                  const newValue = e.target.value === 'yes' ? 1 : 0;
                  handleInputChange('smoker', newValue);
                  if (!lead) return;
                  setIsSaving(true);
                  try {
                    const response = await api.leads.update(lead.id, { smoker: newValue });
                    if (response.success) {
                      queryClient.setQueryData(['lead', lead.id] as LeadQueryKey, {
                        success: true,
                        lead: { ...lead, smoker: newValue }
                      });
                      queryClient.setQueriesData<{ pages: { leads: Lead[] }[] }>({ queryKey: ['leads'] }, (old) => {
                        if (!old?.pages) return old;
                        return {
                          ...old,
                          pages: old.pages.map((page) => ({
                            ...page,
                            leads: page.leads.map((l) =>
                              l.id === lead.id ? { ...l, smoker: newValue } : l
                            )
                          }))
                        };
                      });
                      toast.success('Updated smoker status');
                    }
                  } catch (error) {
                    toast.error('Failed to update smoker status');
                    setFormData((prev) => ({ ...prev, smoker: lead.smoker }));
                  }
                  setIsSaving(false);
                }}
                className="flex-1 pl-6 bg-transparent outline-none text-sm text-gray-900 dark:text-white focus:ring-0 appearance-none cursor-pointer"
              >
                <option value="no">No</option>
                <option value="yes">Yes</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
} 