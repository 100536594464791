import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { DialogDescription, DialogTitle } from "@/components/ui/dialog";
import { Skeleton } from "@/components/ui/skeleton";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useToast } from "@/components/ui/use-toast";
import { formatDuration, formatPhoneNumber } from "@/lib/utils";
import { useCallTranscript, useSyncCallData } from "@/services/ai-receptionist";
import { Download, MessageSquare, RefreshCw, User } from "lucide-react";
import { useEffect, useRef, useState } from "react";

interface Speaker {
  id: string;
  name: string;
  role: "ai" | "caller";
}

interface TranscriptSegment {
  id: string;
  speakerId: string;
  text: string;
  timestamp: number;
  duration: number;
  isHighlighted?: boolean;
  highlightReason?: string;
}

interface CallSummary {
  keyPoints: string[];
  leadScore: number | string;
  nextSteps?: string[];
  sentiment?: "positive" | "neutral" | "negative";
}

interface CallTranscriptViewerProps {
  callId: string;
  onClose?: () => void;
}

export function CallTranscriptViewer({ callId, onClose }: CallTranscriptViewerProps) {
  const { toast } = useToast();
  const [activeTab, setActiveTab] = useState("transcript");
  const [transcript, setTranscript] = useState<TranscriptSegment[]>([]);
  const [speakers, setSpeakers] = useState<Speaker[]>([]);
  const [summary, setSummary] = useState<CallSummary | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  
  // Fetch transcript data
  const { data: transcriptData, isLoading, isError, error } = useCallTranscript(callId);
  
  // Sync call data mutation
  const syncCallData = useSyncCallData();
  
  // Add console log to debug the response
  useEffect(() => {
    if (transcriptData) {
      console.log("Transcript data received:", transcriptData);
    }
    if (isError) {
      console.error("Transcript error:", error);
    }
  }, [transcriptData, isError, error]);
  
  // Process transcript data when it's available
  useEffect(() => {
    if (transcriptData && transcriptData.transcript) {
      try {
        // Parse the transcript text into segments
        const lines = transcriptData.transcript.split('\n').filter(line => line.trim());
        const parsedSegments: TranscriptSegment[] = [];
        const speakerMap: Record<string, Speaker> = {};
        
        // Process each line of the transcript
        lines.forEach((line, index) => {
          // Extract speaker and text
          const match = line.match(/^(AI|User):\s*(.*)/);
          if (match) {
            const [, speakerType, text] = match;
            const speakerId = speakerType.toLowerCase();
            
            // Create speaker if not exists
            if (!speakerMap[speakerId]) {
              speakerMap[speakerId] = {
                id: speakerId,
                name: speakerType === 'AI' ? 'AI Assistant' : 'Caller',
                role: speakerType === 'AI' ? 'ai' : 'caller'
              };
            }
            
            // Create transcript segment
            parsedSegments.push({
              id: `segment-${index}`,
              speakerId,
              text,
              timestamp: index * 5, // Approximate timestamp
              duration: 3, // Approximate duration
              isHighlighted: false
            });
          }
        });
        
        // Create summary from API data
        const parsedSummary: CallSummary = {
          keyPoints: transcriptData.keyPoints || [],
          leadScore: transcriptData.leadScore ? parseFloat(transcriptData.leadScore) : 0,
          nextSteps: [],
          sentiment: "neutral"
        };
        
        // Update state
        setSpeakers(Object.values(speakerMap));
        setTranscript(parsedSegments);
        setSummary(parsedSummary);
      } catch (err) {
        console.error("Error parsing transcript:", err);
      }
    }
  }, [transcriptData]);
  
  // Handle audio playback
  const togglePlayback = () => {
    if (!audioRef.current) return;
    
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    
    setIsPlaying(!isPlaying);
  };
  
  // Update current time during playback
  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) return;
    
    const handleTimeUpdate = () => {
      setCurrentTime(audio.currentTime);
    };
    
    audio.addEventListener('timeupdate', handleTimeUpdate);
    
    return () => {
      audio.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [audioRef.current]);
  
  // Get speaker name by ID
  const getSpeakerName = (speakerId: string) => {
    const speaker = speakers.find(s => s.id === speakerId);
    return speaker ? speaker.name : "Unknown Speaker";
  };
  
  // Get speaker role by ID
  const getSpeakerRole = (speakerId: string) => {
    const speaker = speakers.find(s => s.id === speakerId);
    return speaker ? speaker.role : "caller";
  };
  
  // Format timestamp for display
  const formatTimestamp = (seconds: number) => {
    return formatDuration(Math.floor(seconds));
  };
  
  // Get sentiment color
  const getSentimentColor = (sentiment: string) => {
    switch (sentiment) {
      case "positive":
        return "text-green-600";
      case "negative":
        return "text-red-600";
      default:
        return "text-yellow-600";
    }
  };
  
  // Get lead score color
  const getLeadScoreColor = (score: number | string) => {
    const numScore = typeof score === 'string' ? parseFloat(score) * 100 : score * 100;
    if (numScore >= 80) return "text-green-600";
    if (numScore >= 60) return "text-yellow-600";
    return "text-red-600";
  };

  // Handle sync button click
  const handleSyncData = async () => {
    try {
      await syncCallData.mutateAsync(callId);
      toast({
        title: "Sync successful",
        description: "Call data has been synced with Voice AI",
      });
    } catch (error) {
      toast({
        title: "Sync failed",
        description: "Failed to sync call data with Voice AI",
        variant: "destructive",
      });
    }
  };

  // If there's an error or no transcript data, show a message with sync option
  if (isError) {
    return (
      <>
        <DialogTitle className="sr-only">Call Transcript</DialogTitle>
        <DialogDescription className="sr-only">View call transcript details</DialogDescription>
        <Card className="w-full max-w-4xl mx-auto">
          <CardHeader>
            <CardTitle>Call Transcript</CardTitle>
            <CardDescription>
              There was an error loading the transcript
            </CardDescription>
          </CardHeader>
          <CardContent className="text-center py-8">
            <p className="mb-4">
              We couldn't load the transcript for this call. The call may still be in progress or there might be an issue with the transcription service.
            </p>
            <Button 
              onClick={handleSyncData} 
              disabled={syncCallData.isPending}
              className="mx-auto"
            >
              <RefreshCw className="mr-2 h-4 w-4" />
              {syncCallData.isPending ? "Syncing..." : "Sync Call Data"}
            </Button>
          </CardContent>
          <CardFooter className="justify-end">
            <Button variant="outline" onClick={onClose}>Close</Button>
          </CardFooter>
        </Card>
      </>
    );
  }
  
  // If loading, show loading UI
  if (isLoading) {
    return (
      <>
        <DialogTitle className="sr-only">Call Transcript</DialogTitle>
        <DialogDescription className="sr-only">View call transcript details</DialogDescription>
        <Card className="w-full max-w-4xl mx-auto">
          <CardHeader>
            <CardTitle><Skeleton className="h-6 w-48" /></CardTitle>
            <CardDescription><Skeleton className="h-4 w-64" /></CardDescription>
          </CardHeader>
          <CardContent>
            <Skeleton className="h-64 w-full" />
          </CardContent>
        </Card>
      </>
    );
  }
  
  // If no transcript data or empty transcript, show appropriate UI
  if (!transcriptData || !transcriptData.transcript || transcriptData.transcript.trim() === '') {
    return (
      <>
        <DialogTitle className="sr-only">Call Transcript</DialogTitle>
        <DialogDescription className="sr-only">View call transcript details</DialogDescription>
        <Card className="w-full max-w-4xl mx-auto">
          <CardHeader>
            <CardTitle>Call Transcript</CardTitle>
            <CardDescription>
              No transcript data available for this call
            </CardDescription>
          </CardHeader>
          <CardContent className="text-center py-8">
            <p className="mb-4">
              This call doesn't have a transcript available. The call may still be in progress or the transcript hasn't been processed yet.
            </p>
            <Button 
              onClick={handleSyncData} 
              disabled={syncCallData.isPending}
              className="mx-auto"
            >
              <RefreshCw className="mr-2 h-4 w-4" />
              {syncCallData.isPending ? "Syncing..." : "Sync Call Data"}
            </Button>
          </CardContent>
          <CardFooter className="justify-end">
            <Button variant="outline" onClick={onClose}>Close</Button>
          </CardFooter>
        </Card>
      </>
    );
  }

  return (
    <>
      <DialogTitle className="sr-only">Call Transcript</DialogTitle>
      <DialogDescription className="sr-only">View call transcript details</DialogDescription>
      <Card className="w-full max-w-4xl mx-auto">
        <CardHeader>
          <CardTitle>Call Transcript</CardTitle>
          <CardDescription>
            Review the conversation between your receptionist and the caller
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className="mb-4 flex items-center justify-between">
            <div className="flex items-center gap-2">
              <Button 
                variant="outline" 
                size="icon" 
                onClick={togglePlayback}
                disabled={!transcriptData?.recordingUrl}
              >
                {isPlaying ? (
                  <div className="h-4 w-4 relative">
                    <span className="absolute inset-0 flex items-center justify-center">
                      <span className="sr-only">Pause</span>
                      <span className="block h-3 w-1 bg-current"></span>
                      <span className="block h-3 w-1 bg-current ml-1"></span>
                    </span>
                  </div>
                ) : (
                  <div className="h-4 w-4 relative">
                    <span className="absolute inset-0 flex items-center justify-center">
                      <span className="sr-only">Play</span>
                      <span className="block h-0 w-0 border-t-transparent border-t-[6px] border-b-transparent border-b-[6px] border-l-[10px] border-l-current"></span>
                    </span>
                  </div>
                )}
              </Button>
              <span className="text-sm text-gray-500">
                {formatTimestamp(currentTime)}
                {transcriptData?.duration ? (
                  <span className="ml-1">/ {formatDuration(transcriptData.duration)}</span>
                ) : null}
              </span>
            </div>
            
            <div className="flex items-center gap-2">
              <Button 
                onClick={handleSyncData} 
                variant="outline" 
                size="sm" 
                className="gap-1"
                disabled={syncCallData.isPending}
              >
                <RefreshCw className={`h-4 w-4 ${syncCallData.isPending ? 'animate-spin' : ''}`} />
                {syncCallData.isPending ? "Syncing..." : "Sync Data"}
              </Button>
              
              {transcriptData?.recordingUrl && (
                <Button 
                  variant="outline" 
                  size="sm" 
                  className="gap-1"
                  onClick={() => transcriptData.recordingUrl && window.open(transcriptData.recordingUrl, '_blank', 'noopener,noreferrer')}
                >
                  <Download className="h-4 w-4" />
                  Download
                </Button>
              )}
            </div>
          </div>
          
          {/* Hidden audio element for playback */}
          {transcriptData?.recordingUrl && (
            <audio 
              ref={audioRef} 
              src={transcriptData.recordingUrl}
              onPlay={() => setIsPlaying(true)}
              onPause={() => setIsPlaying(false)}
              onEnded={() => setIsPlaying(false)}
              className="hidden"
            />
          )}
          
          <Tabs value={activeTab} onValueChange={setActiveTab} className="space-y-4">
            <TabsList>
              <TabsTrigger value="transcript" className="text-sm">
                Full Transcript
              </TabsTrigger>
              <TabsTrigger value="summary" className="text-sm">
                Summary
              </TabsTrigger>
            </TabsList>
            
            <TabsContent value="transcript" className="space-y-4">
              {transcript.length > 0 ? (
                <div className="space-y-4 max-h-96 overflow-y-auto p-1">
                  {transcript.map((segment) => (
                    <div 
                      key={segment.id}
                      id={`segment-${segment.id}`}
                      className={`flex gap-3 ${
                        segment.isHighlighted 
                          ? "bg-yellow-50 border-l-4 border-yellow-400 pl-2 -ml-2 rounded" 
                          : ""
                      }`}
                    >
                      <div className="flex-shrink-0 w-8 text-gray-500 text-xs pt-1">
                        {formatTimestamp(segment.timestamp)}
                      </div>
                      <div className={`flex-shrink-0 w-8 h-8 rounded-full flex items-center justify-center ${
                        getSpeakerRole(segment.speakerId) === "ai" 
                          ? "bg-blue-100 text-blue-600" 
                          : "bg-gray-100 text-gray-600"
                      }`}>
                        {getSpeakerRole(segment.speakerId) === "ai" 
                          ? <MessageSquare className="h-4 w-4" /> 
                          : <User className="h-4 w-4" />
                        }
                      </div>
                      <div className="flex-grow">
                        <div className="font-medium text-sm">
                          {getSpeakerName(segment.speakerId)}
                        </div>
                        <div className="text-sm mt-1">
                          {segment.text}
                        </div>
                        {segment.highlightReason && (
                          <div className="mt-1 text-xs text-yellow-600 italic">
                            {segment.highlightReason}
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-center py-8 text-gray-500">
                  No transcript segments available
                </div>
              )}
            </TabsContent>
            
            <TabsContent value="summary" className="space-y-6">
              {summary ? (
                <>
                  {/* Call Duration */}
                  {transcriptData?.duration ? (
                    <div className="grid grid-cols-3 gap-4">
                      <div className="space-y-2">
                        <h3 className="font-medium">Call Duration</h3>
                        <p className="text-sm">{formatDuration(transcriptData.duration)}</p>
                      </div>
                      <div className="space-y-2">
                        <h3 className="font-medium">Caller Number</h3>
                        <p className="text-sm">{formatPhoneNumber(transcriptData.phoneNumber) || "Unknown"}</p>
                      </div>
                      <div className="space-y-2">
                        <h3 className="font-medium">DID Number</h3>
                        <p className="text-sm">{formatPhoneNumber(transcriptData.toNumber) || "Unknown"}</p>
                      </div>
                    </div>
                  ) : null}
                  
                  {/* Summary */}
                  <div className="space-y-2">
                    <h3 className="font-medium">Call Summary</h3>
                    <p className="text-sm">{transcriptData?.summary || "No summary available"}</p>
                  </div>
                  
                  {/* Key Points */}
                  {Array.isArray(transcriptData?.keyPoints) && transcriptData.keyPoints.length > 0 && (
                    <div className="space-y-2">
                      <h3 className="font-medium">Key Points</h3>
                      <ul className="list-disc list-inside text-sm space-y-1">
                        {transcriptData.keyPoints.map((point, index) => (
                          <li key={index}>{point}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                  
                  {/* Lead Score */}
                  {transcriptData?.leadScore && (
                    <div className="space-y-2">
                      <h3 className="font-medium">Lead Score</h3>
                      <div className={`text-lg font-semibold ${getLeadScoreColor(transcriptData.leadScore)}`}>
                        {typeof transcriptData.leadScore === 'string' 
                          ? (parseFloat(transcriptData.leadScore) * 100).toFixed(0) 
                          : (transcriptData.leadScore * 100).toFixed(0)}
                        <span className="text-sm font-normal text-gray-500 ml-1">/ 100</span>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div className="text-center py-8 text-gray-500">
                  No summary available for this call
                </div>
              )}
            </TabsContent>
          </Tabs>
        </CardContent>
        <CardFooter className="justify-end">
          <Button variant="outline" onClick={onClose}>Close</Button>
        </CardFooter>
      </Card>
    </>
  );
} 