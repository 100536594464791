import { ArticleLayout } from "@/components/help/ArticleLayout";

export function UserAccountManagementArticle() {
  return (
    <ArticleLayout>
      <h1 className="text-2xl font-bold mb-4 mt-0">User Account Management</h1>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Overview</h2>
      <p className="mb-4">
        Your LeadGPT user account contains your personal profile information, preferences, security settings, and other 
        important configurations. This guide will walk you through how to manage your account effectively and securely.
      </p>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Accessing Your Account Settings</h2>
      <p className="mb-4">
        To access your account settings:
      </p>
      <ol className="list-decimal pl-6 mb-4">
        <li className="mb-1">Click on your profile picture or initials in the top-right corner of any page</li>
        <li className="mb-1">Select "My Account" from the dropdown menu</li>
        <li className="mb-1">You'll be taken to the Account Settings page with various tabs for different settings</li>
      </ol>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Profile Information</h2>
      <p className="mb-4">
        Your profile contains your basic information that is visible to team members and sometimes to leads.
      </p>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Updating Profile Details</h3>
      <p className="mb-4">
        To update your profile:
      </p>
      <ol className="list-decimal pl-6 mb-4">
        <li className="mb-1">Go to Account Settings {'>'} Profile</li>
        <li className="mb-1">You can edit the following information:
          <ul className="list-disc pl-6 mb-2 mt-2">
            <li className="mb-1"><strong>Profile Picture:</strong> Click on the profile picture to upload a new image</li>
            <li className="mb-1"><strong>Name:</strong> Your first and last name</li>
            <li className="mb-1"><strong>Title:</strong> Your professional title (e.g., "Sales Manager")</li>
            <li className="mb-1"><strong>Phone Number:</strong> Your contact phone</li>
            <li className="mb-1"><strong>Email:</strong> Your email address (requires verification for changes)</li>
            <li className="mb-1"><strong>Time Zone:</strong> Your local time zone for scheduling purposes</li>
            <li className="mb-1"><strong>Bio:</strong> A brief professional description</li>
          </ul>
        </li>
        <li className="mb-1">Click "Save Changes" to update your profile</li>
      </ol>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Professional Information</h3>
      <p className="mb-4">
        You can also update professional details that may be visible to leads:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>LinkedIn Profile:</strong> Link to your professional LinkedIn page</li>
        <li className="mb-1"><strong>Business Email Signature:</strong> Customize your email signature</li>
        <li className="mb-1"><strong>Professional Certifications:</strong> Add relevant certifications</li>
        <li className="mb-1"><strong>Languages:</strong> List languages you speak</li>
      </ul>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Security Settings</h2>
      <p className="mb-4">
        Managing your security settings is crucial for protecting your account and data.
      </p>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Password Management</h3>
      <p className="mb-4">
        To change your password:
      </p>
      <ol className="list-decimal pl-6 mb-4">
        <li className="mb-1">Go to Account Settings {'>'} Security</li>
        <li className="mb-1">Under "Password", click "Change Password"</li>
        <li className="mb-1">Enter your current password</li>
        <li className="mb-1">Enter and confirm your new password</li>
        <li className="mb-1">Click "Update Password"</li>
      </ol>
      <p className="mb-4">
        LeadGPT requires strong passwords with at least 8 characters, including numbers, uppercase letters, 
        lowercase letters, and special characters.
      </p>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Two-Factor Authentication (2FA)</h3>
      <p className="mb-4">
        To enhance your account security, enable two-factor authentication:
      </p>
      <ol className="list-decimal pl-6 mb-4">
        <li className="mb-1">Go to Account Settings {'>'} Security</li>
        <li className="mb-1">Under "Two-Factor Authentication", click "Enable"</li>
        <li className="mb-1">Choose your preferred method:
          <ul className="list-disc pl-6 mb-2 mt-2">
            <li className="mb-1"><strong>Authenticator App:</strong> Use apps like Google Authenticator or Authy</li>
            <li className="mb-1"><strong>SMS Verification:</strong> Receive codes via text message</li>
            <li className="mb-1"><strong>Email Verification:</strong> Receive codes via email</li>
          </ul>
        </li>
        <li className="mb-1">Follow the setup instructions for your chosen method</li>
        <li className="mb-1">Save your recovery codes in a secure location</li>
      </ol>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Session Management</h3>
      <p className="mb-4">
        You can view and manage your active sessions:
      </p>
      <ol className="list-decimal pl-6 mb-4">
        <li className="mb-1">Go to Account Settings {'>'} Security</li>
        <li className="mb-1">Under "Active Sessions", you'll see all devices where you're currently logged in</li>
        <li className="mb-1">To log out from a specific device, click "Revoke"</li>
        <li className="mb-1">To log out from all other devices, click "Log Out All Other Devices"</li>
      </ol>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Login History</h3>
      <p className="mb-4">
        Review your recent login activity:
      </p>
      <ol className="list-decimal pl-6 mb-4">
        <li className="mb-1">Go to Account Settings {'>'} Security</li>
        <li className="mb-1">Under "Login History", you'll see a list of recent logins</li>
        <li className="mb-1">Each entry shows the date, time, device, location, and IP address</li>
        <li className="mb-1">If you notice any suspicious activity, change your password immediately</li>
      </ol>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Notification Preferences</h2>
      <p className="mb-4">
        Customize how and when you receive notifications from LeadGPT:
      </p>
      <ol className="list-decimal pl-6 mb-4">
        <li className="mb-1">Go to Account Settings {'>'} Notifications</li>
        <li className="mb-1">Configure your preferences for different notification types:
          <ul className="list-disc pl-6 mb-2 mt-2">
            <li className="mb-1"><strong>Lead Activities:</strong> New leads, status changes, etc.</li>
            <li className="mb-1"><strong>Messages:</strong> New messages from leads</li>
            <li className="mb-1"><strong>Tasks:</strong> Task assignments, due dates, etc.</li>
            <li className="mb-1"><strong>System Notifications:</strong> Updates, maintenance, etc.</li>
            <li className="mb-1"><strong>Team Activity:</strong> Actions by team members</li>
          </ul>
        </li>
        <li className="mb-1">For each notification type, choose how you want to receive alerts:
          <ul className="list-disc pl-6 mb-2 mt-2">
            <li className="mb-1"><strong>In-App:</strong> Notifications within the LeadGPT application</li>
            <li className="mb-1"><strong>Email:</strong> Notifications sent to your email</li>
            <li className="mb-1"><strong>Mobile Push:</strong> Notifications on your mobile device (requires the LeadGPT mobile app)</li>
            <li className="mb-1"><strong>SMS:</strong> Text message notifications for high-priority items</li>
          </ul>
        </li>
        <li className="mb-1">Set your notification schedule (e.g., work hours only, specific days)</li>
        <li className="mb-1">Click "Save Preferences" to update your settings</li>
      </ol>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Interface Preferences</h2>
      <p className="mb-4">
        Customize your LeadGPT experience with personal interface settings:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1">
          <strong>Theme:</strong> Choose between Light, Dark, or System-based theme
        </li>
        <li className="mb-1">
          <strong>Default Views:</strong> Set your preferred default views for leads, dashboard, etc.
        </li>
        <li className="mb-1">
          <strong>Display Density:</strong> Choose between compact, comfortable, or spacious layouts
        </li>
        <li className="mb-1">
          <strong>Dashboard Widgets:</strong> Select which widgets appear on your dashboard
        </li>
        <li className="mb-1">
          <strong>Language:</strong> Select your preferred language for the interface
        </li>
        <li className="mb-1">
          <strong>Date and Time Format:</strong> Choose your preferred format for dates and times
        </li>
      </ul>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Accessibility Settings</h2>
      <p className="mb-4">
        LeadGPT offers various accessibility options to ensure everyone can use the platform effectively:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Font Size:</strong> Adjust the text size</li>
        <li className="mb-1"><strong>Contrast:</strong> Choose high contrast mode</li>
        <li className="mb-1"><strong>Screen Reader Support:</strong> Optimize for screen readers</li>
        <li className="mb-1"><strong>Keyboard Navigation:</strong> Enhanced keyboard shortcuts</li>
        <li className="mb-1"><strong>Animation Reduction:</strong> Minimize motion in the interface</li>
      </ul>
      
      <h2 className="text-xl font-bold mt-8 mb-4">API Keys Management</h2>
      <p className="mb-4">
        If you need to integrate LeadGPT with other systems, you can manage your API keys:
      </p>
      <ol className="list-decimal pl-6 mb-4">
        <li className="mb-1">Go to Account Settings {'>'} API Keys</li>
        <li className="mb-1">Click "Generate New Key" to create a new API key</li>
        <li className="mb-1">Provide a name for the key and select the permissions</li>
        <li className="mb-1">Copy and securely store the generated key</li>
        <li className="mb-1">You can view, revoke, or regenerate your API keys at any time</li>
      </ol>
      <p className="mb-4">
        <strong>Important:</strong> API keys provide access to your LeadGPT data. Keep them secure and never share them publicly.
      </p>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Account Deactivation</h2>
      <p className="mb-4">
        If you need to deactivate your account:
      </p>
      <ol className="list-decimal pl-6 mb-4">
        <li className="mb-1">Go to Account Settings {'>'} Account</li>
        <li className="mb-1">At the bottom of the page, click "Deactivate Account"</li>
        <li className="mb-1">Select a reason for deactivation</li>
        <li className="mb-1">Enter your password to confirm</li>
        <li className="mb-1">Click "Deactivate My Account"</li>
      </ol>
      <p className="mb-4">
        <strong>Note:</strong> Deactivating your account will make it temporarily inaccessible. Your administrator can reactivate it if needed. 
        For permanent deletion, contact your account administrator or LeadGPT support.
      </p>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Data Export</h2>
      <p className="mb-4">
        You can export your personal data:
      </p>
      <ol className="list-decimal pl-6 mb-4">
        <li className="mb-1">Go to Account Settings {'>'} Privacy</li>
        <li className="mb-1">Under "Data Export", click "Request Data Export"</li>
        <li className="mb-1">Select the data you want to export</li>
        <li className="mb-1">Choose your preferred format (CSV, JSON, PDF)</li>
        <li className="mb-1">Click "Request Export"</li>
      </ol>
      <p className="mb-4">
        You'll receive an email when your export is ready for download. Exports may take up to 24 hours to process, 
        depending on the amount of data.
      </p>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Account Security Tips</h2>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1">Use a strong, unique password for your LeadGPT account</li>
        <li className="mb-1">Enable two-factor authentication for additional security</li>
        <li className="mb-1">Regularly review your login history for suspicious activity</li>
        <li className="mb-1">Log out from shared devices after use</li>
        <li className="mb-1">Keep your recovery email up to date</li>
        <li className="mb-1">Never share your login credentials with others</li>
        <li className="mb-1">Be cautious of phishing attempts claiming to be from LeadGPT</li>
      </ul>
      
      <p className="mb-4 mt-8">
        Managing your account settings ensures you have a personalized, secure, and efficient 
        experience with LeadGPT. If you encounter any issues with your account, contact 
        your administrator or LeadGPT support for assistance.
      </p>
    </ArticleLayout>
  );
} 