import { api, formatPhoneNumberForTwilio } from '@/lib/api';
import { Device } from '@twilio/voice-sdk';
import { toast } from 'sonner';

// Add proper TypeScript interfaces for Twilio SDK types
interface TwilioCallParameters {
  From?: string;
  To?: string;
  CallSid?: string;
  AccountSid?: string;
  Direction?: string;
  [key: string]: string | undefined;
}

interface TwilioCall {
  parameters: TwilioCallParameters;
  customParameters: Map<string, string>;
  direction: 'INCOMING' | 'OUTGOING';
  codec?: string;
  status(): string;
  accept(options?: any): void;
  disconnect(): void;
  reject(): void;
  ignore(): void;
  mute(shouldMute: boolean): void;
  isMuted(): boolean;
  sendDigits(digits: string): void;
  on(event: string, handler: Function): void;
  removeListener(event: string, handler: Function): void;
  getLocalStream(): MediaStream | null;
  getRemoteStream(): MediaStream | null;
  connectToken?: string;
  callerInfo?: { isVerified: boolean } | null;
}

// Interface for any object that might represent a Twilio call
interface GenericTwilioCallObject {
  status?: () => string;
  parameters?: TwilioCallParameters;
  [key: string]: any;
}

interface TwilioAudioHelper {
  incoming(enable?: boolean): boolean;
  outgoing(enable?: boolean): boolean;
  disconnect(play?: boolean): void;
  setInputDevice(deviceId: string): Promise<void>;
  setOutputDevice(deviceId: string): Promise<void>;
  speakerDevices: MediaDeviceInfo[];
  ringtoneDevices: MediaDeviceInfo[];
  availableOutputDevices: Map<string, MediaDeviceInfo>;
  availableInputDevices: Map<string, MediaDeviceInfo>;
  // Audio control methods
  mute(shouldMute: boolean): void;
  isMuted(): boolean;
}

interface CallOptions {
  phoneNumber: string;
  fromNumber?: string | null;
  onConnecting?: () => void;
  onConnected?: (callSid?: string) => void;
  onDisconnected?: () => void;
  onError?: (error: Error) => void;
}

interface ConnectionCallbacks {
  onConnected?: (callSid: string) => void;
  onDisconnected?: () => void;
  onError?: (error: Error) => void;
}

export class CallService {
  private static device: Device | undefined;
  private static setupPromise: Promise<void> | undefined;
  private static isRegistered = false;
  private static clientId: string | undefined;
  private static setupRetries = 0;
  private static maxRetries = 3;
  private static incomingConnection: TwilioCall | null = null; // Store the incoming connection
  private static incomingCallSid: string | null = null; // Store the incoming call SID (client-side SID)
  private static parentCallSid: string | null = null; // Store the parent call SID from webhook
  private static activeConnection: TwilioCall | null = null; // Store the active connection

  /**
   * Initialize the Twilio device
   */
  private static async setupDevice(): Promise<void> {
    try {
      // If already registered, return early
      if (this.isRegistered && this.device) {
        console.log('[CallService] Device already registered');
        return;
      }
      
      // Reset device if we're retrying
      if (this.device) {
        try {
          console.log('[CallService] Cleaning up previous device instance');
          this.device.destroy();
          this.device = undefined;
          this.incomingConnection = null;
          this.incomingCallSid = null;
          this.activeConnection = null;
        } catch (err: unknown) {
          console.warn('[CallService] Error cleaning up device:', err);
        }
      }

      console.log('[CallService] Fetching voice token...');
      const { token, clientId } = await api.twilio.getVoiceToken();
      
      if (!token) {
        throw new Error('Failed to get valid token from server');
      }
      
      console.log('[CallService] Token received, initializing device with client ID:', clientId);
      this.clientId = clientId;
      
      // Initialize the Twilio Device with proper options according to v2.12.3 SDK
      const device = new Device(token, {
        // Set edge locations for better routing and reliability
        edge: ['ashburn', 'dallas', 'portland'],
        // Use closeProtection to prevent accidental hangups
        closeProtection: true,
        // Increase signaling timeout for better reliability
        maxCallSignalingTimeoutMs: 30000,
        // Enable default sounds
        disableAudioContextSounds: false,
        // Log level for debugging
        logLevel: 'error'
      });

      console.log('[CallService] Registering device...');
      await device.register();
      this.device = device;
      this.isRegistered = true;
      this.setupRetries = 0; // Reset retry counter on success
      console.log('[CallService] Device registered successfully');

      // Set up event listeners
      this.setupDeviceEventListeners(device);

      return;
    } catch (error) {
      console.error('[CallService] Error setting up Twilio device:', error);
      
      // Implement retry logic
      if (this.setupRetries < this.maxRetries) {
        this.setupRetries++;
        console.log(`[CallService] Retrying setup (${this.setupRetries}/${this.maxRetries})...`);
        
        // Wait before retrying (exponential backoff)
        const delay = Math.min(1000 * Math.pow(2, this.setupRetries), 10000);
        await new Promise(resolve => setTimeout(resolve, delay));
        
        return this.setupDevice();
      } else {
        this.isRegistered = false;
        this.device = undefined;
        toast.error('Failed to initialize call device. Please try again later.');
        throw error;
      }
    }
  }
  
  /**
   * Set up event listeners for the Twilio device
   */
  private static setupDeviceEventListeners(device: Device): void {
    // Error handling
    device.on('error', (error: any) => {
      console.error('[CallService] Twilio device error:', error);
      toast.error('Call error: ' + error.message);
      
      // Handle specific error types
      if (error.code === 31000) {
        // Token expired
        this.isRegistered = false;
        toast.error('Call service token expired. Reconnecting...');
        this.setupDevice().catch((err: Error) => {
          console.error('[CallService] Failed to reconnect after token expiry:', err);
        });
      }
    });

    // Registration events
    device.on('unregistered', () => {
      console.log('[CallService] Device unregistered');
      this.isRegistered = false;
      this.incomingConnection = null;
      this.incomingCallSid = null;
    });
    
    device.on('registered', () => {
      console.log('[CallService] Device registered event');
      this.isRegistered = true;
    });
    
    // Token management
    device.on('tokenWillExpire', () => {
      console.log('[CallService] Token will expire soon, refreshing...');
      this.refreshToken();
    });

    // Incoming call handling
    device.on('incoming', (call: any) => {
      const callSid = call.parameters?.CallSid;
      const from = call.parameters?.From;
      const to = call.parameters?.To;
      
      console.log('[CallService] Incoming call received:', {
        callSid,
        from,
        to,
        connectionState: call.status(),
        allParameters: call.parameters
      });
      
      // Store the incoming call
      this.incomingConnection = call as TwilioCall;
      this.incomingCallSid = callSid;
      
      // Set up event handlers for the call
      this.setupCallEventHandlers(call as TwilioCall);
    });
  }
  
  /**
   * Set up event handlers for a Twilio call
   */
  private static setupCallEventHandlers(call: TwilioCall): void {
    // Call accepted
    call.on('accept', () => {
      console.log(`[CallService] Call accepted: ${call.parameters?.CallSid}`);
      
      // If this is our active call, update its status
      if (this.activeConnection === call) {
        // Call is now active, handle any state updates needed
      }
    });
    
    // Call disconnected
    call.on('disconnect', () => {
      console.log(`[CallService] Call disconnected: ${call.parameters?.CallSid}`);
      
      // If this was our active call, clear it
      if (this.activeConnection === call) {
        this.activeConnection = null;
      }
      
      // If this was our incoming call, clear it
      if (this.incomingConnection === call) {
        this.clearIncomingCall();
      }
    });
    
    // Call errors - use any type to avoid TypeScript error
    call.on('error', (error: any) => {
      console.error(`[CallService] Call error for ${call.parameters?.CallSid}:`, error);
      
      // Handle specific error types if needed
      
      // If this was our incoming call, clear it on error
      if (this.incomingConnection === call) {
        this.clearIncomingCall();
      }
    });
    
    // Call rejected
    call.on('reject', () => {
      console.log(`[CallService] Call rejected: ${call.parameters?.CallSid}`);
      
      // If this was our incoming call, clear it
      if (this.incomingConnection === call) {
        this.clearIncomingCall();
      }
    });
    
    // Call canceled
    call.on('cancel', () => {
      console.log(`[CallService] Call canceled: ${call.parameters?.CallSid}`);
      
      // If this was our incoming call, clear it
      if (this.incomingConnection === call) {
        this.clearIncomingCall();
      }
    });
    
    // Call warning events for quality monitoring - use any type to avoid TypeScript error
    call.on('warning', (warning: any) => {
      console.warn(`[CallService] Call warning: ${warning}`);
    });
    
    call.on('warning-cleared', (warning: any) => {
      console.info(`[CallService] Call warning cleared: ${warning}`);
    });
  }

  /**
   * Refresh the Twilio token
   */
  private static async refreshToken(): Promise<void> {
    try {
      console.log('[CallService] Refreshing token...');
      const { token } = await api.twilio.getVoiceToken();
      
      if (this.device && token) {
        console.log('[CallService] Updating device with new token');
        this.device.updateToken(token);
      } else {
        console.warn('[CallService] Cannot update token: device not initialized');
        // Re-initialize device if needed
        this.isRegistered = false;
        await this.setupDevice();
      }
    } catch (error) {
      console.error('[CallService] Error refreshing token:', error);
      toast.error('Failed to refresh call service. Please try again.');
      this.isRegistered = false;
    }
  }

  /**
   * Make an outbound call
   */
  static async makeCall(options: CallOptions) {
    console.log('[CallService] Initiating call to:', options.phoneNumber);
    
    if (!options.phoneNumber) {
      const error = new Error('Phone number is required');
      console.error('[CallService] Call error:', error);
      options.onError?.(error);
      return;
    }
    
    if (!options.fromNumber) {
      console.warn('[CallService] No from number provided, call may fail');
    }
    
    // Ensure device is set up
    try {
      if (!this.setupPromise) {
        console.log('[CallService] Setting up device for the first time');
        this.setupPromise = this.setupDevice();
      } else if (!this.isRegistered) {
        console.log('[CallService] Device not registered, re-initializing');
        this.setupPromise = this.setupDevice();
      }
      
      await this.setupPromise;
    } catch (error) {
      console.error('[CallService] Failed to set up device:', error);
      const callError = error instanceof Error ? error : new Error('Failed to set up call device');
      options.onError?.(callError);
      return;
    }

    if (!this.isRegistered || !this.device) {
      const error = new Error('Call device not ready');
      console.error('[CallService] Device not ready:', { isRegistered: this.isRegistered, hasDevice: !!this.device });
      toast.error('Call device not ready. Please try again.');
      options.onError?.(error);
      return;
    }

    try {
      // Notify connecting state
      options.onConnecting?.();

      // Format phone number to E.164 with additional validation
      const formattedNumber = formatPhoneNumberForTwilio(options.phoneNumber);
      if (!formattedNumber || !formattedNumber.startsWith('+')) {
        throw new Error(`Invalid phone number format: ${formattedNumber}. Must be in E.164 format.`);
      }
      
      // Format fromNumber if provided with additional validation
      let formattedFromNumber;
      if (options.fromNumber) {
        formattedFromNumber = formatPhoneNumberForTwilio(options.fromNumber);
        if (!formattedFromNumber || !formattedFromNumber.startsWith('+')) {
          throw new Error(`Invalid from number format: ${formattedFromNumber}. Must be in E.164 format.`);
        }
      }

      console.log('[CallService] Making call with:', {
        to: formattedNumber,
        from: formattedFromNumber,
        clientId: this.clientId
      });
      
      // Prepare call parameters - use the parameter names expected by the backend API
      const params: Record<string, string> = {
        phone_number: formattedNumber, // Use snake_case to match backend API
      };

      // Add optional parameters if available
      if (formattedFromNumber) {
        params.from_number = formattedFromNumber; // Use snake_case to match backend API
      }
      if (this.clientId) {
        params.clientId = this.clientId;
      }

      console.log('[CallService] Twilio Device params:', params);

      // In Twilio SDK v2.x, connect returns a Promise that resolves to a Call object
      const call = await this.device.connect({ params });
      console.log('[CallService] Call connected successfully');
      
      // Store as active connection
      this.activeConnection = call as unknown as TwilioCall;
      
      // Extract call SID if available
      const callSid = call.parameters?.CallSid || '';
      options.onConnected?.(callSid);

      // Set up event handlers for the call
      this.setupOutgoingCallHandlers(call as unknown as TwilioCall, options);

      return {
        call,
        callSid
      };

    } catch (error) {
      console.error('[CallService] Error making call:', error);
      
      // Provide more specific error messages based on error type
      let errorMessage = 'Failed to make call';
      if (error instanceof Error) {
        // Check for specific error messages
        if (error.message.includes('Permission denied')) {
          errorMessage = 'Microphone permission denied. Please allow microphone access.';
        } else if (error.message.includes('getUserMedia')) {
          errorMessage = 'Could not access microphone. Please check your device settings.';
        } else if (error.message.includes('token')) {
          errorMessage = 'Authentication error. Please refresh the page and try again.';
        } else {
          errorMessage = error.message;
        }
      }
      
      toast.error(errorMessage);
      options.onError?.(error instanceof Error ? error : new Error(errorMessage));
      
      // Try to recover the device if possible
      if (this.device) {
        try {
          console.log('[CallService] Attempting to reset device after error');
          this.device.disconnectAll();
          this.isRegistered = false;
          this.setupPromise = this.setupDevice();
        } catch (resetError) {
          console.error('[CallService] Failed to reset device:', resetError);
        }
      }
    }
  }

  /**
   * Set up event handlers for an outgoing call
   */
  private static setupOutgoingCallHandlers(call: TwilioCall, options: CallOptions): void {
    // Call disconnected
    call.on('disconnect', () => {
      console.log('[CallService] Outgoing call disconnected');
      
      // Clear active connection if this was it
      if (this.activeConnection === call) {
        this.activeConnection = null;
      }
      
      options.onDisconnected?.();
    });

    // Call errors - use any type to avoid TypeScript error
    call.on('error', (error: any) => {
      console.error('[CallService] Outgoing call error:', error);
      toast.error('Call error: ' + error.message);
      options.onError?.(error instanceof Error ? error : new Error(String(error)));
      
      // Clear active connection if this was it
      if (this.activeConnection === call) {
        this.activeConnection = null;
      }
    });

    // Call quality monitoring - use any type to avoid TypeScript error
    call.on('warning', (warning: any) => {
      console.warn('[CallService] Call warning:', warning);
    });

    call.on('warning-cleared', (warning: any) => {
      console.info('[CallService] Call warning cleared:', warning);
    });
  }

  /**
   * Check if there's an incoming call
   */
  static hasIncomingCall(): boolean {
    return !!this.incomingConnection;
  }

  /**
   * Get the incoming call SID
   */
  static getIncomingCallSid(): string | null {
    return this.incomingCallSid;
  }

  /**
   * Check if there is an active call in progress
   * @returns true if there is an active call, false otherwise
   */
  static isInCall(): boolean {
    // First try our stored activeConnection
    if (this.activeConnection) {
      try {
        const status = this.activeConnection.status();
        if (status === 'open') {
          return true;
        }
      } catch (err) {
        console.warn('[CallService] Error checking stored connection status:', err);
        this.activeConnection = null;
      }
    }
    
    if (!this.device) return false;
    
    try {
      // For Twilio Voice SDK v2.x, properly check the device.calls collection
      const device = this.device as unknown as { calls?: Map<string, GenericTwilioCallObject> };
      const calls = Array.from(device.calls?.values() || []);
      
      if (calls.length > 0) {
        // Look for any call that's in an active state
        return calls.some((callObj: GenericTwilioCallObject) => {
          try {
            return callObj && typeof callObj.status === 'function' && callObj.status() === 'open';
          } catch (err) {
            return false;
          }
        });
      }
      
      return false;
    } catch (error) {
      console.error('[CallService] Error checking if in call:', error);
      return false;
    }
  }

  /**
   * Disconnect all active calls
   */
  static disconnectAll() {
    if (this.device) {
      console.log('[CallService] Disconnecting all calls');
      this.device.disconnectAll();
      this.activeConnection = null;
    }
  }

  /**
   * Initialize the device early to be ready for incoming calls
   * This should be called when the app starts
   */
  static async initializeDevice(): Promise<void> {
    console.log('[CallService] Initializing device for incoming calls');
    
    if (!this.setupPromise) {
      this.setupPromise = this.setupDevice();
    }
    
    try {
      await this.setupPromise;
      console.log('[CallService] Device initialized and ready for incoming calls');
    } catch (error) {
      console.error('[CallService] Failed to initialize device:', error);
      // We'll retry later when needed
    }
  }

  /**
   * Accepts an incoming call
   * @param callSid The SID of the call to accept
   * @param callbacks Optional callbacks for call events
   */
  public static acceptIncomingCall(
    callSid: string,
    callbacks?: ConnectionCallbacks
  ): void {
    console.log(`[CallService] Accepting incoming call: ${callSid}`);
    
    // Ensure the device is set up
    if (!this.device) {
      console.error('[CallService] Cannot accept call: Device not initialized');
      callbacks?.onError?.(new Error('Device not initialized'));
      return;
    }
    
    try {
      // In Twilio SDK v2.x, use the stored incoming call
      if (this.incomingConnection) {
        console.log(`[CallService] Using stored incoming call: ${this.incomingCallSid}`);
        
        // Accept the call
        this.incomingConnection.accept();
        
        // Set as active connection
        this.activeConnection = this.incomingConnection;
        
        // Set up callbacks
        const storedCall = this.incomingConnection;
        const storedCallSid = this.incomingCallSid || 'unknown';
        
        // Call accepted
        storedCall.on('accept', () => {
          console.log(`[CallService] Incoming call accepted: ${storedCallSid}`);
          callbacks?.onConnected?.(storedCallSid);
        });
        
        // Call disconnected
        storedCall.on('disconnect', () => {
          console.log(`[CallService] Incoming call disconnected: ${storedCallSid}`);
          
          // Clear from active connection if this was it
          if (this.activeConnection === storedCall) {
            this.activeConnection = null;
          }
          
          callbacks?.onDisconnected?.();
        });
        
        // Call errors
        storedCall.on('error', (error: Error) => {
          console.error(`[CallService] Incoming call error: ${storedCallSid}`, error);
          
          // Clear from active connection if this was it
          if (this.activeConnection === storedCall) {
            this.activeConnection = null;
          }
          
          callbacks?.onError?.(error);
        });
        
        // Clear incoming call references now that it's active
        this.incomingConnection = null;
        this.incomingCallSid = null;
      } else {
        console.error('[CallService] No incoming call found to accept');
        callbacks?.onError?.(new Error('No incoming call found'));
      }
    } catch (error) {
      console.error('[CallService] Error accepting call:', error);
      callbacks?.onError?.(error instanceof Error ? error : new Error('Unknown error accepting call'));
    }
  }

  /**
   * Reject an incoming call
   */
  static async rejectIncomingCall(callSid: string): Promise<void> {
    console.log('[CallService] Rejecting incoming call:', callSid);
    
    if (!this.isRegistered || !this.device) {
      console.warn('[CallService] Cannot reject call: device not ready');
      return;
    }
    
    try {
      // In Twilio SDK v2.x, use the stored incoming call
      if (this.incomingConnection) {
        console.log(`[CallService] Rejecting stored incoming call: ${this.incomingCallSid}`);
        
        // Reject the call
        this.incomingConnection.reject();
        console.log('[CallService] Incoming call rejected');
        
        // Clear references
        this.clearIncomingCall();
      } else {
        console.warn('[CallService] No incoming call found to reject');
      }
    } catch (error) {
      console.error('[CallService] Error rejecting incoming call:', error);
    }
  }

  /**
   * Set the parent call SID from the webhook notification
   * This helps us track the relationship between the parent call and the client-side call
   */
  static setParentCallSid(callSid: string): void {
    console.log(`[CallService] Setting parent call SID: ${callSid}`);
    this.parentCallSid = callSid;
  }

  /**
   * Get the parent call SID from the webhook notification
   */
  static getParentCallSid(): string | null {
    return this.parentCallSid;
  }

  /**
   * Check if we have a parent call SID
   */
  static hasParentCallSid(): boolean {
    return !!this.parentCallSid;
  }

  /**
   * Clear the stored incoming call references
   */
  private static clearIncomingCall(): void {
    this.incomingConnection = null;
    this.incomingCallSid = null;
    this.parentCallSid = null;
    console.log('[CallService] Cleared incoming call references');
  }

  /**
   * Reset all call-related state
   * This can be called when we want to completely reset the call state
   */
  static resetCallState(): void {
    this.incomingConnection = null;
    this.incomingCallSid = null;
    this.parentCallSid = null;
    console.log('[CallService] Reset all call state');
  }

  /**
   * Get information about the active call if one exists
   * @returns Information about the active call, or null if no call is active
   */
  static getActiveCallInfo(): { 
    callSid: string | null;
    direction: string | null;
    from: string | null;
    to: string | null;
  } | null {
    // Get the active call
    const call = this.getActiveConnection();
    if (!call) {
      return null;
    }
    
    try {
      const params = call.parameters || {};
      return {
        callSid: params.CallSid || null,
        direction: call.direction || null,
        from: params.From || null,
        to: params.To || null
      };
    } catch (error) {
      console.error('[CallService] Error getting active call info:', error);
      return null;
    }
  }

  /**
   * Get the active call if one exists
   * @returns The active call or null if none exists
   */
  static getActiveConnection(): TwilioCall | null {
    // First check our stored active connection
    if (this.activeConnection) {
      try {
        const status = this.activeConnection.status();
        if (['open', 'connecting', 'ringing'].includes(status)) {
          return this.activeConnection;
        } else {
          this.activeConnection = null;
        }
      } catch (err) {
        console.warn('[CallService] Error checking stored connection:', err);
        this.activeConnection = null;
      }
    }
    
    if (!this.device) {
      return null;
    }
    
    try {
      // In Twilio Voice SDK v2.x, get active calls from the device.calls collection
      const device = this.device as unknown as { calls?: Map<string, GenericTwilioCallObject> };
      const calls = Array.from(device.calls?.values() || []);
      
      if (calls.length > 0) {
        // Find the first call in an active state
        for (const callObj of calls) {
          try {
            if (callObj && typeof callObj.status === 'function') {
              const status = callObj.status();
              if (['open', 'connecting', 'ringing'].includes(status)) {
                // Type cast to TwilioCall since we've verified it has required methods
                const call = callObj as unknown as TwilioCall;
                this.activeConnection = call;
                return call;
              }
            }
          } catch (err) {
            console.warn('[CallService] Error checking call status:', err);
          }
        }
      }
      
      return null;
    } catch (error) {
      console.error('[CallService] Error getting active connection:', error);
      return null;
    }
  }

  /**
   * Send a DTMF tone (digit) during an active call
   * @param digit The digit to send (0-9, *, #)
   * @returns true if the digit was sent successfully, false otherwise
   */
  static sendDigit(digit: string): boolean {
    try {
      // Validate input
      const validDTMF = /^[0-9*#]+$/;
      if (!digit || !validDTMF.test(digit)) {
        console.warn(`[CallService] Invalid DTMF digit: ${digit}. Must be 0-9, *, or #`);
        return false;
      }

      console.log(`[CallService] Sending DTMF digit: ${digit}`);
      const call = this.getActiveConnection();
      
      if (!call) {
        console.warn('[CallService] Cannot send digit: No active call');
        return false;
      }

      // In Twilio Voice SDK v2.x, the sendDigits method is available on the Call object
      if (typeof call.sendDigits === 'function') {
        call.sendDigits(digit);
        console.log(`[CallService] Successfully sent DTMF digit: ${digit}`);
        return true;
      }
      
      console.error('[CallService] Call object does not have sendDigits method');
      return false;
    } catch (error) {
      console.error('[CallService] Error sending digit:', error);
      return false;
    }
  }

  /**
   * Mute the active call
   * @returns true if successful, false otherwise
   */
  static muteCall(): boolean {
    try {
      const call = this.getActiveConnection();
      if (!call) {
        console.warn('[CallService] Cannot mute: No active call');
        return false;
      }

      // In Twilio Voice SDK v2.x, the mute method is available on the Call object
      if (typeof call.mute === 'function') {
        call.mute(true);
        console.log('[CallService] Call muted');
        return true;
      }
      
      console.error('[CallService] Call object does not have mute method');
      return false;
    } catch (error) {
      console.error('[CallService] Error muting call:', error);
      return false;
    }
  }

  /**
   * Unmute the active call
   * @returns true if successful, false otherwise
   */
  static unmuteCall(): boolean {
    try {
      const call = this.getActiveConnection();
      if (!call) {
        console.warn('[CallService] Cannot unmute: No active call');
        return false;
      }

      // In Twilio Voice SDK v2.x, the mute method with false parameter unmutes the call
      if (typeof call.mute === 'function') {
        call.mute(false);
        console.log('[CallService] Call unmuted');
        return true;
      }
      
      console.error('[CallService] Call object does not have mute method');
      return false;
    } catch (error) {
      console.error('[CallService] Error unmuting call:', error);
      return false;
    }
  }

  /**
   * Get the mute status of the active call
   * @returns true if muted, false if unmuted, null if no active call
   */
  static isMuted(): boolean | null {
    try {
      const call = this.getActiveConnection();
      if (!call) {
        console.warn('[CallService] Cannot check mute status: No active call');
        return null;
      }

      // In Twilio Voice SDK v2.x, the isMuted method is available on the Call object
      if (typeof call.isMuted === 'function') {
        const muted = call.isMuted();
        console.log(`[CallService] Call mute status: ${muted}`);
        return muted;
      }
      
      console.error('[CallService] Call object does not have isMuted method');
      return null;
    } catch (error) {
      console.error('[CallService] Error getting mute status:', error);
      return null;
    }
  }

  /**
   * Enable or disable the speaker (audio output)
   * @param enable Whether to enable or disable the speaker
   * @returns true if successful, false otherwise
   */
  static setSpeaker(enable: boolean): boolean {
    try {
      if (!this.device) {
        console.warn('[CallService] No device to set speaker');
        return false;
      }

      // In Twilio SDK v2.x, we can try to use the device.audio API
      if (this.device.audio) {
        const audioHelper = this.device.audio as unknown as TwilioAudioHelper;
        if (typeof audioHelper.outgoing === 'function') {
          audioHelper.outgoing(enable);
          console.log(`[CallService] ${enable ? 'Enabled' : 'Disabled'} audio using device.audio.outgoing()`);
          return true;
        }
      }

      // Fallback to manipulating the audio elements directly
      const audioElements = document.querySelectorAll('audio');
      let twilioAudioFound = false;

      audioElements.forEach(audio => {
        // Look for Twilio-created audio elements
        // They typically don't have a src attribute and are created dynamically
        if (!audio.src && !audio.hasAttribute('src')) {
          audio.muted = !enable;
          twilioAudioFound = true;
          console.log(`[CallService] ${enable ? 'Enabled' : 'Disabled'} audio for Twilio element`);
        }
      });

      return twilioAudioFound;
    } catch (error) {
      console.error('[CallService] Error setting speaker:', error);
      return false;
    }
  }
} 