import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Pagination, PaginationContent, PaginationItem, PaginationLink, PaginationNext, PaginationPrevious } from "@/components/ui/pagination";
import { Skeleton } from "@/components/ui/skeleton";
import { useCallLogs } from "@/services/ai-receptionist";
import { useLeadStore } from "@/store/leadStore";
import { useNavigate } from "@tanstack/react-router";
import { formatDistanceToNow } from "date-fns";
import { FileText, Phone, UserCircle } from "lucide-react";
import { useState } from "react";

// Define interfaces for the component based on actual API response
interface CallLogItem {
  id: string;
  userId: number;
  phoneNumber: string;
  toNumber: string | null;
  duration: number | null;
  startTime: string;
  endTime: string | null;
  status: "completed" | "in-progress" | "failed" | "missed";
  summary: string | null;
  transcript: string | null;
  keyPoints: string[] | null;
  leadScore: string | null;
  recordingUrl: string | null;
  actions: {
    type: string;
    description: string;
    timestamp: string;
    completed: boolean;
  }[] | null;
  createdAt: string;
  updatedAt: string;
  leadId: number | null;
  leadName: string | null;
}

interface CallLogsResponse {
  calls: CallLogItem[];
  pagination: {
    page: number;
    limit: number;
    totalPages: number;
    totalCount: number;
  };
}

interface TranscriptData {
  id: string;
  transcript: {
    speaker: "ai" | "human";
    text: string;
    timestamp: string;
  }[];
  summary: string;
  keyPoints: string[];
  sentiment: number;
  actions: {
    type: string;
    description: string;
    timestamp: string;
    completed: boolean;
  }[];
}

interface CallLogsProps {
  onViewTranscript?: (callId: string) => void;
}

export function CallLogs({ onViewTranscript }: CallLogsProps) {
  const [page, setPage] = useState(1);
  const { data: callLogsData, isLoading } = useCallLogs({ page });
  const { setSelectedLead } = useLeadStore();
  const navigate = useNavigate();

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const formatDuration = (seconds: number | null) => {
    if (seconds === null) return "N/A";
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  const formatTimestamp = (timestamp: string) => {
    return formatDistanceToNow(new Date(timestamp), { addSuffix: true });
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case "completed":
        return "bg-green-100 text-green-800";
      case "in-progress":
        return "bg-blue-100 text-blue-800";
      case "failed":
        return "bg-red-100 text-red-800";
      case "missed":
        return "bg-yellow-100 text-yellow-800";
      default:
        return "bg-gray-100 text-gray-800";
    }
  };

  const getLeadScoreColor = (score: string | null) => {
    if (!score) return "text-gray-500";
    const numScore = parseFloat(score) * 100;
    if (numScore >= 80) return "text-green-600";
    if (numScore >= 60) return "text-yellow-600";
    return "text-red-600";
  };

  const handleNavigateToLead = (leadId: number) => {
    if (leadId) {
      // Update the lead store with the selected lead ID
      setSelectedLead({ id: leadId });
      
      // Navigate to the leads page
      navigate({ to: '/leads' });
    }
  };

  return (
    <Card className="w-full">
      <CardHeader>
        <CardTitle>Receptionist Call Logs</CardTitle>
        <CardDescription>
          View call logs and transcripts from your Receptionist
        </CardDescription>
      </CardHeader>
      <CardContent>
        {isLoading ? (
          <div className="space-y-4">
            {Array.from({ length: 5 }).map((_, index) => (
              <div key={index} className="flex items-center space-x-4">
                <Skeleton className="h-12 w-12 rounded-full" />
                <div className="space-y-2">
                  <Skeleton className="h-4 w-[250px]" />
                  <Skeleton className="h-4 w-[200px]" />
                </div>
              </div>
            ))}
          </div>
        ) : callLogsData?.calls && callLogsData.calls.length > 0 ? (
          <div className="space-y-6">
            <div className="grid gap-4">
              {callLogsData.calls.map((call: any) => (
                <div key={call.id} className="flex flex-col sm:flex-row justify-between items-start sm:items-center p-4 border rounded-lg">
                  <div className="flex items-start gap-3">
                    <div className="p-2 bg-primary/10 rounded-full">
                      <Phone className="h-5 w-5 text-primary" />
                    </div>
                    <div>
                      <h4 className="font-medium">
                        {call.leadName || call.phoneNumber}
                        {call.leadId && (
                          <span className="ml-2 text-xs text-blue-600">(Lead)</span>
                        )}
                      </h4>
                      <div className="flex flex-wrap gap-2 mt-1 text-sm text-gray-500">
                        <span>{formatTimestamp(call.startTime)}</span>
                        <span>•</span>
                        <span>{formatDuration(call.duration)}</span>
                        <span>•</span>
                        <span className={`px-2 py-0.5 rounded-full text-xs ${getStatusColor(call.status)}`}>
                          {call.status.charAt(0).toUpperCase() + call.status.slice(1)}
                        </span>
                        {/* Display lead score if available */}
                        {call.leadScore && (
                          <span className={`ml-2 text-xs font-medium ${getLeadScoreColor(call.leadScore)}`}>
                            Lead Score: {(parseFloat(call.leadScore) * 100).toFixed(0)}
                          </span>
                        )}
                      </div>
                      {call.summary && (
                        <p className="mt-2 text-sm text-gray-700 line-clamp-2">{call.summary}</p>
                      )}
                    </div>
                  </div>
                  <div className="flex gap-2 mt-3 sm:mt-0">
                    {call.leadId && (
                      <Button 
                        variant="outline" 
                        size="sm" 
                        onClick={() => handleNavigateToLead(call.leadId)}
                        className="flex items-center gap-1"
                      >
                        <UserCircle className="h-4 w-4" />
                        View Lead
                      </Button>
                    )}
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => {
                        if (onViewTranscript) {
                          onViewTranscript(call.id);
                        }
                      }}
                      className="flex items-center gap-1"
                    >
                      <FileText className="h-4 w-4" />
                      Transcript
                    </Button>
                  </div>
                </div>
              ))}
            </div>
            
            {/* Pagination */}
            {callLogsData.pagination && callLogsData.pagination.totalPages > 1 && (
              <Pagination>
                <PaginationContent>
                  <PaginationItem>
                    <PaginationPrevious 
                      onClick={() => handlePageChange(Math.max(1, page - 1))}
                      aria-disabled={page === 1}
                      className={page === 1 ? "pointer-events-none opacity-50" : ""}
                    />
                  </PaginationItem>
                  
                  {Array.from({ length: callLogsData.pagination.totalPages }, (_, i) => i + 1)
                    .filter(p => Math.abs(p - page) < 2 || p === 1 || p === callLogsData.pagination.totalPages)
                    .map((p, i, arr) => {
                      // Add ellipsis
                      if (i > 0 && p > arr[i - 1] + 1) {
                        return (
                          <PaginationItem key={`ellipsis-${p}`}>
                            <span className="px-4">...</span>
                          </PaginationItem>
                        );
                      }
                      
                      return (
                        <PaginationItem key={p}>
                          <PaginationLink
                            isActive={page === p}
                            onClick={() => handlePageChange(p)}
                          >
                            {p}
                          </PaginationLink>
                        </PaginationItem>
                      );
                    })}
                  
                  <PaginationItem>
                    <PaginationNext 
                      onClick={() => handlePageChange(Math.min(callLogsData.pagination.totalPages, page + 1))}
                      aria-disabled={page === callLogsData.pagination.totalPages}
                      className={page === callLogsData.pagination.totalPages ? "pointer-events-none opacity-50" : ""}
                    />
                  </PaginationItem>
                </PaginationContent>
              </Pagination>
            )}
          </div>
        ) : (
          <div className="text-center py-8">
            <p className="text-gray-500">No call logs found</p>
          </div>
        )}
      </CardContent>
    </Card>
  );
} 