import { CreditCard } from 'lucide-react';
import { Button } from '../../components/ui/button';
import { axiosInstance } from '../../lib/api';
import { StripeUser } from './types';

interface PaymentMethodSectionProps {
  user: StripeUser | null;
}

export function PaymentMethodSection({ user }: PaymentMethodSectionProps) {
  // Create and redirect to Stripe Customer Portal
  const redirectToStripeCustomerPortal = async () => {
    try {
      const response = await axiosInstance.post('/stripe/create-customer-portal-session');
      
      // Redirect to the Stripe Customer Portal URL
      if (response.data?.url) {
        window.location.href = response.data.url;
      } else {
        console.error('No URL returned from Stripe Customer Portal session');
      }
    } catch (error) {
      console.error('Error creating Stripe Customer Portal session:', error);
      
      // If working locally, provide a helpful message about potential URL issues
      if (window.location.hostname === 'localhost' || window.location.hostname.includes('ngrok')) {
        console.warn('When working with localhost or ngrok, ensure your CLIENT_URL environment variable includes the full URL with http:// or https:// prefix');
      }
    }
  };

  return (
    <div className="mb-8">
      <div className="flex justify-between items-center mb-4">
        <div>
          <h2 className="text-xl font-semibold">Payment Method</h2>
          <p className="text-sm text-muted-foreground">
            {user?.cardBrand && user?.cardLastFour 
              ? `Current method: ${user.cardBrand} •••• ${user.cardLastFour}` 
              : "No payment method on file"}
          </p>
        </div>
        <Button 
          variant="outline" 
          onClick={redirectToStripeCustomerPortal}
          className="flex items-center gap-2"
          size="sm"
        >
          <CreditCard className="h-4 w-4" />
          {user?.cardBrand && user?.cardLastFour ? "Manage Payment Methods" : "Add Payment Method"}
        </Button>
      </div>
    </div>
  );
} 