import { axiosInstance, getAvatarUploadUrl, resizeImage, updateAvatar, uploadFileToS3 } from '@/lib/api';
import type { User } from '@/types/user';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export interface ProfileUpdateData {
  name: string;
  email: string;
  phone: string | null;
  avatar?: File;
}

export interface NotificationPreferences {
  notifyRealTime: boolean;
  emailLeads: boolean;
}

export interface PasswordUpdateData {
  currentPassword: string;
  newPassword: string;
}

export const settingsKeys = {
  all: ['settings'] as const,
  profile: () => [...settingsKeys.all, 'profile'] as const,
  notifications: () => [...settingsKeys.all, 'notifications'] as const,
};

export const settingsApi = {
  updateProfile: async (data: ProfileUpdateData): Promise<User> => {
    // Handle avatar upload if provided
    if (data.avatar) {
      try {
        // 1. Resize the image before upload
        const resizedAvatar = await resizeImage(data.avatar, 300, 300);
        
        // 2. Get a pre-signed URL for the avatar upload
        const { uploadUrl, fileKey, directS3Url } = await getAvatarUploadUrl(
          resizedAvatar.name,
          resizedAvatar.type
        );

        // 3. Upload the avatar to S3
        await uploadFileToS3(uploadUrl, resizedAvatar);

        // 4. Update the user's avatar in the database with the direct S3 URL
        const updatedUser = await updateAvatar(fileKey, directS3Url);
        
        // 5. Return the updated user
        return updatedUser;
      } catch (error) {
        console.error('Error uploading avatar:', error);
        throw new Error('Failed to upload avatar');
      }
    }

    // If no avatar, just update the profile data
    const response = await axiosInstance.put<User>('/settings/profile', {
      name: data.name,
      email: data.email,
      phone: data.phone
    });
    return response.data;
  },

  updateNotificationPreferences: async (preferences: NotificationPreferences) => {
    const response = await axiosInstance.put<NotificationPreferences>('/settings/notifications', { preferences });
    return response.data;
  },

  updatePassword: async (data: PasswordUpdateData) => {
    const response = await axiosInstance.put<{ message: string }>('/settings/security/password', data);
    return response.data;
  },

  deleteAccount: async () => {
    const response = await axiosInstance.delete<{ message: string }>('/settings/security/account');
    return response.data;
  },
};

// TanStack Query mutations
export const useUpdateProfile = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: settingsApi.updateProfile,
    onSuccess: (data) => {
      queryClient.setQueryData(settingsKeys.profile(), data);
    },
  });
};

export const useUpdateNotificationPreferences = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: settingsApi.updateNotificationPreferences,
    onSuccess: (data) => {
      queryClient.setQueryData(settingsKeys.notifications(), data);
    },
  });
};

export const useUpdatePassword = () => {
  return useMutation({
    mutationFn: settingsApi.updatePassword,
  });
};

export const useDeleteAccount = () => {
  return useMutation({
    mutationFn: settingsApi.deleteAccount,
  });
}; 