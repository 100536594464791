import { useAuthStore } from '@/store/authStore';
import { Team } from '@/types/team';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface TeamState {
  currentTeamId: number | null;
  currentTeam: Team | null;
  setCurrentTeam: (team: Team | null) => void;
  setCurrentTeamId: (teamId: number | null) => void;
  clearCurrentTeam: () => void;
}

export const useTeamStore = create<TeamState>()(
  persist(
    (set) => ({
      currentTeamId: null,
      currentTeam: null,
      
      setCurrentTeam: (team: Team | null) => {
        console.log('[TeamStore] Setting current team:', team ? `${team.name} (${team.id})` : 'null');
        set({ 
          currentTeam: team,
          currentTeamId: team?.id || null
        });
      },
      
      setCurrentTeamId: (teamId: number | null) => {
        console.log('[TeamStore] Setting current team ID:', teamId);
        set({ currentTeamId: teamId });
      },
      
      clearCurrentTeam: () => {
        console.log('[TeamStore] Clearing current team');
        set({ 
          currentTeam: null,
          currentTeamId: null
        });
      }
    }),
    {
      name: (function getUserSpecificStorageKey() {
        // Get the user ID from the auth store
        const user = useAuthStore.getState().user;
        const userId = user?.id;
        
        // Include the user ID in the storage key if available
        return userId ? `team-storage-user-${userId}` : 'team-storage-no-user';
      })(),
      
      onRehydrateStorage: () => {
        return (state) => {
          if (state) {
            // Check if a user is logged in
            const user = useAuthStore.getState().user;
            
            if (!user) {
              // If no user is logged in, clear the team data
              console.log('[TeamStore] No user logged in, clearing team data');
              state.clearCurrentTeam();
            } else {
              console.log('[TeamStore] Rehydrated with team ID:', state.currentTeamId, 'for user:', user.id);
            }
          }
        };
      },
      
      // Filter the persisted state so we don't store functions
      partialize: (state) => ({
        currentTeamId: state.currentTeamId,
        currentTeam: state.currentTeam,
      }),
    }
  )
); 