import { Card, CardContent } from '@/components/ui/card';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Clock, Trophy, Users } from 'lucide-react';

interface TeamMember {
  id: number;
  name: string;
  avatar?: string;
  conversionRate: number;
  responseTime: string;
  leadsProcessed: number;
}

interface TeamLeaderboardProps {
  members: TeamMember[];
  currentUserId: number;
}

export function TeamLeaderboard({ members, currentUserId }: TeamLeaderboardProps) {
  // Sort members by conversion rate (highest first)
  const sortedByConversion = [...members].sort((a, b) => b.conversionRate - a.conversionRate);
  
  // Sort members by response time (lowest first)
  const sortedByResponseTime = [...members].sort((a, b) => {
    const aMinutes = parseResponseTime(a.responseTime);
    const bMinutes = parseResponseTime(b.responseTime);
    return aMinutes - bMinutes;
  });
  
  // Sort members by leads processed (highest first)
  const sortedByLeadsProcessed = [...members].sort((a, b) => b.leadsProcessed - a.leadsProcessed);
  
  // Render a leaderboard section
  const renderLeaderboard = (title: string, icon: JSX.Element, members: TeamMember[], valueFormatter: (member: TeamMember) => string) => (
    <div>
      <div className="flex items-center mb-4">
        {icon}
        <h3 className="text-sm font-medium">{title}</h3>
      </div>
      <div className="space-y-3">
        {members.slice(0, 5).map((member, index) => (
          <div 
            key={member.id} 
            className={`flex items-center justify-between p-2 rounded-md ${
              member.id === currentUserId ? 'bg-blue-50 border border-blue-100' : ''
            }`}
          >
            <div className="flex items-center">
              <div className="w-5 text-xs text-gray-500">{index + 1}.</div>
              <div className="flex-1 ml-2">
                {member.avatar ? (
                  <img src={member.avatar} alt={member.name} className="h-6 w-6 rounded-full inline mr-2" />
                ) : (
                  <div className="h-6 w-6 rounded-full bg-gray-200 inline-block mr-2"></div>
                )}
                <span className={`text-sm ${member.id === currentUserId ? 'font-medium' : ''}`}>
                  {member.name}
                </span>
              </div>
            </div>
            <div className="text-sm font-medium">{valueFormatter(member)}</div>
          </div>
        ))}
      </div>
    </div>
  );
  
  return (
    <Card className="rounded-none shadow-none border-none">
      <CardContent className="pt-6">
        {/* Mobile view - tabs */}
        <div className="md:hidden">
          <Tabs defaultValue="conversion">
            <TabsList className="w-full grid grid-cols-3 mb-4">
              <TabsTrigger value="conversion">Conversion</TabsTrigger>
              <TabsTrigger value="response">Response</TabsTrigger>
              <TabsTrigger value="leads">Leads</TabsTrigger>
            </TabsList>
            
            <TabsContent value="conversion">
              {renderLeaderboard(
                "Top Converters", 
                <Trophy className="h-4 w-4 text-yellow-500 mr-2" />, 
                sortedByConversion,
                (member) => `${member.conversionRate}%`
              )}
            </TabsContent>
            
            <TabsContent value="response">
              {renderLeaderboard(
                "Fastest Responders", 
                <Clock className="h-4 w-4 text-green-500 mr-2" />, 
                sortedByResponseTime,
                (member) => member.responseTime
              )}
            </TabsContent>
            
            <TabsContent value="leads">
              {renderLeaderboard(
                "Most Leads Processed", 
                <Users className="h-4 w-4 text-purple-500 mr-2" />, 
                sortedByLeadsProcessed,
                (member) => member.leadsProcessed.toString()
              )}
            </TabsContent>
          </Tabs>
        </div>
        
        {/* Desktop view - grid */}
        <div className="hidden md:grid md:grid-cols-3 md:gap-6">
          {/* Conversion Rate Leaders */}
          {renderLeaderboard(
            "Top Converters", 
            <Trophy className="h-4 w-4 text-yellow-500 mr-2" />, 
            sortedByConversion,
            (member) => `${member.conversionRate}%`
          )}
          
          {/* Response Time Leaders */}
          {renderLeaderboard(
            "Fastest Responders", 
            <Clock className="h-4 w-4 text-green-500 mr-2" />, 
            sortedByResponseTime,
            (member) => member.responseTime
          )}
          
          {/* Leads Processed Leaders */}
          {renderLeaderboard(
            "Most Leads Processed", 
            <Users className="h-4 w-4 text-purple-500 mr-2" />, 
            sortedByLeadsProcessed,
            (member) => member.leadsProcessed.toString()
          )}
        </div>
      </CardContent>
    </Card>
  );
}

// Helper function to parse response time string to minutes for sorting
function parseResponseTime(timeStr: string): number {
  if (timeStr.includes('min')) {
    return parseFloat(timeStr.replace('min', '').trim());
  } else if (timeStr.includes('sec')) {
    return parseFloat(timeStr.replace('sec', '').trim()) / 60;
  } else if (timeStr.includes('hr')) {
    return parseFloat(timeStr.replace('hr', '').trim()) * 60;
  }
  return 0;
} 