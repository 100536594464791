import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Link } from "@tanstack/react-router";
import { ArrowLeft, ArrowRight, BookOpen, Clock, Search, Tag, TrendingUp, User } from "lucide-react";
import { useEffect, useMemo, useRef, useState } from "react";

// Define types for our blog content
interface BlogPost {
  id: string;
  title: string;
  excerpt: string;
  author: string;
  date: string;
  readTime: string;
  category: string;
  image: string;
  tags: string[];
  content: string;
}

// Inline CSS as a string for blog content
const blogStylesCSS = `
  .blog-content h2 {
    font-size: 1.5rem;
    font-weight: 700;
    margin-top: 2rem;
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #e5e7eb;
    color: #111827;
  }
  
  .blog-content h3 {
    font-size: 1.25rem;
    font-weight: 600;
    margin-top: 1.5rem;
    margin-bottom: 0.75rem;
    color: #111827;
  }
  
  .blog-content p {
    margin-top: 1rem;
    margin-bottom: 1rem;
    line-height: 1.7;
    color: #374151;
  }
  
  .blog-content ul, .blog-content ol {
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding-left: 1.5rem;
  }
  
  .blog-content ul {
    list-style-type: disc;
  }
  
  .blog-content ol {
    list-style-type: decimal;
  }
  
  .blog-content li {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    color: #374151;
  }
  
  .blog-content strong {
    font-weight: 600;
    color: #111827;
  }
  
  .blog-content a {
    color: #2563eb;
    text-decoration: none;
  }
  
  .blog-content a:hover {
    text-decoration: underline;
  }
`;

// Component to render HTML content with proper styling
function FormattedContent({ content }: { content: string }) {
  const contentRef = useRef<HTMLDivElement>(null);

  // Apply additional styles to HTML elements after render
  useEffect(() => {
    if (contentRef.current) {
      // Find all lists and add proper styling
      const lists = contentRef.current.querySelectorAll('ul, ol');
      lists.forEach(list => {
        list.classList.add('pl-5', 'space-y-2');
        
        // Style list items
        const items = list.querySelectorAll('li');
        items.forEach(item => {
          item.classList.add('my-1');
        });
      });

      // Style paragraphs
      const paragraphs = contentRef.current.querySelectorAll('p');
      paragraphs.forEach(p => {
        p.classList.add('my-4', 'leading-relaxed');
      });

      // Style headings
      const headings = contentRef.current.querySelectorAll('h2, h3, h4');
      headings.forEach(heading => {
        heading.classList.add('font-bold', 'text-gray-900', 'mt-6', 'mb-3');
      });

      // Style h2 specifically
      const h2s = contentRef.current.querySelectorAll('h2');
      h2s.forEach(h2 => {
        h2.classList.add('text-2xl', 'mt-8', 'mb-4', 'pb-2', 'border-b', 'border-gray-200');
      });

      // Style h3 specifically
      const h3s = contentRef.current.querySelectorAll('h3');
      h3s.forEach(h3 => {
        h3.classList.add('text-xl', 'mt-6', 'mb-3');
      });

      // Style strong tags
      const strongs = contentRef.current.querySelectorAll('strong');
      strongs.forEach(strong => {
        strong.classList.add('font-semibold', 'text-gray-900');
      });
    }
  }, [content]);

  // Wrap content in a div with base styles
  return (
    <div 
      ref={contentRef}
      className="prose prose-sm sm:prose lg:prose-lg max-w-none text-gray-700 blog-content"
      dangerouslySetInnerHTML={{ __html: content }} 
    />
  );
}

export function Blog() {
  // State for selected article and modal
  const [selectedPost, setSelectedPost] = useState<BlogPost | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  
  // State for search and filtering
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [visiblePostCount, setVisiblePostCount] = useState(4); // Initial number of posts to show

  // Set document title for SEO
  useEffect(() => {
    document.title = "Insurance Sales & AI Technology Blog | LeadGPT";
  }, []);

  const categories = [
    "All",
    "AI Technology",
    "Insurance Sales",
    "Best Practices",
    "Industry News",
    "Product Updates",
    "Case Studies",
    "Marketing",
    "Sales Techniques",
    "Compliance"
  ];

  const featuredPosts = [
    {
      id: "ai-insurance-sales-insights",
      title: "The Future of Insurance Sales: AI-Driven Insights",
      excerpt: "Discover how artificial intelligence is transforming the insurance industry by providing deeper customer insights, automating routine tasks, and helping agents close more deals.",
      author: "Sarah Johnson",
      date: "March 18, 2025",
      readTime: "8 min read",
      category: "AI Technology",
      image: "https://images.unsplash.com/photo-1535378917042-10a22c95931a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1296&q=80",
      tags: ["AI", "Insurance", "Sales Technology", "Customer Insights"],
      content: `
        <h2>How AI is Revolutionizing Insurance Sales</h2>
        <p>The insurance industry is experiencing a technological revolution, with AI at its forefront. By leveraging machine learning algorithms and predictive analytics, insurance agents can now identify qualified leads with unprecedented accuracy.</p>
        
        <h3>Key Benefits of AI in Insurance Sales</h3>
        <ul>
          <li>Automated lead qualification and scoring</li>
          <li>Personalized client recommendations based on data analysis</li>
          <li>Predictive insights about client needs and potential objections</li>
          <li>Time-saving automation of routine administrative tasks</li>
        </ul>
        
        <h3>Real-World Applications</h3>
        <p>Leading insurance companies implementing AI-driven tools have reported an average 35% increase in conversion rates and a 40% reduction in time spent on administrative tasks. This translates to more time with clients and better customer relationships.</p>
        
        <h2>How LeadGPT Leverages AI for Insurance Agents</h2>
        <p>LeadGPT's platform combines conversational AI with industry-specific algorithms to help insurance agents work smarter, not harder. Our tools analyze client interactions, identify patterns, and provide actionable recommendations.</p>
        
        <h3>The Future Outlook</h3>
        <p>As AI technology continues to evolve, we can expect even more sophisticated tools that will further transform how insurance is sold. Agents who embrace these technologies today will be well-positioned for success in tomorrow's market.</p>
      `
    },
    {
      id: "insurance-sales-pipeline-optimization",
      title: "5 Proven Strategies to Optimize Your Insurance Sales Pipeline",
      excerpt: "Learn proven strategies to optimize your sales pipeline, prioritize high-quality leads, and close more deals using LeadGPT's advanced features and AI-driven insights.",
      author: "Michael Chen",
      date: "March 15, 2025",
      readTime: "6 min read",
      category: "Best Practices",
      image: "https://images.unsplash.com/photo-1531973486364-5fa64260d75b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1295&q=80",
      tags: ["Sales Pipeline", "Lead Management", "Conversion Optimization", "CRM"],
      content: `
        <h2>Building a More Effective Sales Pipeline</h2>
        <p>A well-optimized sales pipeline is essential for insurance agents looking to maximize their conversion rates and revenue. By implementing strategic improvements to your pipeline management, you can significantly increase efficiency and close more deals.</p>
        
        <h3>Strategy 1: Implement AI-Powered Lead Scoring</h3>
        <p>Not all leads are created equal. Using advanced lead scoring algorithms, LeadGPT helps you identify which prospects are most likely to convert, allowing you to focus your efforts where they'll have the greatest impact.</p>
        
        <h3>Strategy 2: Automate Follow-up Communications</h3>
        <p>Consistent follow-up is crucial, but it's also time-consuming. By setting up automated sequences based on client interactions, you can ensure no opportunity falls through the cracks while maintaining a personal touch.</p>
        
        <h3>Strategy 3: Use Data Analytics to Identify Bottlenecks</h3>
        <p>LeadGPT's analytics dashboard provides valuable insights into where prospects are getting stuck in your pipeline. By identifying these bottlenecks, you can implement targeted improvements to smooth the customer journey.</p>
        
        <h3>Strategy 4: Personalize Your Sales Approach</h3>
        <p>Modern clients expect personalized service. LeadGPT analyzes client data to help you customize your approach, addressing specific needs and concerns before they're even voiced.</p>
        
        <h3>Strategy 5: Implement Regular Pipeline Reviews</h3>
        <p>Set aside time each week to review your pipeline metrics and make adjustments. This regular maintenance ensures your process stays optimized over time.</p>
      `
    }
  ];

  const posts = [
    {
      id: "hipaa-compliance-ai-tools",
      title: "Understanding HIPAA Compliance in AI-Powered Insurance Tools",
      excerpt: "A comprehensive guide to maintaining HIPAA compliance while leveraging AI tools in your insurance practice, including best practices for data security and privacy.",
      author: "Emily Rodriguez",
      date: "March 14, 2025",
      readTime: "10 min read",
      category: "Industry News",
      image: "https://images.unsplash.com/photo-1576091160550-2173dba999ef?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80",
      tags: ["HIPAA", "Compliance", "Data Security", "Healthcare Insurance"],
      content: `
        <h2>Navigating HIPAA Requirements in the Age of AI</h2>
        <p>For insurance agents working in the health and medical space, HIPAA compliance is non-negotiable. As AI tools become more prevalent in the industry, understanding how to leverage these technologies while maintaining strict compliance is essential.</p>
        
        <h3>Key HIPAA Considerations for AI Implementation</h3>
        <ul>
          <li>Data encryption and secure storage</li>
          <li>Access controls and authentication</li>
          <li>Audit trails and documentation</li>
          <li>Business Associate Agreements (BAAs)</li>
          <li>Risk assessment and management</li>
        </ul>
        
        <h3>How LeadGPT Ensures HIPAA Compliance</h3>
        <p>Our platform is designed with HIPAA compliance at its core, implementing technical safeguards, administrative controls, and physical security measures to protect sensitive health information.</p>
      `
    },
    {
      id: "smart-lead-scoring-analytics",
      title: "New Features: Smart Lead Scoring and Advanced Analytics Dashboard",
      excerpt: "Explore our latest features designed to help you identify and prioritize your most promising leads, with AI-powered insights and comprehensive performance metrics.",
      author: "David Kim",
      date: "March 12, 2025",
      readTime: "5 min read",
      category: "Product Updates",
      image: "https://images.unsplash.com/photo-1551288049-bebda4e38f71?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80",
      tags: ["Lead Scoring", "Analytics", "Product Updates", "CRM"],
      content: `
        <h2>Introducing Our New Smart Lead Scoring System</h2>
        <p>We're excited to announce the launch of our enhanced lead scoring system that uses machine learning to identify your most promising prospects. This feature analyzes dozens of data points to predict conversion likelihood with remarkable accuracy.</p>
        
        <h3>How Smart Lead Scoring Works</h3>
        <p>Our algorithm considers factors like engagement history, demographic information, and behavioral patterns to assign accurate scores to each lead. The system continuously learns and improves based on your results.</p>
        
        <h3>The New Analytics Dashboard</h3>
        <p>Complementing our lead scoring system is our revamped analytics dashboard, providing at-a-glance insights into your sales performance, conversion rates, and pipeline health. Custom reports and visualizations make it easy to identify trends and opportunities.</p>
      `
    },
    {
      id: "regional-insurance-agency-case-study",
      title: "Case Study: How Regional Insurance Agency 10x'd Their Sales with AI",
      excerpt: "Learn how a regional insurance agency used LeadGPT to transform their sales process, overcome common obstacles, and achieve remarkable growth in just six months.",
      author: "Jessica Martinez",
      date: "March 10, 2025",
      readTime: "7 min read",
      category: "Case Studies",
      image: "https://images.unsplash.com/photo-1494976388531-d1058494cdd8?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80",
      tags: ["Case Study", "Success Story", "ROI", "Agency Growth"],
      content: `
        <h2>Client Profile: Midwest Health Benefits Agency</h2>
        <p>Midwest Health Benefits, a regional insurance agency with 12 agents, was struggling with inefficient processes and stagnant growth. Their manual lead management system was causing missed opportunities and their conversion rate had plateaued at just 8%.</p>
        
        <h3>The Challenge</h3>
        <p>The agency faced several critical challenges: an overwhelming lead volume, inefficient follow-up processes, and difficulty identifying their most promising prospects. Agents were spending too much time on administrative tasks and not enough on client relationships.</p>
        
        <h3>The Solution: LeadGPT Implementation</h3>
        <p>After implementing LeadGPT's AI-powered platform, Midwest Health Benefits was able to:</p>
        <ul>
          <li>Automate lead qualification and scoring</li>
          <li>Implement personalized follow-up sequences</li>
          <li>Leverage data-driven insights for sales conversations</li>
          <li>Streamline their entire sales process</li>
        </ul>
        
        <h3>The Results</h3>
        <p>Within six months, the agency saw remarkable results:</p>
        <ul>
          <li>10x increase in monthly premium sales</li>
          <li>Conversion rate improved from 8% to 27%</li>
          <li>45% reduction in administrative time per agent</li>
          <li>35% increase in client satisfaction scores</li>
        </ul>
      `
    },
    {
      id: "emotional-intelligence-insurance-sales",
      title: "The Critical Role of Emotional Intelligence in Modern Insurance Sales",
      excerpt: "Discover how combining AI insights with emotional intelligence can lead to better client relationships, higher trust, and improved conversion rates in insurance sales.",
      author: "Alex Thompson",
      date: "March 8, 2025",
      readTime: "6 min read",
      category: "Best Practices",
      image: "https://images.unsplash.com/photo-1573497620053-ea5300f94f21?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80",
      tags: ["Emotional Intelligence", "Client Relationships", "Sales Skills", "Trust Building"],
      content: `
        <h2>Why EQ Matters in Insurance Sales</h2>
        <p>Insurance is a deeply personal product, often purchased during times of significant life changes or concerns about the future. High emotional intelligence (EQ) allows agents to connect with clients on a deeper level, building the trust necessary for successful relationships.</p>
        
        <h3>Key Components of Emotional Intelligence in Sales</h3>
        <ul>
          <li>Empathy: Understanding client concerns and motivations</li>
          <li>Self-awareness: Recognizing how you come across to prospects</li>
          <li>Social awareness: Reading the room and adjusting your approach</li>
          <li>Relationship management: Building lasting client connections</li>
        </ul>
        
        <h3>Combining AI Insights with Human EQ</h3>
        <p>While AI can provide valuable data and insights, the human element of emotional intelligence creates a powerful combination. LeadGPT helps agents identify client needs and potential concerns, while their EQ skills allow them to address these in a genuinely empathetic way.</p>
        
        <h3>Practical Ways to Improve Your EQ</h3>
        <p>Insurance agents can enhance their emotional intelligence through active listening exercises, seeking feedback, practicing empathy, and continuous self-reflection. These skills complement the technological advantages provided by AI tools.</p>
      `
    },
    {
      id: "medicare-advantage-sales-strategies",
      title: "Mastering Medicare Advantage Sales: Strategies for the Annual Enrollment Period",
      excerpt: "Comprehensive guide for insurance agents selling Medicare Advantage plans, with proven strategies to maximize success during the crucial Annual Enrollment Period.",
      author: "Robert Williams",
      date: "March 6, 2025",
      readTime: "9 min read",
      category: "Best Practices",
      image: "https://images.unsplash.com/photo-1560518883-ce09059eeffa?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1473&q=80",
      tags: ["Medicare Advantage", "AEP", "Senior Market", "Health Insurance"],
      content: `
        <h2>Preparing for the Annual Enrollment Period Rush</h2>
        <p>The Medicare Annual Enrollment Period (AEP) from October 15 to December 7 represents the most critical sales opportunity for agents specializing in Medicare Advantage. Proper preparation and strategy are essential for success during this limited window.</p>
        
        <h3>Pre-AEP Preparation Checklist</h3>
        <ul>
          <li>Update your carrier certifications by early September</li>
          <li>Review plan changes and new offerings in your market</li>
          <li>Segment your client database for targeted outreach</li>
          <li>Prepare educational materials addressing common questions</li>
          <li>Schedule client review meetings in advance</li>
        </ul>
        
        <h3>How LeadGPT Helps Optimize Your AEP Strategy</h3>
        <p>Our platform provides specialized tools for Medicare Advantage agents, including plan comparison features, compliance-checked email templates, and automated follow-up sequences designed specifically for the senior market.</p>
        
        <h3>Post-AEP Strategies for Year-Round Success</h3>
        <p>The most successful Medicare agents don't disappear after December 7. Learn how to leverage Special Enrollment Periods, generate referrals, and maintain client relationships throughout the year.</p>
      `
    }
  ];

  const popularPosts = [
    {
      id: "insurance-agent-tools",
      title: "10 Must-Have Tools for Insurance Agents in 2025",
      date: "March 5, 2025",
      readTime: "8 min read",
      category: "Best Practices",
      image: "https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80",
      tags: ["Tools", "Insurance Agent", "Productivity", "Technology"],
      excerpt: "Discover the essential tools that are helping top insurance agents stay organized, efficient, and ahead of the competition in 2025.",
      content: `
        <h2>Essential Tools for Modern Insurance Agents</h2>
        <p>In today's competitive insurance landscape, having the right tools can mean the difference between thriving and merely surviving. Here are the top 10 must-have tools for insurance agents in 2025:</p>
        
        <h3>1. AI-Powered CRM Systems</h3>
        <p>Modern CRM systems like LeadGPT don't just store contact information—they analyze interactions, predict client needs, and automate follow-ups to ensure no opportunity falls through the cracks.</p>
        
        <h3>2. Digital Proposal Software</h3>
        <p>Tools that allow you to create professional, interactive insurance proposals help clients understand complex policies and make informed decisions faster.</p>
        
        <h3>3. Automated Email Marketing Platforms</h3>
        <p>Email remains one of the most effective channels for nurturing leads and maintaining client relationships. Automated platforms ensure consistent communication with minimal effort.</p>
        
        <h3>4. Virtual Meeting Solutions</h3>
        <p>High-quality video conferencing tools with screen sharing and document signing capabilities make remote client meetings just as effective as in-person ones.</p>
        
        <h3>5. Data Analytics Dashboards</h3>
        <p>Comprehensive analytics tools help you understand your sales funnel, identify bottlenecks, and optimize your processes for better results.</p>
        
        <h3>6. Mobile Apps for On-the-Go Access</h3>
        <p>The ability to access client information, quote engines, and communication tools from your smartphone ensures you're never caught unprepared, even when away from your desk.</p>
        
        <h3>7. Social Media Management Tools</h3>
        <p>Building a professional presence on social platforms is essential for modern insurance agents. The right tools make scheduling posts and engaging with your audience simple.</p>
        
        <h3>8. Compliance Monitoring Software</h3>
        <p>Staying compliant with insurance regulations is non-negotiable. Dedicated compliance tools help you navigate complex requirements with confidence.</p>
        
        <h3>9. E-Signature Solutions</h3>
        <p>Secure, legally-binding electronic signature tools streamline the closing process and improve the client experience.</p>
        
        <h3>10. AI-Powered Chatbots</h3>
        <p>24/7 availability for basic client questions and lead qualification helps you capture opportunities even outside business hours.</p>
        
        <h3>Conclusion</h3>
        <p>While no tool can replace the human touch that's central to insurance sales, the right technology stack can significantly amplify your effectiveness. By strategically implementing these tools, you'll be well-positioned to exceed client expectations and grow your business in 2025.</p>
      `
    },
    {
      id: "insurance-sales-objections",
      title: "How to Handle Common Insurance Sales Objections: A Comprehensive Guide",
      date: "March 3, 2025",
      readTime: "6 min read",
      category: "Sales Techniques",
      image: "https://images.unsplash.com/photo-1556742077-0a6b3a545a81?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80",
      tags: ["Objection Handling", "Sales Skills", "Communication", "Client Conversations"],
      excerpt: "Learn proven strategies for effectively addressing the most common objections in health insurance sales and turning hesitations into opportunities.",
      content: `
        <h2>Mastering Objection Handling in Insurance Sales</h2>
        <p>Objections are a natural part of the insurance sales process. Rather than viewing them as roadblocks, successful agents see objections as opportunities to address concerns, provide value, and strengthen client relationships.</p>
        
        <h3>The "It's Too Expensive" Objection</h3>
        <p>When clients focus solely on premium costs, shift the conversation to value. Break down the cost over time, highlight specific benefits that address their needs, and explain the financial risks of being underinsured.</p>
        <p>Example response: "I understand cost is important. Let's look at what this coverage actually provides for that premium, and what the financial impact could be if you faced these health challenges without adequate coverage."</p>
        
        <h3>The "I Need to Think About It" Objection</h3>
        <p>This often masks other unspoken concerns. Gently probe to uncover the real hesitation while respecting their need for consideration.</p>
        <p>Example response: "That makes sense—this is an important decision. To make sure I've given you all the information you need, could you share what specific aspects you'd like to think more about?"</p>
        
        <h3>The "I'm Already Covered" Objection</h3>
        <p>This provides an opportunity for a coverage review. Often, clients don't fully understand their current coverage limitations.</p>
        <p>Example response: "Great! I'm glad you have some protection in place. Would you mind if we quickly review what you currently have to make sure there aren't any gaps that could leave you exposed?"</p>
        
        <h3>The "I Don't Trust Insurance Companies" Objection</h3>
        <p>Acknowledge their concern, share claims statistics, explain your role as their advocate, and offer testimonials from satisfied clients.</p>
        <p>Example response: "I've heard similar concerns before, and they're valid. What might help is hearing from some of my clients who've had positive claims experiences. I see my role as your advocate throughout the process."</p>
        
        <h3>The "I Can Find It Cheaper Online" Objection</h3>
        <p>Emphasize the value of personal service, expert guidance, and ongoing support that comes with having a dedicated agent.</p>
        <p>Example response: "Online options certainly exist, but my clients value having someone who knows their situation personally and will be there to help navigate claims and answer questions years down the road."</p>
        
        <h3>Key Principles for Handling Any Objection</h3>
        <ul>
          <li>Listen fully before responding</li>
          <li>Acknowledge the concern as valid</li>
          <li>Ask clarifying questions</li>
          <li>Respond with specific benefits rather than generic features</li>
          <li>Use stories and examples rather than abstract explanations</li>
          <li>Check for understanding before moving forward</li>
        </ul>
        
        <h3>Using Technology to Improve Objection Handling</h3>
        <p>LeadGPT's conversation analysis can help identify patterns in client objections and suggest effective responses based on successful past interactions. This data-driven approach helps refine your objection handling skills over time.</p>
      `
    },
    {
      id: "ai-insurance-customer-service",
      title: "The Impact of AI on Insurance Customer Service: Trends and Predictions",
      date: "March 1, 2025",
      readTime: "7 min read",
      category: "Industry News",
      image: "https://images.unsplash.com/photo-1596526131083-e8c633c948d2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1374&q=80",
      tags: ["AI", "Customer Service", "Insurance Industry", "Technology Trends"],
      excerpt: "Explore how artificial intelligence is transforming customer service in the insurance industry, from chatbots to personalized recommendations.",
      content: `
        <h2>AI: Revolutionizing Insurance Customer Service</h2>
        <p>The insurance industry is experiencing a significant transformation in how customer service is delivered, with AI technologies leading the change. This shift is creating both opportunities and challenges for insurance professionals.</p>
        
        <h3>Current AI Applications in Insurance Customer Service</h3>
        <p>Today's implementation of AI in insurance customer service goes far beyond basic chatbots. Modern systems include:</p>
        <ul>
          <li>Natural language processing for understanding complex customer queries</li>
          <li>Sentiment analysis to detect customer emotions and adjust responses accordingly</li>
          <li>Predictive analytics for anticipating customer needs before they're expressed</li>
          <li>Automated claims processing that can settle straightforward claims without human intervention</li>
          <li>Personalized policy recommendations based on individual customer profiles</li>
        </ul>
        
        <h3>Benefits for Insurance Customers</h3>
        <p>These AI implementations are delivering tangible benefits to insurance customers:</p>
        <ul>
          <li>24/7 availability for basic inquiries and assistance</li>
          <li>Faster claims processing and disbursements</li>
          <li>More personalized coverage recommendations</li>
          <li>Consistent quality of information across all interactions</li>
          <li>Proactive outreach for policy reviews and life changes</li>
        </ul>
        
        <h3>The Human + AI Partnership</h3>
        <p>Rather than replacing insurance agents, the most successful implementations are creating a partnership where AI handles routine tasks while human agents focus on complex situations, emotional support, and relationship building. This hybrid approach delivers superior results compared to either humans or AI working alone.</p>
        
        <h3>Looking Ahead: Emerging Trends</h3>
        <p>The future of AI in insurance customer service will likely include:</p>
        <ul>
          <li>Voice-activated insurance assistants that can handle complex policy questions</li>
          <li>Augmented reality for visual claims assessment</li>
          <li>Blockchain integration for transparent claims handling</li>
          <li>Hyper-personalization of the entire customer journey</li>
          <li>Proactive risk mitigation recommendations based on behavioral and environmental data</li>
        </ul>
        
        <h3>Preparing for the AI-Enhanced Future</h3>
        <p>Insurance professionals who want to thrive in this changing landscape should:</p>
        <ul>
          <li>Develop emotional intelligence skills that AI cannot replicate</li>
          <li>Learn to effectively collaborate with AI systems</li>
          <li>Focus on complex advisory services beyond what automation can provide</li>
          <li>Understand how to interpret and explain AI-generated insights to clients</li>
          <li>Stay informed about emerging AI technologies in the insurance space</li>
        </ul>
        
        <p>As AI technology continues to evolve, the insurance industry is entering an era where exceptional customer service becomes the standard rather than the exception. Those who embrace this change will find themselves well-positioned to exceed customer expectations and drive business growth.</p>
      `
    },
    {
      id: "health-insurance-compliance",
      title: "Navigating Health Insurance Compliance: A Guide for Agents",
      date: "February 28, 2025",
      readTime: "9 min read",
      category: "Compliance",
      image: "https://images.unsplash.com/photo-1589829545856-d10d557cf95f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80",
      tags: ["Compliance", "Regulations", "Health Insurance", "Risk Management"],
      excerpt: "An essential guide to staying compliant with the complex and evolving regulations in the health insurance industry.",
      content: `
        <h2>Staying Compliant in a Complex Regulatory Environment</h2>
        <p>For health insurance agents, navigating the complex web of regulations is not just good practice—it's essential for business survival. This guide will help you build a strong compliance foundation.</p>
        
        <h3>Understanding the Regulatory Framework</h3>
        <p>Health insurance agents must be familiar with multiple layers of regulation:</p>
        <ul>
          <li>Federal regulations (ACA, HIPAA, Medicare/Medicaid requirements)</li>
          <li>State insurance laws and regulations</li>
          <li>Carrier-specific requirements</li>
          <li>Industry standards and best practices</li>
        </ul>
        <p>These regulations frequently change, requiring ongoing education and vigilance.</p>
        
        <h3>Essential Compliance Areas for Health Insurance Agents</h3>
        
        <h4>1. Licensing and Education Requirements</h4>
        <p>Maintain current licenses in all states where you conduct business, complete required continuing education, and keep comprehensive records of all certifications.</p>
        
        <h4>2. Privacy and Data Security</h4>
        <p>HIPAA compliance is non-negotiable when handling protected health information. Implement:</p>
        <ul>
          <li>Secure client data storage systems</li>
          <li>Clear privacy policies and procedures</li>
          <li>Regular team training on data security</li>
          <li>Breach response plans</li>
        </ul>
        
        <h4>3. Marketing and Advertising Compliance</h4>
        <p>All marketing materials must be truthful, clear, and compliant with state and federal regulations. Avoid:</p>
        <ul>
          <li>Misleading statements about coverage or benefits</li>
          <li>Guarantees of savings or results</li>
          <li>Unfair comparisons to competitors</li>
          <li>Unapproved carrier materials</li>
        </ul>
        
        <h4>4. Sales Practices and Disclosures</h4>
        <ul>
          <li>Maintain detailed documentation of client interactions</li>
          <li>Use standardized needs analysis procedures</li>
          <li>Provide required disclosures at appropriate times</li>
          <li>Clearly explain policy limitations and exclusions</li>
        </ul>
        
        <h3>Building a Compliance Culture</h3>
        <p>Effective compliance goes beyond checking boxes—it requires creating a culture where compliance is valued:</p>
        <ul>
          <li>Designate a compliance officer or resource person</li>
          <li>Conduct regular compliance reviews and audits</li>
          <li>Celebrate good compliance practices</li>
          <li>Make compliance resources readily accessible</li>
        </ul>
        
        <h3>Technology Solutions for Compliance Management</h3>
        <p>Modern tools like LeadGPT can help streamline compliance efforts through:</p>
        <ul>
          <li>Automated tracking of licensing requirements</li>
          <li>Compliant communication templates</li>
          <li>Secure document management</li>
          <li>Audit trails for client interactions</li>
        </ul>
        
        <h3>Responding to Compliance Challenges</h3>
        <p>When faced with potential compliance issues:</p>
        <ul>
          <li>Address concerns promptly and transparently</li>
          <li>Document your response process</li>
          <li>Consider seeking legal counsel when appropriate</li>
          <li>Use each challenge as a learning opportunity</li>
        </ul>
        
        <p>While compliance requirements may seem overwhelming, they ultimately serve to protect consumers and maintain the integrity of the insurance industry. Agents who embrace compliance as a core value will build stronger, more sustainable businesses.</p>
      `
    },
    {
      id: "insurance-sales-scripts",
      title: "Effective Insurance Sales Scripts That Actually Convert",
      date: "February 25, 2025",
      readTime: "5 min read",
      category: "Sales Techniques",
      image: "https://images.unsplash.com/photo-1450101499163-c8848c66ca85?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80",
      tags: ["Sales Scripts", "Conversion", "Communication", "Health Insurance"],
      excerpt: "Discover proven insurance sales scripts that help you connect with prospects authentically and boost your conversion rates.",
      content: `
        <h2>Sales Scripts That Drive Results</h2>
        <p>Effective insurance sales scripts aren't about reciting robotic, one-size-fits-all pitches. They're flexible frameworks that guide meaningful conversations and help clients see the value in what you're offering.</p>
        
        <h3>Cold Calling Script: The 30-Second Attention Grabber</h3>
        <p><strong>Introduction:</strong> "Hi [Name], this is [Your Name] with [Agency]. We specialize in helping [target demographic] find the right health insurance coverage for their specific needs."</p>
        
        <p><strong>Pattern Interrupt:</strong> "I know you probably get a lot of insurance calls, so I'll be quick. The reason I'm calling specifically is that we've helped several people in [client's profession/situation] save an average of [specific amount] on their health coverage while actually improving their benefits."</p>
        
        <p><strong>Question:</strong> "I'm curious—when was the last time someone reviewed your health insurance options to make sure you're not overpaying?"</p>
        
        <p><strong>Why it works:</strong> This script acknowledges the reality of cold calls, provides immediate value, and asks a question that's hard to dismiss. It's brief but focuses on benefits rather than features.</p>
        
        <h3>First Appointment Script: Building Trust</h3>
        <p><strong>Setting Expectations:</strong> "I appreciate you taking the time to meet today. Over the next [timeframe], I'd like to learn about your specific healthcare needs, share some options that might be a good fit, and answer any questions you have. My goal isn't to sell you something today—it's to help you make the best decision for your situation, even if that means not changing anything right now. Does that approach work for you?"</p>
        
        <p><strong>Discovery Questions:</strong></p>
        <ul>
          <li>"What prompted you to consider looking at health insurance options right now?"</li>
          <li>"What aspects of healthcare are most important to your family?"</li>
          <li>"If you could change one thing about your current coverage, what would it be?"</li>
          <li>"On a scale of 1-10, how satisfied are you with your current insurance costs?"</li>
        </ul>
        
        <p><strong>Why it works:</strong> This script reduces pressure, establishes you as a consultant rather than a salesperson, and gathers the critical information you need to make relevant recommendations.</p>
        
        <h3>Presenting Options Script: The Three-Option Approach</h3>
        <p><strong>Framing the Presentation:</strong> "Based on what you've shared about your priorities, I've prepared three options for you to consider. I'll walk you through each one, focusing on how they address your specific needs, and then you can decide which, if any, feels right for you."</p>
        
        <p><strong>Option Structure:</strong></p>
        <ol>
          <li>"This first option prioritizes [client's top priority] with [specific benefit]. It's priced at [amount] per month."</li>
          <li>"The second option balances [priority one] and [priority two] by [specific features]. This one comes in at [amount] per month."</li>
          <li>"The third option offers the most comprehensive coverage, particularly for [specific concern client mentioned]. The investment for this plan is [amount] per month."</li>
        </ol>
        
        <p><strong>Why it works:</strong> Presenting three options avoids the binary yes/no decision, allows the client to compare based on value rather than just price, and positions the middle option as the balanced choice (which is often selected).</p>
        
        <h3>Closing Script: The Assumptive Guidance</h3>
        <p><strong>Guidance Request:</strong> "Based on everything we've discussed, which of these options do you feel would work best for your situation?"</p>
        
        <p><strong>If they choose an option:</strong> "I think that's a great choice because it addresses [reiterate specific needs they mentioned]. To get this coverage in place for you, I'll need to [explain next steps]."</p>
        
        <p><strong>If they're hesitant:</strong> "I sense you might have some questions or concerns. What specific aspects are you still considering?"</p>
        
        <p><strong>Why it works:</strong> This approach is collaborative rather than pushy, reinforces that the choice aligns with their stated needs, and provides a clear path forward while still addressing hesitations.</p>
        
        <h3>Using AI to Refine Your Scripts</h3>
        <p>LeadGPT can analyze your conversations to identify which phrases and approaches resonate most effectively with different client demographics. This allows you to continuously refine your scripts based on real-world results rather than guesswork.</p>
        
        <p>Remember that the best scripts serve as guidelines rather than rigid text to recite. Authenticity, active listening, and genuine concern for your clients' needs will always outperform even the most perfectly crafted script.</p>
      `
    }
  ];

  const recentArticles = [
    {
      id: "digital-marketing-insurance",
      title: "Digital Marketing Strategies for Independent Insurance Agents",
      date: "March 20, 2025",
      category: "Marketing",
      image: "https://images.unsplash.com/photo-1460925895917-afdab827c52f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1415&q=80",
      tags: ["Digital Marketing", "Social Media", "SEO", "Insurance Agents"],
      excerpt: "Modern marketing strategies tailored specifically for independent insurance agents looking to grow their client base in the digital age.",
      readTime: "6 min read",
      content: `
        <h2>Effective Digital Marketing for Insurance Agents</h2>
        <p>As an independent insurance agent, your expertise lies in protecting clients with the right coverage—not necessarily in digital marketing. Yet in today's online-first world, a strong digital presence is essential for agency growth. This guide offers practical, implementable strategies specifically for insurance professionals.</p>
        
        <h3>Building Your Digital Foundation</h3>
        
        <h4>1. Create a Client-Focused Website</h4>
        <p>Your website should serve as your 24/7 digital office:</p>
        <ul>
          <li>Focus on clear navigation and mobile-friendly design</li>
          <li>Include specific service pages for different insurance types you offer</li>
          <li>Feature client testimonials prominently</li>
          <li>Offer multiple easy contact options</li>
          <li>Implement live chat where possible</li>
        </ul>
        
        <h4>2. Local SEO Optimization</h4>
        <p>Since insurance agents typically serve specific geographic areas, local SEO is crucial:</p>
        <ul>
          <li>Create and optimize your Google Business Profile</li>
          <li>Incorporate location-specific keywords throughout your site</li>
          <li>Build citations on insurance directories and local business listings</li>
          <li>Generate reviews on Google, Yelp, and industry-specific platforms</li>
        </ul>
        
        <h3>Content Marketing for Insurance Agents</h3>
        
        <h4>1. Educational Blog Content</h4>
        <p>Position yourself as an expert through helpful content:</p>
        <ul>
          <li>Answer common insurance questions in simple, clear language</li>
          <li>Create guides for life events that trigger insurance needs (new home, baby, retirement)</li>
          <li>Explain policy features and coverage options</li>
          <li>Share real claim stories (anonymized) that demonstrate the value of proper coverage</li>
        </ul>
        
        <h4>2. Video Content</h4>
        <p>Video builds trust and personality:</p>
        <ul>
          <li>Create brief explainer videos on insurance concepts</li>
          <li>Record client testimonials</li>
          <li>Develop a "meet the agent" video for your website</li>
          <li>Share quick tips on social platforms</li>
        </ul>
        
        <h3>Social Media Strategy</h3>
        
        <h4>1. Platform Selection</h4>
        <p>Focus on quality over quantity:</p>
        <ul>
          <li>LinkedIn for B2B connections and professional networking</li>
          <li>Facebook for community engagement and targeting specific demographics</li>
          <li>Instagram for visual storytelling and reaching younger clients</li>
          <li>NextDoor for hyper-local community connection</li>
        </ul>
        
        <h4>2. Content Mix</h4>
        <p>Balance your social content:</p>
        <ul>
          <li>40% educational (tips, industry news, coverage explanations)</li>
          <li>30% engagement (questions, polls, community involvement)</li>
          <li>20% promotional (specific services, seasonal offerings)</li>
          <li>10% personal (team highlights, community involvement, behind-the-scenes)</li>
        </ul>
        
        <h3>Email Marketing Campaigns</h3>
        <p>Email remains highly effective for insurance marketing:</p>
        <ul>
          <li>Create segmented lists based on policy type, lifecycle stage, etc.</li>
          <li>Develop a welcome sequence for new leads</li>
          <li>Send seasonal reminders for coverage reviews</li>
          <li>Share relevant life-event triggers (new drivers, homebuying season)</li>
          <li>Distribute your educational content</li>
        </ul>
        
        <h3>Using AI to Enhance Your Marketing</h3>
        <p>Tools like LeadGPT can help independent agents compete with larger carriers:</p>
        <ul>
          <li>Analyze which marketing messages resonate with different client segments</li>
          <li>Automate personalized follow-up sequences</li>
          <li>Identify trends in prospect inquiries to inform content creation</li>
          <li>Optimize ad spending based on conversion patterns</li>
        </ul>
        
        <p>While digital marketing may seem overwhelming alongside your insurance responsibilities, even implementing a few of these strategies consistently can significantly impact your agency's growth. Start with the approaches that align best with your strengths and client base, then expand as you build momentum.</p>
      `
    },
    {
      id: "ai-chatbots-insurance",
      title: "How AI Chatbots Are Transforming Lead Qualification",
      date: "March 19, 2025",
      category: "AI Technology",
      image: "https://images.unsplash.com/photo-1527689368864-3a821dbccc34?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80",
      tags: ["AI Chatbots", "Lead Qualification", "Automation", "Customer Experience"],
      excerpt: "Discover how modern AI chatbots are revolutionizing the lead qualification process for insurance agents, saving time while improving conversion rates.",
      readTime: "5 min read",
      content: `
        <h2>The New Era of Lead Qualification</h2>
        <p>The insurance industry has always faced a critical challenge: efficiently identifying which prospects are worth an agent's valuable time. Modern AI chatbots are revolutionizing this process, going far beyond the simple decision-tree bots of the past to create meaningful, intelligent interactions that effectively qualify leads 24/7.</p>
        
        <h3>Beyond Basic Q&A: How Modern AI Chatbots Work</h3>
        <p>Today's insurance chatbots leverage several advanced technologies:</p>
        <ul>
          <li><strong>Natural Language Processing (NLP)</strong>: Understands prospect questions even when phrased conversationally</li>
          <li><strong>Machine Learning</strong>: Improves responses based on thousands of previous interactions</li>
          <li><strong>Sentiment Analysis</strong>: Detects frustration, confusion, or enthusiasm in prospect responses</li>
          <li><strong>Integration Capabilities</strong>: Connects with CRMs, quoting engines, and calendars</li>
        </ul>
        <p>This creates a much more natural conversation flow that prospects find helpful rather than frustrating.</p>
        
        <h3>The Lead Qualification Process Reimagined</h3>
        
        <h4>1. Initial Engagement</h4>
        <p>AI chatbots can engage website visitors at exactly the right moment—whether that's immediately upon arrival, after viewing specific pages, or when exhibiting exit intent. This perfect timing increases engagement rates significantly compared to static forms.</p>
        
        <h4>2. Information Gathering</h4>
        <p>Through conversational dialogue, chatbots gather key qualifying information:</p>
        <ul>
          <li>Current insurance status and renewal dates</li>
          <li>Specific coverage needs and concerns</li>
          <li>Budget expectations</li>
          <li>Timeline for decision making</li>
          <li>Communication preferences</li>
        </ul>
        <p>The conversational approach feels less intrusive than form-filling, resulting in higher completion rates.</p>
        
        <h4>3. Real-Time Qualification</h4>
        <p>As information is gathered, AI algorithms assess lead quality based on:</p>
        <ul>
          <li>Alignment with ideal client profiles</li>
          <li>Urgency signals in responses</li>
          <li>Budget-solution fit</li>
          <li>Engagement level during the conversation</li>
        </ul>
        
        <h4>4. Appropriate Next Steps</h4>
        <p>Based on qualification scores, the chatbot can:</p>
        <ul>
          <li>Schedule high-value prospects directly into an agent's calendar</li>
          <li>Provide preliminary quotes for qualified prospects</li>
          <li>Direct mid-tier leads to educational resources</li>
          <li>Offer self-service options for leads that aren't agent-ready</li>
        </ul>
        
        <h3>Key Benefits for Insurance Agents</h3>
        
        <h4>Time Optimization</h4>
        <p>Agents using AI-qualified leads report spending 40% less time on prospects who aren't ready to buy, allowing them to focus on higher-probability opportunities.</p>
        
        <h4>After-Hours Capture</h4>
        <p>Up to 35% of insurance inquiries occur outside business hours. AI chatbots ensure these valuable leads receive immediate attention when human agents aren't available.</p>
        
        <h4>Improved Conversion Rates</h4>
        <p>Agencies implementing AI chatbots see an average 28% improvement in lead-to-appointment conversion, as agents receive better-qualified, better-informed prospects.</p>
        
        <h4>Enhanced Customer Experience</h4>
        <p>Prospects appreciate the immediate response, 24/7 availability, and the ability to gather information at their own pace without sales pressure.</p>
        
        <h3>Implementation Best Practices</h3>
        <p>To maximize the effectiveness of AI chatbots for lead qualification:</p>
        <ul>
          <li>Train your chatbot with your specific products and qualification criteria</li>
          <li>Ensure seamless handoff between bot and human agents</li>
          <li>Continuously refine qualification algorithms based on actual sales outcomes</li>
          <li>Maintain a balance between gathering information and providing value</li>
          <li>Be transparent about the automated nature of initial conversations</li>
        </ul>
        
        <h3>The Future: AI and Human Collaboration</h3>
        <p>The most successful insurance agencies aren't replacing humans with AI—they're creating a powerful collaboration where AI handles initial qualification, information gathering, and routine queries, while human agents focus on building relationships, addressing complex needs, and closing sales.</p>
        
        <p>This division of labor plays to the strengths of both AI (24/7 availability, consistent performance, data processing) and human agents (empathy, complex problem solving, relationship building), creating a superior experience for prospects and a more efficient sales process.</p>
      `
    }
  ];

  // Function to find a post by ID from all posts
  const findPostById = (id: string): BlogPost | undefined => {
    return [...featuredPosts, ...posts, ...popularPosts, ...recentArticles].find(
      post => post.id === id
    ) as BlogPost | undefined;
  };

  // Open article detail modal
  const openArticleModal = (postId: string) => {
    const post = findPostById(postId);
    if (post) {
      setSelectedPost(post);
      setIsDialogOpen(true);
    }
  };

  // Filter posts based on search query and category
  const filteredPosts = useMemo(() => {
    return posts.filter(post => {
      // Category filter
      const categoryMatch = selectedCategory === "All" || post.category === selectedCategory;
      
      // Search filter
      const searchTerms = searchQuery.toLowerCase();
      const matchesSearch = 
        searchQuery === "" || 
        post.title.toLowerCase().includes(searchTerms) ||
        post.excerpt.toLowerCase().includes(searchTerms) ||
        post.author.toLowerCase().includes(searchTerms) ||
        post.category.toLowerCase().includes(searchTerms) ||
        (post.tags && post.tags.some(tag => tag.toLowerCase().includes(searchTerms)));
      
      return categoryMatch && matchesSearch;
    });
  }, [posts, searchQuery, selectedCategory]);

  // Get the visible posts based on current count
  const visiblePosts = useMemo(() => {
    return filteredPosts.slice(0, visiblePostCount);
  }, [filteredPosts, visiblePostCount]);

  // Function to load more posts
  const loadMorePosts = () => {
    setVisiblePostCount(prev => prev + 4); // Increase by 4 each time
  };

  // Function to handle category selection
  const handleCategorySelect = (category: string) => {
    setSelectedCategory(category);
    setVisiblePostCount(4); // Reset visible count when changing category
  };

  // Function to handle search input
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    setVisiblePostCount(4); // Reset visible count when searching
  };

  // Function to get post count by category
  const getPostCountByCategory = (category: string): number => {
    return [...posts, ...featuredPosts, ...popularPosts, ...recentArticles].filter(
      post => post.category === category
    ).length;
  };

  // Function to filter posts by tag
  const filterByTag = (tag: string) => {
    setSearchQuery(tag);
    setSelectedCategory("All");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // Add styles when component mounts
  useEffect(() => {
    // Add the blog styles to the document head
    const styleElement = document.createElement('style');
    styleElement.textContent = blogStylesCSS;
    document.head.appendChild(styleElement);

    // Clean up style when component unmounts
    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="container mx-auto px-4 py-12">
        {/* Back Button */}
        <Link to="/" className="inline-block mb-8">
          <Button variant="ghost" className="gap-2">
            <ArrowLeft className="h-4 w-4" />
            Back to Home
          </Button>
        </Link>

        <div className="max-w-7xl mx-auto">
          {/* Hero Section with Featured Posts */}
          <div className="mb-12">
            <h1 className="text-4xl font-bold mb-4">LeadGPT Insurance Sales Blog</h1>
            <p className="text-lg text-gray-600 mb-8">
              Expert insights, proven strategies, and industry trends for insurance agents looking to grow their business with AI
            </p>

            <div className="grid md:grid-cols-2 gap-8">
              {featuredPosts.map((post, index) => (
                <div key={index} className="bg-white rounded-xl shadow-sm overflow-hidden">
                  <div className="h-48 bg-gray-200">
                    <img
                      src={post.image}
                      alt={`Featured image for article: ${post.title}`}
                      className="w-full h-full object-cover"
                      onError={(e) => {
                        e.currentTarget.src = "https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1472&q=80"
                      }}
                    />
                  </div>
                  <div className="p-6">
                    <div className="flex items-center gap-2 text-sm text-primary mb-2">
                      <Tag className="h-4 w-4" />
                      {post.category}
                    </div>
                    <h2 className="text-xl font-bold mb-2">{post.title}</h2>
                    <p className="text-gray-600 mb-4">{post.excerpt}</p>
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-2 text-sm text-gray-500">
                        <User className="h-4 w-4" />
                        {post.author}
                        <span>•</span>
                        <Clock className="h-4 w-4" />
                        {post.readTime}
                      </div>
                      <Button 
                        variant="ghost" 
                        className="gap-2"
                        onClick={() => openArticleModal(post.id)}
                      >
                        Read More
                        <ArrowRight className="h-4 w-4" />
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="grid md:grid-cols-3 gap-8">
            {/* Main Content */}
            <div className="md:col-span-2">
              {/* Search and Categories */}
              <div className="bg-white rounded-xl shadow-sm p-6 mb-8">
                <div className="relative mb-6">
                  <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
                  <Input 
                    className="pl-10" 
                    placeholder="Search insurance sales and AI articles..." 
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </div>
                <div className="flex flex-wrap gap-2">
                  {categories.map((category, index) => (
                    <Button
                      key={index}
                      variant={category === selectedCategory ? "default" : "outline"}
                      size="sm"
                      onClick={() => handleCategorySelect(category)}
                    >
                      {category}
                    </Button>
                  ))}
                </div>
              </div>

              {/* Blog Posts */}
              <div className="space-y-8">
                {visiblePosts.length > 0 ? (
                  visiblePosts.map((post, index) => (
                  <div key={index} className="bg-white rounded-xl shadow-sm overflow-hidden">
                    <div className="md:flex">
                      <div className="md:w-1/3 h-48 md:h-auto bg-gray-200">
                        <img
                          src={post.image}
                            alt={`Image for ${post.title}`}
                          className="w-full h-full object-cover"
                          onError={(e) => {
                              e.currentTarget.src = "https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1472&q=80"
                          }}
                        />
                      </div>
                      <div className="p-6 md:w-2/3">
                        <div className="flex items-center gap-2 text-sm text-primary mb-2">
                          <Tag className="h-4 w-4" />
                          {post.category}
                        </div>
                        <h2 className="text-xl font-bold mb-2">{post.title}</h2>
                        <p className="text-gray-600 mb-4">{post.excerpt}</p>
                        <div className="flex items-center justify-between">
                          <div className="flex items-center gap-2 text-sm text-gray-500">
                            <User className="h-4 w-4" />
                            {post.author}
                            <span>•</span>
                            <Clock className="h-4 w-4" />
                            {post.readTime}
                          </div>
                            <Button 
                              variant="ghost" 
                              className="gap-2"
                              onClick={() => openArticleModal(post.id)}
                            >
                            Read More
                            <ArrowRight className="h-4 w-4" />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  ))
                ) : (
                  <div className="bg-white rounded-xl shadow-sm p-8 text-center">
                    <h3 className="text-xl font-bold mb-2">No Articles Found</h3>
                    <p className="text-gray-600">
                      We couldn't find any articles matching your search criteria. Try adjusting your search terms or category selection.
                    </p>
                    {searchQuery || selectedCategory !== "All" ? (
                      <Button 
                        variant="outline" 
                        className="mt-4"
                        onClick={() => {
                          setSearchQuery("");
                          setSelectedCategory("All");
                        }}
                      >
                        Reset Filters
                      </Button>
                    ) : null}
                  </div>
                )}
              </div>

              {/* Load More */}
              {visiblePosts.length > 0 && visiblePosts.length < filteredPosts.length && (
              <div className="text-center mt-8">
                  <Button 
                    variant="outline" 
                    size="lg"
                    onClick={loadMorePosts}
                  >
                    Load More Articles
                  </Button>
              </div>
              )}
            </div>

            {/* Sidebar */}
            <div className="space-y-8">
              {/* Newsletter Subscription */}
              <div className="bg-white rounded-xl shadow-sm p-6">
                <div className="w-12 h-12 rounded-lg bg-primary/10 flex items-center justify-center mb-4">
                  <BookOpen className="h-6 w-6 text-primary" />
                </div>
                <h3 className="text-xl font-bold mb-2">Subscribe to Our Newsletter</h3>
                <p className="text-gray-600 mb-4">
                  Get the latest insurance sales tips, AI insights, and industry news delivered to your inbox.
                </p>
                <div className="space-y-3">
                  <Input placeholder="Enter your email" />
                  <Button className="w-full">Subscribe</Button>
                </div>
              </div>

              {/* Popular Posts */}
              <div className="bg-white rounded-xl shadow-sm p-6">
                <div className="flex items-center gap-2 mb-4">
                  <TrendingUp className="h-5 w-5 text-primary" />
                  <h3 className="text-xl font-bold">Popular Posts</h3>
                </div>
                <div className="space-y-4">
                  {popularPosts.map((post, index) => (
                    <div 
                      key={index} 
                      className="group cursor-pointer" 
                      onClick={() => openArticleModal(post.id)}
                    >
                      <h4 className="font-medium group-hover:text-primary transition-colors mb-1">
                        {post.title}
                      </h4>
                      <div className="flex items-center gap-2 text-sm text-gray-500">
                        <Clock className="h-4 w-4" />
                        {post.readTime}
                        <span>•</span>
                        {post.date}
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* Recent Articles */}
              <div className="bg-white rounded-xl shadow-sm p-6">
                <div className="flex items-center gap-2 mb-4">
                  <Clock className="h-5 w-5 text-primary" />
                  <h3 className="text-xl font-bold">Recent Articles</h3>
                </div>
                <div className="space-y-4">
                  {recentArticles.map((post, index) => (
                    <div 
                      key={index} 
                      className="group cursor-pointer" 
                      onClick={() => openArticleModal(post.id)}
                    >
                      <h4 className="font-medium group-hover:text-primary transition-colors mb-1">
                        {post.title}
                      </h4>
                      <div className="flex items-center gap-2 text-sm text-gray-500">
                        <Tag className="h-4 w-4" />
                        {post.category}
                        <span>•</span>
                        {post.date}
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* Categories */}
              <div className="bg-white rounded-xl shadow-sm p-6">
                <h3 className="text-xl font-bold mb-4">Categories</h3>
                <div className="space-y-2">
                  {categories.slice(1).map((category, index) => (
                    <div 
                      key={index} 
                      className="flex items-center justify-between group cursor-pointer"
                      onClick={() => handleCategorySelect(category)}
                    >
                      <span className={`${category === selectedCategory ? 'text-primary font-medium' : ''} group-hover:text-primary transition-colors`}>{category}</span>
                      <span className="text-sm text-gray-500">
                        {getPostCountByCategory(category)} articles
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* FAQ Section for SEO */}
          <div className="mt-16 bg-white rounded-xl shadow-sm p-8">
            <h2 className="text-2xl font-bold mb-6">Frequently Asked Questions About Insurance Sales</h2>
            <div className="space-y-6">
              {[
                {
                  question: "How is AI changing the insurance sales process?",
                  answer: "AI is revolutionizing insurance sales by automating lead qualification, providing data-driven insights, predicting client needs, and streamlining administrative tasks. This allows agents to focus more on building relationships and less on paperwork."
                },
                {
                  question: "What are the most effective lead generation strategies for insurance agents?",
                  answer: "The most effective lead generation strategies include targeted digital marketing, referral programs, strategic partnerships, content marketing, and leveraging AI-powered tools to identify and engage potential clients."
                },
                {
                  question: "How can insurance agents stay compliant while using AI tools?",
                  answer: "Insurance agents can stay compliant by choosing AI tools with built-in compliance features, regularly reviewing privacy policies, ensuring proper data handling practices, maintaining accurate documentation, and staying updated on industry regulations."
                },
                {
                  question: "What are the key metrics insurance agents should track to improve performance?",
                  answer: "Key performance metrics include conversion rate, client acquisition cost, lifetime value, retention rate, cross-selling ratio, response time, and return on marketing investment."
                }
              ].map((faq, index) => (
                <div key={index}>
                  <h3 className="text-xl font-bold mb-2">{faq.question}</h3>
                  <p className="text-gray-600">{faq.answer}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Article Detail Modal */}
      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent className="max-w-4xl max-h-[90vh] overflow-y-auto">
          {selectedPost && (
            <>
              <DialogHeader>
                <div className="flex justify-between items-center">
                  <div className="flex items-center gap-2 text-sm text-primary">
                    <Tag className="h-4 w-4" />
                    {selectedPost.category}
                  </div>
                </div>
                <DialogTitle className="text-2xl font-bold mt-2">{selectedPost.title}</DialogTitle>
                <DialogDescription>
                  <div className="flex items-center gap-2 text-sm text-gray-500 mt-2">
                    <User className="h-4 w-4" />
                    {selectedPost.author}
                    <span>•</span>
                    <Clock className="h-4 w-4" />
                    {selectedPost.readTime}
                    <span>•</span>
                    {selectedPost.date}
                  </div>
                </DialogDescription>
              </DialogHeader>

              <div className="mt-4">
                <div className="h-64 bg-gray-200 mb-6 rounded-lg overflow-hidden">
                  <img
                    src={selectedPost.image}
                    alt={`Featured image for article: ${selectedPost.title}`}
                    className="w-full h-full object-cover"
                    onError={(e) => {
                      e.currentTarget.src = "https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1472&q=80"
                    }}
                  />
                </div>
                
                {/* Use the FormattedContent component for proper styling */}
                <FormattedContent content={selectedPost.content} />
                
                <div className="flex flex-wrap gap-2 mt-6">
                  {selectedPost.tags && selectedPost.tags.map((tag, index) => (
                    <span 
                      key={index} 
                      className="bg-gray-100 text-gray-800 px-2 py-1 rounded-full text-sm cursor-pointer hover:bg-gray-200"
                      onClick={() => {
                        filterByTag(tag);
                        setIsDialogOpen(false);
                      }}
                    >
                      #{tag}
                    </span>
                  ))}
                </div>
              </div>
            </>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
} 