import { Progress } from '@/components/ui/progress';
import { HiOutlineRefresh } from 'react-icons/hi';

export function ImportProgress() {
  return (
    <div className="flex flex-col items-center justify-center py-8 space-y-6">
      <div className="animate-spin text-blue-500">
        <HiOutlineRefresh className="h-16 w-16" />
      </div>
      <h3 className="text-xl font-medium text-center">Importing Leads</h3>
      <p className="text-gray-500 text-center max-w-md">
        Please wait while your leads are being imported. This may take a few moments depending on the size of your file.
      </p>
      <div className="w-full max-w-md">
        <Progress value={undefined} className="h-2" />
      </div>
    </div>
  );
} 