import { Drawer, DrawerContent } from '@/components/ui/drawer';
import { useLeadStore } from '@/store/leadStore';
import { Lead } from '@/types';
import { useEffect, useState } from 'react';
import { CommunicationPane } from './CommunicationPane';

interface CommunicationPaneDrawerProps {
  lead: Lead;
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export function CommunicationPaneDrawer({ lead, open, onOpenChange }: CommunicationPaneDrawerProps) {
  // When the drawer opens, update the selected lead in the store
  useEffect(() => {
    if (open && lead) {
      useLeadStore.getState().setSelectedLead({
        id: lead.id,
        name: lead.name,
        email: lead.email,
        phone: lead.phoneNumber,
        city: lead.city,
        state: lead.state,
        county: lead.county,
        zip: lead.zip,
        businessName: lead.businessName,
        title: lead.title
      });
    }
  }, [open, lead]);

  // Use different drawer directions based on screen size
  const [direction, setDirection] = useState<"bottom" | "right">("bottom");
  
  useEffect(() => {
    // Set initial direction
    setDirection(window.innerWidth >= 1024 ? "right" : "bottom");
    
    // Update direction on resize
    const handleResize = () => {
      setDirection(window.innerWidth >= 1024 ? "right" : "bottom");
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Drawer open={open} onOpenChange={onOpenChange} direction={direction}>
      <DrawerContent className="h-[90vh] p-0 bg-background">
        <CommunicationPane 
          phoneNumber={lead.phoneNumber} 
          name={lead.name} 
          className="h-full border-none rounded-none" 
          isDrawer={true} // Mark this as a drawer to prevent slide animation
          leadId={lead.id}
        />
      </DrawerContent>
    </Drawer>
  );
} 