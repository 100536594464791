import { axiosInstance } from '@/lib/api';

// Types
export interface CalendarIntegration {
  id: string;
  provider: 'google' | 'microsoft' | 'apple' | 'calendly';
  syncEnabled: boolean;
  calendarId: string | null;
  createdAt: string;
  updatedAt: string;
}

export interface CalendarEvent {
  id: string;
  leadId: number | null;
  calendarIntegrationId: string;
  externalEventId: string | null;
  title: string;
  description: string | null;
  startTime: string;
  endTime: string;
  location: string | null;
  meetingLink: string | null;
  reminderSent: boolean;
  attendanceConfirmed: boolean;
  outcome: string | null;
  createdAt: string;
  updatedAt: string;
}

export interface GoogleCalendar {
  id: string;
  summary: string;
  description?: string;
  primary?: boolean;
}

// Google Calendar integration services
export const CalendarService = {
  // Get OAuth URL for Google Calendar
  getGoogleAuthUrl: async (teamId: string): Promise<{ authUrl: string }> => {
    const response = await axiosInstance.post('/calendar/google/auth', { teamId });
    return response.data;
  },

  // Get connected calendar integrations
  getCalendarIntegrations: async (): Promise<CalendarIntegration[]> => {
    const response = await axiosInstance.get('/calendar/integrations');
    return response.data.integrations;
  },

  // List available Google Calendars
  listGoogleCalendars: async (integrationId: string): Promise<GoogleCalendar[]> => {
    const response = await axiosInstance.get(`/calendar/google/connected/${integrationId}`);
    return response.data.calendars;
  },

  // Select primary Google Calendar
  selectGoogleCalendar: async (integrationId: string, calendarId: string): Promise<void> => {
    await axiosInstance.post(`/calendar/google/select/${integrationId}`, { calendarId });
  },

  // Disconnect Google Calendar
  disconnectGoogleCalendar: async (integrationId: string): Promise<void> => {
    await axiosInstance.delete(`/calendar/google/${integrationId}`);
  },

  // Toggle calendar sync settings
  toggleCalendarSync: async (integrationId: string, enabled: boolean): Promise<void> => {
    await axiosInstance.post(`/calendar/google/${integrationId}/sync`, { enabled });
  },

  // Manually trigger calendar sync
  syncCalendarNow: async (integrationId: string): Promise<void> => {
    await axiosInstance.post(`/calendar/google/${integrationId}/sync-now`);
  },

  // Get synced calendar events
  getSyncedEvents: async (integrationId: string): Promise<CalendarEvent[]> => {
    const response = await axiosInstance.get(`/calendar/google/${integrationId}/events`);
    return response.data.events;
  },

  // Get calendar events for a lead
  getLeadEvents: async (leadId: number): Promise<CalendarEvent[]> => {
    const response = await axiosInstance.get(`/calendar/events/lead/${leadId}`);
    return response.data.events;
  },

  // Create calendar event
  createEvent: async (event: Omit<CalendarEvent, 'id' | 'externalEventId' | 'reminderSent' | 'attendanceConfirmed' | 'createdAt' | 'updatedAt'>): Promise<CalendarEvent> => {
    const response = await axiosInstance.post('/calendar/events', event);
    return response.data.event;
  },

  // Update calendar event
  updateEvent: async (eventId: string, event: Partial<CalendarEvent>): Promise<CalendarEvent> => {
    const response = await axiosInstance.put(`/calendar/events/${eventId}`, event);
    return response.data.event;
  },

  // Add Google Meet link to an event and update attendees
  updateEventWithMeetLink: async (eventId: string): Promise<CalendarEvent> => {
    const response = await axiosInstance.post(`/calendar/events/${eventId}/add-meet-link`, {});
    return response.data.event;
  },

  // Delete calendar event
  deleteEvent: async (eventId: string): Promise<void> => {
    await axiosInstance.delete(`/calendar/events/${eventId}`);
  },

  // Get available time slots based on team member availability
  getAvailableTimeSlots: async (params: {
    teamId: string;
    duration: number;
    startDate: string;
    endDate: string;
  }): Promise<Array<{ start: string; end: string }>> => {
    const response = await axiosInstance.post('/calendar/availability', params);
    return response.data.slots;
  }
}; 