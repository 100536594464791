import { Button } from "@/components/ui/button";
import { ArrowLeft, CheckCircle, Shield } from "lucide-react";

export function HIPAA() {
  const lastUpdated = "March 18, 2024";

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="container mx-auto px-4 py-12">
        {/* Back Button */}
        <a href="https://leadgpt.com" className="inline-block mb-8">
          <Button variant="ghost" className="gap-2">
            <ArrowLeft className="h-4 w-4" />
            Back to Home
          </Button>
        </a>

        <div className="max-w-4xl mx-auto">
          {/* Hero Section */}
          <div className="bg-white rounded-xl shadow-sm p-8 md:p-12 mb-8">
            <div className="flex items-center gap-4 mb-6">
              <div className="h-12 w-12 rounded-full bg-primary/10 flex items-center justify-center">
                <Shield className="h-6 w-6 text-primary" />
              </div>
              <div>
                <h1 className="text-3xl md:text-4xl font-bold">HIPAA Compliance</h1>
                <p className="text-gray-600">Last Updated: {lastUpdated}</p>
              </div>
            </div>
            
            <p className="text-lg text-gray-600 mb-6">
              LeadGPT is committed to maintaining the highest standards of HIPAA compliance to protect your clients' sensitive health information. Our platform is built with privacy and security at its core.
            </p>

            <div className="grid md:grid-cols-3 gap-4">
              {[
                {
                  title: "Data Protection",
                  description: "Enterprise-grade encryption for all PHI"
                },
                {
                  title: "Access Controls",
                  description: "Role-based access and authentication"
                },
                {
                  title: "Audit Trails",
                  description: "Comprehensive logging and monitoring"
                }
              ].map((feature, index) => (
                <div key={index} className="bg-gray-50 p-4 rounded-lg">
                  <h3 className="font-bold mb-2">{feature.title}</h3>
                  <p className="text-sm text-gray-600">{feature.description}</p>
                </div>
              ))}
            </div>
          </div>

          {/* Main Content */}
          <div className="bg-white rounded-xl shadow-sm p-8 md:p-12">
            <div className="prose prose-gray max-w-none">
              <section className="mb-8">
                <h2 className="text-2xl font-bold mb-4">Our HIPAA Commitment</h2>
                <p className="mb-4">
                  As a platform handling sensitive health insurance information, we understand the critical importance of HIPAA compliance. Our commitment includes:
                </p>
                <ul className="space-y-3">
                  {[
                    "Regular security assessments and audits",
                    "Comprehensive staff training on HIPAA regulations",
                    "Strict data handling and privacy protocols",
                    "Business Associate Agreements (BAA) with all partners"
                  ].map((item, i) => (
                    <li key={i} className="flex items-start gap-3">
                      <CheckCircle className="h-5 w-5 text-green-500 mt-0.5 flex-shrink-0" />
                      <span>{item}</span>
                    </li>
                  ))}
                </ul>
              </section>

              <section className="mb-8">
                <h2 className="text-2xl font-bold mb-4">Technical Safeguards</h2>
                <div className="grid md:grid-cols-2 gap-6">
                  <div className="bg-gray-50 p-6 rounded-lg">
                    <h3 className="text-xl font-bold mb-3">Data Encryption</h3>
                    <ul className="space-y-2">
                      <li>AES-256 encryption at rest</li>
                      <li>TLS 1.3 for data in transit</li>
                      <li>Secure key management</li>
                      <li>Regular encryption updates</li>
                    </ul>
                  </div>
                  <div className="bg-gray-50 p-6 rounded-lg">
                    <h3 className="text-xl font-bold mb-3">Access Controls</h3>
                    <ul className="space-y-2">
                      <li>Multi-factor authentication</li>
                      <li>Role-based access control</li>
                      <li>Session management</li>
                      <li>Automatic logoff</li>
                    </ul>
                  </div>
                </div>
              </section>

              <section className="mb-8">
                <h2 className="text-2xl font-bold mb-4">Administrative Safeguards</h2>
                <div className="space-y-4">
                  <div className="flex items-start gap-4">
                    <div className="w-12 h-12 rounded-lg bg-blue-100 flex items-center justify-center flex-shrink-0">
                      <Shield className="h-6 w-6 text-blue-600" />
                    </div>
                    <div>
                      <h3 className="text-xl font-bold mb-2">Risk Assessment</h3>
                      <p className="text-gray-600">
                        Regular evaluation of potential risks and vulnerabilities to PHI, with comprehensive mitigation strategies.
                      </p>
                    </div>
                  </div>

                  <div className="flex items-start gap-4">
                    <div className="w-12 h-12 rounded-lg bg-green-100 flex items-center justify-center flex-shrink-0">
                      <Shield className="h-6 w-6 text-green-600" />
                    </div>
                    <div>
                      <h3 className="text-xl font-bold mb-2">Workforce Training</h3>
                      <p className="text-gray-600">
                        Comprehensive HIPAA training program for all employees, with regular updates and assessments.
                      </p>
                    </div>
                  </div>

                  <div className="flex items-start gap-4">
                    <div className="w-12 h-12 rounded-lg bg-purple-100 flex items-center justify-center flex-shrink-0">
                      <Shield className="h-6 w-6 text-purple-600" />
                    </div>
                    <div>
                      <h3 className="text-xl font-bold mb-2">Incident Response</h3>
                      <p className="text-gray-600">
                        Documented procedures for identifying, responding to, and reporting security incidents.
                      </p>
                    </div>
                  </div>
                </div>
              </section>

              <section className="mb-8">
                <h2 className="text-2xl font-bold mb-4">Physical Safeguards</h2>
                <p className="mb-4">
                  Our infrastructure is hosted in secure data centers with:
                </p>
                <ul className="space-y-3">
                  {[
                    "24/7 physical security monitoring",
                    "Biometric access controls",
                    "Environmental controls",
                    "Redundant power systems",
                    "Regular facility audits"
                  ].map((item, i) => (
                    <li key={i} className="flex items-start gap-3">
                      <CheckCircle className="h-5 w-5 text-green-500 mt-0.5 flex-shrink-0" />
                      <span>{item}</span>
                    </li>
                  ))}
                </ul>
              </section>

              <section className="mb-8">
                <h2 className="text-2xl font-bold mb-4">Compliance Documentation</h2>
                <p className="mb-4">
                  We maintain comprehensive documentation of our HIPAA compliance program, including:
                </p>
                <ul className="space-y-3">
                  {[
                    "Privacy and security policies",
                    "Risk assessment reports",
                    "Training records",
                    "Incident response plans",
                    "Business Associate Agreements"
                  ].map((item, i) => (
                    <li key={i} className="flex items-start gap-3">
                      <CheckCircle className="h-5 w-5 text-green-500 mt-0.5 flex-shrink-0" />
                      <span>{item}</span>
                    </li>
                  ))}
                </ul>
              </section>

              <section>
                <h2 className="text-2xl font-bold mb-4">Contact Our Compliance Team</h2>
                <p className="mb-4">
                  For questions about our HIPAA compliance program or to request documentation:
                </p>
                <div className="bg-gray-50 p-4 rounded-lg">
                  <p>Email: compliance@leadgpt.com</p>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
} 