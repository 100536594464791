import { Button } from "@/components/ui/button";
import { ArrowLeft, Brain, Building2, CheckCircle, GraduationCap, Heart, MapPin, Rocket, Users } from "lucide-react";

export function Careers() {
  return (
    <div className="min-h-screen bg-gray-50">
      <div className="container mx-auto px-4 py-12">
        {/* Back Button */}
        <a href="https://leadgpt.com" className="inline-block mb-8">
          <Button variant="ghost" className="gap-2">
            <ArrowLeft className="h-4 w-4" />
            Back to Home
          </Button>
        </a>

        <div className="max-w-4xl mx-auto">
          {/* Hero Section */}
          <div className="bg-white rounded-xl shadow-sm p-8 md:p-12 mb-8">
            <div className="flex items-center gap-4 mb-6">
              <div className="h-12 w-12 rounded-full bg-primary/10 flex items-center justify-center">
                <Rocket className="h-6 w-6 text-primary" />
              </div>
              <div>
                <h1 className="text-3xl md:text-4xl font-bold">Join Our Team</h1>
                <p className="text-gray-600">Build the Future of Insurance Sales</p>
              </div>
            </div>
            
            <p className="text-lg text-gray-600 mb-6">
              We're looking for passionate individuals who want to transform the insurance industry with cutting-edge AI technology. Join us in our mission to empower insurance agents worldwide.
            </p>

            <div className="grid md:grid-cols-3 gap-4">
              {[
                {
                  title: "Team Size",
                  value: "50+",
                  description: "Talented professionals"
                },
                {
                  title: "Locations",
                  value: "5",
                  description: "Global offices"
                },
                {
                  title: "Growth",
                  value: "300%",
                  description: "YoY team growth"
                }
              ].map((stat, index) => (
                <div key={index} className="bg-gray-50 p-4 rounded-lg">
                  <p className="text-sm text-gray-600">{stat.title}</p>
                  <p className="text-2xl font-bold mb-1">{stat.value}</p>
                  <p className="text-sm text-gray-600">{stat.description}</p>
                </div>
              ))}
            </div>
          </div>

          {/* Main Content */}
          <div className="space-y-8">
            {/* Why Join Us */}
            <div className="bg-white rounded-xl shadow-sm p-8 md:p-12">
              <h2 className="text-2xl font-bold mb-6">Why Join LeadGPT?</h2>
              <div className="grid md:grid-cols-3 gap-6">
                {[
                  {
                    icon: <Brain className="h-6 w-6 text-purple-500" />,
                    title: "Innovation",
                    description: "Work with cutting-edge AI technology and shape the future of insurance sales."
                  },
                  {
                    icon: <Rocket className="h-6 w-6 text-blue-500" />,
                    title: "Growth",
                    description: "Fast-paced environment with abundant opportunities for career advancement."
                  },
                  {
                    icon: <Heart className="h-6 w-6 text-red-500" />,
                    title: "Impact",
                    description: "Make a real difference in how insurance agents work and succeed."
                  }
                ].map((reason, index) => (
                  <div key={index} className="bg-gray-50 p-6 rounded-lg">
                    <div className="w-12 h-12 rounded-lg bg-white flex items-center justify-center mb-4">
                      {reason.icon}
                    </div>
                    <h3 className="font-bold mb-2">{reason.title}</h3>
                    <p className="text-sm text-gray-600">{reason.description}</p>
                  </div>
                ))}
              </div>
            </div>

            {/* Benefits */}
            <div className="bg-white rounded-xl shadow-sm p-8 md:p-12">
              <h2 className="text-2xl font-bold mb-6">Benefits & Perks</h2>
              <div className="grid md:grid-cols-2 gap-6">
                {[
                  {
                    icon: <Heart className="h-6 w-6 text-red-500" />,
                    title: "Health & Wellness",
                    items: [
                      "Comprehensive health insurance",
                      "Dental and vision coverage",
                      "Mental health support",
                      "Wellness program stipend"
                    ]
                  },
                  {
                    icon: <GraduationCap className="h-6 w-6 text-blue-500" />,
                    title: "Learning & Development",
                    items: [
                      "Professional development budget",
                      "Conference attendance",
                      "Online course subscriptions",
                      "Mentorship program"
                    ]
                  },
                  {
                    icon: <Users className="h-6 w-6 text-green-500" />,
                    title: "Work-Life Balance",
                    items: [
                      "Flexible work hours",
                      "Remote work options",
                      "Unlimited PTO",
                      "Paid parental leave"
                    ]
                  },
                  {
                    icon: <Building2 className="h-6 w-6 text-purple-500" />,
                    title: "Financial Benefits",
                    items: [
                      "Competitive salary",
                      "Stock options",
                      "401(k) matching",
                      "Annual bonuses"
                    ]
                  }
                ].map((benefit, index) => (
                  <div key={index} className="bg-gray-50 p-6 rounded-lg">
                    <div className="flex items-center gap-3 mb-4">
                      <div className="w-10 h-10 rounded-lg bg-white flex items-center justify-center">
                        {benefit.icon}
                      </div>
                      <h3 className="text-xl font-bold">{benefit.title}</h3>
                    </div>
                    <ul className="space-y-2">
                      {benefit.items.map((item, i) => (
                        <li key={i} className="flex items-start gap-2">
                          <CheckCircle className="h-5 w-5 text-green-500 mt-0.5 flex-shrink-0" />
                          <span>{item}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>

            {/* Open Positions */}
            <div className="bg-white rounded-xl shadow-sm p-8 md:p-12">
              <h2 className="text-2xl font-bold mb-6">Open Positions</h2>
              <div className="space-y-4">
                {[
                  {
                    title: "Senior Full Stack Engineer",
                    department: "Engineering",
                    location: "San Francisco, CA / Remote",
                    type: "Full-time"
                  },
                  {
                    title: "AI/ML Engineer",
                    department: "Engineering",
                    location: "San Francisco, CA / Remote",
                    type: "Full-time"
                  },
                  {
                    title: "Product Manager",
                    department: "Product",
                    location: "San Francisco, CA",
                    type: "Full-time"
                  },
                  {
                    title: "Sales Development Representative",
                    department: "Sales",
                    location: "Remote",
                    type: "Full-time"
                  }
                ].map((position, index) => (
                  <div key={index} className="bg-gray-50 p-6 rounded-lg hover:shadow-md transition-shadow">
                    <div className="flex flex-col md:flex-row md:items-center justify-between gap-4">
                      <div>
                        <h3 className="font-bold text-lg mb-1">{position.title}</h3>
                        <div className="flex flex-wrap gap-2 text-sm text-gray-600">
                          <span className="flex items-center gap-1">
                            <Building2 className="h-4 w-4" />
                            {position.department}
                          </span>
                          <span className="flex items-center gap-1">
                            <MapPin className="h-4 w-4" />
                            {position.location}
                          </span>
                          <span>{position.type}</span>
                        </div>
                      </div>
                      <Button>Apply Now</Button>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Application Process */}
            <div className="bg-white rounded-xl shadow-sm p-8 md:p-12">
              <h2 className="text-2xl font-bold mb-6">Our Hiring Process</h2>
              <div className="space-y-6">
                {[
                  {
                    step: "1",
                    title: "Application Review",
                    description: "Our team reviews your application and ensures your experience matches our requirements."
                  },
                  {
                    step: "2",
                    title: "Initial Interview",
                    description: "A 30-minute video call to discuss your background and interest in LeadGPT."
                  },
                  {
                    step: "3",
                    title: "Technical/Skills Assessment",
                    description: "Role-specific evaluation to assess your expertise and problem-solving abilities."
                  },
                  {
                    step: "4",
                    title: "Team Interviews",
                    description: "Meet with potential teammates and leaders to ensure mutual fit."
                  },
                  {
                    step: "5",
                    title: "Offer & Welcome",
                    description: "Receive your offer and join our team!"
                  }
                ].map((step, index) => (
                  <div key={index} className="flex gap-4">
                    <div className="w-8 h-8 rounded-full bg-primary/10 flex items-center justify-center flex-shrink-0 mt-1">
                      <span className="text-primary font-bold">{step.step}</span>
                    </div>
                    <div>
                      <h3 className="font-bold mb-1">{step.title}</h3>
                      <p className="text-gray-600">{step.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* CTA */}
            <div className="bg-primary text-white rounded-xl shadow-sm p-8 md:p-12 text-center">
              <h2 className="text-2xl md:text-3xl font-bold mb-4">Ready to Make an Impact?</h2>
              <p className="text-lg mb-6 opacity-90">
                Join our team and help transform the insurance industry with AI technology.
              </p>
              <Button variant="secondary" size="lg" className="bg-white text-primary hover:bg-gray-100">
                View All Positions
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
} 