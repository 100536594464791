import { Activity, ArrowDown, ArrowUp, Mail, Phone, Users } from "lucide-react";
import { useEffect, useState } from "react";

// Mock Team Dashboard Component
export function TeamPerformanceDashboard() {
  // Sample data for charts and metrics
  const teamMembers = [
    { name: "Sarah Johnson", role: "Team Lead", avatar: "SJ", performance: 92, calls: 78, leads: 24 },
    { name: "Michael Chen", role: "Senior Agent", avatar: "MC", performance: 88, calls: 65, leads: 18 },
    { name: "Jessica Williams", role: "Agent", avatar: "JW", performance: 76, calls: 52, leads: 14 },
    { name: "David Rodriguez", role: "Agent", avatar: "DR", performance: 84, calls: 61, leads: 16 }
  ];
  
  const conversionRate = 28; // percentage
  const totalLeads = 342;
  const activeLeads = 156;
  
  // Random data generator for the chart animation
  const [leadData, setLeadData] = useState(Array(12).fill(0).map(() => Math.floor(Math.random() * 50) + 30));
  const [conversionData, setConversionData] = useState(
    leadData.map(value => Math.floor(value * (Math.random() * 0.3 + 0.2))) // 20-50% of leads
  );
  
  // Month labels for the chart
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  
  useEffect(() => {
    // Animate both lead and conversion data
    const interval = setInterval(() => {
      // Update lead data
      setLeadData(prev => {
        const newData = [...prev];
        const randomIndex = Math.floor(Math.random() * newData.length);
        newData[randomIndex] = Math.floor(Math.random() * 50) + 30;
        return newData;
      });
      
      // Update conversion data based on lead data
      setConversionData(prev => {
        const newData = [...prev];
        const randomIndex = Math.floor(Math.random() * newData.length);
        newData[randomIndex] = Math.floor(leadData[randomIndex] * (Math.random() * 0.3 + 0.2));
        return newData;
      });
    }, 5000);
    
    return () => clearInterval(interval);
  }, [leadData]);
  
  return (
    <div className="bg-white rounded-lg overflow-hidden">
      {/* Dashboard Header */}
      <div className="p-4 flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
        <div className="flex items-center">
          <h3 className="font-semibold text-gray-800 text-lg sm:text-xl">Team Performance Dashboard</h3>
          <div className="ml-3 px-2 py-1 bg-blue-100 text-blue-800 text-xs font-medium rounded">
            New
          </div>
        </div>
        <div className="flex items-center gap-2 w-full sm:w-auto">
          <select className="text-sm border border-gray-300 rounded px-2 py-1 flex-1 sm:flex-none">
            <option>Last 30 Days</option>
            <option>Last Quarter</option>
            <option>Year to Date</option>
          </select>
          <button className="text-sm bg-primary text-white px-3 py-1 rounded whitespace-nowrap">
            Export
          </button>
        </div>
      </div>
      
      {/* Dashboard Content */}
      <div className="p-4 grid grid-cols-1 gap-4">
        {/* Key Metrics */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-4">
          <div className="bg-blue-50 p-3 rounded-lg">
            <div className="flex justify-between items-start">
              <div>
                <p className="text-sm text-gray-500">Total Leads</p>
                <h4 className="text-xl sm:text-2xl font-bold text-gray-800">{totalLeads}</h4>
              </div>
              <div className="p-2 bg-blue-100 rounded-full">
                <Users size={18} className="text-blue-600" />
              </div>
            </div>
            <div className="flex items-center mt-2 text-xs text-green-600">
              <ArrowUp size={12} />
              <span className="ml-1">12% from last month</span>
            </div>
          </div>
          
          <div className="bg-green-50 p-3 rounded-lg">
            <div className="flex justify-between items-start">
              <div>
                <p className="text-sm text-gray-500">Conversion Rate</p>
                <h4 className="text-xl sm:text-2xl font-bold text-gray-800">{conversionRate}%</h4>
              </div>
              <div className="p-2 bg-green-100 rounded-full">
                <Activity size={18} className="text-green-600" />
              </div>
            </div>
            <div className="flex items-center mt-2 text-xs text-green-600">
              <ArrowUp size={12} />
              <span className="ml-1">3.2% from last month</span>
            </div>
          </div>
          
          <div className="bg-purple-50 p-3 rounded-lg">
            <div className="flex justify-between items-start">
              <div>
                <p className="text-sm text-gray-500">Active Leads</p>
                <h4 className="text-xl sm:text-2xl font-bold text-gray-800">{activeLeads}</h4>
              </div>
              <div className="p-2 bg-purple-100 rounded-full">
                <Phone size={18} className="text-purple-600" />
              </div>
            </div>
            <div className="flex items-center mt-2 text-xs text-red-600">
              <ArrowDown size={12} />
              <span className="ml-1">5% from last month</span>
            </div>
          </div>
          
          <div className="bg-amber-50 p-3 rounded-lg">
            <div className="flex justify-between items-start">
              <div>
                <p className="text-sm text-gray-500">Avg. Response Time</p>
                <h4 className="text-xl sm:text-2xl font-bold text-gray-800">2.4h</h4>
              </div>
              <div className="p-2 bg-amber-100 rounded-full">
                <Mail size={18} className="text-amber-600" />
              </div>
            </div>
            <div className="flex items-center mt-2 text-xs text-green-600">
              <ArrowUp size={12} />
              <span className="ml-1">18% faster than target</span>
            </div>
          </div>
        </div>
        
        {/* Charts Section */}
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-4">
          <div className="lg:col-span-8">
            <div className="bg-white border border-gray-200 rounded-lg p-4 h-[300px] sm:h-64">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-2 mb-4">
                <h4 className="font-medium text-gray-700">Monthly Lead Generation</h4>
                <div className="flex flex-wrap gap-4">
                  <div className="flex items-center">
                    <div className="h-3 w-3 bg-blue-500 rounded-full mr-1"></div>
                    <span className="text-xs text-gray-500">New Leads</span>
                  </div>
                  <div className="flex items-center">
                    <div className="h-3 w-3 bg-green-500 rounded-full mr-1"></div>
                    <span className="text-xs text-gray-500">Conversions</span>
                  </div>
                </div>
              </div>
              
              {/* Chart Visualization */}
              <div className="h-[180px] sm:h-44 flex items-end space-x-1 relative border-b border-gray-200">
                {leadData.map((value, index) => (
                  <div key={index} className="flex-1 flex flex-col items-center group">
                    {/* Tooltip on hover */}
                    <div className="absolute bottom-full mb-2 bg-gray-800 text-white text-xs rounded px-2 py-1 opacity-0 group-hover:opacity-100 transition-opacity pointer-events-none z-20">
                      <div>{months[index]}</div>
                      <div>Leads: {value}</div>
                      <div>Conversions: {conversionData[index]}</div>
                    </div>
                    
                    {/* Lead bar */}
                    <div className="w-full relative" style={{ minHeight: '2px' }}>
                      {/* Conversion bar (positioned inside lead bar) */}
                      <div 
                        className="absolute bottom-0 left-0 w-full bg-green-500 rounded-t transition-all duration-500 ease-in-out z-10"
                        style={{ 
                          height: `${Math.max((conversionData[index] / 100) * 100, 2)}px`,
                          minHeight: '2px'
                        }}
                      ></div>
                      
                      {/* Lead bar */}
                      <div 
                        className="absolute bottom-0 left-0 w-full bg-blue-500 rounded-t transition-all duration-500 ease-in-out"
                        style={{ 
                          height: `${Math.max((value / 100) * 150, 4)}px`,
                          minHeight: '4px'
                        }}
                      ></div>
                    </div>
                    
                    {/* Month label */}
                    <div className="text-[10px] sm:text-xs text-gray-500 mt-1">
                      {window.innerWidth < 640 ? months[index].substring(0, 1) : months[index].substring(0, 3)}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          
          {/* Team Performance */}
          <div className="lg:col-span-4">
            <div className="bg-white border border-gray-200 rounded-lg p-4 h-[300px] sm:h-64 overflow-y-auto">
              <h4 className="font-medium text-gray-700 mb-3">Team Performance</h4>
              <div className="space-y-3">
                {teamMembers.map((member, index) => (
                  <div key={index} className="flex items-center p-2 hover:bg-gray-50 rounded-lg">
                    <div className="h-8 w-8 rounded-full bg-primary text-white flex items-center justify-center font-medium text-xs mr-3">
                      {member.avatar}
                    </div>
                    <div className="flex-1 min-w-0">
                      <p className="text-sm font-medium text-gray-800 truncate">{member.name}</p>
                      <p className="text-xs text-gray-500">{member.role}</p>
                    </div>
                    <div className="text-right ml-2">
                      <p className="text-sm font-medium text-gray-800">{member.performance}%</p>
                      <div className="w-16 h-1.5 bg-gray-200 rounded-full mt-1">
                        <div 
                          className={`h-full rounded-full ${
                            member.performance > 85 ? 'bg-green-500' : 
                            member.performance > 75 ? 'bg-amber-500' : 'bg-red-500'
                          }`}
                          style={{ width: `${member.performance}%` }}
                        ></div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}