import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger
} from "@/components/ui/dropdown-menu";
import { Skeleton } from "@/components/ui/skeleton";
import { useTeams } from "@/hooks/useTeams";
import { useAuthStore } from "@/store/authStore";
import { AlertCircle, MoreHorizontal, RefreshCw, Shield, User, UserMinus } from "lucide-react";
import { useEffect, useState } from "react";

export function TeamMembersList() {
  const { 
    currentTeam, 
    currentTeamId,
    updateMemberRole, 
    removeMember, 
    isUpdatingRole, 
    isRemovingMember,
    getTeamDetails
  } = useTeams();
  
  const currentUser = useAuthStore(state => state.user);
  const [processingMemberId, setProcessingMemberId] = useState<number | null>(null);
  
  // Fetch team details if we have a currentTeamId but no members data
  const { 
    isLoading: isLoadingTeamDetails, 
    isError: isTeamDetailsError,
    error: teamDetailsError,
    refetch: refetchTeamDetails
  } = getTeamDetails(currentTeamId || 0);
  
  // Reset processing member ID when operations complete
  useEffect(() => {
    if (!isUpdatingRole && !isRemovingMember && processingMemberId !== null) {
      setProcessingMemberId(null);
    }
  }, [isUpdatingRole, isRemovingMember]);

  if (!currentTeam) {
    return (
      <Card>
        <CardHeader>
          <CardTitle>No Team Selected</CardTitle>
          <CardDescription>
            Please select a team to view its members.
          </CardDescription>
        </CardHeader>
      </Card>
    );
  }

  if (isLoadingTeamDetails) {
    return (
      <div className="space-y-4">
        <Skeleton className="h-16 w-full" />
        <Skeleton className="h-16 w-full" />
        <Skeleton className="h-16 w-full" />
      </div>
    );
  }
  
  if (isTeamDetailsError) {
    return (
      <Card className="border-red-200">
        <CardHeader>
          <CardTitle className="text-red-600 flex items-center gap-2">
            <AlertCircle className="h-5 w-5" />
            Error Loading Team Members
          </CardTitle>
          <CardDescription>
            {teamDetailsError instanceof Error 
              ? teamDetailsError.message 
              : 'Failed to load team members. Please try again.'}
          </CardDescription>
        </CardHeader>
        <CardContent>
          <Button 
            variant="outline" 
            size="sm" 
            onClick={() => refetchTeamDetails()}
            className="flex items-center gap-2"
          >
            <RefreshCw className="h-4 w-4" />
            Retry
          </Button>
        </CardContent>
      </Card>
    );
  }

  if (!currentTeam.members || currentTeam.members.length === 0) {
    return (
      <Card>
        <CardHeader>
          <CardTitle>No Team Members</CardTitle>
          <CardDescription>
            This team doesn't have any members yet.
          </CardDescription>
        </CardHeader>
      </Card>
    );
  }

  const isCurrentUserAdmin = currentTeam.role === 'admin';

  return (
    <Card>
      <CardHeader>
        <CardTitle>Team Members</CardTitle>
        <CardDescription>
          {currentTeam.members.length} {currentTeam.members.length === 1 ? 'member' : 'members'} in this team
        </CardDescription>
      </CardHeader>
      <CardContent>
        <ul className="space-y-4">
          {currentTeam.members.map((member) => {
            const isCurrentUser = currentUser && member.id === currentUser.id;
            const isProcessing = processingMemberId === member.id && (isUpdatingRole || isRemovingMember);
            const initials = member.name
              ? member.name.split(' ').map(n => n[0]).join('').toUpperCase()
              : member.email?.charAt(0).toUpperCase() || 'U';
            
            return (
              <li key={member.id} className="flex items-center justify-between">
                <div className="flex items-center gap-3">
                  <Avatar>
                    {member.profilePhotoPath ? (
                      <AvatarImage src={member.profilePhotoPath} alt={member.name || member.email || ''} />
                    ) : null}
                    <AvatarFallback>{initials}</AvatarFallback>
                  </Avatar>
                  <div>
                    <div className="font-medium">{member.name || member.email}</div>
                    <div className="text-sm text-gray-500 flex items-center gap-1">
                      {member.role === 'admin' ? (
                        <>
                          <Shield className="h-3 w-3" />
                          Admin
                        </>
                      ) : (
                        <>
                          <User className="h-3 w-3" />
                          Member
                        </>
                      )}
                      {isCurrentUser && <span className="ml-1">(You)</span>}
                    </div>
                  </div>
                </div>
                
                {isCurrentUserAdmin && !isCurrentUser && (
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button variant="ghost" size="icon" disabled={isProcessing}>
                        {isProcessing ? (
                          <div className="h-4 w-4 animate-spin rounded-full border-2 border-gray-300 border-t-gray-600" />
                        ) : (
                          <MoreHorizontal className="h-4 w-4" />
                        )}
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                      {member.role === 'admin' ? (
                        <DropdownMenuItem
                          onClick={() => {
                            setProcessingMemberId(member.id);
                            updateMemberRole({
                              teamId: currentTeam.id,
                              memberId: member.id,
                              role: 'member'
                            });
                          }}
                        >
                          <User className="h-4 w-4 mr-2" />
                          Make Member
                        </DropdownMenuItem>
                      ) : (
                        <DropdownMenuItem
                          onClick={() => {
                            setProcessingMemberId(member.id);
                            updateMemberRole({
                              teamId: currentTeam.id,
                              memberId: member.id,
                              role: 'admin'
                            });
                          }}
                        >
                          <Shield className="h-4 w-4 mr-2" />
                          Make Admin
                        </DropdownMenuItem>
                      )}
                      <DropdownMenuSeparator />
                      <DropdownMenuItem
                        className="text-red-600"
                        onClick={() => {
                          if (window.confirm(`Are you sure you want to remove ${member.name || member.email} from the team?`)) {
                            setProcessingMemberId(member.id);
                            removeMember({
                              teamId: currentTeam.id,
                              memberId: member.id
                            });
                          }
                        }}
                      >
                        <UserMinus className="h-4 w-4 mr-2" />
                        Remove from Team
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                )}
              </li>
            );
          })}
        </ul>
      </CardContent>
    </Card>
  );
} 