import { Download, FileText, X } from 'lucide-react';
import { useState } from 'react';
import { Button } from '../../components/ui/button';
import { Card, CardContent } from '../../components/ui/card';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle
} from '../../components/ui/dialog';
import {
    Pagination,
    PaginationContent,
    PaginationEllipsis,
    PaginationItem,
    PaginationLink,
    PaginationNext,
    PaginationPrevious
} from '../../components/ui/pagination';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow
} from '../../components/ui/table';
import { Invoice } from './types';
import { formatDate, formatPrice } from './utils';

interface BillingHistorySectionProps {
  invoices: Invoice[] | undefined;
  isLoadingInvoices: boolean;
  invoicesError: any;
  refetchInvoices: () => void;
}

export function BillingHistorySection({
  invoices,
  isLoadingInvoices,
  invoicesError,
  refetchInvoices
}: BillingHistorySectionProps) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [selectedInvoice, setSelectedInvoice] = useState<Invoice | null>(null);
  const [isInvoiceDetailsOpen, setIsInvoiceDetailsOpen] = useState(false);

  // Calculate pagination for invoices
  const totalInvoices = invoices?.length || 0;
  const totalPages = Math.ceil(totalInvoices / itemsPerPage);
  const indexOfLastInvoice = currentPage * itemsPerPage;
  const indexOfFirstInvoice = indexOfLastInvoice - itemsPerPage;
  const currentInvoices = invoices?.slice(indexOfFirstInvoice, indexOfLastInvoice) || [];

  // Handle page change
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  // Handle viewing invoice details
  const handleViewInvoiceDetails = (invoice: Invoice) => {
    setSelectedInvoice(invoice);
    setIsInvoiceDetailsOpen(true);
  };

  // Close invoice details modal
  const handleCloseInvoiceDetails = () => {
    setIsInvoiceDetailsOpen(false);
    setSelectedInvoice(null);
  };

  return (
    <div className="mb-10">
      <div className="mb-6">
        <h2 className="text-xl font-semibold">Billing History</h2>
        <p className="text-sm text-muted-foreground mt-1">
          View and download your past invoices and payment receipts.
        </p>
      </div>
      
      <Card>
        <CardContent className="p-6">
          {isLoadingInvoices ? (
            <div className="h-64 flex items-center justify-center">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
            </div>
          ) : invoicesError ? (
            <div className="text-center py-10">
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                className="h-10 w-10 text-red-500 mx-auto mb-3" 
                fill="none" 
                viewBox="0 0 24 24" 
                stroke="currentColor"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth={2} 
                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" 
                />
              </svg>
              <h3 className="text-lg font-medium mb-1">Failed to load invoices</h3>
              <p className="text-sm text-muted-foreground mb-4">
                There was an error loading your billing history.
              </p>
              <Button 
                variant="outline" 
                onClick={() => refetchInvoices()}
                className="px-4 py-2 h-auto text-sm"
              >
                Try Again
              </Button>
            </div>
          ) : totalInvoices === 0 ? (
            <div className="text-center py-10">
              <FileText className="h-10 w-10 text-muted-foreground mx-auto mb-3" />
              <h3 className="text-lg font-medium mb-1">No invoices yet</h3>
              <p className="text-sm text-muted-foreground">
                Your billing history will appear here once you've made a payment.
              </p>
            </div>
          ) : (
            <>
              <div className="rounded-md border">
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Plan</TableHead>
                      <TableHead>Amount</TableHead>
                      <TableHead>Date</TableHead>
                      <TableHead>Status</TableHead>
                      <TableHead className="text-right">Actions</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {currentInvoices.map((invoice: Invoice) => {
                      // Get the plan name from the invoice line items
                      const planName = invoice.lines?.data[0]?.description || 'Subscription';
                      
                      return (
                        <TableRow key={invoice.id}>
                          <TableCell className="font-medium">
                            {planName}
                          </TableCell>
                          <TableCell>
                            {formatPrice(invoice.amount_paid, invoice.currency)}
                          </TableCell>
                          <TableCell>{formatDate(invoice.created)}</TableCell>
                          <TableCell>
                            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                              invoice.status === 'paid' 
                                ? 'bg-green-100 text-green-800' 
                                : invoice.status === 'open' 
                                  ? 'bg-blue-100 text-blue-800' 
                                  : 'bg-gray-100 text-gray-800'
                            }`}>
                              {invoice.status.charAt(0).toUpperCase() + invoice.status.slice(1)}
                            </span>
                          </TableCell>
                          <TableCell className="text-right">
                            <div className="flex items-center justify-end space-x-2">
                              {invoice.invoice_pdf && (
                                <a 
                                  href={invoice.invoice_pdf} 
                                  target="_blank" 
                                  rel="noopener noreferrer"
                                  className="text-gray-500 hover:text-primary"
                                  title="Download PDF"
                                >
                                  <Download className="h-4 w-4" />
                                </a>
                              )}
                              <Button 
                                variant="ghost" 
                                size="sm" 
                                className="h-8 px-2"
                                title="View Details"
                                onClick={() => handleViewInvoiceDetails(invoice)}
                              >
                                <FileText className="h-4 w-4" />
                              </Button>
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
              
              {/* Pagination */}
              {totalPages > 1 && (
                <div className="mt-4">
                  <Pagination>
                    <PaginationContent>
                      <PaginationItem>
                        <PaginationPrevious 
                          onClick={() => handlePageChange(Math.max(1, currentPage - 1))}
                          className={currentPage === 1 ? 'pointer-events-none opacity-50' : ''}
                        />
                      </PaginationItem>
                      
                      {Array.from({ length: Math.min(5, totalPages) }).map((_, i) => {
                        // Logic to show pages around current page
                        let pageNum;
                        if (totalPages <= 5) {
                          // If 5 or fewer pages, show all
                          pageNum = i + 1;
                        } else if (currentPage <= 3) {
                          // If near start, show first 5 pages
                          pageNum = i + 1;
                        } else if (currentPage >= totalPages - 2) {
                          // If near end, show last 5 pages
                          pageNum = totalPages - 4 + i;
                        } else {
                          // Otherwise show 2 before and 2 after current
                          pageNum = currentPage - 2 + i;
                        }
                        
                        return (
                          <PaginationItem key={pageNum}>
                            <PaginationLink 
                              isActive={pageNum === currentPage}
                              onClick={() => handlePageChange(pageNum)}
                            >
                              {pageNum}
                            </PaginationLink>
                          </PaginationItem>
                        );
                      })}
                      
                      {totalPages > 5 && currentPage < totalPages - 2 && (
                        <>
                          <PaginationItem>
                            <PaginationEllipsis />
                          </PaginationItem>
                          <PaginationItem>
                            <PaginationLink onClick={() => handlePageChange(totalPages)}>
                              {totalPages}
                            </PaginationLink>
                          </PaginationItem>
                        </>
                      )}
                      
                      <PaginationItem>
                        <PaginationNext 
                          onClick={() => handlePageChange(Math.min(totalPages, currentPage + 1))}
                          className={currentPage === totalPages ? 'pointer-events-none opacity-50' : ''}
                        />
                      </PaginationItem>
                    </PaginationContent>
                  </Pagination>
                </div>
              )}
            </>
          )}
        </CardContent>
      </Card>

      {/* Invoice Details Modal */}
      <Dialog open={isInvoiceDetailsOpen} onOpenChange={setIsInvoiceDetailsOpen}>
        <DialogContent className="sm:max-w-[600px]">
          <DialogHeader>
            <DialogTitle className="flex items-center justify-between">
              <span>Invoice Details</span>
              <Button 
                variant="ghost" 
                size="sm" 
                className="h-8 w-8 p-0" 
                onClick={handleCloseInvoiceDetails}
              >
                <X className="h-4 w-4" />
              </Button>
            </DialogTitle>
            <DialogDescription>
              {selectedInvoice && (
                <span>Invoice #{selectedInvoice.number || selectedInvoice.id.slice(-8)}</span>
              )}
            </DialogDescription>
          </DialogHeader>
          
          {selectedInvoice && (
            <div className="space-y-6">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <h4 className="text-sm font-medium text-muted-foreground mb-1">Date</h4>
                  <p>{formatDate(selectedInvoice.created)}</p>
                </div>
                <div>
                  <h4 className="text-sm font-medium text-muted-foreground mb-1">Status</h4>
                  <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                    selectedInvoice.status === 'paid' 
                      ? 'bg-green-100 text-green-800' 
                      : selectedInvoice.status === 'open' 
                        ? 'bg-blue-100 text-blue-800' 
                        : 'bg-gray-100 text-gray-800'
                  }`}>
                    {selectedInvoice.status.charAt(0).toUpperCase() + selectedInvoice.status.slice(1)}
                  </span>
                </div>
              </div>
              
              <div>
                <h4 className="text-sm font-medium text-muted-foreground mb-2">Items</h4>
                <div className="border rounded-md">
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead>Description</TableHead>
                        <TableHead className="text-right">Amount</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {selectedInvoice.lines.data.map((line, index) => (
                        <TableRow key={index}>
                          <TableCell>{line.description}</TableCell>
                          <TableCell className="text-right">
                            {formatPrice(line.amount, selectedInvoice.currency)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </div>
              
              <div className="flex justify-between items-center pt-4 border-t">
                <span className="font-medium">Total</span>
                <span className="font-bold text-lg">
                  {formatPrice(selectedInvoice.amount_paid, selectedInvoice.currency)}
                </span>
              </div>
              
              <div className="flex justify-end space-x-2 pt-4">
                {selectedInvoice.invoice_pdf && (
                  <Button 
                    variant="outline" 
                    className="flex items-center"
                    asChild
                  >
                    <a 
                      href={selectedInvoice.invoice_pdf} 
                      target="_blank" 
                      rel="noopener noreferrer"
                    >
                      <Download className="h-4 w-4 mr-2" />
                      Download PDF
                    </a>
                  </Button>
                )}
                <Button onClick={handleCloseInvoiceDetails}>Close</Button>
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
} 