import { api } from '@/lib/api';
import { cn } from '@/lib/utils';
import { LeadStatus } from '@/types';
import { useQuery } from '@tanstack/react-query';

const defaultStatusConfig: Record<string, { label: string; className: string }> = {
  new: {
    label: 'New',
    className: 'bg-blue-50 text-blue-700 dark:bg-blue-900/20 dark:text-blue-300'
  },
  contacted: {
    label: 'Contacted',
    className: 'bg-yellow-50 text-yellow-700 dark:bg-yellow-900/20 dark:text-yellow-300'
  },
  qualified: {
    label: 'Qualified',
    className: 'bg-green-50 text-green-700 dark:bg-green-900/20 dark:text-green-300'
  },
  proposal: {
    label: 'Proposal',
    className: 'bg-orange-50 text-orange-700 dark:bg-orange-900/20 dark:text-orange-300'
  },
  won: {
    label: 'Won',
    className: 'bg-green-50 text-green-700 dark:bg-green-900/20 dark:text-green-300'
  },
  lost: {
    label: 'Lost',
    className: 'bg-red-50 text-red-700 dark:bg-red-900/20 dark:text-red-300'
  }
};

interface LeadStatusBadgeProps {
  status: LeadStatus;
  className?: string;
}

export function LeadStatusBadge({ status, className }: LeadStatusBadgeProps) {
  // Fetch all statuses to get custom status colors
  const { data: statusesData } = useQuery({
    queryKey: ['statuses'],
    queryFn: api.statuses.list,
    staleTime: 30000,
  });

  // Find custom status configuration if it exists
  const customStatus = statusesData?.success 
    ? statusesData.statuses.find(s => s.name === status && !s.isDefault)
    : null;

  // Get config or create one based on custom status
  const config = customStatus 
    ? {
        label: customStatus.name.charAt(0).toUpperCase() + customStatus.name.slice(1),
        className: ''
      }
    : defaultStatusConfig[status] || {
        label: status == 'aged lead' ? 'Aged' : status.charAt(0).toUpperCase() + status.slice(1),
        className: 'bg-gray-50 text-gray-700 dark:bg-gray-900/20 dark:text-gray-300'
      };
  
  return (
    <span
      className={cn(
        'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium',
        !customStatus && config.className,
        className
      )}
      style={customStatus ? {
        backgroundColor: `${customStatus.color}20`, // 20 is hex for 12.5% opacity
        color: customStatus.color
      } : undefined}
    >
      {config.label}
    </span>
  );
} 