import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { ScrollArea } from '@/components/ui/scroll-area';
import { api } from '@/lib/api';
import { cn } from '@/lib/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { format, isToday, isYesterday } from 'date-fns';
import { Brain, Loader2, Pause, Phone, PhoneIncoming, PhoneOutgoing, PlayCircle, Sparkles } from 'lucide-react';
import { useState } from 'react';
import { toast } from 'sonner';

export interface Call {
  id: number;
  datetime: string;
  direction: 'inbound' | 'outbound' | null;
  lengthInSec: number;
  statusCode: string;
  recordings: Array<{
    id: number;
    recordingUrl: string;
    durationSec: number;
  }>;
  analytics: Array<{
    transcription: string;
    summary: string;
    keyPoints: string[];
    sentimentScore: number;
    leadScore: number;
  }>;
}

interface CallHistoryProps {
  calls: Call[];
  onPlayRecording: (recordingId: number, url: string) => void;
  currentlyPlaying: number | null;
  activeCallId?: number | null;
  isAnalyzing?: boolean;
  callsBeingAnalyzed?: Set<number>;
  className?: string;
}

interface CallHistoryItemProps {
  call: Call;
  onPlayRecording: (recordingId: number, url: string) => void;
  currentlyPlaying: number | null;
  isActive?: boolean;
  isAnalyzing?: boolean;
}

function CallHistoryItem({ call, onPlayRecording, currentlyPlaying, isActive, isAnalyzing }: CallHistoryItemProps) {
  const queryClient = useQueryClient();
  const [isManuallyAnalyzing, setIsManuallyAnalyzing] = useState(false);

  const analyzeCallMutation = useMutation({
    mutationFn: async () => {
      if (!call.recordings?.[0]) throw new Error('No recording available');
      return api.calls.generateAnalysis(call.id);
    },
    onMutate: () => {
      setIsManuallyAnalyzing(true);
    },
    onSuccess: () => {
      toast.success('Call analysis generated successfully');
      queryClient.invalidateQueries({ queryKey: ['callHistory'] });
    },
    onError: (error) => {
      toast.error('Failed to generate analysis: ' + error.message);
    },
    onSettled: () => {
      setIsManuallyAnalyzing(false);
    },
  });

  const isPlaying = call.recordings?.[0] && currentlyPlaying === call.recordings[0].id;
  
  // Fix: Only check for existing analytics when determining if we should show the analyzing state
  const showAnalyzing = isActive || isAnalyzing || isManuallyAnalyzing || 
    // Show analyzing animation if no analytics exist yet
    (call.recordings?.[0] && !call.analytics?.length);

  // Format duration in mm:ss
  const formatDuration = (seconds: number) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs.toString().padStart(2, '0')}`;
  };

  const getSentimentColor = (score: number) => {
    if (score > 0.3) return 'bg-green-500';
    if (score > -0.3) return 'bg-yellow-500';
    return 'bg-red-500';
  };

  const getLeadScoreColor = (score: number) => {
    if (score > 0.7) return 'bg-indigo-500';
    if (score > 0.4) return 'bg-blue-500';
    return 'bg-gray-500';
  };

  return (
    <Card className={cn(
      "p-4 hover:bg-muted/50 transition-colors group w-full",
      isActive && "border-primary border-2 bg-primary/10 shadow-md"
    )}>
      <div className="flex items-start justify-between">
        <div className="flex items-start gap-3">
          <div className={cn(
            "rounded-full p-2",
            isActive && "animate-pulse bg-primary/20 text-primary",
            call.direction === 'inbound' 
              ? 'bg-green-100 text-green-600 dark:bg-green-900/30 dark:text-green-400' 
              : call.direction === 'outbound' 
                ? 'bg-blue-100 text-blue-600 dark:bg-blue-900/30 dark:text-blue-400' 
                : 'bg-gray-100 text-gray-600 dark:bg-gray-800 dark:text-gray-400'
          )}>
            {call.direction === 'inbound' ? (
              <PhoneIncoming className="h-4 w-4" />
            ) : call.direction === 'outbound' ? (
              <PhoneOutgoing className="h-4 w-4" />
            ) : (
              <Phone className="h-4 w-4" />
            )}
          </div>
          <div>
            <div className="flex items-center gap-2">
              <span className={cn(
                "font-medium",
                isActive && "text-primary"
              )}>
                {isActive ? 'Active Call' : call.direction ? 
                  `${call.direction === 'inbound' ? 'Incoming' : 'Outgoing'} Call` : 
                  'Manual Call Log'}
              </span>
              <span className="text-sm text-muted-foreground">
                {format(new Date(call.datetime), 'h:mm a')}
              </span>
              {isActive && (
                <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-primary/20 text-primary animate-pulse">
                  In Progress
                </span>
              )}
            </div>
            <p className="text-sm text-muted-foreground flex items-center gap-2">
              <span>{isActive ? 'Ongoing' : formatDuration(call.lengthInSec)}</span>
              {showAnalyzing && (
                <span className="flex items-center gap-1 text-primary animate-pulse">
                  <Loader2 className="h-3 w-3 animate-spin" />
                  AI Analyzing
                </span>
              )}
              {call.analytics?.[0] && !showAnalyzing && (
                <span className="flex items-center gap-1">
                  <Brain className="h-3 w-3" />
                  AI Analyzed
                </span>
              )}
            </p>
          </div>
        </div>

        {call.recordings?.[0] && !isActive && (
          <Button
            variant="ghost"
            size="sm"
            onClick={() => onPlayRecording(call.recordings[0].id, call.recordings[0].recordingUrl)}
            className={cn(
              "transition-all",
              isPlaying ? "opacity-100" : "opacity-0 group-hover:opacity-100"
            )}
          >
            {isPlaying ? (
              <>
                <Pause className="h-4 w-4 mr-1" />
                Stop
              </>
            ) : (
              <>
                <PlayCircle className="h-4 w-4 mr-1" />
                Play
              </>
            )}
          </Button>
        )}
      </div>

      {(call.analytics?.[0] || showAnalyzing) && (
        <div className="mt-4 pt-4 border-t space-y-4">
          {showAnalyzing && !call.analytics?.[0] ? (
            <div className="flex flex-col items-center justify-center py-4 space-y-3 bg-primary/5 rounded-lg border border-primary/20 relative overflow-hidden">
              {/* Animated background pulse effect */}
              <div className="absolute inset-0 bg-gradient-to-r from-transparent via-primary/10 to-transparent animate-pulse-slow"></div>
              
              {/* Animated brain waves */}
              <div className="absolute inset-0 flex items-center justify-center opacity-10">
                <div className="w-32 h-32 rounded-full border-4 border-primary/20 animate-ping"></div>
                <div className="absolute w-24 h-24 rounded-full border-4 border-primary/30 animate-ping animation-delay-300"></div>
                <div className="absolute w-16 h-16 rounded-full border-4 border-primary/40 animate-ping animation-delay-600"></div>
              </div>
              
              <div className="flex items-center space-x-2 z-10">
                <div className="relative">
                  <Loader2 className="h-5 w-5 animate-spin text-primary" />
                  <Sparkles className="absolute -top-1 -right-1 h-2 w-2 text-yellow-500 animate-pulse" />
                </div>
                <span className="text-sm font-medium text-primary">AI analyzing call data...</span>
              </div>
              
              {/* Animated progress bar */}
              <div className="w-3/4 h-1.5 bg-muted rounded-full overflow-hidden z-10">
                <div className="h-full bg-primary/60 animate-progress-indeterminate"></div>
              </div>
              
              <p className="text-xs text-muted-foreground text-center max-w-md px-4 z-10">
                Our AI is processing the call to extract insights, sentiment analysis, and lead scoring.
                This may take a moment to complete.
              </p>
            </div>
          ) : call.analytics?.[0] && (
            <>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <div className="flex items-center justify-between mb-1">
                    <p className="text-sm font-medium">Sentiment</p>
                    <Sparkles className="h-3 w-3 text-yellow-500" />
                  </div>
                  <div className="h-2 bg-muted rounded-full overflow-hidden">
                    <div 
                      className={`h-full ${getSentimentColor(call.analytics[0].sentimentScore)} transition-all`}
                      style={{ width: `${(call.analytics[0].sentimentScore + 1) * 50}%` }}
                    />
                  </div>
                </div>
                <div>
                  <div className="flex items-center justify-between mb-1">
                    <p className="text-sm font-medium">Lead Score</p>
                    <Brain className="h-3 w-3 text-indigo-500" />
                  </div>
                  <div className="h-2 bg-muted rounded-full overflow-hidden">
                    <div 
                      className={`h-full ${getLeadScoreColor(call.analytics[0].leadScore)} transition-all`}
                      style={{ width: `${call.analytics[0].leadScore * 100}%` }}
                    />
                  </div>
                </div>
              </div>
              <div className="bg-muted/50 rounded-lg p-3 text-sm text-muted-foreground">
                {call.analytics[0].summary}
              </div>
            </>
          )}
        </div>
      )}
    </Card>
  );
}

export function CallHistory({ calls, onPlayRecording, currentlyPlaying, activeCallId, isAnalyzing, callsBeingAnalyzed, className }: CallHistoryProps) {
  // Group calls by date
  const groupedCalls = calls.reduce((acc, call) => {
    const date = new Date(call.datetime);
    let key = 'Earlier';
    
    if (isToday(date)) {
      key = 'Today';
    } else if (isYesterday(date)) {
      key = 'Yesterday';
    } else if (date.getTime() > Date.now() - 7 * 24 * 60 * 60 * 1000) {
      key = format(date, 'EEEE'); // Day name
    }
    
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(call);
    return acc;
  }, {} as Record<string, Call[]>);

  return (
    <ScrollArea className={className}>
      <div className="space-y-6 pb-4 w-full">
        {Object.entries(groupedCalls).map(([date, dateCalls]) => (
          <div key={date} className="relative w-full">
            <h3 className="text-sm font-medium text-muted-foreground mb-3 flex items-center gap-2 sticky top-0 bg-background/80 backdrop-blur-sm z-20 py-2">
              {date}
              <span className="text-xs">({dateCalls.length} calls)</span>
            </h3>
            <div className="space-y-3 w-full">
              {dateCalls.map(call => (
                <CallHistoryItem 
                  key={call.id} 
                  call={call}
                  onPlayRecording={onPlayRecording}
                  currentlyPlaying={currentlyPlaying}
                  isActive={activeCallId === call.id}
                  isAnalyzing={
                    // Active call being analyzed
                    (isAnalyzing && activeCallId === call.id) || 
                    // Or this call ID is in the set of calls being analyzed
                    (callsBeingAnalyzed && callsBeingAnalyzed.has(call.id))
                  }
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </ScrollArea>
  );
} 