import { Button } from '@/components/ui/button';
import { Lead } from '@/types';

interface MobileCallButtonProps {
  lead: Lead | null;
  setIsCommunicationModalOpen: (isOpen: boolean) => void;
}

export function MobileCallButton({ lead, setIsCommunicationModalOpen }: MobileCallButtonProps) {
  return (
    <div className="fixed bottom-0 left-0 right-0 p-4 bg-white dark:bg-gray-900 border-t border-gray-200 dark:border-gray-700 lg:hidden z-40">
      <Button 
        className="w-full" 
        size="lg"
        onClick={() => setIsCommunicationModalOpen(true)}
      >
        Contact {lead?.name || 'Lead'}
      </Button>
    </div>
  );
} 