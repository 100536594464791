import { Input } from '@/components/ui/input';
import { cn } from '@/lib/utils';
import { Lead } from '@/types';
import { MapPin } from 'lucide-react';
import { formFieldClasses } from './utils';

interface AddressSectionProps {
  formData: Partial<Lead>;
  handleInputChange: (field: keyof Lead, value: string | number | boolean) => void;
  handleBlur: (field: keyof Lead) => void;
}

export function AddressSection({
  formData,
  handleInputChange,
  handleBlur
}: AddressSectionProps) {
  return (
    <div className={formFieldClasses.section}>
      <div className="space-y-3">
        <div className={formFieldClasses.field}>
          <label className={formFieldClasses.label}>Street Address</label>
          <div className="relative">
            <MapPin className={formFieldClasses.icon} />
            <Input
              value={formData.address || ''}
              onChange={(e) => handleInputChange('address', e.target.value)}
              onBlur={() => handleBlur('address')}
              className={cn(formFieldClasses.input, formFieldClasses.inputWithIcon)}
              placeholder="Street address"
            />
          </div>
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-4 gap-3">
          <div className={formFieldClasses.field}>
            <label className={formFieldClasses.label}>City</label>
            <Input
              value={formData.city || ''}
              onChange={(e) => handleInputChange('city', e.target.value)}
              onBlur={() => handleBlur('city')}
              className={formFieldClasses.input}
              placeholder="City"
            />
          </div>
          <div className={formFieldClasses.field}>
            <label className={formFieldClasses.label}>State</label>
            <Input
              value={formData.state || ''}
              onChange={(e) => handleInputChange('state', e.target.value)}
              onBlur={() => handleBlur('state')}
              className={formFieldClasses.input}
              placeholder="State"
            />
          </div>
          <div className={formFieldClasses.field}>
            <label className={formFieldClasses.label}>County</label>
            <Input
              value={formData.county || ''}
              onChange={(e) => handleInputChange('county', e.target.value)}
              onBlur={() => handleBlur('county')}
              className={formFieldClasses.input}
              placeholder="County"
            />
          </div>
          <div className={formFieldClasses.field}>
            <label className={formFieldClasses.label}>ZIP Code</label>
            <Input
              value={formData.zip || ''}
              onChange={(e) => handleInputChange('zip', e.target.value)}
              onBlur={() => handleBlur('zip')}
              className={formFieldClasses.input}
              placeholder="ZIP code"
            />
          </div>
        </div>
      </div>
    </div>
  );
} 