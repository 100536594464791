import { axiosInstance } from '@/lib/api';
import { useAuthStore } from '@/store/authStore';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { useEffect, useState } from 'react';
import { toast } from 'sonner';

export const Route = createFileRoute('/invitation/$token')({
  component: InvitationAcceptPage,
});

function InvitationAcceptPage() {
  const { token } = Route.useParams();
  const navigate = useNavigate();
  const isAuthenticated = useAuthStore(state => !!state.token);
  const [isProcessing, setIsProcessing] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    async function handleInvitation() {
      try {
        if (!isAuthenticated) {
          // If not authenticated, redirect to auth page with invitation token
          navigate({ 
            to: '/auth', 
            search: { 
              invitation: token,
              tab: undefined,
              redirect: undefined
            },
            replace: true
          });
          return;
        }

        // Get invitation details
        const invitationResponse = await axiosInstance.get(`/teams/invitation/${token}`);
        const invitationData = invitationResponse.data.invitation;

        // Get user's invitations
        const userInvitationsResponse = await axiosInstance.get('/teams/invitations');
        const userInvitations = userInvitationsResponse.data.invitations || [];

        // Find the matching invitation
        const matchingInvitation = userInvitations.find(
          (inv: any) => inv.teamId === invitationData.teamId
        );

        if (matchingInvitation) {
          // Accept the invitation
          await axiosInstance.post(`/teams/invitations/${matchingInvitation.id}/accept`);
          
          toast.success(`You've joined ${invitationData.teamName}!`, {
            description: 'You are now a member of this team.'
          });
          
          // Navigate to the teams page
          navigate({ 
            to: '/settings',
            search: { 
              tab: 'teams',
              section: 'members'
            },
            replace: true
          });
        } else {
          // If no matching invitation found, show error
          setError('Invitation not found or already accepted');
          setIsProcessing(false);
        }
      } catch (error: any) {
        console.error('Error handling invitation:', error);
        setError(error.response?.data?.message || 'Failed to process invitation');
        setIsProcessing(false);
      }
    }

    handleInvitation();
  }, [token, isAuthenticated, navigate]);

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="max-w-md w-full p-6 bg-white rounded-lg shadow-md">
          <h1 className="text-2xl font-semibold text-red-600 mb-4">Invitation Error</h1>
          <p className="text-gray-700 mb-4">{error}</p>
          <button
            onClick={() => navigate({ to: '/dashboard' })}
            className="w-full py-2 px-4 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            Go to Dashboard
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50">
      <div className="max-w-md w-full p-6 bg-white rounded-lg shadow-md text-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600 mx-auto mb-4"></div>
        <h1 className="text-xl font-semibold text-gray-900 mb-2">Processing Invitation</h1>
        <p className="text-gray-600">Please wait while we process your team invitation...</p>
      </div>
    </div>
  );
} 