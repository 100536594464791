import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Textarea } from '@/components/ui/textarea';
import { useWebSocket } from '@/contexts/WebSocketContext';
import { api, type ConversationState, type MessageTemplate, type SMSAnalysis } from '@/lib/api';
import { cn } from '@/lib/utils';
import { SocketEvents } from '@/services/websocket.service';
import { useLeadStore } from '@/store/leadStore';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { AlertCircle, Brain, FileText, Loader2, MessageSquare, Send, Sparkles } from 'lucide-react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'sonner';
import { MessageTemplates } from './MessageTemplates';

// Extend the Message type to include analytics
interface MessageWithAnalytics {
  id: number;
  conversationId: number;
  body: string;
  sentByUser: number;
  twilioMessageSid: string;
  createdAt: string | null;
  updatedAt: string | null;
  notified: number;
  analytics?: {
    id: number;
    conversationId: number;
    messageId: number;
    summary: string;
    keyPoints: string[];
    sentimentScore: string;
    leadScore: string;
    createdAt: string | null;
    updatedAt: string | null;
  } | null;
}

interface SMSChatProps {
  phoneNumber: string;
  fromNumber?: string | null;
  className?: string;
  skipLookup?: boolean;
}

interface ConversationSummaryDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  conversationState: ConversationState | null;
}

function ConversationSummaryDialog({ open, onOpenChange, conversationState }: ConversationSummaryDialogProps) {
  if (!conversationState) {
    return (
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className="max-w-2xl">
          <DialogHeader>
            <DialogTitle>Conversation Summary</DialogTitle>
            <DialogDescription>
              AI-generated overview of this conversation
            </DialogDescription>
          </DialogHeader>
          <div className="py-8 text-center">
            <Loader2 className="h-8 w-8 animate-spin mx-auto mb-4 text-muted-foreground" />
            <p className="text-muted-foreground">Loading conversation summary...</p>
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-2xl">
        <DialogHeader>
          <DialogTitle>SMS Conversation Summary</DialogTitle>
          <DialogDescription>
            AI-generated overview of this conversation
          </DialogDescription>
        </DialogHeader>
        
        <div className="space-y-4 py-2">
          {/* Overall Summary - Now in a scrollable container */}
          <div>
            <h4 className="text-sm font-medium mb-1">Summary</h4>
            <div className="bg-muted/30 rounded-md overflow-hidden">
              <div className="max-h-[200px] overflow-y-auto p-3">
                <p className="text-sm text-muted-foreground whitespace-pre-line">
                  {conversationState.summary}
                </p>
              </div>
            </div>
          </div>
          
          {/* Sentiment and Lead Score Trajectories */}
          <div className="grid grid-cols-2 gap-4">
            <div>
              <h4 className="text-sm font-medium mb-1">Sentiment Trend</h4>
              <div className="bg-muted/30 p-3 rounded-md">
                <div className="flex items-center justify-between text-xs">
                  <span>Start</span>
                  <span>Current</span>
                </div>
                <div className="flex items-center justify-between mt-1">
                  {conversationState.sentimentTrajectory.map((score: number, i: number) => {
                    const color = score > 0.3 ? 'bg-green-500' : score > -0.3 ? 'bg-yellow-500' : 'bg-red-500';
                    const isEndpoint = i === 0 || i === conversationState.sentimentTrajectory.length - 1;
                    return (
                      <div key={i} className="flex flex-col items-center">
                        <div className={`w-2 h-2 rounded-full ${color}`}></div>
                        {isEndpoint && <span className="text-xs mt-1">{score.toFixed(1)}</span>}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            
            <div>
              <h4 className="text-sm font-medium mb-1">Lead Score Trend</h4>
              <div className="bg-muted/30 p-3 rounded-md">
                <div className="flex items-center justify-between text-xs">
                  <span>Start</span>
                  <span>Current</span>
                </div>
                <div className="flex items-center justify-between mt-1">
                  {conversationState.leadScoreTrajectory.map((score: number, i: number) => {
                    const color = score > 0.7 ? 'bg-indigo-500' : score > 0.4 ? 'bg-blue-500' : 'bg-gray-500';
                    const isEndpoint = i === 0 || i === conversationState.leadScoreTrajectory.length - 1;
                    return (
                      <div key={i} className="flex flex-col items-center">
                        <div className={`w-2 h-2 rounded-full ${color}`}></div>
                        {isEndpoint && <span className="text-xs mt-1">{score.toFixed(1)}</span>}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          
          {/* Message Stats */}
          <div className="grid grid-cols-2 gap-4 text-sm">
            <div className="bg-muted/30 p-3 rounded-md">
              <h4 className="font-medium mb-1">Message Count</h4>
              <p className="text-2xl font-semibold">{conversationState.messageCount}</p>
            </div>
            <div className="bg-muted/30 p-3 rounded-md">
              <h4 className="font-medium mb-1">SMS Lead Score</h4>
              <p className="text-2xl font-semibold">
                {conversationState.leadScoreTrajectory.length > 0 
                  ? conversationState.leadScoreTrajectory[conversationState.leadScoreTrajectory.length - 1].toFixed(2) 
                  : 'N/A'}
              </p>
            </div>
          </div>
          
          {/* Last Updated */}
          <div className="text-xs text-muted-foreground text-right">
            Last updated: {conversationState.lastUpdated 
              ? new Date(conversationState.lastUpdated).toLocaleString() 
              : 'Unknown'}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export function SMSChat({ phoneNumber, fromNumber, className, skipLookup }: SMSChatProps) {
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<MessageTemplate | null>(null);
  const [sendError, setSendError] = useState<string | null>(null);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [showAnalytics, setShowAnalytics] = useState(false);
  const [conversationId, setConversationId] = useState<number | null>(null);
  const [showSummaryDialog, setShowSummaryDialog] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const queryClient = useQueryClient();
  
  // Get lead name utilities from the store
  const getFirstName = useLeadStore((state) => state.getFirstName);
  const getLastName = useLeadStore((state) => state.getLastName);
  const selectedLead = useLeadStore((state) => state.selectedLead);
  
  const { on } = useWebSocket();
  
  // Fetch conversation history
  const { data: conversation, isLoading: isLoadingHistory, error: conversationError, refetch: refetchConversation } = useQuery({
    queryKey: ['conversation', phoneNumber],
    queryFn: () => api.twilio.getConversation(phoneNumber),
    enabled: !!phoneNumber,
    // Remove polling and rely on WebSocket events
    staleTime: 5 * 60 * 1000 // 5 minutes
  });

  // Type assertion for messages with analytics
  const messagesWithAnalytics = useMemo(() => {
    return conversation?.messages as MessageWithAnalytics[] || [];
  }, [conversation?.messages]);

  // Store conversation ID when it's available
  useEffect(() => {
    if (conversation?.conversation?.id) {
      setConversationId(conversation.conversation.id);
      
      // Mark all messages in this conversation as read when it's loaded
      if (conversation.conversation.id && messagesWithAnalytics.length > 0) {
        markConversationAsRead(conversation.conversation.id);
      }
    }
  }, [conversation]);

  // Function to mark all messages in a conversation as read
  const markConversationAsRead = async (convId: number) => {
    try {
      console.log('[SMSChat] Marking all messages as read for conversation:', convId);
      await api.twilio.markMessagesAsRead({ conversationId: convId });
    } catch (error) {
      console.error('[SMSChat] Error marking messages as read:', error);
    }
  };

  // Automatically show analytics when they're available
  useEffect(() => {
    // Check if any messages have analytics
    const hasAnalytics = messagesWithAnalytics.some(msg => msg.analytics);
    if (hasAnalytics) {
      setShowAnalytics(true);
    }
    
    if (!conversation?.conversation?.state?.summary) {
      setShowSummaryDialog(false);
    }
  }, [conversation, messagesWithAnalytics]);

  // Calculate analytics summary from message analytics
  const analyticsData = useMemo(() => {
    if (!messagesWithAnalytics.length || !showAnalytics) return null;
    
    // Filter messages with analytics
    const filteredMessagesWithAnalytics = messagesWithAnalytics.filter(msg => msg.analytics);
    
    if (filteredMessagesWithAnalytics.length === 0) return null;
    
    // Calculate average sentiment and lead score
    let totalSentiment = 0;
    let totalLeadScore = 0;
    
    filteredMessagesWithAnalytics.forEach(msg => {
      if (msg.analytics) {
        totalSentiment += Number(msg.analytics.sentimentScore);
        totalLeadScore += Number(msg.analytics.leadScore);
      }
    });
    
    const averageSentiment = filteredMessagesWithAnalytics.length > 0 
      ? totalSentiment / filteredMessagesWithAnalytics.length 
      : 0;
      
    const averageLeadScore = filteredMessagesWithAnalytics.length > 0 
      ? totalLeadScore / filteredMessagesWithAnalytics.length 
      : 0;
    
    return {
      conversation: conversation?.conversation,
      conversationState: conversation?.conversation?.state || null,
      analytics: messagesWithAnalytics.map(msg => ({
        messageId: msg.id,
        body: msg.body,
        sentByUser: msg.sentByUser === 1,
        createdAt: msg.createdAt,
        analytics: msg.analytics ? {
          summary: msg.analytics.summary,
          keyPoints: msg.analytics.keyPoints,
          sentimentScore: Number(msg.analytics.sentimentScore),
          leadScore: Number(msg.analytics.leadScore)
        } : null
      })),
      summary: {
        messageCount: messagesWithAnalytics.length,
        analyzedMessageCount: filteredMessagesWithAnalytics.length,
        averageSentiment,
        averageLeadScore
      }
    };
  }, [conversation, messagesWithAnalytics, showAnalytics]);

  // Scroll to bottom when new messages arrive
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [conversation?.messages, showAnalytics]);

  // Clear error when phone number or from number changes
  useEffect(() => {
    setSendError(null);
  }, [phoneNumber, fromNumber]);

  // Reset component state when phone number changes
  useEffect(() => {
    if (phoneNumber) {
      // Reset state when phone number changes
      setShowAnalytics(false);
      setShowSummaryDialog(false);
      setIsAnalyzing(false);
    }
  }, [phoneNumber, queryClient]);

  // Subscribe to new message events
  useEffect(() => {
    if (!phoneNumber) return;
    
    console.log('[SMSChat] Setting up WebSocket subscription for new messages');
    
    // Subscribe to conversation updates via WebSocket
    const unsubscribe = on<{
      conversationId: number;
      leadId?: number;
      action: 'created' | 'updated' | 'new-message';
    }>(SocketEvents.CONVERSATION_UPDATE, (data) => {
      // Check if this update is for our conversation
      if (conversation?.conversation?.id === data.conversationId) {
        console.log('[SMSChat] Received conversation update, refreshing messages');
        refetchConversation();
      }
    });
    
    // Also subscribe to new message events directly
    const unsubscribeMessages = on<{
      conversationId: number;
      from: string;
      to: string;
      body: string;
    }>(SocketEvents.NEW_MESSAGE, (data) => {
      // Check if this message is for our conversation
      if (conversation?.conversation?.id === data.conversationId) {
        console.log('[SMSChat] Received new message, refreshing conversation');
        refetchConversation();
      }
    });
    
    // Subscribe to SMS analysis completion events
    const unsubscribeAnalysis = on<{
      conversationId: number;
      messageId?: number;
      messageCount?: number;
    }>(SocketEvents.SMS_ANALYSIS_COMPLETED, (data) => {
      // Check if this analysis is for our conversation
      if (conversation?.conversation?.id === data.conversationId) {
        console.log('[SMSChat] Received SMS analysis update, refreshing conversation');
        refetchConversation();
        
        // Show analytics panel when analysis is complete
        setShowAnalytics(true);
        
        // If this was a conversation-level analysis, enable summary dialog
        if (data.messageCount) {
          setIsAnalyzing(false);
          toast.success(`Analysis of ${data.messageCount} messages complete`);
        }
      }
    });
    
    return () => {
      unsubscribe();
      unsubscribeMessages();
      unsubscribeAnalysis();
    };
  }, [on, phoneNumber, conversation?.conversation?.id, refetchConversation]);

  // Process template variables in text
  const processTemplateVariables = (text: string): string => {
    return text
      .replace(/\{firstName\}/g, getFirstName() || '')
      .replace(/\{lastName\}/g, getLastName() || '')
      .replace(/\{fullName\}/g, selectedLead.name || '');
  };

  // Handle sending a message
  const handleSendMessage = async (text: string) => {
    if (!text.trim()) return;
    if (!fromNumber) {
      setSendError('Please select a phone number to send from');
      return;
    }

    try {
      setIsLoading(true);
      setSendError(null);
      
      // Process any template variables in the message
      const processedText = processTemplateVariables(text);
      
      console.log(`Sending SMS to ${phoneNumber} from ${fromNumber}: ${processedText}${skipLookup ? ' (skipping lookup)' : ''}`);
      
      await api.twilio.sendSMS(phoneNumber, processedText, fromNumber, skipLookup);
      setMessage('');
      setSelectedTemplate(null);
      
      // Mark all messages in this conversation as read when sending a new message
      if (conversationId) {
        await markConversationAsRead(conversationId);
      }
      
      // Immediately refetch conversation to show the new message
      await queryClient.invalidateQueries({ queryKey: ['conversation', phoneNumber] });
      
      // Scroll to bottom
      setTimeout(() => {
        if (messagesEndRef.current) {
          messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);
      
    } catch (error) {
      console.error('Error sending message:', error);
      const errorMessage = error instanceof Error ? error.message : 'Failed to send message';
      setSendError(errorMessage);
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle template text selection
  const handleTemplateTextSelect = (text: string) => {
    // Process template variables when a template is selected
    const processedText = processTemplateVariables(text);
    setMessage(processedText);
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  };

  // Format timestamp
  const formatTimestamp = (timestamp: string | null) => {
    if (!timestamp) return '';
    return new Date(timestamp).toLocaleString();
  };

  // Handle retry when there's an error
  const handleRetry = () => {
    setSendError(null);
    refetchConversation();
  };

  // Handle analyzing the conversation
  const handleAnalyzeConversation = async () => {
    if (!conversationId) return;
    
    try {
      setIsAnalyzing(true);
      await api.smsAnalytics.analyzeConversation(conversationId);
      setShowAnalytics(true);
      // Refetch the conversation to get the updated analytics
      await queryClient.invalidateQueries({ queryKey: ['conversation', phoneNumber] });
      toast.success('Conversation analyzed successfully');
    } catch (error) {
      console.error('Error analyzing conversation:', error);
      toast.error('Failed to analyze conversation');
    } finally {
      setIsAnalyzing(false);
    }
  };

  // Get sentiment color
  const getSentimentColor = (score: number) => {
    if (score > 0.3) return 'text-green-500';
    if (score > -0.3) return 'text-yellow-500';
    return 'text-red-500';
  };

  // Get lead score color
  const getLeadScoreColor = (score: number) => {
    if (score > 0.7) return 'text-indigo-500';
    if (score > 0.4) return 'text-blue-500';
    return 'text-gray-500';
  };

  // Find analytics for a message
  const findAnalyticsForMessage = (messageId: number): SMSAnalysis | null => {
    // Find the message in the conversation
    const message = messagesWithAnalytics.find(m => m.id === messageId);
    // Return the analytics if available
    return message?.analytics ? {
      summary: message.analytics.summary,
      keyPoints: message.analytics.keyPoints,
      sentimentScore: Number(message.analytics.sentimentScore),
      leadScore: Number(message.analytics.leadScore)
    } : null;
  };

  return (
    <div className={cn('flex flex-col h-full bg-background overflow-hidden', className)}>
      {/* Header with Analytics Toggle */}
      <div className="flex items-center justify-between p-2 border-b">
        <h3 className="text-sm font-medium">SMS Conversation</h3>
        <div className="flex items-center gap-2">
          {conversationId && (
            <>
              {/* Show Summary button when conversation state exists */}
              {conversation?.conversation && 'state' in conversation.conversation && conversation.conversation.state?.summary ? (
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => setShowSummaryDialog(true)}
                  className="flex items-center gap-1 text-xs h-7"
                >
                  <FileText className="h-3 w-3" />
                  Summary
                </Button>
              ) : analyticsData && analyticsData.summary.analyzedMessageCount === analyticsData.summary.messageCount ? (
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => setShowSummaryDialog(true)}
                  className="flex items-center gap-1 text-xs h-7"
                >
                  <FileText className="h-3 w-3" />
                  Summary
                </Button>
              ) : (
                <Button
                  variant="outline"
                  size="sm"
                  onClick={handleAnalyzeConversation}
                  disabled={isAnalyzing}
                  className="flex items-center gap-1 text-xs h-7"
                >
                  {isAnalyzing ? (
                    <>
                      <Loader2 className="h-3 w-3 animate-spin" />
                      Analyzing...
                    </>
                  ) : (
                    <>
                      <Brain className="h-3 w-3" />
                      Analyze
                    </>
                  )}
                </Button>
              )}
            </>
          )}
          {/* Show Analytics button with improved hover states and subtle glow */}
          {(analyticsData || (conversation?.conversation && 'state' in conversation.conversation)) && (
            <Button
              variant="outline"
              size="sm"
              onClick={() => setShowAnalytics(!showAnalytics)}
              className={cn(
                "flex items-center gap-1 text-xs h-7 transition-all duration-200",
                conversation?.conversation && 'state' in conversation.conversation && conversation.conversation.state?.summary 
                  ? "text-indigo-600 border-indigo-600 hover:bg-indigo-50 hover:text-indigo-700 hover:border-indigo-700 dark:hover:bg-indigo-950/30 dark:hover:text-indigo-400 focus:ring-2 focus:ring-indigo-500/20 focus:outline-none shadow-[0_0_0_1px_rgba(79,70,229,0.1)]" 
                  : "hover:border-gray-400 hover:text-gray-700 dark:hover:text-gray-200"
              )}
            >
              <Sparkles className={cn(
                "h-3 w-3",
                conversation?.conversation && 'state' in conversation.conversation && conversation.conversation.state?.summary && "text-indigo-500"
              )} />
              {showAnalytics ? 'Hide Analytics' : 'Show Analytics'}
            </Button>
          )}
        </div>
      </div>

      {/* Analytics Summary */}
      {showAnalytics && analyticsData && (
        <div className="p-3 bg-muted/30 border-b text-xs">
          <div className="flex items-center justify-between mb-2">
            <h4 className="font-medium">Conversation Analytics</h4>
            <span className="text-muted-foreground">{analyticsData.summary.analyzedMessageCount} of {analyticsData.summary.messageCount} messages analyzed</span>
          </div>
          <div className="grid grid-cols-2 gap-3">
            <div>
              <div className="flex items-center justify-between mb-1">
                <span className="text-muted-foreground">Average Sentiment</span>
                <span className={getSentimentColor(analyticsData.summary.averageSentiment)}>
                  {analyticsData.summary.averageSentiment.toFixed(2)}
                </span>
              </div>
              <div className="h-1.5 bg-muted rounded-full overflow-hidden">
                <div 
                  className={cn(
                    "h-full transition-all",
                    getSentimentColor(analyticsData.summary.averageSentiment).replace('text-', 'bg-')
                  )}
                  style={{ width: `${(analyticsData.summary.averageSentiment + 1) * 50}%` }}
                />
              </div>
            </div>
            <div>
              <div className="flex items-center justify-between mb-1">
                <span className="text-muted-foreground">Lead Score</span>
                <span className={getLeadScoreColor(analyticsData.summary.averageLeadScore)}>
                  {analyticsData.summary.averageLeadScore.toFixed(2)}
                </span>
              </div>
              <div className="h-1.5 bg-muted rounded-full overflow-hidden">
                <div 
                  className={cn(
                    "h-full transition-all",
                    getLeadScoreColor(analyticsData.summary.averageLeadScore).replace('text-', 'bg-')
                  )}
                  style={{ width: `${analyticsData.summary.averageLeadScore * 100}%` }}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Message History */}
      <div className="flex-1 overflow-y-auto min-h-0">
        <div className="p-4 space-y-4">
          {isLoadingHistory ? (
            <div className="flex items-center justify-center py-8">
              <Loader2 className="h-8 w-8 animate-spin text-muted-foreground" />
            </div>
          ) : conversationError ? (
            <div className="flex flex-col items-center justify-center py-8 text-center text-muted-foreground">
              <AlertCircle className="h-12 w-12 mb-4 text-destructive" />
              <p>Failed to load conversation</p>
              <Button 
                variant="outline" 
                size="sm" 
                className="mt-2"
                onClick={handleRetry}
              >
                Retry
              </Button>
            </div>
          ) : conversation?.messages.length === 0 ? (
            <div className="flex flex-col items-center justify-center py-8 text-center text-muted-foreground">
              <MessageSquare className="h-12 w-12 mb-4" />
              <p>No messages yet</p>
              {fromNumber ? <p className="text-sm">Start the conversation by sending a message</p> : <p className="text-sm">Select a phone number to send a message</p>}
            </div>
          ) : (
            messagesWithAnalytics.map((msg) => {
              const analytics = showAnalytics ? findAnalyticsForMessage(msg.id) : null;
              
              return (
                <div
                  key={msg.id}
                  className={cn(
                    'flex flex-col max-w-[80%] space-y-1',
                    msg.sentByUser ? 'ml-auto items-end' : 'mr-auto items-start'
                  )}
                >
                  <div
                    className={cn(
                      'rounded-lg px-4 py-2',
                      msg.sentByUser
                        ? 'bg-primary text-primary-foreground'
                        : 'bg-muted'
                    )}
                  >
                    {msg.body}
                  </div>
                  <div className="flex items-center gap-2">
                    <span className="text-xs text-muted-foreground">
                      {formatTimestamp(msg.createdAt)}
                    </span>
                    {analytics && (
                      <div className="flex items-center gap-1 text-xs">
                        <span className={getSentimentColor(analytics.sentimentScore)}>
                          <Sparkles className="h-3 w-3 inline-block" />
                          {analytics.sentimentScore.toFixed(2)}
                        </span>
                        <span className={getLeadScoreColor(analytics.leadScore)}>
                          <Brain className="h-3 w-3 inline-block" />
                          {analytics.leadScore.toFixed(2)}
                        </span>
                      </div>
                    )}
                  </div>
                  {analytics && (
                    <div className="text-xs text-muted-foreground bg-muted/50 p-2 rounded-md max-w-full">
                      {analytics.keyPoints.length > 0 && (
                        <div className="mt-1">
                          <p className="font-medium">Key Points:</p>
                          <ul className="list-disc list-inside">
                            {analytics.keyPoints.slice(0, 2).map((point, i) => (
                              <li key={i}>{point}</li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              );
            })
          )}
          <div ref={messagesEndRef} />
        </div>
      </div>

      {/* Message Input */}
      <div className="flex-shrink-0 p-2 border-t border-border bg-background">
        {sendError && (
          <div className="mb-2 p-2 bg-destructive/10 text-destructive text-sm rounded flex items-center">
            <AlertCircle className="h-4 w-4 mr-2 flex-shrink-0" />
            <span className="flex-1">{sendError}</span>
            <Button 
              variant="ghost" 
              size="sm" 
              className="h-6 w-6 p-0 hover:bg-destructive/20"
              onClick={() => setSendError(null)}
            >
              &times;
            </Button>
          </div>
        )}
        
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSendMessage(message);
          }}
          className="flex flex-col gap-2"
        >
          <div className="flex justify-start">
            <MessageTemplates onSelect={handleTemplateTextSelect} />
          </div>
          <div className="flex items-end gap-2">
            <div className="flex-1">
              <Textarea
                ref={textareaRef}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder={fromNumber ? "Type your message..." : "Select a phone number to send from"}
                className="min-h-[80px] max-h-[300px] resize-y"
                disabled={isLoading || !fromNumber}
              />
            </div>
            <Button
              type="submit"
              size="icon"
              disabled={isLoading || !message.trim() || !fromNumber}
            >
              {isLoading ? (
                <Loader2 className="h-4 w-4 animate-spin" />
              ) : (
                <Send className="h-4 w-4" />
              )}
            </Button>
          </div>
        </form>
      </div>

      {/* Summary Dialog */}
      <ConversationSummaryDialog 
        open={showSummaryDialog} 
        onOpenChange={setShowSummaryDialog} 
        conversationState={analyticsData?.conversationState || (conversation?.conversation?.state as ConversationState | null) || null} 
      />
    </div>
  );
} 