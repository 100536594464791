import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { RouterProvider, createRouter } from '@tanstack/react-router';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { CallServiceProvider } from './contexts/CallServiceContext';
import { WebSocketProvider } from './contexts/WebSocketContext';
import './index.css';
import { routeTree } from './routeTree.gen';

// Configure QueryClient
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5,
      gcTime: 1000 * 60 * 60,
      refetchOnWindowFocus: true,
      retry: 1,
    },
  },
});

// Create router
const router = createRouter({
  routeTree,
  defaultPreload: 'intent',
  scrollRestoration: true,
});

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

// Check if we're in a prerendering environment (react-snap)
const isPrerendering = navigator.userAgent === 'ReactSnap';

// Render immediately for react-snap, otherwise wait for router.load()
const renderApp = () => {
  const rootElement = document.getElementById('root');
  if (!rootElement) throw new Error('Root element not found');

  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <WebSocketProvider>
          <CallServiceProvider>
            <RouterProvider router={router} />
          </CallServiceProvider>
        </WebSocketProvider>
      </QueryClientProvider>
    </React.StrictMode>
  );
};

if (isPrerendering) {
  renderApp(); // Immediate render for react-snap
} else {
  router.load().then(renderApp); // Normal client-side load
}