"use client"

import { format } from "date-fns"
import { CalendarIcon } from "lucide-react"
import { useEffect, useState } from "react"
import { DateRange } from "react-day-picker"

import { Button } from "@/components/ui/button"
import { Calendar } from "@/components/ui/calendar"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { cn } from "@/lib/utils"

interface DateRangePickerProps {
  value: DateRange | undefined;
  onChange: (value: DateRange | undefined) => void;
  align?: "center" | "start" | "end";
  placeholder?: string;
  numberOfMonths?: number;
  calendarClassName?: string;
  className?: string;
}

export function DateRangePicker({
  className,
  value,
  onChange,
  align = "center",
  placeholder = "Select date range",
  numberOfMonths = 2,
  calendarClassName,
}: DateRangePickerProps) {
  const [open, setOpen] = useState(false);
  const [localValue, setLocalValue] = useState<DateRange | undefined>(value);

  // Update local value when prop value changes
  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  // Handle selection and only propagate complete ranges
  const handleSelect = (newRange: DateRange | undefined) => {
    setLocalValue(newRange);
    
    // Only call onChange when we have a complete range or undefined
    if (!newRange || (newRange.from && newRange.to)) {
      onChange(newRange);
      
      // Close the popover when a complete range is selected
      if (newRange?.from && newRange?.to) {
        setTimeout(() => setOpen(false), 300);
      }
    }
  };

  return (
    <div className={cn("grid gap-2", className)}>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={"outline"}
            className={cn(
              "w-[250px] justify-start text-left font-normal",
              !value && "text-muted-foreground"
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {value?.from ? (
              value.to ? (
                <>
                  {format(value.from, "LLL dd, y")} -{" "}
                  {format(value.to, "LLL dd, y")}
                </>
              ) : (
                format(value.from, "LLL dd, y")
              )
            ) : (
              <span>{placeholder}</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className={cn("w-auto p-0", calendarClassName)} align={align}>
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={value?.from}
            selected={localValue}
            onSelect={handleSelect}
            numberOfMonths={numberOfMonths}
            disabled={(date) => date > new Date() || date < new Date("1900-01-01")}
          />
          <div className="flex items-center justify-between p-3 border-t border-border">
            <Button
              variant="ghost"
              size="sm"
              onClick={() => {
                setLocalValue(undefined);
                onChange(undefined);
                setOpen(false);
              }}
            >
              Clear
            </Button>
            <Button
              size="sm"
              onClick={() => {
                if (localValue?.from && localValue?.to) {
                  onChange(localValue);
                }
                setOpen(false);
              }}
            >
              Apply
            </Button>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  )
}
