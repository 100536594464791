// Constants
export const BREAKPOINT_WIDTH = 1375;
export const HEADER_HEIGHT = 64; // 16 * 4 = 64px (4rem)

// Helper functions
export function getAreaCode(phone: string): string | null {
  const cleaned = phone.replace(/\D/g, '');
  if (cleaned.length === 10) {
    return cleaned.substring(0, 3);
  }
  if (cleaned.length === 11 && cleaned.startsWith('1')) {
    return cleaned.substring(1, 4);
  }
  return null;
} 