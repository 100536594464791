import { useState } from 'react';
import { Toaster } from 'sonner';
import { WebSocketCallNotification } from '../websocket/WebSocketCallNotification';
import { WebSocketDashboardUpdates } from '../websocket/WebSocketDashboardUpdates';
import { WebSocketDataSync } from '../websocket/WebSocketDataSync';
import { WebSocketLeadAssignmentNotification } from '../websocket/WebSocketLeadAssignmentNotification';
import { WebSocketTextMessageNotification } from '../websocket/WebSocketTextMessageNotification';
import { MobileHeader } from './MobileHeader';
import { Sidebar } from './Sidebar';

interface MainLayoutProps {
  children: React.ReactNode;
  pageTitle?: string;
  pageDescription?: string;
}

export function MainLayout({ children, pageTitle, pageDescription }: MainLayoutProps) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="h-screen w-full bg-gray-50">
      <Toaster richColors position="top-right" />

      {/* WebSocket Notifications */}
      <WebSocketCallNotification />
      <WebSocketLeadAssignmentNotification />
      <WebSocketTextMessageNotification />
      <WebSocketDataSync />
      <WebSocketDashboardUpdates />

      {/* Mobile Header - visible only on mobile */}
      <MobileHeader 
        onMenuClick={toggleSidebar} 
        pageTitle={pageTitle}
        pageDescription={pageDescription}
      />

      <div className="flex h-full">
        {/* Sidebar */}
        <Sidebar isOpen={isSidebarOpen} onClose={() => setIsSidebarOpen(false)} />

        {/* Main Content */}
        <main className="flex-1 overflow-auto h-screen">
          {/* Overlay for mobile when sidebar is open */}
          {isSidebarOpen && (
            <div
              className="fixed inset-0 bg-black bg-opacity-50 transition-opacity md:hidden"
              onClick={() => setIsSidebarOpen(false)}
            />
          )}
          
          {/* Page Content */}
          <div className="h-screen">
            {children}
          </div>
        </main>
      </div>
    </div>
  );
} 