import ResetPasswordPage from '@/pages/ResetPasswordPage';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/reset-password')({
  component: ResetPasswordPage,
  validateSearch: (search) => {
    return {
      token: search.token as string | null,
      email: search.email as string | null
    }
  }
});