import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";
import { toast } from "@/components/ui/use-toast";
import { cn } from "@/lib/utils";
import { CalendarEvent, CalendarService } from "@/services/calendar.service";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { addMinutes, compareAsc, format, isToday, set } from "date-fns";
import { AlertCircle, CalendarIcon, Clock, Loader2 } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

interface CreateEventDialogProps {
  isOpen: boolean;
  onClose: () => void;
  calendarIntegrationId: string;
}

// Form schema for validating event creation
const eventFormSchema = z.object({
  title: z.string().min(1, "Title is required"),
  description: z.string().optional(),
  location: z.string().optional(),
  startDate: z.date({
    required_error: "Start date is required",
  }),
  startHour: z.enum([
    "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"
  ], {
    required_error: "Hour is required",
  }),
  startMinute: z.enum([
    "00", "15", "30", "45"
  ], {
    required_error: "Minute is required",
  }),
  startPeriod: z.enum(["AM", "PM"], {
    required_error: "AM/PM is required",
  }),
  duration: z.enum(["15", "30", "60", "90", "120"], {
    required_error: "Duration is required",
  }),
}).refine((data) => {
  // Validate that selected time is not in the past when date is today
  if (isToday(data.startDate)) {
    const now = new Date();
    let selectedHour = parseInt(data.startHour);
    if (data.startPeriod === "PM" && selectedHour !== 12) {
      selectedHour += 12;
    } else if (data.startPeriod === "AM" && selectedHour === 12) {
      selectedHour = 0;
    }
    
    const selectedTime = set(new Date(), {
      hours: selectedHour,
      minutes: parseInt(data.startMinute),
      seconds: 0,
      milliseconds: 0
    });
    
    return selectedTime > now;
  }
  return true;
}, {
  message: "Cannot select a time in the past",
  path: ["startHour"], // This will show the error under the hour field
});

type EventFormValues = z.infer<typeof eventFormSchema>;

export function CreateEventDialog({ isOpen, onClose, calendarIntegrationId }: CreateEventDialogProps) {
  const [date, setDate] = useState<Date | undefined>(new Date());
  const queryClient = useQueryClient();
  
  // Get current time for default values
  const now = new Date();
  const currentHour = now.getHours();
  const currentMinute = now.getMinutes();
  const roundedMinute = Math.floor(currentMinute / 15) * 15;
  
  // Default to next hour if minutes are greater than 45
  const defaultHour = roundedMinute > 45 ? (currentHour + 1) % 12 || 12 : currentHour % 12 || 12;
  const defaultMinute = roundedMinute === 0 ? "00" : roundedMinute.toString();
  const defaultPeriod = (roundedMinute > 45 ? (currentHour + 1) : currentHour) >= 12 ? "PM" : "AM";

  // Fetch existing calendar events
  const { data: calendarEvents } = useQuery<CalendarEvent[]>({
    queryKey: ['calendarEvents', calendarIntegrationId],
    queryFn: async () => {
      return CalendarService.getSyncedEvents(calendarIntegrationId);
    },
    enabled: !!calendarIntegrationId && isOpen,
  });

  // Set up form with validation
  const form = useForm<EventFormValues>({
    resolver: zodResolver(eventFormSchema),
    defaultValues: {
      title: "",
      description: "",
      location: "",
      startDate: new Date(),
      startHour: defaultHour.toString() as any,
      startMinute: defaultMinute as any,
      startPeriod: defaultPeriod as "AM" | "PM",
      duration: "60",
    },
  });

  // Watch the startDate to update time selection validation
  const selectedDate = form.watch("startDate");
  
  // Watch time fields to detect changes and validate
  const startHour = form.watch("startHour");
  const startMinute = form.watch("startMinute");
  const startPeriod = form.watch("startPeriod");
  const duration = form.watch("duration");
  
  // Filter events for the selected date
  const eventsOnSelectedDate = useMemo(() => {
    if (!calendarEvents || !selectedDate) return [];
    
    // Filter events that occur on the selected date
    return calendarEvents.filter(event => {
      const eventStartTime = new Date(event.startTime);
      const eventDate = new Date(
        eventStartTime.getFullYear(),
        eventStartTime.getMonth(),
        eventStartTime.getDate()
      );
      
      const formattedSelectedDate = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        selectedDate.getDate()
      );
      
      return compareAsc(eventDate, formattedSelectedDate) === 0;
    });
  }, [calendarEvents, selectedDate]);
  
  // Update time when date changes
  useEffect(() => {
    // If selecting today's date, ensure time is in the future
    if (isToday(selectedDate)) {
      const now = new Date();
      const currentHour = now.getHours();
      const currentMinute = now.getMinutes();
      const roundedMinute = Math.ceil(currentMinute / 15) * 15;
      
      // Calculate next available time slot
      let nextHour = currentHour;
      let nextMinute = roundedMinute;
      
      if (roundedMinute >= 60) {
        nextHour += 1;
        nextMinute = 0;
      }
      
      // Convert to 12-hour format
      const nextHour12 = nextHour % 12 || 12;
      const nextPeriod = nextHour >= 12 ? "PM" : "AM";
      
      // Update form values with next available time
      form.setValue("startHour", nextHour12.toString() as any);
      form.setValue("startMinute", nextMinute === 0 ? "00" : nextMinute.toString() as any);
      form.setValue("startPeriod", nextPeriod as "AM" | "PM");
    }
  }, [selectedDate, form]);

  // Validate time selection when period changes and adjust if needed
  useEffect(() => {
    if (isToday(selectedDate) && isTimeInPast(startHour, startMinute, startPeriod)) {
      // Find the next valid time if current selection is in the past
      const now = new Date();
      const currentHour = now.getHours();
      const currentMinute = now.getMinutes();
      const roundedMinute = Math.ceil(currentMinute / 15) * 15;
      
      // Calculate next available time slot
      let nextHour = currentHour;
      let nextMinute = roundedMinute;
      
      if (roundedMinute >= 60) {
        nextHour += 1;
        nextMinute = 0;
      }
      
      // Convert to 12-hour format
      const nextHour12 = nextHour % 12 || 12;
      const nextPeriod = nextHour >= 12 ? "PM" : "AM";
      
      // Update form values with next available time
      form.setValue("startHour", nextHour12.toString() as any);
      form.setValue("startMinute", nextMinute === 0 ? "00" : nextMinute.toString() as any);
      form.setValue("startPeriod", nextPeriod as "AM" | "PM");
    }
  }, [startPeriod, form, selectedDate, startHour, startMinute]);

  // Create event mutation
  const { mutate: createEvent, isPending } = useMutation({
    mutationFn: async (values: EventFormValues) => {
      // Convert 12-hour time to 24-hour time
      let hours = parseInt(values.startHour);
      if (values.startPeriod === "PM" && hours !== 12) {
        hours += 12;
      } else if (values.startPeriod === "AM" && hours === 12) {
        hours = 0;
      }
      
      const minutes = parseInt(values.startMinute);
      
      // Create start time by combining date and time
      const startTime = set(values.startDate, {
        hours,
        minutes,
        seconds: 0,
        milliseconds: 0
      });

      // Calculate end time based on duration
      const endTime = addMinutes(startTime, parseInt(values.duration));

      // Create event object
      const eventData = {
        title: values.title,
        description: values.description || null,
        location: values.location || null,
        startTime: startTime.toISOString(),
        endTime: endTime.toISOString(),
        calendarIntegrationId,
        leadId: null, // Use null instead of empty string for no lead connection
        meetingLink: null,
        outcome: null, // Add outcome field to match CalendarEvent type
      };

      return CalendarService.createEvent(eventData);
    },
    onSuccess: () => {
      // Reset form
      form.reset();
      
      // Show success message
      toast({
        title: "Event created",
        description: "Your calendar event has been created.",
      });
      
      // Close dialog
      onClose();
      
      // Invalidate calendar events query
      queryClient.invalidateQueries({ queryKey: ['calendarEvents'], exact: false });
    },
    onError: (error) => {
      console.error("Error creating event:", error);
      toast({
        title: "Failed to create event",
        description: "There was an error creating your event. Please try again.",
        variant: "destructive",
      });
    },
  });

  function onSubmit(values: EventFormValues) {
    createEvent(values);
  }

  // Check if a time slot conflicts with existing events
  const isTimeSlotConflicting = (hour: string, minute: string, period: "AM" | "PM") => {
    if (!eventsOnSelectedDate || !eventsOnSelectedDate.length) return false;
    
    // Convert to 24-hour format
    let selectedHour = parseInt(hour);
    if (period === "PM" && selectedHour !== 12) {
      selectedHour += 12;
    } else if (period === "AM" && selectedHour === 12) {
      selectedHour = 0;
    }
    
    // Create start and end time for the proposed slot
    const proposedStartTime = set(selectedDate, {
      hours: selectedHour,
      minutes: parseInt(minute),
      seconds: 0,
      milliseconds: 0
    });
    
    const proposedEndTime = addMinutes(proposedStartTime, parseInt(duration));
    
    // Check if it conflicts with any existing event
    return eventsOnSelectedDate.some(event => {
      const eventStartTime = new Date(event.startTime);
      const eventEndTime = new Date(event.endTime);
      
      // Add a small buffer (1 second) to avoid marking back-to-back meetings as conflicts
      const bufferedEventEndTime = new Date(eventEndTime.getTime() - 1000);
      const bufferedProposedEndTime = new Date(proposedEndTime.getTime() - 1000);
      
      // Check for proper overlap (not just end time = start time of another)
      return (
        // Check if proposed start time is during another event
        (proposedStartTime >= eventStartTime && proposedStartTime < bufferedEventEndTime) ||
        // Check if proposed end time is during another event
        (bufferedProposedEndTime > eventStartTime && bufferedProposedEndTime <= bufferedEventEndTime) ||
        // Check if proposed event completely contains another event
        (proposedStartTime <= eventStartTime && proposedEndTime >= eventEndTime)
      );
    });
  };

  // Check if a time slot is in the past
  const isTimeInPast = (hour: string, minute: string, period: "AM" | "PM") => {
    if (!isToday(form.watch("startDate"))) return false;
    
    const now = new Date();
    let selectedHour = parseInt(hour);
    if (period === "PM" && selectedHour !== 12) {
      selectedHour += 12;
    } else if (period === "AM" && selectedHour === 12) {
      selectedHour = 0;
    }
    
    return (
      selectedHour < now.getHours() || 
      (selectedHour === now.getHours() && parseInt(minute) <= now.getMinutes())
    );
  };

  // Check if AM is entirely in the past today
  const isAMInPast = () => {
    if (!isToday(selectedDate)) return false;
    
    const now = new Date();
    return now.getHours() >= 12;
  };

  // Check if a specific hour in AM is in the past
  const isHourInAMPast = (hour: string) => {
    if (!isToday(selectedDate)) return false;
    if (form.watch("startPeriod") !== "AM") return false;
    
    const now = new Date();
    const hourNum = parseInt(hour);
    const hourIn24 = hourNum === 12 ? 0 : hourNum;
    
    return now.getHours() > hourIn24;
  };

  // Check if a specific hour in PM is in the past
  const isHourInPMPast = (hour: string) => {
    if (!isToday(selectedDate)) return false;
    if (form.watch("startPeriod") !== "PM") return false;
    
    const now = new Date();
    const hourNum = parseInt(hour);
    const hourIn24 = hourNum === 12 ? 12 : hourNum + 12;
    
    return now.getHours() > hourIn24;
  };

  return (
    <Dialog open={isOpen} onOpenChange={(open) => !open && onClose()}>
      <DialogContent className="sm:max-w-[550px] max-h-[90vh] overflow-y-auto p-4 sm:p-6">
        <DialogHeader>
          <DialogTitle>Create New Event</DialogTitle>
          <DialogDescription>
            Add a new event to your calendar.
          </DialogDescription>
        </DialogHeader>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4 pt-2">
            <FormField
              control={form.control}
              name="title"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Event Title</FormLabel>
                  <FormControl>
                    <Input placeholder="Meeting with Client" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Description</FormLabel>
                  <FormControl>
                    <Textarea
                      placeholder="Details about the meeting..."
                      className="min-h-[80px]"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="location"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Location</FormLabel>
                  <FormControl>
                    <Input placeholder="Office or Zoom call" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="startDate"
              render={({ field }) => (
                <FormItem className="flex flex-col">
                  <FormLabel>Date</FormLabel>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant={"outline"}
                          className={cn(
                            "w-full pl-3 text-left font-normal",
                            !field.value && "text-muted-foreground"
                          )}
                        >
                          {field.value ? (
                            format(field.value, "EEEE, MMMM d, yyyy")
                          ) : (
                            <span>Pick a date</span>
                          )}
                          <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar
                        mode="single"
                        selected={field.value}
                        onSelect={field.onChange}
                        disabled={(date) => date < new Date(new Date().setHours(0, 0, 0, 0))}
                        initialFocus
                      />
                    </PopoverContent>
                  </Popover>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="space-y-2">
              <FormLabel>Start Time</FormLabel>
              <div className="flex items-center gap-2">
                <div className="flex-1 flex gap-2 items-center">
                  <Popover>
                    <PopoverTrigger asChild>
                      <Button variant="outline" className="flex-1">
                        <Clock className="mr-2 h-4 w-4" />
                        <span className="mr-2">
                          {form.watch("startHour")}:{form.watch("startMinute")} {form.watch("startPeriod")}
                        </span>
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent className="p-0 w-[280px] sm:w-[350px] md:w-fit" align="start">
                      <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 p-3 sm:p-4">
                        <div>
                          <p className="text-sm font-medium mb-2">Hour</p>
                          <div className="grid grid-cols-4 gap-2">
                            {["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"].map((hour) => {
                              const isConflicting = ["00", "15", "30", "45"].some(minute => 
                                isTimeSlotConflicting(hour, minute, form.watch("startPeriod"))
                              );
                              
                              return (
                                <Button
                                  key={hour}
                                  type="button"
                                  variant={form.watch("startHour") === hour ? "default" : "outline"}
                                  className={cn(
                                    "h-10 w-10 sm:h-9 sm:w-9 p-0",
                                    isConflicting && !isTimeInPast(hour, form.watch("startMinute"), form.watch("startPeriod")) && 
                                      "bg-gray-100 hover:bg-gray-200 text-gray-500"
                                  )}
                                  disabled={
                                    (form.watch("startPeriod") === "AM" && isHourInAMPast(hour)) ||
                                    (form.watch("startPeriod") === "PM" && isHourInPMPast(hour)) ||
                                    isTimeInPast(hour, form.watch("startMinute"), form.watch("startPeriod"))
                                  }
                                  onClick={() => {
                                    form.setValue("startHour", hour as any);
                                    // If this creates an invalid time, also update the minute to next available
                                    if (isTimeInPast(hour, form.watch("startMinute"), form.watch("startPeriod"))) {
                                      const now = new Date();
                                      // If we're in the current hour, find next available minute
                                      if (
                                        (form.watch("startPeriod") === "AM" && 
                                         (parseInt(hour) === 12 ? 0 : parseInt(hour)) === now.getHours() % 12) ||
                                        (form.watch("startPeriod") === "PM" && 
                                         (parseInt(hour) === 12 ? 12 : parseInt(hour) + 12) === now.getHours())
                                      ) {
                                        const nextMinute = Math.ceil(now.getMinutes() / 15) * 15;
                                        form.setValue("startMinute", nextMinute === 0 ? "00" : nextMinute.toString() as any);
                                      }
                                    }
                                  }}
                                >
                                  {hour}
                                </Button>
                              );
                            })}
                          </div>
                        </div>
                        <div>
                          <p className="text-sm font-medium mb-2">Minute</p>
                          <div className="grid grid-cols-2 gap-2">
                            {["00", "15", "30", "45"].map((minute) => {
                              const isConflicting = isTimeSlotConflicting(
                                form.watch("startHour"), 
                                minute, 
                                form.watch("startPeriod")
                              );
                              
                              return (
                                <Button
                                  key={minute}
                                  type="button"
                                  variant={form.watch("startMinute") === minute ? "default" : "outline"}
                                  className={cn(
                                    "h-10 w-12 sm:h-9 sm:w-9 p-0",
                                    isConflicting && !isTimeInPast(form.watch("startHour"), minute, form.watch("startPeriod")) && 
                                      "bg-gray-100 hover:bg-gray-200 text-gray-500"
                                  )}
                                  disabled={isTimeInPast(form.watch("startHour"), minute, form.watch("startPeriod"))}
                                  onClick={() => form.setValue("startMinute", minute as any)}
                                >
                                  {minute}
                                </Button>
                              );
                            })}
                          </div>
                        </div>
                        <div>
                          <p className="text-sm font-medium mb-2">AM/PM</p>
                          <div className="grid grid-cols-2 sm:grid-cols-1 gap-2">
                            {["AM", "PM"].map((period) => (
                              <Button
                                key={period}
                                type="button"
                                variant={form.watch("startPeriod") === period ? "default" : "outline"}
                                className="h-10 sm:h-9"
                                disabled={period === "AM" && isAMInPast()}
                                onClick={() => {
                                  form.setValue("startPeriod", period as any);
                                  
                                  // If switching to AM and the current selection would be in the past
                                  if (period === "AM" && isToday(selectedDate)) {
                                    const now = new Date();
                                    const currentHourIn12 = now.getHours() % 12 || 12;
                                    const selectedHour = parseInt(form.watch("startHour"));
                                    
                                    // If the selected hour would be in the past in AM
                                    if ((selectedHour < currentHourIn12) || 
                                        (selectedHour === currentHourIn12 && 
                                         parseInt(form.watch("startMinute")) <= now.getMinutes())) {
                                      // Update to current hour + next available minute
                                      form.setValue("startHour", currentHourIn12.toString() as any);
                                      const nextMinute = Math.ceil(now.getMinutes() / 15) * 15;
                                      const adjustedMinute = nextMinute >= 60 ? "00" : nextMinute === 0 ? "00" : nextMinute.toString();
                                      form.setValue("startMinute", adjustedMinute as any);
                                      
                                      // If needed to roll over to next hour
                                      if (nextMinute >= 60) {
                                        const nextHour = (currentHourIn12 + 1) % 12 || 12;
                                        form.setValue("startHour", nextHour.toString() as any);
                                      }
                                    }
                                  }
                                }}
                              >
                                {period}
                              </Button>
                            ))}
                          </div>
                        </div>
                      </div>
                    </PopoverContent>
                  </Popover>
                </div>
              </div>
              {form.formState.errors.startHour && (
                <p className="text-sm font-medium text-destructive">
                  {form.formState.errors.startHour.message}
                </p>
              )}
              
              {/* Show hint about conflicting events */}
              {isTimeSlotConflicting(startHour, startMinute, startPeriod) && (
                <div className="flex items-center gap-2 text-amber-600 text-sm mt-2">
                  <AlertCircle className="h-4 w-4" />
                  <span>This time conflicts with an existing event</span>
                </div>
              )}
            </div>

            <FormField
              control={form.control}
              name="duration"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Duration</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Select duration" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="15">15 minutes</SelectItem>
                      <SelectItem value="30">30 minutes</SelectItem>
                      <SelectItem value="60">1 hour</SelectItem>
                      <SelectItem value="90">1.5 hours</SelectItem>
                      <SelectItem value="120">2 hours</SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            <DialogFooter className="pt-4">
              <Button type="button" variant="outline" onClick={onClose}>
                Cancel
              </Button>
              <Button type="submit" disabled={isPending}>
                {isPending ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    Creating...
                  </>
                ) : (
                  "Create Event"
                )}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
} 