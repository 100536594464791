import { Button } from '@/components/ui/button';
import {
    HiOutlineCheckCircle,
    HiOutlineExclamationCircle,
    HiOutlineXCircle,
} from 'react-icons/hi';

interface ImportSummaryProps {
  results: {
    totalRecords: number;
    importedRecords: number;
    failedRecords: number;
    errors?: string[];
  } | null;
  onClose: () => void;
}

export function ImportSummary({ results, onClose }: ImportSummaryProps) {
  if (!results) {
    return null;
  }

  const { totalRecords, importedRecords, failedRecords, errors } = results;
  const successRate = Math.round((importedRecords / totalRecords) * 100);
  
  let statusIcon;
  let statusColor;
  
  if (failedRecords === 0) {
    statusIcon = <HiOutlineCheckCircle className="h-16 w-16 text-green-500" />;
    statusColor = 'text-green-500';
  } else if (importedRecords === 0) {
    statusIcon = <HiOutlineXCircle className="h-16 w-16 text-red-500" />;
    statusColor = 'text-red-500';
  } else {
    statusIcon = <HiOutlineExclamationCircle className="h-16 w-16 text-yellow-500" />;
    statusColor = 'text-yellow-500';
  }

  return (
    <div className="flex flex-col items-center space-y-6 py-4">
      {statusIcon}
      
      <h3 className={`text-xl font-medium ${statusColor}`}>
        {failedRecords === 0
          ? 'Import Completed Successfully'
          : importedRecords === 0
          ? 'Import Failed'
          : 'Import Completed with Warnings'}
      </h3>
      
      <div className="grid grid-cols-3 gap-4 w-full max-w-md">
        <div className="text-center p-4 bg-gray-50 rounded-lg">
          <div className="text-2xl font-bold">{totalRecords}</div>
          <div className="text-sm text-gray-500">Total Records</div>
        </div>
        <div className="text-center p-4 bg-green-50 rounded-lg">
          <div className="text-2xl font-bold text-green-600">{importedRecords}</div>
          <div className="text-sm text-green-600">Imported</div>
        </div>
        <div className="text-center p-4 bg-red-50 rounded-lg">
          <div className="text-2xl font-bold text-red-600">{failedRecords}</div>
          <div className="text-sm text-red-600">Failed</div>
        </div>
      </div>
      
      <div className="text-center">
        <p className="text-gray-600">
          {successRate}% of records were successfully imported.
        </p>
      </div>
      
      {errors && errors.length > 0 && (
        <div className="w-full max-w-md">
          <h4 className="font-medium mb-2">Errors</h4>
          <div className="max-h-[200px] overflow-y-auto border rounded-md p-3 bg-gray-50">
            <ul className="list-disc pl-5 space-y-1 text-sm text-gray-600">
              {errors.slice(0, 10).map((error, index) => (
                <li key={index}>{error}</li>
              ))}
              {errors.length > 10 && (
                <li className="font-medium">
                  ...and {errors.length - 10} more errors
                </li>
              )}
            </ul>
          </div>
        </div>
      )}
      
      <div className="pt-4">
        <Button onClick={onClose}>
          Close
        </Button>
      </div>
    </div>
  );
} 