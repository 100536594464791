import { Tabs, TabsContent } from '@/components/ui/tabs';
import { CommunicationHeader } from './CommunicationHeader';
import { SMSTab } from './SMSTab';
import { VoiceCallTab } from './VoiceCallTab';

interface DesktopLayoutProps {
  selectedDID: string | null;
  setSelectedDID: (did: string | null) => void;
  phoneNumber: string;
  displayName: string;
  leadId?: number | null;
}

export function DesktopLayout({
  selectedDID,
  setSelectedDID,
  phoneNumber,
  displayName,
  leadId
}: DesktopLayoutProps) {
  return (
    <div className="hidden lg:flex flex-col h-[calc(100vh-64px)] overflow-hidden">
      <Tabs defaultValue="voice" className="flex flex-col h-full overflow-hidden">
        <CommunicationHeader
          selectedDID={selectedDID}
          setSelectedDID={setSelectedDID}
          phoneNumber={phoneNumber}
        />
        <TabsContent value="voice" className="flex-1 px-4 py-4">
          <VoiceCallTab
            phoneNumber={phoneNumber}
            fromNumber={selectedDID}
            contactName={displayName}
            leadId={leadId}
          />
        </TabsContent>
        <TabsContent value="sms" className="flex-1 overflow-hidden flex flex-col mt-0">
          <SMSTab
            phoneNumber={phoneNumber}
            fromNumber={selectedDID}
            className="h-full"
          />
        </TabsContent>
      </Tabs>
    </div>
  );
} 