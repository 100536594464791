import { Button } from "@/components/ui/button";
import { ArrowLeft } from "lucide-react";

export function Privacy() {
  const lastUpdated = "July 22, 2024";

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="container mx-auto px-4 py-12">
        {/* Back Button */}
        <a href="https://leadgpt.com" className="inline-block mb-8">
          <Button variant="ghost" className="gap-2">
            <ArrowLeft className="h-4 w-4" />
            Back to Home
          </Button>
        </a>

        <div className="max-w-4xl mx-auto bg-white rounded-xl shadow-sm p-8 md:p-12">
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Privacy Policy</h1>
          <p className="text-gray-600 mb-8">Last Updated: {lastUpdated}</p>

          <div className="prose prose-gray max-w-none">
            <section className="mb-8">
              <h2 className="text-2xl font-bold mb-4">1. Introduction</h2>
              <p className="mb-4">
                LeadGPT ("we," "our," or "us") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our AI-powered insurance sales platform.
              </p>
              <p className="mb-4">
                By accessing or using our services, you agree to the terms of this Privacy Policy. If you do not agree with the terms of this Privacy Policy, please do not access or use our services.
              </p>
              <p className="mb-4">
                This Privacy Policy applies to all information collected through our platform, website, mobile applications, and any related services, sales, marketing, or events (collectively, the "Services").
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold mb-4">2. Information We Collect</h2>
              <h3 className="text-xl font-semibold mb-3">2.1 Personal Information</h3>
              <p className="mb-4">
                We collect personal information that you voluntarily provide to us when you:
              </p>
              <ul className="list-disc pl-6 mb-4">
                <li>Register for an account</li>
                <li>Express interest in obtaining information about us or our products</li>
                <li>Participate in activities on the Services</li>
                <li>Contact us</li>
              </ul>
              <p className="mb-4">
                The personal information we collect may include:
              </p>
              <ul className="list-disc pl-6 mb-4">
                <li>Name and contact information</li>
                <li>Email address and phone number</li>
                <li>Business information</li>
                <li>Insurance license information</li>
                <li>Payment information</li>
                <li>Professional credentials and qualifications</li>
                <li>Employer information and job title</li>
                <li>Social media profiles (if you choose to link them)</li>
              </ul>

              <h3 className="text-xl font-semibold mb-3">2.2 Usage Information</h3>
              <p className="mb-4">
                We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity but may include:
              </p>
              <ul className="list-disc pl-6 mb-4">
                <li>Log data and device information (IP address, browser type, operating system)</li>
                <li>Usage patterns and preferences</li>
                <li>Communication records</li>
                <li>AI analysis results</li>
                <li>Time spent on pages and features used</li>
                <li>Click patterns and interactions with the platform</li>
                <li>Device information (device type, operating system, and browser information)</li>
                <li>Location data (with your permission)</li>
              </ul>
              
              <h3 className="text-xl font-semibold mb-3">2.3 Customer and Lead Information</h3>
              <p className="mb-4">
                We may collect information about your customers and leads that you provide to us or authorize us to collect, including:
              </p>
              <ul className="list-disc pl-6 mb-4">
                <li>Contact information of leads and customers</li>
                <li>Insurance needs and preferences</li>
                <li>Communication history (call recordings, transcripts, and text messages)</li>
                <li>Policy information and history</li>
                <li>Demographic information</li>
              </ul>
              <p className="mb-4">
                You represent and warrant that you have the necessary rights and permissions to share this information with us and that you have provided any required notices and obtained any required consents from your leads and customers.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold mb-4">3. How We Use Your Information</h2>
              <p className="mb-4">
                We use the information we collect for various business and commercial purposes, including:
              </p>
              <ul className="list-disc pl-6 mb-4">
                <li>Provide and improve our services</li>
                <li>Process payments and transactions</li>
                <li>Analyze and optimize platform performance</li>
                <li>Send important notifications and updates</li>
                <li>Comply with legal obligations</li>
                <li>Prevent fraud and abuse</li>
                <li>Facilitate communication between you and your leads/customers</li>
                <li>Generate and provide AI-powered insights and recommendations</li>
                <li>Create and maintain your account</li>
                <li>Respond to your inquiries and provide customer support</li>
                <li>Develop and test new products and features</li>
                <li>Monitor usage metrics to improve our Services</li>
                <li>Personalize your experience</li>
              </ul>
              
              <h3 className="text-xl font-semibold mb-3">3.1 Processing of Customer and Lead Data</h3>
              <p className="mb-4">
                When you provide us with information about your customers and leads, we process this information as your service provider. We use this information to:
              </p>
              <ul className="list-disc pl-6 mb-4">
                <li>Provide you with lead management tools</li>
                <li>Enable communication with leads and customers</li>
                <li>Analyze communication patterns to provide insights</li>
                <li>Generate automated tasks and recommendations</li>
                <li>Track conversion rates and performance metrics</li>
              </ul>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold mb-4">4. Information Sharing</h2>
              <p className="mb-4">We may share your information with:</p>
              
              <h3 className="text-xl font-semibold mb-3">4.1 Service Providers</h3>
              <p className="mb-4">
                We may share your information with third-party vendors, service providers, contractors, or agents who perform services for us or on our behalf, including:
              </p>
              <ul className="list-disc pl-6 mb-4">
                <li>Data analytics providers</li>
                <li>Payment processors</li>
                <li>Customer service providers</li>
                <li>Email delivery services</li>
                <li>Hosting and cloud computing providers</li>
                <li>Telecommunication service providers</li>
              </ul>
              
              <h3 className="text-xl font-semibold mb-3">4.2 Business Transfers</h3>
              <p className="mb-4">
                If we are involved in a merger, acquisition, financing, reorganization, bankruptcy, receivership, sale of company assets, or transition of service to another provider, your information may be transferred as part of such a transaction. We will notify you of any such change in ownership or control of your personal information.
              </p>
              
              <h3 className="text-xl font-semibold mb-3">4.3 Legal Requirements</h3>
              <p className="mb-4">
                We may disclose your information where required to do so by law or subpoena, or if we believe such action is necessary to:
              </p>
              <ul className="list-disc pl-6 mb-4">
                <li>Comply with a legal obligation</li>
                <li>Protect and defend our rights or property</li>
                <li>Prevent or investigate possible wrongdoing in connection with the Services</li>
                <li>Protect the personal safety of users of the Services or the public</li>
                <li>Protect against legal liability</li>
              </ul>
              
              <h3 className="text-xl font-semibold mb-3">4.4 With Your Consent</h3>
              <p className="mb-4">
                We may disclose your personal information for any other purpose with your consent.
              </p>
              
              <h3 className="text-xl font-semibold mb-3">4.5 Aggregated and De-identified Information</h3>
              <p className="mb-4">
                We may use and share aggregated and de-identified information, which cannot reasonably be used to identify you. This includes using aggregated data for industry analysis, demographic profiling, marketing, and other business purposes.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold mb-4">5. Data Security</h2>
              <p className="mb-4">
                We implement appropriate technical and organizational measures to protect your data, including:
              </p>
              <ul className="list-disc pl-6 mb-4">
                <li>Encryption of data in transit and at rest</li>
                <li>Regular security assessments</li>
                <li>Access controls and authentication</li>
                <li>Employee training on data protection</li>
                <li>Firewalls and intrusion detection systems</li>
                <li>Secure data centers and cloud environments</li>
                <li>Vulnerability scanning and penetration testing</li>
                <li>Disaster recovery and business continuity plans</li>
              </ul>
              <p className="mb-4">
                Despite our best efforts, no security system is impenetrable. We cannot guarantee the security of your information and are not responsible for unauthorized access, hacking, or other security intrusions that are beyond our reasonable control.
              </p>
              
              <h3 className="text-xl font-semibold mb-3">5.1 Data Breach Procedures</h3>
              <p className="mb-4">
                In the event of a data breach, we will:
              </p>
              <ul className="list-disc pl-6 mb-4">
                <li>Notify affected users as required by applicable laws</li>
                <li>Cooperate with regulatory authorities</li>
                <li>Take reasonable measures to mitigate the impact</li>
                <li>Investigate the cause and implement preventive measures</li>
              </ul>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold mb-4">6. Your Rights</h2>
              <p className="mb-4">
                Depending on your location and applicable laws, you may have the following rights regarding your personal information:
              </p>
              <ul className="list-disc pl-6 mb-4">
                <li>Access your personal information</li>
                <li>Correct inaccurate data</li>
                <li>Request deletion of your data</li>
                <li>Object to data processing</li>
                <li>Data portability</li>
                <li>Withdraw consent</li>
                <li>Opt-out of certain data sharing</li>
                <li>Non-discrimination for exercising your rights</li>
              </ul>
              
              <h3 className="text-xl font-semibold mb-3">6.1 How to Exercise Your Rights</h3>
              <p className="mb-4">
                To exercise your rights, please contact us using the contact information provided at the end of this Privacy Policy. We may need to verify your identity before fulfilling your request.
              </p>
              <p className="mb-4">
                We will respond to your request within the timeframe required by applicable law, typically within 30 days. In certain circumstances, we may need to extend our response time, in which case we will notify you.
              </p>
              
              <h3 className="text-xl font-semibold mb-3">6.2 Limitation on Rights</h3>
              <p className="mb-4">
                Your rights may be limited in some circumstances. For example, we may be unable to delete your data if retention is necessary to:
              </p>
              <ul className="list-disc pl-6 mb-4">
                <li>Complete a transaction for which the data was collected</li>
                <li>Provide a service you requested</li>
                <li>Comply with legal obligations</li>
                <li>Detect security incidents or protect against fraud</li>
                <li>Fix errors in existing systems</li>
                <li>Protect legal rights or property</li>
              </ul>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold mb-4">7. Cookies and Tracking</h2>
              <p className="mb-4">
                We use cookies and similar tracking technologies to improve your experience on our Services. Cookies are small text files that are stored on your device when you visit a website.
              </p>
              
              <h3 className="text-xl font-semibold mb-3">7.1 Types of Cookies We Use</h3>
              <ul className="list-disc pl-6 mb-4">
                <li><strong>Essential Cookies:</strong> Necessary for the basic functionality of our Services.</li>
                <li><strong>Analytical/Performance Cookies:</strong> Help us understand how visitors interact with our Services.</li>
                <li><strong>Functionality Cookies:</strong> Remember your preferences and settings.</li>
                <li><strong>Targeting/Advertising Cookies:</strong> Deliver advertisements relevant to you and your interests.</li>
              </ul>
              
              <h3 className="text-xl font-semibold mb-3">7.2 Managing Cookies</h3>
              <p className="mb-4">
                You can control cookie settings through your browser preferences. Most web browsers allow you to:
              </p>
              <ul className="list-disc pl-6 mb-4">
                <li>View your cookies</li>
                <li>Accept or reject cookies</li>
                <li>Set preferences for certain types of cookies</li>
                <li>Delete cookies</li>
              </ul>
              <p className="mb-4">
                Please note that if you disable cookies, some features of our Services may not function properly.
              </p>
              
              <h3 className="text-xl font-semibold mb-3">7.3 Do Not Track Signals</h3>
              <p className="mb-4">
                Some browsers have a "Do Not Track" feature that signals to websites that you visit that you do not want to have your online activity tracked. Our Services do not currently respond to "Do Not Track" signals.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold mb-4">8. Children's Privacy</h2>
              <p className="mb-4">
                Our services are not intended for users under 18. We do not knowingly collect information from children.
              </p>
              <p className="mb-4">
                If we learn that we have collected personal information from a child under 18, we will promptly take steps to delete such information from our records. If you believe we have any information from or about a child under 18, please contact us using the contact information provided at the end of this Privacy Policy.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold mb-4">9. International Data Transfers</h2>
              <p className="mb-4">
                We are based in the United States and may process, store, and transfer your information in the United States and other countries which may have different data protection laws than those in your country of residence.
              </p>
              <p className="mb-4">
                By using our Services, you consent to the transfer of your information to the United States and other countries. We will take appropriate measures to protect your personal information in accordance with this Privacy Policy and applicable laws.
              </p>
              
              <h3 className="text-xl font-semibold mb-3">9.1 Data Transfer Mechanisms</h3>
              <p className="mb-4">
                When transferring data from the European Economic Area (EEA), United Kingdom, or Switzerland to countries that do not have an adequacy decision, we rely on appropriate safeguards such as:
              </p>
              <ul className="list-disc pl-6 mb-4">
                <li>Standard contractual clauses</li>
                <li>Binding corporate rules</li>
                <li>Consent (in limited circumstances)</li>
                <li>Contractual necessity</li>
              </ul>
              <p className="mb-4">
                You may contact us for more information about these safeguards.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold mb-4">10. Third-Party Links and Services</h2>
              <p className="mb-4">
                Our Services may contain links to third-party websites, applications, or services that are not owned or controlled by us. This Privacy Policy applies only to our Services.
              </p>
              <p className="mb-4">
                We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party websites or services. We encourage you to review the privacy policies of any third-party websites or services that you visit.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold mb-4">11. Data Retention</h2>
              <p className="mb-4">
                We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, including:
              </p>
              <ul className="list-disc pl-6 mb-4">
                <li>Providing the Services you requested</li>
                <li>Complying with legal obligations</li>
                <li>Resolving disputes</li>
                <li>Enforcing our agreements</li>
              </ul>
              <p className="mb-4">
                When we no longer need your personal information, we will securely delete or anonymize it. If this is not possible, we will securely store your personal information and isolate it from any further use until deletion is possible.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold mb-4">12. Specific Privacy Rights by Region</h2>
              
              <h3 className="text-xl font-semibold mb-3">12.1 California Residents</h3>
              <p className="mb-4">
                If you are a California resident, you have specific rights under the California Consumer Privacy Act (CCPA) and the California Privacy Rights Act (CPRA), including:
              </p>
              <ul className="list-disc pl-6 mb-4">
                <li>Right to know what personal information we collect</li>
                <li>Right to delete your personal information</li>
                <li>Right to opt-out of the sale or sharing of personal information</li>
                <li>Right to non-discrimination for exercising your rights</li>
                <li>Right to limit use of sensitive personal information</li>
                <li>Right to correct inaccurate personal information</li>
              </ul>
              <p className="mb-4">
                To exercise your rights, please contact us using the information in the "Contact Us" section. You may designate an authorized agent to make a request on your behalf.
              </p>
              
              <h3 className="text-xl font-semibold mb-3">12.2 European Economic Area (EEA), United Kingdom, and Switzerland Residents</h3>
              <p className="mb-4">
                If you are in the EEA, UK, or Switzerland, you have rights under the General Data Protection Regulation (GDPR) or similar laws, including:
              </p>
              <ul className="list-disc pl-6 mb-4">
                <li>Right of access</li>
                <li>Right to rectification</li>
                <li>Right to erasure</li>
                <li>Right to restrict processing</li>
                <li>Right to data portability</li>
                <li>Right to object</li>
                <li>Rights related to automated decision-making and profiling</li>
              </ul>
              <p className="mb-4">
                To exercise your rights, please contact us using the information in the "Contact Us" section. You also have the right to lodge a complaint with your local data protection authority.
              </p>
              
              <h3 className="text-xl font-semibold mb-3">12.3 Legal Basis for Processing (EEA, UK, Switzerland)</h3>
              <p className="mb-4">
                We process your personal information based on one or more of the following legal grounds:
              </p>
              <ul className="list-disc pl-6 mb-4">
                <li><strong>Consent:</strong> You have given us permission to process your personal information.</li>
                <li><strong>Contract:</strong> Processing is necessary to perform a contract with you or to take steps before entering into a contract.</li>
                <li><strong>Legal Obligation:</strong> Processing is necessary to comply with our legal obligations.</li>
                <li><strong>Legitimate Interests:</strong> Processing is necessary for our legitimate interests or those of a third party, and your interests and fundamental rights do not override those interests.</li>
              </ul>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold mb-4">13. Changes to This Policy</h2>
              <p className="mb-4">
                We may update this Privacy Policy periodically. We will notify you of any material changes through our platform or via email.
              </p>
              <p className="mb-4">
                The date at the top of this Privacy Policy indicates when it was last updated. We encourage you to review this Privacy Policy regularly to stay informed about our information practices.
              </p>
              <p className="mb-4">
                Your continued use of our Services after any changes to this Privacy Policy constitutes your acceptance of the updated Privacy Policy.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold mb-4">14. Contact Us</h2>
              <p className="mb-4">
                If you have questions about this Privacy Policy, please contact us at:
              </p>
              <div className="bg-gray-50 p-4 rounded-lg">
                <p>Email: privacy@leadgpt.com</p>
              </div>
              <p className="mt-4">
                If you have a disability and need this Privacy Policy in an alternative format, please contact us at the information above.
              </p>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
} 