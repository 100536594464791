import { RoadmapItem } from "@/components/feedback/RoadmapList";
import { axiosInstance } from "@/lib/api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

export interface FeedbackInput {
  title: string;
  type: "feature" | "bug" | "improvement" | "other";
  description: string;
}

export const feedbackKeys = {
  all: ["feedback"] as const,
  roadmap: () => [...feedbackKeys.all, "roadmap"] as const,
  roadmapItem: (id: string) => [...feedbackKeys.roadmap(), id] as const,
};

export const feedbackApi = {
  // Submit new feedback
  submitFeedback: async (data: FeedbackInput): Promise<void> => {
    try {
      await axiosInstance.post("/feedback", data);
    } catch (error) {
      console.error("Error submitting feedback:", error);
      throw error;
    }
  },

  // Get all roadmap items
  getRoadmapItems: async (): Promise<RoadmapItem[]> => {
    try {
      const response = await axiosInstance.get("/feedback/roadmap");
      return response.data;
    } catch (error) {
      console.error("Error fetching roadmap items:", error);
      // For development purposes, return mock data if the API fails
      // In production, this should be removed or handled differently
      if (process.env.NODE_ENV === "development") {
        console.warn("Using mock data for roadmap items");
        return getMockRoadmapItems();
      }
      throw error;
    }
  },

  // Vote for a roadmap item
  voteForItem: async (id: string): Promise<void> => {
    try {
      await axiosInstance.post(`/feedback/roadmap/${id}/vote`);
    } catch (error) {
      console.error("Error voting for roadmap item:", error);
      throw error;
    }
  },
};

// TanStack Query hooks
export const useSubmitFeedback = () => {
  return useMutation({
    mutationFn: feedbackApi.submitFeedback,
  });
};

export const useRoadmapItems = () => {
  return useQuery({
    queryKey: feedbackKeys.roadmap(),
    queryFn: feedbackApi.getRoadmapItems,
    staleTime: 5 * 60 * 1000, // 5 minutes
    retry: 2,
  });
};

export const useVoteForItem = () => {
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: feedbackApi.voteForItem,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: feedbackKeys.roadmap() });
    },
    // Optimistic update
    onMutate: async (id) => {
      // Cancel any outgoing refetches
      await queryClient.cancelQueries({ queryKey: feedbackKeys.roadmap() });
      
      // Snapshot the previous value
      const previousItems = queryClient.getQueryData<RoadmapItem[]>(feedbackKeys.roadmap());
      
      // Optimistically update to the new value
      if (previousItems) {
        queryClient.setQueryData<RoadmapItem[]>(
          feedbackKeys.roadmap(),
          previousItems.map(item => {
            if (item.id === id) {
              return {
                ...item,
                votes: item.hasVoted ? item.votes - 1 : item.votes + 1,
                hasVoted: !item.hasVoted,
              };
            }
            return item;
          })
        );
      }
      
      // Return a context object with the snapshot
      return { previousItems };
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (err, id, context) => {
      if (context?.previousItems) {
        queryClient.setQueryData(feedbackKeys.roadmap(), context.previousItems);
      }
    },
  });
};

// Mock data for development
function getMockRoadmapItems(): RoadmapItem[] {
  return [
    {
      id: "1",
      title: "Bulk SMS Messaging",
      description: "Add the ability to send SMS messages to multiple leads at once.",
      type: "feature",
      status: "planned",
      votes: 42,
      hasVoted: false,
      createdAt: "2023-06-15T10:30:00Z",
    },
    {
      id: "2",
      title: "Email Template Builder",
      description: "Create a drag-and-drop email template builder for marketing campaigns.",
      type: "feature",
      status: "in-progress",
      votes: 38,
      hasVoted: true,
      createdAt: "2023-07-02T14:45:00Z",
    },
    {
      id: "3",
      title: "Dashboard Performance Issues",
      description: "Fix slow loading times on the dashboard when there are many leads.",
      type: "bug",
      status: "completed",
      votes: 27,
      hasVoted: false,
      createdAt: "2023-05-20T09:15:00Z",
    },
    {
      id: "4",
      title: "Lead Scoring System",
      description: "Implement an AI-based lead scoring system to prioritize high-value leads.",
      type: "feature",
      status: "planned",
      votes: 56,
      hasVoted: true,
      createdAt: "2023-08-10T11:20:00Z",
    },
    {
      id: "5",
      title: "Mobile App for Field Agents",
      description: "Develop a mobile app for field agents to access lead information on the go.",
      type: "feature",
      status: "planned",
      votes: 49,
      hasVoted: false,
      createdAt: "2023-07-25T16:30:00Z",
    },
    {
      id: "6",
      title: "Improve Search Functionality",
      description: "Enhance the global search to include more fields and provide better results.",
      type: "improvement",
      status: "in-progress",
      votes: 31,
      hasVoted: false,
      createdAt: "2023-06-28T13:10:00Z",
    },
    {
      id: "7",
      title: "Calendar Integration with Google",
      description: "Add two-way sync with Google Calendar for appointments.",
      type: "feature",
      status: "completed",
      votes: 45,
      hasVoted: true,
      createdAt: "2023-05-05T08:45:00Z",
    },
    {
      id: "8",
      title: "Dark Mode Support",
      description: "Add a dark mode option for the entire application.",
      type: "improvement",
      status: "planned",
      votes: 37,
      hasVoted: false,
      createdAt: "2023-08-15T10:00:00Z",
    },
  ];
} 