import { useWebSocket } from '@/contexts/WebSocketContext';
import { SocketEvents } from '@/services/websocket.service';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';

/**
 * WebSocketDataSync component
 * 
 * This component subscribes to various WebSocket events and updates the React Query cache
 * instead of relying on polling. This significantly reduces API calls and improves performance.
 * 
 * This component is invisible (returns null) and should be included once in the app layout.
 */
export function WebSocketDataSync() {
  const { on, connectionStatus } = useWebSocket();
  const queryClient = useQueryClient();
  const unsubscribeFunctionsRef = useRef<(() => void)[]>([]);
  const isSubscribedRef = useRef<boolean>(false);

  useEffect(() => {
    // Only set up subscriptions if we're connected and haven't already subscribed
    if (connectionStatus === 'connected' && !isSubscribedRef.current) {
      console.log('[WebSocketDataSync] Setting up data update subscriptions');
      
      const unsubscribeFunctions = setupSubscriptions();
      unsubscribeFunctionsRef.current = unsubscribeFunctions;
      isSubscribedRef.current = true;
    }

    return () => {
      // Clean up subscriptions when component unmounts
      if (unsubscribeFunctionsRef.current.length > 0) {
        console.log('[WebSocketDataSync] Cleaning up subscriptions');
        unsubscribeFunctionsRef.current.forEach(unsubscribe => unsubscribe());
        unsubscribeFunctionsRef.current = [];
        isSubscribedRef.current = false;
      }
    };
  }, [connectionStatus]);

  const setupSubscriptions = () => {
    const unsubscribeFunctions: (() => void)[] = [];

    // Subscribe to team invitation updates
    const unsubscribeTeamInvitations = on<{
      invitationId: number;
      teamId: number;
      teamName: string;
      action: 'created' | 'accepted' | 'declined' | 'canceled';
    }>(SocketEvents.TEAM_INVITATION_UPDATE, (data) => {
      console.log('[WebSocketDataSync] Team invitation update:', data);
      
      // Invalidate team invitations query to refresh the data
      queryClient.invalidateQueries({ queryKey: ['teams', 'invitations'] });
    });
    unsubscribeFunctions.push(unsubscribeTeamInvitations);

    // Subscribe to call history updates
    const unsubscribeCallHistory = on<{
      leadId: number;
      callId: number;
      action: 'created' | 'updated' | 'deleted';
    }>(SocketEvents.CALL_HISTORY_UPDATE, (data) => {
      console.log('[WebSocketDataSync] Call history update:', data);
      
      // Invalidate call history queries
      if (data.leadId) {
        queryClient.invalidateQueries({ queryKey: ['calls', data.leadId] });
      }
      
      // Also invalidate the specific lead data
      queryClient.invalidateQueries({ queryKey: ['leads', data.leadId] });
    });
    unsubscribeFunctions.push(unsubscribeCallHistory);

    // Subscribe to lead updates
    const unsubscribeLeadUpdates = on<{
      leadId: number;
      action: 'created' | 'updated' | 'deleted';
    }>(SocketEvents.LEAD_UPDATE, (data) => {
      console.log('[WebSocketDataSync] Lead update:', data);
      
      // Invalidate specific lead query
      queryClient.invalidateQueries({ queryKey: ['leads', data.leadId] });
      
      // Also invalidate the leads list
      queryClient.invalidateQueries({ queryKey: ['leads'] });
    });
    unsubscribeFunctions.push(unsubscribeLeadUpdates);

    // Subscribe to conversation updates
    const unsubscribeConversationUpdates = on<{
      conversationId: number;
      leadId?: number;
      action: 'created' | 'updated' | 'new-message';
    }>(SocketEvents.CONVERSATION_UPDATE, (data) => {
      console.log('[WebSocketDataSync] Conversation update:', data);
      
      // Invalidate conversation queries
      queryClient.invalidateQueries({ queryKey: ['conversations', data.conversationId] });
      
      // If we have a lead ID, also invalidate lead-specific conversation queries
      if (data.leadId) {
        queryClient.invalidateQueries({ queryKey: ['conversations', 'lead', data.leadId] });
      }
    });
    unsubscribeFunctions.push(unsubscribeConversationUpdates);

    return unsubscribeFunctions;
  };

  // This component doesn't render anything
  return null;
} 