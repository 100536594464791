import { ActionCenter } from '@/components/dashboard/ActionCenter';
import { ActivityFeed } from '@/components/dashboard/ActivityFeed';
import { CommunicationMetrics } from '@/components/dashboard/CommunicationMetrics';
import { DateRangeSelector, DateRangeValue } from '@/components/dashboard/DateRangeSelector';
import { ExportOptions, ExportReportDialog } from '@/components/dashboard/ExportReportDialog';
import { MetricCard } from '@/components/dashboard/MetricCard';
import { TeamLeaderboard } from '@/components/dashboard/TeamLeaderboard';
import { MainLayout } from '@/components/layout/MainLayout';
import { PageContainer } from '@/components/layout/PageContainer';
import { Card, CardContent } from '@/components/ui/card';
import { Skeleton } from '@/components/ui/skeleton';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useDashboardData } from '@/hooks/useDashboardData';
import { api } from '@/lib/api';
import { useActionItems, useRecentActivity } from '@/services/dashboard';
import { useNavigate } from '@tanstack/react-router';
import { useEffect, useRef, useState } from 'react';
import { DateRange } from 'react-day-picker';
import { toast } from 'sonner';

export function DashboardPage() {
  const navigate = useNavigate();
  const [timeframe, setTimeframe] = useState<DateRangeValue>('today');
  const [customDateRange, setCustomDateRange] = useState<{ startDate: Date; endDate: Date } | null>(null);
  const [dateRange, setDateRange] = useState<DateRange | undefined>(undefined);
  const [showCompletedItems, setShowCompletedItems] = useState(false);
  const { data, isLoading } = useDashboardData(customDateRange ? 'custom' : timeframe, customDateRange);
  const containerRef = useRef<HTMLDivElement>(null);
  const [gridHeight, setGridHeight] = useState<number | null>(null);
  const [isMobile, setIsMobile] = useState(false);
  
  const { 
    data: actionItemsData, 
    isLoading: isLoadingActionItems,
    fetchNextPage: fetchNextActionItems,
    hasNextPage: hasNextActionItems,
    isFetchingNextPage: isFetchingNextActionItems
  } = useActionItems(showCompletedItems);
  
  const {
    data: activityData,
    isLoading: isLoadingActivity,
    fetchNextPage: fetchNextActivity,
    hasNextPage: hasNextActivity,
    isFetchingNextPage: isFetchingNextActivity
  } = useRecentActivity();

  // Check if we're on mobile
  useEffect(() => {
    const checkIfMobile = () => {
      const isMobileView = window.innerWidth < 768; // md breakpoint
      setIsMobile(isMobileView);
    };
    
    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);
    
    return () => {
      window.removeEventListener('resize', checkIfMobile);
    };
  }, []);

  // Calculate available height for the grid
  useEffect(() => {
    const calculateHeight = () => {
      if (!containerRef.current) return;
      
      const containerRect = containerRef.current.getBoundingClientRect();
      const viewportHeight = window.innerHeight;
      const topOffset = containerRect.top;
      const bottomPadding = 20; // Add some padding at the bottom
      
      // Calculate available height
      const availableHeight = viewportHeight - topOffset - bottomPadding;
      
      // Set minimum height for mobile
      const minHeight = window.innerWidth < 768 ? 400 : 400;
      
      setGridHeight(Math.max(availableHeight, minHeight));
    };
    
    // Only calculate height for desktop
    if (!isMobile) {
      calculateHeight();
      window.addEventListener('resize', calculateHeight);
      
      return () => {
        window.removeEventListener('resize', calculateHeight);
      };
    } else {
      // For mobile, set a fixed height
      setGridHeight(null);
    }
  }, [isMobile]);

  const handleLeadClick = (leadId: number) => {
    navigate({ to: '/leads', search: { leadId: leadId.toString() } });
  };

  const handleTimeframeChange = (value: DateRangeValue) => {
    setTimeframe(value);
    if (value !== 'custom') {
      setCustomDateRange(null);
      setDateRange(undefined);
    }
  };

  const handleCustomDateChange = (startDate: Date, endDate: Date) => {
    setCustomDateRange({ startDate, endDate });
  };

  // Handle date range picker changes
  const handleDateRangeChange = (range: DateRange | undefined) => {
    setDateRange(range);
    if (range?.from && range?.to) {
      setTimeframe('custom');
      setCustomDateRange({ 
        startDate: range.from, 
        endDate: range.to 
      });
    }
  };

  const handleExportReport = async (options: ExportOptions) => {
    try {
      // Show loading toast
      const loadingToast = toast.loading(
        options.emailTo 
          ? 'Sending report to your email...' 
          : 'Preparing your report for download...'
      );
      
      // If email is provided, send the report via email
      if (options.emailTo) {
        const result = await api.dashboard.exportReport(options);
        
        // Dismiss loading toast
        toast.dismiss(loadingToast);
        
        if (result.success) {
          toast.success(`Report sent to ${options.emailTo}. Please check your inbox.`);
        } else {
          toast.error(`Failed to send report: ${result.message || 'Unknown error'}`);
          
          // Offer to download instead
          const downloadInstead = window.confirm(
            'Would you like to download the report instead?'
          );
          
          if (downloadInstead) {
            // Try to download the report directly
            const downloadOptions = { ...options, emailTo: '' };
            const downloadResult = await api.dashboard.exportReport(downloadOptions);
            
            if (!downloadResult.success) {
              toast.error(`Failed to download report: ${downloadResult.message || 'Unknown error'}`);
            }
          }
        }
      } else {
        // Otherwise, trigger a file download
        const result = await api.dashboard.exportReport(options);
        
        // Dismiss loading toast
        toast.dismiss(loadingToast);
        
        if (result.success) {
          toast.success('Report downloaded successfully');
        } else {
          toast.error(`Failed to download report: ${result.message || 'Unknown error'}`);
        }
      }
    } catch (error) {
      console.error('Error exporting report:', error);
      
      // Provide more specific error message if available
      const errorMessage = error instanceof Error 
        ? error.message 
        : 'An unexpected error occurred';
        
      toast.error(`Failed to export report: ${errorMessage}`);
    }
  };

  const handleToggleShowCompleted = (show: boolean) => {
    setShowCompletedItems(show);
  };

  // Flatten action items from all pages
  const allActionItems = actionItemsData?.pages.flatMap(page => page.actionItems) || [];
  const hasCompletedItems = actionItemsData?.pages[0]?.hasCompletedItems || false;
  
  // Flatten activity items from all pages
  const allActivityItems = activityData?.pages.flatMap(page => page.recentActivity) || [];

  // Render loading skeletons for metrics
  const renderMetricSkeletons = () => (
    <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-4 p-4 bg-white">
      {[...Array(4)].map((_, i) => (
        <Card key={i}>
          <CardContent className="p-6">
            <Skeleton className="h-4 w-1/2 mb-2" />
            <Skeleton className="h-8 w-1/3" />
            <Skeleton className="h-4 w-2/3 mt-2" />
          </CardContent>
        </Card>
      ))}
    </div>
  );

  return (
    <MainLayout>
      <PageContainer
        title="Dashboard"
        description="Your sales activity at a glance"
      >
        <div className="flex flex-col h-full">
          {/* Performance Insights */}
          <div className="mt-16 sm:mt-0">
            <Tabs defaultValue="overview" className="w-full bg-white">
              <div className="flex flex-col md:flex-row justify-between items-start md:items-center px-4 pt-4">
                <TabsList>
                  <TabsTrigger 
                    value="overview" 
                    className="flex items-center gap-2"
                  >
                    Lead Metrics
                  </TabsTrigger>
                  <TabsTrigger 
                    value="communication" 
                    className="flex items-center gap-2"
                  >
                    Communication
                  </TabsTrigger>
                  <TabsTrigger 
                    value="team" 
                    className="flex items-center gap-2"
                  >
                    Team Comparison
                  </TabsTrigger>
                </TabsList>
                
                <div className="flex items-center gap-4 mt-4 md:mt-0 w-full md:w-auto">
                  <DateRangeSelector 
                    mode="dashboard"
                    initialValue={timeframe} 
                    onChange={handleTimeframeChange}
                    onCustomDateChange={handleCustomDateChange}
                    initialCustomRange={dateRange}
                    onDateRangeChange={handleDateRangeChange}
                  />
                  <ExportReportDialog
                    timeframe={timeframe}
                    customDateRange={customDateRange}
                    onExport={handleExportReport}
                  />
                </div>
              </div>

              <TabsContent value="overview" className="mt-0">
                {/* Today's Metrics */}
                {isLoading ? (
                  renderMetricSkeletons()
                ) : (
                  <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-4 p-4 bg-white">
                    {data?.todayMetrics.map((metric) => (
                      <MetricCard key={metric.name} metric={metric} />
                    ))}
                  </div>
                )}
              </TabsContent>
              
              <TabsContent value="communication" className="mt-0">
                {isLoading ? (
                  <Card className="border border-gray-100 shadow-none rounded-none">
                    <CardContent className="p-6">
                      <div className="space-y-4">
                        <Skeleton className="h-8 w-1/3" />
                        <Skeleton className="h-[200px] w-full" />
                        <Skeleton className="h-8 w-1/2" />
                        <Skeleton className="h-[200px] w-full" />
                      </div>
                    </CardContent>
                  </Card>
                ) : (
                  data?.communicationMetrics && (
                    <CommunicationMetrics metrics={data.communicationMetrics} />
                  )
                )}
              </TabsContent>
              
              <TabsContent value="team" className="mt-0">
                {isLoading ? (
                  <Card className="border border-gray-100 shadow-none rounded-none">
                    <CardContent className="p-6">
                      <div className="space-y-4">
                        <Skeleton className="h-8 w-1/3" />
                        <Skeleton className="h-[300px] w-full" />
                      </div>
                    </CardContent>
                  </Card>
                ) : (
                  data?.teamMembers && data?.currentUserId ? (
                    <TeamLeaderboard 
                      members={data.teamMembers} 
                      currentUserId={data.currentUserId} 
                    />
                  ) : (
                    <Card className="border border-gray-100 shadow-none rounded-none">
                      <CardContent className="text-center py-12 text-gray-500">
                        <p>Team comparison data will be available soon</p>
                      </CardContent>
                    </Card>
                  )
                )}
              </TabsContent>
            </Tabs>
          </div>

          {/* Action Center and Activity Feed */}
          <div 
            ref={containerRef}
            className="grid md:grid-cols-2 gap-4 px-4 mt-0 flex-1 bg-white pb-4"
            style={{ 
              height: !isMobile && gridHeight ? `${gridHeight}px` : 'auto',
              minHeight: isMobile ? 'auto' : '400px'
            }}
          >
            {/* Left Column: Action Center */}
            <ActionCenter
              items={allActionItems}
              hasCompletedItems={hasCompletedItems}
              onItemClick={handleLeadClick}
              onViewAll={() => navigate({ to: '/dashboard' })}
              onToggleShowCompleted={handleToggleShowCompleted}
              showCompleted={showCompletedItems}
              fetchNextPage={fetchNextActionItems}
              hasNextPage={hasNextActionItems}
              isFetchingNextPage={isFetchingNextActionItems}
              isLoading={isLoadingActionItems}
            />
              
            {/* Right Column: Activity Feed */}
            <ActivityFeed
              items={allActivityItems}
              onItemClick={handleLeadClick}
              onViewAll={() => navigate({ to: '/dashboard' })}
              isLoading={isLoadingActivity}
              fetchNextPage={fetchNextActivity}
              hasNextPage={hasNextActivity}
              isFetchingNextPage={isFetchingNextActivity}
            />
          </div>
        </div>
      </PageContainer>
    </MainLayout>
  );
} 