import { FeedbackForm } from "@/components/feedback/FeedbackForm";
import { RoadmapList } from "@/components/feedback/RoadmapList";
import { MainLayout } from "@/components/layout/MainLayout";
import { PageContainer } from "@/components/layout/PageContainer";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useRoadmapItems, useVoteForItem } from "@/services/feedback";
import { useEffect, useState } from "react";

export function FeedbackPage() {
  const { data: roadmapItems, isLoading } = useRoadmapItems();
  const voteForItemMutation = useVoteForItem();
  const [isMobile, setIsMobile] = useState(false);

  // Check if we're on mobile
  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth < 768); // md breakpoint
    };
    
    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);
    
    return () => {
      window.removeEventListener('resize', checkIfMobile);
    };
  }, []);

  const handleVote = (id: string) => {
    voteForItemMutation.mutate(id);
  };

  return (
    <MainLayout pageTitle="Feedback & Roadmap">
      <PageContainer>
        <div className="container py-20 sm:py-6">
          <div className="flex flex-col space-y-2">
            <h1 className="hidden sm:block text-3xl font-bold tracking-tight">Feedback & Roadmap</h1>
            <p className="text-muted-foreground">
              Submit your feedback or feature requests and see what's coming next.
            </p>
          </div>

          <Tabs defaultValue="roadmap" className="w-full mt-6">
            <TabsList className="grid w-full grid-cols-2 mb-4 sm:mb-8">
              <TabsTrigger value="roadmap" className="text-sm sm:text-base">Product Roadmap</TabsTrigger>
              <TabsTrigger value="feedback" className="text-sm sm:text-base">Submit Feedback</TabsTrigger>
            </TabsList>
            
            <TabsContent value="roadmap" className="">
              {isLoading ? (
                <div className="flex justify-center items-center py-12">
                  <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
                </div>
              ) : roadmapItems ? (
                <RoadmapList 
                  items={roadmapItems} 
                  onVote={handleVote} 
                />
              ) : (
                <div className="text-center py-12">
                  <p className="text-gray-500">No roadmap items found</p>
                </div>
              )}
            </TabsContent>
            
            <TabsContent value="feedback" className="space-y-4">
              <div className="w-full max-w-2xl mx-auto">
                <FeedbackForm />
              </div>
            </TabsContent>
          </Tabs>
        </div>
      </PageContainer>
    </MainLayout>
  );
} 