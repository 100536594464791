import { Button } from '../../components/ui/button';
import { PlanAction, Product } from './types';
import { formatPrice, isComingSoon, isPopular } from './utils';

interface VipPlanCardProps {
  product: Product;
  planAction: PlanAction;
  onSubscribe: (priceId: string) => void;
}

export function VipPlanCard({ product, planAction, onSubscribe }: VipPlanCardProps) {
  const defaultPriceId = product.default_price;
  const comingSoon = isComingSoon(product);
  const popular = isPopular(product);
  
  // Format the title and description
  let displayTitle = product.name;
  let displayDescription = '';
  
  // Extract the VIP level (Bronze, Silver, Gold, etc.)
  let vipLevel = '';
  if (product.name.startsWith('VIP Bronze')) vipLevel = 'Bronze';
  else if (product.name.startsWith('VIP Silver')) vipLevel = 'Silver';
  else if (product.name.startsWith('VIP Gold')) vipLevel = 'Gold';
  else if (product.name.startsWith('VIP Platinum')) vipLevel = 'Platinum';
  else if (product.name.startsWith('VIP Diamond Plus')) vipLevel = 'Diamond Plus';
  else if (product.name.startsWith('VIP Diamond')) vipLevel = 'Diamond';
  
  // If the name contains a dash, split it and use the part after the dash as the description
  const dashIndex = product.name.indexOf(' - ');
  if (dashIndex > 0) {
    displayTitle = `VIP ${vipLevel}`;
    displayDescription = product.name.substring(dashIndex + 3);
  }
  
  // Determine which base plan is included
  let includedPlan = '';
  if (vipLevel === 'Bronze' || vipLevel === 'Silver') {
    includedPlan = 'Includes Lead Scout Plan';
  } else if (vipLevel === 'Gold') {
    includedPlan = 'Includes Lead Hunter Plan';
  } else if (vipLevel === 'Platinum' || vipLevel === 'Diamond' || vipLevel === 'Diamond Plus') {
    includedPlan = 'Includes Lead Master Plan';
  }
  
  return (
    <div 
      className={`rounded-xl overflow-hidden ${
        planAction === 'current' 
          ? "border-2 border-primary shadow-lg relative" 
          : popular 
          ? "border-2 border-primary shadow-lg relative" 
          : "border border-gray-200"
      }`}
    >
      {popular && planAction !== 'current' && (
        <div className="bg-primary text-white text-center py-1 text-sm font-medium">
          Most Popular
        </div>
      )}
      {planAction === 'current' && (
        <div className="bg-green-600 text-white text-center py-1 text-sm font-medium">
          Current Plan
        </div>
      )}
      <div className="bg-white p-5">
        <h3 className="text-lg font-bold mb-2">{displayTitle}</h3>
        <div className="flex items-baseline mb-3">
          {product.priceData && (
            <>
              <span className="text-2xl font-bold">
                {formatPrice(product.priceData.unit_amount, product.priceData.currency)}
              </span>
              <span className="text-sm text-gray-600 ml-1">
                /{product.priceData.recurring?.interval}
              </span>
            </>
          )}
        </div>
        {displayDescription && (
          <p className="text-sm text-gray-600 mb-2">{displayDescription}</p>
        )}
        {includedPlan && (
          <p className="text-sm text-primary font-medium mb-4">{includedPlan}</p>
        )}
        <Button 
          className={`w-full text-sm py-1.5 h-auto ${
            planAction === 'current' 
              ? "bg-green-600 hover:bg-green-700" 
              : planAction === 'upgrade' 
                ? "bg-blue-600 hover:bg-blue-700" 
                : planAction === 'downgrade' 
                  ? "bg-orange-600 hover:bg-orange-700" 
                  : popular 
                    ? "" 
                    : "bg-gray-800 hover:bg-gray-700"
          }`}
          onClick={() => onSubscribe(defaultPriceId)}
          disabled={!defaultPriceId || comingSoon || planAction === 'current'}
          variant={comingSoon ? "outline" : "default"}
        >
          {comingSoon 
            ? "Coming Soon" 
            : planAction === 'current' 
              ? "Current Plan" 
              : planAction === 'upgrade' 
                ? "Upgrade Plan" 
                : planAction === 'downgrade' 
                  ? "Downgrade Plan" 
                  : "Subscribe Now"}
        </Button>
      </div>
    </div>
  );
} 