import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { Button } from '@/components/ui/button';
import { api } from '@/lib/api';
import { AlertCircle, Loader2 } from 'lucide-react';
import { useEffect, useState } from 'react';
import { toast } from 'sonner';
import { SMSChat } from '../SMSChat';

interface SMSTabProps {
  phoneNumber: string;
  fromNumber: string | null;
  className?: string;
}

export function SMSTab({
  phoneNumber,
  fromNumber,
  className
}: SMSTabProps) {
  const [isChecking, setIsChecking] = useState(true);
  const [canReceiveSMS, setCanReceiveSMS] = useState<boolean | null>(null);
  const [phoneType, setPhoneType] = useState<string | null>(null);
  const [skipLookup, setSkipLookup] = useState(false);
  
  useEffect(() => {
    async function checkPhoneType() {
      if (!phoneNumber) return;
      
      setIsChecking(true);
      try {
        const result = await api.twilio.checkPhoneLineType(phoneNumber);
        setCanReceiveSMS(result.canReceiveSMS);
        setPhoneType(result.lineTypeIntelligence?.type || null);
      } catch (error) {
        console.error('Error checking phone line type:', error);
        toast.error('Failed to check phone type. Assuming SMS is available.');
        setCanReceiveSMS(true); // Default to true to allow sending
      } finally {
        setIsChecking(false);
      }
    }
    
    checkPhoneType();
  }, [phoneNumber]);
  
  if (isChecking) {
    return (
      <div className="flex flex-col items-center justify-center h-full py-20">
        <Loader2 className="h-10 w-10 animate-spin mb-4 text-muted-foreground" />
        <p className="text-muted-foreground">Checking if this number can receive SMS...</p>
      </div>
    );
  }
  
  if (canReceiveSMS === false && !skipLookup) {
    return (
      <div className="flex flex-col p-4 h-full">
        <Alert variant="destructive" className="mb-4">
          <AlertCircle className="h-4 w-4" />
          <AlertTitle>Cannot Send SMS to this Number</AlertTitle>
          <AlertDescription>
            {phoneType ? (
              <>This appears to be a {phoneType} number which cannot receive SMS messages.</>
            ) : (
              <>This number cannot receive SMS messages.</>
            )}
          </AlertDescription>
        </Alert>
        
        <p className="text-sm text-muted-foreground mb-4">
          Only mobile phones and some VoIP services can receive text messages. Try calling this number instead.
        </p>
        
        <Button 
          variant="outline" 
          onClick={() => {
            setSkipLookup(true);
            setCanReceiveSMS(true);
          }} 
          className="self-start"
        >
          Send SMS Anyway
        </Button>
      </div>
    );
  }
  
  return (
    <div className="flex-1 overflow-hidden">
      <SMSChat 
        phoneNumber={phoneNumber} 
        fromNumber={fromNumber} 
        className={className || "h-full"}
        skipLookup={skipLookup}
      />
    </div>
  );
} 