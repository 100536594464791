import { Button } from "@/components/ui/button";
import { Card, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";
import { useTeamInvitations } from "@/hooks/useTeamInvitations";
import { formatDistanceToNow } from "date-fns";
import { Check, X } from "lucide-react";

export function TeamInvitationsList() {
  const { 
    invitations, 
    isLoading, 
    acceptInvitation, 
    declineInvitation,
    isAccepting,
    isDeclining
  } = useTeamInvitations();

  if (isLoading) {
    return (
      <div className="space-y-4">
        <Skeleton className="h-32 w-full" />
        <Skeleton className="h-32 w-full" />
      </div>
    );
  }

  if (invitations.length === 0) {
    return (
      <Card>
        <CardHeader>
          <CardTitle>No Invitations</CardTitle>
          <CardDescription>
            You don't have any pending team invitations.
          </CardDescription>
        </CardHeader>
      </Card>
    );
  }

  return (
    <div className="space-y-4">
      {invitations.map((invitation) => {
        // Check if this invitation is currently being processed
        const isProcessing = isAccepting || isDeclining;
        
        return (
          <Card key={invitation.id}>
            <CardHeader>
              <CardTitle>{invitation.teamName}</CardTitle>
              <CardDescription>
                You've been invited to join this team as a {invitation.role}.
                <div className="text-xs mt-1 text-gray-500">
                  Invited {formatDistanceToNow(new Date(invitation.createdAt), { addSuffix: true })}
                </div>
              </CardDescription>
            </CardHeader>
            <CardFooter className="flex justify-end gap-2">
              <Button
                variant="outline"
                size="sm"
                onClick={() => declineInvitation(invitation.id)}
                disabled={isProcessing}
              >
                <X className="h-4 w-4 mr-1" />
                Decline
              </Button>
              <Button
                size="sm"
                onClick={() => acceptInvitation(invitation.id)}
                disabled={isProcessing}
              >
                <Check className="h-4 w-4 mr-1" />
                Accept
              </Button>
            </CardFooter>
          </Card>
        );
      })}
    </div>
  );
} 