import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Checkbox } from '@/components/ui/checkbox';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { PageHeader } from '@/components/ui/page-header';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Skeleton } from '@/components/ui/skeleton';
import { Switch } from '@/components/ui/switch';
import { Webhook, WebhookEvent, createWebhook, deleteWebhook, getWebhookEvents, getWebhooks, testWebhook, toggleWebhook } from '@/utils/webhooksApi';
import { useEffect, useState } from 'react';
import { HiOutlineClipboardCopy, HiOutlinePlus, HiOutlineTrash } from 'react-icons/hi';
import { toast } from 'sonner';

export function WebhooksIntegrationPage() {
  // State for webhooks and events
  const [webhooks, setWebhooks] = useState<Webhook[]>([]);
  const [events, setEvents] = useState<WebhookEvent[]>([]);
  const [newWebhookUrl, setNewWebhookUrl] = useState('');
  const [selectedEvents, setSelectedEvents] = useState<string[]>(['lead.created']);
  const [copied, setCopied] = useState(false);
  const [selectedWebhookForTest, setSelectedWebhookForTest] = useState<string | null>(null);
  const [selectedEventForTest, setSelectedEventForTest] = useState<string>('lead.created');
  
  // Loading states
  const [isLoading, setIsLoading] = useState({
    webhooks: true,
    events: true,
    creating: false,
    deleting: false,
    toggling: false,
    testing: false
  });

  // Fetch webhooks and events on component mount
  useEffect(() => {
    fetchWebhooks();
    fetchEvents();
  }, []);

  // Fetch webhooks from API
  const fetchWebhooks = async () => {
    setIsLoading(prev => ({ ...prev, webhooks: true }));
    try {
      const result = await getWebhooks();
      if (result.success && result.webhooks) {
        setWebhooks(result.webhooks);
      } else {
        toast.error(result.message || 'Failed to fetch webhooks');
      }
    } catch (error) {
      console.error('Error fetching webhooks:', error);
      toast.error('An unexpected error occurred while fetching webhooks');
    } finally {
      setIsLoading(prev => ({ ...prev, webhooks: false }));
    }
  };

  // Fetch webhook events from API
  const fetchEvents = async () => {
    setIsLoading(prev => ({ ...prev, events: true }));
    try {
      const result = await getWebhookEvents();
      
      if (result.success && result.events) {
        setEvents(result.events);
        
        // If we have events, set the first event as the default for testing
        if (result.events.length > 0) {
          setSelectedEventForTest(result.events[0].id);
        }
      } else {
        toast.error(result.message || 'Failed to fetch webhook events');
      }
    } catch (error) {
      console.error('Error fetching webhook events:', error);
      toast.error('An unexpected error occurred while fetching webhook events');
    } finally {
      setIsLoading(prev => ({ ...prev, events: false }));
    }
  };

  // Add a new webhook
  const handleAddWebhook = async () => {
    if (!newWebhookUrl) {
      toast.error('Please enter a webhook URL');
      return;
    }
    
    if (selectedEvents.length === 0) {
      toast.error('Please select at least one event');
      return;
    }
    
    setIsLoading(prev => ({ ...prev, creating: true }));
    
    try {
      const result = await createWebhook({
        url: newWebhookUrl,
        events: [...selectedEvents], // Create a copy to avoid reference issues
        active: true
      });
      
      if (result.success && result.webhook) {
        // Make sure the webhook has the correct events array
        const newWebhook: Webhook = {
          id: typeof result.webhook.id === 'string' ? result.webhook.id : String(result.webhook.id),
          url: result.webhook.url,
          active: result.webhook.active,
          // Ensure events is an array and matches what was selected
          events: Array.isArray(result.webhook.events) 
            ? [...result.webhook.events] 
            : [...selectedEvents],
          createdAt: result.webhook.createdAt,
          updatedAt: result.webhook.updatedAt
        };
        
        setWebhooks([...webhooks, newWebhook]);
        setNewWebhookUrl('');
        setSelectedEvents(['lead.created']);
        toast.success('Webhook created successfully');
        
        // Refresh webhooks to ensure we have the latest data
        setTimeout(() => {
          fetchWebhooks();
        }, 500);
      } else {
        toast.error(result.message || 'Failed to create webhook');
      }
    } catch (error) {
      console.error('Error creating webhook:', error);
      toast.error('An unexpected error occurred while creating the webhook');
    } finally {
      setIsLoading(prev => ({ ...prev, creating: false }));
    }
  };

  // Delete a webhook
  const handleDeleteWebhook = async (id: string) => {
    setIsLoading(prev => ({ ...prev, deleting: true }));
    
    try {
      const result = await deleteWebhook(id);
      
      if (result.success) {
        // Remove the webhook from the state
        setWebhooks(webhooks.filter(webhook => webhook.id !== id));
        toast.success('Webhook deleted successfully');
      } else {
        toast.error(result.message || 'Failed to delete webhook');
        
        // If the webhook wasn't found, it might have been deleted already
        // or there might be an issue with ID conversion, so refresh the list
        if (result.message === 'Webhook not found') {
          fetchWebhooks();
        }
      }
    } catch (error) {
      console.error('Error deleting webhook:', error);
      toast.error('An unexpected error occurred while deleting the webhook');
      
      // Refresh the webhooks list to ensure it's up to date
      fetchWebhooks();
    } finally {
      setIsLoading(prev => ({ ...prev, deleting: false }));
    }
  };

  // Toggle a webhook's active status
  const handleToggleWebhook = async (id: string, active: boolean) => {
    setIsLoading(prev => ({ ...prev, toggling: true }));
    
    try {
      const result = await toggleWebhook(id, active);
      
      if (result.success && result.webhook) {
        setWebhooks(webhooks.map(webhook => 
          webhook.id === id ? { ...webhook, active } : webhook
        ));
        toast.success(`Webhook ${active ? 'activated' : 'deactivated'} successfully`);
      } else {
        toast.error(result.message || `Failed to ${active ? 'activate' : 'deactivate'} webhook`);
      }
    } catch (error) {
      console.error('Error toggling webhook:', error);
      toast.error(`An unexpected error occurred while ${active ? 'activating' : 'deactivating'} the webhook`);
    } finally {
      setIsLoading(prev => ({ ...prev, toggling: false }));
    }
  };

  // Test a webhook
  const handleTestWebhook = async () => {
    if (!selectedWebhookForTest) {
      setSelectedWebhookForTest(webhooks[0]?.id || null);
      return;
    }
    
    setIsLoading(prev => ({ ...prev, testing: true }));
    
    try {
      const result = await testWebhook(selectedWebhookForTest, selectedEventForTest);
      
      if (result.success) {
        toast.success('Test webhook sent successfully');
      } else {
        toast.error(result.message || 'Failed to send test webhook');
      }
    } catch (error) {
      console.error('Error testing webhook:', error);
      toast.error('An unexpected error occurred while testing the webhook');
    } finally {
      setIsLoading(prev => ({ ...prev, testing: false }));
    }
  };

  // Copy text to clipboard
  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  // Toggle event selection for new webhook
  const toggleEventSelection = (eventId: string) => {
    if (selectedEvents.includes(eventId)) {
      setSelectedEvents(selectedEvents.filter(id => id !== eventId));
    } else {
      setSelectedEvents([...selectedEvents, eventId]);
    }
  };

  // Get event name by ID
  const getEventNameById = (eventId: string): string => {
    if (!events || events.length === 0) {
      return eventId;
    }
    
    const event = events.find(e => e.id === eventId);
    
    if (!event) {
      return eventId;
    }
    
    return event.name;
  };

  return (
    <div>
      <div className="flex items-start justify-between mt-8 sm:mt-4 md:mt-0">
        <PageHeader
          title="Webhooks"
          description="Send lead data to any custom endpoint with webhooks"
        />
        <img src="/assets/integrations/webhooks.svg" alt="Webhooks" className="h-16 w-16 rounded-lg" />
      </div>

      <div className="mt-8 grid gap-6 md:grid-cols-2">
        <div>
          <Card>
            <CardHeader>
              <CardTitle>About Webhooks</CardTitle>
              <CardDescription>
                Webhooks allow you to receive real-time notifications when events happen in LeadGPT
              </CardDescription>
            </CardHeader>
            <CardContent className="space-y-4">
              <p>
                Webhooks are a way for LeadGPT to send automatic notifications to your app when certain events occur. 
                Instead of having your application constantly poll our API for changes, we'll send HTTP POST requests to your 
                specified URL when events happen.
              </p>
              <div className="mt-4 space-y-2">
                <h4 className="font-medium">Available Events:</h4>
                {isLoading.events ? (
                  <div className="space-y-2">
                    <Skeleton className="h-4 w-full" />
                    <Skeleton className="h-4 w-full" />
                    <Skeleton className="h-4 w-full" />
                    <Skeleton className="h-4 w-full" />
                    <Skeleton className="h-4 w-full" />
                  </div>
                ) : (
                  <ul className="list-disc pl-5 space-y-1">
                    {events.map(event => (
                      <li key={event.id}>
                        <code className="text-sm bg-gray-100 px-1 py-0.5 rounded">{event.id}</code> - {event.description}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </CardContent>
          </Card>
        </div>

        <div>
          <Card>
            <CardHeader>
              <CardTitle>Your Webhooks</CardTitle>
              <CardDescription>
                Manage your webhook endpoints
              </CardDescription>
            </CardHeader>
            <CardContent className="space-y-4">
              {isLoading.webhooks ? (
                <div className="space-y-4">
                  <Skeleton className="h-24 w-full" />
                  <Skeleton className="h-24 w-full" />
                </div>
              ) : webhooks.length === 0 ? (
                <div className="text-center py-8">
                  <p className="text-gray-500">You don't have any webhooks yet</p>
                  <p className="text-sm text-gray-400 mt-1">Add your first webhook below</p>
                </div>
              ) : (
                <div className="space-y-4">
                  {webhooks.map(webhook => (
                    <div key={webhook.id} className="border rounded-lg p-4">
                      <div className="flex items-center justify-between">
                        <div className="font-medium truncate max-w-[200px]">{webhook.url}</div>
                        <div className="flex items-center gap-2">
                          <Switch 
                            checked={webhook.active} 
                            onCheckedChange={() => handleToggleWebhook(webhook.id, !webhook.active)}
                            disabled={isLoading.toggling}
                          />
                          <Button 
                            variant="ghost" 
                            size="sm"
                            onClick={() => handleDeleteWebhook(webhook.id)}
                            disabled={isLoading.deleting}
                          >
                            <HiOutlineTrash className="h-4 w-4 text-red-500" />
                          </Button>
                        </div>
                      </div>
                      <div className="mt-2 text-sm text-gray-600">
                        Events: {webhook.events.map(eventId => getEventNameById(eventId)).join(', ')}
                      </div>
                      <div className="mt-2 text-xs">
                        <span className={webhook.active ? 'text-green-600' : 'text-gray-500'}>
                          {webhook.active ? 'Active' : 'Inactive'}
                        </span>
                        {webhook.createdAt && (
                          <span className="text-gray-400 ml-2">
                            Created: {new Date(webhook.createdAt).toLocaleDateString()}
                          </span>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}

              <div className="mt-6">
                <h4 className="text-sm font-medium mb-2">Add New Webhook</h4>
                <div className="space-y-4">
                  <div>
                    <Label htmlFor="webhook-url" className="mb-1 block">Webhook URL</Label>
                    <div className="flex gap-2">
                      <Input
                        id="webhook-url"
                        placeholder="https://your-app.com/webhook"
                        value={newWebhookUrl}
                        onChange={(e) => setNewWebhookUrl(e.target.value)}
                      />
                      <Button 
                        onClick={handleAddWebhook}
                        disabled={isLoading.creating || !newWebhookUrl}
                      >
                        {isLoading.creating ? (
                          <div className="h-4 w-4 animate-spin rounded-full border-2 border-b-transparent border-white" />
                        ) : (
                          <HiOutlinePlus className="h-4 w-4" />
                        )}
                      </Button>
                    </div>
                  </div>
                  
                  <div>
                    <Label className="mb-1 block">Events to Subscribe</Label>
                    <div className="grid grid-cols-2 gap-2 mt-1">
                      {isLoading.events ? (
                        <>
                          <Skeleton className="h-6 w-full" />
                          <Skeleton className="h-6 w-full" />
                          <Skeleton className="h-6 w-full" />
                          <Skeleton className="h-6 w-full" />
                        </>
                      ) : (
                        events.map(event => (
                          <div key={event.id} className="flex items-center space-x-2">
                            <Checkbox 
                              id={`event-${event.id}`} 
                              checked={selectedEvents.includes(event.id)}
                              onCheckedChange={() => toggleEventSelection(event.id)}
                            />
                            <Label 
                              htmlFor={`event-${event.id}`}
                              className="text-sm cursor-pointer"
                            >
                              {event.name}
                            </Label>
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>

          <Card className="mt-6">
            <CardHeader>
              <CardTitle>Testing Your Webhook</CardTitle>
              <CardDescription>
                Send a test payload to your webhook endpoint
              </CardDescription>
            </CardHeader>
            <CardContent>
              <p className="mb-4 text-sm">
                You can send a test payload to your webhook endpoint to verify it's working correctly.
              </p>
              
              {webhooks.length > 0 && (
                <div className="space-y-4 mb-4">
                  <div>
                    <Label htmlFor="test-webhook" className="mb-1 block">Select Webhook</Label>
                    <Select
                      value={selectedWebhookForTest || ''}
                      onValueChange={setSelectedWebhookForTest}
                    >
                      <SelectTrigger id="test-webhook">
                        <SelectValue placeholder="Select a webhook" />
                      </SelectTrigger>
                      <SelectContent>
                        {webhooks.map(webhook => (
                          <SelectItem key={webhook.id} value={webhook.id}>
                            {webhook.url}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                  
                  <div>
                    <Label htmlFor="test-event" className="mb-1 block">Event Type</Label>
                    <Select
                      value={selectedEventForTest}
                      onValueChange={setSelectedEventForTest}
                    >
                      <SelectTrigger id="test-event">
                        <SelectValue placeholder="Select an event" />
                      </SelectTrigger>
                      <SelectContent>
                        {events.map(event => (
                          <SelectItem key={event.id} value={event.id}>
                            {event.name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                </div>
              )}
              
              <div className="mb-4">
                <h4 className="text-sm font-medium mb-2">Sample Payload:</h4>
                <div className="relative">
                  <pre className="bg-gray-50 p-3 rounded-md text-xs overflow-x-auto max-w-full whitespace-pre-wrap break-all">
{`{
  "event": "${selectedEventForTest || 'lead.created'}",
  "data": {
    "id": "lead_123456",
    "name": "John Doe",
    "email": "john@example.com",
    "phone": "+1234567890",
    "created_at": "${new Date().toISOString()}"
  }
}`}
                  </pre>
                  <Button
                    variant="ghost"
                    size="sm"
                    className="absolute right-2 top-2"
                    onClick={() => copyToClipboard(`{
  "event": "${selectedEventForTest || 'lead.created'}",
  "data": {
    "id": "lead_123456",
    "name": "John Doe",
    "email": "john@example.com",
    "phone": "+1234567890",
    "created_at": "${new Date().toISOString()}"
  }
}`)}
                  >
                    <HiOutlineClipboardCopy className="h-4 w-4" />
                  </Button>
                </div>
                {copied && (
                  <p className="text-xs text-green-600 mt-1">Copied to clipboard!</p>
                )}
              </div>
              <Button 
                onClick={handleTestWebhook}
                disabled={isLoading.testing || webhooks.length === 0 || !selectedWebhookForTest}
              >
                {isLoading.testing ? 'Sending...' : 'Send Test Webhook'}
              </Button>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
} 