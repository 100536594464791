import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Switch } from '@/components/ui/switch';
import { toast } from '@/components/ui/use-toast';
import { useUpdateNotificationPreferences } from '@/services/settings';
import { useAuthStore } from '@/store/authStore';
import { useEffect, useState } from 'react';

export function NotificationSettings() {
  const user = useAuthStore((state) => state.user);
  const setUser = useAuthStore((state) => state.setUser);
  const updateNotifications = useUpdateNotificationPreferences();
  const [preferences, setPreferences] = useState({
    notifyRealTime: user?.notifyRealTime ?? false,
    emailLeads: user?.emailLeads ?? false,
  });

  const handleToggle = async (field: keyof typeof preferences) => {
    try {
      const updatedPreferences = {
        ...preferences,
        [field]: !preferences[field],
      };
      
      await updateNotifications.mutateAsync(updatedPreferences);
      setPreferences(updatedPreferences);
      
      // Update the global user state with new preferences
      if (user) {
        setUser({
          ...user,
          ...updatedPreferences
        });
      }
      
      toast({
        title: 'Preferences updated',
        description: 'Your notification preferences have been updated.',
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: error instanceof Error ? error.message : 'Failed to update preferences. Please try again.',
        variant: 'destructive',
      });
    }
  };

  // Update local state when user changes
  useEffect(() => {
    if (user) {
      setPreferences({
        notifyRealTime: user.notifyRealTime,
        emailLeads: user.emailLeads,
      });
    }
  }, [user]);

  return (
    <Card>
      <CardHeader>
        <CardTitle>Notification Settings</CardTitle>
        <CardDescription>Manage how you receive notifications</CardDescription>
      </CardHeader>
      <CardContent className="space-y-6">
        <div className="flex items-center justify-between space-x-4">
          <div>
            <p className="font-medium text-gray-900">New Leads</p>
            <p className="text-sm text-gray-500">Get notified in real-time when you receive new leads</p>
          </div>
          <Switch
            checked={preferences.notifyRealTime}
            onCheckedChange={() => handleToggle('notifyRealTime')}
            aria-label="Toggle New Leads notifications"
            disabled={updateNotifications.isPending}
          />
        </div>

        <div className="flex items-center justify-between space-x-4">
          <div>
            <p className="font-medium text-gray-900">Email Leads</p>
            <p className="text-sm text-gray-500">Email all leads at the end of the day</p>
          </div>
          <Switch
            checked={preferences.emailLeads}
            onCheckedChange={() => handleToggle('emailLeads')}
            aria-label="Toggle Email Leads notifications"
            disabled={updateNotifications.isPending}
          />
        </div>
      </CardContent>
    </Card>
  );
} 