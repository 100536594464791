import { axiosInstance } from "@/lib/api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";

// Schema definitions
export const receptionistSettingsSchema = z.object({
  isEnabled: z.boolean(),
  workingHours: z.object({
    enabled: z.boolean(),
    schedule: z.array(
      z.object({
        day: z.enum(["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]),
        isActive: z.boolean(),
        startTime: z.string(),
        endTime: z.string()
      })
    )
  }),
  callForwarding: z.object({
    enabled: z.boolean(),
    afterRings: z.number().min(1).max(10),
    forwardToNumber: z.string().optional()
  }),
  voiceSettings: z.object({
    voiceId: z.string().default("nova"),
    voiceName: z.string().default("Sofia"),
    gender: z.enum(["male", "female"]).default("female"),
    style: z.enum(["professional", "friendly", "casual"]).default("professional"),
    speed: z.number().min(0.5).max(2.0).default(1.0)
  }).optional().default({
    voiceId: "nova",
    voiceName: "Nova",
    gender: "female",
    style: "professional",
    speed: 1.0
  }),
  activeTemplateId: z.string().optional()
});

export const assistantTemplateSchema = z.object({
  id: z.string().optional(),
  name: z.string().min(1, "Name is required"),
  description: z.string().optional(),
  greeting: z.string().min(1, "Greeting message is required"),
  capabilities: z.array(z.string()).min(1, "At least one capability is required"),
  fallbackMessage: z.string().min(1, "Fallback message is required"),
  leadQualificationQuestions: z.array(z.string()).min(1, "At least one qualification question is required"),
  appointmentSettings: z.object({
    canScheduleAppointments: z.boolean(),
    availabilityMessage: z.string().optional(),
    confirmationMessage: z.string().optional()
  }),
  customFields: z.array(
    z.object({
      name: z.string(),
      required: z.boolean()
    })
  ).optional()
});

export const callLogSchema = z.object({
  id: z.string(),
  userId: z.number(),
  phoneNumber: z.string(),
  toNumber: z.string().nullable(),
  duration: z.number().nullable(),
  startTime: z.string(),
  endTime: z.string().nullable(),
  status: z.enum(["completed", "in-progress", "failed", "missed"]),
  summary: z.string().nullable(),
  transcript: z.string().nullable(),
  keyPoints: z.array(z.string()).nullable(),
  leadScore: z.string().nullable(),
  recordingUrl: z.string().nullable(),
  actions: z.array(
    z.object({
      type: z.string(),
      description: z.string(),
      timestamp: z.string(),
      completed: z.boolean()
    })
  ).nullable(),
  createdAt: z.string(),
  updatedAt: z.string(),
  leadId: z.number().nullable(),
  leadName: z.string().nullable()
});

export const activeCallSchema = z.object({
  id: z.string(),
  callerName: z.string().nullable(),
  callerNumber: z.string(),
  status: z.enum(["connecting", "in-progress", "completed", "failed"]),
  startTime: z.string(),
  duration: z.number()
});

export const callTranscriptSchema = z.object({
  id: z.string(),
  phoneNumber: z.string(),
  transcript: z.string(),
  summary: z.string().nullable(),
  keyPoints: z.array(z.string()).nullable(),
  leadScore: z.string().nullable(),
  toNumber: z.string(),
  recordingUrl: z.string().nullable(),
  duration: z.number().nullable().default(0)
});

export const callLogsResponseSchema = z.object({
  calls: z.array(callLogSchema),
  pagination: z.object({
    page: z.number(),
    limit: z.number(),
    totalPages: z.number(),
    totalCount: z.number()
  })
});

// Types
export type ReceptionistSettings = z.infer<typeof receptionistSettingsSchema>;
export type AssistantTemplate = z.infer<typeof assistantTemplateSchema>;
export type CallLog = z.infer<typeof callLogSchema>;
export type ActiveCall = z.infer<typeof activeCallSchema>;
export type CallTranscript = z.infer<typeof callTranscriptSchema>;

// Query keys
export const aiReceptionistKeys = {
  all: ["aiReceptionist"] as const,
  settings: () => [...aiReceptionistKeys.all, "settings"] as const,
  templates: () => [...aiReceptionistKeys.all, "templates"] as const,
  template: (id: string) => [...aiReceptionistKeys.templates(), id] as const,
  calls: (params?: any) => [...aiReceptionistKeys.all, "calls", params] as const,
  call: (id: string) => [...aiReceptionistKeys.calls(), id] as const,
  callTranscript: (id: string) => [...aiReceptionistKeys.call(id), "transcript"] as const,
  metrics: () => [...aiReceptionistKeys.all, "metrics"] as const,
  activeCalls: () => [...aiReceptionistKeys.all, "activeCalls"] as const,
};

// API functions
export const aiReceptionistApi = {
  // Settings
  getSettings: async (): Promise<ReceptionistSettings> => {
    try {
      const response = await axiosInstance.get("/ai-receptionist/settings");
      return response.data;
    } catch (error) {
      console.error("Error fetching AI receptionist settings:", error);
      throw error;
    }
  },

  updateSettings: async (settings: ReceptionistSettings): Promise<ReceptionistSettings> => {
    try {
      const response = await axiosInstance.put("/ai-receptionist/settings", settings);
      return response.data;
    } catch (error) {
      console.error("Error updating AI receptionist settings:", error);
      throw error;
    }
  },

  // Templates
  getTemplates: async (): Promise<AssistantTemplate[]> => {
    try {
      const response = await axiosInstance.get("/ai-receptionist/templates");
      return response.data;
    } catch (error) {
      console.error("Error fetching AI receptionist templates:", error);
      throw error;
    }
  },

  getTemplate: async (id: string): Promise<AssistantTemplate> => {
    try {
      const response = await axiosInstance.get(`/ai-receptionist/templates/${id}`);
      return response.data;
    } catch (error) {
      console.error(`Error fetching AI receptionist template ${id}:`, error);
      throw error;
    }
  },

  createTemplate: async (template: AssistantTemplate): Promise<AssistantTemplate> => {
    try {
      const response = await axiosInstance.post("/ai-receptionist/templates", template);
      return response.data;
    } catch (error) {
      console.error("Error creating AI receptionist template:", error);
      throw error;
    }
  },

  updateTemplate: async (template: AssistantTemplate): Promise<AssistantTemplate> => {
    if (!template.id) {
      throw new Error("Template ID is required for update");
    }
    try {
      const response = await axiosInstance.put(`/ai-receptionist/templates/${template.id}`, template);
      return response.data;
    } catch (error) {
      console.error(`Error updating AI receptionist template ${template.id}:`, error);
      throw error;
    }
  },

  deleteTemplate: async (id: string): Promise<void> => {
    try {
      await axiosInstance.delete(`/ai-receptionist/templates/${id}`);
    } catch (error) {
      console.error(`Error deleting AI receptionist template ${id}:`, error);
      throw error;
    }
  },

  // Call Logs
  getCalls: async (params?: { page?: number; limit?: number; status?: string; startDate?: string; endDate?: string }) => {
    const { data } = await axiosInstance.get('/ai-receptionist/call-logs', { params });
    return callLogsResponseSchema.parse(data);
  },
  
  getCall: async (id: string) => {
    const { data } = await axiosInstance.get(`/ai-receptionist/call-logs/${id}`);
    return callLogSchema.parse(data);
  },
  
  getCallTranscript: async (id: string) => {
    try {
      const { data } = await axiosInstance.get(`/ai-receptionist/call-logs/${id}/transcript`);
      console.log("API response for transcript:", data);
      
      // Ensure we have a valid transcript object
      if (!data || typeof data !== 'object') {
        console.error("Invalid transcript data format:", data);
        throw new Error("Invalid transcript data format");
      }
      
      // Ensure transcript field exists (even if empty)
      if (data.transcript === undefined) {
        data.transcript = "";
      }
      
      return callTranscriptSchema.parse(data);
    } catch (error) {
      console.error(`Error fetching call transcript for ${id}:`, error);
      throw error;
    }
  },
  
  syncCallData: async (id: string) => {
    const { data } = await axiosInstance.post(`/ai-receptionist/call-logs/${id}/sync`);
    return data;
  },

  // Metrics
  getMetrics: async (params?: { startDate?: string; endDate?: string }): Promise<any> => {
    try {
      const response = await axiosInstance.get("/ai-receptionist/metrics", { params });
      return response.data;
    } catch (error) {
      console.error("Error fetching AI receptionist metrics:", error);
      throw error;
    }
  },

  // Voice AI Integration
  testCall: async (phoneNumber: string): Promise<{ callId: string }> => {
    try {
      const response = await axiosInstance.post("/ai-receptionist/test-call", { phoneNumber });
      return response.data;
    } catch (error) {
      console.error("Error initiating test call:", error);
      throw error;
    }
  },

  // Export call logs
  exportCallLogs: async (options: {
    startDate: Date | null;
    endDate: Date | null;
    includeTranscripts: boolean;
    emailTo: string;
  }) => {
    try {
      // Format dates properly for the API
      const formattedOptions = {
        ...options,
        startDate: options.startDate ? options.startDate.toISOString() : null,
        endDate: options.endDate ? options.endDate.toISOString() : null
      };
      
      console.log('Exporting AI Receptionist call logs with options:', formattedOptions);
      
      // If email is provided, use POST to send the report via email
      if (options.emailTo) {
        try {
          const { data } = await axiosInstance.post('/ai-receptionist/export', formattedOptions);
          return data;
        } catch (error: any) {
          console.error('Error sending report via email:', error);
          
          // Try to extract error message from response
          const errorMessage = error.response?.data?.message || error.message || 'Failed to send report via email';
          return { success: false, message: errorMessage };
        }
      }
      
      // For direct file download, use axios with responseType blob
      try {
        const response = await axiosInstance.post('/ai-receptionist/export', formattedOptions, {
          responseType: 'blob'
        });
        
        // Check if the response is JSON (error message)
        const contentType = response.headers['content-type'];
        if (contentType && contentType.includes('application/json')) {
          // Convert blob to text to read the error message
          const text = await new Response(response.data).text();
          const errorData = JSON.parse(text);
          console.error('Error in export response:', errorData);
          return { success: false, message: errorData.message || 'Export failed' };
        }
        
        // Create a download link for the blob
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        
        // Generate filename with timestamp
        const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
        const filename = `ai-receptionist-calls-${timestamp}.csv`;
        
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        
        // Clean up
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
        
        return { success: true, message: 'Report download initiated' };
      } catch (error: any) {
        console.error('Error downloading report:', error);
        
        // Try to extract error message from response
        let errorMessage = 'Failed to download report';
        
        if (error.response) {
          if (error.response.data instanceof Blob) {
            try {
              const text = await error.response.data.text();
              try {
                const errorData = JSON.parse(text);
                errorMessage = errorData.message || errorMessage;
              } catch (e) {
                errorMessage = text || errorMessage;
              }
            } catch (e) {
              console.error('Error parsing error blob:', e);
            }
          } else if (error.response.data?.message) {
            errorMessage = error.response.data.message;
          }
        } else if (error.message) {
          errorMessage = error.message;
        }
        
        return { success: false, message: errorMessage };
      }
    } catch (error: any) {
      console.error('Error in exportCallLogs:', error);
      return { success: false, message: error.message || 'Export failed' };
    }
  },
};

// React Query hooks
export const useReceptionistSettings = () => {
  return useQuery({
    queryKey: aiReceptionistKeys.settings(),
    queryFn: aiReceptionistApi.getSettings
  });
};

export const useUpdateReceptionistSettings = () => {
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: aiReceptionistApi.updateSettings,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: aiReceptionistKeys.settings() });
    }
  });
};

export const useAssistantTemplates = () => {
  return useQuery({
    queryKey: aiReceptionistKeys.templates(),
    queryFn: aiReceptionistApi.getTemplates
  });
};

export const useAssistantTemplate = (id: string) => {
  return useQuery({
    queryKey: aiReceptionistKeys.template(id),
    queryFn: () => aiReceptionistApi.getTemplate(id),
    enabled: !!id
  });
};

export const useCreateAssistantTemplate = () => {
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: aiReceptionistApi.createTemplate,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: aiReceptionistKeys.templates() });
    }
  });
};

export const useUpdateAssistantTemplate = () => {
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: aiReceptionistApi.updateTemplate,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: aiReceptionistKeys.templates() });
      queryClient.invalidateQueries({ queryKey: aiReceptionistKeys.template(data.id!) });
    }
  });
};

export const useDeleteAssistantTemplate = () => {
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: aiReceptionistApi.deleteTemplate,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: aiReceptionistKeys.templates() });
    }
  });
};

export const useCallLogs = (params?: { page?: number; limit?: number; status?: string; startDate?: string; endDate?: string }) => {
  return useQuery({
    queryKey: aiReceptionistKeys.calls(params),
    queryFn: () => aiReceptionistApi.getCalls(params),
    staleTime: 1000 * 60, // 1 minute
    refetchOnWindowFocus: true
  });
};

export const useCallLog = (id: string) => {
  return useQuery({
    queryKey: aiReceptionistKeys.call(id),
    queryFn: () => aiReceptionistApi.getCall(id),
    enabled: !!id
  });
};

export const useReceptionistMetrics = (params?: { startDate?: string; endDate?: string }) => {
  return useQuery({
    queryKey: [...aiReceptionistKeys.metrics(), params],
    queryFn: () => aiReceptionistApi.getMetrics(params),
    staleTime: 1000 * 60, // 1 minute
    refetchOnWindowFocus: true,
    refetchInterval: 1000 * 60 * 2 // Refetch every 2 minutes
  });
};

export const useTestCall = () => {
  return useMutation({
    mutationFn: aiReceptionistApi.testCall
  });
};

export const useCallTranscript = (callId: string) => {
  return useQuery({
    queryKey: aiReceptionistKeys.callTranscript(callId),
    queryFn: () => aiReceptionistApi.getCallTranscript(callId),
    enabled: !!callId,
    retry: 1,
    staleTime: 30000, // 30 seconds
    refetchOnWindowFocus: false
  });
};

export const useActiveCalls = () => {
  return useQuery({
    queryKey: aiReceptionistKeys.activeCalls(),
    queryFn: async () => {
      const response = await axiosInstance.get("/ai-receptionist/active-calls");
      const data = response.data;
      return {
        activeCalls: z.array(activeCallSchema).parse(data.activeCalls)
      };
    },
    refetchInterval: 5000, // Refetch every 5 seconds to keep the data fresh
  });
};

export const useSyncCallData = () => {
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: aiReceptionistApi.syncCallData,
    onSuccess: (_, callId) => {
      queryClient.invalidateQueries({ queryKey: aiReceptionistKeys.call(callId) });
      queryClient.invalidateQueries({ queryKey: aiReceptionistKeys.calls() });
    }
  });
};

// Export hook
export const useExportCallLogs = () => {
  return useMutation({
    mutationFn: aiReceptionistApi.exportCallLogs,
  });
};