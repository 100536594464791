import { Button } from "@/components/ui/button";
import { ArrowLeft, Download, FileText, Mail, Newspaper, Phone, Share2 } from "lucide-react";

export function Press() {
  return (
    <div className="min-h-screen bg-gray-50">
      <div className="container mx-auto px-4 py-12">
        {/* Back Button */}
        <a href="https://leadgpt.com" className="inline-block mb-8">
          <Button variant="ghost" className="gap-2">
            <ArrowLeft className="h-4 w-4" />
            Back to Home
          </Button>
        </a>

        <div className="max-w-6xl mx-auto">
          {/* Hero Section */}
          <div className="text-center mb-12">
            <h1 className="text-4xl font-bold mb-4">Press & Media</h1>
            <p className="text-lg text-gray-600 max-w-2xl mx-auto">
              Get the latest news about LeadGPT and find media resources for press coverage.
            </p>
          </div>

          {/* Press Contacts */}
          <div className="bg-white rounded-xl shadow-sm p-8 mb-12">
            <h2 className="text-2xl font-bold mb-6">Press Contacts</h2>
            <div className="grid md:grid-cols-2 gap-6">
              {[
                {
                  name: "Sarah Johnson",
                  title: "Head of Communications",
                  email: "press@leadgpt.com",
                  phone: "+1 (800) 123-4567 ext. 3"
                },
                {
                  name: "Michael Chen",
                  title: "Media Relations Manager",
                  email: "media@leadgpt.com",
                  phone: "+1 (800) 123-4567 ext. 4"
                }
              ].map((contact, index) => (
                <div key={index} className="bg-gray-50 p-6 rounded-lg">
                  <h3 className="font-bold text-lg mb-1">{contact.name}</h3>
                  <p className="text-gray-600 mb-4">{contact.title}</p>
                  <div className="space-y-2">
                    <p className="flex items-center gap-2 text-sm">
                      <Mail className="h-4 w-4 text-gray-500" />
                      {contact.email}
                    </p>
                    <p className="flex items-center gap-2 text-sm">
                      <Phone className="h-4 w-4 text-gray-500" />
                      {contact.phone}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Latest News */}
          <div className="mb-12">
            <h2 className="text-2xl font-bold mb-6">Latest News</h2>
            <div className="grid md:grid-cols-2 gap-8">
              {[
                {
                  date: "March 15, 2024",
                  title: "LeadGPT Raises $50M Series B to Transform Insurance Sales",
                  excerpt: "Leading AI company secures major funding to expand its innovative insurance sales platform globally.",
                  source: "TechCrunch"
                },
                {
                  date: "March 1, 2024",
                  title: "LeadGPT Named in Forbes AI 50 List",
                  excerpt: "Recognition as one of the most promising AI companies revolutionizing traditional industries.",
                  source: "Forbes"
                },
                {
                  date: "February 20, 2024",
                  title: "LeadGPT Launches New AI-Powered Features",
                  excerpt: "Revolutionary updates to help insurance agents close deals faster and more efficiently.",
                  source: "Insurance Journal"
                },
                {
                  date: "February 5, 2024",
                  title: "LeadGPT Expands to European Market",
                  excerpt: "Strategic expansion brings AI-powered insurance sales platform to European insurance agencies.",
                  source: "Reuters"
                }
              ].map((news, index) => (
                <div key={index} className="bg-white p-6 rounded-xl shadow-sm">
                  <div className="flex items-center gap-2 text-sm text-gray-500 mb-3">
                    <Newspaper className="h-4 w-4" />
                    <span>{news.source}</span>
                    <span>•</span>
                    <span>{news.date}</span>
                  </div>
                  <h3 className="text-xl font-bold mb-2">{news.title}</h3>
                  <p className="text-gray-600 mb-4">{news.excerpt}</p>
                  <div className="flex items-center gap-4">
                    <Button variant="outline" size="sm" className="gap-2">
                      <Share2 className="h-4 w-4" />
                      Share
                    </Button>
                    <Button variant="ghost" size="sm">
                      Read More
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Media Resources */}
          <div className="bg-white rounded-xl shadow-sm p-8">
            <h2 className="text-2xl font-bold mb-6">Media Resources</h2>
            <div className="grid md:grid-cols-3 gap-6">
              {[
                {
                  title: "Press Kit",
                  description: "Download our comprehensive press kit including logos, product screenshots, and executive headshots.",
                  size: "25 MB",
                  format: "ZIP"
                },
                {
                  title: "Brand Guidelines",
                  description: "Access our brand guidelines, including logo usage, color palette, and typography specifications.",
                  size: "10 MB",
                  format: "PDF"
                },
                {
                  title: "Company Fact Sheet",
                  description: "Get key statistics, milestones, and information about LeadGPT's mission and impact.",
                  size: "2 MB",
                  format: "PDF"
                }
              ].map((resource, index) => (
                <div key={index} className="bg-gray-50 p-6 rounded-lg">
                  <div className="w-12 h-12 rounded-lg bg-primary/10 flex items-center justify-center mb-4">
                    <FileText className="h-6 w-6 text-primary" />
                  </div>
                  <h3 className="font-bold mb-2">{resource.title}</h3>
                  <p className="text-sm text-gray-600 mb-4">{resource.description}</p>
                  <div className="flex items-center justify-between">
                    <span className="text-sm text-gray-500">
                      {resource.size} • {resource.format}
                    </span>
                    <Button variant="outline" size="sm" className="gap-2">
                      <Download className="h-4 w-4" />
                      Download
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* CTA */}
          <div className="mt-12 bg-primary text-white rounded-xl shadow-sm p-8 md:p-12 text-center">
            <h2 className="text-2xl md:text-3xl font-bold mb-4">Need More Information?</h2>
            <p className="text-lg mb-6 opacity-90">
              Our press team is ready to assist with any media inquiries or interview requests.
            </p>
            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <Button variant="secondary" size="lg" className="bg-white text-primary hover:bg-gray-100">
                Contact Press Team
              </Button>
              <Button variant="outline" size="lg" className="border-white text-white hover:bg-white/10">
                Subscribe to Updates
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
} 