import { Button } from '@/components/ui/button';
import { AnimatePresence, motion } from 'framer-motion';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { OnboardingStep as StepEnum } from './OnboardingContext';

interface OnboardingStepProps {
  step: StepEnum;
  currentStep: StepEnum;
  title: string;
  description: ReactNode;
  targetSelector: string;
  position?: 'top' | 'right' | 'bottom' | 'left';
  onNext: () => void;
  onPrev: () => void;
  onSkip: () => void;
  isFirstStep: boolean;
  isLastStep: boolean;
}

export function OnboardingStep({
  step,
  currentStep,
  title,
  description,
  targetSelector,
  position = 'bottom',
  onNext,
  onPrev,
  onSkip,
  isFirstStep,
  isLastStep,
}: OnboardingStepProps) {
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [targetElement, setTargetElement] = useState<Element | null>(null);
  const isActive = step === currentStep;

  // Handle target element and scrolling
  useEffect(() => {
    if (!isActive) return;

    const target = document.querySelector(targetSelector);
    if (!target) return;

    setTargetElement(target);

    // Scroll the target element into view with smooth behavior
    // Special handling for leaderboard section
    if (step === StepEnum.LEADERBOARD) {
      // For the leaderboard, we need to:
      // 1. Switch to team view if not already there
      const teamTabTrigger = document.querySelector('.team-personal-tabs [value="team"]') as HTMLElement;
      if (teamTabTrigger) {
        teamTabTrigger.click();
      }
      
      // 2. Scroll the leaderboard into view with a slight delay to ensure it's rendered
      setTimeout(() => {
        target.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 300);
    } else {
      // For other elements, just scroll them into view
      target.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
    
    // Add highlight to target element
    target.classList.add('onboarding-highlight');

    return () => {
      target.classList.remove('onboarding-highlight');
    };
  }, [isActive, targetSelector, step]);

  // If not active or no target element, don't render
  if (!isActive || !targetElement) return null;

  // Render tooltip using portal
  return createPortal(
    <AnimatePresence>
      {isActive && (
        <div className="fixed inset-0 z-50 flex items-center justify-center pointer-events-none">
          <motion.div
            ref={tooltipRef}
            className="bg-white rounded-lg shadow-lg p-4 max-w-md w-full pointer-events-auto"
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.9 }}
            transition={{ duration: 0.2 }}
          >
            <div className="flex flex-col gap-3">
              <div>
                <h3 className="font-semibold text-gray-900">{title}</h3>
                <div className="mt-1 text-sm text-gray-600">{description}</div>
              </div>
              
              <div className="flex justify-between items-center mt-2">
                <div className="flex gap-2">
                  {!isFirstStep && (
                    <Button 
                      variant="outline" 
                      size="sm" 
                      onClick={onPrev}
                    >
                      Back
                    </Button>
                  )}
                  <Button 
                    variant="outline" 
                    size="sm" 
                    onClick={onSkip}
                  >
                    Skip
                  </Button>
                </div>
                <Button 
                  size="sm" 
                  onClick={onNext}
                >
                  {isLastStep ? 'Finish' : 'Next'}
                </Button>
              </div>
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>,
    document.body
  );
} 