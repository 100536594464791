import { Button } from '@/components/ui/button';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { useWebSocket } from '@/contexts/WebSocketContext';
import { cn } from '@/lib/utils';
import { CallService } from '@/services/call.service';
import { ConnectionStatus, SocketEvents } from '@/services/websocket.service';
import { useCallStore } from '@/store/callStore';
import { useLeadStore } from '@/store/leadStore';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import {
  ChevronDown,
  ChevronUp,
  Keyboard,
  Mic, MicOff,
  Phone,
  PhoneCall, PhoneOff,
  User,
  X
} from 'lucide-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'sonner';

// Define custom CSS for slow animation
const slowPulseStyles = `
  @keyframes slow-pulse {
    0%, 100% { opacity: 1; }
    50% { opacity: 0.7; }
  }
  .animate-slow-pulse {
    animation: slow-pulse 2.5s ease-in-out infinite;
  }
  @keyframes slow-border-pulse {
    0%, 100% { border-color: rgba(59, 130, 246, 0.7); }
    50% { border-color: rgba(59, 130, 246, 0.3); }
  }
  .animate-slow-border-pulse {
    animation: slow-border-pulse 2.5s ease-in-out infinite;
  }
`;

// Add interface for dial pad key
interface DialPadKey {
  value: string;
  label: string;
}

export function WebSocketCallNotification() {
  const { on, connectionStatus } = useWebSocket();
  const [isDialpadOpen, setIsDialpadOpen] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [showControls, setShowControls] = useState(true); 
  const [callDuration, setCallDuration] = useState(0);
  const callTimerRef = useRef<NodeJS.Timeout | null>(null);
  const unsubscribeFunctionsRef = useRef<(() => void)[]>([]);
  const isSubscribedRef = useRef<boolean>(false);
  const instanceId = useRef<string>(Math.random().toString(36).substring(2, 9));
  const [isActiveInstance, setIsActiveInstance] = useState(true);
  const navigate = useNavigate();
  const { setSelectedLead } = useLeadStore();
  const queryClient = useQueryClient();

  // Use the call store
  const {
    deviceId,
    activeCall,
    handleIncomingCall,
    answerCall,
    hangupCall,
    updateActiveCall
  } = useCallStore();

  // Function to check if this instance should be active
  const checkIsActiveInstance = useCallback(() => {
    const instanceKey = 'call-notification-instance';
    const activeInstance = sessionStorage.getItem(instanceKey);
    
    // If no active instance is set, this one should be active
    if (!activeInstance) {
      sessionStorage.setItem(instanceKey, `${instanceId.current}-${Date.now()}`);
      setIsActiveInstance(true);
      return true;
    }
    
    // If this is already the active instance, keep it active
    if (activeInstance.startsWith(instanceId.current)) {
      return true;
    }
    
    // Check if the active instance timestamp is stale (> 10 seconds)
    const parts = activeInstance.split('-');
    const timestamp = parseInt(parts[parts.length - 1], 10);
    const isStale = Date.now() - timestamp > 10000;
    
    if (isStale) {
      // Take over as the active instance
      sessionStorage.setItem(instanceKey, `${instanceId.current}-${Date.now()}`);
      setIsActiveInstance(true);
      return true;
    }
    
    // Another instance is active and not stale
    setIsActiveInstance(false);
    return false;
  }, []);

  // Add CSS to the document head
  useEffect(() => {
    const styleEl = document.createElement('style');
    styleEl.id = `call-notification-styles-${instanceId.current}`;
    styleEl.textContent = slowPulseStyles;
    document.head.appendChild(styleEl);
    
    return () => {
      const existingStyle = document.getElementById(`call-notification-styles-${instanceId.current}`);
      if (existingStyle) {
        existingStyle.remove();
      }
    };
  }, []);

  // Make sure only one instance of this component is handling events
  useEffect(() => {
    const instanceKey = 'call-notification-instance';
    
    // Initial check
    const isActive = checkIsActiveInstance();
    
    // Check periodically if we're still the active instance
    const checkInterval = setInterval(() => {
      const stillActive = checkIsActiveInstance();
      
      if (!stillActive && isSubscribedRef.current) {
        console.log('[WebSocketCallNotification] Another instance is active, deactivating this one');
        cleanupSubscriptions();
      } else if (stillActive && !isSubscribedRef.current && connectionStatus === ConnectionStatus.CONNECTED) {
        console.log('[WebSocketCallNotification] This instance becoming active, setting up subscriptions');
        const unsubscribeFunctions = setupEventSubscriptions();
        unsubscribeFunctionsRef.current = unsubscribeFunctions;
        isSubscribedRef.current = true;
      }
    }, 2000);
    
    return () => {
      clearInterval(checkInterval);
      // If this was the active instance, clear the key
      const activeInstance = sessionStorage.getItem(instanceKey);
      if (activeInstance && activeInstance.startsWith(instanceId.current)) {
        sessionStorage.removeItem(instanceKey);
      }
    };
  }, [connectionStatus, checkIsActiveInstance]);

  // Listen for localStorage events from other tabs
  useEffect(() => {
    const handleStorage = (e: StorageEvent) => {
      if (e.key === 'call_ringing_device' && e.newValue && e.newValue !== deviceId) {
        console.log('[WebSocketCallNotification] Another device is ringing:', e.newValue);
      } else if (e.key === 'call_answered_device' && e.newValue) {
        console.log('[WebSocketCallNotification] Call answered by device:', e.newValue);
        // If another device answered, clear our notification
        if (e.newValue !== deviceId && activeCall?.status === 'ringing') {
          updateActiveCall({ status: 'in-progress' });
        }
      }
    };

    window.addEventListener('storage', handleStorage);
    return () => window.removeEventListener('storage', handleStorage);
  }, [deviceId, activeCall, updateActiveCall]);

  // Set up timer when call is connected
  useEffect(() => {
    if (activeCall?.status === 'in-progress') {
      // Start the timer
      if (!callTimerRef.current) {
        const startTime = Date.now();
        callTimerRef.current = setInterval(() => {
          const elapsed = Math.floor((Date.now() - startTime) / 1000);
          setCallDuration(elapsed);
        }, 1000);
      }
    } else {
      // Clear the timer
      if (callTimerRef.current) {
        clearInterval(callTimerRef.current);
        callTimerRef.current = null;
        setCallDuration(0);
      }
    }

    return () => {
      if (callTimerRef.current) {
        clearInterval(callTimerRef.current);
      }
    };
  }, [activeCall?.status]);

  // Cleanup subscriptions
  const cleanupSubscriptions = () => {
    if (unsubscribeFunctionsRef.current.length > 0) {
      console.log('[WebSocketCallNotification] Cleaning up existing subscriptions');
      unsubscribeFunctionsRef.current.forEach(unsubscribe => unsubscribe());
      unsubscribeFunctionsRef.current = [];
    }
    isSubscribedRef.current = false;
  };

  // Format the call duration into MM:SS
  const formatCallDuration = (seconds: number) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  // Setup all event subscriptions
  const setupEventSubscriptions = () => {
    console.log('[WebSocketCallNotification] Setting up event subscriptions');
    const unsubscribeFunctions: (() => void)[] = [];
    
    // Handle incoming call notifications
    const unsubscribeCall = on<{ 
      from: string; 
      to: string; 
      timestamp: string;
      leadId?: number;
      leadName?: string;
      callSid: string;
    }>(
      SocketEvents.INBOUND_CALL,
      (data) => {
        console.log('[WebSocketCallNotification] Incoming call:', data);
        handleIncomingCall({
          callSid: data.callSid,
          from: data.from,
          to: data.to,
          leadId: data.leadId,
          leadName: data.leadName
        });
      }
    );
    unsubscribeFunctions.push(unsubscribeCall);
    
    // Handle call status updates
    const unsubscribeCallStatus = on<{ 
      callSid: string; 
      status: string;
    }>(
      SocketEvents.CALL_STATUS_UPDATE,
      (data) => {
        console.log('[WebSocketCallNotification] Call status update:', data);
        
        // If this is our active call, handle its status
        if (activeCall?.callSid === data.callSid) {
          // List of terminal call states that should clear the notification
          const terminalStates = [
            'completed', 'failed', 'busy', 'no-answer', 'canceled', 'missed'
          ];
          
          // If the call status is a terminal state, update our state
          if (terminalStates.includes(data.status)) {
            console.log(`[WebSocketCallNotification] Call ${data.status}, clearing notification`);
            
            // Clear the notification by calling hangupCall
            hangupCall();
            
            // Ensure we update the dashboard to reflect the missed call
            if (['no-answer', 'canceled', 'missed'].includes(data.status)) {
              console.log('[WebSocketCallNotification] Call was missed, updating dashboard');
              // Invalidate dashboard queries to refresh data with the missed call
              queryClient.invalidateQueries({ queryKey: ['dashboard'] });
              queryClient.invalidateQueries({ queryKey: ['dashboard', 'action-items'] });
              queryClient.invalidateQueries({ queryKey: ['dashboard', 'recent-activity'] });
            }
          }
        }
      }
    );
    unsubscribeFunctions.push(unsubscribeCallStatus);
    
    // Handle call fallback events (when a call is forwarded to our Voice AI or voicemail)
    const unsubscribeCallFallback = on<{
      callSid: string;
      status: string;
      dialCallStatus?: string;
    }>(
      'call-fallback',
      (data) => {
        console.log('[WebSocketCallNotification] Call fallback event:', data);
        
        // If this is our active call, handle fallback
        if (activeCall?.callSid === data.callSid) {
          console.log('[WebSocketCallNotification] Call fallback event for active call:', data);
          
          // Show a toast message if the call was forwarded to our Voice AI
          if (data.status === 'forwarded-to-ai') {
            toast.info('Call forwarded to AI Receptionist');
          }
          
          // If this was a missed call, update the dashboard
          if (data.dialCallStatus === 'no-answer' || data.status === 'no-answer') {
            console.log('[WebSocketCallNotification] Call was missed, updating dashboard');
            // Clear the notification
            hangupCall();
            // Invalidate dashboard queries to refresh data with the missed call
            queryClient.invalidateQueries({ queryKey: ['dashboard'] });
            queryClient.invalidateQueries({ queryKey: ['dashboard', 'action-items'] });
            queryClient.invalidateQueries({ queryKey: ['dashboard', 'recent-activity'] });
          }
        }
      }
    );
    unsubscribeFunctions.push(unsubscribeCallFallback);
    
    console.log('[WebSocketCallNotification] Event subscriptions set up');
    return unsubscribeFunctions;
  };

  // Handle answering a call
  const handleAnswerCall = async () => {
    if (!activeCall) return;
    
    // Prevent answering if we're already in the process of answering
    if (activeCall.isAnsweringInProgress) return;
    
    // Show a loading toast
    const loadingToast = toast.loading(`Connecting to ${activeCall.leadName || activeCall.from}...`);
    
    try {
      await answerCall({
        onConnected: (callSid) => {
          toast.dismiss(loadingToast);
          toast.success(`Connected to ${activeCall.leadName || activeCall.from}`);
          console.log('[WebSocketCallNotification] Call connected:', callSid);
        },
        onDisconnected: () => {
          toast.info('Call ended');
          console.log('[WebSocketCallNotification] Call disconnected');
          setIsMuted(false);
          setIsDialpadOpen(false);
        },
        onError: (error) => {
          toast.dismiss(loadingToast);
          toast.error(`Call error: ${error.message}`);
          console.error('[WebSocketCallNotification] Call error:', error);
        }
      });
    } catch (error) {
      toast.dismiss(loadingToast);
      const errorMessage = error instanceof Error ? error.message : 'Unknown error';
      toast.error(`Failed to answer call: ${errorMessage}`);
      console.error('[WebSocketCallNotification] Error answering call:', error);
    }
  };

  // Handle navigation to lead details
  const handleNavigateToLead = () => {
    if (activeCall?.leadId) {
      try {
        // Update the lead store with the selected lead ID
        setSelectedLead({ id: activeCall.leadId });
        
        // Use setTimeout to ensure state updates complete before navigation
        setTimeout(() => {
          // Navigate to the leads page
          navigate({ to: '/leads' });
          console.log(`[WebSocketCallNotification] Navigated to lead ID: ${activeCall.leadId}`);
        }, 50);
      } catch (error) {
        console.error('[WebSocketCallNotification] Navigation error:', error);
        toast.error('Failed to navigate to lead details');
      }
    }
  };

  // Handle declining a call
  const handleDeclineCall = async () => {
    if (!activeCall) return;

    try {
      await hangupCall();
      console.log('[WebSocketCallNotification] Call rejected');
    } catch (error) {
      console.error('[WebSocketCallNotification] Error rejecting call:', error);
      toast.error(`Error rejecting call: ${error instanceof Error ? error.message : 'Unknown error'}`);
    }
  };

  // Handle sending DTMF tones
  const handleSendDigit = (digit: string) => {
    console.log(`[WebSocketCallNotification] Sending digit: ${digit}`);
    // Call the service to send the digit
    CallService.sendDigit(digit);
  };

  // Handle toggling mute
  const handleToggleMute = () => {
    if (isMuted) {
      // Unmute the call
      const success = CallService.unmuteCall();
      if (success) {
        setIsMuted(false);
      } else {
        toast.error('Failed to unmute call');
      }
    } else {
      // Mute the call
      const success = CallService.muteCall();
      if (success) {
        setIsMuted(true);
      } else {
        toast.error('Failed to mute call');
      }
    }
  };

  // Define dialpad keys
  const dialpadKeys: DialPadKey[] = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
    { value: '7', label: '7' },
    { value: '8', label: '8' },
    { value: '9', label: '9' },
    { value: '*', label: '*' },
    { value: '0', label: '0' },
    { value: '#', label: '#' },
  ];

  // Render dialpad buttons
  const renderDialpad = () => {
    return (
      <div className="p-2 grid grid-cols-3 gap-2 bg-gray-50 dark:bg-gray-900 rounded-lg">
        {dialpadKeys.map((key) => (
          <Button
            key={key.value}
            variant="outline"
            className="aspect-square text-xl font-medium"
            onClick={() => handleSendDigit(key.value)}
          >
            {key.label}
          </Button>
        ))}
      </div>
    );
  };

  // If no active call or not connected, don't render anything
  // Also, if this isn't the active instance and there's no call, don't render
  if (!activeCall || connectionStatus !== ConnectionStatus.CONNECTED) return null;

  // At this point, we're sure activeCall is not null for the rest of the component
  const call = activeCall;

  return (
    <div className={cn(
      "fixed z-50 transition-all duration-300",
      call.status === 'in-progress' ? "bottom-4 right-4 w-80" : "bottom-4 right-4 max-w-sm w-full"
    )}>
      <Card className={cn(
        "shadow-lg overflow-hidden transition-all duration-300",
        call.status === 'ringing' && "border-2 border-blue-500 animate-slow-border-pulse"
      )}>
        <CardHeader className={cn(
          "py-3",
          call.status === 'ringing' ? "bg-blue-50 dark:bg-blue-950" : 
          call.status === 'in-progress' ? "bg-green-50 dark:bg-green-950" : 
          call.status === 'connecting' ? "bg-yellow-50 dark:bg-yellow-950" : 
          "bg-gray-50 dark:bg-gray-900"
        )}>
          <div className="flex items-center justify-between">
            <CardTitle className="text-sm font-medium flex items-center">
              {call.status === 'preparing' && (
                <Phone className="h-4 w-4 text-yellow-500 mr-2 animate-pulse" />
              )}
              {call.status === 'ringing' && (
                <Phone className="h-4 w-4 text-blue-500 mr-2 animate-slow-pulse" />
              )}
              {call.status === 'connecting' && (
                <Phone className="h-4 w-4 text-yellow-500 mr-2 animate-pulse" />
              )}
              {call.status === 'in-progress' && (
                <Phone className="h-4 w-4 text-green-500 mr-2" />
              )}
              {call.status === 'preparing' && "Call Preparing..."}
              {call.status === 'ringing' && "Incoming Call"}
              {call.status === 'connecting' && "Connecting..."}
              {call.status === 'in-progress' && "Call in Progress"}
            </CardTitle>
            
            {call.status === 'in-progress' && (
              <div className="text-sm font-mono text-green-600 dark:text-green-400">
                {formatCallDuration(callDuration)}
              </div>
            )}
            
            {(call.status === 'ringing' || call.status === 'connecting') && (
              <Button 
                variant="ghost" 
                size="sm" 
                className="h-6 w-6 p-0" 
                onClick={handleDeclineCall}
              >
                <X className="h-4 w-4" />
              </Button>
            )}
            
            {call.status === 'in-progress' && (
              <Button
                variant="ghost"
                size="sm"
                className="h-6 w-6 p-0"
                onClick={() => setShowControls(!showControls)}
              >
                {showControls ? (
                  <ChevronDown className="h-4 w-4" />
                ) : (
                  <ChevronUp className="h-4 w-4" />
                )}
              </Button>
            )}
          </div>
        </CardHeader>
        
        <CardContent className={cn(
          "pt-3",
          !showControls && call.status === 'in-progress' && "hidden"
        )}>
          <div className="text-center">
            <div className="text-lg font-semibold">
              {call.leadName || call.from}
            </div>
            {call.leadName && (
              <div className="text-sm text-gray-500">{call.from}</div>
            )}
            
            {call.leadId && (
              <Button 
                variant="link" 
                size="sm" 
                className="mt-1 text-blue-500 dark:text-blue-400"
                onClick={handleNavigateToLead}
              >
                <User className="h-3 w-3 mr-1" />
                View Lead Details
              </Button>
            )}
          </div>
          
          {/* Dialpad (only shown in in-progress state when dialpad is open) */}
          {call.status === 'in-progress' && isDialpadOpen && (
            <div className="mt-3">
              {renderDialpad()}
            </div>
          )}
        </CardContent>
        
        <CardFooter className={cn(
          "flex flex-col gap-2 p-3",
          !showControls && call.status === 'in-progress' && "hidden"
        )}>
          {call.status === 'preparing' && (
            <div className="grid grid-cols-1 w-full gap-4">
              <div className="text-center text-yellow-600 dark:text-yellow-400 text-sm mb-2">
                Please wait while the call is being prepared...
              </div>
              <Button 
                variant="outline" 
                className="w-full border-red-500 hover:bg-red-50 dark:hover:bg-red-950 text-red-500"
                onClick={handleDeclineCall}
              >
                <PhoneOff className="h-4 w-4 mr-2" />
                Decline
              </Button>
            </div>
          )}
          
          {call.status === 'ringing' && (
            <div className="grid grid-cols-1 sm:grid-cols-2 w-full gap-4">
              <Button 
                variant="outline" 
                className="w-full border-red-500 hover:bg-red-50 dark:hover:bg-red-950 text-red-500"
                onClick={handleDeclineCall}
              >
                <PhoneOff className="h-4 w-4 mr-2" />
                Decline
              </Button>
              
              <Button 
                variant="outline" 
                className={cn(
                  "w-full border-green-500 hover:bg-green-50 dark:hover:bg-green-950 text-green-500",
                  call.isAnsweringInProgress && "opacity-50 cursor-not-allowed"
                )}
                onClick={handleAnswerCall}
                disabled={call.isAnsweringInProgress}
              >
                <PhoneCall className="h-4 w-4 mr-2" />
                Answer
              </Button>
            </div>
          )}
          
          {call.status === 'connecting' && (
            <div className="flex-1 flex justify-center">
              <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-yellow-500"></div>
            </div>
          )}
          
          {call.status === 'in-progress' && (
            <div className="grid grid-cols-2 sm:grid-cols-4 gap-2">
              <Button
                variant="outline"
                className={cn(
                  "w-full",
                  isMuted ? "border-red-500 bg-red-50 dark:bg-red-950 text-red-500" : "border-gray-300"
                )}
                onClick={handleToggleMute}
              >
                {isMuted ? (
                  <MicOff className="h-4 w-4" />
                ) : (
                  <Mic className="h-4 w-4" />
                )}
              </Button>
              
              <Button
                variant="outline"
                className={cn(
                  "w-full",
                  isDialpadOpen ? "border-blue-500 bg-blue-50 dark:bg-blue-950 text-blue-500" : "border-gray-300"
                )}
                onClick={() => setIsDialpadOpen(!isDialpadOpen)}
              >
                <Keyboard className="h-4 w-4" />
              </Button>
              
              {call.leadId && (
                <Button
                  variant="outline"
                  className="w-full border-gray-300"
                  onClick={handleNavigateToLead}
                >
                  <User className="h-4 w-4" />
                </Button>
              )}
              
              <Button
                variant="outline"
                className="w-full border-red-500 hover:bg-red-50 dark:hover:bg-red-950 text-red-500"
                onClick={handleDeclineCall}
              >
                <PhoneOff className="h-4 w-4" />
              </Button>
            </div>
          )}
        </CardFooter>
      </Card>
    </div>
  );
} 