import { Button } from '@/components/ui/button';
import {
  Drawer,
  DrawerContent
} from '@/components/ui/drawer';
import { useAuth } from '@/hooks/useAuth';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { axiosInstance } from '@/lib/api';
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Loader2, RefreshCw, X } from 'lucide-react';
import { useEffect, useState } from 'react';

// Replace with your actual publishable key
const STRIPE_PUBLISHABLE_KEY = 'pk_live_51I345jFyHOUXNkUPpST6nnTdst0vpSOVV01bHlF8lJ4vXhlfmPcM6iDqSMURIIFxbkkcVgl1ZGt6aELRf0akU8KY00lOUj7tHV';

// Make sure to call loadStripe outside of a component's render to avoid
// recreating the Stripe object on every render
const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

interface StripeEmbeddedCheckoutProps {
  isOpen: boolean;
  onClose: () => void;
  priceId: string;
  onSuccess?: (sessionId: string) => void;
}

export function StripeEmbeddedCheckout({
  isOpen,
  onClose,
  priceId,
  onSuccess
}: StripeEmbeddedCheckoutProps) {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [sessionId, setSessionId] = useState<string | null>(null);
  
  // Check if the device is mobile
  const isMobile = useMediaQuery('(max-width: 768px)');

  // Function to fetch the client secret
  const fetchClientSecret = async () => {
    try {
      setIsLoading(true);
      setError(null);
      
      const response = await axiosInstance.post('/stripe/checkout-sessions', {
        priceId,
        returnUrl: `${window.location.origin}/settings?tab=subscription&session_id={CHECKOUT_SESSION_ID}`,
        mode: 'subscription',
      });
      
      if (!response.data.clientSecret) {
        throw new Error('No client secret received from server');
      }
      
      setClientSecret(response.data.clientSecret);
      setSessionId(response.data.sessionId);
      return response.data.clientSecret;
    } catch (err: any) {
      console.error('Error creating checkout session:', err);
      setError(err.response?.data?.error || err.message || 'Failed to create checkout session');
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch client secret when drawer opens
  useEffect(() => {
    if (isOpen && priceId && !clientSecret && !isLoading) {
      // Validate priceId before making the request
      if (!priceId || priceId.trim() === '') {
        setError('Invalid price ID. Please select a valid subscription plan.');
        return;
      }
      
      fetchClientSecret();
    }
  }, [isOpen, priceId, clientSecret, isLoading]);

  // Reset state when drawer closes
  useEffect(() => {
    if (!isOpen) {
      setClientSecret(null);
      setSessionId(null);
      setError(null);
    }
  }, [isOpen]);

  // Check session status when session ID is available
  useEffect(() => {
    if (!sessionId || !isOpen) return;
    
    const checkSessionStatus = async () => {
      try {
        const response = await axiosInstance.get(`/stripe/checkout-sessions/${sessionId}`);
        const session = response.data.session;
        
        if (session.status === 'complete') {
          if (onSuccess) {
            onSuccess(sessionId);
          }
          onClose();
        }
      } catch (err) {
        console.error('Error checking session status:', err);
      }
    };
    
    // Poll for session status every 2 seconds
    const intervalId = setInterval(checkSessionStatus, 2000);
    
    return () => clearInterval(intervalId);
  }, [sessionId, onSuccess, onClose, isOpen]);

  // Content of the checkout component
  const checkoutContent = (
    <div className="h-full flex flex-col">
      <div className="flex justify-between items-center p-4 border-b">
        <div>
          <h2 className="text-lg font-semibold leading-none tracking-tight">Complete your subscription</h2>
          <p className="text-sm text-muted-foreground mt-1">Secure payment powered by Stripe</p>
        </div>
        <Button 
          variant="ghost" 
          size="icon" 
          onClick={onClose}
          className="h-6 w-6 rounded-md border border-input hover:bg-accent hover:text-accent-foreground"
        >
          <X className="h-4 w-4" />
          <span className="sr-only">Close</span>
        </Button>
      </div>
      
      <div className="p-4 flex-1 overflow-auto">
        {isLoading && (
          <div className="flex flex-col items-center justify-center h-full">
            <Loader2 className="h-8 w-8 animate-spin text-primary mb-4" />
            <p className="text-muted-foreground">Preparing checkout...</p>
          </div>
        )}
        
        {error && (
          <div className="flex flex-col items-center justify-center h-full">
            <div className="bg-destructive/10 p-4 rounded-md mb-4 max-w-md">
              <p className="text-destructive">{error}</p>
            </div>
            <Button 
              variant="outline" 
              className="mt-4 flex items-center gap-2"
              onClick={() => fetchClientSecret()}
            >
              <RefreshCw className="h-4 w-4" />
              Try Again
            </Button>
          </div>
        )}
        
        {clientSecret && !error && (
          <div className="h-full" style={{ minHeight: '500px' }}>
            <EmbeddedCheckoutProvider
              stripe={stripePromise}
              options={{
                clientSecret,
                onComplete: () => {
                  if (onSuccess && sessionId) {
                    onSuccess(sessionId);
                  }
                  onClose();
                }
              }}
            >
              <EmbeddedCheckout className="h-full" />
            </EmbeddedCheckoutProvider>
          </div>
        )}
        
        {!clientSecret && !isLoading && !error && (
          <div className="flex flex-col items-center justify-center h-full">
            <Button
              onClick={() => fetchClientSecret()}
              className="px-4 py-2 flex items-center gap-2"
            >
              <RefreshCw className="h-4 w-4" />
              Load Checkout
            </Button>
          </div>
        )}
      </div>
    </div>
  );

  // Mobile: Use Drawer
  if (isMobile) {
    return (
      <Drawer open={isOpen} onOpenChange={(open) => !open && onClose()}>
        <DrawerContent className="h-[85vh] max-h-[85vh]">
          {checkoutContent}
        </DrawerContent>
      </Drawer>
    );
  }

  // Desktop: Use right-side pane
  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 z-50 flex justify-end">
          {/* Backdrop */}
          <div 
            className="fixed inset-0 bg-black/20"
            onClick={onClose}
          />
          
          {/* Right-side pane */}
          <div className="relative bg-background border-l shadow-lg w-[450px] max-w-[100vw] h-full animate-in slide-in-from-right duration-300">
            {checkoutContent}
          </div>
        </div>
      )}
    </>
  );
} 