import { AuthPage } from '@/pages/AuthPage';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/auth')({
  component: AuthPage,
  validateSearch: (search: Record<string, unknown>) => {
    return {
      tab: search.tab as string | undefined,
      invitation: search.invitation as string | undefined,
      redirect: search.redirect as string | undefined,
    };
  }
}); 