import { MainLayout } from '@/components/layout/MainLayout';
import { PageContainer } from '@/components/layout/PageContainer';
import { MetricsLeaderboard } from '@/components/metrics/MetricsLeaderboard';
import { OnboardingButton, OnboardingOverlay, OnboardingProvider } from '@/components/onboarding';
import '@/components/onboarding/onboarding.css';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { DateRangePicker } from '@/components/ui/date-range-picker';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { MetricType, TimeframeType, fetchLeaderboard, fetchMetricsInfo, fetchTeamMetrics, fetchUserMetrics } from '@/lib/api';
import { useQuery } from '@tanstack/react-query';
import {
  addDays,
  eachDayOfInterval,
  eachMonthOfInterval,
  format,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subWeeks
} from 'date-fns';
import {
  Activity,
  ArrowDown,
  ArrowRight,
  ArrowUp,
  Calendar,
  Clock,
  Loader2,
  Mail,
  Phone,
  RefreshCw,
  SlidersHorizontal,
  Users
} from 'lucide-react';
import { useState } from 'react';
import { DateRange } from 'react-day-picker';
import { Bar, BarChart, CartesianGrid, Legend, Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

// Chart colors
const CHART_COLORS = [
  '#3b82f6', // blue-500 (replacing #0088fe)
  '#14b8a6', // teal-500 (replacing #00c49f)
  '#8b5cf6', // purple-500 (replacing #8884d8)
  '#f59e0b', // amber-500 (replacing #ffbb28)
  '#f97316', // orange-500 (replacing #ff8042)
  
  '#22c55e', // green-500 (replacing #82ca9d)
  '#eab308', // yellow-500 (replacing #ffc658)
  '#f97316', // orange-500 (replacing #ff8042)
];

// Metric groups for chart tabs
const METRIC_GROUPS = {
  conversion: ['conversion_rate_percent', 'sms_response_rate_percent', 'call_answer_rate_percent'],
  leads: ['leads_created_count', 'leads_contacted_count'],
  communication: ['messages_sent_count', 'calls_made_count'],
  response_time: ['avg_response_time_seconds']
};

// Types
export interface MetricsFilter {
  metrics: MetricType[];
  timeframe: TimeframeType;
  dateRange: DateRange;
  chartType?: 'bar' | 'line';
}

// Predefined date ranges
const DATE_PRESETS = [
  {
    name: 'Today',
    getValue: () => ({
      from: new Date(),
      to: new Date(),
    }),
  },
  {
    name: 'Yesterday',
    getValue: () => ({
      from: subDays(new Date(), 1),
      to: subDays(new Date(), 1),
    }),
  },
  {
    name: 'Last 7 days',
    getValue: () => ({
      from: subDays(new Date(), 6),
      to: new Date(),
    }),
  },
  {
    name: 'Last 14 days',
    getValue: () => ({
      from: subDays(new Date(), 13),
      to: new Date(),
    }),
  },
  {
    name: 'Last 30 days',
    getValue: () => ({
      from: subDays(new Date(), 29),
      to: new Date(),
    }),
  },
  {
    name: 'This week',
    getValue: () => ({
      from: startOfWeek(new Date(), { weekStartsOn: 1 }),
      to: new Date(),
    }),
  },
  {
    name: 'Last week',
    getValue: () => {
      const now = new Date();
      const startOfLastWeek = startOfWeek(subWeeks(now, 1), { weekStartsOn: 1 });
      const endOfLastWeek = subDays(startOfWeek(now, { weekStartsOn: 1 }), 1);
      return {
        from: startOfLastWeek,
        to: endOfLastWeek,
      };
    },
  },
  {
    name: 'This month',
    getValue: () => ({
      from: startOfMonth(new Date()),
      to: new Date(),
    }),
  },
  {
    name: 'Last month',
    getValue: () => {
      const now = new Date();
      const startOfLastMonth = startOfMonth(subMonths(now, 1));
      const endOfLastMonth = subDays(startOfMonth(now), 1);
      return {
        from: startOfLastMonth,
        to: endOfLastMonth,
      };
    },
  },
  {
    name: 'This year',
    getValue: () => ({
      from: startOfYear(new Date()),
      to: new Date(),
    }),
  },
];

export default function MetricsDashboard() {
  // State for filters
  const [activeTab, setActiveTab] = useState<'personal' | 'team'>('personal');
  const [filters, setFilters] = useState<MetricsFilter>({
    metrics: [
      'leads_created_count', 
      'leads_contacted_count', 
      'messages_sent_count', 
      'calls_made_count',
      'conversion_rate_percent',
      'sms_response_rate_percent',
      'call_answer_rate_percent',
      'avg_response_time_seconds'
    ],
    timeframe: 'daily',
    dateRange: {
      from: subDays(new Date(), 29),
      to: new Date()
    },
    chartType: 'bar'
  });
  const [chartTab, setChartTab] = useState<'conversion' | 'leads' | 'communication' | 'response_time'>('leads');
  const [leaderboardMetric, setLeaderboardMetric] = useState<MetricType>('leads_contacted_count');
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  // Fetch metrics info (available metrics and timeframes)
  const { data: metricsInfo, isLoading: isLoadingInfo } = useQuery({
    queryKey: ['metricsInfo'],
    queryFn: fetchMetricsInfo
  });

  // Fetch user metrics
  const { 
    data: userMetrics, 
    isLoading: isLoadingUserMetrics,
    refetch: refetchUserMetrics
  } = useQuery({
    queryKey: ['userMetrics', filters],
    queryFn: () => fetchUserMetrics({
      metrics: filters.metrics.join(','),
      timeframe: filters.timeframe,
      startDate: filters.dateRange.from ? format(filters.dateRange.from, 'yyyy-MM-dd') : format(new Date(), 'yyyy-MM-dd'),
      endDate: filters.dateRange.to ? format(filters.dateRange.to, 'yyyy-MM-dd') : format(new Date(), 'yyyy-MM-dd')
    }),
    enabled: activeTab === 'personal'
  });

  // Fetch team metrics
  const { 
    data: teamMetrics, 
    isLoading: isLoadingTeamMetrics,
    refetch: refetchTeamMetrics
  } = useQuery({
    queryKey: ['teamMetrics', filters],
    queryFn: () => fetchTeamMetrics({
      metrics: filters.metrics.join(','),
      timeframe: filters.timeframe,
      startDate: filters.dateRange.from ? format(filters.dateRange.from, 'yyyy-MM-dd') : format(new Date(), 'yyyy-MM-dd'),
      endDate: filters.dateRange.to ? format(filters.dateRange.to, 'yyyy-MM-dd') : format(new Date(), 'yyyy-MM-dd')
    }),
    enabled: activeTab === 'team'
  });

  // Fetch leaderboard data
  const { 
    data: leaderboard, 
    isLoading: isLoadingLeaderboard,
    refetch: refetchLeaderboard
  } = useQuery({
    queryKey: ['leaderboard', leaderboardMetric, filters.timeframe, filters.dateRange],
    queryFn: () => fetchLeaderboard({
      metric: leaderboardMetric,
      timeframe: filters.timeframe,
      startDate: filters.dateRange.from ? format(filters.dateRange.from, 'yyyy-MM-dd') : format(new Date(), 'yyyy-MM-dd'),
      endDate: filters.dateRange.to ? format(filters.dateRange.to, 'yyyy-MM-dd') : format(new Date(), 'yyyy-MM-dd')
    })
  });

  // Handle filter changes
  const handleMetricsChange = (metrics: MetricType[]) => {
    setFilters(prev => ({ ...prev, metrics }));
  };

  const handleTimeframeChange = (timeframe: TimeframeType) => {
    setFilters(prev => ({ ...prev, timeframe }));
  };

  const handleDateRangeChange = (dateRange: DateRange) => {
    setFilters(prev => ({ ...prev, dateRange }));
  };

  const handleLeaderboardMetricChange = (metric: MetricType) => {
    setLeaderboardMetric(metric);
  };

  // Handle refresh
  const handleRefresh = () => {
    if (activeTab === 'personal') {
      refetchUserMetrics();
    } else {
      refetchTeamMetrics();
    }
    refetchLeaderboard();
  };

  // Apply date preset
  const applyDatePreset = (preset: typeof DATE_PRESETS[number]) => {
    const dateRange = preset.getValue();
    setFilters(prev => ({ ...prev, dateRange }));
  };

  // Format date range for display
  const formatDateRange = (range: DateRange) => {

    if(!range) return 'Select date range';

    if (!range.from) return 'Select date range';
    
    if (!range.to) {
      return format(range.from, 'MMM d, yyyy');
    }
    
    // If same day
    if (format(range.from, 'yyyy-MM-dd') === format(range.to, 'yyyy-MM-dd')) {
      return format(range.from, 'MMM d, yyyy');
    }
    
    // If same month and year
    if (format(range.from, 'MMM yyyy') === format(range.to, 'MMM yyyy')) {
      return `${format(range.from, 'MMM d')} - ${format(range.to, 'd, yyyy')}`;
    }
    
    // If same year
    if (format(range.from, 'yyyy') === format(range.to, 'yyyy')) {
      return `${format(range.from, 'MMM d')} - ${format(range.to, 'MMM d, yyyy')}`;
    }
    
    // Different years
    return `${format(range.from, 'MMM d, yyyy')} - ${format(range.to, 'MMM d, yyyy')}`;
  };

  // Format response time in a human-readable format for the comparison message
  const formatResponseTime = (seconds: number): string => {
    // Format the given seconds into a human-readable format for the comparison message
    if (seconds < 60) {
      return `${Math.round(seconds)}s`;
    } else if (seconds < 3600) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = Math.round(seconds % 60);
      return `${minutes}m ${remainingSeconds}s`;
    } else {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      return `${hours}h ${minutes}m`;
    }
  };

  // Convert seconds to minutes for chart display
  const secondsToMinutes = (seconds: number): number => {
    return parseFloat((seconds / 60).toFixed(2));
  };

  // Format metric value based on metric type
  const formatMetricValue = (value: number, metricType: MetricType): string => {
    if (value === undefined || value === null) {
      return 'N/A';
    }
    
    if (metricType.includes('percent')) {
      return `${Number(value).toFixed(1)}%`;
    } else if (metricType.includes('time_seconds')) {
      return formatResponseTime(value);
    } else {
      return value.toLocaleString();
    }
  };

  // Generate time labels based on timeframe and date range
  const generateTimeLabels = (timeframe: TimeframeType, dateRange: DateRange): string[] => {
    if (!dateRange.from || !dateRange.to) return [];
    
    let dates: Date[] = [];
    
    switch (timeframe) {
      case 'daily':
        dates = eachDayOfInterval({ start: dateRange.from, end: dateRange.to });
        return dates.map(date => format(date, 'MMM dd'));
      
      case 'weekly':
        // Get start of each week in the range
        let startDate = startOfWeek(dateRange.from, { weekStartsOn: 1 });
        const endDate = dateRange.to;
        dates = [];
        
        while (startDate <= endDate) {
          dates.push(startDate);
          startDate = addDays(startDate, 7);
        }
        
        return dates.map(date => format(date, 'MMM dd'));
      
      case 'monthly':
        dates = eachMonthOfInterval({ start: dateRange.from, end: dateRange.to });
        return dates.map(date => format(date, 'MMM yyyy'));
      
      default:
        return [];
    }
  };

  // Get short label for chart display
  const getShortLabel = (label: string, timeframe: TimeframeType): string => {
    switch (timeframe) {
      case 'daily':
        // Return day number only
        return label.split(' ')[1];
      
      case 'weekly':
        // Return first 3 chars
        return label.substring(0, 3);
      
      case 'monthly':
        // Return first 3 chars
        return label.substring(0, 3);
      
      default:
        return label;
    }
  };

  // Get current metrics data based on active tab
  const currentMetrics = activeTab === 'personal' ? userMetrics : teamMetrics;
  const isLoadingMetrics = activeTab === 'personal' ? isLoadingUserMetrics : isLoadingTeamMetrics;

  // Format data for Recharts
  const formatChartData = () => {
    if (!currentMetrics?.metrics) return [];
    
    const timeLabels = generateTimeLabels(filters.timeframe, filters.dateRange);
    const activeMetrics = METRIC_GROUPS[chartTab].filter(metric => 
      currentMetrics.metrics[metric as MetricType] !== undefined
    );
    
    // Find the longest array of values to determine how many data points to show
    let maxDataPoints = 0;
    activeMetrics.forEach(metric => {
      const values = currentMetrics.metrics[metric as MetricType];
      if (values && values.length > maxDataPoints) {
        maxDataPoints = values.length;
      }
    });
    
    // Use timeLabels or the maxDataPoints, whichever is smaller
    const dataPointsToRender = Math.min(timeLabels.length, maxDataPoints);
    
    // Only use the number of time labels we actually have data for
    const usableTimeLabels = timeLabels.slice(0, dataPointsToRender);
    
    return usableTimeLabels.map((label, index) => {
      const dataPoint: any = { name: getShortLabel(label, filters.timeframe) };
      
      activeMetrics.forEach(metric => {
        const values = currentMetrics.metrics[metric as MetricType];
        if (values && index < values.length && values[index] !== undefined) {
          // Convert response time from seconds to minutes for the chart
          if (metric === 'avg_response_time_seconds') {
            dataPoint[metric] = secondsToMinutes(values[index]);
          } else {
            dataPoint[metric] = values[index];
          }
          dataPoint[`${metric}_formatted`] = formatMetricValue(values[index], metric as MetricType);
        }
      });
      
      return dataPoint;
    });
  };

  // Get Y-axis label based on chart type
  const getYAxisLabel = () => {
    switch (chartTab) {
      case 'leads':
        return 'Leads';
      case 'conversion':
        return 'Conversion Rate (%)';
      case 'communication':
        return 'Count';
      case 'response_time':
        return 'Response Time (minutes)';
      default:
        return 'Metric';
    }
  };

  // Get the latest value or average for a metric safely
  const getLatestMetricValue = (metricValues: number[] | undefined, metricType?: MetricType): number | null => {
    if (!metricValues || metricValues.length === 0) {
      return null;
    }
    
    // For rate metrics, calculate the average of non-zero values for consistency with the backend
    const isRateMetric = metricType && (
      metricType.includes('percent') || 
      metricType.includes('rate') || 
      metricType.includes('time_seconds')
    );
    
    if (isRateMetric) {
      // Get valid values (non-null, non-zero)
      const validValues = metricValues.filter(v => v !== null && v !== undefined && v > 0);
      if (validValues.length === 0) return null;
      
      // Calculate average
      const sum = validValues.reduce((total, val) => total + val, 0);
      console.log('sum', sum);
      console.log('validValues.length', validValues.length);
      return sum / validValues.length;
    } else {
      // For count metrics, use the latest value
      // Get the last non-null, non-undefined value in the array
      for (let i = metricValues.length - 1; i >= 0; i--) {
        const value = metricValues[i];
        if (value !== undefined && value !== null) {
          return value;
        }
      }
      return null;
    }
  };

  // Get comparison value from the API data
  const getMetricComparison = (metricType: MetricType): number | null => {
    if (!currentMetrics?.comparisons) return null;
    
    const value = currentMetrics.comparisons[metricType];
    return value !== undefined ? value : null;
  };

  // Format a percentage value safely
  const formatPercentage = (value: number | null): string => {
    if (value === null || isNaN(value)) {
      return 'N/A';
    }
    return value.toFixed(1) + '%';
  };

  // Calculate the sum of metric values
  const getMetricSum = (metricValues: number[] | undefined): number | null => {
    if (!metricValues || metricValues.length === 0) {
      return null;
    }
    
    // Filter out null/undefined values and sum
    const sum = metricValues.reduce((total, value) => {
      return total + (value !== null && value !== undefined ? value : 0);
    }, 0);
    
    return sum;
  };

  // Calculate average response time for the reference line
  const getAverageResponseTime = (): number | null => {
    if (!currentMetrics?.metrics?.avg_response_time_seconds || 
        currentMetrics.metrics.avg_response_time_seconds.length === 0) {
      return null;
    }
    
    const values = currentMetrics.metrics.avg_response_time_seconds;
    const validValues = values.filter(v => v !== null && v !== undefined);
    
    if (validValues.length === 0) {
      return null;
    }
    
    const sum = validValues.reduce((total, value) => total + value, 0);
    return secondsToMinutes(sum / validValues.length);
  };

  return (
    <OnboardingProvider>
      <MainLayout pageTitle="Metrics Dashboard">
        <PageContainer className="bg-white">
          <div className="bg-white rounded-lg overflow-hidden">
            {/* Dashboard Header */}
            <div className="p-4 border-b border-gray-200 mt-16 sm:mt-0 metrics-dashboard-header">
              <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center">
                <div className="flex items-center mb-4 sm:mb-0">
                  <h3 className="font-semibold text-gray-800">
                    {activeTab === 'personal' ? 'Personal' : 'Team'} Performance Dashboard
                  </h3>
                  <div className="ml-3 px-2 py-1 bg-blue-100 text-blue-800 text-xs font-medium rounded">
                    Live
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row space-y-3 sm:space-y-0 sm:space-x-2">
                  <Tabs 
                    value={activeTab} 
                    onValueChange={(value) => setActiveTab(value as 'personal' | 'team')}
                    className="sm:mr-4 team-personal-tabs"
                  >
                    <TabsList className="h-9 bg-gray-100 w-full sm:w-auto">
                      <TabsTrigger 
                        value="personal" 
                        className="px-4 data-[state=active]:bg-white data-[state=active]:text-primary flex-1 sm:flex-none"
                      >
                        Personal
                      </TabsTrigger>
                      <TabsTrigger 
                        value="team" 
                        className="px-4 data-[state=active]:bg-white data-[state=active]:text-primary flex-1 sm:flex-none"
                      >
                        Team
                      </TabsTrigger>
                    </TabsList>
                  </Tabs>
                  
                  <div className="flex flex-wrap gap-2 sm:flex-nowrap">
                    {/* Date Range Selector */}
                    <Popover>
                      <PopoverTrigger asChild>
                        <Button 
                          variant="outline" 
                          className="text-sm border border-gray-300 rounded px-3 py-1 h-9 bg-white flex-1 sm:flex-none date-range-selector"
                        >
                          <Calendar className="mr-2 h-4 w-4 text-gray-500" />
                          <span className="truncate max-w-[120px]">{formatDateRange(filters.dateRange)}</span>
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent className="w-auto p-0" align="end">
                        <div className="grid grid-cols-2 gap-2 p-3 border-b">
                          {DATE_PRESETS.slice(0, 6).map((preset) => (
                            <Button
                              key={preset.name}
                              variant="outline"
                              className="justify-start font-normal text-sm"
                              onClick={() => applyDatePreset(preset)}
                            >
                              {preset.name}
                            </Button>
                          ))}
                        </div>
                        <DateRangePicker
                          value={filters.dateRange}
                          onChange={(value) => handleDateRangeChange(value as DateRange)}
                          className="p-3"
                        />
                      </PopoverContent>
                    </Popover>
                    
                    {/* Timeframe Selector */}
                    <Select
                      value={filters.timeframe}
                      onValueChange={(value) => handleTimeframeChange(value as TimeframeType)}
                    >
                      <SelectTrigger className="w-[130px] h-9 text-sm border border-gray-300 bg-white flex-1 sm:flex-none sm:w-[130px] timeframe-selector">
                        <Clock className="mr-2 h-4 w-4 text-gray-500" />
                        <SelectValue placeholder="Timeframe" />
                      </SelectTrigger>
                      <SelectContent>
                        {metricsInfo?.timeframes && Object.entries(metricsInfo.timeframes).map(([key, label]) => (
                          <SelectItem key={key} value={key}>{label}</SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    
                    {/* Metrics Filter */}
                    <Popover open={isFilterOpen} onOpenChange={setIsFilterOpen}>
                      <PopoverTrigger asChild>
                        <Button 
                          variant="outline" 
                          size="icon" 
                          className="h-9 w-9 border border-gray-300 bg-white relative metrics-filter"
                        >
                          <SlidersHorizontal className="h-4 w-4 text-gray-500" />
                          {filters.metrics.length > 0 && (
                            <Badge 
                              className="absolute -top-2 -right-2 h-5 w-5 p-0 flex items-center justify-center text-xs bg-primary text-white"
                            >
                              {filters.metrics.length}
                            </Badge>
                          )}
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent className="w-[300px] p-4" align="end">
                        <div className="space-y-4">
                          <h4 className="font-medium">Metrics to Display</h4>
                          <div className="grid grid-cols-1 gap-2">
                            {metricsInfo?.metricTypes && Object.entries(metricsInfo.metricTypes).map(([key, label]) => (
                              <div key={key} className="flex items-center space-x-2">
                                <input
                                  type="checkbox"
                                  id={`filter-${key}`}
                                  className="rounded text-primary focus:ring-primary"
                                  checked={filters.metrics.includes(key as MetricType)}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      handleMetricsChange([...filters.metrics, key as MetricType]);
                                    } else {
                                      handleMetricsChange(filters.metrics.filter(m => m !== key));
                                    }
                                  }}
                                />
                                <label htmlFor={`filter-${key}`} className="text-sm">{label}</label>
                              </div>
                            ))}
                          </div>
                          <div className="flex justify-between pt-2">
                            <Button 
                              variant="outline" 
                              size="sm"
                              onClick={() => handleMetricsChange([])}
                              className="text-sm"
                            >
                              Clear All
                            </Button>
                            <Button 
                              size="sm"
                              onClick={() => setIsFilterOpen(false)}
                              className="text-sm bg-primary"
                            >
                              Apply Filters
                            </Button>
                          </div>
                        </div>
                      </PopoverContent>
                    </Popover>
                    
                    <Button 
                      className="text-sm bg-primary text-white h-9 px-3 flex-1 sm:flex-none"
                      onClick={handleRefresh}
                      disabled={isLoadingMetrics || isLoadingLeaderboard}
                    >
                      {(isLoadingMetrics || isLoadingLeaderboard) ? (
                        <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                      ) : (
                        <RefreshCw className="h-4 w-4 mr-2" />
                      )}
                      Refresh
                    </Button>
                    
                    {/* Onboarding Button */}
                    <OnboardingButton />
                  </div>
                </div>
              </div>
            </div>

            {/* Dashboard Content */}
            <div className="p-4 grid grid-cols-12 gap-4">
              {/* Key Metrics */}
              <div className="col-span-12 grid grid-cols-1 md:grid-cols-4 gap-4 mb-4">
                {isLoadingMetrics ? (
                  <div className="col-span-4 flex justify-center py-8">
                    <Loader2 className="h-8 w-8 animate-spin text-primary" />
                  </div>
                ) : (
                  <>
                    {/* Total Leads */}
                    {currentMetrics?.metrics?.leads_created_count && (
                      <div className="bg-blue-50 p-3 rounded-lg">
                        <div className="flex justify-between items-start">
                          <div>
                            <p className="text-sm text-gray-500">Total Leads (Period)</p>
                            <h4 className="text-2xl font-bold text-gray-800">
                              {getMetricSum(currentMetrics.metrics.leads_created_count) !== null ? 
                                getMetricSum(currentMetrics.metrics.leads_created_count)!.toLocaleString() : 
                                'N/A'}
                            </h4>
                          </div>
                          <div className="p-2 bg-blue-100 rounded-full">
                            <Users size={18} className="text-blue-600" />
                          </div>
                        </div>
                        {currentMetrics.metrics.leads_created_count && currentMetrics.metrics.leads_created_count.length > 1 && (
                          <div className="flex items-center mt-2 text-xs">
                            {getMetricComparison('leads_created_count') === null ? (
                              <span className="text-gray-500">No comparison data available</span>
                            ) : getMetricComparison('leads_created_count')! > 0 ? (
                              <div className="flex items-center text-green-600">
                                <ArrowUp size={12} />
                                <span className="ml-1">
                                  {getMetricComparison('leads_created_count')!.toLocaleString()} more than previous period
                                </span>
                              </div>
                            ) : (
                              <div className="flex items-center text-red-600">
                                <ArrowDown size={12} />
                                <span className="ml-1">
                                  {Math.abs(getMetricComparison('leads_created_count')!).toLocaleString()} fewer than previous period
                                </span>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                    
                    {/* Leads Contacted */}
                    {currentMetrics?.metrics?.leads_contacted_count && (
                      <div className="bg-teal-50 p-3 rounded-lg">
                        <div className="flex justify-between items-start">
                          <div>
                            <p className="text-sm text-gray-500">Leads Contacted (Period)</p>
                            <h4 className="text-2xl font-bold text-gray-800">
                              {getMetricSum(currentMetrics.metrics.leads_contacted_count) !== null ? 
                                getMetricSum(currentMetrics.metrics.leads_contacted_count)!.toLocaleString() : 
                                'N/A'}
                            </h4>
                          </div>
                          <div className="p-2 bg-teal-100 rounded-full">
                            <Users size={18} className="text-teal-600" />
                          </div>
                        </div>
                        {currentMetrics.metrics.leads_contacted_count && currentMetrics.metrics.leads_contacted_count.length > 1 && (
                          <div className="flex items-center mt-2 text-xs">
                            {getMetricComparison('leads_contacted_count') === null ? (
                              <span className="text-gray-500">No comparison data available</span>
                            ) : getMetricComparison('leads_contacted_count')! > 0 ? (
                              <div className="flex items-center text-green-600">
                                <ArrowUp size={12} />
                                <span className="ml-1">
                                  {getMetricComparison('leads_contacted_count')!.toLocaleString()} more than previous period
                                </span>
                              </div>
                            ) : (
                              <div className="flex items-center text-red-600">
                                <ArrowDown size={12} />
                                <span className="ml-1">
                                  {Math.abs(getMetricComparison('leads_contacted_count')!).toLocaleString()} fewer than previous period
                                </span>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                    
                    {/* Calls Made */}
                    {currentMetrics?.metrics?.calls_made_count && (
                      <div className="bg-purple-50 p-3 rounded-lg">
                        <div className="flex justify-between items-start">
                          <div>
                            <p className="text-sm text-gray-500">Calls Made (Period)</p>
                            <h4 className="text-2xl font-bold text-gray-800">
                              {getMetricSum(currentMetrics.metrics.calls_made_count) !== null ? 
                                getMetricSum(currentMetrics.metrics.calls_made_count)!.toLocaleString() : 
                                'N/A'}
                            </h4>
                          </div>
                          <div className="p-2 bg-purple-100 rounded-full">
                            <Phone size={18} className="text-purple-600" />
                          </div>
                        </div>
                        {currentMetrics.metrics.calls_made_count && currentMetrics.metrics.calls_made_count.length > 1 && (
                          <div className="flex items-center mt-2 text-xs">
                            {getMetricComparison('calls_made_count') === null ? (
                              <span className="text-gray-500">No comparison data available</span>
                            ) : getMetricComparison('calls_made_count')! > 0 ? (
                              <div className="flex items-center text-green-600">
                                <ArrowUp size={12} />
                                <span className="ml-1">
                                  {getMetricComparison('calls_made_count')!.toLocaleString()} more than previous period
                                </span>
                              </div>
                            ) : (
                              <div className="flex items-center text-red-600">
                                <ArrowDown size={12} />
                                <span className="ml-1">
                                  {Math.abs(getMetricComparison('calls_made_count')!).toLocaleString()} fewer than previous period
                                </span>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                    
                    {/* Messages Sent */}
                    {currentMetrics?.metrics?.messages_sent_count && (
                      <div className="bg-indigo-50 p-3 rounded-lg">
                        <div className="flex justify-between items-start">
                          <div>
                            <p className="text-sm text-gray-500">Messages Sent (Period)</p>
                            <h4 className="text-2xl font-bold text-gray-800">
                              {getMetricSum(currentMetrics.metrics.messages_sent_count) !== null ? 
                                getMetricSum(currentMetrics.metrics.messages_sent_count)!.toLocaleString() : 
                                'N/A'}
                            </h4>
                          </div>
                          <div className="p-2 bg-indigo-100 rounded-full">
                            <Mail size={18} className="text-indigo-600" />
                          </div>
                        </div>
                        {currentMetrics.metrics.messages_sent_count && currentMetrics.metrics.messages_sent_count.length > 1 && (
                          <div className="flex items-center mt-2 text-xs">
                            {getMetricComparison('messages_sent_count') === null ? (
                              <span className="text-gray-500">No comparison data available</span>
                            ) : getMetricComparison('messages_sent_count')! > 0 ? (
                              <div className="flex items-center text-green-600">
                                <ArrowUp size={12} />
                                <span className="ml-1">
                                  {getMetricComparison('messages_sent_count')!.toLocaleString()} more than previous period
                                </span>
                              </div>
                            ) : (
                              <div className="flex items-center text-red-600">
                                <ArrowDown size={12} />
                                <span className="ml-1">
                                  {Math.abs(getMetricComparison('messages_sent_count')!).toLocaleString()} fewer than previous period
                                </span>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                    
                    {/* Response Time */}
                    {currentMetrics?.metrics?.avg_response_time_seconds && (
                      <div className="bg-amber-50 p-3 rounded-lg">
                        <div className="flex justify-between items-start">
                          <div>
                            <p className="text-sm text-gray-500">Avg. Response Time</p>
                            <h4 className="text-2xl font-bold text-gray-800">
                              {getLatestMetricValue(currentMetrics.metrics.avg_response_time_seconds, 'avg_response_time_seconds') !== null ? 
                                formatResponseTime(getLatestMetricValue(currentMetrics.metrics.avg_response_time_seconds, 'avg_response_time_seconds')!) : 
                                'N/A'}
                            </h4>
                            <button 
                              onClick={() => setChartTab('response_time')}
                              className="text-xs text-amber-600 mt-1 flex items-center hover:underline"
                            >
                              See detailed trend analysis
                              <ArrowRight size={12} className="ml-1" />
                            </button>
                          </div>
                          <div className="p-2 bg-amber-100 rounded-full">
                            <Clock size={18} className="text-amber-600" />
                          </div>
                        </div>
                        {currentMetrics.metrics.avg_response_time_seconds && currentMetrics.metrics.avg_response_time_seconds.length > 1 && (
                          <div className="flex items-center mt-2 text-xs">
                            {getMetricComparison('avg_response_time_seconds') === null ? (
                              <span className="text-gray-500">No comparison data available</span>
                            ) : getMetricComparison('avg_response_time_seconds')! < 0 ? (
                              <div className="flex items-center text-green-600">
                                <ArrowUp size={12} />
                                <span className="ml-1">
                                  {formatResponseTime(Math.abs(getMetricComparison('avg_response_time_seconds')!))} faster than previous period
                                </span>
                              </div>
                            ) : (
                              <div className="flex items-center text-red-600">
                                <ArrowDown size={12} />
                                <span className="ml-1">
                                  {formatResponseTime(getMetricComparison('avg_response_time_seconds')!)} slower than previous period
                                </span>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                    
                    {/* Conversion Rate */}
                    {currentMetrics?.metrics?.conversion_rate_percent && (
                      <div className="bg-green-50 p-3 rounded-lg">
                        <div className="flex justify-between items-start">
                          <div>
                            <p className="text-sm text-gray-500">Conversion Rate</p>
                            <h4 className="text-2xl font-bold text-gray-800">
                              {formatPercentage(getLatestMetricValue(currentMetrics.metrics.conversion_rate_percent, 'conversion_rate_percent'))}
                            </h4>
                          </div>
                          <div className="p-2 bg-green-100 rounded-full">
                            <Activity size={18} className="text-green-600" />
                          </div>
                        </div>
                        {currentMetrics.metrics.conversion_rate_percent && currentMetrics.metrics.conversion_rate_percent.length > 1 && (
                          <div className="flex items-center mt-2 text-xs">
                            {getMetricComparison('conversion_rate_percent') === null ? (
                              <span className="text-gray-500">No comparison data available</span>
                            ) : getMetricComparison('conversion_rate_percent')! > 0 ? (
                              <div className="flex items-center text-green-600">
                                <ArrowUp size={12} />
                                <span className="ml-1">
                                  {getMetricComparison('conversion_rate_percent')!.toFixed(1)}% points higher than previous period
                                </span>
                              </div>
                            ) : (
                              <div className="flex items-center text-red-600">
                                <ArrowDown size={12} />
                                <span className="ml-1">
                                  {Math.abs(getMetricComparison('conversion_rate_percent')!).toFixed(1)}% points lower than previous period
                                </span>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                    
                    {/* Call Answer Rate */}
                    {currentMetrics?.metrics?.call_answer_rate_percent && (
                      <div className="bg-violet-50 p-3 rounded-lg">
                        <div className="flex justify-between items-start">
                          <div>
                            <p className="text-sm text-gray-500">Call Answer Rate</p>
                            <h4 className="text-2xl font-bold text-gray-800">
                              {formatPercentage(getLatestMetricValue(currentMetrics.metrics.call_answer_rate_percent, 'call_answer_rate_percent'))}
                            </h4>
                          </div>
                          <div className="p-2 bg-violet-100 rounded-full">
                            <Phone size={18} className="text-violet-600" />
                          </div>
                        </div>
                        {currentMetrics.metrics.call_answer_rate_percent && currentMetrics.metrics.call_answer_rate_percent.length > 1 && (
                          <div className="flex items-center mt-2 text-xs">
                            {getMetricComparison('call_answer_rate_percent') === null ? (
                              <span className="text-gray-500">No comparison data available</span>
                            ) : getMetricComparison('call_answer_rate_percent')! > 0 ? (
                              <div className="flex items-center text-green-600">
                                <ArrowUp size={12} />
                                <span className="ml-1">
                                  {getMetricComparison('call_answer_rate_percent')!.toFixed(1)}% points higher than previous period
                                </span>
                              </div>
                            ) : (
                              <div className="flex items-center text-red-600">
                                <ArrowDown size={12} />
                                <span className="ml-1">
                                  {Math.abs(getMetricComparison('call_answer_rate_percent')!).toFixed(1)}% points lower than previous period
                                </span>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                    
                    {/* SMS Response Rate */}
                    {currentMetrics?.metrics?.sms_response_rate_percent && (
                      <div className="bg-sky-50 p-3 rounded-lg">
                        <div className="flex justify-between items-start">
                          <div>
                            <p className="text-sm text-gray-500">SMS Response Rate</p>
                            <h4 className="text-2xl font-bold text-gray-800">
                              {formatPercentage(getLatestMetricValue(currentMetrics.metrics.sms_response_rate_percent, 'sms_response_rate_percent'))}
                            </h4>
                          </div>
                          <div className="p-2 bg-sky-100 rounded-full">
                            <Mail size={18} className="text-sky-600" />
                          </div>
                        </div>
                        {currentMetrics.metrics.sms_response_rate_percent && currentMetrics.metrics.sms_response_rate_percent.length > 1 && (
                          <div className="flex items-center mt-2 text-xs">
                            {getMetricComparison('sms_response_rate_percent') === null ? (
                              <span className="text-gray-500">No comparison data available</span>
                            ) : getMetricComparison('sms_response_rate_percent')! > 0 ? (
                              <div className="flex items-center text-green-600">
                                <ArrowUp size={12} />
                                <span className="ml-1">
                                  {getMetricComparison('sms_response_rate_percent')!.toFixed(1)}% points higher than previous period
                                </span>
                              </div>
                            ) : (
                              <div className="flex items-center text-red-600">
                                <ArrowDown size={12} />
                                <span className="ml-1">
                                  {Math.abs(getMetricComparison('sms_response_rate_percent')!).toFixed(1)}% points lower than previous period
                                </span>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </>
                )}
              </div>
              
              {/* Charts Section - Full Width */}
              <div className="col-span-12">
                <div className="bg-white border border-gray-200 rounded-lg p-4">
                  <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 gap-4">
                    <h4 className="font-medium text-gray-700">
                      {activeTab === 'personal' ? 'Personal' : 'Team'} Metrics
                    </h4>
                    
                    {/* Chart Type Tabs */}
                    <div className="w-full sm:w-auto overflow-x-auto pb-2 sm:pb-0 chart-tabs">
                      <Tabs
                        value={chartTab}
                        onValueChange={(value) => setChartTab(value as 'conversion' | 'leads' | 'communication' | 'response_time')}
                        className="w-full sm:w-auto"
                      >
                        <TabsList className="min-w-[450px] sm:min-w-0 w-full sm:w-auto grid grid-cols-4">
                          <TabsTrigger 
                            value="leads" 
                            className="px-2 text-xs sm:text-sm truncate"
                          >
                            Leads
                          </TabsTrigger>
                          <TabsTrigger 
                            value="conversion" 
                            className="px-2 text-xs sm:text-sm truncate"
                          >
                            Conversion
                          </TabsTrigger>
                          <TabsTrigger 
                            value="communication" 
                            className="px-2 text-xs sm:text-sm truncate"
                          >
                            Communication
                          </TabsTrigger>
                          <TabsTrigger 
                            value="response_time" 
                            className="px-2 text-xs sm:text-sm truncate"
                          >
                            Response Time
                          </TabsTrigger>
                        </TabsList>
                      </Tabs>
                    </div>
                  </div>
                  
                  {isLoadingMetrics ? (
                    <div className="h-64 flex items-center justify-center">
                      <Loader2 className="h-8 w-8 animate-spin text-primary" />
                    </div>
                  ) : currentMetrics?.metrics && Object.keys(currentMetrics.metrics).length > 0 ? (
                    <div className="h-80">
                      {chartTab === 'response_time' && (
                        <div className="mb-4 hidden sm:block">
                          <h5 className="text-sm font-medium text-gray-700">Response Time Trend Analysis</h5>
                          <p className="text-xs text-gray-500">
                            Track how your average response time has changed over the selected period
                          </p>
                        </div>
                      )}
                      <ResponsiveContainer width="100%" height={chartTab === 'response_time' ? "90%" : "100%"}>
                        {chartTab === 'response_time' ? (
                          <LineChart
                            data={formatChartData()}
                            margin={{ top: 0, right: 25, left: 0, bottom: 15 }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis 
                              label={{ 
                                value: getYAxisLabel(), 
                                angle: -90, 
                                position: 'insideLeft',
                                offset: 10,
                                style: { textAnchor: 'middle' },
                                dy: -10,
                                className: "hidden sm:block"
                              }}
                            />
                            <Tooltip 
                              formatter={(value, name) => {
                                const metricName = name as string;
                                const metricType = metricName as MetricType;
                                return [formatMetricValue(value as number, metricType), metricsInfo?.metricTypes?.[metricType] || metricType];
                              }}
                            />
                            <Legend />
                            {METRIC_GROUPS[chartTab]
                              .filter(metric => currentMetrics.metrics[metric as MetricType] !== undefined)
                              .map((metric, index) => (
                                <Line 
                                  key={metric} 
                                  type="monotone"
                                  dataKey={metric} 
                                  name={metricsInfo?.metricTypes?.[metric as MetricType] || metric}
                                  stroke="#8884d8"
                                  strokeWidth={2}
                                  dot={{ r: 4, strokeWidth: 2 }}
                                  activeDot={{ r: 8, strokeWidth: 2 }}
                                />
                              ))
                            }
                            {(() => {
                              const avgResponseTime = getAverageResponseTime();
                              return avgResponseTime !== null ? (
                                <ReferenceLine 
                                  y={avgResponseTime} 
                                  stroke="#ff7300" 
                                  strokeDasharray="3 3"
                                  label={{ 
                                    value: `Avg: ${avgResponseTime.toFixed(2)} min`, 
                                    position: 'right',
                                    fill: '#ff7300',
                                    fontSize: 12
                                  }} 
                                />
                              ) : null;
                            })()}
                          </LineChart>
                        ) : (
                          <BarChart
                            data={formatChartData()}
                            margin={{ top: 20, right: 10, left: 0, bottom: 5 }}
                            barGap={0}
                            barCategoryGap={0}
                            className="sm:mr-10"
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis 
                              label={{ 
                                value: getYAxisLabel(), 
                                angle: -90, 
                                position: 'insideLeft',
                                offset: 10,
                                style: { textAnchor: 'middle' },
                                dy: -10,
                                className: "hidden sm:block"
                              }}
                            />
                            <Tooltip 
                              formatter={(value, name) => {
                                const metricName = name as string;
                                const metricType = metricName as MetricType;
                                return [formatMetricValue(value as number, metricType), metricsInfo?.metricTypes?.[metricType] || metricType];
                              }}
                            />
                            <Legend />
                            {METRIC_GROUPS[chartTab]
                              .filter(metric => currentMetrics.metrics[metric as MetricType] !== undefined)
                              .map((metric, index) => (
                                <Bar 
                                  key={metric} 
                                  dataKey={metric} 
                                  name={metricsInfo?.metricTypes?.[metric as MetricType] || metric}
                                  fill={CHART_COLORS[index % CHART_COLORS.length]} 
                                />
                              ))
                            }
                          </BarChart>
                        )}
                      </ResponsiveContainer>
                    </div>
                  ) : (
                    <div className="flex justify-center items-center h-64 text-gray-500">
                      No data available for the selected filters
                    </div>
                  )}
                </div>
              </div>
              
              {/* Team Leaderboard */}
              {activeTab === 'team' && (
                <div className="col-span-12 mt-4 leaderboard-section">
                  <div className="bg-white border border-gray-200 rounded-lg p-4">
                    <div className="flex justify-between items-center mb-6">
                      <div>
                        <h4 className="font-medium text-gray-700">Team Performance Rankings</h4>
                        <p className="text-sm text-gray-500 mt-1">
                          Ranked by {metricsInfo?.metricTypes?.[leaderboardMetric] || leaderboardMetric} 
                          {filters.dateRange.from && filters.dateRange.to && 
                            ` • ${formatDateRange(filters.dateRange)}`
                          }
                        </p>
                      </div>
                      <Select
                        value={leaderboardMetric}
                        onValueChange={(value) => handleLeaderboardMetricChange(value as MetricType)}
                      >
                        <SelectTrigger className="w-[180px] h-9 text-sm border border-gray-300 bg-white">
                          <SelectValue placeholder="Select metric" />
                        </SelectTrigger>
                        <SelectContent>
                          {metricsInfo?.metricTypes && Object.entries(metricsInfo.metricTypes).map(([key, label]) => (
                            <SelectItem key={key} value={key}>{label}</SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </div>
                    
                    {isLoadingLeaderboard ? (
                      <div className="h-64 flex items-center justify-center">
                        <Loader2 className="h-8 w-8 animate-spin text-primary" />
                      </div>
                    ) : leaderboard ? (
                      <div className="overflow-x-auto">
                        <MetricsLeaderboard 
                          metricType={leaderboardMetric} 
                          timeframe={filters.timeframe}
                          dateRange={{
                            from: filters.dateRange.from ? format(filters.dateRange.from, 'yyyy-MM-dd') : '',
                            to: filters.dateRange.to ? format(filters.dateRange.to, 'yyyy-MM-dd') : ''
                          }}
                        />
                      </div>
                    ) : (
                      <div className="flex justify-center items-center h-64 text-gray-500">
                        No leaderboard data available
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </PageContainer>
        
        {/* Onboarding Overlay */}
        <OnboardingOverlay />
      </MainLayout>
    </OnboardingProvider>
  );
} 