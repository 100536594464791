import { Lead } from '@/types';
import { CommunicationPane as ModularCommunicationPane, CommunicationPaneDrawer as ModularCommunicationPaneDrawer } from './communication-pane';

interface CommunicationPaneProps {
  phoneNumber: string;
  className?: string;
  name?: string;
  isDrawer?: boolean;
  leadId?: number | null;
}

interface CommunicationPaneDrawerProps {
  lead: Lead;
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export function CommunicationPane(props: CommunicationPaneProps) {
  return <ModularCommunicationPane {...props} />;
}

export function CommunicationPaneDrawer(props: CommunicationPaneDrawerProps) {
  return <ModularCommunicationPaneDrawer {...props} />;
} 