import { api } from '@/lib/api';
import { cn } from '@/lib/utils';
import { ActionItem as ActionItemType } from '@/services/dashboard';
import { useLeadStore } from '@/store/leadStore';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import { ArrowRight, CheckCircle } from 'lucide-react';

interface ActionItemProps {
  item: ActionItemType;
  onClick: (leadId: number) => void;
}

export function ActionItem({ item, onClick }: ActionItemProps) {
  const Icon = item.icon;
  const priorityColor = 
    item.priority === 'high' ? 'bg-red-100 text-red-700' : 
    item.priority === 'medium' ? 'bg-yellow-100 text-yellow-700' : 
    'bg-blue-100 text-blue-700';
  
  const navigate = useNavigate();
  const { setSelectedLead } = useLeadStore();
  const queryClient = useQueryClient();
  
  const handleClick = async () => {
    try {
      // If this is a message action item, mark it as read
      if (item.type === 'message' && item.messageId && item.conversationId) {
        try {
          console.log('[ActionItem] Marking message as read:', item.messageId);
          await api.twilio.markMessagesAsRead({ 
            messageId: item.messageId,
            conversationId: item.conversationId 
          });
          
          // Invalidate action items query to refresh the list
          queryClient.invalidateQueries({ queryKey: ['dashboard', 'action-items'] });
        } catch (error) {
          console.error('[ActionItem] Error marking message as read:', error);
        }
      }
      
      // Update the lead store with the selected lead ID
      setSelectedLead({ id: item.leadId });
      
      // Use setTimeout to ensure state updates complete before navigation
      setTimeout(() => {
        // Navigate to the leads page
        navigate({ to: '/leads' });
      }, 50);
      
      // Still call the original onClick handler if needed
      onClick(item.leadId);
    } catch (error) {
      console.error('[ActionItem] Navigation error:', error);
    }
  };
  
  return (
    <div
      className={cn(
        "flex items-start justify-between border-b border-gray-100 pb-4 last:border-0 last:pb-0 cursor-pointer hover:bg-gray-50 p-2 rounded-md transition-colors",
        item.isCompleted && "opacity-60"
      )}
      onClick={handleClick}
    >
      <div className="flex items-start space-x-4 min-w-0 flex-1 mb-2">
        <div className={`p-2 rounded-full ${priorityColor} flex-shrink-0 mt-0.5`}>
          <Icon className="h-4 w-4" />
        </div>
        <div className="min-w-0 flex-1">
          <div className="flex items-center gap-1">
            <p className={cn(
              "text-sm font-medium truncate max-w-[180px]",
              item.isCompleted && "line-through text-gray-500"
            )}>
              {item.leadName}
            </p>
            {item.isCompleted && (
              <CheckCircle className="h-3 w-3 text-green-500 flex-shrink-0" />
            )}
          </div>
          <p className={cn(
            "text-xs text-gray-500 break-words",
            item.isCompleted && "line-through"
          )}>
            {item.content}
          </p>
        </div>
      </div>
      <div className="flex items-center flex-shrink-0 ml-2">
        <span className="text-xs text-gray-500 mr-2 whitespace-nowrap">{item.time}</span>
        <ArrowRight className="h-3 w-3 text-gray-400 flex-shrink-0" />
      </div>
    </div>
  );
} 