import { toast } from '@/components/ui/use-toast';
import { SocketEvents, WebSocketService } from '@/services/websocket.service';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';

/**
 * Component that listens for WebSocket events related to dashboard updates
 * and invalidates the appropriate queries to refresh the data.
 */
export function WebSocketDashboardUpdates() {
  const queryClient = useQueryClient();
  const webSocketService = WebSocketService.getInstance();

  useEffect(() => {
    // Subscribe to new message events
    const newMessageUnsubscribe = webSocketService.on(SocketEvents.NEW_MESSAGE, (data: { 
      from: string; 
      body: string; 
      leadId?: number;
      leadName?: string;
      conversationId: number;
      direction: 'inbound' | 'outbound';
      timestamp: string;
    }) => {
      // Only show notifications for inbound messages
      if (data.direction === 'inbound') {
        // Invalidate dashboard queries to refresh data
        queryClient.invalidateQueries({ queryKey: ['dashboard'] });
        queryClient.invalidateQueries({ queryKey: ['dashboard', 'action-items'] });
        queryClient.invalidateQueries({ queryKey: ['dashboard', 'recent-activity'] });
        
        // Show toast notification for new message
        toast({
          title: 'New Message',
          description: `From ${data.leadName || 'Unknown'}: ${data.body?.substring(0, 50)}${data.body?.length > 50 ? '...' : ''}`,
          variant: 'default',
        });
      }
    });

    // Subscribe to call status update events
    const callStatusUnsubscribe = webSocketService.on(SocketEvents.CALL_STATUS_UPDATE, (data: { call: any; leadId: number }) => {
      // Add null check for data.call
      if (data?.call && data.call.status === 'missed') {
        // Invalidate dashboard queries to refresh data
        queryClient.invalidateQueries({ queryKey: ['dashboard'] });
        queryClient.invalidateQueries({ queryKey: ['dashboard', 'action-items'] });
        queryClient.invalidateQueries({ queryKey: ['dashboard', 'recent-activity'] });
        
        // Show toast notification for missed call
        toast({
          title: 'Missed Call',
          description: `From ${data.call.leadName || 'Lead'}`,
          variant: 'destructive',
        });
      }
    });

    // Subscribe to lead status change events
    const leadStatusUnsubscribe = webSocketService.on(SocketEvents.LEAD_STATUS_CHANGE, (data: { lead: any }) => {
      // Add null check for data.lead
      if (data?.lead) {
        // Invalidate dashboard queries to refresh data
        queryClient.invalidateQueries({ queryKey: ['dashboard'] });
        queryClient.invalidateQueries({ queryKey: ['dashboard', 'recent-activity'] });
        
        // Show toast notification for status change
        toast({
          title: 'Lead Status Changed',
          description: `${data.lead.name || 'Lead'} is now ${data.lead.status}`,
          variant: 'default',
        });
      }
    });

    // Subscribe to lead update events
    const leadUpdateUnsubscribe = webSocketService.on(SocketEvents.LEAD_UPDATE, () => {
      // Invalidate dashboard queries to refresh data
      queryClient.invalidateQueries({ queryKey: ['dashboard'] });
    });

    // Subscribe to lead note added events
    const leadNoteUnsubscribe = webSocketService.on(SocketEvents.LEAD_NOTE_ADDED, () => {
      // Invalidate dashboard queries to refresh data
      queryClient.invalidateQueries({ queryKey: ['dashboard', 'recent-activity'] });
    });

    // Cleanup subscriptions on unmount
    return () => {
      newMessageUnsubscribe();
      callStatusUnsubscribe();
      leadStatusUnsubscribe();
      leadUpdateUnsubscribe();
      leadNoteUnsubscribe();
    };
  }, [queryClient]);

  // This component doesn't render anything
  return null;
} 