// src/components/leads/LeadsList.tsx
import { api } from "@/lib/api";
import { cn } from "@/lib/utils";
import { Lead, LeadStatus } from "@/types";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useVirtualizer } from "@tanstack/react-virtual";
import { formatDistanceToNow } from "date-fns";
import { useCallback, useEffect, useRef } from "react";
import { LeadStatusBadge } from './LeadStatusBadge';

interface LeadsListProps {
  search?: string;
  selectedLeadId?: number | null;
  onLeadSelect: (id: number) => void;
  className?: string;
  filter: {
    search: string;
    type: 'all' | 'aged' | 'prime';
    status: LeadStatus | 'all';
    sortBy: 'leadScore' | 'lastContacted' | 'createdAt' | 'updatedAt' | 'name';
    sortOrder: 'asc' | 'desc';
  };
}

const LEADS_PER_PAGE = 50;
const ROW_HEIGHT = 80;

const STATUS_CONFIG: Record<LeadStatus, {
  color: string;
  bgColor: string;
  borderColor: string;
}> = {
  new: {
    color: "text-blue-600 dark:text-blue-400",
    bgColor: "bg-blue-50 dark:bg-blue-900/20",
    borderColor: "border-blue-200 dark:border-blue-800",
  },
  contacted: {
    color: "text-yellow-600 dark:text-yellow-400",
    bgColor: "bg-yellow-50 dark:bg-yellow-900/20",
    borderColor: "border-yellow-200 dark:border-yellow-800",
  },
  qualified: {
    color: "text-green-600 dark:text-green-400",
    bgColor: "bg-green-50 dark:bg-green-900/20",
    borderColor: "border-green-200 dark:border-green-800",
  },
  proposal: {
    color: "text-purple-600 dark:text-purple-400",
    bgColor: "bg-purple-50 dark:bg-purple-900/20",
    borderColor: "border-purple-200 dark:border-purple-800",
  },
  won: {
    color: "text-emerald-600 dark:text-emerald-400",
    bgColor: "bg-emerald-50 dark:bg-emerald-900/20",
    borderColor: "border-emerald-200 dark:border-emerald-800",
  },
  lost: {
    color: "text-red-600 dark:text-red-400",
    bgColor: "bg-red-50 dark:bg-red-900/20",
    borderColor: "border-red-200 dark:border-red-800",
  },
};

const getLeadScoreColor = (score: number) => {
  if (score > 0.7) return "bg-green-100 text-green-700 dark:bg-green-900/20 dark:text-green-400";
  if (score > 0.4) return "bg-yellow-100 text-yellow-700 dark:bg-yellow-900/20 dark:text-yellow-400";
  return "bg-red-100 text-red-700 dark:bg-red-900/20 dark:text-red-400";
};

export function LeadsList({ search, selectedLeadId, onLeadSelect, className, filter }: LeadsListProps) {
  const parentRef = useRef<HTMLDivElement>(null);
  const hasMeasuredRef = useRef(false); // Track initial measure to avoid loops

  // Infinite query for fetching leads
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    error,
  } = useInfiniteQuery({
    queryKey: ['leads', filter],
    queryFn: async ({ pageParam = 1 }) => {
      console.log('Fetching leads with params:', {
        page: pageParam,
        limit: LEADS_PER_PAGE,
        search: filter.search,
        type: filter.type,
        status: filter.status,
        sortBy: filter.sortBy,
        sortOrder: filter.sortOrder,
        includeArchived: false, // Always exclude archived leads
      });

      const response = await api.leads.list({
        page: pageParam,
        limit: LEADS_PER_PAGE,
        search: filter.search,
        type: filter.type,
        status: filter.status === 'all' ? undefined : filter.status,
        sortBy: filter.sortBy,
        sortOrder: filter.sortOrder,
        includeArchived: false, // Always exclude archived leads
      });

      console.log('Response:', response);
      return response;
    },
    getNextPageParam: (lastPage, allPages) => {
      const totalFetched = allPages.reduce((sum, page) => sum + page.leads.length, 0);
      return totalFetched < lastPage.total ? allPages.length + 1 : undefined;
    },
    initialPageParam: 1,
  });

  const allLeads = data?.pages.flatMap((page) => page.leads) ?? [];
  console.log("All Leads:", allLeads.length);

  // If the selected lead is no longer in the list (e.g., it was archived),
  // clear the selection
  useEffect(() => {
    if (selectedLeadId && allLeads.length > 0 && !allLeads.some(lead => lead.id === selectedLeadId)) {
      onLeadSelect(0); // Clear selection by passing an invalid ID
    }
  }, [selectedLeadId, allLeads, onLeadSelect]);

  // Virtualizer for efficient rendering
  const rowVirtualizer = useVirtualizer({
    count: allLeads.length + (hasNextPage ? 1 : 0),
    getScrollElement: () => parentRef.current,
    estimateSize: () => ROW_HEIGHT,
    overscan: 10,
  });

  // Trigger fetchNextPage when nearing the bottom
  useEffect(() => {
    const scrollElement = parentRef.current;
    if (!scrollElement || !hasNextPage || isFetchingNextPage) return;

    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = scrollElement;
      if (scrollTop + clientHeight >= scrollHeight - ROW_HEIGHT * 2 && !isFetchingNextPage) {
        console.log("Fetching next page...");
        fetchNextPage();
      }
    };

    scrollElement.addEventListener("scroll", handleScroll);
    return () => scrollElement.removeEventListener("scroll", handleScroll);
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

  // Measure virtualizer once after initial data load
  useEffect(() => {
    if (allLeads.length > 0 && parentRef.current && !hasMeasuredRef.current) {
      rowVirtualizer.measure();
      hasMeasuredRef.current = true;
      console.log("Measured Virtualizer Total Size:", rowVirtualizer.getTotalSize());
    }
  }, [allLeads]); // Only depend on allLeads, not rowVirtualizer

  // Memoized lead item renderer
  const renderLeadItem = useCallback(
    (lead: Lead, isSelected: boolean) => {
      const statusConfig = STATUS_CONFIG[lead.status];
      
      // Check if there are any metadata matches with the current search term
      const searchTerm = filter.search?.toLowerCase() || '';
      const metadataMatches: string[] = [];
      
      if (searchTerm && lead.customMetadata) {
        // Look through metadata for matches
        Object.entries(lead.customMetadata).forEach(([key, metaValue]) => {
          const valueStr = String(metaValue.value).toLowerCase();
          if (valueStr.includes(searchTerm)) {
            metadataMatches.push(`${key}: ${metaValue.value}`);
          }
        });
      }

      return (
        <div
          className={cn(
            "flex flex-col gap-1.5 py-2 px-4",
            `border-l-4 ${statusConfig?.borderColor}`,
            "hover:bg-gray-50 dark:hover:bg-gray-800/50 transition-all duration-200",
            "cursor-pointer select-none",
            isSelected && "bg-gray-50 dark:bg-gray-800/50"
          )}
          onClick={() => onLeadSelect(lead.id)}
        >
          <div className="flex items-start justify-between gap-4">
            <div className="flex flex-col min-w-0 gap-0.5">
              <h3 className="font-medium text-gray-900 dark:text-gray-100 truncate">
                {lead.name}
              </h3>
              {/* Show title and business name, or N/A if both are empty */}
              <span className="text-sm text-gray-600 dark:text-gray-400 truncate">
                {(lead.title || lead.businessName) 
                  ? [lead.title, lead.businessName].filter(Boolean).join(" at ")
                  : "N/A"}
              </span>
              <div className="flex items-center gap-2 text-xs text-gray-500 dark:text-gray-500">
                <span>{formatDistanceToNow(new Date(lead.createdAt))} ago</span>
                {lead.leadScore !== undefined && lead.callCount && lead.callCount > 0 && (
                  <span className="flex items-center gap-1">
                    •
                    <span className={cn(
                      "px-1.5 py-0.5 rounded-full text-xs",
                      getLeadScoreColor(lead.leadScore)
                    )}>
                      Score: {Math.round(lead.leadScore * 100)}
                    </span>
                  </span>
                )}
                {lead.callCount !== undefined && lead.callCount > 0 && (
                  <span className="flex items-center gap-1">
                    •
                    <span className="text-gray-400">
                      {lead.callCount} call{lead.callCount !== 1 ? 's' : ''}
                    </span>
                  </span>
                )}
              </div>
              
              {/* Display metadata matches if any */}
              {metadataMatches.length > 0 && (
                <div className="mt-1 flex flex-wrap gap-1">
                  {metadataMatches.map((match, index) => (
                    <span 
                      key={index}
                      className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-50 text-blue-700 dark:bg-blue-900/30 dark:text-blue-300"
                    >
                      {match}
                    </span>
                  ))}
                </div>
              )}
            </div>
            <LeadStatusBadge status={lead.status} />
          </div>
        </div>
      );
    },
    [onLeadSelect, filter.search]
  );

  if (isLoading) {
    return (
      <div className="flex h-full items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-2 border-gray-300 border-t-gray-900 dark:border-t-white"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex h-full items-center justify-center">
        <div className="text-center">
          <div className="text-red-500 dark:text-red-400 font-medium mb-2">
            Failed to load leads
          </div>
          <div className="text-sm text-gray-600 dark:text-gray-400">
            {error instanceof Error ? error.message : "An error occurred"}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div ref={parentRef} className={cn("h-full w-full overflow-auto bg-white dark:bg-gray-900", className)}>
      {allLeads.length === 0 ? (
        <div className="flex h-full items-center justify-center text-gray-500 dark:text-gray-400">
          <span>No leads found</span>
        </div>
      ) : (
        <div
          className="relative w-full"
          style={{ height: `${rowVirtualizer.getTotalSize()}px` }}
        >
          {rowVirtualizer.getVirtualItems().map((virtualItem) => {
            const isLoaderRow = virtualItem.index >= allLeads.length;
            return (
              <div
                key={virtualItem.key}
                className="absolute top-0 left-0 w-full"
                style={{
                  height: `${virtualItem.size}px`,
                  transform: `translateY(${virtualItem.start}px)`,
                }}
              >
                {isLoaderRow ? (
                  hasNextPage && (
                    <div className="flex items-center justify-center w-full h-full">
                      <div className="animate-spin rounded-full h-8 w-8 border-2 border-gray-300 border-t-gray-900 dark:border-t-white"></div>
                    </div>
                  )
                ) : (
                  renderLeadItem(allLeads[virtualItem.index], allLeads[virtualItem.index].id === selectedLeadId)
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}