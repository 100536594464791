import { Button } from '@/components/ui/button';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { motion } from 'framer-motion';
import { HelpCircle } from 'lucide-react';
import { useOnboarding } from './OnboardingContext';

export function OnboardingButton() {
  const { startOnboarding, resetOnboarding } = useOnboarding();

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <Button
              variant="ghost"
              size="icon"
              onClick={resetOnboarding}
              className="text-gray-500 hover:text-primary hover:bg-primary/10"
              aria-label="Start dashboard tour"
            >
              <motion.div
                animate={{ rotate: [0, 10, -10, 10, 0] }}
                transition={{ 
                  duration: 1.5, 
                  repeat: Infinity, 
                  repeatType: "loop", 
                  repeatDelay: 5 
                }}
              >
                <HelpCircle size={18} />
              </motion.div>
            </Button>
          </motion.div>
        </TooltipTrigger>
        <TooltipContent>
          <p>Take a tour of the dashboard</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
} 