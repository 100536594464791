import { ArticleLayout } from "@/components/help/ArticleLayout";

export function DashboardOverviewArticle() {
  return (
    <ArticleLayout>
      <h1 className="text-2xl font-bold mb-4 mt-0">Dashboard Overview</h1>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Understanding Your Dashboard</h2>
      <p className="mb-4">
        The LeadGPT dashboard is your command center for monitoring performance, tracking leads, and managing daily tasks. This guide will help you understand each section and make the most of your dashboard.
      </p>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Accessing the Dashboard</h2>
      <p className="mb-4">
        After logging in to LeadGPT, you'll automatically be directed to the Dashboard. You can also access it at any time by clicking on the "Dashboard" option in the sidebar navigation.
      </p>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Key Performance Metrics</h2>
      <p className="mb-4">
        At the top of your dashboard, you'll find key performance metrics divided into three tabs:
      </p>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Lead Metrics</h3>
      <p className="mb-4">
        The "Lead Metrics" tab provides insights into your lead activity and conversion:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Total Leads:</strong> The number of leads in your system in the selected time period</li>
        <li className="mb-1"><strong>New Leads:</strong> The number of new leads added in the selected time period</li>
        <li className="mb-1"><strong>Conversion Rate:</strong> The percentage of leads that have converted to customers</li>
        <li className="mb-1"><strong>Response Time:</strong> Your average time to respond to lead inquiries</li>
      </ul>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Communication</h3>
      <p className="mb-4">
        The "Communication" tab shows metrics related to your outreach activities:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Calls Made:</strong> The number of calls you've made in the selected time period</li>
        <li className="mb-1"><strong>Messages Sent:</strong> The number of messages you've sent</li>
        <li className="mb-1"><strong>Call Answer Rate:</strong> The percentage of successful calls (answered calls)</li>
        <li className="mb-1"><strong>Message Response Rate:</strong> The percentage of messages that received replies</li>
      </ul>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Team Comparison</h3>
      <p className="mb-4">
        If you're part of a team, this tab allows you to see how your performance compares to your teammates:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Team Member Rankings:</strong> See how team members rank on key metrics</li>
        <li className="mb-1"><strong>Performance Comparison:</strong> Compare your metrics to the team average</li>
        <li className="mb-1"><strong>Top Performers:</strong> Identify who's achieving the best results</li>
      </ul>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Action Center</h2>
      <p className="mb-4">
        The Action Center displays tasks requiring your attention:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Follow-ups:</strong> Scheduled follow-ups with leads</li>
        <li className="mb-1"><strong>Tasks:</strong> Tasks you've created or been assigned</li>
        <li className="mb-1"><strong>Notifications:</strong> Important system notifications</li>
        <li className="mb-1"><strong>Missed Activities:</strong> Calls or messages that need responses</li>
      </ul>
      <p className="mb-4">
        Features of the Action Center include:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Priority Sorting:</strong> Tasks are automatically sorted by priority and due date</li>
        <li className="mb-1"><strong>Quick Actions:</strong> Buttons to complete tasks, reschedule, or view the associated lead</li>
        <li className="mb-1"><strong>Filtering:</strong> Option to show or hide completed tasks</li>
        <li className="mb-1"><strong>Infinite Scrolling:</strong> Easily browse through all your tasks by scrolling</li>
      </ul>
      <p className="mb-4">
        Click on any action item to be taken directly to the relevant section where you can address it. Use the toggle at the top to show or hide completed items.
      </p>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Activity Feed</h2>
      <p className="mb-4">
        The Activity Feed shows your recent interactions with leads:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Call Records:</strong> Details of recent calls, including duration and outcome</li>
        <li className="mb-1"><strong>Message Logs:</strong> Records of text messages and emails sent or received</li>
        <li className="mb-1"><strong>Status Changes:</strong> Updates when a lead's status changes</li>
        <li className="mb-1"><strong>Lead Assignments:</strong> Notifications when new leads are assigned to you</li>
      </ul>
      <p className="mb-4">
        This provides a chronological view of your sales activities and helps you keep track of your recent communications. You can click on any activity to view more details or navigate to the associated lead.
      </p>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Time Frame Selection</h2>
      <p className="mb-4">
        At the top of the Dashboard, you'll find a date range selector that allows you to filter the data displayed. Options include:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Today:</strong> Show data for the current day only</li>
        <li className="mb-1"><strong>Yesterday:</strong> Show data for the previous day</li>
        <li className="mb-1"><strong>Last 7 days:</strong> Show data for the previous week</li>
        <li className="mb-1"><strong>Last 14 days:</strong> Show data for the last two weeks</li>
        <li className="mb-1"><strong>Last 30 days:</strong> Show data for the last month</li>
        <li className="mb-1"><strong>This week:</strong> Show data for the current week</li>
        <li className="mb-1"><strong>Last week:</strong> Show data for the previous week</li>
        <li className="mb-1"><strong>This month:</strong> Show data for the current month</li>
        <li className="mb-1"><strong>Last month:</strong> Show data for the previous month</li>
        <li className="mb-1"><strong>Custom Range:</strong> Select a specific date range</li>
      </ul>
      <p className="mb-4">
        Changing the date range will update all metrics and charts on the dashboard to reflect data from the selected period.
      </p>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Exporting Reports</h2>
      <p className="mb-4">
        You can export Dashboard data as a report by clicking the "Export Report" button:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Download Reports:</strong> Save reports in various formats</li>
        <li className="mb-1"><strong>Email Reports:</strong> Send reports directly to your email</li>
        <li className="mb-1"><strong>Customize Content:</strong> Select which metrics and data to include in the report</li>
        <li className="mb-1"><strong>Date Range:</strong> Choose the time period for the report data</li>
      </ul>
      <p className="mb-4">
        This feature is particularly useful for sharing performance data with team members or preparing for meetings.
      </p>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Tips for Using the Dashboard Effectively</h2>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Check the Dashboard daily:</strong> Make it a habit to review your Dashboard at the start of each day to plan your activities</li>
        <li className="mb-1"><strong>Complete action items promptly:</strong> Address tasks in the Action Center as they appear to maintain an efficient workflow</li>
        <li className="mb-1"><strong>Monitor your metrics:</strong> Regularly review your performance metrics to identify areas for improvement</li>
        <li className="mb-1"><strong>Use the time frame selector:</strong> Adjust the time frame to gain insights into different periods of your sales activity</li>
        <li className="mb-1"><strong>Export reports regularly:</strong> Generate weekly or monthly reports to track your progress over time</li>
        <li className="mb-1"><strong>Follow up on activity:</strong> Use the Activity Feed to ensure nothing falls through the cracks</li>
        <li className="mb-1"><strong>Switch between tabs:</strong> Use all three metric tabs to get a complete picture of your performance</li>
      </ul>
      
      <p className="mb-4 mt-8">
        Your dashboard is designed to provide you with actionable insights that help you focus your efforts and improve your results. By regularly monitoring this information, you can make more informed decisions about which leads to prioritize and which activities drive the best outcomes.
      </p>
    </ArticleLayout>
  );
} 