import { CallService } from '@/services/call.service';
import { create } from 'zustand';

export interface ActiveCallState {
  callSid: string;
  leadId?: number;
  leadName?: string;
  from: string;
  to: string;
  status: 'initializing' | 'preparing' | 'ringing' | 'connecting' | 'in-progress' | 'completed' | 'failed';
  direction: 'inbound' | 'outbound';
  isAnsweringInProgress: boolean;
  deviceId: string; // To identify which device is handling the call
}

interface CallStore {
  // Active call state
  activeCall: ActiveCallState | null;
  isGloballyAnswered: boolean;
  isAnyDeviceRinging: boolean;
  deviceId: string; // Unique ID for this browser instance
  
  // Actions
  registerDevice: (deviceId: string) => void;
  setActiveCall: (call: ActiveCallState | null) => void;
  updateActiveCall: (updates: Partial<ActiveCallState>) => void;
  handleIncomingCall: (callData: {
    callSid: string;
    from: string;
    to: string;
    leadId?: number;
    leadName?: string;
  }) => void;
  setCallAnsweringInProgress: (inProgress: boolean) => void;
  setCallAnswered: (answered: boolean) => void;
  setDeviceRinging: (isRinging: boolean) => void;
  
  // Call handling
  answerCall: (callbacks?: {
    onConnected?: (callSid: string) => void;
    onDisconnected?: () => void;
    onError?: (error: Error) => void;
  }) => Promise<void>;
  hangupCall: () => Promise<void>;
}

// Generate a unique device ID that persists for this browser session
const generateDeviceId = () => {
  const existingId = sessionStorage.getItem('device_id');
  if (existingId) return existingId;
  
  const newId = `device_${Date.now()}_${Math.random().toString(36).substring(2, 9)}`;
  sessionStorage.setItem('device_id', newId);
  return newId;
};

export const useCallStore = create<CallStore>((set, get) => ({
  activeCall: null,
  isGloballyAnswered: false,
  isAnyDeviceRinging: false,
  deviceId: generateDeviceId(),
  
  registerDevice: (deviceId) => set({ deviceId }),
  
  setActiveCall: (call) => set({ activeCall: call }),
  
  updateActiveCall: (updates) => set(state => ({
    activeCall: state.activeCall ? { ...state.activeCall, ...updates } : null
  })),
  
  handleIncomingCall: (callData) => {
    const currentState = get();
    
    // If a call is already being handled globally, don't show this notification
    if (currentState.isGloballyAnswered) {
      console.log(`[CallStore] Call ${callData.callSid} already being handled globally, ignoring on this device`);
      return;
    }
    
    // If this device is already handling a call, update the existing call
    if ((currentState.activeCall?.status === 'preparing' || currentState.activeCall?.status === 'ringing') && 
        currentState.activeCall.deviceId === currentState.deviceId) {
      set({
        activeCall: {
          ...currentState.activeCall,
          ...callData,
        },
        isAnyDeviceRinging: true
      });
      return;
    }
    
    // If any other device is ringing, don't ring on this device too
    if (currentState.isAnyDeviceRinging) {
      console.log(`[CallStore] Another device is already ringing, ignoring on this device`);
      return;
    }
    
    // Set this device with preparing status first
    set({
      activeCall: {
        ...callData,
        status: 'preparing',
        direction: 'inbound',
        isAnsweringInProgress: false,
        deviceId: currentState.deviceId
      },
      isAnyDeviceRinging: true
    });
    
    // Broadcast to other tabs/devices that we're handling this call
    window.localStorage.setItem('call_ringing_device', currentState.deviceId);
    window.localStorage.setItem('call_ringing_time', Date.now().toString());
    
    // After a short delay, transition to 'ringing' status
    setTimeout(() => {
      const state = get();
      if (state.activeCall?.callSid === callData.callSid && state.activeCall.status === 'preparing') {
        set({
          activeCall: {
            ...state.activeCall,
            status: 'ringing'
          }
        });
      }
    }, 3000); // 3 seconds delay before allowing answering
  },
  
  setCallAnsweringInProgress: (inProgress) => set(state => ({
    activeCall: state.activeCall ? { ...state.activeCall, isAnsweringInProgress: inProgress } : null
  })),
  
  setCallAnswered: (answered) => set({ isGloballyAnswered: answered }),
  
  setDeviceRinging: (isRinging) => set({ isAnyDeviceRinging: isRinging }),
  
  answerCall: async (callbacks) => {
    const { activeCall, deviceId } = get();
    
    if (!activeCall?.callSid) {
      throw new Error('No active call to answer');
    }
    
    // Set call as being answered
    set(state => ({
      activeCall: state.activeCall ? { 
        ...state.activeCall, 
        status: 'connecting',
        isAnsweringInProgress: true 
      } : null,
      isGloballyAnswered: true
    }));
    
    // Broadcast to other tabs/devices that call is being answered
    window.localStorage.setItem('call_answered_device', deviceId);
    window.localStorage.setItem('call_answered_time', Date.now().toString());
    
    try {
      // Accept the call
      await CallService.acceptIncomingCall(activeCall.callSid, {
        onConnected: (callSid) => {
          set(state => ({
            activeCall: state.activeCall ? { 
              ...state.activeCall, 
              status: 'in-progress',
              isAnsweringInProgress: false
            } : null
          }));
          
          if (callbacks?.onConnected) {
            callbacks.onConnected(callSid);
          }
        },
        onDisconnected: () => {
          set({
            activeCall: null,
            isGloballyAnswered: false,
            isAnyDeviceRinging: false
          });
          
          // Clear local storage flags
          window.localStorage.removeItem('call_ringing_device');
          window.localStorage.removeItem('call_ringing_time');
          window.localStorage.removeItem('call_answered_device');
          window.localStorage.removeItem('call_answered_time');
          
          if (callbacks?.onDisconnected) {
            callbacks.onDisconnected();
          }
        },
        onError: (error) => {
          set(state => ({
            activeCall: state.activeCall ? { 
              ...state.activeCall, 
              status: 'failed',
              isAnsweringInProgress: false
            } : null,
            isGloballyAnswered: false
          }));
          
          if (callbacks?.onError) {
            callbacks.onError(error);
          }
        }
      });
    } catch (error) {
      set(state => ({
        activeCall: state.activeCall ? { 
          ...state.activeCall, 
          status: 'failed',
          isAnsweringInProgress: false
        } : null,
        isGloballyAnswered: false
      }));
      
      throw error;
    }
  },
  
  hangupCall: async () => {
    const { activeCall, deviceId } = get();
    
    if (!activeCall?.callSid) {
      return;
    }
    
    try {
      if (activeCall.status === 'ringing') {
        await CallService.rejectIncomingCall(activeCall.callSid);
      } else {
        CallService.disconnectAll();
      }
      
      set({
        activeCall: null,
        isGloballyAnswered: false,
        isAnyDeviceRinging: false
      });
      
      // Clear local storage flags
      window.localStorage.removeItem('call_ringing_device');
      window.localStorage.removeItem('call_ringing_time');
      window.localStorage.removeItem('call_answered_device');
      window.localStorage.removeItem('call_answered_time');
    } catch (error) {
      console.error('Error hanging up call:', error);
      throw error;
    }
  }
})); 