import { Attachment, attachmentsApi } from '@/lib/attachments';
import { bytesToSize, cn } from '@/lib/utils';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { FileIcon, Loader2, PaperclipIcon, Trash2, Upload } from 'lucide-react';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { z } from 'zod';
import { Button } from '../../ui/button';
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '../../ui/dialog';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '../../ui/form';
import { Input } from '../../ui/input';
import { Progress } from '../../ui/progress';
import { Textarea } from '../../ui/textarea';
import { formFieldClasses } from './utils';

interface AttachmentsSectionProps {
  leadId: number;
  className?: string;
}

const uploadFormSchema = z.object({
  file: z.instanceof(FileList).transform(list => list.item(0)).refine((file): file is File => file !== null, {
    message: "File is required"
  }),
  description: z.string().optional()
});

type UploadFormValues = z.infer<typeof uploadFormSchema>;

export function AttachmentsSection({ leadId, className }: AttachmentsSectionProps) {
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedAttachment, setSelectedAttachment] = useState<Attachment | null>(null);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const queryClient = useQueryClient();

  // Query for attachments
  const { data: attachments = [], isLoading } = useQuery({
    queryKey: ['attachments', leadId],
    queryFn: () => attachmentsApi.getLeadAttachments(leadId),
    staleTime: 0, // Always fetch fresh data
    refetchOnMount: true,
    refetchOnWindowFocus: true
  });

  const form = useForm<UploadFormValues>({
    resolver: zodResolver(uploadFormSchema),
    defaultValues: {
      description: ''
    }
  });

  const handleUpload = async (values: UploadFormValues) => {
    const file = values.file;
    
    try {
      setIsUploading(true);
      setUploadProgress(0);

      // Get upload URL
      console.log('[AttachmentsSection] Getting pre-signed URL...');
      const { uploadUrl, fileKey } = await attachmentsApi.getUploadUrl(leadId, file.name, file.type);

      // Upload file to S3 with progress tracking
      console.log('[AttachmentsSection] Starting file upload...');
      await attachmentsApi.uploadFile(uploadUrl, file, (progress: number) => {
        setUploadProgress(progress);
      });

      // Create attachment record
      console.log('[AttachmentsSection] Creating attachment record...');
      const newAttachment = await attachmentsApi.createAttachment(leadId, {
        fileName: file.name,
        fileKey,
        fileType: file.type,
        fileSize: file.size,
        originalName: file.name,
        description: values.description
      });

      // Update cache with new attachment
      queryClient.setQueryData(['attachments', leadId], (old: Attachment[] = []) => {
        return [...old, newAttachment];
      });

      // Reset form and close dialog
      form.reset();
      setUploadDialogOpen(false);

      // Invalidate queries to ensure data is fresh
      queryClient.invalidateQueries({ queryKey: ['attachments', leadId] });
      queryClient.invalidateQueries({ queryKey: ['lead', leadId] });

      toast.success('File uploaded successfully');
    } catch (error) {
      console.error('[AttachmentsSection] Upload error:', error);
      toast.error('Failed to upload file');
    } finally {
      setIsUploading(false);
      setUploadProgress(0);
    }
  };

  const confirmDelete = useCallback((attachment: Attachment) => {
    setSelectedAttachment(attachment);
    setIsDeleteDialogOpen(true);
  }, []);

  const handleDelete = useCallback(async () => {
    if (!selectedAttachment) return;

    try {
      await attachmentsApi.deleteAttachment(selectedAttachment.id);
      
      // Update cache
      queryClient.setQueryData(['attachments', leadId], (old: Attachment[] = []) => {
        return old.filter(a => a.id !== selectedAttachment.id);
      });

      // Invalidate queries
      queryClient.invalidateQueries({ queryKey: ['attachments', leadId] });
      queryClient.invalidateQueries({ queryKey: ['lead', leadId] });

      toast.success('Attachment deleted');
    } catch (error) {
      console.error('[AttachmentsSection] Delete error:', error);
      toast.error('Failed to delete attachment');
    } finally {
      setIsDeleteDialogOpen(false);
      setSelectedAttachment(null);
    }
  }, [leadId, queryClient, selectedAttachment]);

  return (
    <div className={cn(formFieldClasses.section, className)}>
      <div className="flex items-center justify-between mb-3">
        <h3 className="text-[10px] font-normal text-gray-400 dark:text-gray-400 mb-0 block">Attachments</h3>
        <Button 
          variant="ghost" 
          size="sm" 
          className="h-6 px-2 text-xs text-blue-600 hover:text-blue-700 hover:bg-blue-50 dark:text-blue-400 dark:hover:bg-blue-900/20"
          onClick={() => setUploadDialogOpen(true)}
        >
          <Upload className="h-3 w-3 mr-1" />
          Upload
        </Button>
      </div>

      {isLoading ? (
        <div className="flex items-center justify-center py-4">
          <Loader2 className="h-4 w-4 animate-spin text-gray-400" />
        </div>
      ) : attachments.length === 0 ? (
        <div className="flex flex-col items-center justify-center py-4 text-center">
          <PaperclipIcon className="h-8 w-8 text-gray-300 dark:text-gray-600" />
          <p className="mt-2 text-xs text-gray-500 dark:text-gray-400 font-medium">No attachments yet</p>
          <p className="text-xs text-gray-400">Upload files to keep track of important documents</p>
        </div>
      ) : (
        <div className="space-y-1">
          {attachments.map((attachment) => (
            <div
              key={attachment.id}
              className="flex items-center justify-between rounded-md border border-gray-100 dark:border-gray-800 p-2 text-sm group hover:bg-gray-50 dark:hover:bg-gray-800/50"
            >
              <div className="flex items-center space-x-2 min-w-0">
                <FileIcon className="h-4 w-4 text-gray-400 flex-shrink-0" />
                <div className="min-w-0">
                  <p className="font-medium text-sm truncate">{attachment.fileName}</p>
                  <p className="text-xs text-gray-500 dark:text-gray-400 truncate">
                    {bytesToSize(attachment.fileSize)}
                    {attachment.description && ` • ${attachment.description}`}
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-1">
                <Button
                  variant="ghost"
                  size="icon"
                  className="h-7 w-7 opacity-40 hover:opacity-100 transition-opacity cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-800"
                  onClick={() => {
                    if (attachment.downloadUrl) {
                      window.open(attachment.downloadUrl, '_blank');
                    }
                  }}
                >
                  <FileIcon className="h-4 w-4" />
                </Button>
                <Button
                  variant="ghost"
                  size="icon"
                  className="h-7 w-7 opacity-40 hover:opacity-100 transition-opacity text-red-500 hover:text-red-600 cursor-pointer hover:bg-red-50 dark:hover:bg-red-900/20"
                  onClick={() => confirmDelete(attachment)}
                >
                  <Trash2 className="h-4 w-4" />
                </Button>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Upload Dialog */}
      <Dialog open={uploadDialogOpen} onOpenChange={setUploadDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Upload Attachment</DialogTitle>
            <DialogDescription>
              Upload a file to attach to this lead.
            </DialogDescription>
          </DialogHeader>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(handleUpload)} className="space-y-4">
              <FormField
                control={form.control}
                name="file"
                render={({ field: { onChange, value, ...rest } }) => (
                  <FormItem>
                    <FormLabel>File</FormLabel>
                    <FormControl>
                      <Input
                        type="file"
                        onChange={(e) => {
                          onChange(e.target.files);
                        }}
                        {...rest}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="description"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Description (optional)</FormLabel>
                    <FormControl>
                      <Textarea
                        placeholder="Enter a description for this file"
                        className="resize-none"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              {isUploading && (
                <div className="space-y-2">
                  <Progress value={uploadProgress} className="h-2" />
                  <p className="text-xs text-center text-gray-500">
                    Uploading... {Math.round(uploadProgress)}%
                  </p>
                </div>
              )}
              <DialogFooter>
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => setUploadDialogOpen(false)}
                  disabled={isUploading}
                >
                  Cancel
                </Button>
                <Button type="submit" disabled={isUploading}>
                  {isUploading ? (
                    <>
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      Uploading
                    </>
                  ) : (
                    'Upload'
                  )}
                </Button>
              </DialogFooter>
            </form>
          </Form>
        </DialogContent>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Delete Attachment</DialogTitle>
            <DialogDescription>
              Are you sure you want to delete this attachment? This action cannot be undone.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => setIsDeleteDialogOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant="destructive"
              onClick={handleDelete}
            >
              Delete
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}