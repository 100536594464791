import { ArticleLayout } from "@/components/help/ArticleLayout";

export function LeadManagementArticle() {
  return (
    <ArticleLayout>
      <h1 className="text-2xl font-bold mb-4 mt-0">Managing Leads</h1>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Lead Management Overview</h2>
      <p className="mb-4">
        Effective lead management is crucial for sales success. LeadGPT provides powerful tools to organize, track, and convert your leads efficiently. This guide covers everything you need to know about managing your leads.
      </p>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Accessing the Leads Page</h2>
      <p className="mb-4">
        To access your leads, click on the "Leads" option in the sidebar navigation. This will take you to the Leads page, which consists of three main sections:
      </p>
      <ol className="list-decimal pl-6 mb-4">
        <li className="mb-1">Lead List (left panel)</li>
        <li className="mb-1">Lead Details (center panel)</li>
        <li className="mb-1">Communication Pane (right panel, optional)</li>
      </ol>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Lead List</h2>
      <p className="mb-4">
        The Lead List displays all your leads in a scrollable list with options for searching, filtering, and sorting:
      </p>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Searching for Leads</h3>
      <p className="mb-4">
        Use the search bar at the top to find leads by name, contact information, or metadata values. The search updates as you type, helping you quickly locate specific leads.
      </p>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Filtering and Sorting</h3>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Filters Button:</strong> Click to access filtering options including:
          <ul className="list-disc pl-6 mt-1">
            <li className="mb-1"><strong>Lead Type:</strong> Filter by All Leads or Aged Leads</li>
            <li className="mb-1"><strong>Status:</strong> Filter by status including New, Contacted, Qualified, Proposal, Won, Lost, and any custom statuses you've created</li>
          </ul>
        </li>
        <li className="mb-1"><strong>Sort Options:</strong> Sort leads by Lead Score, Last Contacted date, Date Added, or Name in ascending or descending order</li>
      </ul>
      <p className="mb-4">
        Active filters are indicated by a badge showing the number of filters applied. You can reset all filters using the "Reset Filters" option in the filter dropdown.
      </p>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Lead List Items</h3>
      <p className="mb-4">
        Each lead in the list displays key information at a glance:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Name:</strong> The lead's full name</li>
        <li className="mb-1"><strong>Business/Title:</strong> Their business name and job title (if available)</li>
        <li className="mb-1"><strong>Lead Score:</strong> A numerical score indicating the lead's potential value</li>
        <li className="mb-1"><strong>Last Contact:</strong> When the lead was last contacted</li>
        <li className="mb-1"><strong>Status:</strong> The current status in your sales pipeline</li>
      </ul>
      <p className="mb-4">
        The left border of each lead item is color-coded based on the lead's status for easy visual identification.
      </p>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Lead Details</h2>
      <p className="mb-4">
        When you select a lead from the list, their detailed information appears in the center panel, divided into several sections:
      </p>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Quick Actions Bar</h3>
      <p className="mb-4">
        At the top of the Lead Details panel, you'll find buttons for common actions:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Call:</strong> Initiate a call to the lead</li>
        <li className="mb-1"><strong>Text:</strong> Send a text message</li>
        <li className="mb-1"><strong>Email:</strong> Send an email</li>
        <li className="mb-1"><strong>Schedule:</strong> Schedule a meeting or follow-up</li>
        <li className="mb-1"><strong>Status Selector:</strong> Change the lead's status</li>
      </ul>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Contact Information</h3>
      <p className="mb-4">
        This section displays editable contact details, including name, phone, email, business, and address. Click on any field to edit it.
      </p>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Lead Score</h3>
      <p className="mb-4">
        Shows an AI-generated score indicating the lead's potential value, calculated based on communication history, engagement level, demographics, and detected buying signals.
      </p>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Action Items</h3>
      <p className="mb-4">
        Displays pending tasks and follow-ups, allowing you to mark items complete, add new action items, and set due dates and reminders.
      </p>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Notes & Attachments</h3>
      <p className="mb-4">
        Add and view notes about the lead and upload relevant files like documents or contracts. Notes are timestamped and attributed to the creator.
      </p>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Communication Pane</h2>
      <p className="mb-4">
        The right panel provides a unified view of all communications with the lead:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Communication History:</strong> Chronological history of all interactions</li>
        <li className="mb-1"><strong>Call Interface:</strong> Tools for making and receiving calls</li>
        <li className="mb-1"><strong>Text Messaging:</strong> Send and receive text messages</li>
      </ul>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Making Calls</h3>
      <p className="mb-4">
        To make a call to a lead:
      </p>
      <ol className="list-decimal pl-6 mb-4">
        <li className="mb-1">First, select a lead from your lead list to view their details</li>
        <li className="mb-1">The Communication Pane will appear on the right side (if not visible, look for a button to expand it)</li>
        <li className="mb-1">Click the "Voice" tab at the top of the Communication Pane</li>
        <li className="mb-1">The system will automatically select a phone number with a matching area code</li>
        <li className="mb-1">Click the "Start Call" button to initiate the call</li>
      </ol>
      <p className="mb-4">
        Note: You must first select a lead to access their phone number before placing a call. Direct dialing from a dial pad is not available.
      </p>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Lead Statuses and Pipeline</h2>
      <p className="mb-4">
        LeadGPT uses a standard pipeline with the following default statuses:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>New:</strong> Recently added leads that haven't been contacted</li>
        <li className="mb-1"><strong>Contacted:</strong> Leads you've reached out to but haven't shown significant interest</li>
        <li className="mb-1"><strong>Qualified:</strong> Leads that have shown interest and meet your qualification criteria</li>
        <li className="mb-1"><strong>Proposal:</strong> Leads who have received a proposal or quote</li>
        <li className="mb-1"><strong>Won:</strong> Successfully converted leads</li>
        <li className="mb-1"><strong>Lost:</strong> Leads that didn't convert or are no longer viable</li>
      </ul>
      <p className="mb-4">
        You can also create custom statuses with custom colors to match your specific sales process. These custom statuses will appear in the Status filter dropdown.
      </p>
      <p className="mb-4">
        You can move leads through these statuses using the Status Selector in the Quick Actions bar.
      </p>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Lead Types</h2>
      <p className="mb-4">
        LeadGPT organizes leads into different types to help you prioritize your efforts:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>All Leads:</strong> Shows all leads in your system</li>
        <li className="mb-1"><strong>Aged Leads:</strong> Leads that have been in your system for a specific period without progress</li>
      </ul>
      <p className="mb-4">
        You can filter by lead type using the Filter button in the Lead List.
      </p>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Lead Scoring</h2>
      <p className="mb-4">
        LeadGPT's AI-powered lead scoring helps you prioritize high-value prospects:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1">Leads are automatically scored based on engagement, demographics, and behavior</li>
        <li className="mb-1">Scores help you identify which leads are most likely to convert</li>
        <li className="mb-1">You can sort your leads by score to focus on the most promising opportunities</li>
      </ul>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Scoring Factors</h3>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Engagement Level:</strong> How responsive the lead has been to your communications</li>
        <li className="mb-1"><strong>Conversation Analysis:</strong> Keywords and sentiment detected in conversations</li>
        <li className="mb-1"><strong>Demographic Match:</strong> How well the lead matches your ideal customer profile</li>
        <li className="mb-1"><strong>Activity History:</strong> The lead's interaction history with your communications</li>
      </ul>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Communication and Follow-up</h2>
      <p className="mb-4">
        Maintain consistent communication with your leads:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1">Send text messages, emails, or make calls directly from the lead profile</li>
        <li className="mb-1">Schedule follow-ups with reminders</li>
        <li className="mb-1">Track all communications in the Communication Pane</li>
      </ul>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Lead Information Management</h2>
      <p className="mb-4">
        Keep your lead information organized and up-to-date:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1">Add notes to document important conversations and details</li>
        <li className="mb-1">Upload relevant files and documents to lead profiles</li>
        <li className="mb-1">Update contact information as needed</li>
        <li className="mb-1">Track status changes to monitor progress</li>
      </ul>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Best Practices for Lead Management</h2>
      <ol className="list-decimal pl-6 mb-4">
        <li className="mb-1">Update lead status regularly to reflect the current stage in your sales process</li>
        <li className="mb-1">Add detailed notes to document important information from calls and meetings</li>
        <li className="mb-1">Set follow-up tasks with due dates to ensure timely follow-ups</li>
        <li className="mb-1">Use the search and filter options to quickly find specific leads</li>
        <li className="mb-1">Sort by lead score to prioritize your most promising prospects</li>
        <li className="mb-1">Regularly review aged leads to reactivate or archive them</li>
      </ol>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Keyboard Shortcuts</h2>
      <p className="mb-4">
        Speed up your lead management with these helpful keyboard shortcuts:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Ctrl+F (Cmd+F on Mac):</strong> Focus the search bar</li>
        <li className="mb-1"><strong>Esc:</strong> Clear the search bar</li>
        <li className="mb-1"><strong>Enter (when a lead is highlighted):</strong> Select the lead</li>
        <li className="mb-1"><strong>Tab:</strong> Navigate between sections</li>
      </ul>
      
      <p className="mb-4 mt-8">
        For more information on analyzing your lead performance, please refer to the Analytics guide.
      </p>
    </ArticleLayout>
  );
} 