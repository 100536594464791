import { Alert, AlertDescription } from '@/components/ui/alert';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Label } from '@/components/ui/label';
import { PageHeader } from '@/components/ui/page-header';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Skeleton } from '@/components/ui/skeleton';
import { Switch } from '@/components/ui/switch';
import { toast } from '@/components/ui/use-toast';
import { CalendarEvent, CalendarService, GoogleCalendar } from '@/services/calendar.service';
import { useAuthStore } from '@/store/authStore';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';
import { Calendar, Check, ExternalLink, Loader2, RefreshCw, Settings, X } from 'lucide-react';
import { useEffect, useState } from 'react';

export default function CalendarIntegration() {
  const { user } = useAuthStore();
  const queryClient = useQueryClient();
  const [authError, setAuthError] = useState<string | null>(null);
  const [selectedCalendarId, setSelectedCalendarId] = useState<string | null>(null);

  // Get connected calendar integrations
  const { 
    data: integrations,
    isLoading: isLoadingIntegrations,
    error: integrationsError
  } = useQuery({
    queryKey: ['calendarIntegrations'],
    queryFn: () => CalendarService.getCalendarIntegrations(),
    refetchOnWindowFocus: false,
  });

  // Get the Google integration if available
  const googleIntegration = integrations?.find(i => i.provider === 'google');

  // Get available Google calendars
  const {
    data: googleCalendars,
    isLoading: isLoadingCalendars,
    error: calendarsError
  } = useQuery<GoogleCalendar[]>({
    queryKey: ['googleCalendars', googleIntegration?.id],
    queryFn: () => {
      if (!googleIntegration?.id) return Promise.resolve([]);
      return CalendarService.listGoogleCalendars(googleIntegration.id);
    },
    enabled: !!googleIntegration?.id,
  });

  // Get events from the selected calendar
  const {
    data: calendarEvents,
    isLoading: isLoadingEvents,
    error: eventsError
  } = useQuery<CalendarEvent[]>({
    queryKey: ['calendarEvents', googleIntegration?.id],
    queryFn: async () => {
      if (!googleIntegration?.id) return Promise.resolve([]);
      return CalendarService.getSyncedEvents(googleIntegration.id);
    },
    enabled: !!googleIntegration?.id && googleIntegration.syncEnabled,
  });

  // Get OAuth URL for Google Calendar
  const { 
    mutate: getAuthUrl,
    isPending: isGettingAuthUrl
  } = useMutation({
    mutationFn: () => {
      // Use a default team ID since we don't have access to the team structure
      const teamId = "default-team-id";
      return CalendarService.getGoogleAuthUrl(teamId);
    },
    onSuccess: (data) => {
      // Redirect to Google OAuth flow
      window.location.href = data.authUrl;
    },
    onError: (error: Error) => {
      setAuthError(error.message);
    },
  });

  // Select primary Google Calendar
  const {
    mutate: selectCalendar,
    isPending: isSelectingCalendar
  } = useMutation({
    mutationFn: ({ integrationId, calendarId }: { integrationId: string, calendarId: string }) => 
      CalendarService.selectGoogleCalendar(integrationId, calendarId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['calendarIntegrations'] });
      toast({
        title: "Calendar selected",
        description: "Your primary calendar has been updated."
      });
    },
    onError: (error: Error) => {
      setAuthError(error.message);
    }
  });
  
  // Set the selected calendar ID when calendars are loaded
  useEffect(() => {
    if (googleCalendars?.length && !selectedCalendarId && googleIntegration?.id) {
      // If we have a primary calendar, select it
      const primaryCalendar = googleCalendars.find(cal => cal.primary);
      if (primaryCalendar) {
        setSelectedCalendarId(primaryCalendar.id);
        // Also set this calendar on the server side
        selectCalendar({ 
          integrationId: googleIntegration.id, 
          calendarId: primaryCalendar.id 
        });
      } else if (googleCalendars.length > 0) {
        // If no primary calendar, select the first one
        setSelectedCalendarId(googleCalendars[0].id);
        // Also set this calendar on the server side
        selectCalendar({ 
          integrationId: googleIntegration.id, 
          calendarId: googleCalendars[0].id 
        });
      }
    }
  }, [googleCalendars, selectedCalendarId, googleIntegration?.id, selectCalendar]);

  // Toggle calendar sync
  const {
    mutate: toggleSync,
    isPending: isTogglingSync
  } = useMutation({
    mutationFn: ({ integrationId, enabled }: { integrationId: string, enabled: boolean }) => 
      CalendarService.toggleCalendarSync(integrationId, enabled),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['calendarIntegrations'] });
      toast({
        title: "Sync settings updated",
        description: "Your calendar sync settings have been updated."
      });
    },
    onError: (error: Error) => {
      setAuthError(error.message);
      toast({
        title: "Error",
        description: "Failed to update sync settings.",
        variant: "destructive"
      });
    }
  });

  // Manual sync
  const {
    mutate: syncNow,
    isPending: isSyncingNow
  } = useMutation({
    mutationFn: (integrationId: string) => 
      CalendarService.syncCalendarNow(integrationId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['calendarEvents'] });
      toast({
        title: "Sync completed",
        description: "Your calendar has been synchronized."
      });
    },
    onError: (error: Error) => {
      setAuthError(error.message);
      toast({
        title: "Sync failed",
        description: "Failed to synchronize your calendar.",
        variant: "destructive"
      });
    }
  });

  // Disconnect Google Calendar integration
  const { 
    mutate: disconnectCalendar,
    isPending: isDisconnecting 
  } = useMutation({
    mutationFn: (integrationId: string) => CalendarService.disconnectGoogleCalendar(integrationId),
    onSuccess: () => {
      // Invalidate calendar integration queries
      queryClient.invalidateQueries({ queryKey: ['calendarIntegrations'] });
      queryClient.invalidateQueries({ queryKey: ['leadDetailsCalendarIntegrations'] });
      queryClient.invalidateQueries({ queryKey: ['sidebarCalendarIntegrations'] });
      
      // Invalidate calendar events
      queryClient.invalidateQueries({ queryKey: ['calendarEvents'] });
      
      // Invalidate lead events - this will refresh meeting sections for all leads
      queryClient.invalidateQueries({ queryKey: ['leadEvents'] });
      
      toast({
        title: "Calendar disconnected",
        description: "Your Google Calendar has been disconnected and meeting data has been updated accordingly."
      });
    },
    onError: (error: Error) => {
      setAuthError(error.message);
      toast({
        title: "Disconnect failed",
        description: "Failed to disconnect calendar. Please try again.",
        variant: "destructive"
      });
    },
  });

  const handleConnect = () => {
    setAuthError(null);
    getAuthUrl();
  };

  const handleDisconnect = (integrationId: string) => {
    if (window.confirm(
      'Are you sure you want to disconnect this calendar?\n\n' +
      'This will also:\n' +
      '• Remove all meeting information from lead profiles\n' +
      '• Preserve meetings connected to leads for future reconnection\n' +
      '• Remove meetings not connected to leads'
    )) {
      disconnectCalendar(integrationId);
    }
  };

  const handleCalendarSelect = (calendarId: string) => {
    if (googleIntegration?.id) {
      setSelectedCalendarId(calendarId);
      selectCalendar({ integrationId: googleIntegration.id, calendarId });
    }
  };

  const handleToggleSync = (enabled: boolean) => {
    if (googleIntegration?.id) {
      toggleSync({ integrationId: googleIntegration.id, enabled });
    }
  };

  const handleSyncNow = () => {
    if (googleIntegration?.id) {
      syncNow(googleIntegration.id);
    }
  };

  // Group events by date for display
  const groupedEvents = calendarEvents?.reduce<Record<string, CalendarEvent[]>>((groups, event) => {
    const date = format(new Date(event.startTime), 'yyyy-MM-dd');
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(event);
    return groups;
  }, {});

  return (
    <div className="space-y-4">
      <div className="flex items-start justify-between">
        <PageHeader
          title="Google Calendar Integration"
          description="Connect your Google Calendar to schedule meetings with leads"
        />
        <img src="/assets/integrations/google-calendar.svg" alt="Google Calendar" className="h-16 w-16 rounded-lg" />
      </div>
      
      {authError && (
        <Alert variant="destructive">
          <AlertDescription>{authError}</AlertDescription>
        </Alert>
      )}

      <div className="mt-8 grid gap-6 md:grid-cols-2">
        <div>
          <Card>
            <CardHeader>
              <CardTitle>About Google Calendar Integration</CardTitle>
              <CardDescription>
                Seamlessly sync your appointments between LeadGPT and Google Calendar
              </CardDescription>
            </CardHeader>
            <CardContent className="space-y-4">
              <p>With the Google Calendar integration, you can:</p>
              <ul className="list-disc pl-5 space-y-2">
                <li>Schedule meetings with leads directly from LeadGPT</li>
                <li>Automatically sync events between platforms</li>
                <li>Get reminders for upcoming meetings</li>
                <li>Track appointment attendance</li>
              </ul>
              <Button 
                variant="outline" 
                className="mt-4" 
                onClick={() => window.open('https://calendar.google.com', '_blank')}
              >
                <span>Open Google Calendar</span>
                <ExternalLink className="ml-2 h-4 w-4" />
              </Button>
            </CardContent>
          </Card>

          {googleIntegration?.syncEnabled && (
            <Card className="mt-6">
              <CardHeader>
                <CardTitle className="flex items-center">
                  <Calendar className="h-5 w-5 mr-2" />
                  Calendar Dashboard
                </CardTitle>
                <CardDescription>
                  Manage all your calendar events in one place
                </CardDescription>
              </CardHeader>
              <CardContent className="space-y-4">
                <div className="text-sm">
                  <p>Your Google Calendar is now connected. Use the Calendar Dashboard to:</p>
                  <ul className="list-disc pl-5 mt-2 space-y-1.5">
                    <li>View upcoming events in a unified calendar</li>
                    <li>Create and edit events directly in the CRM</li>
                    <li>Connect calendar events to your leads</li>
                    <li>Add Google Meet links to your meetings</li>
                  </ul>
                </div>
                <div className="pt-2 flex justify-center">
                  <Button asChild size="lg" className="w-full sm:w-auto">
                    <a href="/calendar-dashboard">
                      <Calendar className="h-4 w-4 mr-2" />
                      Open Calendar Dashboard
                    </a>
                  </Button>
                </div>
              </CardContent>
            </Card>
          )}
        </div>

        <div>
          <Card>
            <CardHeader>
              <CardTitle>Connection Status</CardTitle>
              <CardDescription>
                Link your Google Calendar account to start syncing events
              </CardDescription>
            </CardHeader>
            <CardContent>
              {isLoadingIntegrations ? (
                <div className="flex items-center justify-center py-6">
                  <Loader2 className="h-8 w-8 animate-spin text-muted-foreground" />
                </div>
              ) : integrationsError ? (
                <Alert variant="destructive">
                  <AlertDescription>Failed to load integrations. Please try again later.</AlertDescription>
                </Alert>
              ) : (
                <div className="space-y-4">
                  {integrations?.filter(i => i.provider === 'google').length ? (
                    integrations
                      .filter(i => i.provider === 'google')
                      .map((integration) => (
                        <div key={integration.id} className="flex items-center justify-between border p-4 rounded-md">
                          <div className="space-y-1">
                            <div className="font-medium">Connected Account</div>
                            <div className="text-sm text-muted-foreground">
                              {integration.syncEnabled ? (
                                <Badge variant="outline" className="bg-green-50 text-green-700 border-green-200">
                                  <Check className="h-3 w-3 mr-1" /> Syncing
                                </Badge>
                              ) : (
                                <Badge variant="outline" className="bg-amber-50 text-amber-700 border-amber-200">
                                  Sync Disabled
                                </Badge>
                              )}
                            </div>
                          </div>
                          <div className="flex items-center gap-2">
                            <Button 
                              variant="outline" 
                              size="sm"
                              onClick={() => handleDisconnect(integration.id)}
                              disabled={isDisconnecting}
                            >
                              {isDisconnecting ? (
                                <Loader2 className="h-4 w-4 mr-1 animate-spin" />
                              ) : (
                                <X className="h-4 w-4 mr-1" />
                              )}
                              Disconnect
                            </Button>
                          </div>
                        </div>
                      ))
                  ) : (
                    <div className="flex flex-col items-center justify-center py-6 space-y-4">
                      <Calendar className="h-12 w-12 text-muted-foreground" />
                      <div className="text-center">
                        <p className="text-muted-foreground">No Google Calendar connected</p>
                        <p className="text-sm text-muted-foreground">
                          Connect your calendar to schedule meetings with leads easily.
                        </p>
                      </div>
                      <Button
                        onClick={handleConnect}
                        disabled={isGettingAuthUrl}
                      >
                        {isGettingAuthUrl ? (
                          <>
                            <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                            Connecting...
                          </>
                        ) : (
                          <>Connect Google Calendar</>
                        )}
                      </Button>
                    </div>
                  )}
                </div>
              )}
            </CardContent>
          </Card>

          {googleIntegration ? (
            <Card className="mt-6">
              <CardHeader>
                <CardTitle className="flex items-center">
                  <Settings className="h-5 w-5 mr-2" />
                  Calendar Settings
                </CardTitle>
                <CardDescription>
                  Configure your Google Calendar integration
                </CardDescription>
              </CardHeader>
              <CardContent className="space-y-6">
                <div className="space-y-4">
                  <div>
                    <h3 className="text-sm font-medium mb-2">Primary Calendar</h3>
                    {isLoadingCalendars ? (
                      <Skeleton className="h-10 w-full" />
                    ) : calendarsError ? (
                      <Alert variant="destructive">
                        <AlertDescription>Failed to load calendars</AlertDescription>
                      </Alert>
                    ) : (
                      <Select
                        value={selectedCalendarId || undefined}
                        onValueChange={handleCalendarSelect}
                        disabled={isSelectingCalendar}
                      >
                        <SelectTrigger className="w-full">
                          <SelectValue placeholder="Select a calendar" />
                        </SelectTrigger>
                        <SelectContent>
                          {googleCalendars && googleCalendars.map((calendar) => (
                            <SelectItem key={calendar.id} value={calendar.id}>
                              {calendar.summary} {calendar.primary && '(Primary)'}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    )}
                    <p className="text-sm text-muted-foreground mt-1">
                      Events will be created in this calendar
                    </p>
                  </div>

                  <div className="flex items-center justify-between pt-4">
                    <div className="space-y-0.5">
                      <Label htmlFor="sync-toggle">Automatic Sync</Label>
                      <p className="text-sm text-muted-foreground">
                        Automatically sync events between LeadGPT and Google Calendar
                      </p>
                    </div>
                    <Switch
                      id="sync-toggle"
                      checked={googleIntegration?.syncEnabled}
                      onCheckedChange={handleToggleSync}
                      disabled={isTogglingSync}
                    />
                  </div>

                  <div className="pt-4">
                    <Button
                      variant="outline"
                      size="sm"
                      className="w-full"
                      disabled={isSyncingNow || !googleIntegration.syncEnabled}
                      onClick={handleSyncNow}
                    >
                      {isSyncingNow ? (
                        <>
                          <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                          Syncing...
                        </>
                      ) : (
                        <>
                          <RefreshCw className="h-4 w-4 mr-2" />
                          Sync Now
                        </>
                      )}
                    </Button>
                    <p className="text-xs text-muted-foreground text-center mt-2">
                      Last sync: {new Date(googleIntegration.updatedAt).toLocaleString()}
                    </p>
                  </div>
                </div>
              </CardContent>
            </Card>
          ) : null}
        </div>
      </div>
    </div>
  );
} 