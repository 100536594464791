import { Lead } from '@/types';

interface OriginalCallFooterProps {
  formData: Partial<Lead>;
}

export function OriginalCallFooter({ formData }: OriginalCallFooterProps) {
  if (!formData.tsr || !formData.callDate) return null;

  return (
    <div className="p-3 px-4 sm:px-3 bg-gray-50 dark:bg-gray-800/50 text-xs text-gray-500 dark:text-gray-400 text-center">
      Original call by {formData.tsr.split(" ").slice(0, -1).join(" ")} on{" "}
      {new Date(formData.callDate).toLocaleString("en-US", {
        weekday: "short",
        month: "short",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      })}
    </div>
  );
} 