import { axiosInstance } from '@/lib/api';

// Types for Webhooks
export interface Webhook {
  id: string;
  url: string;
  events: string[];
  active: boolean;
  createdAt?: string;
  updatedAt?: string;
}

export interface WebhookEvent {
  id: string;
  name: string;
  description: string;
}

/**
 * Get all webhooks for the current user
 * @returns Promise with the webhooks response
 */
export async function getWebhooks(): Promise<{ 
  success: boolean; 
  webhooks?: Webhook[]; 
  message?: string; 
  code?: string; 
  description?: string 
}> {
  try {
    const response = await axiosInstance.get('/integrations/webhooks');
    
    // Ensure the webhook data is properly formatted
    if (response.data.success && response.data.webhooks) {
      // Format each webhook
      response.data.webhooks = response.data.webhooks.map((webhook: any) => {
        // Make sure the ID is a string
        if (typeof webhook.id === 'number') {
          webhook.id = webhook.id.toString();
        }
        
        // Ensure events is an array
        if (webhook.events && !Array.isArray(webhook.events)) {
          console.warn('Webhook events is not an array:', webhook.events);
          try {
            // Try to parse it if it's a JSON string
            if (typeof webhook.events === 'string') {
              webhook.events = JSON.parse(webhook.events);
            }
          } catch (e) {
            console.error('Failed to parse webhook events:', e);
            webhook.events = [];
          }
        }
        
        return webhook;
      });
    }
    
    return response.data;
  } catch (error: any) {
    console.error('Error fetching webhooks:', error);
    return { 
      success: false, 
      message: error.response?.data?.message || error.message || 'Unknown error occurred' 
    };
  }
}

/**
 * Get available webhook events
 * @returns Promise with the events response
 */
export async function getWebhookEvents(): Promise<{ 
  success: boolean; 
  events?: WebhookEvent[]; 
  message?: string; 
  code?: string; 
  description?: string 
}> {
  try {
    const response = await axiosInstance.get('/integrations/webhooks/events');
    return response.data;
  } catch (error: any) {
    console.error('Error fetching webhook events:', error);
    return { 
      success: false, 
      message: error.response?.data?.message || error.message || 'Unknown error occurred' 
    };
  }
}

/**
 * Create a new webhook
 * @param webhookData The webhook data to submit
 * @returns Promise with the webhook creation response
 */
export async function createWebhook(webhookData: {
  url: string;
  events: string[];
  active?: boolean;
}): Promise<{ 
  success: boolean; 
  webhook?: Webhook; 
  message?: string; 
  code?: string; 
  description?: string 
}> {
  try {
    // Create a deep copy of the webhook data to avoid reference issues
    const webhookDataCopy = {
      url: webhookData.url,
      events: [...webhookData.events], // Create a copy of the events array
      active: webhookData.active
    };
    
    const response = await axiosInstance.post('/integrations/webhooks', webhookDataCopy);
    
    // Ensure the webhook data is properly formatted
    if (response.data.success && response.data.webhook) {
      // Create a new object to avoid modifying the original response
      const webhook = { ...response.data.webhook };
      
      // Always use the original events from the request data
      // This ensures we have the correct events even if the server response is incorrect
      webhook.events = [...webhookDataCopy.events];
      
      // Convert ID to string if it's a number
      if (typeof webhook.id === 'number') {
        webhook.id = webhook.id.toString();
      }
      
      response.data.webhook = webhook;
    }
    
    return response.data;
  } catch (error: any) {
    console.error('Error creating webhook:', error);
    return { 
      success: false, 
      message: error.response?.data?.message || error.message || 'Unknown error occurred' 
    };
  }
}

/**
 * Update an existing webhook
 * @param id The ID of the webhook to update
 * @param webhookData The webhook data to update
 * @returns Promise with the webhook update response
 */
export async function updateWebhook(
  id: string, 
  webhookData: {
    url?: string;
    events?: string[];
    active?: boolean;
  }
): Promise<{ 
  success: boolean; 
  webhook?: Webhook; 
  message?: string; 
  code?: string; 
  description?: string 
}> {
  try {
    // Create a deep copy of the webhook data to avoid reference issues
    const webhookDataCopy = { ...webhookData };
    
    // Make a copy of the events array if it exists
    if (webhookDataCopy.events) {
      webhookDataCopy.events = [...webhookDataCopy.events];
    }
    
    const response = await axiosInstance.put(`/integrations/webhooks/${id}`, webhookDataCopy);
    
    // Ensure the webhook data is properly formatted
    if (response.data.success && response.data.webhook) {
      // Create a new object to avoid modifying the original response
      const webhook = { ...response.data.webhook };
      
      // Use the original events from the request data if provided
      if (webhookDataCopy.events) {
        webhook.events = [...webhookDataCopy.events];
      }
      
      // Convert ID to string if it's a number
      if (typeof webhook.id === 'number') {
        webhook.id = webhook.id.toString();
      }
      
      response.data.webhook = webhook;
    }
    
    return response.data;
  } catch (error: any) {
    console.error('Error updating webhook:', error);
    return { 
      success: false, 
      message: error.response?.data?.message || error.message || 'Unknown error occurred' 
    };
  }
}

/**
 * Delete a webhook
 * @param id The ID of the webhook to delete
 * @returns Promise with the webhook deletion response
 */
export async function deleteWebhook(id: string): Promise<{ 
  success: boolean; 
  message?: string; 
  code?: string; 
  description?: string 
}> {
  try {
    const response = await axiosInstance.delete(`/integrations/webhooks/${id}`);
    return response.data;
  } catch (error: any) {
    console.error('Error deleting webhook:', error);
    return { 
      success: false, 
      message: error.response?.data?.message || error.message || 'Unknown error occurred' 
    };
  }
}

/**
 * Toggle a webhook's active status
 * @param id The ID of the webhook to toggle
 * @param active The new active status
 * @returns Promise with the webhook update response
 */
export async function toggleWebhook(id: string, active: boolean): Promise<{ 
  success: boolean; 
  webhook?: Webhook; 
  message?: string; 
  code?: string; 
  description?: string 
}> {
  return updateWebhook(id, { active });
}

/**
 * Send a test webhook to a specific endpoint
 * @param webhookId The ID of the webhook to test
 * @param eventType The event type to simulate
 * @returns Promise with the test response
 */
export async function testWebhook(
  webhookId: string, 
  eventType: string = 'lead.created'
): Promise<{ 
  success: boolean; 
  message?: string; 
  code?: string; 
  description?: string 
}> {
  try {
    const response = await axiosInstance.post(`/integrations/webhooks/${webhookId}/test`, { eventType });
    return response.data;
  } catch (error: any) {
    console.error('Error testing webhook:', error);
    return { 
      success: false, 
      message: error.response?.data?.message || error.message || 'Unknown error occurred' 
    };
  }
}

/**
 * Check if webhooks are enabled for the current user
 * @returns Promise with the status response
 */
export async function checkWebhooksStatus(): Promise<{ 
  success: boolean; 
  enabled?: boolean;
  webhookCount?: number;
  message?: string; 
  code?: string; 
  description?: string 
}> {
  try {
    const response = await axiosInstance.get('/integrations/webhooks/status');
    return response.data;
  } catch (error: any) {
    console.error('Error checking webhooks status:', error);
    return { 
      success: false, 
      enabled: false,
      message: error.response?.data?.message || error.message || 'Unknown error occurred' 
    };
  }
} 