import { IntegrationsPage } from '@/pages/IntegrationsPage';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/integrations')({
  component: IntegrationsPage,
  validateSearch: (search: Record<string, unknown>) => {
    return {
      integration: search.integration as string | undefined,
    };
  }
}); 