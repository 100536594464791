import { axiosInstance } from '@/lib/api';

export interface ChatMessage {
  role: 'user' | 'assistant';
  content: string;
  isFormatted?: boolean;
}

export interface ChatRequest {
  assistantId?: string;
  message: string;
  history?: ChatMessage[];
  threadId?: string;
}

export interface ChatResponse {
  success: boolean;
  response?: string;
  message?: string;
  error?: string;
  threadId?: string;
  isFormatted?: boolean;
}

export const AIAssistantService = {
  /**
   * Send a message to the AI Assistant
   * @param request The chat request containing the message and history
   * @returns The AI Assistant's response
   */
  async sendMessage(request: ChatRequest): Promise<ChatResponse> {
    try {
      // Get the thread ID from localStorage if it exists and wasn't provided
      if (!request.threadId) {
        const storedThreadId = localStorage.getItem('ai_assistant_thread_id');
        if (storedThreadId) {
          request.threadId = storedThreadId;
          console.log(`[AIAssistantService] Using stored thread ID: ${storedThreadId}`);
        }
      }
      
      const response = await axiosInstance.post<ChatResponse>('/ai-assistant/chat', request);
      
      // Store the thread ID for future conversations if provided
      if (response.data.threadId) {
        localStorage.setItem('ai_assistant_thread_id', response.data.threadId);
        console.log(`[AIAssistantService] Stored thread ID: ${response.data.threadId}`);
      }
      
      return response.data;
    } catch (error: any) {
      console.error('Error sending message to AI Assistant:', error);
      
      // Handle different types of errors
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        return {
          success: false,
          message: error.response.data.message || 'Error from server',
          error: error.response.data.error
        };
      } else if (error.request) {
        // The request was made but no response was received
        return {
          success: false,
          message: 'No response from server. Please check your connection.'
        };
      } else {
        // Something happened in setting up the request that triggered an Error
        return {
          success: false,
          message: 'Error setting up request',
          error: error.message
        };
      }
    }
  },
  
  /**
   * Clear the stored thread ID to start a new conversation
   */
  clearThread(): void {
    localStorage.removeItem('ai_assistant_thread_id');
    console.log('[AIAssistantService] Cleared thread ID');
  }
}; 