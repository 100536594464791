import { Button } from '@/components/ui/button';
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList, CommandSeparator } from '@/components/ui/command';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { useUserNumbers } from '@/hooks/did';
import { api, type AvailablePhoneNumber } from '@/lib/api';
import { cn, formatPhoneNumber } from '@/lib/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Check, ChevronsUpDown, Loader2, Phone, Plus } from 'lucide-react';
import { useEffect, useState } from 'react';
import { toast } from 'sonner';
import { DIDManager } from '../settings/DIDManager';

interface DIDSelectorProps {
  selectedNumber: string | null;
  onSelect: (number: string) => void;
  phoneNumber: string;
}

export function DIDSelector({ selectedNumber, onSelect, phoneNumber }: DIDSelectorProps) {
  const [open, setOpen] = useState(false);
  const [showAddDialog, setShowAddDialog] = useState(false);
  const { data: userNumbers, isLoading } = useUserNumbers();
  const queryClient = useQueryClient();

  const getAreaCode = (phone: string) => {
    console.log('phone', phone);

    if (!phone) {
      return null;
    }
    
    const cleaned = phone.replace(/\D/g, '');
    if (cleaned.length === 10) {
      return cleaned.substring(0, 3);
    }
    if (cleaned.length === 11 && cleaned.startsWith('1')) {
      return cleaned.substring(1, 4);
    }
    return null;
  };

  useEffect(() => {
    console.log('phoneNumber', phoneNumber);
    if (!selectedNumber && userNumbers && phoneNumber) {
      const areaCode = getAreaCode(phoneNumber);
      if (areaCode) {
        const matchingNumber = userNumbers.find(num => num.areaCode === areaCode);
        if (matchingNumber) {
          onSelect(matchingNumber.phoneNumber);
        }
      }
    }
  }, [userNumbers, phoneNumber, selectedNumber, onSelect]);

  const purchaseMutation = useMutation({
    mutationFn: (number: AvailablePhoneNumber) => api.did.purchaseNumber(number.phoneNumber, number.locality, number.region),
    onSuccess: (_, number) => {
      toast.success('Phone number purchased successfully');
      queryClient.invalidateQueries({ queryKey: ['userNumbers'] });
      setShowAddDialog(false);
      onSelect(number.phoneNumber);
    },
    onError: (error) => {
      toast.error('Failed to purchase phone number: ' + error.message);
    },
  });

  return (
    <div className="flex items-center gap-2">
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className="w-[240px] justify-between"
          >
            {isLoading ? (
              <Loader2 className="h-4 w-4 animate-spin" />
            ) : selectedNumber ? (
              <div className="flex items-center gap-2">
                <Phone className="h-4 w-4" />
                {formatPhoneNumber(selectedNumber)}
              </div>
            ) : (
              "Select a number..."
            )}
            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[240px] p-0">
          <Command>
            <CommandInput placeholder="Search numbers..." />
            <CommandList>
              <CommandEmpty>No numbers found.</CommandEmpty>
              <CommandGroup heading="Your Numbers">
                {userNumbers?.map((number) => (
                  <CommandItem
                    key={number.phoneNumber}
                    value={number.phoneNumber}
                    onSelect={() => {
                      onSelect(number.phoneNumber);
                      setOpen(false);
                    }}
                    className={cn(
                      "cursor-pointer",
                      number.areaCode === getAreaCode(phoneNumber) && "bg-muted"
                    )}
                  >
                    <Check
                      className={`mr-2 h-4 w-4 ${
                        selectedNumber === number.phoneNumber
                          ? "opacity-100"
                          : "opacity-0"
                      }`}
                    />
                    <div className="flex flex-col">
                      <span>{formatPhoneNumber(number.phoneNumber)}</span>
                      <span className="text-xs text-muted-foreground">
                        {number.locality}, {number.region}
                        {number.areaCode === getAreaCode(phoneNumber) && " (Area code match)"}
                      </span>
                    </div>
                  </CommandItem>
                ))}
              </CommandGroup>
              <CommandSeparator />
              <CommandGroup>
                <CommandItem
                  onSelect={() => {
                    // setOpen(false);
                    setShowAddDialog(true);
                  }}
                  className="cursor-pointer"
                >
                  <Plus className="mr-2 h-4 w-4" />
                  Add new number
                </CommandItem>
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>

      {/* Add Number Dialog */}
      <Dialog open={showAddDialog} onOpenChange={setShowAddDialog}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Add New Number</DialogTitle>
            <DialogDescription>
              Search and purchase a new phone number for your account. Numbers are billed at $2 per month.
            </DialogDescription>
          </DialogHeader>
          <div className="py-4">
            <DIDManager
              mode="compact"
              onNumberSelected={(number) => {
                onSelect(number);
                setShowAddDialog(false);
              }}
              areacode={getAreaCode(phoneNumber)}
            />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
} 