import { Textarea } from '@/components/ui/textarea';
import { cn } from '@/lib/utils';
import { Lead } from '@/types';
import { useEffect, useRef } from 'react';
import { formFieldClasses } from './utils';

interface NotesSectionProps {
  formData: Partial<Lead>;
  handleInputChange: (field: keyof Lead, value: string | number | boolean) => void;
  handleBlur: (field: keyof Lead) => void;
}

export function NotesSection({
  formData,
  handleInputChange,
  handleBlur
}: NotesSectionProps) {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  
  // Function to auto-resize the textarea
  const autoResizeTextarea = () => {
    const textarea = textareaRef.current;
    if (!textarea) return;
    
    // Reset height temporarily to get the correct scrollHeight
    textarea.style.height = 'auto';
    
    // Set the height to scrollHeight but respect min and max values
    const newHeight = Math.min(Math.max(textarea.scrollHeight, 100), 300) + 10;
    textarea.style.height = `${newHeight}px`;
  };
  
  // Resize on mount and when content changes
  useEffect(() => {
    autoResizeTextarea();
  }, [formData.comments]);
  
  return (
    <div className={formFieldClasses.section}>
      <div className={formFieldClasses.field}>
        <label className={formFieldClasses.label}>Notes & Comments</label>
        <Textarea
          ref={textareaRef}
          placeholder="Add a note about this lead..."
          className={cn(formFieldClasses.input, "min-h-[100px] max-h-[300px] resize-y overflow-y")}
          value={formData.comments || ''}
          onChange={(e) => {
            handleInputChange('comments', e.target.value);
          }}
          onBlur={() => handleBlur('comments')}
        />
      </div>
    </div>
  );
} 