import { io, Socket } from 'socket.io-client';

// Get the socket URL from environment variables or use localhost as fallback
const SOCKET_URL = import.meta.env.VITE_SOCKET_URL || 'http://localhost:3001';

// Event types - must match server-side events
export enum SocketEvents {
  // Connection events
  CONNECT = 'connect',
  DISCONNECT = 'disconnect',
  RECONNECT = 'reconnect',
  ERROR = 'error',
  
  // Communication events
  INBOUND_CALL = 'inbound-call',
  CALL_STATUS_UPDATE = 'call-status-update',
  CALL_ENDED = 'call-ended',
  CALL_ANALYSIS_COMPLETED = 'call-analysis-completed',
  NEW_MESSAGE = 'new-message',
  MESSAGE_STATUS_UPDATE = 'message-status-update',
  TYPING_INDICATOR = 'typing-indicator',
  SMS_ANALYSIS_COMPLETED = 'sms-analysis-completed',
  
  // Lead management events
  LEAD_STATUS_CHANGE = 'lead-status-change',
  LEAD_ASSIGNMENT = 'lead-assignment',
  LEAD_NOTE_ADDED = 'lead-note-added',
  LEAD_VIEWED = 'lead-viewed',
  LEAD_UPDATE = 'lead-update',
  
  // User events
  USER_ONLINE = 'user-online',
  USER_OFFLINE = 'user-offline',
  USER_ACTIVITY = 'user-activity',
  
  // System events
  CONNECTION_STATUS = 'connection-status',
  SYSTEM_ERROR = 'system-error',
  MAINTENANCE = 'maintenance',
  
  // Room management events
  JOIN_ROOM = 'join-room',
  LEAVE_ROOM = 'leave-room',
  ROOM_JOINED = 'room-joined',
  ROOM_LEFT = 'room-left',
  
  // Team events
  TEAM_INVITATION_UPDATE = 'team-invitation-update',
  
  // Call history events
  CALL_HISTORY_UPDATE = 'call-history-update',
  
  // Conversation events
  CONVERSATION_UPDATE = 'conversation-update',
  
  // AI Receptionist events
  AI_RECEPTIONIST_CALL_COMPLETED = 'ai-receptionist-call-completed'
}

// Room types for subscription management
export enum RoomType {
  LEAD = 'lead',
  TEAM = 'team',
  AGENCY = 'agency',
  CONVERSATION = 'conversation',
  GLOBAL = 'global'
}

// Connection status
export enum ConnectionStatus {
  CONNECTED = 'connected',
  DISCONNECTED = 'disconnected',
  CONNECTING = 'connecting',
  ERROR = 'error'
}

// Constants for the service
const RECONNECT_DELAY = 2000; // 2 seconds
const MAX_RECONNECT_ATTEMPTS = 5;
const HEARTBEAT_INTERVAL = 30000; // 30 seconds
const EVENT_QUEUE_LIMIT = 100;

// Event queue item interface
interface QueuedEvent {
  event: string;
  data: any;
  timestamp: number;
}

// Room subscription interface
interface RoomSubscription {
  roomType: RoomType;
  roomId: string | number;
  timestamp: number;
}

// WebSocket service
export class WebSocketService {
  private static instance: WebSocketService;
  private socket: Socket | null = null;
  private connectionStatus: ConnectionStatus = ConnectionStatus.DISCONNECTED;
  private eventHandlers: Map<string, Set<(data: any) => void>> = new Map();
  private reconnectAttempts = 0;
  private maxReconnectAttempts = 5;
  private reconnectTimeout: NodeJS.Timeout | null = null;
  private eventQueue: QueuedEvent[] = []; // Queue for events when disconnected
  private maxQueueSize = 50; // Maximum number of events to queue
  private maxQueueAge = 5 * 60 * 1000; // 5 minutes in milliseconds
  private lastToken: string | null = null; // Store the last token used for connection
  private activeRooms: Map<string, RoomSubscription> = new Map(); // Track active room subscriptions
  
  // Get singleton instance
  public static getInstance(): WebSocketService {
    if (!WebSocketService.instance) {
      WebSocketService.instance = new WebSocketService();
    }
    return WebSocketService.instance;
  }
  
  // Initialize WebSocket connection
  public connect(token: string): void {
    try {
      console.log('[WebSocketService] Connecting to WebSocket server');
      
      // If we're already connected with the same token, don't reconnect
      if (this.socket && this.connectionStatus === ConnectionStatus.CONNECTED && token === this.lastToken) {
        console.log('[WebSocketService] Already connected with the same token, skipping reconnection');
        return;
      }
      
      // Disconnect existing socket if any
      if (this.socket) {
        console.log('[WebSocketService] Disconnecting existing socket');
        this.socket.disconnect();
        this.socket = null;
      }
      
      // Set connection status to connecting
      this.setConnectionStatus(ConnectionStatus.CONNECTING);
      
      // Store the token for future reference
      this.lastToken = token;
      
      // Get API base URL
      const apiBaseUrl = SOCKET_URL;
      
      // Log connection attempt
      console.log('[WebSocketService] Connecting to WebSocket server at:', apiBaseUrl);
      console.log('[WebSocketService] Token available:', !!token);
      console.log('[WebSocketService] Token length:', token?.length || 0);
      
      // Create socket with auth
      try {
        this.socket = io(apiBaseUrl, {
          auth: { token },
          transports: ['websocket', 'polling'],
          reconnection: true,
          reconnectionAttempts: this.maxReconnectAttempts,
          reconnectionDelay: 1000,
          reconnectionDelayMax: 5000,
          timeout: 20000,
          autoConnect: true
        });
        
        console.log('[WebSocketService] Socket instance created');
        
        // Setup socket event listeners
        this.setupSocketListeners();
      } catch (socketError) {
        console.error('[WebSocketService] Error creating socket:', socketError);
        this.setConnectionStatus(ConnectionStatus.ERROR, 'Failed to create socket connection');
        this.socket = null;
        return;
      }
    } catch (error) {
      console.error('[WebSocketService] Error connecting to WebSocket server:', error);
      this.setConnectionStatus(ConnectionStatus.ERROR, error instanceof Error ? error.message : 'Unknown error');
      this.socket = null;
    }
  }
  
  // Disconnect WebSocket
  public disconnect(): void {
    console.log('[WebSocketService] Disconnecting from WebSocket server');
    
    // Clear reconnect timeout
    if (this.reconnectTimeout) {
      clearTimeout(this.reconnectTimeout);
      this.reconnectTimeout = null;
    }
    
    // Disconnect socket
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
    
    // Update connection status
    this.setConnectionStatus(ConnectionStatus.DISCONNECTED);
    
    // Clear active rooms
    this.activeRooms.clear();
  }
  
  // Get connection status
  public getConnectionStatus(): ConnectionStatus {
    return this.connectionStatus;
  }
  
  // Subscribe to an event
  public on<T>(event: string, callback: (data: T) => void): () => void {
    // Special case for connection status event
    if (event === SocketEvents.CONNECTION_STATUS) {
      const listener = callback as (data: { status: ConnectionStatus, error?: string }) => void;
      
      // Get existing handlers or create new set
      if (!this.eventHandlers.has(event)) {
        this.eventHandlers.set(event, new Set());
      }
      
      const handlers = this.eventHandlers.get(event)!;
      handlers.add(listener);
      
      // Immediately notify with current status
      listener({ status: this.connectionStatus });
      
      // Return unsubscribe function
      return () => {
        const handlers = this.eventHandlers.get(event);
        if (handlers) {
          handlers.delete(listener);
        }
      };
    }
    
    // For other events, if socket is null, store the handler for later
    if (!this.socket) {
      console.warn(`[WebSocketService] Cannot subscribe to event '${event}': Socket is null. Will register handler for when socket connects.`);
      
      // Store the handler for this event
      if (!this.eventHandlers.has(event)) {
        this.eventHandlers.set(event, new Set());
      }
      
      const handlers = this.eventHandlers.get(event)!;
      handlers.add(callback as (data: any) => void);
      
      // Return unsubscribe function
      return () => {
        const handlers = this.eventHandlers.get(event);
        if (handlers) {
          handlers.delete(callback as (data: any) => void);
        }
      };
    }
    
    console.log(`[WebSocketService] Subscribing to event: ${event}`);
    
    // Subscribe to socket event
    this.socket.on(event, callback);
    
    // Also store the handler for reconnection scenarios
    if (!this.eventHandlers.has(event)) {
      this.eventHandlers.set(event, new Set());
    }
    
    const handlers = this.eventHandlers.get(event)!;
    handlers.add(callback as (data: any) => void);
    
    // Return unsubscribe function
    return () => {
      if (this.socket) {
        this.socket.off(event, callback);
      }
      
      const handlers = this.eventHandlers.get(event);
      if (handlers) {
        handlers.delete(callback as (data: any) => void);
      }
    };
  }
  
  // Emit an event
  public emit(event: string, data: any): void {
    // If connected, emit immediately
    if (this.socket && this.connectionStatus === ConnectionStatus.CONNECTED) {
      console.log(`[WebSocketService] Emitting event: ${event}`);
      this.socket.emit(event, data);
      return;
    }
    
    // If not connected, queue the event
    console.log(`[WebSocketService] Socket not connected (status: ${this.connectionStatus}), queueing event: ${event}`);
    
    // Add to queue if not full
    if (this.eventQueue.length < this.maxQueueSize) {
      this.eventQueue.push({
        event,
        data,
        timestamp: Date.now()
      });
      console.log(`[WebSocketService] Event queued (${this.eventQueue.length}/${this.maxQueueSize})`);
    } else {
      console.warn(`[WebSocketService] Event queue full (${this.maxQueueSize}), dropping event: ${event}`);
    }
  }
  
  // Join a room
  public joinRoom(roomType: RoomType, roomId: string | number): void {
    if (!this.socket || this.connectionStatus !== ConnectionStatus.CONNECTED) {
      console.warn(`[WebSocketService] Cannot join room ${roomType}:${roomId}: Not connected (status: ${this.connectionStatus})`);
      return;
    }
    
    const roomKey = `${roomType}:${roomId}`;
    
    // Check if already in this room
    if (this.activeRooms.has(roomKey)) {
      console.log(`[WebSocketService] Already in room ${roomKey}, skipping join`);
      return;
    }
    
    console.log(`[WebSocketService] Joining room: ${roomKey}`);
    
    // Emit join room event
    this.socket.emit(SocketEvents.JOIN_ROOM, { roomType, roomId });
    
    // Track room subscription
    this.activeRooms.set(roomKey, {
      roomType,
      roomId,
      timestamp: Date.now()
    });
    
    // Listen for room joined confirmation
    this.socket.once(SocketEvents.ROOM_JOINED, (data: { roomType: RoomType, roomId: string | number }) => {
      if (data.roomType === roomType && data.roomId === roomId) {
        console.log(`[WebSocketService] Successfully joined room: ${roomType}:${roomId}`);
      }
    });
  }
  
  // Leave a room to stop receiving room-specific events
  public leaveRoom(roomType: RoomType, roomId: string | number): void {
    if (!this.socket || this.connectionStatus !== ConnectionStatus.CONNECTED) {
      console.warn(`[WebSocketService] Cannot leave room ${roomType}:${roomId}: Not connected`);
      return;
    }
    
    const roomKey = `${roomType}:${roomId}`;
    
    // Check if in this room
    if (!this.activeRooms.has(roomKey)) {
      console.log(`[WebSocketService] Not in room ${roomKey}, skipping leave`);
      return;
    }
    
    console.log(`[WebSocketService] Leaving room: ${roomKey}`);
    
    // Emit leave room event
    this.socket.emit(SocketEvents.LEAVE_ROOM, { roomType, roomId });
    
    // Remove from active rooms
    this.activeRooms.delete(roomKey);
    
    // Listen for room left confirmation
    this.socket.once(SocketEvents.ROOM_LEFT, (data: { roomType: RoomType, roomId: string | number }) => {
      if (data.roomType === roomType && data.roomId === roomId) {
        console.log(`[WebSocketService] Successfully left room: ${roomType}:${roomId}`);
      }
    });
  }
  
  // Get all active room subscriptions
  public getActiveRooms(): Map<string, RoomSubscription> {
    return new Map(this.activeRooms);
  }
  
  // Rejoin all active rooms after reconnection
  private rejoinRooms(): void {
    if (!this.socket || this.connectionStatus !== ConnectionStatus.CONNECTED) {
      console.warn('[WebSocketService] Cannot rejoin rooms: Not connected');
      return;
    }
    
    if (this.activeRooms.size === 0) {
      console.log('[WebSocketService] No rooms to rejoin');
      return;
    }
    
    console.log(`[WebSocketService] Rejoining ${this.activeRooms.size} rooms`);
    
    // Rejoin each room
    this.activeRooms.forEach((subscription, roomKey) => {
      console.log(`[WebSocketService] Rejoining room: ${roomKey}`);
      this.socket!.emit(SocketEvents.JOIN_ROOM, {
        roomType: subscription.roomType,
        roomId: subscription.roomId
      });
    });
  }
  
  // Notify that user is viewing a lead
  public viewLead(leadId: number): void {
    console.log(`[WebSocketService] Viewing lead: ${leadId}`);
    
    // Join the lead room to receive lead-specific events
    this.joinRoom(RoomType.LEAD, leadId);
    
    // Emit the lead viewed event
    this.emit(SocketEvents.LEAD_VIEWED, { leadId });
  }
  
  // Send typing indicator for SMS
  public sendTypingIndicator(conversationId: number, isTyping: boolean): void {
    console.log(`[WebSocketService] Sending typing indicator for conversation ${conversationId}: ${isTyping}`);
    
    // Join the conversation room if typing
    if (isTyping) {
      this.joinRoom(RoomType.CONVERSATION, conversationId);
    }
    
    // Emit typing indicator event
    this.emit(SocketEvents.TYPING_INDICATOR, { conversationId, isTyping });
    
    // Leave the conversation room if not typing
    if (!isTyping) {
      // Wait a bit before leaving to ensure all typing events are received
      setTimeout(() => {
        this.leaveRoom(RoomType.CONVERSATION, conversationId);
      }, 5000);
    }
  }
  
  // Set up socket event listeners
  private setupSocketListeners(): void {
    if (!this.socket) {
      console.error('[WebSocketService] Cannot setup listeners: Socket is null');
      return;
    }
    
    console.log('[WebSocketService] Setting up socket event listeners');
    
    // Connection events
    this.socket.on('connect', () => {
      console.log('[WebSocketService] Connected to WebSocket server');
      this.setConnectionStatus(ConnectionStatus.CONNECTED);
      
      // Reset reconnect attempts
      this.reconnectAttempts = 0;
      
      // Process any queued events
      this.processEventQueue();
      
      // Rejoin active rooms
      this.rejoinRooms();
      
      // Register any stored event handlers
      this.registerStoredEventHandlers();
    });
    
    this.socket.on('disconnect', (reason) => {
      console.log(`[WebSocketService] Disconnected from WebSocket server: ${reason}`);
      this.setConnectionStatus(ConnectionStatus.DISCONNECTED);
    });
    
    this.socket.on('connect_error', (error) => {
      console.error('[WebSocketService] Connection error:', error);
      this.setConnectionStatus(ConnectionStatus.ERROR, error.message);
      
      // Increment reconnect attempts
      this.reconnectAttempts++;
      
      // If max attempts reached, stop trying
      if (this.reconnectAttempts >= this.maxReconnectAttempts) {
        console.log('[WebSocketService] Max reconnect attempts reached, giving up');
        this.disconnect();
      }
    });
    
    // Room management events
    this.socket.on(SocketEvents.ROOM_JOINED, (data: { roomType: RoomType, roomId: string | number }) => {
      console.log(`[WebSocketService] Room joined confirmation: ${data.roomType}:${data.roomId}`);
    });
    
    this.socket.on(SocketEvents.ROOM_LEFT, (data: { roomType: RoomType, roomId: string | number }) => {
      console.log(`[WebSocketService] Room left confirmation: ${data.roomType}:${data.roomId}`);
    });
    
    // Lead events
    this.socket.on(SocketEvents.LEAD_ASSIGNMENT, (data) => {
      console.log('[WebSocketService] Received lead assignment event:', data);
      this.notifyHandlers(SocketEvents.LEAD_ASSIGNMENT, data);
    });
    
    // Handle general errors
    this.socket.on('error', (error) => {
      console.error('[WebSocketService] Socket error:', error);
      this.setConnectionStatus(ConnectionStatus.ERROR, error instanceof Error ? error.message : 'Unknown socket error');
    });
  }
  
  // Register all stored event handlers to the socket
  private registerStoredEventHandlers(): void {
    if (!this.socket) {
      console.error('[WebSocketService] Cannot register handlers: Socket is null');
      return;
    }
    
    console.log('[WebSocketService] Registering stored event handlers');
    
    // For each event type
    for (const [event, handlers] of this.eventHandlers.entries()) {
      // Skip connection status as it's handled differently
      if (event === SocketEvents.CONNECTION_STATUS) continue;
      
      console.log(`[WebSocketService] Registering ${handlers.size} handlers for event: ${event}`);
      
      // For each handler of this event type
      for (const handler of handlers) {
        // Register with the socket
        this.socket.on(event, handler);
      }
    }
  }
  
  // Set connection status and notify handlers
  private setConnectionStatus(status: ConnectionStatus, error?: string): void {
    // Only update if status has changed
    if (this.connectionStatus !== status) {
      console.log(`[WebSocketService] Connection status changing from ${this.connectionStatus} to ${status}${error ? `: ${error}` : ''}`);
      this.connectionStatus = status;
      this.notifyHandlers(SocketEvents.CONNECTION_STATUS, { status, error });
    } else if (error) {
      // If status is the same but error message has changed, still notify
      console.log(`[WebSocketService] Connection status remains ${status}, but error updated: ${error}`);
      this.notifyHandlers(SocketEvents.CONNECTION_STATUS, { status, error });
    }
  }
  
  // Notify all handlers for an event
  private notifyHandlers(event: string, data: any): void {
    const handlers = this.eventHandlers.get(event);
    if (handlers) {
      handlers.forEach(callback => {
        try {
          callback(data);
        } catch (error) {
          console.error(`[WebSocketService] Error in event handler for ${event}:`, error);
        }
      });
    }
  }

  // Process any events that were queued while disconnected
  private processEventQueue(): void {
    if (!this.socket || this.connectionStatus !== ConnectionStatus.CONNECTED) {
      console.log('[WebSocketService] Cannot process event queue: Not connected');
      return;
    }
    
    if (this.eventQueue.length === 0) {
      console.log('[WebSocketService] No events in queue to process');
      return;
    }
    
    console.log(`[WebSocketService] Processing ${this.eventQueue.length} queued events`);
    
    // Process events in order they were queued
    const now = Date.now();
    const validEvents = this.eventQueue.filter(event => {
      // Only process events that are not too old
      return (now - event.timestamp) <= this.maxQueueAge;
    });
    
    if (validEvents.length < this.eventQueue.length) {
      console.log(`[WebSocketService] Discarded ${this.eventQueue.length - validEvents.length} expired events`);
    }
    
    // Emit each valid event
    validEvents.forEach(event => {
      console.log(`[WebSocketService] Emitting queued event: ${event.event}`);
      this.socket?.emit(event.event, event.data);
    });
    
    // Clear the queue
    this.eventQueue = [];
    console.log('[WebSocketService] Event queue processed and cleared');
  }
}

// Export singleton instance
export const websocketService = WebSocketService.getInstance();