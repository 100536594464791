import { Card } from '@/components/ui/card';
import { useUserNumbers } from '@/lib/api';
import { cn } from '@/lib/utils';
import { useCommunicationPaneStore } from '@/store/communicationPaneStore';
import { useLeadStore } from '@/store/leadStore';
import { useEffect, useState } from 'react';
import { DesktopLayout } from './DesktopLayout';
import { MobileLayout } from './MobileLayout';
import { BREAKPOINT_WIDTH, getAreaCode } from './utils';

interface CommunicationPaneProps {
  phoneNumber: string;
  className?: string;
  name?: string;
  isDrawer?: boolean;
  leadId?: number | null;
}

export function CommunicationPane({ 
  phoneNumber, 
  className, 
  name, 
  isDrawer = false, 
  leadId 
}: CommunicationPaneProps) {
  const isOpen = useCommunicationPaneStore((state) => state.isOpen);
  const setOpen = useCommunicationPaneStore((state) => state.setOpen);
  const [selectedDID, setSelectedDID] = useState<string | null>(null);
  const { data: userNumbers } = useUserNumbers();
  const selectedLead = useLeadStore((state) => state.selectedLead);
  const displayName = name || selectedLead.name || 'this lead';

  // Update the DID selection logic with proper typing for DIDNumber
  useEffect(() => {
    if (userNumbers && phoneNumber) {
      const areaCode = getAreaCode(phoneNumber);
      if (areaCode) {
        // If we have a selected DID, check if it matches the new area code
        if (selectedDID) {
          const currentDID = userNumbers.find(num => num.phoneNumber === selectedDID);
          // If the current DID doesn't match the new area code, try to find a matching one
          if (!currentDID || currentDID.areaCode !== areaCode) {
            const matchingNumber = userNumbers.find(num => num.areaCode === areaCode);
            if (matchingNumber) {
              setSelectedDID(matchingNumber.phoneNumber);
            } else {
              // No matching number found, clear selection
              setSelectedDID(null);
            }
          }
        } else {
          // No current selection, try to find a matching number
          const matchingNumber = userNumbers.find(num => num.areaCode === areaCode);
          if (matchingNumber) {
            setSelectedDID(matchingNumber.phoneNumber);
          }
        }
      } else {
        // Invalid area code, clear selection
        setSelectedDID(null);
      }
    }
  }, [phoneNumber, userNumbers]);

  // Set initial state and handle resize events for desktop view
  useEffect(() => {
    if (isDrawer) return; // Skip for drawer mode
    
    // Set initial state based on screen size
    const isAboveBreakpoint = window.innerWidth >= BREAKPOINT_WIDTH;
    setOpen(isAboveBreakpoint);
    
    // Mark as initialized
    document.documentElement.setAttribute('data-communication-pane-initialized', 'true');
    
    // Handle resize events
    const handleResize = () => {
      const isNowAboveBreakpoint = window.innerWidth >= BREAKPOINT_WIDTH;
      setOpen(isNowAboveBreakpoint);
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [setOpen, isDrawer]);

  // Apply slide animation for desktop view with absolute positioning
  return (
    <Card className={cn(
      "flex flex-col overflow-hidden border-none shadow-none lg:shadow-md transition-transform duration-300 ease-in-out",
      !isDrawer && "fixed top-16 right-0 z-20 w-[400px]", // Fixed positioning instead of absolute for desktop
      !isDrawer && "h-[calc(100vh-64px)]", // Adjust height to account for header height
      !isDrawer && !isOpen && "translate-x-full", // Slide out desktop view
      !isDrawer && isOpen && "translate-x-0", // Slide in desktop view
      isDrawer && "h-full", // Full height for drawer mode
      className
    )}>
      <DesktopLayout
        selectedDID={selectedDID}
        setSelectedDID={setSelectedDID}
        phoneNumber={phoneNumber}
        displayName={displayName}
        leadId={leadId}
      />
      <MobileLayout
        selectedDID={selectedDID}
        setSelectedDID={setSelectedDID}
        phoneNumber={phoneNumber}
        displayName={displayName}
        leadId={leadId}
      />
    </Card>
  );
} 