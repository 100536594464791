import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
    CommandSeparator,
} from "@/components/ui/command";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/components/ui/tooltip";
import { api } from '@/lib/api';
import { cn } from '@/lib/utils';
import { LeadStatus, Status } from '@/types';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Archive, Check, ChevronsUpDown, HelpCircle, Loader2, Plus, Trash2 } from 'lucide-react';
import { useEffect, useState } from 'react';
import { toast } from 'sonner';
import { CreateStatusDialog } from './CreateStatusDialog';

// Default status configuration with descriptions
const DEFAULT_STATUS_CONFIG: Record<string, {
  color: string;
  bgColor: string;
  borderColor: string;
  description: string;
}> = {
  new: {
    color: "text-blue-600 dark:text-blue-400",
    bgColor: "bg-blue-50 dark:bg-blue-900/20",
    borderColor: "border-blue-200 dark:border-blue-800",
    description: "Initial status for leads that haven't been contacted yet"
  },
  contacted: {
    color: "text-yellow-600 dark:text-yellow-400",
    bgColor: "bg-yellow-50 dark:bg-yellow-900/20",
    borderColor: "border-yellow-200 dark:border-yellow-800",
    description: "Lead has been reached and initial contact has been made"
  },
  qualified: {
    color: "text-green-600 dark:text-green-400",
    bgColor: "bg-green-50 dark:bg-green-900/20",
    borderColor: "border-green-200 dark:border-green-800",
    description: "Lead meets qualification criteria and shows genuine interest"
  },
  proposal: {
    color: "text-purple-600 dark:text-purple-400",
    bgColor: "bg-purple-50 dark:bg-purple-900/20",
    borderColor: "border-purple-200 dark:border-purple-800",
    description: "Proposal has been sent or is being prepared for the lead"
  },
  won: {
    color: "text-emerald-600 dark:text-emerald-400",
    bgColor: "bg-emerald-50 dark:bg-emerald-900/20",
    borderColor: "border-emerald-200 dark:border-emerald-800",
    description: "Lead has converted into a customer"
  },
  lost: {
    color: "text-red-600 dark:text-red-400",
    bgColor: "bg-red-50 dark:bg-red-900/20",
    borderColor: "border-red-200 dark:border-red-800",
    description: "Lead has declined or is no longer interested"
  },
};

interface LeadStatusSelectorProps {
  status: LeadStatus;
  onStatusChange: (status: LeadStatus) => Promise<void>;
  isLoading?: boolean;
  className?: string;
  leadId?: number;
}

interface StatusResponse {
  success: boolean;
  statuses: Status[];
}

export function LeadStatusSelector({ 
  status = 'new',
  onStatusChange, 
  isLoading = false,
  className = '',
  leadId
}: LeadStatusSelectorProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [statusConfig, setStatusConfig] = useState(DEFAULT_STATUS_CONFIG);
  const [isArchiveDialogOpen, setIsArchiveDialogOpen] = useState(false);
  const [isArchiving, setIsArchiving] = useState(false);
  const queryClient = useQueryClient();

  // Fetch all statuses
  const { data: statusesData, refetch: refetchStatuses } = useQuery<StatusResponse, Error>({
    queryKey: ['statuses'],
    queryFn: api.statuses.list,
    staleTime: 30000,
    retry: 2
  });

  // Update statusConfig when custom statuses are loaded
  useEffect(() => {
    if (statusesData?.success && Array.isArray(statusesData.statuses)) {
      const newConfig = { ...DEFAULT_STATUS_CONFIG };
      statusesData.statuses.forEach((status: Status) => {
        if (!status.isDefault && status.name && status.color) {
          newConfig[status.name] = {
            color: `text-[${status.color}]`,
            bgColor: `bg-[${status.color}]/10`,
            borderColor: `border-[${status.color}]/20`,
            description: status.description || 'No description available'
          };
        }
      });
      setStatusConfig(newConfig);
    }
  }, [statusesData]);

  const handleStatusCreated = async (newStatus: Status) => {
    if (!newStatus?.name || !newStatus?.color) {
      toast.error('Invalid status data');
      return;
    }

    // Update the status config
    setStatusConfig(prev => ({
      ...prev,
      [newStatus.name]: {
        color: `text-[${newStatus.color}]`,
        bgColor: `bg-[${newStatus.color}]/10`,
        borderColor: `border-[${newStatus.color}]/20`,
        description: newStatus.description || 'No description available'
      }
    }));

    // Update the lead's status
    try {
      await onStatusChange(newStatus.name);
      // Refetch statuses to ensure we have the latest list
      refetchStatuses();
    } catch (error) {
      console.error('Error updating status:', error);
      toast.error('Failed to update status');
    }
  };

  const handleDeleteStatus = async (statusId: number) => {
    try {
      await api.statuses.delete(statusId);
      toast.success('Status deleted successfully');
      refetchStatuses();
    } catch (error) {
      console.error('Error deleting status:', error);
      toast.error('Failed to delete status');
    }
  };

  const handleArchiveLead = async () => {
    if (!leadId) return;
    
    try {
      setIsArchiving(true);
      
      const response = await api.leads.archive(leadId);
      
      if (response.success) {
        toast.success('Lead archived successfully');
        setIsArchiveDialogOpen(false);
        
        // Invalidate all relevant queries to ensure UI is updated
        queryClient.invalidateQueries({ queryKey: ['leads'] });
        queryClient.invalidateQueries({ queryKey: ['lead', leadId] });
        
        // Force refetch of the leads list
        queryClient.refetchQueries({ queryKey: ['leads'] });
      } else {
        toast.error('Failed to archive lead');
      }
    } catch (error) {
      console.error('Error archiving lead:', error);
      let errorMessage = 'An error occurred while archiving the lead';
      
      if (error instanceof Error) {
        errorMessage += ': ' + error.message;
      }
      
      toast.error(errorMessage);
    } finally {
      setIsArchiving(false);
    }
  };

  // Ensure we have a valid status
  const safeStatus = status || 'new';

  // Find custom status configuration if it exists
  const customStatus = statusesData?.success 
    ? statusesData.statuses.find(s => s.name === safeStatus && !s.isDefault)
    : null;

  // Get config or create one based on custom status
  const currentStatusConfig = customStatus 
    ? {
        color: '',
        bgColor: '',
        borderColor: '',
        description: customStatus.description || 'No description available'
      }
    : statusConfig[safeStatus] || DEFAULT_STATUS_CONFIG.new;

  // Check if we have any custom statuses
  const hasCustomStatuses = statusesData?.success && statusesData.statuses.some(s => !s.isDefault);
  const customStatuses = statusesData?.success ? statusesData.statuses.filter(s => !s.isDefault) : [];

  return (
    <TooltipProvider>
      <Popover open={isOpen} onOpenChange={setIsOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={isOpen}
            className={cn(
              "justify-between gap-2",
              !customStatus && [
                currentStatusConfig.color,
                currentStatusConfig.bgColor,
                currentStatusConfig.borderColor,
              ],
              className || ''
            )}
            style={customStatus ? {
              backgroundColor: `${customStatus.color}20`,
              color: customStatus.color,
              borderColor: `${customStatus.color}40`
            } : undefined}
            disabled={isLoading}
          >
            {isLoading ? (
              <Loader2 className="h-4 w-4 animate-spin" />
            ) : (
              <>
                {safeStatus.charAt(0).toUpperCase() + safeStatus.slice(1)}
                <ChevronsUpDown className="h-4 w-4 shrink-0 opacity-50" />
              </>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[200px] p-0">
          <Command>
            <CommandInput placeholder="Search status..." />
            <CommandList>
              <CommandEmpty>No status found.</CommandEmpty>
              <CommandItem
                onSelect={() => {
                  setIsOpen(false);
                  setIsCreateDialogOpen(true);
                }}
                className="flex items-center gap-2 cursor-pointer"
              >
                <Plus className="h-4 w-4" />
                <span>Create Status</span>
              </CommandItem>
              
              <CommandSeparator />
              
              {/* Custom Statuses - Now before Default Statuses */}
              {hasCustomStatuses && (
                <CommandGroup heading="Custom Statuses">
                  {customStatuses.map((customStatus) => (
                    <CommandItem
                      key={customStatus.id}
                      value={customStatus.name}
                      onSelect={() => {
                        onStatusChange(customStatus.name);
                        setIsOpen(false);
                      }}
                      className="flex items-center justify-between cursor-pointer group"
                    >
                      <div className="flex items-center gap-2">
                        <Check
                          className={cn(
                            "h-4 w-4",
                            status === customStatus.name ? "opacity-100" : "opacity-0"
                          )}
                        />
                        <span style={{ color: customStatus.color }}>
                          {customStatus.name.charAt(0).toUpperCase() + customStatus.name.slice(1)}
                        </span>
                      </div>
                      <div className="flex items-center gap-1">
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Button
                              variant="ghost"
                              size="icon"
                              className="h-4 w-4 opacity-50 hover:opacity-100"
                            >
                              <HelpCircle className="h-3 w-3" />
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent side="left" className="max-w-[200px]">
                            {customStatus.description}
                          </TooltipContent>
                        </Tooltip>
                        <Button
                          variant="ghost"
                          size="icon"
                          className="h-6 w-6 opacity-0 group-hover:opacity-100 transition-opacity"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteStatus(customStatus.id);
                          }}
                        >
                          <Trash2 className="h-3 w-3" />
                        </Button>
                      </div>
                    </CommandItem>
                  ))}
                </CommandGroup>
              )}
              
              {hasCustomStatuses && <CommandSeparator />}
              
              {/* Default Statuses */}
              <CommandGroup heading="Default Statuses">
                {Object.entries(DEFAULT_STATUS_CONFIG).map(([statusName, config]) => (
                  <CommandItem
                    key={statusName}
                    value={statusName}
                    onSelect={() => {
                      onStatusChange(statusName as LeadStatus);
                      setIsOpen(false);
                    }}
                    className="flex items-center gap-2 cursor-pointer"
                  >
                    <div className="flex items-center gap-2 flex-1">
                      <Check
                        className={cn(
                          "h-4 w-4",
                          status === statusName ? "opacity-100" : "opacity-0"
                        )}
                      />
                      <span className={config.color}>
                        {statusName.charAt(0).toUpperCase() + statusName.slice(1)}
                      </span>
                    </div>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Button
                          variant="ghost"
                          size="icon"
                          className="h-4 w-4 opacity-50 hover:opacity-100"
                        >
                          <HelpCircle className="h-3 w-3" />
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent side="left" className="max-w-[200px]">
                        {config.description}
                      </TooltipContent>
                    </Tooltip>
                  </CommandItem>
                ))}
              </CommandGroup>
              
              {/* Archive Lead - Now at the very end */}
              {leadId && (
                <>
                  <CommandSeparator />
                  <CommandItem
                    onSelect={() => {
                      setIsOpen(false);
                      setIsArchiveDialogOpen(true);
                    }}
                    className="flex items-center gap-2 cursor-pointer text-red-600"
                  >
                    <Archive className="h-4 w-4" />
                    <span>Archive Lead</span>
                  </CommandItem>
                </>
              )}
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>

      <CreateStatusDialog
        open={isCreateDialogOpen}
        onOpenChange={setIsCreateDialogOpen}
        onStatusCreated={handleStatusCreated}
      />
      
      {/* Archive Confirmation Dialog */}
      <AlertDialog open={isArchiveDialogOpen} onOpenChange={setIsArchiveDialogOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Archive Lead</AlertDialogTitle>
            <AlertDialogDescription>
              Are you sure you want to archive this lead? This will remove it from your active leads list.
              Archived leads can be restored by an administrator if needed.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={handleArchiveLead}
              className="bg-red-600 hover:bg-red-700 text-white"
              disabled={isArchiving}
            >
              {isArchiving ? 'Archiving...' : 'Archive'}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </TooltipProvider>
  );
} 