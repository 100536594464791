import {
  AIReceptionistArticle,
  AnalyticsArticle,
  DashboardOverviewArticle,
  GettingStartedArticle,
  LeadManagementArticle,
  SettingsConfigurationArticle,
  TeamManagementArticle,
  UserAccountManagementArticle
} from "@/components/help/articles";
import { MainLayout } from "@/components/layout/MainLayout";
import { PageContainer } from "@/components/layout/PageContainer";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useNavigate, useSearch } from "@tanstack/react-router";
import { useEffect, useState } from "react";

// Define the article structure
interface Article {
  id: string;
  title: string;
  description: string;
  category: string;
  component: () => JSX.Element;
}

// Define articles data
const articles: Article[] = [
  {
    id: "getting-started",
    title: "Getting Started with LeadGPT",
    description: "Learn the basics of LeadGPT",
    category: "guides",
    component: GettingStartedArticle
  },
  {
    id: "dashboard-overview",
    title: "Dashboard Overview",
    description: "Understanding your dashboard",
    category: "guides",
    component: DashboardOverviewArticle
  },
  {
    id: "lead-management",
    title: "Managing Leads",
    description: "Learn how to effectively manage your leads",
    category: "guides",
    component: LeadManagementArticle
  },
  {
    id: "ai-receptionist",
    title: "AI Receptionist",
    description: "Set up and use the AI Receptionist",
    category: "guides",
    component: AIReceptionistArticle
  },
  {
    id: "analytics",
    title: "Analytics",
    description: "Understand and leverage your sales data",
    category: "guides",
    component: AnalyticsArticle
  },
  {
    id: "settings-configuration",
    title: "Settings and Configuration",
    description: "Customize LeadGPT to match your needs",
    category: "guides",
    component: SettingsConfigurationArticle
  },
  {
    id: "user-account-management",
    title: "User Account Management",
    description: "Manage your user account and profile settings",
    category: "guides",
    component: UserAccountManagementArticle
  },
  {
    id: "team-management",
    title: "Team Management",
    description: "Collaborate effectively with your team",
    category: "guides",
    component: TeamManagementArticle
  }
];

// Define the search params type to match the route's validateSearch
type HelpCenterSearchParams = {
  tab: string | undefined;
  article: string | undefined;
};

export function HelpCenterPage() {
  const navigate = useNavigate();
  const { tab, article } = useSearch({ from: '/help-center' });
  const [isMobile, setIsMobile] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState<Article | null>(null);

  // Check if we're on mobile
  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth < 768); // md breakpoint
    };
    
    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);
    
    return () => {
      window.removeEventListener('resize', checkIfMobile);
    };
  }, []);

  // Handle tab from URL
  useEffect(() => {
    // If no tab is specified, default to "guides"
    if (!tab) {
      navigate({
        to: '/help-center',
        search: () => ({ tab: "guides", article: undefined }),
        replace: true
      });
    }
  }, [tab, navigate]);

  // Handle article selection from URL
  useEffect(() => {
    if (article) {
      const found = articles.find(a => a.id === article);
      if (found) {
        setSelectedArticle(found);
      }
    } else {
      setSelectedArticle(null);
    }
  }, [article]);

  // Handle article selection
  const handleArticleSelect = (article: Article) => {
    navigate({
      to: '/help-center',
      search: () => ({ tab, article: article.id }),
      replace: false
    });
  };

  // Filter articles by category (tab)
  const filteredArticles = articles.filter(a => a.category === tab);

  return (
    <MainLayout pageTitle="Help Center">
      <PageContainer>
        <div className="container py-20 sm:py-6">
          <div className="flex flex-col space-y-2">
            <h1 className="hidden sm:block text-3xl font-bold tracking-tight">Help Center</h1>
            <p className="text-muted-foreground">
              Find guides, tutorials, and answers to common questions.
            </p>
          </div>

          <Tabs defaultValue={tab || "guides"} value={tab as string} className="w-full mt-6">
            <TabsList className="grid w-full grid-cols-3 mb-4 sm:mb-8">
              <TabsTrigger 
                value="guides" 
                onClick={() => navigate({
                  to: '/help-center',
                  search: () => ({ tab: "guides", article: undefined }),
                  replace: true
                })}
                className="text-sm sm:text-base"
              >
                User Guides
              </TabsTrigger>
              <TabsTrigger 
                value="faq" 
                onClick={() => navigate({
                  to: '/help-center',
                  search: () => ({ tab: "faq", article: undefined }),
                  replace: true
                })}
                className="text-sm sm:text-base"
              >
                FAQ
              </TabsTrigger>
              <TabsTrigger 
                value="videos" 
                onClick={() => navigate({
                  to: '/help-center',
                  search: () => ({ tab: "videos", article: undefined }),
                  replace: true
                })}
                className="text-sm sm:text-base"
              >
                Video Tutorials
              </TabsTrigger>
            </TabsList>
            
            <TabsContent value="guides" className="space-y-6">
              {selectedArticle && selectedArticle.category === "guides" ? (
                <div className="space-y-4">
                  <button 
                    onClick={() => navigate({
                      to: '/help-center',
                      search: () => ({ tab: "guides", article: undefined }),
                      replace: false
                    })}
                    className="text-blue-600 hover:text-blue-800 flex items-center gap-2 mb-4"
                  >
                    ← Back to Guides
                  </button>
                  {/* Render the article component */}
                  {selectedArticle.component()}
                </div>
              ) : (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {filteredArticles.map((article) => (
                    <Card 
                      key={article.id}
                      className="cursor-pointer hover:shadow-md transition-shadow"
                      onClick={() => handleArticleSelect(article)}
                    >
                      <CardHeader className="pb-2">
                        <CardTitle className="text-lg">{article.title}</CardTitle>
                        <CardDescription>{article.description}</CardDescription>
                      </CardHeader>
                      <CardContent>
                        <p className="text-sm text-muted-foreground">
                          Click to read the full guide
                        </p>
                      </CardContent>
                    </Card>
                  ))}
                </div>
              )}
            </TabsContent>
            
            <TabsContent value="faq" className="space-y-6">
              <Card>
                <CardHeader>
                  <CardTitle>Frequently Asked Questions</CardTitle>
                  <CardDescription>
                    Find answers to common questions about LeadGPT
                  </CardDescription>
                </CardHeader>
                <CardContent className="space-y-4">
                  <div className="space-y-2">
                    <h3 className="font-medium">How do I import leads?</h3>
                    <p className="text-sm text-muted-foreground">
                      You can import leads by clicking the "Add Leads" button in the sidebar and selecting the "Import Leads" tab.
                    </p>
                  </div>
                  <div className="space-y-2">
                    <h3 className="font-medium">How do I set up the AI Receptionist?</h3>
                    <p className="text-sm text-muted-foreground">
                      Navigate to the AI Receptionist page from the sidebar and follow the setup instructions.
                    </p>
                  </div>
                  <div className="space-y-2">
                    <h3 className="font-medium">How do I make a call to a lead?</h3>
                    <p className="text-sm text-muted-foreground">
                      First select a lead from your list, then click on the Voice tab in the Communication Pane on the right side of the screen. Click the "Start Call" button to initiate the call.
                    </p>
                  </div>
                  <div className="space-y-2">
                    <h3 className="font-medium">How do I invite team members?</h3>
                    <p className="text-sm text-muted-foreground">
                      Go to Settings {'>'} Team and use the "Invite Member" button to send invitations.
                    </p>
                  </div>
                </CardContent>
              </Card>
            </TabsContent>
            
            <TabsContent value="videos" className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <Card>
                  <CardHeader className="pb-2">
                    <CardTitle className="text-lg">Introduction to LeadGPT</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <div className="aspect-video bg-gray-100 flex items-center justify-center rounded-md">
                      <p className="text-muted-foreground">Video coming soon</p>
                    </div>
                  </CardContent>
                </Card>
                <Card>
                  <CardHeader className="pb-2">
                    <CardTitle className="text-lg">AI Receptionist Tutorial</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <div className="aspect-video bg-gray-100 flex items-center justify-center rounded-md">
                      <p className="text-muted-foreground">Video coming soon</p>
                    </div>
                  </CardContent>
                </Card>
              </div>
            </TabsContent>
          </Tabs>
        </div>
      </PageContainer>
    </MainLayout>
  );
} 