import { cn } from '@/lib/utils';
import { ActivityItem as ActivityItemType } from '@/services/dashboard';
import { useLeadStore } from '@/store/leadStore';
import { useNavigate } from '@tanstack/react-router';
import { ArrowRight, CheckCircle2, MessageCircle, MessageSquare, Phone, PhoneOff, Users } from 'lucide-react';

// Fallback icons by activity type
const fallbackIcons = {
  message: MessageSquare,
  call: Phone,
  status: CheckCircle2,
  assignment: Users
};

// Special case for missed calls - use both status and content as fallback
const isMissedCall = (item: ActivityItemType) => {
  return (
    (item.status === 'missed' || item.status === 'no-answer') || 
    (item.type === 'call' && item.content.toLowerCase().includes('missed'))
  );
};

interface ActivityItemProps {
  item: ActivityItemType;
  onClick: (leadId: number) => void;
}

export function ActivityItem({ item, onClick }: ActivityItemProps) {
  // Use the icon from the item, with fallback based on activity type
  const Icon = item.icon || (isMissedCall(item) ? PhoneOff : fallbackIcons[item.type]) || MessageCircle;
  
  const navigate = useNavigate();
  const { setSelectedLead } = useLeadStore();
  
  const handleClick = () => {
    try {
      // Update the lead store with the selected lead ID
      setSelectedLead({ id: item.leadId });
      
      // Use setTimeout to ensure state updates complete before navigation
      setTimeout(() => {
        // Navigate to the leads page
        navigate({ to: '/leads' });
      }, 50);
      
      // Still call the original onClick handler if needed
      onClick(item.leadId);
    } catch (error) {
      console.error('[ActivityItem] Navigation error:', error);
    }
  };
  
  // Determine background color based on activity type and status
  const getBgColor = () => {
    if (item.type === 'message') {
      return 'bg-green-100 text-green-700';
    } else if (item.type === 'call') {
      // First check the status field
      if (isMissedCall(item)) {
        return 'bg-red-100 text-red-700';
      }
      
      // Check if it's an inbound call
      if (item.status === 'inbound' || item.content.toLowerCase().includes('inbound')) {
        return 'bg-blue-100 text-blue-700';
      }
      
      // Default for outbound calls
      return 'bg-indigo-100 text-indigo-700';
    } else if (item.type === 'status') {
      return 'bg-yellow-100 text-yellow-700';
    } else if (item.type === 'assignment') {
      return 'bg-purple-100 text-purple-700';
    }
    // Default
    return 'bg-gray-100 text-gray-700';
  };
  
  return (
    <div
      className="flex items-start justify-between border-b border-gray-100 pb-4 last:border-0 last:pb-0 cursor-pointer hover:bg-gray-50 p-2 rounded-md transition-colors"
      onClick={handleClick}
    >
      <div className="flex items-start space-x-4 min-w-0 flex-1">
        <div className={cn("p-2 rounded-full flex-shrink-0 mt-0.5", getBgColor())}>
          <Icon className="h-4 w-4" />
        </div>
        <div className="min-w-0 flex-1">
          <p className="text-sm font-medium truncate max-w-[180px]">{item.leadName}</p>
          <p className="text-xs text-gray-500 break-words">
            {item.content}
          </p>
        </div>
      </div>
      <div className="flex items-center flex-shrink-0 ml-2">
        <span className="text-xs text-gray-500 mr-2 whitespace-nowrap">{item.time}</span>
        <ArrowRight className="h-3 w-3 text-gray-400 flex-shrink-0" />
      </div>
    </div>
  );
} 