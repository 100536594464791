import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Badge } from '@/components/ui/badge';
import { MetricType, TimeframeType, fetchLeaderboard } from '@/lib/api';
import { useQuery } from '@tanstack/react-query';
import { Crown, Loader2, Medal, User, Users, XCircle } from 'lucide-react';
import { Legend, PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart, ResponsiveContainer, Tooltip } from 'recharts';

interface MetricsLeaderboardProps {
  metricType: MetricType;
  timeframe?: TimeframeType;
  dateRange?: { from: string; to: string };
}

export function MetricsLeaderboard({ metricType, timeframe, dateRange }: MetricsLeaderboardProps) {
  const { data, isLoading, error } = useQuery({
    queryKey: ['leaderboard', metricType, timeframe, dateRange],
    queryFn: () => fetchLeaderboard({
      metric: metricType,
      timeframe: timeframe || 'weekly',
      startDate: dateRange?.from || '',
      endDate: dateRange?.to || ''
    })
  });

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-full p-8">
        <Loader2 className="w-8 h-8 animate-spin text-primary" />
      </div>
    );
  }

  if (error || !data?.leaderboard) {
    return (
      <div className="flex flex-col items-center justify-center h-full p-8 text-muted-foreground">
        <XCircle className="w-8 h-8 mb-2" />
        <p>Could not load leaderboard data</p>
      </div>
    );
  }

  const leaderboard = data.leaderboard;
  
  if (leaderboard.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center h-full p-8 text-muted-foreground">
        <Users className="w-8 h-8 mb-2" />
        <p>No team data available</p>
      </div>
    );
  }
  
  if (leaderboard.length === 1) {
    return (
      <div className="flex flex-col items-center justify-center h-full p-8 text-muted-foreground">
        <User className="w-8 h-8 mb-2" />
        <p>You're the only team member!</p>
        <p className="text-xs mt-2">Your {formatMetricLabel(metricType)}: {formatMetricValue(leaderboard[0].value, metricType)}</p>
      </div>
    );
  }

  // Format the metric value with appropriate units
  function formatMetricValue(value: number, metricType: MetricType) {
    if (metricType === 'avg_response_time_seconds') {
      // Convert seconds to minutes:seconds for display
      const minutes = Math.floor(value / 60);
      const seconds = Math.floor(value % 60);
      return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    } else if (metricType.includes('percent')) {
      return `${value.toFixed(1)}%`;
    } else {
      return value.toString();
    }
  }

  // Format value based on metric type
  const formatValue = (value: number, metricType: MetricType): string => {
    // Handle percentage metrics
    if (metricType.includes('percent') || metricType.includes('rate')) {
      // Format with 1 decimal place for percentages
      return `${value.toFixed(1)}%`;
    } 
    
    // Handle time-based metrics
    else if (metricType.includes('time_seconds')) {
      // Format seconds into minutes and seconds
      const minutes = Math.floor(value / 60);
      const seconds = Math.round(value % 60);
      
      if (value >= 3600) {
        const hours = Math.floor(value / 3600);
        const remainingMinutes = Math.floor((value % 3600) / 60);
        return `${hours}h ${remainingMinutes}m`;
      } else if (minutes > 0) {
        return `${minutes}m ${seconds}s`;
      } else {
        return `${seconds}s`;
      }
    } 
    
    // Handle count metrics
    else if (metricType.includes('count')) {
      // Use locale string for thousands separators
      return value.toLocaleString();
    } 
    
    // Default formatting for other metrics
    else {
      return value.toLocaleString();
    }
  };

  // Get initials from name
  const getInitials = (name: string) => {
    return name
      .split(' ')
      .map(part => part[0])
      .join('')
      .toUpperCase();
  };

  // Get rank icon for top performers
  const getRankIcon = (index: number) => {
    if (index === 0) {
      return <Crown className="h-5 w-5 text-yellow-500" />;
    } else if (index === 1) {
      return <Medal className="h-5 w-5 text-gray-400" />;
    } else if (index === 2) {
      return <Medal className="h-5 w-5 text-amber-600" />;
    }
    return <span className="text-sm font-semibold text-gray-500 w-5 text-center">{index + 1}</span>;
  };

  // Prepare data for radar chart - take top 5 performers
  const radarData = leaderboard.slice(0, 5).map(entry => ({
    name: entry.name,
    value: entry.value,
    fullMark: Math.max(...leaderboard.map(d => d.value)) * 1.2 // Set full mark slightly higher than max value
  }));

  // Generate color for radar chart based on metric type
  const getMetricColor = () => {
    if (metricType.includes('call') || metricType.includes('phone')) {
      return '#3b82f6'; // Blue-500 for call metrics
    } else if (metricType.includes('lead') || metricType.includes('conversion')) {
      return '#10b981'; // Emerald-500 for lead metrics
    } else if (metricType.includes('time') || metricType.includes('duration')) {
      return '#f59e0b'; // Amber-500 for time metrics
    } else if (metricType.includes('message') || metricType.includes('sms')) {
      return '#8b5cf6'; // Violet-500 for message metrics
    } else {
      return '#6366f1'; // Indigo-500 default
    }
  };

  const metricColor = getMetricColor();

  // Format metric type to a readable label
  function formatMetricLabel(metricType: MetricType): string {
    switch (metricType) {
      case 'leads_created_count':
        return 'Leads Created';
      case 'leads_contacted_count':
        return 'Leads Contacted';
      case 'messages_sent_count':
        return 'Messages Sent';
      case 'calls_made_count':
        return 'Calls Made';
      case 'avg_response_time_seconds':
        return 'Avg. Response Time';
      case 'conversion_rate_percent':
        return 'Conversion Rate';
      case 'sms_response_rate_percent':
        return 'SMS Response Rate';
      case 'call_answer_rate_percent':
        return 'Call Answer Rate';
      default:
        return metricType;
    }
  }

  return (
    <div className="flex flex-col lg:flex-row gap-4">
      {/* Leaderboard Table - 1/2 width on desktop, full on mobile */}
      <div className="w-full lg:w-1/2">
        <div className="space-y-3 max-h-[500px] overflow-y-auto pr-1">
          {leaderboard.map((entry, index) => (
            <div 
              key={entry.userId}
              className={`flex items-center justify-between p-3 rounded-lg transition-colors ${
                index === 0 
                  ? 'bg-yellow-50 border border-yellow-100' 
                  : index === 1 
                    ? 'bg-gray-50 border border-gray-100' 
                    : index === 2 
                      ? 'bg-amber-50 border border-amber-100' 
                      : 'hover:bg-gray-50'
              }`}
            >
              <div className="flex items-center space-x-3">
                <div className="flex-shrink-0 w-8 flex justify-center">
                  {getRankIcon(index)}
                </div>
                
                <Avatar className="h-9 w-9 border border-gray-200">
                  <AvatarImage src={`/avatars/${entry.userId}.png`} alt={entry.name} />
                  <AvatarFallback className="bg-primary/10 text-primary text-xs">
                    {getInitials(entry.name)}
                  </AvatarFallback>
                </Avatar>
                
                <div>
                  <span className="font-medium text-gray-900">{entry.name}</span>
                  {index < 3 && (
                    <Badge 
                      variant="outline" 
                      className={`ml-2 text-xs ${
                        index === 0 
                          ? 'bg-yellow-100 border-yellow-200 text-yellow-800' 
                          : index === 1 
                            ? 'bg-gray-100 border-gray-200 text-gray-800' 
                            : 'bg-amber-100 border-amber-200 text-amber-800'
                      }`}
                    >
                      {index === 0 ? 'Top performer' : index === 1 ? '2nd place' : '3rd place'}
                    </Badge>
                  )}
                </div>
              </div>
              
              <div className="font-bold text-gray-900">
                {formatValue(entry.value, metricType)}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Radar Chart - 1/2 width on desktop, full on mobile */}
      <div className="w-full lg:w-1/2 bg-white rounded-lg p-4 border border-gray-100 shadow-sm">
        <div className="h-[300px]">
          <ResponsiveContainer width="100%" height="100%">
            <RadarChart cx="50%" cy="50%" outerRadius="80%" data={radarData}>
              <PolarGrid />
              <PolarAngleAxis dataKey="name" />
              <PolarRadiusAxis />
              <Tooltip formatter={(value) => [formatValue(value as number, metricType), formatMetricLabel(metricType)]} />
              <Radar
                name={formatMetricLabel(metricType)}
                dataKey="value"
                stroke={metricColor}
                fill={metricColor}
                fillOpacity={0.2}
              />
              <Legend />
            </RadarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
}