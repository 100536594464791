import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { useAvailableNumbers, useUserNumbers } from '@/hooks/did';
import { api } from '@/lib/api';
import { US_STATES } from '@/lib/constants';
import { formatPhoneNumber } from '@/lib/utils';
import { useLeadStore } from '@/store/leadStore';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Loader2, Phone, Search, Trash2 } from 'lucide-react';
import { useEffect, useState } from 'react';
import { toast } from 'sonner';

interface DIDManagerProps {
  mode?: 'full' | 'compact';
  onNumberSelected?: (number: string) => void;
  areacode?: string | null;
}

// Type for the number we're purchasing
interface PurchaseNumberData {
  phoneNumber: string;
  locality: string | null;
  region: string | null;
}

export function DIDManager({ mode = 'full', onNumberSelected, areacode = null }: DIDManagerProps) {
  const [searchType, setSearchType] = useState<'areaCode' | 'state'>('areaCode');
  const [areaCode, setAreaCode] = useState(areacode || '');
  const [selectedState, setSelectedState] = useState('');
  const [releaseNumber, setReleaseNumber] = useState<string | null>(null);
  const [purchaseNumber, setPurchaseNumber] = useState<string | null>(null);
  const selectedLead = useLeadStore(state => state.selectedLead);

  const queryClient = useQueryClient();

  // Use the selected location for state/city search
  const { data: availableNumbersResponse, isLoading: isLoadingAvailable } = useAvailableNumbers({
    areaCode: searchType === 'areaCode' ? areaCode : undefined,
    state: searchType === 'state' ? selectedState : mode === 'compact' ? selectedLead?.state ?? undefined : undefined,
    city: searchType === 'state' ? undefined : mode === 'compact' ? selectedLead?.city ?? undefined : undefined,
  });

  const availableNumbers = availableNumbersResponse?.numbers || [];
  const searchMessage = availableNumbersResponse?.message;

  const { data: userNumbers, isLoading: isLoadingUser } = useUserNumbers();

  // Set initial state from selected location if available
  useEffect(() => {
    if (searchType === 'state' && selectedLead?.state && !selectedState) {
      setSelectedState(selectedLead.state);
    }
  }, [searchType, selectedLead?.state, selectedState]);

  useEffect(() => {
    if (areacode) {
      handleAreaCodeChange(areacode);
    }
  }, [areacode]);

  const purchaseMutation = useMutation({
    mutationFn: (number: PurchaseNumberData) => {
      // Log the data we're sending to help debug
      console.log('Purchasing number with data:', {
        phoneNumber: number.phoneNumber,
        locality: number.locality,
        region: number.region
      });
      return api.did.purchaseNumber(number.phoneNumber, number.locality, number.region);
    },
    onSuccess: (response, number) => {
      // Log the response to help debug
      console.log('Purchase response:', response);
      toast.success('Phone number purchased successfully');
      queryClient.invalidateQueries({ queryKey: ['userNumbers'] });
      setPurchaseNumber(null);
      if (onNumberSelected) {
        onNumberSelected(number.phoneNumber);
      }
    },
    onError: (error) => {
      toast.error('Failed to purchase phone number: ' + error.message);
      setPurchaseNumber(null);
    },
  });

  const releaseMutation = useMutation({
    mutationFn: api.did.releaseNumber,
    onSuccess: () => {
      toast.success('Phone number released successfully');
      queryClient.invalidateQueries({ queryKey: ['userNumbers'] });
      setReleaseNumber(null);
    },
    onError: (error) => {
      toast.error('Failed to release phone number: ' + error.message);
      setReleaseNumber(null);
    },
  });

  const handleAreaCodeChange = (value: string) => {
    const formatted = value.replace(/\D/g, '').slice(0, 3);
    setAreaCode(formatted);
  };

  const handlePurchase = (number: { phoneNumber: string; locality: string | null; region: string | null }) => {
    purchaseMutation.mutate({
      phoneNumber: number.phoneNumber,
      locality: number.locality,
      region: number.region
    });
  };

  if (mode === 'compact') {
    return (
      <div className="space-y-4">
        <div className="flex gap-4">
          <Select value={searchType} onValueChange={(value: 'areaCode' | 'state') => setSearchType(value)}>
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="Search by..." />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="areaCode">Area Code</SelectItem>
              <SelectItem value="state">State</SelectItem>
            </SelectContent>
          </Select>

          {searchType === 'areaCode' ? (
            <Input
              type="text"
              placeholder="Enter area code (e.g. 415)"
              value={areaCode}
              onChange={(e) => handleAreaCodeChange(e.target.value)}
              className="w-[180px]"
            />
          ) : (
            <Select value={selectedState} onValueChange={setSelectedState}>
              <SelectTrigger className="w-[180px]">
                <SelectValue placeholder="Select state..." />
              </SelectTrigger>
              <SelectContent>
                {Object.entries(US_STATES).map(([code, name]) => (
                  <SelectItem key={code} value={code}>
                    {name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          )}
        </div>

        <div className="grid grid-cols-1 gap-2 max-h-[300px] overflow-y-auto">
          {isLoadingAvailable ? (
            <div className="flex items-center justify-center py-4">
              <Loader2 className="h-6 w-6 animate-spin text-muted-foreground" />
            </div>
          ) : availableNumbers.length > 0 ? (
            <>
              {searchMessage && (
                <div className="px-3 py-2 text-sm text-muted-foreground bg-muted/50 rounded-md">
                  {searchMessage}
                </div>
              )}
              {availableNumbers.map((number) => (
                <Card key={number.phoneNumber} className="p-3">
                  <div className="flex items-center justify-between">
                    <div>
                      <p className="font-medium">{formatPhoneNumber(number.phoneNumber)}</p>
                      <p className="text-sm text-muted-foreground">
                        {number.locality}, {number.region}
                        {searchType === 'areaCode' && number.region !== selectedState && (
                          <span className="ml-1 text-xs">(Nearby area)</span>
                        )}
                      </p>
                    </div>
                    <AlertDialog open={purchaseNumber === number.phoneNumber} onOpenChange={(open) => !open && setPurchaseNumber(null)}>
                      <AlertDialogTrigger asChild>
                        <Button
                          variant="outline"
                          size="sm"
                          onClick={() => setPurchaseNumber(number.phoneNumber)}
                          disabled={purchaseMutation.isPending && purchaseNumber === number.phoneNumber}
                        >
                          {purchaseMutation.isPending && purchaseNumber === number.phoneNumber ? (
                            <Loader2 className="h-4 w-4 animate-spin mr-2" />
                          ) : null}
                          Select & Purchase
                        </Button>
                      </AlertDialogTrigger>
                      <AlertDialogContent>
                        <AlertDialogHeader>
                          <AlertDialogTitle>Purchase Phone Number</AlertDialogTitle>
                          <AlertDialogDescription>
                            Are you sure you want to purchase {formatPhoneNumber(number.phoneNumber)}? This will incur an additional $2 per month on your Twilio account.
                          </AlertDialogDescription>
                        </AlertDialogHeader>
                        <AlertDialogFooter>
                          <AlertDialogCancel>Cancel</AlertDialogCancel>
                          <AlertDialogAction 
                            onClick={() => handlePurchase(number)}
                            disabled={purchaseMutation.isPending}
                          >
                            {purchaseMutation.isPending ? (
                              <Loader2 className="h-4 w-4 animate-spin mr-2" />
                            ) : null}
                            Purchase
                          </AlertDialogAction>
                        </AlertDialogFooter>
                      </AlertDialogContent>
                    </AlertDialog>
                  </div>
                </Card>
              ))}
            </>
          ) : (
            <div className="flex flex-col items-center justify-center py-4 text-center">
              <Search className="h-8 w-8 text-muted-foreground mb-2" />
              <p className="text-sm text-muted-foreground">
                {searchType === 'areaCode'
                  ? areaCode
                    ? 'No numbers available in this area code or nearby areas'
                    : 'Enter an area code to search for available numbers'
                  : selectedState
                  ? 'No numbers available in this state'
                  : 'Select a state to search for available numbers'}
              </p>
              {areaCode && (
                <p className="text-xs text-muted-foreground mt-2">
                  Try searching by state instead to see all available numbers in your region.
                </p>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <Card>
        <CardHeader>
          <CardTitle>Add New Numbers</CardTitle>
          <CardDescription>Add new phone numbers to your account</CardDescription>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            <div className="flex gap-4">
              <Select value={searchType} onValueChange={(value: 'areaCode' | 'state') => setSearchType(value)}>
              <SelectTrigger className="w-[180px]">
                <SelectValue placeholder="Search by..." />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="areaCode">Area Code</SelectItem>
                <SelectItem value="state">State</SelectItem>
              </SelectContent>
            </Select>

            {searchType === 'areaCode' ? (
              <Input
                type="text"
                placeholder="Enter area code (e.g. 415)"
                value={areaCode}
                onChange={(e) => handleAreaCodeChange(e.target.value)}
                className="w-[180px]"
              />
            ) : (
              <Select value={selectedState} onValueChange={setSelectedState}>
                <SelectTrigger className="w-[180px]">
                  <SelectValue placeholder="Select state..." />
                </SelectTrigger>
                <SelectContent>
                  {Object.entries(US_STATES).map(([code, name]) => (
                    <SelectItem key={code} value={code}>
                      {name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            )}
          </div>

          {isLoadingAvailable ? (
            <div className="flex items-center justify-center py-8">
              <Loader2 className="h-8 w-8 animate-spin text-muted-foreground" />
            </div>
          ) : availableNumbers && availableNumbers.length > 0 ? (
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Phone Number</TableHead>
                  <TableHead>Location</TableHead>
                  <TableHead className="text-right">Action</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {availableNumbers.map((number) => (
                  <TableRow key={number.phoneNumber}>
                    <TableCell className="font-medium">
                      {formatPhoneNumber(number.phoneNumber)}
                    </TableCell>
                    <TableCell>
                      {number.locality}, {number.region}
                    </TableCell>
                    <TableCell className="text-right">
                      <AlertDialog open={purchaseNumber === number.phoneNumber} onOpenChange={(open) => !open && setPurchaseNumber(null)}>
                        <AlertDialogTrigger asChild>
                          <Button 
                            variant="outline" 
                            size="sm" 
                            onClick={() => setPurchaseNumber(number.phoneNumber)}
                            disabled={purchaseMutation.isPending && purchaseNumber === number.phoneNumber}
                          >
                            {purchaseMutation.isPending && purchaseNumber === number.phoneNumber ? (
                              <Loader2 className="h-4 w-4 animate-spin mr-2" />
                            ) : null}
                            Purchase
                          </Button>
                        </AlertDialogTrigger>
                        <AlertDialogContent>
                          <AlertDialogHeader>
                            <AlertDialogTitle>Purchase Phone Number</AlertDialogTitle>
                            <AlertDialogDescription>
                              Are you sure you want to purchase {formatPhoneNumber(number.phoneNumber)}? This will incur an additional $2 per month on your account.
                            </AlertDialogDescription>
                          </AlertDialogHeader>
                          <AlertDialogFooter>
                            <AlertDialogCancel>Cancel</AlertDialogCancel>
                            <AlertDialogAction 
                              onClick={() => handlePurchase(number)}
                              disabled={purchaseMutation.isPending}
                            >
                              {purchaseMutation.isPending ? (
                                <Loader2 className="h-4 w-4 animate-spin mr-2" />
                              ) : null}
                              Purchase
                            </AlertDialogAction>
                          </AlertDialogFooter>
                        </AlertDialogContent>
                      </AlertDialog>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <div className="flex flex-col items-center justify-center py-8 text-center">
              <Search className="h-12 w-12 text-muted-foreground mb-4" />
              <p className="text-muted-foreground">
                {searchType === 'areaCode'
                  ? areaCode
                    ? 'No numbers available in this area code'
                    : 'Enter an area code to search for available numbers'
                  : selectedState
                  ? 'No numbers available in this state'
                  : 'Select a state to search for available numbers'}
              </p>
            </div>
          )}
        </div>
        </CardContent>
      </Card>

      <Card className="p-6">
        <h3 className="text-lg font-medium mb-4">Your Numbers</h3>
        {isLoadingUser ? (
          <div className="flex items-center justify-center py-8">
            <Loader2 className="h-8 w-8 animate-spin text-muted-foreground" />
          </div>
        ) : userNumbers && userNumbers.length > 0 ? (
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Phone Number</TableHead>
                <TableHead>Location</TableHead>
                <TableHead>Area Code</TableHead>
                <TableHead>Added</TableHead>
                <TableHead className="text-right">Action</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {userNumbers.map((number) => (
                <TableRow key={number.phoneNumber}>
                  <TableCell className="font-medium">
                    {formatPhoneNumber(number.phoneNumber)}
                  </TableCell>
                  <TableCell>
                    {number.locality}, {number.region}
                  </TableCell>
                  <TableCell>{number.areaCode}</TableCell>
                  <TableCell>{new Date(number.createdAt).toLocaleDateString()}</TableCell>
                  <TableCell className="text-right">
                    <AlertDialog open={releaseNumber === number.phoneNumber} onOpenChange={(open) => !open && setReleaseNumber(null)}>
                      <AlertDialogTrigger asChild>
                        <Button variant="outline" size="sm" className="text-destructive hover:text-destructive" onClick={() => setReleaseNumber(number.phoneNumber)}>
                          <Trash2 className="h-4 w-4" />
                        </Button>
                      </AlertDialogTrigger>
                      <AlertDialogContent>
                        <AlertDialogHeader>
                          <AlertDialogTitle>Release Phone Number</AlertDialogTitle>
                          <AlertDialogDescription>
                            Are you sure you want to release {formatPhoneNumber(number.phoneNumber)}? This action cannot be undone.
                          </AlertDialogDescription>
                        </AlertDialogHeader>
                        <AlertDialogFooter>
                          <AlertDialogCancel>Cancel</AlertDialogCancel>
                          <AlertDialogAction onClick={() => releaseMutation.mutate(number.phoneNumber)} className="bg-destructive hover:bg-destructive/90">
                            Release
                          </AlertDialogAction>
                        </AlertDialogFooter>
                      </AlertDialogContent>
                    </AlertDialog>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <div className="flex flex-col items-center justify-center py-8 text-center">
            <Phone className="h-12 w-12 text-muted-foreground mb-4" />
            <p className="text-muted-foreground">You don't have any phone numbers yet</p>
          </div>
        )}
      </Card>
    </div>
  );
} 