import { axiosInstance } from '@/lib/api';
import { ActionItem, ActivityItem, CommunicationMetrics, MetricItem, TeamMember } from '@/services/dashboard';
import { useQuery } from '@tanstack/react-query';

/**
 * Dashboard data structure returned from the API
 * 
 * Note on metrics:
 * - Sum metrics: New Leads, Leads Contacted
 * - Average metrics: Conversion Rate, Avg. Response Time, SMS Response Rate, Call Answer Rate
 * 
 * For team metrics:
 * - Sum metrics: Leads Processed
 * - Average metrics: Conversion Rate, Response Time
 */
interface DashboardData {
  todayMetrics: MetricItem[];
  actionItems: ActionItem[];
  recentActivity: ActivityItem[];
  communicationMetrics: CommunicationMetrics;
  teamMembers: TeamMember[];
  currentUserId: number;
}

export interface CustomDateRange {
  startDate: Date;
  endDate: Date;
}

/**
 * Hook to fetch dashboard data with support for different timeframes and custom date ranges
 * 
 * @param timeframe - The timeframe to fetch data for ('today', 'week', 'month', 'custom')
 * @param customDateRange - Custom date range when timeframe is 'custom'
 * @returns Query result with dashboard data
 */
export function useDashboardData(
  timeframe: string = 'today', 
  customDateRange: CustomDateRange | null = null
) {
  return useQuery<DashboardData>({
    queryKey: ['dashboard', timeframe, customDateRange],
    queryFn: async () => {
      let url = `/dashboard?timeframe=${timeframe}`;
      
      // Add custom date range parameters if provided
      if (timeframe === 'custom' && customDateRange) {
        const startDate = customDateRange.startDate.toISOString().split('T')[0];
        const endDate = customDateRange.endDate.toISOString().split('T')[0];
        url += `&startDate=${startDate}&endDate=${endDate}`;
      }
      
      const { data } = await axiosInstance.get<DashboardData>(url);
      
      // Map string icon names to actual Lucide icon components
      if (data.todayMetrics) {
        data.todayMetrics = data.todayMetrics.map((metric) => ({
          ...metric,
          // The icon is already a string name in the response, will be mapped in the component
        }));
      }
      
      return data;
    },
    staleTime: 5 * 60 * 1000, // 5 minutes
  });
} 