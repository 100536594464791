import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";
import { toast } from "@/components/ui/use-toast";
import { cn } from "@/lib/utils";
import { CalendarEvent, CalendarService } from "@/services/calendar.service";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addMinutes, differenceInMinutes, format, isToday, parseISO, set } from "date-fns";
import { CalendarIcon, Clock, Loader2 } from "lucide-react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

interface EditEventDialogProps {
  isOpen: boolean;
  onClose: () => void;
  event: CalendarEvent;
}

// Form schema for validating event creation (same as CreateEventDialog)
const eventFormSchema = z.object({
  title: z.string().min(1, "Title is required"),
  description: z.string().optional(),
  location: z.string().optional(),
  startDate: z.date({
    required_error: "Start date is required",
  }),
  startHour: z.enum([
    "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"
  ], {
    required_error: "Hour is required",
  }),
  startMinute: z.enum([
    "00", "15", "30", "45"
  ], {
    required_error: "Minute is required",
  }),
  startPeriod: z.enum(["AM", "PM"], {
    required_error: "AM/PM is required",
  }),
  duration: z.enum(["15", "30", "60", "90", "120"], {
    required_error: "Duration is required",
  }),
}).refine((data) => {
  // Validate that selected time is not in the past when date is today
  if (isToday(data.startDate)) {
    const now = new Date();
    let selectedHour = parseInt(data.startHour);
    if (data.startPeriod === "PM" && selectedHour !== 12) {
      selectedHour += 12;
    } else if (data.startPeriod === "AM" && selectedHour === 12) {
      selectedHour = 0;
    }
    
    const selectedTime = set(new Date(), {
      hours: selectedHour,
      minutes: parseInt(data.startMinute),
      seconds: 0,
      milliseconds: 0
    });
    
    return selectedTime > now;
  }
  return true;
}, {
  message: "Cannot select a time in the past",
  path: ["startHour"], // This will show the error under the hour field
});

type EventFormValues = z.infer<typeof eventFormSchema>;

export function EditEventDialog({ isOpen, onClose, event }: EditEventDialogProps) {
  const queryClient = useQueryClient();
  
  // Parse the event dates
  const startDate = parseISO(event.startTime);
  const endDate = parseISO(event.endTime);
  
  // Calculate duration in minutes
  const durationInMinutes = differenceInMinutes(endDate, startDate);
  
  // Get the closest standard duration
  const getDuration = (minutes: number): "15" | "30" | "60" | "90" | "120" => {
    if (minutes <= 15) return "15";
    if (minutes <= 30) return "30";
    if (minutes <= 60) return "60";
    if (minutes <= 90) return "90";
    return "120";
  };
  
  // Format time values for form
  const hours = startDate.getHours();
  const minutes = startDate.getMinutes();
  
  // Convert to 12-hour format
  const hour12 = hours % 12 || 12;
  const period = hours >= 12 ? "PM" : "AM";
  const minute = minutes === 0 ? "00" : minutes === 15 ? "15" : minutes === 30 ? "30" : "45";

  // Set up form with validation and pre-filled values
  const form = useForm<EventFormValues>({
    resolver: zodResolver(eventFormSchema),
    defaultValues: {
      title: event.title,
      description: event.description || "",
      location: event.location || "",
      startDate: startDate,
      startHour: hour12.toString() as any,
      startMinute: minute as any,
      startPeriod: period as "AM" | "PM",
      duration: getDuration(durationInMinutes),
    },
  });

  // Watch the startDate to update time selection validation
  const selectedDate = form.watch("startDate");
  
  // Watch time fields to detect changes and validate
  const startHour = form.watch("startHour");
  const startMinute = form.watch("startMinute");
  const startPeriod = form.watch("startPeriod");
  
  // Validate time selection when period changes and adjust if needed
  useEffect(() => {
    if (isToday(selectedDate) && isTimeInPast(startHour, startMinute, startPeriod)) {
      // Find the next valid time if current selection is in the past
      const now = new Date();
      const currentHour = now.getHours();
      const currentMinute = now.getMinutes();
      const roundedMinute = Math.ceil(currentMinute / 15) * 15;
      
      // Calculate next available time slot
      let nextHour = currentHour;
      let nextMinute = roundedMinute;
      
      if (roundedMinute >= 60) {
        nextHour += 1;
        nextMinute = 0;
      }
      
      // Convert to 12-hour format
      const nextHour12 = nextHour % 12 || 12;
      const nextPeriod = nextHour >= 12 ? "PM" : "AM";
      
      // Update form values with next available time
      form.setValue("startHour", nextHour12.toString() as any);
      form.setValue("startMinute", nextMinute === 0 ? "00" : nextMinute.toString() as any);
      form.setValue("startPeriod", nextPeriod as "AM" | "PM");
    }
  }, [startPeriod, form, selectedDate, startHour, startMinute]);

  // Update event mutation
  const { mutate: updateEvent, isPending } = useMutation({
    mutationFn: async (values: EventFormValues) => {
      // Convert 12-hour time to 24-hour time
      let hours = parseInt(values.startHour);
      if (values.startPeriod === "PM" && hours !== 12) {
        hours += 12;
      } else if (values.startPeriod === "AM" && hours === 12) {
        hours = 0;
      }
      
      const minutes = parseInt(values.startMinute);
      
      // Create start time by combining date and time
      const startTime = set(values.startDate, {
        hours,
        minutes,
        seconds: 0,
        milliseconds: 0
      });

      // Calculate end time based on duration
      const endTime = addMinutes(startTime, parseInt(values.duration));

      // Create event update object
      const eventUpdateData = {
        title: values.title,
        description: values.description || null,
        location: values.location || null,
        startTime: startTime.toISOString(),
        endTime: endTime.toISOString(),
        // Keep other properties unchanged
        leadId: event.leadId,
        meetingLink: event.meetingLink,
      };

      return CalendarService.updateEvent(event.id, eventUpdateData);
    },
    onSuccess: () => {
      // Show success message
      toast({
        title: "Event updated",
        description: "Your calendar event has been updated.",
      });
      
      // Close dialog
      onClose();
      
      // Invalidate calendar events query
      queryClient.invalidateQueries({ queryKey: ['calendarEvents'], exact: false });
      
      // Also invalidate leadEvents query if this event is connected to a lead
      if (event.leadId) {
        queryClient.invalidateQueries({ queryKey: ['leadEvents', event.leadId], exact: true });
      }
    },
    onError: (error) => {
      console.error("Error updating event:", error);
      toast({
        title: "Failed to update event",
        description: "There was an error updating your event. Please try again.",
        variant: "destructive",
      });
    },
  });

  function onSubmit(values: EventFormValues) {
    updateEvent(values);
  }

  // Check if a time slot is in the past
  const isTimeInPast = (hour: string, minute: string, period: "AM" | "PM") => {
    if (!isToday(form.watch("startDate"))) return false;
    
    const now = new Date();
    let selectedHour = parseInt(hour);
    if (period === "PM" && selectedHour !== 12) {
      selectedHour += 12;
    } else if (period === "AM" && selectedHour === 12) {
      selectedHour = 0;
    }
    
    return (
      selectedHour < now.getHours() || 
      (selectedHour === now.getHours() && parseInt(minute) <= now.getMinutes())
    );
  };

  // Check if AM is entirely in the past today
  const isAMInPast = () => {
    if (!isToday(selectedDate)) return false;
    
    const now = new Date();
    return now.getHours() >= 12;
  };

  // Check if a specific hour in AM is in the past
  const isHourInAMPast = (hour: string) => {
    if (!isToday(selectedDate)) return false;
    if (form.watch("startPeriod") !== "AM") return false;
    
    const now = new Date();
    const hourNum = parseInt(hour);
    const hourIn24 = hourNum === 12 ? 0 : hourNum;
    
    return now.getHours() > hourIn24;
  };

  // Check if a specific hour in PM is in the past
  const isHourInPMPast = (hour: string) => {
    if (!isToday(selectedDate)) return false;
    if (form.watch("startPeriod") !== "PM") return false;
    
    const now = new Date();
    const hourNum = parseInt(hour);
    const hourIn24 = hourNum === 12 ? 12 : hourNum + 12;
    
    return now.getHours() > hourIn24;
  };

  return (
    <Dialog open={isOpen} onOpenChange={(open) => !open && onClose()}>
      <DialogContent className="sm:max-w-[550px] overflow-y-auto max-h-[90vh]">
        <DialogHeader>
          <DialogTitle>Edit Event</DialogTitle>
          <DialogDescription>
            Update the details of your calendar event.
          </DialogDescription>
        </DialogHeader>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4 pt-2">
            <FormField
              control={form.control}
              name="title"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Event Title</FormLabel>
                  <FormControl>
                    <Input placeholder="Meeting with Client" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Description</FormLabel>
                  <FormControl>
                    <Textarea
                      placeholder="Details about the meeting..."
                      className="min-h-[80px]"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="location"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Location</FormLabel>
                  <FormControl>
                    <Input placeholder="Office or Zoom call" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="startDate"
              render={({ field }) => (
                <FormItem className="flex flex-col">
                  <FormLabel>Date</FormLabel>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant={"outline"}
                          className={cn(
                            "w-full pl-3 text-left font-normal",
                            !field.value && "text-muted-foreground"
                          )}
                        >
                          {field.value ? (
                            format(field.value, "EEEE, MMMM d, yyyy")
                          ) : (
                            <span>Pick a date</span>
                          )}
                          <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar
                        mode="single"
                        selected={field.value}
                        onSelect={field.onChange}
                        disabled={(date) => date < new Date(new Date().setHours(0, 0, 0, 0))}
                        initialFocus
                      />
                    </PopoverContent>
                  </Popover>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="space-y-2">
              <FormLabel>Start Time</FormLabel>
              <div className="flex items-center gap-2">
                <div className="flex-1 flex gap-2 items-center">
                  <Popover>
                    <PopoverTrigger asChild>
                      <Button variant="outline" className="flex-1">
                        <Clock className="mr-2 h-4 w-4" />
                        <span className="mr-2">
                          {form.watch("startHour")}:{form.watch("startMinute")} {form.watch("startPeriod")}
                        </span>
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent className="p-0 w-fit" align="start">
                      <div className="grid grid-cols-3 gap-2 p-4">
                        <div>
                          <p className="text-sm font-medium mb-2">Hour</p>
                          <div className="grid grid-cols-4 gap-2">
                            {["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"].map((hour) => (
                              <Button
                                key={hour}
                                type="button"
                                variant={form.watch("startHour") === hour ? "default" : "outline"}
                                className="h-9 w-9 p-0"
                                disabled={
                                  (form.watch("startPeriod") === "AM" && isHourInAMPast(hour)) ||
                                  (form.watch("startPeriod") === "PM" && isHourInPMPast(hour)) ||
                                  isTimeInPast(hour, form.watch("startMinute"), form.watch("startPeriod"))
                                }
                                onClick={() => {
                                  form.setValue("startHour", hour as any);
                                  // If this creates an invalid time, also update the minute to next available
                                  if (isTimeInPast(hour, form.watch("startMinute"), form.watch("startPeriod"))) {
                                    const now = new Date();
                                    // If we're in the current hour, find next available minute
                                    if (
                                      (form.watch("startPeriod") === "AM" && 
                                       (parseInt(hour) === 12 ? 0 : parseInt(hour)) === now.getHours() % 12) ||
                                      (form.watch("startPeriod") === "PM" && 
                                       (parseInt(hour) === 12 ? 12 : parseInt(hour) + 12) === now.getHours())
                                    ) {
                                      const nextMinute = Math.ceil(now.getMinutes() / 15) * 15;
                                      form.setValue("startMinute", nextMinute === 0 ? "00" : nextMinute.toString() as any);
                                    }
                                  }
                                }}
                              >
                                {hour}
                              </Button>
                            ))}
                          </div>
                        </div>
                        <div>
                          <p className="text-sm font-medium mb-2">Minute</p>
                          <div className="grid grid-cols-2 gap-2">
                            {["00", "15", "30", "45"].map((minute) => (
                              <Button
                                key={minute}
                                type="button"
                                variant={form.watch("startMinute") === minute ? "default" : "outline"}
                                className="h-9 w-9 p-0"
                                disabled={isTimeInPast(form.watch("startHour"), minute, form.watch("startPeriod"))}
                                onClick={() => form.setValue("startMinute", minute as any)}
                              >
                                {minute}
                              </Button>
                            ))}
                          </div>
                        </div>
                        <div>
                          <p className="text-sm font-medium mb-2">AM/PM</p>
                          <div className="grid grid-cols-1 gap-2">
                            {["AM", "PM"].map((period) => (
                              <Button
                                key={period}
                                type="button"
                                variant={form.watch("startPeriod") === period ? "default" : "outline"}
                                className="h-9"
                                disabled={period === "AM" && isAMInPast()}
                                onClick={() => {
                                  form.setValue("startPeriod", period as any);
                                  
                                  // If switching to AM and the current selection would be in the past
                                  if (period === "AM" && isToday(selectedDate)) {
                                    const now = new Date();
                                    const currentHourIn12 = now.getHours() % 12 || 12;
                                    const selectedHour = parseInt(form.watch("startHour"));
                                    
                                    // If the selected hour would be in the past in AM
                                    if ((selectedHour < currentHourIn12) || 
                                        (selectedHour === currentHourIn12 && 
                                         parseInt(form.watch("startMinute")) <= now.getMinutes())) {
                                      // Update to current hour + next available minute
                                      form.setValue("startHour", currentHourIn12.toString() as any);
                                      const nextMinute = Math.ceil(now.getMinutes() / 15) * 15;
                                      const adjustedMinute = nextMinute >= 60 ? "00" : nextMinute === 0 ? "00" : nextMinute.toString();
                                      form.setValue("startMinute", adjustedMinute as any);
                                      
                                      // If needed to roll over to next hour
                                      if (nextMinute >= 60) {
                                        const nextHour = (currentHourIn12 + 1) % 12 || 12;
                                        form.setValue("startHour", nextHour.toString() as any);
                                      }
                                    }
                                  }
                                }}
                              >
                                {period}
                              </Button>
                            ))}
                          </div>
                        </div>
                      </div>
                    </PopoverContent>
                  </Popover>
                </div>
              </div>
              {form.formState.errors.startHour && (
                <p className="text-sm font-medium text-destructive">
                  {form.formState.errors.startHour.message}
                </p>
              )}
            </div>

            <FormField
              control={form.control}
              name="duration"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Duration</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Select duration" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="15">15 minutes</SelectItem>
                      <SelectItem value="30">30 minutes</SelectItem>
                      <SelectItem value="60">1 hour</SelectItem>
                      <SelectItem value="90">1.5 hours</SelectItem>
                      <SelectItem value="120">2 hours</SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            <DialogFooter className="pt-4">
              <Button type="button" variant="outline" onClick={onClose}>
                Cancel
              </Button>
              <Button type="submit" disabled={isPending}>
                {isPending ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    Updating...
                  </>
                ) : (
                  "Update Event"
                )}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
} 