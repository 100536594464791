import { Button } from '@/components/ui/button';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue
} from '@/components/ui/select';
import { useTeams } from '@/hooks/useTeams';
import { Lead } from '@/types';
import {
  ChevronLeft,
  Loader2,
  Mail,
  ScrollText,
  UserPlus2
} from 'lucide-react';
import { LeadStatusSelector } from '../LeadStatusSelector';

type LoadingStateKey = 'calendar' | 'googleCalendar' | 'callLog' | 'email' | 'saveNote' | 'saving' | 'deleting' | 'reassigning';

interface QuickActionsBarProps {
  lead: Lead | null;
  formData: Partial<Lead>;
  isSaving: boolean;
  loadingStates: {
    calendar: boolean;
    googleCalendar: boolean;
    callLog: boolean;
    email: boolean;
    saveNote: boolean;
    saving: boolean;
    deleting: boolean;
    reassigning: boolean;
  };
  setSelectedLeadId: (id: number | null) => void;
  handleStatusChange: (newStatus: string) => Promise<void>;
  handleEmailClick: () => Promise<void>;
  handleGoogleCalendarClick: () => Promise<void>;
  setIsCallLogOpen: (isOpen: boolean) => void;
  setLoadingState: (action: LoadingStateKey, isLoading: boolean) => void;
  googleCalendarCooldownRef: React.MutableRefObject<NodeJS.Timeout | undefined>;
  handleReassignLead: (userId: number) => Promise<void>;
}

export function QuickActionsBar({
  lead,
  formData,
  isSaving,
  loadingStates,
  setSelectedLeadId,
  handleStatusChange,
  handleEmailClick,
  handleGoogleCalendarClick,
  setIsCallLogOpen,
  setLoadingState,
  googleCalendarCooldownRef,
  handleReassignLead
}: QuickActionsBarProps) {
  const { currentTeam, getTeamDetails } = useTeams();
  const { data: teamDetails } = getTeamDetails(currentTeam?.id || 0);

  return (
    <div className="flex items-center justify-between gap-2 p-2 px-4 lg:px-2 bg-gray-50 dark:bg-gray-800/50">
      <div className="flex items-center gap-2">
        <Button
          variant="ghost"
          size="icon"
          onClick={() => setSelectedLeadId(null)}
          className="lg:hidden"
        >
          <ChevronLeft className="h-4 w-4" />
        </Button>
        <LeadStatusSelector
          className="max-w-[140px] sm:max-w-[180px] overflow-hidden"
          status={formData.status || 'new'}
          onStatusChange={handleStatusChange}
          isLoading={isSaving}
          leadId={lead?.id}
        />
      </div>

      <div className="flex gap-2">
        {/* Reassign Lead Dropdown */}
        {currentTeam?.members && (
          <Select
            disabled={loadingStates.reassigning}
            onValueChange={(value) => {
              const userId = parseInt(value);
              if (!isNaN(userId)) {
                setLoadingState('reassigning', true);
                handleReassignLead(userId)
                  .finally(() => setLoadingState('reassigning', false));
              }
            }}
            value={lead?.userId?.toString()}
          >
            <SelectTrigger className="max-w-[140px] sm:max-w-[180px] overflow-hidden">
              <div className="flex items-center gap-2">
                <UserPlus2 className="hidden sm:block h-4 w-4" />
                <SelectValue placeholder="Assign to..." />
              </div>
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectLabel>Assign to...</SelectLabel>
                {currentTeam.members.map((member) => (
                  <SelectItem 
                    key={member.id} 
                    value={member.id.toString()}
                    disabled={member.id === lead?.userId}
                  >
                    {member.name || member.email}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        )}

         <Button 
          variant="ghost" 
          size="icon"
          disabled={loadingStates.googleCalendar || !!googleCalendarCooldownRef.current}
          onClick={handleGoogleCalendarClick}
        >
          {loadingStates.googleCalendar ? (
            <Loader2 className="h-4 w-4 animate-spin" />
          ) : (
            <img src="/assets/integrations/google-calendar.svg" alt="Google Calendar" className="h-4 w-4" />
          )}
        </Button>
        
        <Button 
          variant="ghost" 
          size="icon"
          disabled={loadingStates.email}
          onClick={handleEmailClick}
        >
          {loadingStates.email ? (
            <Loader2 className="h-4 w-4 animate-spin" />
          ) : (
            <Mail className="h-4 w-4" />
          )}
        </Button>

        <Button 
          variant="ghost" 
          size="icon"
          disabled={loadingStates.callLog}
          onClick={async () => {
            if (!lead) return;
            setLoadingState('callLog', true);
            try {
              setIsCallLogOpen(true);
            } finally {
              setLoadingState('callLog', false);
            }
          }}
        >
          {loadingStates.callLog ? (
            <Loader2 className="h-4 w-4 animate-spin" />
          ) : (
            <ScrollText className="h-4 w-4" />
          )}
        </Button>
      </div>
    </div>
  );
} 