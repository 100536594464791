import { useWebSocket } from '@/contexts/WebSocketContext';
import { RoomType, SocketEvents } from '@/services/websocket.service';
import { useAuthStore } from '@/store/authStore';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';
import { toast } from 'sonner';

export function WebSocketLeadAssignmentNotification() {
  const { on, joinRoom } = useWebSocket();
  const queryClient = useQueryClient();
  const currentUser = useAuthStore((state) => state.user);
  // Track recently shown notifications to prevent duplicates
  const recentNotifications = useRef<Set<string>>(new Set());
  const NOTIFICATION_DEBOUNCE_MS = 2000; // 2 seconds

  useEffect(() => {
    if (!currentUser) {
      console.log('[WebSocketLeadAssignmentNotification] No current user, skipping setup');
      return;
    }

    console.log(`[WebSocketLeadAssignmentNotification] Setting up listener for user ${currentUser.id}`);

    // Join the agency room to receive lead assignment events
    joinRoom(RoomType.AGENCY, currentUser.agencyId);
    console.log(`[WebSocketLeadAssignmentNotification] Joined agency room: ${currentUser.agencyId}`);

    const unsubscribe = on<{
      leadId: number;
      leadName: string;
      userId: number;
      userName: string;
      assignedToId: number;
      assignedToName: string;
      timestamp: string;
    }>(SocketEvents.LEAD_ASSIGNMENT, (data) => {
      console.log('[WebSocketLeadAssignmentNotification] Received lead assignment event:', {
        leadId: data.leadId,
        leadName: data.leadName,
        userId: data.userId,
        userName: data.userName,
        assignedToId: data.assignedToId,
        assignedToName: data.assignedToName,
        currentUserId: currentUser.id
      });

      // Create a unique key for this notification
      const notificationKey = `${data.leadId}-${data.timestamp}`;

      // If we've shown this notification recently, skip it
      if (recentNotifications.current.has(notificationKey)) {
        console.log('[WebSocketLeadAssignmentNotification] Skipping duplicate notification:', notificationKey);
        return;
      }

      // If we are the assignee, show a notification and update our cache
      if (data.assignedToId === currentUser.id) {
        console.log('[WebSocketLeadAssignmentNotification] This lead was assigned to us, showing notification');
        
        toast.info(`New lead assigned to you`, {
          description: `${data.leadName} assigned by ${data.userName || 'another user'}`
        });

        // Add to recent notifications
        recentNotifications.current.add(notificationKey);

        // Remove from recent notifications after debounce period
        setTimeout(() => {
          recentNotifications.current.delete(notificationKey);
        }, NOTIFICATION_DEBOUNCE_MS);

        // Invalidate the leads query to show the new lead in the list
        console.log('[WebSocketLeadAssignmentNotification] Invalidating leads query');
        queryClient.invalidateQueries({ queryKey: ['leads'] });
      } else {
        console.log('[WebSocketLeadAssignmentNotification] Lead was assigned to someone else');
      }
    });

    return () => {
      console.log('[WebSocketLeadAssignmentNotification] Cleaning up listener');
      unsubscribe();
    };
  }, [on, currentUser, queryClient, joinRoom]);

  return null;
} 