import { axiosInstance } from '@/lib/api';
import { useAuthStore } from '@/store/authStore';
import { useTeamStore } from '@/store/teamStore';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { toast } from 'sonner';

interface User {
  id: number;
  name: string;
  email: string;
  role: string;
  avatar?: string;
  stripeId?: string | null;
  cardBrand?: string | null;
  cardLastFour?: string | null;
  trialEndsAt?: string | null;
}

interface AuthState {
  user: User | null;
  token: string | null;
  isAuthenticated: boolean;
  isLoading: boolean;
  isError: boolean;
  error: Error | null;
  login: (email: string, password: string) => Promise<boolean>;
  logout: () => void;
  refetchUser: () => Promise<any>;
}

// Custom hook to fetch user data
function useUserQuery(token: string | null) {
  return useQuery({
    queryKey: ['user'],
    queryFn: async () => {
      if (!token) return null;
      
      try {
        console.log('[useAuth] Fetching user data with token:', token ? `${token.substring(0, 15)}...` : 'null');
        // Remove '/api' prefix since axiosInstance already includes it
        const response = await axiosInstance.get('/auth/me', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        console.log('[useAuth] User data fetched successfully:', response.data);
        return response.data.user;
      } catch (error) {
        console.error('[useAuth] Error fetching user data:', error);
        console.log('[useAuth] API request failed. Check server logs and API endpoint configuration.');
        
        // Check if it's a 404 error specifically
        if (error instanceof AxiosError && error.response && error.response.status === 404) {
          console.error('[useAuth] API endpoint not found (404). Check that the endpoint /auth/me exists on the server.');
        }
        
        // If token is invalid, clear it
        localStorage.removeItem('auth_token');
        throw error;
      }
    },
    enabled: !!token, // Only run query if token exists
    retry: false
  });
}

export function useAuth(): AuthState {
  const authStore = useAuthStore();
  const [token, setToken] = useState<string | null>(authStore.token);
  
  // Initialize token from localStorage and authStore
  useEffect(() => {
    // Check token from localStorage
    const localStorageToken = localStorage.getItem('auth_token');
    console.log('[useAuth] Initial token from localStorage:', localStorageToken ? `${localStorageToken.substring(0, 15)}...` : 'null');
    console.log('[useAuth] Token length:', localStorageToken?.length || 0);
    
    // Check token from authStore
    const storeToken = authStore.token;
    console.log('[useAuth] Initial token from authStore:', storeToken ? `${storeToken.substring(0, 15)}...` : 'null');
    console.log('[useAuth] Store token length:', storeToken?.length || 0);
    
    // If we have a token in localStorage but not in the store, update the store
    if (localStorageToken && !storeToken) {
      console.log('[useAuth] Updating auth store with token from localStorage');
      authStore.setToken(localStorageToken);
      setToken(localStorageToken);
    } 
    // If we have a token in the store but not in localStorage, update localStorage
    else if (storeToken && !localStorageToken) {
      console.log('[useAuth] Updating localStorage with token from auth store');
      localStorage.setItem('auth_token', storeToken);
      setToken(storeToken);
    }
    // If we have tokens in both places but they're different, prefer the store token
    else if (storeToken && localStorageToken && storeToken !== localStorageToken) {
      console.log('[useAuth] Tokens differ between localStorage and store, using store token');
      localStorage.setItem('auth_token', storeToken);
      setToken(storeToken);
    }
    // If we have the same token in both places, just set it
    else if (storeToken) {
      setToken(storeToken);
    }
  }, [authStore.token]);
  
  // Fetch user data if we have a token
  const { 
    data: user, 
    isLoading, 
    isError, 
    error, 
    refetch 
  } = useUserQuery(token);
  
  // Login function
  const login = async (email: string, password: string) => {
    try {
      console.log('[useAuth] Attempting login for:', email);
      
      // Remove '/api' prefix since axiosInstance already includes it
      const response = await axiosInstance.post('/auth/login', { 
        email, 
        password 
      });
      
      const data = response.data;
      
      if (!data.token) {
        throw new Error('No token received from server');
      }
      
      console.log('[useAuth] Login successful, token received');
      console.log('[useAuth] Token length:', data.token.length);
      
      // Save token to localStorage
      localStorage.setItem('auth_token', data.token);
      console.log('[useAuth] Token saved to localStorage');
      
      // Update state
      setToken(data.token);
      
      // Update auth store with both token and user data
      if (data.user) {
        console.log('[useAuth] User data received with login, setting auth state');
        authStore.setAuth(data.token, data.user);
      } else {
        console.log('[useAuth] No user data received with login, setting token only');
        authStore.setToken(data.token);
      }
      
      // Refetch user data if not provided in the login response
      if (!data.user) {
        console.log('[useAuth] Refetching user data');
        await refetch();
      }
      
      toast.success('Login successful');
      return true;
    } catch (error) {
      console.error('[useAuth] Login error:', error);
      toast.error('Login failed', { 
        description: error instanceof Error ? error.message : 'Unknown error' 
      });
      return false;
    }
  };
  
  // Logout function
  const logout = async () => {
    console.log('[useAuth] Logging out');
    
    try {
      // Call the logout endpoint if user is authenticated
      if (token) {
        await axiosInstance.post('/auth/logout');
        console.log('[useAuth] Logout API call successful');
      }
    } catch (error) {
      console.error('[useAuth] Error calling logout endpoint:', error);
      // Continue with local logout even if API call fails
    } finally {
      // Clear token from localStorage
      localStorage.removeItem('auth_token');
      console.log('[useAuth] Token removed from localStorage');
      
      // Clear team store data
      const teamStore = useTeamStore.getState();
      teamStore.clearCurrentTeam();
      console.log('[useAuth] Team data cleared');
      
      // Clear state and auth store
      setToken(null);
      authStore.logout();
      
      toast.info('Logged out successfully');
    }
  };
  
  return {
    user,
    token,
    isLoading,
    isError,
    error,
    login,
    logout,
    isAuthenticated: !!user && !!token,
    refetchUser: refetch
  };
} 