import { Button } from '@/components/ui/button';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { ScrollArea } from '@/components/ui/scroll-area';
import { api, type MessageTemplate } from '@/lib/api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ChevronDown, Edit2, Plus, Trash } from 'lucide-react';
import { useState } from 'react';
import { toast } from 'sonner';
import { TemplateEditor } from './TemplateEditor';

interface MessageTemplatesProps {
  onSelect: (template: string) => void;
}

// Default templates that can't be deleted
const DEFAULT_TEMPLATES = [
  {
    label: 'Introduction',
    text: 'Hi! This is your insurance agent following up on your recent inquiry. Is now a good time to chat?'
  },
  {
    label: 'Follow Up',
    text: 'Just following up on our previous conversation. Would you like to schedule a time to discuss your insurance needs?'
  },
  {
    label: 'Quote Ready',
    text: 'I have your insurance quote ready. When would be a good time to review it together?'
  },
  {
    label: 'Thank You',
    text: 'Thank you for your time today! Please don\'t hesitate to reach out if you have any questions.'
  },
  {
    label: 'Appointment Confirmation',
    text: 'Looking forward to our appointment! I\'ll call you at the scheduled time. Please let me know if anything changes.'
  }
];

export function MessageTemplates({ onSelect }: MessageTemplatesProps) {
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<MessageTemplate>();
  const queryClient = useQueryClient();

  // Fetch user templates
  const { data: userTemplates = [] } = useQuery<MessageTemplate[]>({
    queryKey: ['templates'],
    queryFn: () => api.templates.list()
  });

  // Delete template mutation
  const { mutate: deleteTemplate } = useMutation({
    mutationFn: (id: number) => api.templates.delete(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['templates'] });
      toast.success('Template deleted');
    },
    onError: (error) => {
      toast.error(error instanceof Error ? error.message : 'Failed to delete template');
    },
  });

  const handleEdit = (e: React.MouseEvent, template: MessageTemplate) => {
    e.stopPropagation(); // Prevent template insertion when clicking edit
    setSelectedTemplate(template);
    setIsEditorOpen(true);
  };

  const handleDelete = (e: React.MouseEvent, template: MessageTemplate) => {
    e.stopPropagation(); // Prevent template insertion when clicking delete
    if (template.isDefault) {
      toast.error('Cannot delete default templates');
      return;
    }
    if (confirm('Are you sure you want to delete this template?')) {
      deleteTemplate(template.id);
    }
  };

  const handleNewTemplate = () => {
    setSelectedTemplate(undefined);
    setIsEditorOpen(true);
  };

  // Sort user templates by creation date, newest first
  const sortedUserTemplates = [...userTemplates].sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  );

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button 
            variant="ghost" 
            size="sm"
            className="text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-300 hover:bg-gray-100/50 dark:hover:bg-gray-700/50"
          >
            Templates
            <ChevronDown className="ml-1 h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent 
          align="start"
          className="w-[min(calc(100vw-2rem),320px)] bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-700"
          sideOffset={4}
        >
          <ScrollArea className="h-[var(--radix-dropdown-menu-content-available-height)] max-h-[min(400px,70vh)] overflow-y-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 dark:[&::-webkit-scrollbar-track]:bg-gray-800 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-thumb]:bg-gray-600">
            {/* User Templates */}
            {sortedUserTemplates.length > 0 && (
              <>
                {sortedUserTemplates.map((template) => (
                  <DropdownMenuItem
                    key={template.id}
                    onClick={() => onSelect(template.text)}
                    className="flex flex-col items-start gap-1.5 py-3.5 px-4 cursor-pointer data-[highlighted]:bg-gray-100 dark:data-[highlighted]:bg-gray-700/50 data-[highlighted]:text-gray-900 dark:data-[highlighted]:text-gray-100"
                  >
                    <div className="flex items-center justify-between w-full">
                      <span className="font-medium text-gray-900 dark:text-gray-200">{template.label}</span>
                      <div className="flex items-center gap-2">
                        <Button
                          variant="ghost"
                          size="icon"
                          className="h-8 w-8 text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700/50"
                          onClick={(e) => handleEdit(e, template)}
                        >
                          <Edit2 className="h-4 w-4" />
                        </Button>
                        <Button
                          variant="ghost"
                          size="icon"
                          className="h-8 w-8 text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700/50"
                          onClick={(e) => handleDelete(e, template)}
                        >
                          <Trash className="h-4 w-4" />
                        </Button>
                      </div>
                    </div>
                    <span className="text-sm text-gray-500 dark:text-gray-400 line-clamp-2">
                      {template.text}
                    </span>
                  </DropdownMenuItem>
                ))}
                <DropdownMenuSeparator className="bg-gray-200 dark:bg-gray-700" />
              </>
            )}

            {/* Default Templates */}
            {DEFAULT_TEMPLATES.map((template) => (
              <DropdownMenuItem
                key={template.label}
                onClick={() => onSelect(template.text)}
                className="flex flex-col items-start gap-1.5 py-3.5 px-4 cursor-pointer data-[highlighted]:bg-gray-100 dark:data-[highlighted]:bg-gray-700/50 data-[highlighted]:text-gray-900 dark:data-[highlighted]:text-gray-100"
              >
                <div className="flex items-center justify-between w-full">
                  <span className="font-medium text-gray-900 dark:text-gray-200">{template.label}</span>
                </div>
                <span className="text-sm text-gray-500 dark:text-gray-400 line-clamp-2">
                  {template.text}
                </span>
              </DropdownMenuItem>
            ))}
          </ScrollArea>

          <DropdownMenuSeparator className="bg-gray-200 dark:bg-gray-700" />
          <DropdownMenuItem
            className="flex items-center gap-2 py-3 px-4 cursor-pointer data-[highlighted]:bg-gray-100 dark:data-[highlighted]:bg-gray-700/50 data-[highlighted]:text-gray-900 dark:data-[highlighted]:text-gray-100"
            onClick={handleNewTemplate}
          >
            <Plus className="h-4 w-4 text-gray-500 dark:text-gray-400" />
            <span className="text-gray-900 dark:text-gray-200">New Template</span>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <TemplateEditor
        template={selectedTemplate}
        open={isEditorOpen}
        onOpenChange={setIsEditorOpen}
      />
    </>
  );
} 