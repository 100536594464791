import { motion } from 'framer-motion';
import { X } from 'lucide-react';

interface OnboardingProgressProps {
  currentStep: number;
  totalSteps: number;
  onSkip: () => void;
}

export function OnboardingProgress({ 
  currentStep, 
  totalSteps, 
  onSkip 
}: OnboardingProgressProps) {
  const progress = (currentStep / totalSteps) * 100;
  
  return (
    <motion.div 
      className="fixed top-4 left-1/2 transform -translate-x-1/2 z-50 bg-white rounded-full shadow-lg px-4 py-2 flex items-center gap-3"
      initial={{ y: -50, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ delay: 0.2 }}
    >
      <div className="text-xs font-medium text-gray-500">
        Step {currentStep} of {totalSteps}
      </div>
      
      <div className="w-32 h-2 bg-gray-200 rounded-full overflow-hidden">
        <motion.div 
          className="h-full bg-primary rounded-full"
          initial={{ width: 0 }}
          animate={{ width: `${progress}%` }}
          transition={{ duration: 0.3 }}
        />
      </div>
      
      <button
        onClick={onSkip}
        className="text-gray-400 hover:text-gray-600 transition-colors"
        aria-label="Skip onboarding"
      >
        <X size={16} />
      </button>
    </motion.div>
  );
} 