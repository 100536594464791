import { Button } from "@/components/ui/button";
import { Logo } from "@/components/ui/logo";
import { Avatar } from "@radix-ui/react-avatar";
import { Link, useNavigate } from "@tanstack/react-router";
import {
  ArrowRight,
  BarChart3,
  Brain,
  CheckCircle,
  Clock,
  Headphones,
  LayoutDashboard,
  LogIn,
  MessageSquare,
  Phone,
  PlayCircle,
  Shield,
  Sparkles,
  Star,
  Users,
  X
} from "lucide-react";
import { useEffect, useState } from "react";
import { AIWorkflowAutomation } from "./landing-page/AIWorkflowAutomation";
import { TeamPerformanceDashboard } from "./landing-page/TeamPerformanceDashboard";

export function LandingPage() {
  const navigate = useNavigate();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  // Add shimmer effect styles
  useEffect(() => {
    const styleEl = document.createElement('style');
    styleEl.textContent = `
      .shimmer-container {
        position: relative;
        display: inline-block;
        overflow: hidden;
      }
      
      .shimmer-container::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.25) 50%,
          rgba(255, 255, 255, 0) 100%
        );
        transform: translateX(-100%);
        animation: shimmer 3s infinite;
        pointer-events: none;
        z-index: 1;
      }
      
      @keyframes shimmer {
        100% {
          transform: translateX(100%);
        }
      }
    `;
    document.head.appendChild(styleEl);
    
    return () => {
      document.head.removeChild(styleEl);
    };
  }, []);

  // Handle scroll for sticky header
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };
    
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const navigateToAuth = (tab: string) => {
    navigate({ 
      to: "/auth", 
      search: { 
        tab, 
        invitation: undefined, 
        redirect: undefined 
      } 
    });
  };

  // Close mobile menu when clicking a link
  const handleMobileNavClick = (href?: string) => {
    setIsMobileMenuOpen(false);
    if (href) {
      const element = document.querySelector(href);
      element?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="min-h-screen flex flex-col">
      {/* Header */}
      <header 
        className={`sticky top-0 z-50 w-full transition-all duration-200 ${
          isScrolled ? "bg-white shadow-md py-3" : "bg-transparent py-5"
        }`}
      >
        <div className="container mx-auto px-4 flex justify-between items-center">
          <div className="flex items-center gap-2">
            <Avatar className="h-8 w-8 rounded-lg">
              <Brain className="h-8 w-8 text-sky-200 bg-black p-1 rounded-lg" />
            </Avatar>
            <Logo className="w-28" />
          </div>
          
          <nav className="hidden md:flex items-center gap-8">
            <a href="#features" className="text-gray-600 hover:text-primary transition-colors">AI Features</a>
            <a href="#how-it-works" className="text-gray-600 hover:text-primary transition-colors">How It Works</a>
            <a href="#team-features" className="text-gray-600 hover:text-primary transition-colors">Team Features</a>
            <a href="#pricing" className="text-gray-600 hover:text-primary transition-colors">Pricing</a>
          </nav>
          
          <div className="flex items-center gap-4">
            <Link
              to="/auth"
              search={{ tab: "login", invitation: undefined, redirect: undefined }}
              className="hidden sm:inline-block text-gray-600 hover:text-primary transition-colors"
            >
              Log in
            </Link>
            <Button 
              onClick={() => navigateToAuth("register")}
              className="hidden sm:flex"
            >
              Start Free Trial
            </Button>
            <Button 
              variant="ghost" 
              size="icon" 
              className="md:hidden"
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            >
              {isMobileMenuOpen ? (
                <X className="h-6 w-6" />
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="4" x2="20" y1="12" y2="12"/><line x1="4" x2="20" y1="6" y2="6"/><line x1="4" x2="20" y1="18" y2="18"/></svg>
              )}
            </Button>
          </div>
        </div>

        {/* Mobile Menu */}
        <div 
          className={`md:hidden fixed inset-0 bg-black/50 backdrop-blur-sm transition-opacity duration-300 ${
            isMobileMenuOpen ? "opacity-100" : "opacity-0 pointer-events-none"
          }`}
          onClick={() => setIsMobileMenuOpen(false)}
        >
          <div 
            className={`absolute right-0 top-0 bottom-0 w-[300px] bg-white shadow-2xl transition-transform duration-300 ease-out ${
              isMobileMenuOpen ? "translate-x-0" : "translate-x-full"
            }`}
            onClick={e => e.stopPropagation()}
          >
            <div className="flex flex-col h-full">
              <div className="p-4 border-b border-gray-100">
                <div className="flex justify-between items-center">
                  <div className="flex items-center gap-2">
                    <Avatar className="h-8 w-8 rounded-lg">
                      <Brain className="h-8 w-8 text-sky-200 bg-black p-1 rounded-lg" />
                    </Avatar>
                    <Logo className="w-28" />
                  </div>
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => setIsMobileMenuOpen(false)}
                  >
                    <X className="h-6 w-6" />
                  </Button>
                </div>
              </div>

              <nav className="flex-1 overflow-y-auto">
                <div className="px-3 py-4">
                  <div className="mb-2 px-3 text-xs font-semibold text-gray-400 uppercase tracking-wider">
                    Navigation
                  </div>
                  <div className="space-y-1">
                    <a 
                      href="#features" 
                      className="flex items-center gap-3 px-3 py-2 text-gray-700 hover:text-primary hover:bg-primary/5 rounded-lg transition-colors"
                      onClick={() => handleMobileNavClick('#features')}
                    >
                      <Sparkles className="h-5 w-5" />
                      <span>AI Features</span>
                    </a>
                    <a 
                      href="#how-it-works" 
                      className="flex items-center gap-3 px-3 py-2 text-gray-700 hover:text-primary hover:bg-primary/5 rounded-lg transition-colors"
                      onClick={() => handleMobileNavClick('#how-it-works')}
                    >
                      <PlayCircle className="h-5 w-5" />
                      <span>How It Works</span>
                    </a>
                    <a 
                      href="#team-features" 
                      className="flex items-center gap-3 px-3 py-2 text-gray-700 hover:text-primary hover:bg-primary/5 rounded-lg transition-colors"
                      onClick={() => handleMobileNavClick('#team-features')}
                    >
                      <LayoutDashboard className="h-5 w-5" />
                      <span>Team Features</span>
                    </a>
                    <a 
                      href="#pricing" 
                      className="flex items-center gap-3 px-3 py-2 text-gray-700 hover:text-primary hover:bg-primary/5 rounded-lg transition-colors"
                      onClick={() => handleMobileNavClick('#pricing')}
                    >
                      <Star className="h-5 w-5" />
                      <span>Pricing</span>
                    </a>
                  </div>
                </div>
              </nav>

              <div className="p-4 border-t border-gray-100 bg-gray-50">
                <div className="grid gap-3">
                  <Link
                    to="/auth"
                    search={{ tab: "login", invitation: undefined, redirect: undefined }}
                    className="flex items-center justify-center gap-2 px-3 py-2 text-gray-700 hover:text-primary hover:bg-white rounded-lg transition-colors"
                    onClick={() => handleMobileNavClick()}
                  >
                    <LogIn className="h-5 w-5" />
                    <span>Log in</span>
                  </Link>
                  <Button 
                    onClick={() => {
                      handleMobileNavClick();
                      navigateToAuth("register");
                    }}
                    className="w-full gap-2"
                  >
                    <ArrowRight className="h-5 w-5" />
                    Start Free Trial
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <main className="flex-1">
        {/* Hero Section */}
        <section className="relative bg-gradient-to-br from-blue-50 to-indigo-50 pt-20 pb-32 overflow-x-hidden">
          <div className="container mx-auto px-4">
            <div className="flex flex-col md:flex-row items-center gap-12">
              <div className="flex-1 space-y-6">
                <div className="inline-block px-3 py-1 rounded-full bg-primary/10 text-primary text-sm font-medium mb-2">
                  AI-Powered Insurance Sales Platform
                </div>
                <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold leading-tight text-gray-900">
                  Close More <span className="text-primary shimmer-container -bottom-2">Health</span> <span className="text-primary shimmer-container -bottom-2">Insurance</span> Deals with AI
                </h1>
                <p className="text-lg sm:text-xl text-gray-600 max-w-2xl">
                  LeadGPT helps health insurance agents streamline their workflow, 
                  automate tedious tasks, and close more deals with powerful AI insights.
                </p>
                <div className="flex flex-col sm:flex-row gap-4 pt-4">
                  <Button 
                    size="lg" 
                    onClick={() => navigateToAuth("register")}
                    className="text-base"
                  >
                    Start Your Free Trial
                    <ArrowRight className="ml-2 h-5 w-5" />
                  </Button>
                </div>
                <div className="hidden items-center gap-4 pt-4">
                  <div className="flex -space-x-2">
                    {[1, 2, 3, 4].map((i) => (
                      <div key={i} className="h-8 w-8 rounded-full bg-gray-300 border-2 border-white" />
                    ))}
                  </div>
                  <p className="text-sm text-gray-600">
                    <span className="font-bold">1k+</span> sales agents are already using LeadGPT
                  </p>
                </div>
              </div>
              <div className="flex-1 relative">
                <div className="relative z-10 bg-white rounded-xl shadow-xl overflow-hidden border border-gray-100">
                  <img 
                    src="https://leadgpt.s3.us-east-1.amazonaws.com/landing/leadpage.webp" 
                    alt="LeadGPT Dashboard" 
                    width={726}
                    height={453.75}
                    fetchPriority="high"
                    onError={(e) => {
                      e.currentTarget.src = "https://leadgpt.s3.us-east-1.amazonaws.com/landing/leadpage.webp"
                    }}
                  />
                </div>
                <div className="absolute -bottom-6 -right-6 w-64 h-64 bg-primary/10 rounded-full blur-3xl"></div>
                <div className="absolute -top-6 -left-6 w-64 h-64 bg-blue-500/10 rounded-full blur-3xl"></div>
              </div>
            </div>
          </div>
          
          {/* Brands */}
          {/* <div className="container mx-auto px-4 mt-24">
            <p className="text-center text-gray-500 mb-8">Trusted by leading insurance agencies</p>
            <div className="flex flex-wrap justify-center items-center gap-8 md:gap-16 opacity-70">
              {["Anthem", "UnitedHealth", "Humana", "Cigna", "Aetna"].map((brand) => (
                <div key={brand} className="text-xl font-bold text-gray-400">{brand}</div>
              ))}
            </div>
          </div> */}
        </section>

        {/* AI Features Section */}
        <section id="features" className="py-20 bg-white">
          <div className="container mx-auto px-4">
            <div className="text-center max-w-3xl mx-auto mb-16">
              <div className="inline-block px-3 py-1 rounded-full bg-primary/10 text-primary text-sm font-medium mb-4">
                AI-Powered Features
              </div>
              <h2 className="text-3xl sm:text-4xl font-bold mb-4">AI That Works For Health Insurance Agents</h2>
              <p className="text-lg text-gray-600">
                Our AI analyzes calls and messages to automate tasks, provide insights, and help you close more deals.
              </p>
            </div>
            
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
              {[
                {
                  icon: <MessageSquare className="h-6 w-6" />,
                  title: "AI Call & Text Analysis",
                  description: "Automatically extract key points, sentiment, and action items from every call and text conversation."
                },
                {
                  icon: <Brain className="h-6 w-6" />,
                  title: "Smart Lead Scoring",
                  description: "AI analyzes conversations to score leads based on interest level, objections, and buying signals."
                },
                {
                  icon: <Clock className="h-6 w-6" />,
                  title: "Automated Task Generation",
                  description: "AI creates and prioritizes follow-up tasks based on conversation analysis and lead potential."
                },
                {
                  icon: <Headphones className="h-6 w-6" />,
                  title: "AI Receptionist",
                  description: "Never miss a lead with our AI receptionist that handles missed calls and schedules callbacks."
                },
                {
                  icon: <Phone className="h-6 w-6" />,
                  title: "Local Number Matching",
                  description: "Purchase phone numbers with local area codes to increase answer rates and build trust with leads."
                },
                {
                  icon: <Shield className="h-6 w-6" />,
                  title: "Compliance Assistant",
                  description: "AI helps ensure all communications comply with insurance regulations and best practices."
                }
              ].map((feature, index) => (
                <div key={index} className="bg-gray-50 rounded-xl p-6 hover:shadow-md transition-shadow border border-gray-100">
                  <div className="w-12 h-12 bg-primary/10 rounded-lg flex items-center justify-center text-primary mb-4">
                    {feature.icon}
                  </div>
                  <h3 className="text-xl font-bold mb-2">{feature.title}</h3>
                  <p className="text-gray-600">{feature.description}</p>
                </div>
              ))}
            </div>

            {/* AI Showcase */}
            <div className="mt-24 grid md:grid-cols-2 gap-12 items-center">
              <div>
                <h3 className="text-2xl sm:text-3xl font-bold mb-4">Conversation Intelligence</h3>
                <p className="text-gray-600 mb-6">
                  Our AI listens to your calls and analyzes your text messages to provide actionable insights that help you close more deals.
                </p>
                <ul className="space-y-4">
                  {[
                    "Automatically identifies key discussion points",
                    "Extracts objections and buying signals",
                    "Suggests personalized follow-up strategies",
                    "Tracks compliance with insurance regulations",
                    "Provides sentiment analysis for each interaction"
                  ].map((item, i) => (
                    <li key={i} className="flex items-start gap-3">
                      <div className="mt-1 h-5 w-5 rounded-full bg-green-100 flex items-center justify-center flex-shrink-0">
                        <CheckCircle className="h-3 w-3 text-green-600" />
                      </div>
                      <span>{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="bg-white rounded-xl shadow-xl overflow-hidden border border-gray-200">
                <div className="bg-gray-50 px-4 py-2 border-b border-gray-200">
                  <div className="flex items-center gap-2">
                    <div className="h-3 w-3 rounded-full bg-red-500"></div>
                    <div className="h-3 w-3 rounded-full bg-yellow-500"></div>
                    <div className="h-3 w-3 rounded-full bg-green-500"></div>
                    <span className="text-sm text-gray-500 ml-2">Call Analysis</span>
                  </div>
                </div>
                <div className="p-6">
                  <div className="mb-4">
                    <div className="text-sm font-medium text-gray-500 mb-1">Call Summary</div>
                    <p className="text-gray-800">
                      30-minute call with John about family health insurance options. Discussed premium costs, coverage options, and enrollment deadlines.
                    </p>
                  </div>
                  <div className="mb-4">
                    <div className="text-sm font-medium text-gray-500 mb-1">Key Points</div>
                    <ul className="space-y-2">
                      <li className="flex items-start gap-2">
                        <div className="h-5 w-5 text-primary mt-0.5"><CheckCircle className="h-5 w-5" /></div>
                        <span>Family of 4 seeking comprehensive coverage</span>
                      </li>
                      <li className="flex items-start gap-2">
                        <div className="h-5 w-5 text-primary mt-0.5"><CheckCircle className="h-5 w-5" /></div>
                        <span>Budget concern: max $800/month premium</span>
                      </li>
                      <li className="flex items-start gap-2">
                        <div className="h-5 w-5 text-primary mt-0.5"><CheckCircle className="h-5 w-5" /></div>
                        <span>Needs coverage for son's asthma medication</span>
                      </li>
                    </ul>
                  </div>
                  <div className="mb-4">
                    <div className="text-sm font-medium text-gray-500 mb-1">Sentiment</div>
                    <div className="h-2 w-full bg-gray-200 rounded-full overflow-hidden">
                      <div className="h-full bg-green-500 rounded-full" style={{ width: '75%' }}></div>
                    </div>
                    <div className="flex justify-between text-xs mt-1">
                      <span>Negative</span>
                      <span className="font-medium text-green-600">Positive (75%)</span>
                    </div>
                  </div>
                  <div>
                    <div className="text-sm font-medium text-gray-500 mb-1">AI Suggested Actions</div>
                    <div className="space-y-2">
                      <div className="flex items-center gap-2 p-2 bg-blue-50 rounded-md text-sm">
                        <Clock className="h-4 w-4 text-blue-600" />
                        <span>Send Gold Plan comparison by tomorrow</span>
                      </div>
                      <div className="flex items-center gap-2 p-2 bg-purple-50 rounded-md text-sm">
                        <MessageSquare className="h-4 w-4 text-purple-600" />
                        <span>Follow up about prescription coverage details</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* How It Works */}
        <section id="how-it-works" className="py-20 bg-gray-50">
          <div className="container mx-auto px-4">
            <div className="text-center max-w-3xl mx-auto mb-16">
              <div className="inline-block px-3 py-1 rounded-full bg-primary/10 text-primary text-sm font-medium mb-4">
                Simplified Workflow
              </div>
              <h2 className="text-3xl sm:text-4xl font-bold mb-4">How LeadGPT Works for Agents</h2>
              <p className="text-lg text-gray-600">
                Our AI-powered platform streamlines your entire sales process, from lead capture to closing.
              </p>
            </div>
            
            <div className="relative">
              {/* Progress Line */}
              <div className="hidden lg:block absolute top-1/2 left-0 right-0 h-1 bg-gray-200 -translate-y-1/2 z-0"></div>
              
              <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8 relative z-10">
                {[
                  {
                    step: "1",
                    title: "Connect & Capture",
                    description: "Import your leads or capture new ones. Get local phone numbers to increase answer rates.",
                    color: "bg-blue-500"
                  },
                  {
                    step: "2",
                    title: "Communicate",
                    description: "Make calls and send texts from one platform. AI receptionist handles missed calls.",
                    color: "bg-purple-500"
                  },
                  {
                    step: "3",
                    title: "AI Analysis",
                    description: "Our AI analyzes every interaction, extracts key points, and generates follow-up tasks.",
                    color: "bg-pink-500"
                  },
                  {
                    step: "4",
                    title: "Close Deals",
                    description: "Use AI insights to personalize your approach and close more health insurance policies.",
                    color: "bg-green-500"
                  }
                ].map((step, index) => (
                  <div key={index} className="relative">
                    <div className="bg-white rounded-xl p-8 shadow-sm h-full border border-gray-100">
                      <div className={`w-12 h-12 rounded-full ${step.color} text-white flex items-center justify-center font-bold mb-6 mx-auto lg:mx-0`}>
                        {step.step}
                      </div>
                      <h3 className="text-xl font-bold mb-3 text-center lg:text-left">{step.title}</h3>
                      <p className="text-gray-600 text-center lg:text-left">{step.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Workflow Showcase */}
            <div className="mt-24 bg-white rounded-xl shadow-lg overflow-hidden border border-gray-100">
              <div className="grid md:grid-cols-5">
                <div className="md:col-span-2 bg-primary/5 p-8 flex items-center">
                  <div>
                    <h3 className="text-2xl font-bold mb-4">Focus on Selling, Not Admin Work</h3>
                    <p className="text-gray-600 mb-6">
                      LeadGPT automates the tedious parts of insurance sales, so you can focus on what you do best: connecting with clients and closing deals.
                    </p>
                    <ul className="space-y-3">
                      {[
                        "Save 15+ hours per week on administrative tasks",
                        "Increase contact rates by 35% with local numbers",
                        "Never miss important follow-ups with AI reminders",
                        "Close 28% more policies with data-driven insights"
                      ].map((item, i) => (
                        <li key={i} className="flex items-start gap-3">
                          <div className="mt-1 h-5 w-5 rounded-full bg-green-100 flex items-center justify-center flex-shrink-0">
                            <CheckCircle className="h-3 w-3 text-green-600" />
                          </div>
                          <span className="text-sm">{item}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="md:col-span-3 p-0">
                  <div className="h-full w-full bg-gray-100 flex items-center justify-center overflow-hidden">
                    <AIWorkflowAutomation />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Team Features */}
        <section id="team-features" className="py-20 bg-white">
          <div className="container mx-auto px-4">
            <div className="text-center max-w-3xl mx-auto mb-16">
              <div className="inline-block px-3 py-1 rounded-full bg-primary/10 text-primary text-sm font-medium mb-4">
                Team Management
              </div>
              <h2 className="text-3xl sm:text-4xl font-bold mb-4">Empower Your Insurance Team with AI</h2>
              <p className="text-lg text-gray-600">
                LeadGPT helps team managers monitor performance, identify coaching opportunities, and drive better results.
              </p>
            </div>

            {/* Team Dashboard Preview */}
            <div className="bg-white rounded-xl shadow-lg overflow-hidden border border-gray-100 mb-16">
              <div className="p-4 bg-gray-50 border-b border-gray-200">
                <div className="flex items-center gap-2">
                  <div className="h-3 w-3 rounded-full bg-red-500"></div>
                  <div className="h-3 w-3 rounded-full bg-yellow-500"></div>
                  <div className="h-3 w-3 rounded-full bg-green-500"></div>
                  <span className="text-sm text-gray-500 ml-2">Team Performance Dashboard</span>
                </div>
              </div>
              <div className="p-6">
                <TeamPerformanceDashboard />
              </div>
            </div>
            
            <div className="grid md:grid-cols-2 gap-16 items-center">
              <div>
                <h3 className="text-2xl sm:text-3xl font-bold mb-6">Team Analytics & Coaching</h3>
                <div className="space-y-6">
                  <div className="flex gap-4">
                    <div className="w-12 h-12 bg-blue-100 rounded-lg flex items-center justify-center text-blue-600 flex-shrink-0">
                      <BarChart3 className="h-6 w-6" />
                    </div>
                    <div>
                      <h4 className="text-lg font-bold mb-1">Performance Metrics</h4>
                      <p className="text-gray-600">
                        Track key metrics like conversion rates, response times, and policy values across your entire team.
                      </p>
                    </div>
                  </div>
                  
                  <div className="flex gap-4">
                    <div className="w-12 h-12 bg-purple-100 rounded-lg flex items-center justify-center text-purple-600 flex-shrink-0">
                      <Brain className="h-6 w-6" />
                    </div>
                    <div>
                      <h4 className="text-lg font-bold mb-1">AI Coaching Insights</h4>
                      <p className="text-gray-600">
                        AI analyzes agent conversations to identify coaching opportunities and best practices to share with the team.
                      </p>
                    </div>
                  </div>
                  
                  <div className="flex gap-4">
                    <div className="w-12 h-12 bg-green-100 rounded-lg flex items-center justify-center text-green-600 flex-shrink-0">
                      <Users className="h-6 w-6" />
                    </div>
                    <div>
                      <h4 className="text-lg font-bold mb-1">Team Collaboration</h4>
                      <p className="text-gray-600">
                        Share successful scripts, objection handling techniques, and best practices across your insurance agency.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="space-y-6">
                <div className="bg-gray-50 rounded-xl p-6 border border-gray-100">
                  <h4 className="text-lg font-bold mb-4">Agent Performance Comparison</h4>
                  <div className="space-y-4">
                    {[
                      { name: "Sarah Johnson", policies: 24, rate: 32, color: "bg-blue-500" },
                      { name: "Michael Chen", policies: 18, rate: 28, color: "bg-purple-500" },
                      { name: "Emily Rodriguez", policies: 21, rate: 30, color: "bg-pink-500" }
                    ].map((agent, index) => (
                      <div key={index} className="space-y-2">
                        <div className="flex justify-between items-center">
                          <span className="font-medium">{agent.name}</span>
                          <span className="text-sm text-gray-500">{agent.policies} policies</span>
                        </div>
                        <div className="h-2 w-full bg-gray-200 rounded-full overflow-hidden">
                          <div className={`h-full ${agent.color} rounded-full`} style={{ width: `${agent.rate}%` }}></div>
                        </div>
                        <div className="flex justify-between text-xs">
                          <span>Conversion Rate</span>
                          <span className="font-medium">{agent.rate}%</span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                
                <div className="bg-gray-50 rounded-xl p-6 border border-gray-100">
                  <h4 className="text-lg font-bold mb-4">AI-Generated Team Insights</h4>
                  <ul className="space-y-3">
                    {[
                      "Top performers spend 12% more time discussing coverage details",
                      "Successful calls include personal stories about insurance benefits",
                      "Response time under 2 hours increases conversion by 35%",
                      "Agents using local area code numbers have 28% higher answer rates"
                    ].map((insight, i) => (
                      <li key={i} className="flex items-start gap-3">
                        <div className="mt-1 h-5 w-5 rounded-full bg-blue-100 flex items-center justify-center flex-shrink-0">
                          <CheckCircle className="h-3 w-3 text-blue-600" />
                        </div>
                        <span className="text-sm">{insight}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Features Section */}
        <section id="features" className="py-20 bg-white">
          <div className="container mx-auto px-4">
            <div className="text-center max-w-3xl mx-auto mb-16">
              <h2 className="text-3xl sm:text-4xl font-bold mb-4">Everything You Need to Manage Leads Effectively</h2>
              <p className="text-lg text-gray-600">
                Our comprehensive suite of tools helps you capture, nurture, and convert leads with ease.
              </p>
            </div>
            
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
              {[
                {
                  icon: <Users className="h-6 w-6" />,
                  title: "Lead Management",
                  description: "Organize and prioritize your leads with customizable pipelines and automated lead scoring."
                },
                {
                  icon: <MessageSquare className="h-6 w-6" />,
                  title: "Unified Communication",
                  description: "Manage all your emails, SMS, and calls in one place with templates and scheduling."
                },
                {
                  icon: <BarChart3 className="h-6 w-6" />,
                  title: "Advanced Analytics",
                  description: "Gain insights into your sales performance with detailed reports and dashboards."
                },
                {
                  icon: <Clock className="h-6 w-6" />,
                  title: "Automation",
                  description: "Save time with automated follow-ups, task assignments, and lead nurturing sequences."
                },
                {
                  icon: <Phone className="h-6 w-6" />,
                  title: "Call Tracking",
                  description: "Record and analyze calls to improve your team's communication and close more deals."
                },
                {
                  icon: <Shield className="h-6 w-6" />,
                  title: "Secure & Compliant",
                  description: "Keep your data safe with enterprise-grade security and compliance features."
                }
              ].map((feature, index) => (
                <div key={index} className="bg-gray-50 rounded-xl p-6 hover:shadow-md transition-shadow">
                  <div className="w-12 h-12 bg-primary/10 rounded-lg flex items-center justify-center text-primary mb-4">
                    {feature.icon}
                  </div>
                  <h3 className="text-xl font-bold mb-2">{feature.title}</h3>
                  <p className="text-gray-600">{feature.description}</p>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Testimonials */}
        <section id="testimonials" className="py-20 bg-white">
          <div className="container mx-auto px-4">
            <div className="text-center max-w-3xl mx-auto mb-16">
              <h2 className="text-3xl sm:text-4xl font-bold mb-4">What Our Customers Say</h2>
              <p className="text-lg text-gray-600">
                Don't just take our word for it. See what our customers have achieved with LeadGPT.
              </p>
            </div>
            
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
              {[
                {
                  quote: "LeadGPT has transformed our sales process. We've increased our conversion rate by 35% in just three months.",
                  author: "Sarah Johnson",
                  role: "Sales Director",
                  company: "TechCorp Inc."
                },
                {
                  quote: "The unified communication hub saves us hours every day. No more switching between different tools to manage our leads.",
                  author: "Michael Chen",
                  role: "Business Development",
                  company: "Growth Partners"
                },
                {
                  quote: "The analytics and reporting features have given us insights we never had before. Now we know exactly what's working and what's not.",
                  author: "Emily Rodriguez",
                  role: "Marketing Manager",
                  company: "Innovate Solutions"
                }
              ].map((testimonial, index) => (
                <div key={index} className="bg-gray-50 rounded-xl p-6 border border-gray-100">
                  <div className="flex mb-4">
                    {[1, 2, 3, 4, 5].map((star) => (
                      <Star key={star} className="h-5 w-5 text-yellow-400 fill-yellow-400" />
                    ))}
                  </div>
                  <p className="text-gray-700 mb-6 italic">"{testimonial.quote}"</p>
                  {/* <div className="flex items-center gap-3">
                    <div className="w-10 h-10 rounded-full bg-gray-300"></div>
                    <div>
                      <p className="font-bold">{testimonial.author}</p>
                      <p className="text-sm text-gray-600">{testimonial.role}, {testimonial.company}</p>
                    </div>
                  </div> */}
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Pricing */}
        <section id="pricing" className="py-20 bg-gray-50">
          <div className="container mx-auto px-4">
            <div className="text-center max-w-3xl mx-auto mb-16">
              <div className="inline-block px-3 py-1 rounded-full bg-primary/10 text-primary text-sm font-medium mb-4">
                Volume-Based Pricing
              </div>
              <h2 className="text-3xl sm:text-4xl font-bold mb-4">Scale Your Lead Generation Efforts</h2>
              <p className="text-lg text-gray-600">
                All plans include full access to AI features with different capacity limits. Choose the plan that fits your volume needs.
              </p>
            </div>
            
            <div className="grid md:grid-cols-3 gap-8 max-w-5xl mx-auto">
              {[
                {
                  name: "Lead Scout",
                  price: "$149",
                  description: "Perfect for individual agents looking to boost their lead conversion.",
                  features: [
                    "Up to 200 total leads",
                    <>100 AI Receptionist minutes <span className="text-xs text-gray-500 italic">($0.50/min after)</span></>,
                    "Local phone numbers",
                    "Unlimited Calls & Texts",
                    "Standard analytics dashboard",
                    "Email & chat support",
                    "All AI features included"
                  ],
                  cta: "Start Free Trial",
                  popular: false
                },
                {
                  name: "Lead Hunter",
                  price: "$299",
                  description: "For growing agents who need more capacity and advanced analytics.",
                  features: [
                    "Up to 500 total leads",
                    <>250 AI Receptionist minutes <span className="text-xs text-gray-500 italic">($0.40/min after)</span></>,
                    "Local phone numbers",
                    "Unlimited Calls & Texts",
                    "Advanced analytics & reporting",
                    "Priority support",
                    "Team performance tracking",
                    "All AI features included"
                  ],
                  cta: "Start Free Trial",
                  popular: true
                },
                {
                  name: "Lead Master",
                  price: "$499",
                  description: "For power users who demand the highest capacity and best rates.",
                  features: [
                    "Unlimited leads",
                    <>600 AI Receptionist minutes <span className="text-xs text-gray-500 italic">($0.25/min after)</span></>,
                    "Local phone numbers",
                    "Unlimited Calls & Texts",
                    "Custom analytics & reporting",
                    "Dedicated account manager",
                    "White-labeled client portal",
                    "All AI features included"
                  ],
                  cta: "Start Free Trial",
                  popular: false
                }
              ].map((plan, index) => (
                <div 
                  key={index} 
                  className={`rounded-xl overflow-hidden ${
                    plan.popular 
                      ? "border-2 border-primary shadow-lg relative" 
                      : "border border-gray-200"
                  }`}
                >
                  {plan.popular && (
                    <div className="bg-primary text-white text-center py-1 text-sm font-medium">
                      Most Popular
                    </div>
                  )}
                  <div className="bg-white p-6">
                    <h3 className="text-xl font-bold mb-2">{plan.name}</h3>
                    <div className="flex items-baseline mb-4">
                      <span className="text-3xl font-bold">{plan.price}</span>
                      <span className="text-gray-600 ml-1">/month</span>
                    </div>
                    <p className="text-gray-600 mb-6">{plan.description}</p>
                    <Button 
                      className={`w-full ${plan.popular ? "" : "bg-gray-800 hover:bg-gray-700"}`}
                      onClick={() => navigateToAuth("register")}
                    >
                      {plan.cta}
                    </Button>
                  </div>
                  <div className="bg-gray-50 p-6">
                    <p className="font-medium mb-4">What's included:</p>
                    <ul className="space-y-3">
                      {plan.features.map((feature, i) => (
                        <li key={i} className="flex items-start gap-2">
                          <CheckCircle className="h-5 w-5 text-green-500 shrink-0 mt-0.5" />
                          <span>{feature}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>

            {/* Add-on Packages */}
            <div className="mt-16 max-w-3xl mx-auto">
              <h3 className="text-2xl font-bold mb-6 text-center">Add-On Packages</h3>
              <p className="text-center text-gray-600 mb-8">
                Boost your capacity with these additional packages
              </p>
              
              <div className="grid md:grid-cols-2 gap-6">
                <div className="bg-white rounded-xl border border-gray-200 p-6">
                  <h4 className="text-lg font-bold mb-2">Minutes Booster Pack</h4>
                  <div className="flex items-baseline mb-4">
                    <span className="text-2xl font-bold">$49</span>
                    <span className="text-gray-600 ml-1">/month</span>
                  </div>
                  <p className="text-gray-600 mb-4">Add more AI Receptionist minutes to any plan at a discounted rate.</p>
                  <ul className="space-y-2 mb-6">
                    <li className="flex items-start gap-2">
                      <CheckCircle className="h-5 w-5 text-green-500 shrink-0 mt-0.5" />
                      <span>100 additional AI Receptionist minutes</span>
                    </li>
                    <li className="flex items-start gap-2">
                      <CheckCircle className="h-5 w-5 text-green-500 shrink-0 mt-0.5" />
                      <span>Rolls over unused minutes (up to 200)</span>
                    </li>
                    <li className="flex items-start gap-2">
                      <CheckCircle className="h-5 w-5 text-green-500 shrink-0 mt-0.5" />
                      <span>Priority routing for calls</span>
                    </li>
                  </ul>
                </div>
                
                <div className="bg-white rounded-xl border border-gray-200 p-6">
                  <h4 className="text-lg font-bold mb-2">Leads Expansion Pack</h4>
                  <div className="flex items-baseline mb-4">
                    <span className="text-2xl font-bold">$39</span>
                    <span className="text-gray-600 ml-1">/month</span>
                  </div>
                  <p className="text-gray-600 mb-4">Increase your total lead capacity without upgrading your plan.</p>
                  <ul className="space-y-2 mb-6">
                    <li className="flex items-start gap-2">
                      <CheckCircle className="h-5 w-5 text-green-500 shrink-0 mt-0.5" />
                      <span>100 additional leads capacity</span>
                    </li>
                    <li className="flex items-start gap-2">
                      <CheckCircle className="h-5 w-5 text-green-500 shrink-0 mt-0.5" />
                      <span>Lead scoring prioritization</span>
                    </li>
                    <li className="flex items-start gap-2">
                      <CheckCircle className="h-5 w-5 text-green-500 shrink-0 mt-0.5" />
                      <span>Enhanced lead analytics</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* FAQ */}
            <div className="mt-24 max-w-3xl mx-auto">
              <h3 className="text-2xl font-bold mb-8 text-center">Frequently Asked Questions</h3>
              <div className="divide-y divide-gray-200">
                {[
                  {
                    question: "How does the local phone number feature work?",
                    answer: "You can purchase phone numbers with area codes matching your leads' locations. This increases answer rates by up to 35% as leads are more likely to answer calls from local numbers."
                  },
                  {
                    question: "Is LeadGPT HIPAA compliant?",
                    answer: "Yes, our infrastructure is built on AWS with all features for HIPAA compliance, including secure data storage, audit trails, and BAA agreements."
                  },
                  {
                    question: "Can I import my existing health insurance leads?",
                    answer: "Yes, you can easily import your existing leads from CSV files, Excel spreadsheets, or directly from other CRM systems through our integration options."
                  },
                  {
                    question: "How does the AI analyze calls and texts?",
                    answer: "Our AI uses advanced natural language processing to analyze the content of calls and texts, identifying key points, sentiment, objections, and buying signals to provide actionable insights."
                  },
                  {
                    question: "Can I cancel my subscription anytime?",
                    answer: "Yes, you can cancel your subscription at any time. If you cancel, you'll still have access until the end of your current billing period."
                  }
                ].map((faq, index) => (
                  <div key={index} className="py-6">
                    <h4 className="text-lg font-bold mb-2">{faq.question}</h4>
                    <p className="text-gray-600">{faq.answer}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>

        {/* CTA */}
        <section className="py-20 bg-primary text-white">
          <div className="container mx-auto px-4 text-center">
            <h2 className="text-3xl sm:text-4xl font-bold mb-4">Ready to Transform Your Insurance Sales?</h2>
            <p className="text-xl opacity-90 mb-8 max-w-2xl mx-auto">
              Start your 14-day free trial today. No credit card required.
            </p>
            <Button 
              size="lg" 
              variant="secondary"
              onClick={() => navigateToAuth("register")}
              className="text-primary bg-white hover:bg-gray-100 text-base"
            >
              Get Started Now
              <ArrowRight className="ml-2 h-5 w-5" />
            </Button>
          </div>
        </section>

        {/* Footer */}
        <footer className="bg-gray-900 text-gray-300 py-12">
          <div className="container mx-auto px-4">
            <div className="grid grid-cols-2 md:grid-cols-5 gap-8 mb-8">

              <div className="col-span-2 md:col-span-1">
                <div className="flex items-center gap-2 mb-4 md:mb-0">
                  <Avatar className="h-8 w-8 rounded-lg">
                    <Brain className="h-8 w-8 text-sky-200 bg-black p-1 rounded-lg" />
                  </Avatar>
                  <Logo className="w-28" color="#ffffff" />
                </div>
                <p className="text-gray-400 mt-2">
                  LeadGPT helps health insurance agents streamline their workflow, automate tedious tasks, and close more deals with powerful AI insights.
                </p>
              </div>
              
              <div className="col-span-2 md:col-span-1">
              </div>

              <div>
                <h3 className="font-bold text-white mb-4">Product</h3>
                <ul className="space-y-2">
                  <li><a href="#features" className="hover:text-white transition-colors">AI Features</a></li>
                  <li><a href="#how-it-works" className="hover:text-white transition-colors">How It Works</a></li>
                  <li><a href="#team-features" className="hover:text-white transition-colors">Team Features</a></li>
                  <li><a href="#pricing" className="hover:text-white transition-colors">Pricing</a></li>
                </ul>
              </div>
              
              <div>
                <h3 className="font-bold text-white mb-4">Legal</h3>
                <ul className="space-y-2">
                  <li>
                    <Link
                      to="/privacy"
                      className="hover:text-white transition-colors"
                      resetScroll={true}
                    >
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/terms"
                      className="hover:text-white transition-colors"
                      resetScroll={true}
                    >
                      Terms of Service
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/hipaa"
                      className="hover:text-white transition-colors"
                      resetScroll={true}
                    >
                      HIPAA Compliance
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/security"
                      className="hover:text-white transition-colors"
                      resetScroll={true}
                    >
                      Security
                    </Link>
                  </li>
                </ul>
              </div>

              <div>
                <h3 className="font-bold text-white mb-4">Partners</h3>
                <ul className="space-y-2">
                  <li>
                    <a 
                      href="https://tmsleads.com" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="hover:text-white transition-colors"
                    >
                      TMS Leads
                    </a>
                  </li>
                  <li>
                    <Link
                      to="/blog"
                      className="hover:text-white transition-colors"
                      resetScroll={true}
                    >
                      Blog
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            
            <div className="border-t border-gray-800 pt-8 mt-8 flex flex-col md:flex-row justify-between items-center">
              
              <div className="text-sm">
                &copy; {new Date().getFullYear()} LeadGPT. All rights reserved.
              </div>
            </div>
          </div>
        </footer>
      </main>
    </div>
  );
} 