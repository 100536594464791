import { Card, CardContent } from '@/components/ui/card';
import { Lock, Mail, MessageSquare, Phone } from 'lucide-react';

interface CommunicationMetricsData {
  smsResponseRate: {
    percentage: number;
    avgResponseTime: string;
  };
  callAnswerRate: {
    percentage: number;
    avgDuration: string;
  };
  emailOpenRate: {
    percentage: number;
    avgResponseTime: string;
  };
}

interface CommunicationMetricsProps {
  metrics: CommunicationMetricsData;
}

export function CommunicationMetrics({ metrics }: CommunicationMetricsProps) {
  return (
    <Card className="rounded-none shadow-none border-none">
      <CardContent className="pt-4 px-4 pb-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          <div className="border rounded-md p-4">
            <div className="flex items-center justify-between mb-2">
              <div className="text-sm font-medium">SMS Response Rate</div>
              <MessageSquare className="h-4 w-4 text-blue-500" />
            </div>
            <div className="text-2xl font-bold">{metrics.smsResponseRate.percentage}%</div>
            <div className="text-xs text-gray-500 mt-1">
              Avg. response time: {metrics.smsResponseRate.avgResponseTime}
            </div>
          </div>
          <div className="border rounded-md p-4">
            <div className="flex items-center justify-between mb-2">
              <div className="text-sm font-medium">Call Answer Rate</div>
              <Phone className="h-4 w-4 text-green-500" />
            </div>
            <div className="text-2xl font-bold">{metrics.callAnswerRate.percentage}%</div>
            <div className="text-xs text-gray-500 mt-1">
              Avg. call duration: {metrics.callAnswerRate.avgDuration}
            </div>
          </div>
          <div className="border rounded-md p-4 sm:col-span-2 md:col-span-1 relative">
            <div className="absolute inset-0 bg-gray-50 bg-opacity-80 flex flex-col items-center justify-center z-10 rounded-md">
              <Lock className="h-6 w-6 text-gray-400 mb-2" />
              <span className="text-sm font-medium text-gray-500">Coming Soon</span>
              <span className="text-xs text-gray-400 mt-1">Email integration</span>
            </div>
            <div className="flex items-center justify-between mb-2">
              <div className="text-sm font-medium">Email Open Rate</div>
              <Mail className="h-4 w-4 text-purple-500" />
            </div>
            <div className="text-2xl font-bold">{metrics.emailOpenRate.percentage}%</div>
            <div className="text-xs text-gray-500 mt-1">
              Avg. response time: {metrics.emailOpenRate.avgResponseTime}
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
} 