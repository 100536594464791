import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";
import { FeedbackInput, useSubmitFeedback } from "@/services/feedback";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";

const feedbackSchema = z.object({
  title: z.string().min(5, "Title must be at least 5 characters").max(100, "Title must be less than 100 characters"),
  type: z.enum(["feature", "bug", "improvement", "other"], {
    required_error: "Please select a feedback type",
  }),
  description: z.string().min(10, "Description must be at least 10 characters").max(1000, "Description must be less than 1000 characters"),
});

type FeedbackFormValues = z.infer<typeof feedbackSchema>;

interface FeedbackFormProps {
  onSuccess?: () => void;
}

export function FeedbackForm({ onSuccess }: FeedbackFormProps) {
  const submitFeedbackMutation = useSubmitFeedback();

  const form = useForm<FeedbackFormValues>({
    resolver: zodResolver(feedbackSchema),
    defaultValues: {
      title: "",
      type: "feature",
      description: "",
    },
  });

  const onSubmit = async (values: FeedbackFormValues) => {
    try {
      await submitFeedbackMutation.mutateAsync(values as FeedbackInput);
      
      toast.success("Feedback submitted successfully", {
        description: "Thank you for your feedback!",
      });
      
      form.reset();
      
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      console.error("Error submitting feedback:", error);
      toast.error("Failed to submit feedback", {
        description: "Please try again later.",
      });
    }
  };

  return (
    <Card className="border-gray-200">
      <CardHeader className="px-4 sm:px-6 py-4 sm:py-6 space-y-1 sm:space-y-2">
        <CardTitle className="text-xl sm:text-2xl">Submit Feedback</CardTitle>
        <CardDescription className="text-sm">
          Share your ideas, report bugs, or suggest improvements to help us make the product better.
        </CardDescription>
      </CardHeader>
      <CardContent className="px-4 sm:px-6 pb-6">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4 sm:space-y-6">
            <FormField
              control={form.control}
              name="title"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-sm sm:text-base">Title</FormLabel>
                  <FormControl>
                    <Input 
                      placeholder="Brief summary of your feedback" 
                      className="text-sm sm:text-base"
                      {...field} 
                    />
                  </FormControl>
                  <FormDescription className="text-xs sm:text-sm">
                    Keep it short and descriptive
                  </FormDescription>
                  <FormMessage className="text-xs sm:text-sm" />
                </FormItem>
              )}
            />
            
            <FormField
              control={form.control}
              name="type"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-sm sm:text-base">Feedback Type</FormLabel>
                  <Select 
                    onValueChange={field.onChange} 
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger className="text-sm sm:text-base">
                        <SelectValue placeholder="Select a feedback type" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="feature" className="text-sm sm:text-base">Feature Request</SelectItem>
                      <SelectItem value="bug" className="text-sm sm:text-base">Bug Report</SelectItem>
                      <SelectItem value="improvement" className="text-sm sm:text-base">Improvement</SelectItem>
                      <SelectItem value="other" className="text-sm sm:text-base">Other</SelectItem>
                    </SelectContent>
                  </Select>
                  <FormDescription className="text-xs sm:text-sm">
                    Select the type of feedback you're providing
                  </FormDescription>
                  <FormMessage className="text-xs sm:text-sm" />
                </FormItem>
              )}
            />
            
            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-sm sm:text-base">Description</FormLabel>
                  <FormControl>
                    <Textarea 
                      placeholder="Describe your feedback in detail..." 
                      className="min-h-24 sm:min-h-32 text-sm sm:text-base"
                      {...field} 
                    />
                  </FormControl>
                  <FormDescription className="text-xs sm:text-sm">
                    Please provide as much detail as possible
                  </FormDescription>
                  <FormMessage className="text-xs sm:text-sm" />
                </FormItem>
              )}
            />
            
            <Button 
              type="submit" 
              className="w-full mt-2 text-sm sm:text-base" 
              disabled={submitFeedbackMutation.isPending}
            >
              {submitFeedbackMutation.isPending ? "Submitting..." : "Submit Feedback"}
            </Button>
          </form>
        </Form>
      </CardContent>
    </Card>
  );
} 