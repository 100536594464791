import CalendarIntegration from '@/components/calendar/CalendarIntegration';
import { MainLayout } from '@/components/layout/MainLayout';
import { PageContainer } from '@/components/layout/PageContainer';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { PageHeader } from '@/components/ui/page-header';
import { Switch } from '@/components/ui/switch';
import { toast } from '@/components/ui/use-toast';
import { axiosInstance } from '@/lib/api';
import { TmsLeadsDashboard } from '@/pages/integrations/TmsLeadsDashboard';
import { WebhooksIntegrationPage } from '@/pages/integrations/WebhooksIntegrationPage';
import { disconnectIntegration, getTokenAndSettings, updateSettings, validateToken } from '@/utils/tmsLeadsApi';
import { checkWebhooksStatus } from '@/utils/webhooksApi';
import { useNavigate, useSearch } from '@tanstack/react-router';
import { useEffect, useState } from 'react';
import { HiArrowLeft, HiOutlineExternalLink, HiOutlineLockClosed } from 'react-icons/hi';

interface IntegrationCardProps {
  title: string;
  description: string;
  icon: string;
  status: 'connected' | 'not_connected' | 'coming_soon';
  integrationId: string;
  onConnect?: () => void;
}

function IntegrationCard({ title, description, icon, status, integrationId, onConnect }: IntegrationCardProps) {
  const navigate = useNavigate();

  const handleClick = () => {
    if (status !== 'coming_soon') {
      navigate({
        to: '/integrations',
        search: { integration: integrationId },
        replace: false
      });
    }
  };

  return (
    <Card className="overflow-hidden">
      <CardHeader className="pb-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3">
            <img src={icon} alt={title} className="h-10 rounded-md" />
            <CardTitle className="text-lg">{title}</CardTitle>
          </div>
          {status === 'coming_soon' && (
            <span className="rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-600">
              Coming Soon
            </span>
          )}
          {status === 'connected' && (
            <span className="rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-600">
              Connected
            </span>
          )}
        </div>
      </CardHeader>
      <CardContent className="pb-4">
        <CardDescription>{description}</CardDescription>
      </CardContent>
      <CardFooter>
        {status === 'coming_soon' ? (
          <Button variant="outline" className="w-full" disabled>
            <HiOutlineLockClosed className="mr-2 h-4 w-4" />
            Coming Soon
          </Button>
        ) : status === 'connected' ? (
          <Button variant="outline" className="w-full" onClick={handleClick}>
            Manage Connection
          </Button>
        ) : (
          <Button className="w-full" onClick={handleClick}>
            Connect
          </Button>
        )}
      </CardFooter>
    </Card>
  );
}

// Define the available integrations
interface Integration {
  id: string;
  title: string;
  description: string;
  icon: string;
  status: 'connected' | 'not_connected' | 'coming_soon';
}

const integrations: Integration[] = [
  {
    id: 'zapier',
    title: 'Zapier',
    description: 'Connect LeadGPT to 3,000+ apps with custom workflows.',
    icon: '/assets/integrations/zapier.svg',
    status: 'coming_soon',
  },
  {
    id: 'webhooks',
    title: 'Webhooks',
    description: 'Transmit lead data to any custom endpoint using webhooks activated by lead events.',
    icon: '/assets/integrations/webhooks.svg',
    status: 'not_connected',
  },
  {
    id: 'google-calendar',
    title: 'Google Calendar',
    description: 'Sync appointments with Google Calendar for seamless scheduling with leads.',
    icon: '/assets/integrations/google-calendar.svg',
    status: 'not_connected',
  },
  {
    id: 'tms-leads',
    title: 'TMS Leads',
    description: 'Sync with your TMS Leads account for seamless lead management, account balance, and order tracking.',
    icon: 'https://tmsleads.com/images/tmslogo.png',
    status: 'not_connected',
  }
];

export function IntegrationsPage() {
  const navigate = useNavigate();
  const { integration } = useSearch({ from: '/integrations' });
  const [accessToken, setAccessToken] = useState('');
  const [isConnecting, setIsConnecting] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [syncSettings, setSyncSettings] = useState({
    autoSync: false,
    biDirectional: false,
    syncStatus: false
  });
  const [isLoading, setIsLoading] = useState(true);
  const [availableIntegrations, setAvailableIntegrations] = useState<Integration[]>(integrations);
  const [webhooksEnabled, setWebhooksEnabled] = useState(false);

  // Check if TMS Leads is already connected on component mount and load settings from the database
  useEffect(() => {
    async function checkConnections() {
      setIsLoading(true);
      try {
        // Check TMS Leads connection
        const tmsResult = await getTokenAndSettings();
        if (tmsResult.success && tmsResult.token) {
          setIsConnected(true);
          setAccessToken(tmsResult.token);
          
          // Update the TMS Leads integration status in the integrations array
          setAvailableIntegrations(prevIntegrations => 
            prevIntegrations.map(item => 
              item.id === 'tms-leads' ? { ...item, status: 'connected' } : item
            )
          );
          
          if (tmsResult.settings) {
            setSyncSettings(tmsResult.settings as {
              autoSync: boolean;
              biDirectional: boolean;
              syncStatus: boolean;
            });
          }
        } else {
          // Ensure TMS Leads is marked as not connected if no token is found
          setIsConnected(false);
          setAvailableIntegrations(prevIntegrations => 
            prevIntegrations.map(item => 
              item.id === 'tms-leads' ? { ...item, status: 'not_connected' } : item
            )
          );
        }

        // Check Webhooks status
        const webhooksResult = await checkWebhooksStatus();
        if (webhooksResult.success && webhooksResult.enabled) {
          setWebhooksEnabled(true);
          
          // Update the Webhooks integration status in the integrations array
          setAvailableIntegrations(prevIntegrations => 
            prevIntegrations.map(item => 
              item.id === 'webhooks' ? { ...item, status: 'connected' } : item
            )
          );
        } else {
          setWebhooksEnabled(false);
          setAvailableIntegrations(prevIntegrations => 
            prevIntegrations.map(item => 
              item.id === 'webhooks' ? { ...item, status: 'not_connected' } : item
            )
          );
        }
        
        // Check Google Calendar integrations
        try {
          const calendarResponse = await axiosInstance.get('/calendar/integrations');
          const calendarIntegrations = calendarResponse.data.integrations;
          
          if (calendarIntegrations && calendarIntegrations.length > 0) {
            // Update Google Calendar integration status to connected
            setAvailableIntegrations(prevIntegrations => 
              prevIntegrations.map(item => 
                item.id === 'google-calendar' ? { ...item, status: 'connected' } : item
              )
            );
          } else {
            // Ensure Google Calendar is marked as not connected
            setAvailableIntegrations(prevIntegrations => 
              prevIntegrations.map(item => 
                item.id === 'google-calendar' ? { ...item, status: 'not_connected' } : item
              )
            );
          }
        } catch (error) {
          console.error('Error checking Google Calendar integrations:', error);
          // Ensure Google Calendar is marked as not connected on error
          setAvailableIntegrations(prevIntegrations => 
            prevIntegrations.map(item => 
              item.id === 'google-calendar' ? { ...item, status: 'not_connected' } : item
            )
          );
        }
      } catch (error) {
        console.error('Error checking integrations:', error);
        // Ensure integrations are marked as not connected on error
        setIsConnected(false);
        setWebhooksEnabled(false);
        setAvailableIntegrations(prevIntegrations => 
          prevIntegrations.map(item => 
            (item.id === 'tms-leads' || item.id === 'webhooks' || item.id === 'google-calendar') 
              ? { ...item, status: 'not_connected' } 
              : item
          )
        );
      } finally {
        setIsLoading(false);
      }
    }
    
    checkConnections();
  }, []);

  const handleConnect = (integrationId: string) => {
    console.log(`Connecting to ${integrationId}`);
    navigate({
      to: '/integrations',
      search: { integration: integrationId },
      replace: false
    });
  };

  // Handle TMS Leads connection
  const handleTmsLeadsConnect = async () => {
    if (!accessToken.trim()) {
      toast({
        title: "Error",
        description: "Please enter a valid access token",
        variant: "destructive"
      });
      return;
    }

    setIsConnecting(true);

    try {
      // Use our utility function to validate the token and save it to the database
      const result = await validateToken(accessToken, {
        autoSync: true,
        biDirectional: false,
        syncStatus: true
      });
      
      if (result.success) {
        // Update connection status
        setIsConnected(true);
        
        // Update the TMS Leads integration status in the integrations array
        setAvailableIntegrations(prevIntegrations => 
          prevIntegrations.map(item => 
            item.id === 'tms-leads' ? { ...item, status: 'connected' } : item
          )
        );
        
        toast({
          title: "Success",
          description: `Successfully connected to TMS Leads. Balance: $${result.balance || 0}`,
          variant: "default"
        });
        
        // Update sync settings defaults
        const newSettings = {
          autoSync: true,
          biDirectional: false,
          syncStatus: true
        };
        
        setSyncSettings(newSettings);
      } else {
        throw new Error(result.message || "Invalid response from TMS Leads API");
      }
    } catch (error) {
      console.error("Failed to connect to TMS Leads:", error);
      toast({
        title: "Connection Failed",
        description: error instanceof Error ? error.message : "Could not connect to TMS Leads. Please check your access token and try again.",
        variant: "destructive"
      });
    } finally {
      setIsConnecting(false);
    }
  };

  // Handle TMS Leads disconnect
  const handleTmsLeadsDisconnect = async () => {
    try {
      const result = await disconnectIntegration();
      
      if (result.success) {
        setIsConnected(false);
        setAccessToken('');
        
        // Update the TMS Leads integration status in the integrations array
        setAvailableIntegrations(prevIntegrations => 
          prevIntegrations.map(item => 
            item.id === 'tms-leads' ? { ...item, status: 'not_connected' } : item
          )
        );
        
        toast({
          title: "Disconnected",
          description: "Successfully disconnected from TMS Leads",
          variant: "default"
        });
      } else {
        throw new Error(result.message || "Failed to disconnect from TMS Leads");
      }
    } catch (error) {
      console.error("Failed to disconnect from TMS Leads:", error);
      toast({
        title: "Disconnect Failed",
        description: error instanceof Error ? error.message : "Could not disconnect from TMS Leads. Please try again.",
        variant: "destructive"
      });
    }
  };

  // Handle sync settings change
  const handleSyncSettingChange = async (setting: keyof typeof syncSettings) => {
    const newSettings = {
      ...syncSettings,
      [setting]: !syncSettings[setting]
    };
    
    // Update state immediately
    setSyncSettings(newSettings);
    
    try {
      // Save settings to the database
      const result = await updateSettings(newSettings);
      
      if (result.success) {
        toast({
          title: "Settings Updated",
          description: "Sync settings have been saved",
          variant: "default"
        });
      } else {
        throw new Error(result.message || "Failed to update settings");
      }
    } catch (error) {
      console.error("Failed to update settings:", error);
      
      // Revert the setting if the update failed
      setSyncSettings(syncSettings);
      
      toast({
        title: "Update Failed",
        description: error instanceof Error ? error.message : "Could not update settings. Please try again.",
        variant: "destructive"
      });
    }
  };

  // Render the appropriate integration detail page based on the selected integration
  const renderIntegrationDetail = () => {
    switch (integration) {
      case 'zapier':
        return (
          <div className="space-y-4">
            <button 
              onClick={() => navigate({
                to: '/integrations',
                search: { integration: undefined },
                replace: false
              })}
              className="text-blue-600 hover:text-blue-800 flex items-center gap-2 mb-4"
            >
              <HiArrowLeft className="h-4 w-4" />
              Back to Integrations
            </button>
            
            <div className="flex items-start justify-between">
              <PageHeader
                title="Zapier Integration"
                description="Connect LeadGPT to 3,000+ apps with custom workflows"
              />
              <img src="/assets/integrations/zapier.svg" alt="Zapier" className="h-16 w-16 rounded-lg" />
            </div>
            
            <div className="mt-8 flex flex-col items-center justify-center p-12 text-center border-2 border-dashed rounded-lg">
              <div className="bg-gray-100 p-4 rounded-full mb-4">
                <HiOutlineLockClosed className="h-8 w-8 text-gray-500" />
              </div>
              <h2 className="text-2xl font-bold mb-2">Coming Soon</h2>
              <p className="text-gray-600 max-w-md mb-6">
                We're working hard to bring you Zapier integration. This will allow you to connect LeadGPT with 3,000+ apps and create custom workflows.
              </p>
              <Button variant="outline" onClick={() => navigate({ to: '/integrations', search: { integration: undefined } })}>
                Back to Integrations
              </Button>
            </div>
          </div>
        );
      case 'tms-leads':
        return (
          <div className="space-y-4">
            <button 
              onClick={() => navigate({
                to: '/integrations',
                search: { integration: undefined },
                replace: false
              })}
              className="text-blue-600 hover:text-blue-800 flex items-center gap-2 mb-4"
            >
              <HiArrowLeft className="h-4 w-4" />
              Back to Integrations
            </button>
            <div>
              <div className="flex items-start justify-between">
                <PageHeader
                  title="TMS Leads Integration"
                  description="Sync leads with your TMS Leads account for seamless lead management"
                />
                <img src="https://tmsleads.com/images/tmslogo.png" alt="TMS Leads" className="h-16 w-auto rounded-lg" />
              </div>

              {isConnected ? (
                <TmsLeadsDashboard />
              ) : (
                <div className="mt-8 grid gap-6 md:grid-cols-2">
                  <div>
                    <Card>
                      <CardHeader>
                        <CardTitle>About TMS Leads Integration</CardTitle>
                        <CardDescription>
                          Connect your LeadGPT account with TMS Leads for powerful lead management
                        </CardDescription>
                      </CardHeader>
                      <CardContent className="space-y-4">
                        <p>With the TMS Leads integration, you can:</p>
                        <ul className="list-disc pl-5 space-y-2">
                          <li>Access your TMS Leads orders and leads in LeadGPT</li>
                          <li>Automatically sync leads between LeadGPT and TMS Leads</li>
                          <li>Keep lead status and information up-to-date</li>
                          <li>Leverage TMS Leads' lead generation and aged leads pipeline</li>
                          <li>Streamline your sales process with automated workflows</li>
                        </ul>
                        <Button variant="outline" className="mt-4" onClick={() => window.open('https://tmsleads.com', '_blank')}>
                          <span>Learn more about TMS Leads</span>
                          <HiOutlineExternalLink className="ml-2 h-4 w-4" />
                        </Button>
                      </CardContent>
                    </Card>
                  </div>

                  <div>
                    <Card>
                      <CardHeader>
                        <CardTitle>Connect to TMS Leads</CardTitle>
                        <CardDescription>
                          Link your TMS Leads account to start syncing data
                        </CardDescription>
                      </CardHeader>
                      <CardContent className="space-y-4">
                        <p className="mb-4">
                          To connect LeadGPT with TMS Leads, you'll need to provide your TMS Leads API credentials. 
                          These can be found in your TMS Leads account settings.
                        </p>
                        <div className="space-y-4">
                          <div className="space-y-2">
                            <label htmlFor="access-token" className="text-sm font-medium">Access Token</label>
                            <Input 
                              id="access-token" 
                              type="password" 
                              placeholder="Enter your TMS Leads access token" 
                              value={accessToken}
                              onChange={(e) => setAccessToken(e.target.value)}
                              disabled={isConnected}
                            />
                          </div>
                          {isConnected ? (
                            <Button 
                              variant="destructive" 
                              className="w-full mt-2"
                              onClick={handleTmsLeadsDisconnect}
                            >
                              Disconnect TMS Leads
                            </Button>
                          ) : (
                            <Button 
                              className="w-full mt-2" 
                              onClick={handleTmsLeadsConnect}
                              disabled={isConnecting}
                            >
                              {isConnecting ? 'Connecting...' : 'Connect TMS Leads'}
                            </Button>
                          )}
                        </div>
                      </CardContent>
                    </Card>

                    <Card className="mt-6">
                      <CardHeader>
                        <CardTitle>Sync Settings</CardTitle>
                        <CardDescription>
                          Configure how data is synchronized between platforms
                        </CardDescription>
                      </CardHeader>
                      <CardContent>
                        <div className="space-y-4">
                          <div className="flex items-center justify-between">
                            <div>
                              <p className="font-medium">Auto-sync new leads</p>
                              <p className="text-sm text-gray-500">Automatically send new leads to TMS Leads</p>
                            </div>
                            <Switch 
                              id="auto-sync" 
                              checked={syncSettings.autoSync}
                              onCheckedChange={() => handleSyncSettingChange('autoSync')}
                              disabled={!isConnected}
                            />
                          </div>
                          <div className="flex items-center justify-between">
                            <div>
                              <p className="font-medium">Bi-directional sync</p>
                              <p className="text-sm text-gray-500">Keep leads updated in both systems</p>
                            </div>
                            <Switch 
                              id="bi-directional" 
                              checked={syncSettings.biDirectional}
                              onCheckedChange={() => handleSyncSettingChange('biDirectional')}
                              disabled={!isConnected}
                            />
                          </div>
                          <div className="flex items-center justify-between">
                            <div>
                              <p className="font-medium">Sync lead status</p>
                              <p className="text-sm text-gray-500">Keep lead status in sync between platforms</p>
                            </div>
                            <Switch 
                              id="sync-status" 
                              checked={syncSettings.syncStatus}
                              onCheckedChange={() => handleSyncSettingChange('syncStatus')}
                              disabled={!isConnected}
                            />
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      case 'webhooks':
        return (
          <div className="space-y-4">
            <button 
              onClick={() => navigate({
                to: '/integrations',
                search: { integration: undefined },
                replace: false
              })}
              className="text-blue-600 hover:text-blue-800 flex items-center gap-2 mb-4"
            >
              <HiArrowLeft className="h-4 w-4" />
              Back to Integrations
            </button>
            <WebhooksIntegrationPage />
          </div>
        );
      case 'google-calendar':
        return (
          <div className="space-y-4">
            <button 
              onClick={() => navigate({
                to: '/integrations',
                search: { integration: undefined },
                replace: false
              })}
              className="text-blue-600 hover:text-blue-800 flex items-center gap-2 mb-4"
            >
              <HiArrowLeft className="h-4 w-4" />
              Back to Integrations
            </button>
            <CalendarIntegration />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <MainLayout pageTitle="Integrations">
      <PageContainer>
        <div className="container py-6">
          {isLoading ? (
            <div className="flex items-center justify-center h-64">
              <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
            </div>
          ) : integration ? (
            renderIntegrationDetail()
          ) : (
            <>
              <PageHeader
                title="Integrations"
                description="Connect LeadGPT with your favorite tools and services"
              />

              <div className="mt-6 grid gap-6 md:grid-cols-2 lg:grid-cols-3">
                {availableIntegrations.map((item) => (
                  <IntegrationCard
                    key={item.id}
                    title={item.title}
                    description={item.description}
                    icon={item.icon}
                    status={item.status}
                    integrationId={item.id}
                    onConnect={() => handleConnect(item.id)}
                  />
                ))}
              </div>

              <div className="mt-12 rounded-lg bg-blue-50 p-6">
                <h3 className="text-lg font-medium text-blue-800">Need a custom integration?</h3>
                <p className="mt-2 text-blue-700">
                  We can build custom integrations for your specific business needs. Contact our team to discuss your requirements.
                </p>
                <Button variant="outline" className="mt-4 bg-white" onClick={() => window.open('mailto:support@leadgpt.com', '_blank')}  >
                  <span>Contact Support</span>
                  <HiOutlineExternalLink className="ml-2 h-4 w-4" />
                </Button>
              </div>
            </>
          )}
        </div>
      </PageContainer>
    </MainLayout>
  );
} 