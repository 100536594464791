import { Alert, AlertDescription } from '@/components/ui/alert';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { AlertTriangle, XCircle } from 'lucide-react';
import { useEffect, useState } from 'react';

export const Route = createFileRoute('/calendar/auth-error')({
  component: CalendarAuthError,
  validateSearch: (search: Record<string, unknown>) => {
    return {
      error: search.error as string | undefined,
    };
  }
});

function CalendarAuthError() {
  const { error } = Route.useSearch();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    // Format error message
    if (error) {
      try {
        const decodedError = decodeURIComponent(error);
        switch (decodedError) {
          case 'access_denied':
            setErrorMessage('You denied access to your Google Calendar.');
            break;
          case 'missing_params':
            setErrorMessage('Required parameters were missing from the authentication request.');
            break;
          case 'callback_failed':
            setErrorMessage('Failed to complete the authentication process.');
            break;
          case 'redirect_uri_mismatch':
            setErrorMessage('There was a mismatch in the redirect URI configuration.');
            break;
          default:
            if (decodedError.includes('invalid_grant')) {
              setErrorMessage('The authorization code has expired or was already used.');
            } else {
              setErrorMessage(decodedError);
            }
        }
      } catch (e) {
        setErrorMessage('An unknown error occurred during authentication.');
      }
    } else {
      setErrorMessage('An unknown error occurred during authentication.');
    }
  }, [error]);

  const handleRetry = () => {
    navigate({ to: '/integrations', search: { integration: 'calendar' } });
  };

  return (
    <div className="container max-w-md py-12">
      <Card>
        <CardHeader className="text-center">
          <div className="flex justify-center mb-4">
            <XCircle className="h-16 w-16 text-red-500" />
          </div>
          <CardTitle className="text-2xl">Authentication Failed</CardTitle>
          <CardDescription>
            There was a problem connecting your Google Calendar
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-4">
          <Alert variant="destructive">
            <AlertTriangle className="h-4 w-4 mr-2" />
            <AlertDescription>{errorMessage}</AlertDescription>
          </Alert>
          <Button className="w-full" onClick={handleRetry}>
            Try Again
          </Button>
        </CardContent>
      </Card>
    </div>
  );
} 