import { Button } from "@/components/ui/button";
import { ArrowLeft } from "lucide-react";

export function Terms() {
  const lastUpdated = "July 22, 2024";

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="container mx-auto px-4 py-12">
        {/* Back Button */}
        <a href="https://leadgpt.com" className="inline-block mb-8">
          <Button variant="ghost" className="gap-2">
            <ArrowLeft className="h-4 w-4" />
            Back to Home
          </Button>
        </a>

        <div className="max-w-4xl mx-auto bg-white rounded-xl shadow-sm p-8 md:p-12">
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Terms of Service</h1>
          <p className="text-gray-600 mb-8">Last Updated: {lastUpdated}</p>

          <div className="prose prose-gray max-w-none">
            <section className="mb-8">
              <h2 className="text-2xl font-bold mb-4">1. Agreement to Terms</h2>
              <p className="mb-4">
                By accessing or using LeadGPT's services, you agree to be bound by these Terms of Service. If you disagree with any part of these terms, you may not access our services.
              </p>
              <p className="mb-4">
                These Terms of Service constitute a legally binding agreement between you and LeadGPT regarding your use of our platform, website, and associated services (collectively, the "Services").
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold mb-4">2. Description of Services</h2>
              <p className="mb-4">
                LeadGPT provides an AI-powered insurance sales platform that includes:
              </p>
              <ul className="list-disc pl-6 mb-4">
                <li>Lead management and tracking</li>
                <li>AI-powered call and text analysis</li>
                <li>Automated task generation</li>
                <li>Team performance analytics</li>
                <li>Communication tools and local number provisioning</li>
                <li>Customer relationship management (CRM) features</li>
                <li>Sales performance dashboard and reporting</li>
                <li>AI-driven insights and recommendations</li>
              </ul>
              <p className="mb-4">
                LeadGPT reserves the right to modify, suspend, or discontinue any aspect of the Services at any time, with or without notice. We may also impose limits on certain features or restrict access to parts or all of the Services without liability.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold mb-4">3. User Accounts</h2>
              <h3 className="text-xl font-semibold mb-3">3.1 Account Creation</h3>
              <p className="mb-4">
                To use our services, you must:
              </p>
              <ul className="list-disc pl-6 mb-4">
                <li>Be at least 18 years old</li>
                <li>Complete the registration process</li>
                <li>Provide accurate and complete information</li>
                <li>Maintain the security of your account</li>
                <li>Possess the legal authority to enter into these Terms</li>
                <li>Not be prohibited from using the Services under applicable law</li>
              </ul>

              <h3 className="text-xl font-semibold mb-3">3.2 Account Responsibilities</h3>
              <p className="mb-4">
                You are responsible for:
              </p>
              <ul className="list-disc pl-6 mb-4">
                <li>All activities under your account</li>
                <li>Maintaining confidentiality of credentials</li>
                <li>Notifying us of unauthorized access</li>
                <li>Ensuring compliance with insurance regulations</li>
                <li>Ensuring all team members using your account comply with these Terms</li>
                <li>Regularly updating your account information to keep it accurate</li>
              </ul>
              
              <h3 className="text-xl font-semibold mb-3">3.3 Account Tiers and Access</h3>
              <p className="mb-4">
                LeadGPT offers different account tiers with varying features and pricing. Your access to specific features is determined by your subscription level. We reserve the right to upgrade, downgrade, or modify account tiers at our discretion, with appropriate notice.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold mb-4">4. Subscription and Payments</h2>
              <h3 className="text-xl font-semibold mb-3">4.1 Subscription Terms</h3>
              <ul className="list-disc pl-6 mb-4">
                <li>Monthly or annual billing options</li>
                <li>Automatic renewal unless cancelled</li>
                <li>Pro-rated refunds for cancellations</li>
                <li>Price changes with 30-day notice</li>
                <li>Trial periods subject to conversion to paid subscription unless cancelled</li>
                <li>Enterprise pricing available for custom solutions</li>
              </ul>

              <h3 className="text-xl font-semibold mb-3">4.2 Payment Processing</h3>
              <p className="mb-4">
                Payments are processed securely through our payment providers. You agree to provide valid payment information and authorize charges to your account.
              </p>
              <p className="mb-4">
                If your payment method fails or your account is past due, we reserve the right to either suspend or terminate your access to the Services. You are responsible for all applicable taxes, and payment obligations cannot be canceled except as described in these Terms.
              </p>
              
              <h3 className="text-xl font-semibold mb-3">4.3 Refunds and Cancellations</h3>
              <p className="mb-4">
                You may cancel your subscription at any time through your account settings or by contacting customer support. Upon cancellation:
              </p>
              <ul className="list-disc pl-6 mb-4">
                <li>Access to paid features will continue until the end of your current billing cycle</li>
                <li>No refunds will be provided for the current billing period</li>
                <li>Annual subscriptions may be eligible for pro-rated refunds at our discretion</li>
                <li>Promotional offers and discounts may have specific refund policies</li>
              </ul>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold mb-4">5. Acceptable Use</h2>
              <p className="mb-4">You agree not to:</p>
              <ul className="list-disc pl-6 mb-4">
                <li>Violate any laws or regulations</li>
                <li>Infringe on intellectual property rights</li>
                <li>Share account access with unauthorized users</li>
                <li>Use the service for unauthorized purposes</li>
                <li>Attempt to breach system security</li>
                <li>Harass or spam leads and customers</li>
                <li>Engage in deceptive insurance practices</li>
                <li>Upload or distribute malicious code or content</li>
                <li>Interfere with or disrupt the Services or servers</li>
                <li>Use automated scripts or bots to access the Services</li>
                <li>Reverse engineer or decompile any part of the Services</li>
                <li>Use the Services to compete with LeadGPT</li>
              </ul>
              <p className="mb-4">
                We reserve the right to monitor for violations and take appropriate action, including but not limited to removing content, suspending access, or terminating accounts.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold mb-4">6. Data and Privacy</h2>
              <p className="mb-4">
                Your use of our services is also governed by our Privacy Policy. You agree to our data collection and processing practices as described in the Privacy Policy.
              </p>
              <p className="mb-4">
                By using our Services, you acknowledge and agree that:
              </p>
              <ul className="list-disc pl-6 mb-4">
                <li>You have obtained all necessary consents from leads and customers for data processing</li>
                <li>You will handle all personal information in compliance with applicable privacy laws</li>
                <li>LeadGPT may use anonymized and aggregated data for service improvement</li>
                <li>Your data may be transferred and processed in different jurisdictions</li>
                <li>We implement reasonable security measures but cannot guarantee absolute security</li>
              </ul>
              <h3 className="text-xl font-semibold mb-3">6.1 Data Security and Protection</h3>
              <p className="mb-4">
                LeadGPT implements appropriate technical and organizational measures to protect your data. However, no method of transmission over the Internet or electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.
              </p>
              <h3 className="text-xl font-semibold mb-3">6.2 Data Retention and Deletion</h3>
              <p className="mb-4">
                We retain your data for as long as your account is active or as needed to provide you with the Services. Upon termination of your account, we will retain and use your information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold mb-4">7. Intellectual Property</h2>
              <p className="mb-4">
                All content, features, and functionality of our services are owned by LeadGPT and protected by intellectual property laws.
              </p>
              <h3 className="text-xl font-semibold mb-3">7.1 LeadGPT Intellectual Property</h3>
              <p className="mb-4">
                The Services, including all content, features, functionality, software, designs, trademarks, service marks, trade names, logos, and domain names, are owned by LeadGPT or its licensors and are protected by copyright, trademark, patent, and other intellectual property laws.
              </p>
              <h3 className="text-xl font-semibold mb-3">7.2 Limited License</h3>
              <p className="mb-4">
                Subject to your compliance with these Terms, LeadGPT grants you a limited, non-exclusive, non-transferable, revocable license to access and use the Services for your internal business purposes.
              </p>
              <h3 className="text-xl font-semibold mb-3">7.3 User Content</h3>
              <p className="mb-4">
                You retain ownership of any content you submit to the Services. By submitting content, you grant LeadGPT a worldwide, non-exclusive, royalty-free license to use, reproduce, modify, adapt, publish, and display such content in connection with providing and improving the Services.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold mb-4">8. Limitation of Liability</h2>
              <p className="mb-4">
                LeadGPT shall not be liable for any indirect, incidental, special, consequential, or punitive damages resulting from your use of our services.
              </p>
              <p className="mb-4">
                To the maximum extent permitted by law:
              </p>
              <ul className="list-disc pl-6 mb-4">
                <li>Our liability is limited to the amount you paid for the Services in the 12 months prior to the claim</li>
                <li>We are not liable for any loss of data, profits, business, or revenue</li>
                <li>We are not liable for business interruption or any other commercial damages</li>
                <li>We are not liable for any failures or delays beyond our reasonable control</li>
              </ul>
              <p className="mb-4">
                Some jurisdictions do not allow the exclusion or limitation of liability, so these limitations may not apply to you.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold mb-4">9. Termination</h2>
              <p className="mb-4">
                We may terminate or suspend your account and access to our services:
              </p>
              <ul className="list-disc pl-6 mb-4">
                <li>For violations of these terms</li>
                <li>For fraudulent or illegal activities</li>
                <li>Upon your request</li>
                <li>For non-payment of fees</li>
                <li>For extended periods of inactivity</li>
                <li>If we believe, in our sole discretion, that continued use would pose a risk to LeadGPT or other users</li>
              </ul>
              <p className="mb-4">
                Upon termination, your right to use the Services will immediately cease. All provisions of these Terms that by their nature should survive termination shall survive, including ownership provisions, warranty disclaimers, indemnity, and limitations of liability.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold mb-4">10. Changes to Terms</h2>
              <p className="mb-4">
                We reserve the right to modify these terms at any time. We will notify you of material changes through our platform or via email.
              </p>
              <p className="mb-4">
                Your continued use of the Services after such modifications constitutes your acceptance of the updated Terms. If you do not agree to the modified terms, you should discontinue your use of the Services.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold mb-4">11. Disclaimers and Warranties</h2>
              <p className="mb-4">
                The Services are provided "as is" and "as available" without warranties of any kind, either express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, non-infringement, or course of performance.
              </p>
              <p className="mb-4">
                LeadGPT does not warrant that:
              </p>
              <ul className="list-disc pl-6 mb-4">
                <li>The Services will function uninterrupted, secure, or available at any particular time or location</li>
                <li>Any errors or defects will be corrected</li>
                <li>The Services are free of viruses or other harmful components</li>
                <li>The results of using the Services will meet your requirements</li>
              </ul>
              <p className="mb-4">
                You acknowledge that LeadGPT is not an insurance provider and the use of our Services does not replace the need for professional insurance advice.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold mb-4">12. Indemnification</h2>
              <p className="mb-4">
                You agree to defend, indemnify, and hold harmless LeadGPT, its affiliates, officers, directors, employees, and agents from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or relating to:
              </p>
              <ul className="list-disc pl-6 mb-4">
                <li>Your violation of these Terms</li>
                <li>Your User Content</li>
                <li>Your use of the Services</li>
                <li>Your violation of any third-party rights</li>
                <li>Your violation of any laws, rules, or regulations</li>
              </ul>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold mb-4">13. Compliance with Laws</h2>
              <p className="mb-4">
                You agree to comply with all applicable laws, rules, and regulations in connection with your use of the Services, including but not limited to:
              </p>
              <ul className="list-disc pl-6 mb-4">
                <li>Insurance regulations</li>
                <li>Data protection and privacy laws</li>
                <li>Consumer protection laws</li>
                <li>Telemarketing and electronic communication laws (including CAN-SPAM, TCPA, and Do-Not-Call regulations)</li>
                <li>Anti-money laundering laws</li>
                <li>Tax laws</li>
              </ul>
              <p className="mb-4">
                LeadGPT does not provide legal advice. You are solely responsible for ensuring that your use of the Services complies with all applicable laws and regulations in your jurisdiction.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold mb-4">14. Dispute Resolution</h2>
              <h3 className="text-xl font-semibold mb-3">14.1 Governing Law</h3>
              <p className="mb-4">
                These Terms shall be governed by and construed in accordance with the laws of the State of TEXAS, without regard to its conflict of law provisions.
              </p>
              <h3 className="text-xl font-semibold mb-3">14.2 Arbitration</h3>
              <p className="mb-4">
                Any dispute arising from or relating to these Terms or the Services shall be finally settled by binding arbitration, under the rules of the American Arbitration Association, by one or more arbitrators appointed in accordance with said rules.
              </p>
              <p className="mb-4">
                The arbitration shall take place in Dallas, TX, and shall be conducted in the English language. The decision of the arbitrator shall be final and binding, and judgment on the award rendered may be entered in any court having jurisdiction.
              </p>
              <h3 className="text-xl font-semibold mb-3">14.3 Class Action Waiver</h3>
              <p className="mb-4">
                Any proceedings to resolve or litigate any dispute in any forum will be conducted solely on an individual basis. Neither you nor LeadGPT will seek to have any dispute heard as a class action or in any other proceeding in which either party acts or proposes to act in a representative capacity.
              </p>
              <h3 className="text-xl font-semibold mb-3">14.4 Limitation on Time to File Claims</h3>
              <p className="mb-4">
                Any cause of action or claim you may have arising out of or relating to these Terms or the Services must be commenced within one (1) year after the cause of action accrues, otherwise, such cause of action or claim is permanently barred.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold mb-4">15. Force Majeure</h2>
              <p className="mb-4">
                LeadGPT shall not be liable for any failure to perform its obligations under these Terms where such failure results from any cause beyond our reasonable control, including but not limited to: natural disasters, pandemics, power outages, government actions, war, terrorism, civil unrest, or other force majeure events.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold mb-4">16. Severability</h2>
              <p className="mb-4">
                If any provision of these Terms is found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary so that these Terms will otherwise remain in full force and effect and enforceable.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold mb-4">17. Entire Agreement</h2>
              <p className="mb-4">
                These Terms, together with the Privacy Policy and any other legal notices published by LeadGPT, shall constitute the entire agreement between you and LeadGPT concerning the Services.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold mb-4">18. Contact Information</h2>
              <p className="mb-4">
                For questions about these Terms of Service, please contact us at:
              </p>
              <div className="bg-gray-50 p-4 rounded-lg">
                <p>Email: legal@leadgpt.com</p>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
} 