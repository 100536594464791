import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Drawer, DrawerContent, DrawerDescription, DrawerHeader, DrawerTitle } from '@/components/ui/drawer';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Separator } from '@/components/ui/separator';
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle } from '@/components/ui/sheet';
import { Switch } from '@/components/ui/switch';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Textarea } from '@/components/ui/textarea';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { cn } from '@/lib/utils';
import { useAuthStore } from '@/store/authStore';
import { createOrder, createSupportTicket, getBalance, getLeadsCount, getOrders, getPrices, importLeads } from '@/utils/tmsLeadsApi';
import { useQueryClient } from '@tanstack/react-query';
import { format, format as formatDate, isAfter, subDays } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { FaHeartbeat, FaUserShield } from 'react-icons/fa';
import { HiChevronLeft, HiChevronRight, HiOutlineCash, HiOutlineDocumentReport, HiOutlineDownload, HiOutlineExternalLink, HiOutlineRefresh, HiOutlineShoppingCart, HiOutlineTicket } from 'react-icons/hi';
import { toast, Toaster } from 'sonner';

// Types for TMS Leads data
interface TmsLeadsOrder {
  id: number;
  user_id: number;
  order_type: string;
  quantity: number;
  completed: number;
  active: boolean | number; // API returns 1/0
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  tms_order_id: number;
  hold: number;
  round_robin: number;
  group_leads: boolean | number | null; // API returns 1/0/null
  min_emp: number | null;
  max_emp: number | null;
  lead_type: 'health' | 'life';
  leads: any[]; // This could be further typed if needed
  order_details: Array<{
    id: number;
    order_id: number;
    location_type: string;
    location: string;
    created_at: string;
    updated_at: string;
    state: string;
  }>;
}

interface TmsLeadsPrice {
  id: number;
  type: string;
  price: string;
}

interface Location {
  state?: string;
  county?: string;
  city?: string;
}

// US States for dropdown
const US_STATES = [
  { value: 'AL', text: 'Alabama' },
  { value: 'AK', text: 'Alaska' },
  { value: 'AZ', text: 'Arizona' },
  { value: 'AR', text: 'Arkansas' },
  { value: 'CA', text: 'California' },
  { value: 'CO', text: 'Colorado' },
  { value: 'CT', text: 'Connecticut' },
  { value: 'DE', text: 'Delaware' },
  { value: 'FL', text: 'Florida' },
  { value: 'GA', text: 'Georgia' },
  { value: 'HI', text: 'Hawaii' },
  { value: 'ID', text: 'Idaho' },
  { value: 'IL', text: 'Illinois' },
  { value: 'IN', text: 'Indiana' },
  { value: 'IA', text: 'Iowa' },
  { value: 'KS', text: 'Kansas' },
  { value: 'KY', text: 'Kentucky' },
  { value: 'LA', text: 'Louisiana' },
  { value: 'ME', text: 'Maine' },
  { value: 'MD', text: 'Maryland' },
  { value: 'MA', text: 'Massachusetts' },
  { value: 'MI', text: 'Michigan' },
  { value: 'MN', text: 'Minnesota' },
  { value: 'MS', text: 'Mississippi' },
  { value: 'MO', text: 'Missouri' },
  { value: 'MT', text: 'Montana' },
  { value: 'NE', text: 'Nebraska' },
  { value: 'NV', text: 'Nevada' },
  { value: 'NH', text: 'New Hampshire' },
  { value: 'NJ', text: 'New Jersey' },
  { value: 'NM', text: 'New Mexico' },
  { value: 'NY', text: 'New York' },
  { value: 'NC', text: 'North Carolina' },
  { value: 'ND', text: 'North Dakota' },
  { value: 'OH', text: 'Ohio' },
  { value: 'OK', text: 'Oklahoma' },
  { value: 'OR', text: 'Oregon' },
  { value: 'PA', text: 'Pennsylvania' },
  { value: 'RI', text: 'Rhode Island' },
  { value: 'SC', text: 'South Carolina' },
  { value: 'SD', text: 'South Dakota' },
  { value: 'TN', text: 'Tennessee' },
  { value: 'TX', text: 'Texas' },
  { value: 'UT', text: 'Utah' },
  { value: 'VT', text: 'Vermont' },
  { value: 'VA', text: 'Virginia' },
  { value: 'WA', text: 'Washington' },
  { value: 'WV', text: 'West Virginia' },
  { value: 'WI', text: 'Wisconsin' },
  { value: 'WY', text: 'Wyoming' },
  { value: 'DC', text: 'District of Columbia' }
];

export function TmsLeadsDashboard() {
  // State for dashboard data
  const [balance, setBalance] = useState<number | null>(null);
  const [orders, setOrders] = useState<TmsLeadsOrder[]>([]);
  const [prices, setPrices] = useState<TmsLeadsPrice[]>([]);
  const [isLoading, setIsLoading] = useState({
    balance: true,
    orders: true,
    prices: true,
    leadsCount: false,
    importLeads: false,
  });
  
  // State for new order form
  const [newOrder, setNewOrder] = useState({
    type: 'state',
    quantity: 10,
    locations: [{ state: '' }] as Location[],
    group_leads: false,
    min_emp: null as number | null,
    max_emp: null as number | null,
    lead_type: 'health' as 'health' | 'life'
  });
  
  // State for multi-state selection (for state-wide orders)
  const [selectedStates, setSelectedStates] = useState<string[]>([]);
  
  // State for support ticket form
  const [supportTicket, setSupportTicket] = useState({
    subject: '',
    message: '',
  });
  
  // State for order creation loading
  const [isCreatingOrder, setIsCreatingOrder] = useState(false);
  const [isSubmittingTicket, setIsSubmittingTicket] = useState(false);

  // State for reload balance panel
  const [isReloadPanelOpen, setIsReloadPanelOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width: 768px)');
  const user = useAuthStore((state) => state.user);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [ordersPerPage, setOrdersPerPage] = useState(5);

  // State for active tab
  const [activeTab, setActiveTab] = useState("orders");

  // State for import leads functionality
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [importType, setImportType] = useState<'all' | 'dateRange'>('all');
  const [dateRange, setDateRange] = useState<{
    startDate: Date | undefined;
    endDate: Date | undefined;
  }>({
    startDate: subDays(new Date(), 30),
    endDate: new Date(),
  });
  const [leadsCount, setLeadsCount] = useState<number | null>(null);
  const [importStep, setImportStep] = useState<'options' | 'preview' | 'importing' | 'complete'>('options');

  const queryClient = useQueryClient();

  // Function to handle payment process
  const handleReloadBalance = (amount?: number) => {
    if (user?.email) {
      // Construct the payment URL with optional amount
      let paymentUrl = `https://telephonemarket.securepayments.cardpointe.com/pay?email=${encodeURIComponent(user.email)}&mini=1&recurringTimeline=6`;
      
      // Add amount parameter if provided
      if (amount) {
        paymentUrl += `&total=${amount}`;
      }
      
      // Open payment page in a new window
      const paymentWindow = window.open(paymentUrl, '_blank');
      
      // Set up a listener to detect when the payment window is closed
      const checkWindowClosed = setInterval(() => {
        if (paymentWindow?.closed) {
          clearInterval(checkWindowClosed);
          setIsReloadPanelOpen(false);
          
          // Refresh balance after payment window is closed
          fetchBalance(true);
          
          // Show success message
          toast.success('Thank you for your payment', {
            description: 'Your balance will be updated shortly. Please refresh if you don\'t see the changes.',
          });
        }
      }, 1000);
    } else {
      toast.error('Unable to determine your email address. Please contact support.');
    }
  };

  // Fetch initial data
  useEffect(() => {
    fetchBalance(false);
    fetchOrders();
    fetchPrices();
  }, []);

  // Fetch balance from TMS Leads
  const fetchBalance = async (showSuccessToast = true) => {
    setIsLoading(prev => ({ ...prev, balance: true }));
    try {
      const result = await getBalance();
      if (result.success && result.balance !== undefined) {
        setBalance(result.balance);
        // Show success toast only when manually refreshing
        if (showSuccessToast) {
          toast.success('Balance updated successfully');
        }
      } else if (result.code === 'TMS_TOKEN_INVALID') {
        // Token invalidation is already handled in the API utility
        // Just set the balance to null to show empty state
        setBalance(null);
      } else {
        toast.error(result.message || 'Failed to fetch balance');
      }
    } catch (error) {
      console.error('Error fetching balance:', error);
      toast.error('An unexpected error occurred while fetching balance');
    } finally {
      setIsLoading(prev => ({ ...prev, balance: false }));
    }
  };

  // Fetch orders from TMS Leads
  const fetchOrders = async () => {
    setIsLoading(prev => ({ ...prev, orders: true }));
    try {
      const result = await getOrders();
      if (result.success && result.orders) {
        setOrders(result.orders);
      } else if (result.code === 'TMS_TOKEN_INVALID') {
        // Token invalidation is already handled in the API utility
        // Just set orders to empty array to show empty state
        setOrders([]);
      } else {
        toast.error(result.message || 'Failed to fetch orders');
      }
    } catch (error) {
      console.error('Error fetching orders:', error);
      toast.error('An unexpected error occurred while fetching orders');
    } finally {
      setIsLoading(prev => ({ ...prev, orders: false }));
    }
  };

  // Fetch prices from TMS Leads
  const fetchPrices = async () => {
    setIsLoading(prev => ({ ...prev, prices: true }));
    try {
      const result = await getPrices();
      if (result.success && result.prices) {
        setPrices(result.prices);
      } else if (result.code === 'TMS_TOKEN_INVALID') {
        // Token invalidation is already handled in the API utility
        // Just set prices to empty array to show empty state
        setPrices([]);
      } else {
        toast.error(result.message || 'Failed to fetch prices');
      }
    } catch (error) {
      console.error('Error fetching prices:', error);
      toast.error('An unexpected error occurred while fetching prices');
    } finally {
      setIsLoading(prev => ({ ...prev, prices: false }));
    }
  };

  // Handle order type change
  const handleOrderTypeChange = (value: string) => {
    setNewOrder(prev => ({
      ...prev,
      type: value,
      // Reset locations when type changes
      locations: [{ state: '' }],
    }));
    
    // Reset selected states
    setSelectedStates([]);
  };

  // Handle multi-state selection for state-wide orders
  const handleMultiStateChange = (values: string[]) => {
    setSelectedStates(values);
    
    // Create a location object for each selected state
    const stateLocations = values.map(state => ({ state }));
    
    setNewOrder(prev => ({
      ...prev,
      locations: stateLocations.length > 0 ? stateLocations : [{ state: '' }],
    }));
  };

  // Handle quantity change
  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value);
    if (!isNaN(value) && value > 0) {
      setNewOrder(prev => ({
        ...prev,
        quantity: value,
      }));
    }
  };

  // Handle location change
  const handleLocationChange = (index: number, field: keyof Location, value: string) => {
    setNewOrder(prev => {
      const newLocations = [...prev.locations];
      newLocations[index] = {
        ...newLocations[index],
        [field]: value,
      };
      return {
        ...prev,
        locations: newLocations,
      };
    });
  };

  // Add a new location
  const addLocation = () => {
    setNewOrder(prev => ({
      ...prev,
      locations: [...prev.locations, { state: '' }],
    }));
  };

  // Remove a location
  const removeLocation = (index: number) => {
    if (newOrder.locations.length > 1) {
      setNewOrder(prev => {
        const newLocations = [...prev.locations];
        newLocations.splice(index, 1);
        return {
          ...prev,
          locations: newLocations,
        };
      });
    }
  };

  // Handle support ticket subject change
  const handleSubjectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSupportTicket(prev => ({
      ...prev,
      subject: e.target.value,
    }));
  };

  // Handle support ticket message change
  const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSupportTicket(prev => ({
      ...prev,
      message: e.target.value,
    }));
  };

  // Handle lead type change
  const handleLeadTypeChange = (value: 'health' | 'life') => {
    setNewOrder(prev => ({
      ...prev,
      lead_type: value
    }));
  };

  // Handle group leads toggle
  const handleGroupLeadsToggle = (checked: boolean) => {
    setNewOrder(prev => ({
      ...prev,
      group_leads: checked,
      // Set min_emp and max_emp to default values when enabled, null when disabled
      min_emp: checked ? 1 : null,
      max_emp: checked ? 50 : null
    }));
  };

  // Handle min employees change
  const handleMinEmpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value);
    if (!isNaN(value) && value > 0 && value <= 50) {
      setNewOrder(prev => ({
        ...prev,
        min_emp: value
      }));
    }
  };

  // Handle max employees change
  const handleMaxEmpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value);
    if (!isNaN(value) && value > 0 && value <= 50) {
      setNewOrder(prev => ({
        ...prev,
        max_emp: value
      }));
    }
  };

  // Submit a new order
  const submitOrder = async () => {
    // Validate order data
    if (!newOrder.type) {
      toast.error('Please select an order type');
      return;
    }

    if (newOrder.quantity <= 0) {
      toast.error('Quantity must be greater than 0');
      return;
    }

    // Validate locations based on order type
    const invalidLocations = newOrder.locations.some(location => {
      if (newOrder.type === 'state' && !location.state) return true;
      if (newOrder.type === 'county' && (!location.state || !location.county)) return true;
      if (newOrder.type === 'city' && (!location.state || !location.county || !location.city)) return true;
      return false;
    });

    if (invalidLocations || (newOrder.type === 'state' && selectedStates.length === 0)) {
      toast.error(`Please provide all required location information for ${newOrder.type} orders`);
      return;
    }

    // Format locations for the API
    const formattedLocations = newOrder.locations.map(location => {
      // For each location, create an object with only the relevant field based on order type
      if (newOrder.type === 'state') {
        return { state: location.state };
      } else if (newOrder.type === 'county') {
        return { 
          county: location.county,
          state: location.state 
        };
      } else if (newOrder.type === 'city') {
        return { 
          city: location.city,
          county: location.county,
          state: location.state 
        };
      }
      return location;
    });

    setIsCreatingOrder(true);

    try {
      // Prepare order data with proper handling of null values
      const orderData: {
        type: string;
        quantity: number;
        locations: Array<{ state?: string; county?: string; city?: string; }>;
        lead_type: 'health' | 'life';
        group_leads: boolean;
        min_emp?: number;
        max_emp?: number;
      } = {
        type: newOrder.type,
        quantity: newOrder.quantity,
        locations: formattedLocations,
        lead_type: newOrder.lead_type,
        group_leads: newOrder.group_leads
      };
      
      // Only include min_emp and max_emp if group_leads is true
      if (newOrder.group_leads && newOrder.min_emp !== null) {
        orderData.min_emp = newOrder.min_emp;
      }
      
      if (newOrder.group_leads && newOrder.max_emp !== null) {
        orderData.max_emp = newOrder.max_emp;
      }
      
      const result = await createOrder(orderData);
      
      if (result.success) {
        // Show success toast
        toast.success('Your order has been created successfully. You can view it in the Orders tab.');
        
        // Reset form
        setNewOrder({
          type: 'state',
          quantity: 10,
          locations: [{ state: '' }],
          group_leads: false,
          min_emp: null,
          max_emp: null,
          lead_type: 'health'
        });
        
        // Reset selected states
        setSelectedStates([]);
        
        // Refresh data
        await Promise.all([
          fetchOrders(),
          fetchBalance(false)
        ]);
        
        // Wait 1 second
        await new Promise(resolve => setTimeout(resolve, 1000));
        
        // Switch to orders tab using state update instead of DOM manipulation
        setActiveTab("orders");
      } else if (result.code === 'TMS_TOKEN_INVALID') {
        // Token invalidation is already handled in the API utility
        // No need to show additional error message
      } else {
        toast.error(result.message || 'Failed to create order');
      }
    } catch (error) {
      console.error('Error creating order:', error);
      toast.error('An unexpected error occurred while creating the order');
    } finally {
      setIsCreatingOrder(false);
    }
  };

  // Submit a support ticket
  const submitSupportTicket = async () => {
    // Validate ticket data
    if (!supportTicket.subject.trim()) {
      toast.error('Please enter a subject for your ticket');
      return;
    }

    if (!supportTicket.message.trim()) {
      toast.error('Please enter a message for your ticket');
      return;
    }

    setIsSubmittingTicket(true);

    try {
      // The server expects 'subject' and 'content' fields
      const result = await createSupportTicket({
        subject: supportTicket.subject,
        message: supportTicket.message,
        // We're sending both message and content to ensure compatibility
        content: supportTicket.message
      });
      
      if (result.success) {
        toast.success('Your support ticket has been successfully submitted. Our team will respond shortly.');
        
        // Reset form
        setSupportTicket({
          subject: '',
          message: '',
        });
      } else if (result.code === 'TMS_TOKEN_INVALID') {
        // Token invalidation is already handled in the API utility
        // No need to show additional error message
      } else {
        toast.error(result.message || 'Failed to submit support ticket');
      }
    } catch (error: any) {
      console.error('Error submitting support ticket:', error);
      toast.error(error.response?.data?.message || error.message || 'An unexpected error occurred while submitting the support ticket');
    } finally {
      setIsSubmittingTicket(false);
    }
  };

  // Calculate order price
  const calculateOrderPrice = (): number => {
    if (!newOrder.type || !prices.length) return 0;
    
    const priceItem = prices.find(p => p.type === newOrder.type);
    if (!priceItem) return 0;
    
    return parseFloat(priceItem.price) * newOrder.quantity;
  };

  // Calculate pagination values
  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = orders.slice(indexOfFirstOrder, indexOfLastOrder);
  const totalPages = Math.ceil(orders.length / ordersPerPage);

  // Handle page change
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  // Handle orders per page change
  const handleOrdersPerPageChange = (value: string) => {
    setOrdersPerPage(parseInt(value));
    setCurrentPage(1); // Reset to first page when changing items per page
  };

  // Fetch leads count from TMS Leads
  const fetchLeadsCount = async () => {
    setIsLoading(prev => ({ ...prev, leadsCount: true }));
    try {
      const options = importType === 'all' 
        ? {} 
        : {
            startDate: dateRange.startDate ? formatDate(dateRange.startDate, 'yyyy-MM-dd') : undefined,
            endDate: dateRange.endDate ? formatDate(dateRange.endDate, 'yyyy-MM-dd') : undefined
          };
      
      const result = await getLeadsCount(options);
      
      if (result.success && result.count !== undefined) {
        setLeadsCount(result.count);
      } else {
        toast.error(result.message || 'Failed to fetch leads count');
        setLeadsCount(0);
      }
    } catch (error) {
      console.error('Error fetching leads count:', error);
      toast.error('An unexpected error occurred while fetching leads count');
      setLeadsCount(0);
    } finally {
      setIsLoading(prev => ({ ...prev, leadsCount: false }));
    }
  };

  // Start the import process
  const startImport = async () => {
    setIsLoading(prev => ({ ...prev, importLeads: true }));
    setImportStep('importing');
    
    try {
      const options = {
        importAll: importType === 'all',
        startDate: dateRange.startDate ? formatDate(dateRange.startDate, 'yyyy-MM-dd') : undefined,
        endDate: dateRange.endDate ? formatDate(dateRange.endDate, 'yyyy-MM-dd') : undefined
      };
      
      const result = await importLeads(options);
      
      if (result.success) {
        toast.success('Leads imported successfully', {
          description: `${result.importedCount} leads have been imported.`
        });
        setImportStep('complete');
        
        // Refresh orders to show the new leads
        fetchOrders();
        
        // Invalidate all leads queries to refresh the LeadsList component
        queryClient.invalidateQueries({ queryKey: ['leads'] });
      } else {
        toast.error(result.message || 'Failed to import leads');
        setImportStep('preview'); // Go back to preview step on error
      }
    } catch (error) {
      console.error('Error importing leads:', error);
      toast.error('An unexpected error occurred while importing leads');
      setImportStep('preview'); // Go back to preview step on error
    } finally {
      setIsLoading(prev => ({ ...prev, importLeads: false }));
    }
  };

  // Handle back button in import wizard
  const handleImportBack = () => {
    if (importStep === 'preview') {
      setImportStep('options');
    } else if (importStep === 'complete') {
      resetImportWizard();
    }
  };

  // Reset import wizard state
  const resetImportWizard = () => {
    setImportType('all');
    setDateRange({
      startDate: subDays(new Date(), 30),
      endDate: new Date(),
    });
    setLeadsCount(null);
    setImportStep('options');
    setIsImportModalOpen(false);
  };

  return (
    <div className="space-y-6 mt-4">
      <Toaster 
        position="top-right" 
        richColors 
        closeButton
        expand={false}
        duration={4000}
        theme="light"
      />
      
      {/* Reload Balance Sheet (for desktop) */}
      {!isMobile && (
        <Sheet open={isReloadPanelOpen} onOpenChange={setIsReloadPanelOpen}>
          <SheetContent className="sm:max-w-md">
            <SheetHeader>
              <SheetTitle>Reload Your Balance</SheetTitle>
              <SheetDescription>
                Add funds to your TMS Leads account to purchase more leads.
              </SheetDescription>
            </SheetHeader>
            <div className="mt-6 space-y-6">
              <div className="space-y-2">
                <p className="text-sm text-gray-500">Current Balance</p>
                <p className="text-2xl font-bold text-blue-700">${balance !== null ? balance.toFixed(2) : '0.00'}</p>
              </div>
              
              <div className="space-y-2">
                <p className="text-sm text-gray-500">Suggested Amounts</p>
                <div className="grid grid-cols-3 gap-2">
                  <Button variant="outline" onClick={() => handleReloadBalance(200)}>$200</Button>
                  <Button variant="outline" onClick={() => handleReloadBalance(400)}>$400</Button>
                  <Button variant="outline" onClick={() => handleReloadBalance(800)}>$800</Button>
                </div>
              </div>
              
              <div className="space-y-2">
                <p className="text-sm text-gray-500">Payment Method</p>
                <p className="text-sm">You'll be redirected to a secure payment processor to complete your transaction.</p>
              </div>
              
              <div className="mt-8">
                <Button 
                  className="w-full" 
                  onClick={() => handleReloadBalance()}
                >
                  <HiOutlineCash className="mr-2 h-4 w-4" />
                  Custom Amount
                </Button>
                
                <p className="text-xs text-gray-500 mt-2 text-center">
                  After completing your payment, please allow a few minutes for your balance to update.
                </p>
              </div>
            </div>
          </SheetContent>
        </Sheet>
      )}
      
      {/* Reload Balance Drawer (for mobile) */}
      {isMobile && (
        <Drawer open={isReloadPanelOpen} onOpenChange={setIsReloadPanelOpen}>
          <DrawerContent>
            <DrawerHeader>
              <DrawerTitle>Reload Your Balance</DrawerTitle>
              <DrawerDescription>
                Add funds to your TMS Leads account to purchase more leads.
              </DrawerDescription>
            </DrawerHeader>
            <div className="p-4 space-y-6">
              <div className="space-y-2">
                <p className="text-sm text-gray-500">Current Balance</p>
                <p className="text-2xl font-bold text-blue-700">${balance !== null ? balance.toFixed(2) : '0.00'}</p>
              </div>
              
              <div className="space-y-2">
                <p className="text-sm text-gray-500">Suggested Amounts</p>
                <div className="grid grid-cols-3 gap-2">
                  <Button variant="outline" onClick={() => handleReloadBalance(200)}>$200</Button>
                  <Button variant="outline" onClick={() => handleReloadBalance(400)}>$400</Button>
                  <Button variant="outline" onClick={() => handleReloadBalance(800)}>$800</Button>
                </div>
              </div>
              
              <div className="space-y-2">
                <p className="text-sm text-gray-500">Payment Method</p>
                <p className="text-sm">You'll be redirected to a secure payment processor to complete your transaction.</p>
              </div>
              
              <div className="mt-8 pb-8">
                <Button 
                  className="w-full" 
                  onClick={() => handleReloadBalance()}
                >
                  <HiOutlineCash className="mr-2 h-4 w-4" />
                  Custom Amount
                </Button>
                
                <p className="text-xs text-gray-500 mt-2 text-center">
                  After completing your payment, please allow a few minutes for your balance to update.
                </p>
              </div>
            </div>
          </DrawerContent>
        </Drawer>
      )}
      
      <div className="flex flex-col md:flex-row gap-6">
        {/* Balance Card */}
        <Card className="flex-1">
          <CardHeader className="pb-2">
            <CardTitle className="text-lg flex items-center">
              <HiOutlineCash className="mr-2 h-5 w-5 text-blue-500" />
              Account Balance
            </CardTitle>
          </CardHeader>
          <CardContent>
            {isLoading.balance ? (
              <div className="h-12 flex items-center justify-center">
                <div className="animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-blue-500"></div>
              </div>
            ) : (
              <div className="text-3xl font-bold text-blue-700">${balance !== null ? balance.toFixed(2) : '0.00'}</div>
            )}
          </CardContent>
          <CardFooter className="flex flex-col sm:flex-row gap-2">
            <Button 
              variant="outline" 
              size="sm" 
              className="w-full" 
              onClick={() => fetchBalance(true)}
              disabled={isLoading.balance}
            >
              {isLoading.balance ? (
                <>
                  <div className="mr-2 h-4 w-4 animate-spin rounded-full border-2 border-b-transparent border-t-blue-600 border-l-blue-600 border-r-blue-600"></div>
                  Updating...
                </>
              ) : (
                <>
                  <HiOutlineRefresh className="mr-2 h-4 w-4" />
                  Refresh Balance
                </>
              )}
            </Button>
            <Button 
              variant="default" 
              size="sm" 
              className="w-full bg-green-600 hover:bg-green-700" 
              onClick={() => setIsReloadPanelOpen(true)}
            >
              <HiOutlineCash className="mr-2 h-4 w-4" />
              Reload Balance
            </Button>
            <Button 
              variant="secondary" 
              size="sm" 
              className="w-full" 
              onClick={() => setIsImportModalOpen(true)}
            >
              <HiOutlineDownload className="mr-2 h-4 w-4" />
              Import Leads
            </Button>
          </CardFooter>
        </Card>

        {/* Quick Stats Card */}
        <Card className="flex-1">
          <CardHeader className="pb-2">
            <CardTitle className="text-lg flex items-center">
              <HiOutlineDocumentReport className="mr-2 h-5 w-5 text-blue-500" />
              Order Statistics
            </CardTitle>
          </CardHeader>
          <CardContent>
            {isLoading.orders ? (
              <div className="h-12 flex items-center justify-center">
                <div className="animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-blue-500"></div>
              </div>
            ) : (
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <p className="text-sm text-gray-500">Total Orders</p>
                  <p className="text-2xl font-semibold">{orders.length}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-500">Active Orders</p>
                  <p className="text-2xl font-semibold">{orders.filter(o => Boolean(o.active)).length}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-500">Completed Leads</p>
                  <p className="text-2xl font-semibold">{orders.reduce((sum, order) => sum + order.completed, 0)}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-500">Pending Leads</p>
                  <p className="text-2xl font-semibold">
                    {orders
                      .filter(order => Boolean(order.active))
                      .reduce((sum, order) => sum + (order.quantity - order.completed), 0)}
                  </p>
                </div>
              </div>
            )}
          </CardContent>
          <CardFooter>
            <Button 
              variant="outline" 
              size="sm" 
              className="w-full" 
              onClick={fetchOrders}
              disabled={isLoading.orders}
            >
              <HiOutlineRefresh className="mr-2 h-4 w-4" />
              Refresh Stats
            </Button>
          </CardFooter>
        </Card>
      </div>

      <Tabs value={activeTab} onValueChange={setActiveTab} className="w-full">
        <TabsList className="grid grid-cols-3 mb-4">
          <TabsTrigger value="orders">
            <HiOutlineShoppingCart className="mr-2 h-4 w-4" />
            Orders
          </TabsTrigger>
          <TabsTrigger value="new-order">
            <HiOutlineShoppingCart className="mr-2 h-4 w-4" />
            New Order
          </TabsTrigger>
          <TabsTrigger value="support">
            <HiOutlineTicket className="mr-2 h-4 w-4" />
            Support
          </TabsTrigger>
        </TabsList>

        {/* Orders Tab */}
        <TabsContent value="orders" className="space-y-4">
          <Card>
            <CardHeader className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
              <div>
                <CardTitle>Your Orders</CardTitle>
                <CardDescription>View and manage your TMS Leads orders</CardDescription>
              </div>
              {!isLoading.orders && orders.length > 0 && (
                <div className="flex items-center space-x-2 mt-2 sm:mt-0">
                  <Label htmlFor="orders-per-page" className="text-sm">Show:</Label>
                  <Select 
                    value={ordersPerPage.toString()} 
                    onValueChange={handleOrdersPerPageChange}
                  >
                    <SelectTrigger id="orders-per-page" className="w-[80px]">
                      <SelectValue placeholder="5" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="5">5</SelectItem>
                      <SelectItem value="10">10</SelectItem>
                      <SelectItem value="20">20</SelectItem>
                      <SelectItem value="50">50</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
              )}
            </CardHeader>
            <CardContent>
              {isLoading.orders ? (
                <div className="h-64 flex items-center justify-center">
                  <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
                </div>
              ) : orders.length === 0 ? (
                <div className="text-center py-12">
                  <p className="text-gray-500">You don't have any orders yet</p>
                  <Button 
                    variant="outline" 
                    className="mt-4"
                    onClick={() => setActiveTab("new-order")}
                  >
                    Create Your First Order
                  </Button>
                </div>
              ) : (
                <div className="space-y-4">
                  {currentOrders.map((order) => (
                    <Card key={order.id} className="overflow-hidden">
                      <div className="bg-gray-50 px-4 py-2 flex justify-between items-center">
                        <div className="flex items-center">
                          <span className="font-medium">Order #{order.id}</span>
                          <Badge 
                            className={`ml-2 ${
                              Boolean(order.active) 
                                ? 'bg-green-100 text-green-800' 
                                : order.completed === order.quantity
                                  ? 'bg-gray-100 text-gray-800'
                                  : 'bg-red-100 text-red-800'
                            }`}
                          >
                            {Boolean(order.active) 
                              ? 'Active' 
                              : order.completed === order.quantity
                                ? 'Completed'
                                : 'Canceled'}
                          </Badge>
                        </div>
                        <span className="text-sm text-gray-500">
                          {format(new Date(order.created_at), 'MMM d, yyyy')}
                        </span>
                      </div>
                      <div className="p-4">
                        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-4">
                          <div>
                            <p className="text-sm text-gray-500">Type</p>
                            <p className="font-medium capitalize">
                              {Boolean(order.group_leads) ? 'Group ' : ''}
                              {order.lead_type ? 
                                order.lead_type.charAt(0).toUpperCase() + order.lead_type.slice(1) : 
                                'Health'}
                              {Boolean(order.group_leads) && order.min_emp !== null && (
                                <span className="text-xs text-gray-500 block md:inline md:ml-1">
                                  ({order.min_emp}-{order.max_emp} emp.)
                                </span>
                              )}
                            </p>
                          </div>
                          <div>
                            <p className="text-sm text-gray-500">Quantity</p>
                            <p className="font-medium">{order.quantity}</p>
                          </div>
                          <div>
                            <p className="text-sm text-gray-500">Completed</p>
                            <p className="font-medium">{order.completed}</p>
                          </div>
                          <div>
                            <p className="text-sm text-gray-500">Progress</p>
                            <p className="font-medium">{Math.round((order.completed / order.quantity) * 100)}%</p>
                          </div>
                        </div>
                        
                        <Separator className="my-2" />
                        
                        <div className="mt-2">
                          <p className="text-sm text-gray-500 mb-1">Locations:</p>
                          <div className="flex flex-wrap gap-2">
                            {order.order_details.map((detail, index) => (
                              <Badge key={index} variant="outline" className="bg-blue-50">
                                {detail.location}
                              </Badge>
                            ))}
                          </div>
                        </div>
                      </div>
                    </Card>
                  ))}
                  
                  {/* Pagination controls */}
                  {totalPages > 1 && (
                    <div className="flex justify-between items-center mt-4">
                      <div className="text-sm text-gray-500">
                        Showing {indexOfFirstOrder + 1}-{Math.min(indexOfLastOrder, orders.length)} of {orders.length} orders
                      </div>
                      <div className="flex space-x-1">
                        <Button
                          variant="outline"
                          size="sm"
                          onClick={() => handlePageChange(currentPage - 1)}
                          disabled={currentPage === 1}
                          className="h-8 w-8 p-0"
                        >
                          <HiChevronLeft className="h-4 w-4" />
                        </Button>
                        
                        {Array.from({ length: totalPages }, (_, i) => i + 1)
                          .filter(page => {
                            // Show first page, last page, current page, and pages around current page
                            return (
                              page === 1 || 
                              page === totalPages || 
                              (page >= currentPage - 1 && page <= currentPage + 1) ||
                              (currentPage === 1 && page <= 4) ||
                              (currentPage === totalPages && page >= totalPages - 3)
                            );
                          })
                          .map((page, i, filteredPages) => (
                            <React.Fragment key={page}>
                              {i > 0 && filteredPages[i - 1] !== page - 1 && (
                                <Button
                                  variant="outline"
                                  size="sm"
                                  disabled
                                  className="h-8 w-8 p-0"
                                >
                                  ...
                                </Button>
                              )}
                              <Button
                                variant={currentPage === page ? "default" : "outline"}
                                size="sm"
                                onClick={() => handlePageChange(page)}
                                className="h-8 w-8 p-0"
                              >
                                {page}
                              </Button>
                            </React.Fragment>
                          ))
                        }
                        
                        <Button
                          variant="outline"
                          size="sm"
                          onClick={() => handlePageChange(currentPage + 1)}
                          disabled={currentPage === totalPages}
                          className="h-8 w-8 p-0"
                        >
                          <HiChevronRight className="h-4 w-4" />
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </CardContent>
            <CardFooter>
              <Button 
                variant="outline" 
                className="w-full" 
                onClick={fetchOrders}
                disabled={isLoading.orders}
              >
                <HiOutlineRefresh className="mr-2 h-4 w-4" />
                Refresh Orders
              </Button>
            </CardFooter>
          </Card>
        </TabsContent>

        {/* New Order Tab */}
        <TabsContent value="new-order">
          <Card>
            <CardHeader>
              <CardTitle>Create New Order</CardTitle>
              <CardDescription>Place a new order for leads</CardDescription>
            </CardHeader>
            <CardContent>
              <div className="space-y-6">

                {/* Lead Type */}
                <div className="space-y-3">
                  <Label className="text-base font-medium">Lead Type</Label>
                  <RadioGroup 
                    value={newOrder.lead_type} 
                    onValueChange={(value) => handleLeadTypeChange(value as 'health' | 'life')}
                    className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4"
                  >
                    <div 
                      className={cn(
                        "relative rounded-lg border-2 p-4 cursor-pointer transition-all duration-200 hover:shadow-md",
                        newOrder.lead_type === 'health' 
                          ? "border-blue-500 bg-blue-50" 
                          : "border-gray-200 hover:border-blue-200"
                      )}
                      onClick={() => handleLeadTypeChange('health')}
                    >
                      <RadioGroupItem 
                        value="health" 
                        id="health" 
                        className="sr-only" 
                      />
                      <Label 
                        htmlFor="health" 
                        className="cursor-pointer flex flex-col h-full"
                      >
                        <div className="flex items-start mb-2">
                          <div className={cn(
                            "mr-3 p-2 rounded-full transition-colors",
                            newOrder.lead_type === 'health'
                              ? "text-blue-500 bg-blue-100"
                              : "text-gray-400 bg-gray-100"
                          )}>
                            <FaHeartbeat className="h-5 w-5" />
                          </div>
                          <div className="flex-1">
                            <h3 className="font-medium">Health Insurance</h3>
                            <p className="text-sm text-gray-500 mt-1">Medical, dental, and vision coverage leads</p>
                          </div>
                        </div>
                      </Label>
                      {newOrder.lead_type === 'health' && (
                        <div className="absolute top-2 right-2 h-3 w-3 rounded-full bg-blue-500"></div>
                      )}
                    </div>
                    
                    <div 
                      className={cn(
                        "relative rounded-lg border-2 p-4 cursor-pointer transition-all duration-200 hover:shadow-md",
                        newOrder.lead_type === 'life' 
                          ? "border-green-500 bg-green-50" 
                          : "border-gray-200 hover:border-green-200"
                      )}
                      onClick={() => handleLeadTypeChange('life')}
                    >
                      <RadioGroupItem 
                        value="life" 
                        id="life" 
                        className="sr-only" 
                      />
                      <Label 
                        htmlFor="life" 
                        className="cursor-pointer flex flex-col h-full"
                      >
                        <div className="flex items-start mb-2">
                          <div className={cn(
                            "mr-3 p-2 rounded-full transition-colors",
                            newOrder.lead_type === 'life'
                              ? "text-green-500 bg-green-100"
                              : "text-gray-400 bg-gray-100"
                          )}>
                            <FaUserShield className="h-5 w-5" />
                          </div>
                          <div className="flex-1">
                            <h3 className="font-medium">Life Insurance</h3>
                            <p className="text-sm text-gray-500 mt-1">Term, whole, and universal life policy leads</p>
                          </div>
                        </div>
                      </Label>
                      {newOrder.lead_type === 'life' && (
                        <div className="absolute top-2 right-2 h-3 w-3 rounded-full bg-green-500"></div>
                      )}
                    </div>
                  </RadioGroup>
                </div>

                {/* Group Leads Toggle */}
                <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-2 sm:gap-0">
                  <div>
                    <Label htmlFor="group-leads" className="block mb-1">Group Leads</Label>
                    <p className="text-sm text-gray-500">Enable for business/group insurance leads</p>
                  </div>
                  <Switch 
                    id="group-leads" 
                    checked={newOrder.group_leads}
                    onCheckedChange={handleGroupLeadsToggle}
                    className="mt-2 sm:mt-0"
                  />
                </div>

                {/* Employee Range (only shown when group_leads is true) */}
                {newOrder.group_leads && (
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <div className="space-y-2">
                      <Label htmlFor="min-emp">Min Employees</Label>
                      <Input 
                        id="min-emp" 
                        type="number" 
                        min="1"
                        max="50"
                        value={newOrder.min_emp || ''} 
                        onChange={handleMinEmpChange}
                        className="w-full"
                      />
                    </div>
                    <div className="space-y-2">
                      <Label htmlFor="max-emp">Max Employees</Label>
                      <Input 
                        id="max-emp" 
                        type="number" 
                        min="1"
                        max="50"
                        value={newOrder.max_emp || ''} 
                        onChange={handleMaxEmpChange}
                        className="w-full"
                      />
                    </div>
                  </div>
                )}

                {/* Quantity */}
                <div className="space-y-2">
                  <Label htmlFor="quantity">Quantity</Label>
                  <Input 
                    id="quantity" 
                    type="number" 
                    min="1"
                    value={newOrder.quantity} 
                    onChange={handleQuantityChange}
                    className="w-full"
                  />
                </div>

                {/* Locations */}
                {newOrder.type && (
                  <div className="space-y-4">
                    <div className="flex items-center justify-between">
                      <Label>Locations</Label>
                      {newOrder.type !== 'state' && (
                        <Button 
                          type="button" 
                          variant="outline" 
                          size="sm"
                          onClick={addLocation}
                        >
                          Add Location
                        </Button>
                      )}
                    </div>
                    
                    {newOrder.type === 'state' ? (
                      <div className="space-y-2">
                        <Label htmlFor="multi-state-select">Select States</Label>
                        <Select 
                          onValueChange={(value) => handleMultiStateChange([...selectedStates, value])}
                          value=""
                        >
                          <SelectTrigger id="multi-state-select">
                            <SelectValue placeholder="Add a state" />
                          </SelectTrigger>
                          <SelectContent>
                            {US_STATES
                              .filter(state => !selectedStates.includes(state.value))
                              .map((state) => (
                                <SelectItem key={state.value} value={state.value}>
                                  {state.text}
                                </SelectItem>
                              ))
                            }
                          </SelectContent>
                        </Select>
                        
                        {selectedStates.length > 0 && (
                          <div className="mt-4">
                            <Label>Selected States</Label>
                            <div className="flex flex-wrap gap-2 mt-2">
                              {selectedStates.map((stateCode) => {
                                const stateName = US_STATES.find(s => s.value === stateCode)?.text || stateCode;
                                return (
                                  <Badge 
                                    key={stateCode} 
                                    variant="secondary"
                                    className="flex items-center gap-1 px-3 py-1"
                                  >
                                    {stateName}
                                    <button 
                                      type="button"
                                      className="ml-1 rounded-full h-4 w-4 inline-flex items-center justify-center text-xs bg-gray-200 hover:bg-gray-300"
                                      onClick={() => {
                                        const newSelected = selectedStates.filter(s => s !== stateCode);
                                        setSelectedStates(newSelected);
                                        handleMultiStateChange(newSelected);
                                      }}
                                    >
                                      ×
                                    </button>
                                  </Badge>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : (
                      newOrder.locations.map((location, index) => (
                        <div key={index} className="p-4 border rounded-md space-y-4">
                          <div className="flex justify-between items-center">
                            <h4 className="font-medium">Location {index + 1}</h4>
                            {newOrder.locations.length > 1 && (
                              <Button 
                                type="button" 
                                variant="ghost" 
                                size="sm"
                                onClick={() => removeLocation(index)}
                                className="h-8 text-red-500 hover:text-red-700 hover:bg-red-50"
                              >
                                Remove
                              </Button>
                            )}
                          </div>
                          
                          <div className="space-y-4">
                            {/* State */}
                            <div className="space-y-2">
                              <Label htmlFor={`state-${index}`}>State</Label>
                              <Select 
                                value={location.state || ''}
                                onValueChange={(value) => handleLocationChange(index, 'state', value)}
                              >
                                <SelectTrigger id={`state-${index}`}>
                                  <SelectValue placeholder="Select state" />
                                </SelectTrigger>
                                <SelectContent>
                                  {US_STATES.map((state) => (
                                    <SelectItem key={state.value} value={state.value}>
                                      {state.text}
                                    </SelectItem>
                                  ))}
                                </SelectContent>
                              </Select>
                            </div>
                            
                            {/* County (for county and city types) */}
                            {(newOrder.type === 'county' || newOrder.type === 'city') && (
                              <div className="space-y-2">
                                <Label htmlFor={`county-${index}`}>County</Label>
                                <Input 
                                  id={`county-${index}`}
                                  value={location.county || ''}
                                  onChange={(e) => handleLocationChange(index, 'county', e.target.value)}
                                  placeholder="e.g. Orange County, King County"
                                />
                              </div>
                            )}
                            
                            {/* City (for city type only) */}
                            {newOrder.type === 'city' && (
                              <div className="space-y-2">
                                <Label htmlFor={`city-${index}`}>City</Label>
                                <Input 
                                  id={`city-${index}`}
                                  value={location.city || ''}
                                  onChange={(e) => handleLocationChange(index, 'city', e.target.value)}
                                  placeholder="e.g. Los Angeles, Seattle"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                )}

                {/* Order Summary */}
                {newOrder.type && (
                  <Card className="bg-gray-50">
                    <CardHeader className="pb-2">
                      <CardTitle className="text-lg">Order Summary</CardTitle>
                    </CardHeader>
                    <CardContent>
                      <div className="space-y-2">
                        <div className="flex justify-between">
                          <span>Type:</span>
                          <span className="font-medium capitalize">{newOrder.type}</span>
                        </div>
                        <div className="flex justify-between">
                          <span>Lead Type:</span>
                          <span className="font-medium capitalize">{newOrder.lead_type}</span>
                        </div>
                        {newOrder.group_leads && (
                          <div className="flex justify-between">
                            <span>Group Size:</span>
                            <span className="font-medium">{newOrder.min_emp} - {newOrder.max_emp} employees</span>
                          </div>
                        )}
                        <div className="flex justify-between">
                          <span>Quantity:</span>
                          <span className="font-medium">{newOrder.quantity} leads</span>
                        </div>
                        <div className="flex justify-between">
                          <span>Locations:</span>
                          <span className="font-medium">{newOrder.locations.length}</span>
                        </div>
                        <Separator />
                        <div className="flex justify-between text-lg font-bold">
                          <span>Total Price:</span>
                          <span className="text-blue-700">${calculateOrderPrice().toFixed(2)}</span>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                )}
              </div>
            </CardContent>
            <CardFooter className="flex justify-between">
              <Button 
                variant="outline" 
                onClick={() => setActiveTab("orders")}
              >
                Cancel
              </Button>
              <Button 
                onClick={submitOrder}
                disabled={isCreatingOrder || !newOrder.type || (newOrder.type === 'state' && selectedStates.length === 0)}
              >
                {isCreatingOrder ? 'Creating Order...' : 'Place Order'}
              </Button>
            </CardFooter>
          </Card>
        </TabsContent>

        {/* Support Tab */}
        <TabsContent value="support">
          <Card>
            <CardHeader>
              <CardTitle>Contact Support <Button variant="link" className="ml-2" onClick={() => window.open('https://tmsleads.com', '_blank')}>Visit TMS
                <HiOutlineExternalLink className="h-4 w-4" />
              </Button></CardTitle>
              <CardDescription>Need help with your TMS Leads integration? Submit a support ticket</CardDescription>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                <div className="space-y-2">
                  <Label htmlFor="subject">Subject</Label>
                  <Input 
                    id="subject" 
                    value={supportTicket.subject}
                    onChange={handleSubjectChange}
                    placeholder="Brief description of your issue"
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="message">Message</Label>
                  <Textarea 
                    id="message" 
                    value={supportTicket.message}
                    onChange={handleMessageChange}
                    placeholder="Provide details about your issue or question"
                    rows={6}
                  />
                </div>
              </div>
            </CardContent>
            <CardFooter>
              <Button 
                className="w-full" 
                onClick={submitSupportTicket}
                disabled={isSubmittingTicket}
              >
                {isSubmittingTicket ? 'Submitting...' : 'Submit Ticket'}
              </Button>
            </CardFooter>
          </Card>
        </TabsContent>
      </Tabs>

      {/* Import Leads Dialog */}
      <Dialog open={isImportModalOpen} onOpenChange={(open) => {
        if (!open) resetImportWizard();
        setIsImportModalOpen(open);
      }}>
        <DialogContent className="sm:max-w-md">
          <DialogHeader>
            <DialogTitle>
              {importStep === 'options' && 'Import Leads from TMS Leads'}
              {importStep === 'preview' && 'Confirm Import'}
              {importStep === 'importing' && 'Importing Leads...'}
              {importStep === 'complete' && 'Import Complete'}
            </DialogTitle>
            <DialogDescription>
              {importStep === 'options' && 'Select which leads you want to import from your TMS Leads account.'}
              {importStep === 'preview' && 'Review the leads to be imported.'}
              {importStep === 'importing' && 'Please wait while we import your leads.'}
              {importStep === 'complete' && 'Your leads have been successfully imported.'}
            </DialogDescription>
          </DialogHeader>

          {importStep === 'options' && (
            <div className="space-y-4 py-2">
              <div className="space-y-2">
                <RadioGroup
                  value={importType}
                  onValueChange={(value) => setImportType(value as 'all' | 'dateRange')}
                  className="flex flex-col space-y-2"
                >
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="all" id="all" />
                    <Label htmlFor="all">Import all available leads</Label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="dateRange" id="dateRange" />
                    <Label htmlFor="dateRange">Import leads from a specific date range</Label>
                  </div>
                </RadioGroup>
              </div>

              {importType === 'dateRange' && (
                <div className="grid grid-cols-2 gap-4">
                  <div className="space-y-2">
                    <Label htmlFor="startDate">Start Date</Label>
                    <Popover>
                      <PopoverTrigger asChild>
                        <Button
                          variant="outline"
                          className="w-full justify-start text-left font-normal"
                        >
                          {dateRange.startDate ? (
                            formatDate(dateRange.startDate, 'MMM dd, yyyy')
                          ) : (
                            <span>Pick a date</span>
                          )}
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent className="w-auto p-0">
                        <Calendar
                          mode="single"
                          selected={dateRange.startDate}
                          onSelect={(date) => setDateRange({ ...dateRange, startDate: date })}
                          disabled={(date) => isAfter(date, new Date())}
                          initialFocus
                        />
                      </PopoverContent>
                    </Popover>
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="endDate">End Date</Label>
                    <Popover>
                      <PopoverTrigger asChild>
                        <Button
                          variant="outline"
                          className="w-full justify-start text-left font-normal"
                        >
                          {dateRange.endDate ? (
                            formatDate(dateRange.endDate, 'MMM dd, yyyy')
                          ) : (
                            <span>Pick a date</span>
                          )}
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent className="w-auto p-0">
                        <Calendar
                          mode="single"
                          selected={dateRange.endDate}
                          onSelect={(date) => setDateRange({ ...dateRange, endDate: date })}
                          disabled={(date) => isAfter(date, new Date())}
                          initialFocus
                        />
                      </PopoverContent>
                    </Popover>
                  </div>
                </div>
              )}

              <DialogFooter className="mt-4">
                <Button 
                  onClick={() => {
                    setImportStep('preview');
                    fetchLeadsCount();
                  }}
                >
                  Next
                </Button>
              </DialogFooter>
            </div>
          )}

          {importStep === 'preview' && (
            <div className="space-y-4 py-2">
              <div className="bg-gray-50 rounded-lg p-4">
                <h3 className="font-medium mb-2">Import Summary</h3>
                <div className="space-y-2">
                  <div className="flex justify-between">
                    <span>Import Type:</span>
                    <span className="font-medium">
                      {importType === 'all' ? 'All Available Leads' : 'Date Range'}
                    </span>
                  </div>
                  {importType === 'dateRange' && (
                    <div className="flex justify-between">
                      <span>Date Range:</span>
                      <span className="font-medium">
                        {dateRange.startDate && dateRange.endDate 
                          ? `${formatDate(dateRange.startDate, 'MMM dd, yyyy')} - ${formatDate(dateRange.endDate, 'MMM dd, yyyy')}`
                          : 'Invalid date range'}
                      </span>
                    </div>
                  )}
                  <div className="flex justify-between">
                    <span>Leads to Import:</span>
                    {isLoading.leadsCount ? (
                      <span className="inline-flex items-center">
                        <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-blue-500 mr-2"></div>
                        Counting...
                      </span>
                    ) : (
                      <span className="font-bold text-blue-700">{leadsCount || 0}</span>
                    )}
                  </div>
                </div>
              </div>

              <DialogFooter className="flex justify-between">
                <Button 
                  variant="outline" 
                  onClick={handleImportBack}
                  disabled={isLoading.leadsCount}
                >
                  Back
                </Button>
                <Button 
                  onClick={startImport}
                  disabled={isLoading.leadsCount || leadsCount === 0}
                >
                  {leadsCount === 0 ? 'No Leads to Import' : 'Import Leads'}
                </Button>
              </DialogFooter>
            </div>
          )}

          {importStep === 'importing' && (
            <div className="space-y-4 py-8 flex flex-col items-center justify-center">
              <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
              <p className="text-center mt-4">
                Importing {leadsCount} leads. This may take a few moments...
              </p>
            </div>
          )}

          {importStep === 'complete' && (
            <div className="space-y-4 py-2">
              <div className="bg-green-50 rounded-lg p-4 text-center">
                <div className="flex items-center justify-center mb-4 text-green-500">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                </div>
                <h3 className="font-medium text-lg mb-2">Import Successful</h3>
                <p>Your leads have been successfully imported and are now available in the system.</p>
              </div>

              <DialogFooter>
                <Button 
                  onClick={resetImportWizard}
                >
                  Close
                </Button>
              </DialogFooter>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
} 