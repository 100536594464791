import { Button } from '@/components/ui/button';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog';
import { api } from '@/lib/api';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useToast } from '../ui/use-toast';
import { FieldMapper } from './import/FieldMapper';
import { FileUploader } from './import/FileUploader';
import { ImportPreview } from './import/ImportPreview';
import { ImportProgress } from './import/ImportProgress';
import { ImportSummary } from './import/ImportSummary';

interface LeadImportModalProps {
  isOpen: boolean;
  onClose: () => void;
  embedded?: boolean;
}

type ImportStep = 'upload' | 'map' | 'preview' | 'import' | 'summary';

interface MetadataField {
  metadataKey: string;
  valueType: 'text' | 'number' | 'boolean';
  useCount: number;
  lastUsed: string;
}

export function LeadImportModal({ isOpen, onClose, embedded = false }: LeadImportModalProps) {
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const [currentStep, setCurrentStep] = useState<ImportStep>('upload');
  const [csvFile, setCsvFile] = useState<File | null>(null);
  const [csvHeaders, setCsvHeaders] = useState<string[]>([]);
  const [filePath, setFilePath] = useState<string>('');
  const [metadataFields, setMetadataFields] = useState<MetadataField[]>([]);
  const [fieldMapping, setFieldMapping] = useState<Record<string, string>>({});
  const [metadataMapping, setMetadataMapping] = useState<Record<string, string>>({});
  const [previewData, setPreviewData] = useState<any[]>([]);
  const [importResults, setImportResults] = useState<{
    totalRecords: number;
    importedRecords: number;
    failedRecords: number;
    errors?: string[];
  } | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  // Reset state when modal is closed
  const handleClose = () => {
    setCurrentStep('upload');
    setCsvFile(null);
    setCsvHeaders([]);
    setFilePath('');
    setMetadataFields([]);
    setFieldMapping({});
    setMetadataMapping({});
    setPreviewData([]);
    setImportResults(null);
    setIsLoading(false);
    onClose();
  };

  // Handle file upload
  const handleFileUpload = async (file: File) => {
    try {
      setIsLoading(true);
      setCsvFile(file);
      
      const response = await api.leads.uploadCsv(file);
      
      if (response.success) {
        setCsvHeaders(response.headers);
        setFilePath(response.filePath);
        setMetadataFields(response.metadataFields);
        setCurrentStep('map');
      } else {
        toast({
          title: 'Upload Failed',
          description: 'Failed to process the CSV file.',
          variant: 'destructive',
        });
      }
    } catch (error) {
      console.error('Error uploading CSV:', error);
      toast({
        title: 'Upload Error',
        description: error instanceof Error ? error.message : 'An error occurred during upload',
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Handle field mapping
  const handleFieldMappingComplete = async (
    standardMapping: Record<string, string>,
    customMapping: Record<string, string>
  ) => {
    try {
      setIsLoading(true);
      setFieldMapping(standardMapping);
      setMetadataMapping(customMapping);
      
      // Preview the data with the mapping
      const response = await api.leads.previewCsvData({
        filePath,
        mapping: standardMapping,
        limit: 5
      });
      
      if (response.success) {
        setPreviewData(response.previewData);
        setCurrentStep('preview');
      } else {
        toast({
          title: 'Preview Failed',
          description: 'Failed to preview the mapped data.',
          variant: 'destructive',
        });
      }
    } catch (error) {
      console.error('Error previewing data:', error);
      toast({
        title: 'Preview Error',
        description: error instanceof Error ? error.message : 'An error occurred during preview',
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Handle import
  const handleImport = async () => {
    try {
      setIsLoading(true);
      setCurrentStep('import');
      
      const response = await api.leads.importCsvData({
        filePath,
        mapping: fieldMapping,
        metadataMapping
      });
      
      if (response.success) {
        setImportResults(response);
        // Invalidate leads query to refresh the list
        queryClient.invalidateQueries({ queryKey: ['leads'] });
        setCurrentStep('summary');
      } else {
        toast({
          title: 'Import Failed',
          description: 'Failed to import the leads.',
          variant: 'destructive',
        });
        setCurrentStep('preview');
      }
    } catch (error) {
      console.error('Error importing leads:', error);
      toast({
        title: 'Import Error',
        description: error instanceof Error ? error.message : 'An error occurred during import',
        variant: 'destructive',
      });
      setCurrentStep('preview');
    } finally {
      setIsLoading(false);
    }
  };

  // Create a new metadata field
  const handleCreateMetadataField = async (
    metadataKey: string,
    valueType: 'text' | 'number' | 'boolean'
  ) => {
    try {
      const response = await api.leads.createMetadataField({
        metadataKey,
        valueType
      });
      
      if (response.success) {
        setMetadataFields(response.metadataFields);
        toast({
          title: 'Success',
          description: 'Metadata field created successfully.',
        });
        return true;
      } else {
        toast({
          title: 'Failed',
          description: 'Failed to create metadata field.',
          variant: 'destructive',
        });
        return false;
      }
    } catch (error) {
      console.error('Error creating metadata field:', error);
      toast({
        title: 'Error',
        description: error instanceof Error ? error.message : 'An error occurred',
        variant: 'destructive',
      });
      return false;
    }
  };

  // Render the current step
  const renderStep = () => {
    switch (currentStep) {
      case 'upload':
        return (
          <FileUploader
            onFileUpload={handleFileUpload}
            isLoading={isLoading}
          />
        );
      case 'map':
        return (
          <FieldMapper
            csvHeaders={csvHeaders}
            metadataFields={metadataFields}
            onMappingComplete={handleFieldMappingComplete}
            onCreateMetadataField={handleCreateMetadataField}
            isLoading={isLoading}
          />
        );
      case 'preview':
        return (
          <ImportPreview
            previewData={previewData}
            onBack={() => setCurrentStep('map')}
            onImport={handleImport}
            isLoading={isLoading}
          />
        );
      case 'import':
        return (
          <ImportProgress />
        );
      case 'summary':
        return (
          <ImportSummary
            results={importResults}
            onClose={handleClose}
          />
        );
      default:
        return null;
    }
  };

  // Get the title for the current step
  const getStepTitle = () => {
    switch (currentStep) {
      case 'upload':
        return 'Upload CSV File';
      case 'map':
        return 'Map Fields';
      case 'preview':
        return 'Preview Import';
      case 'import':
        return 'Importing Leads';
      case 'summary':
        return 'Import Summary';
      default:
        return 'Import Leads';
    }
  };

  // Get the description for the current step
  const getStepDescription = () => {
    switch (currentStep) {
      case 'upload':
        return 'Select a CSV file containing lead data to import.';
      case 'map':
        return 'Map CSV columns to lead fields or create custom metadata fields.';
      case 'preview':
        return 'Review the data before importing.';
      case 'import':
        return 'Importing your leads. Please wait...';
      case 'summary':
        return 'Import completed.';
      default:
        return '';
    }
  };

  // If embedded, render just the content without the Dialog wrapper
  if (embedded) {
    return (
      <div className="p-6">
        <div className="mb-6">
          <h3 className="text-lg font-medium">{getStepTitle()}</h3>
          <p className="text-sm text-gray-500">{getStepDescription()}</p>
        </div>
        
        <div className="py-4">
          {renderStep()}
        </div>
        
        {currentStep !== 'import' && currentStep !== 'summary' && currentStep !== 'upload' && (
          <div className="flex justify-between pt-4">
            <Button
              type="button"
              variant="outline"
              onClick={() => setCurrentStep(currentStep === 'preview' ? 'map' : 'upload')}
              disabled={isLoading}
            >
              Back
            </Button>
          </div>
        )}
      </div>
    );
  }

  // Otherwise, render with the Dialog wrapper
  return (
    <Dialog open={isOpen} onOpenChange={(open) => !open && handleClose()}>
      <DialogContent className="sm:max-w-[800px] max-h-[90vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle>{getStepTitle()}</DialogTitle>
          <DialogDescription>{getStepDescription()}</DialogDescription>
        </DialogHeader>
        
        <div className="py-4">
          {renderStep()}
        </div>
        
        {currentStep !== 'import' && currentStep !== 'summary' && (
          <DialogFooter>
            {currentStep !== 'upload' && (
              <Button
                type="button"
                variant="outline"
                onClick={() => setCurrentStep(currentStep === 'preview' ? 'map' : 'upload')}
                disabled={isLoading}
              >
                Back
              </Button>
            )}
            <Button
              type="button"
              variant="outline"
              onClick={handleClose}
              disabled={isLoading}
            >
              Cancel
            </Button>
          </DialogFooter>
        )}
      </DialogContent>
    </Dialog>
  );
} 