import { Button } from '@/components/ui/button';
import { useWebSocket } from '@/contexts/WebSocketContext';
import { api } from '@/lib/api';
import { cn } from '@/lib/utils';
import { CallService } from '@/services/call.service';
import { SocketEvents } from '@/services/websocket.service';
import { useCallStore } from '@/store/callStore';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { AlertCircle, Brain, Phone, Sparkles } from 'lucide-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'sonner';
import { ActiveCall } from './ActiveCall';
import { CallHistory, type Call } from './CallHistory';

interface VoiceCallProps {
  phoneNumber: string;
  fromNumber?: string | null;
  contactName: string;
  leadId?: number | null;
  className?: string;
}

interface CallState {
  id: number;
  status: 'connecting' | 'ringing' | 'in-progress' | 'completed' | 'failed';
  direction: 'inbound' | 'outbound';
  contact: {
    name: string;
    phoneNumber: string;
  };
  error?: string;
  twilioCallSid?: string;
}

// Define the interface for the call result
interface CallResult {
  call: any; // Using any for now as we don't need the full Device.Call type
  callSid?: string;
}

export function VoiceCall({ phoneNumber, fromNumber, contactName, leadId, className }: VoiceCallProps) {
  const [activeCall, setActiveCall] = useState<CallState | null>(null);
  const [currentlyPlaying, setCurrentlyPlaying] = useState<number | null>(null);
  const [recentlyEndedCall, setRecentlyEndedCall] = useState<boolean>(false);
  const [isInitializing, setIsInitializing] = useState<boolean>(false);
  const [isAnalyzing, setIsAnalyzing] = useState<boolean>(false);
  const [activeCallId, setActiveCallId] = useState<number | null>(null);
  const audioRef = useRef<HTMLAudioElement>(null);
  const queryClient = useQueryClient();
  const { on } = useWebSocket();
  
  // Access the global call store
  const { 
    deviceId,
    activeCall: globalActiveCall,
    isGloballyAnswered
  } = useCallStore();

  // Function to notify other components about call status changes
  const notifyCallStatusChange = useCallback((status: 'started' | 'ended' | 'analyzing' | 'analyzed') => {
    // Dispatch a custom event that other components can listen for
    const event = new CustomEvent('call-status-change', { 
      detail: { 
        status,
        phoneNumber,
        leadId
      } 
    });
    window.dispatchEvent(event);
    
    // Always invalidate the lead query when call status changes
    if (leadId) {
      queryClient.invalidateQueries({ queryKey: ['lead', leadId] });
    }
  }, [phoneNumber, leadId, queryClient]);

  // Check for existing calls when component mounts and detect if another device is handling the call
  useEffect(() => {
    // Check if there's an active call in the CallService
    if (CallService.isInCall()) {
      console.log('[VoiceCall] Found existing call in CallService');
      
      // Get active call info
      const callInfo = CallService.getActiveCallInfo();
      console.log('[VoiceCall] Active call info:', callInfo);
      
      // If we have call info and it matches our phone number
      if (callInfo) {
        // Check if this call is related to the current phone number
        const callMatchesPhoneNumber = 
          (callInfo.from && callInfo.from.includes(phoneNumber)) || 
          (callInfo.to && callInfo.to.includes(phoneNumber));
        
        if (callMatchesPhoneNumber) {
          // Create a call state for the existing call
          const existingCall: CallState = {
            id: Date.now(),
            status: 'in-progress',
            direction: callInfo.direction === 'outbound' ? 'outbound' : 'inbound',
            contact: {
              name: contactName,
              phoneNumber: phoneNumber
            },
            twilioCallSid: callInfo.callSid || undefined
          };
          
          setActiveCall(existingCall);
          setIsAnalyzing(true);
          
          // Set up disconnect handler for the active call
          const handleDisconnect = () => {
            console.log('[VoiceCall] Existing call disconnected');
            setActiveCall(prev => prev ? { ...prev, status: 'completed' } : null);
            setRecentlyEndedCall(true);
            setIsAnalyzing(true);
            
            // Notify that call has ended and analysis started
            notifyCallStatusChange('analyzing');
            
            // Clear active call after a delay
            setTimeout(() => setActiveCall(null), 2000);
            
            // Fetch final call state
            queryClient.invalidateQueries({ queryKey: ['callHistory', phoneNumber] });
            
            // Refresh lead data to update any changes from the call
            if (leadId) {
              queryClient.invalidateQueries({ queryKey: ['lead', leadId] });
              queryClient.invalidateQueries({ queryKey: ['leads'] });
            }
          };
          
          // Try to set up disconnect handler on the active connection
          try {
            const activeConn = CallService.getActiveConnection();
            if (activeConn) {
              activeConn.on('disconnect', handleDisconnect);
            }
          } catch (error) {
            console.error('[VoiceCall] Error setting up disconnect handler:', error);
          }
          
          // Immediately fetch call history to try to find the call details
          queryClient.invalidateQueries({ queryKey: ['callHistory', phoneNumber] });
        }
      }
    }
    
    // Listen for global call state changes
    const handleStorageChange = (e: StorageEvent) => {
      // If a call is being handled by another device/tab
      if (e.key === 'call_ringing_device' && e.newValue && e.newValue !== deviceId) {
        // Check if the call is for this phone number (we'd need to store this in localStorage)
        console.log('[VoiceCall] Another device is handling an incoming call');
      }
    };
    
    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
    
  }, [contactName, phoneNumber, queryClient, leadId, notifyCallStatusChange, deviceId]);

  // Don't ring/handle this component if there's a global call being answered
  useEffect(() => {
    if (isGloballyAnswered && globalActiveCall) {
      // If this is the phone number being handled globally, disable this component's call UI
      if (globalActiveCall.from === phoneNumber || globalActiveCall.to === phoneNumber) {
        console.log('[VoiceCall] This number is being handled by the global call notification');
        // Don't show the call UI in this component while the global one is active
        if (activeCall) {
          setActiveCall(null);
        }
      }
    }
  }, [isGloballyAnswered, globalActiveCall, phoneNumber, activeCall]);

  // Fetch call history with real-time updates during active calls and shortly after
  const { data: callHistory, isLoading: isLoadingHistory, error: historyError, refetch: refetchHistory } = useQuery<Call[]>({
    queryKey: ['callHistory', phoneNumber],
    queryFn: async () => {
      try {
        const response = await api.twilio.getCallHistory(phoneNumber);
        return response.calls;
      } catch (error) {
        console.error('Error fetching call history:', error);
        throw error;
      }
    },
    // Only poll during active calls or when a call recently ended
    // During active analysis, rely on the CALL_ANALYSIS_COMPLETED socket event instead
    refetchInterval: (activeCall || recentlyEndedCall) ? 5000 : false,
    // Increase stale time to reduce unnecessary refetches
    staleTime: 30000, // 30 seconds
    // Make sure we don't refetch on window focus when not needed
    refetchOnWindowFocus: (activeCall || recentlyEndedCall) ? true : false,
    enabled: !!phoneNumber
  });

  // Subscribe to call status updates via WebSocket
  useEffect(() => {
    if (!phoneNumber) return;
    
    console.log('[VoiceCall] Setting up WebSocket subscription for call updates');
    
    // Subscribe to call history updates
    const unsubscribe = on<{
      leadId: number;
      callId: number;
      action: 'created' | 'updated' | 'deleted';
    }>(SocketEvents.CALL_HISTORY_UPDATE, (data) => {
      if (leadId === data.leadId) {
        console.log('[VoiceCall] Received call history update, refreshing data');
        refetchHistory();
      }
    });
    
    // Also subscribe to call status updates
    const unsubscribeStatus = on<{ 
      callSid: string; 
      status: string;
    }>(SocketEvents.CALL_STATUS_UPDATE, (data) => {
      console.log('[VoiceCall] Received call status update:', data);
      refetchHistory();
    });
    
    // Subscribe to call analysis completion updates
    const unsubscribeAnalysis = on<{
      callId: number;
      leadId: number;
    }>(SocketEvents.CALL_ANALYSIS_COMPLETED, (data) => {
      if (leadId === data.leadId) {
        console.log('[VoiceCall] Call analysis completed, updating lead data');
        
        // Remove this call from the set of calls being analyzed
        setCallsBeingAnalyzed(prev => {
          const updated = new Set(prev);
          updated.delete(data.callId);
          return updated;
        });
        
        // If this is the active call, reset the analyzing state
        if (activeCallId === data.callId) {
          setIsAnalyzing(false);
        }
        
        // Notify that analysis is complete
        notifyCallStatusChange('analyzed');
        
        // Refresh lead data when analysis is complete
        if (leadId) {
          // Invalidate lead queries
          queryClient.invalidateQueries({ queryKey: ['lead', leadId] });
          queryClient.invalidateQueries({ queryKey: ['leads'] });
          
          // Invalidate dashboard queries to refresh action items
          queryClient.invalidateQueries({ queryKey: ['dashboard', 'action-items'] });

          refetchHistory();
        }
      }
    });
    
    return () => {
      unsubscribe();
      unsubscribeStatus();
      unsubscribeAnalysis();
    };
  }, [on, phoneNumber, leadId, refetchHistory, notifyCallStatusChange, queryClient]);

  // Update activeCallId when call history changes and we have an active call
  useEffect(() => {
    if (activeCall?.twilioCallSid && callHistory?.length) {
      const matchingCall = callHistory.find(call => 
        // @ts-ignore - twilioCallSid might not be in the Call type but it exists in the API response
        call.twilioCallSid === activeCall.twilioCallSid || 
        (new Date(call.datetime).getTime() > Date.now() - 60000 && call.direction === 'outbound')
      );
      
      if (matchingCall) {
        setActiveCallId(matchingCall.id);
      }
    } else if (!activeCall && activeCallId) {
      // Clear active call ID when call ends
      setTimeout(() => {
        setActiveCallId(null);
      }, 5000); // Keep the active call ID a bit longer for UI purposes
    }
  }, [activeCall, callHistory, activeCallId]);

  // Track calls that are currently being analyzed
  const [callsBeingAnalyzed, setCallsBeingAnalyzed] = useState<Set<number>>(new Set());
  
  // Update the set of calls being analyzed
  useEffect(() => {
    if (!callHistory) return;
    
    // Find calls that have recordings but no analytics
    const analyzingCallIds = callHistory
      .filter(call => call.recordings?.length > 0 && !call.analytics?.length)
      .map(call => call.id);
    
    // Also include the activeCallId if we're in analyzing state
    if (isAnalyzing && activeCallId && !analyzingCallIds.includes(activeCallId)) {
      analyzingCallIds.push(activeCallId);
    }
    
    setCallsBeingAnalyzed(new Set(analyzingCallIds));
  }, [callHistory, isAnalyzing, activeCallId]);

  // Reset recentlyEndedCall after 15 seconds
  useEffect(() => {
    if (recentlyEndedCall) {
      const timer = setTimeout(() => {
        setRecentlyEndedCall(false);
        // Do one final refresh to ensure we have the latest data
        refetchHistory();
      }, 10000); // Reduce polling time to 10 seconds since we now rely on WebSocket events
      return () => clearTimeout(timer);
    }
  }, [recentlyEndedCall, refetchHistory]);

  // Reset isAnalyzing after 2 minutes if no analysis is found
  useEffect(() => {
    if (isAnalyzing && activeCallId) {
      const timer = setTimeout(() => {
        // Check if the call has analytics
        const call = callHistory?.find(c => c.id === activeCallId);
        if (call && call.analytics?.length) {
          // Analysis is complete
          setIsAnalyzing(false);
          
          // Notify that analysis is complete
          notifyCallStatusChange('analyzed');
          
          // Refresh lead data when analysis is complete
          if (leadId) {
            queryClient.invalidateQueries({ queryKey: ['lead', leadId] });
            queryClient.invalidateQueries({ queryKey: ['leads'] });
          }
        } else if (!activeCall) {
          // No active call and no analytics after timeout
          setIsAnalyzing(false);
          notifyCallStatusChange('ended');
        }
      }, 120000); // 2 minutes
      return () => clearTimeout(timer);
    }
  }, [isAnalyzing, activeCallId, callHistory, activeCall, leadId, queryClient, notifyCallStatusChange]);

  // Handle making a call
  const handleCall = useCallback(async () => {
    if (!phoneNumber) {
      toast.error('Phone number is required');
      return;
    }

    if (!fromNumber) {
      toast.error('Please select a phone number to call from');
      return;
    }
    
    // Don't allow making a call if there's a global call in progress
    if (isGloballyAnswered) {
      toast.error('There is already a call in progress');
      return;
    }

    try {
      setIsInitializing(true);
      
      // Create initial call state
      const newCall: CallState = {
        id: Date.now(), // Temporary ID until we get the real one
        status: 'connecting',
        direction: 'outbound',
        contact: {
          name: contactName,
          phoneNumber: phoneNumber
        }
      };
      setActiveCall(newCall);

      const callResult = await CallService.makeCall({
        phoneNumber,
        fromNumber,
        onConnecting: () => {
          setIsInitializing(false);
          setActiveCall(prev => prev ? { ...prev, status: 'connecting' } : null);
        },
        onConnected: (callSid?: string) => {
          setActiveCall(prev => prev ? { 
            ...prev, 
            status: 'in-progress',
            twilioCallSid: callSid 
          } : null);
          // Start analyzing state
          setIsAnalyzing(true);
          // Notify that call has started
          notifyCallStatusChange('started');
          // Immediately fetch updated call history
          queryClient.invalidateQueries({ queryKey: ['callHistory', phoneNumber] });
        },
        onDisconnected: () => {
          setActiveCall(prev => prev ? { ...prev, status: 'completed' } : null);
          // Set recently ended flag to continue polling
          setRecentlyEndedCall(true);
          // Keep analyzing state active
          setIsAnalyzing(true);
          // Notify that call has ended and analysis started
          notifyCallStatusChange('analyzing');
          // Clear active call after a delay
          setTimeout(() => setActiveCall(null), 2000);
          // Fetch final call state
          queryClient.invalidateQueries({ queryKey: ['callHistory', phoneNumber] });
          
          // Refresh lead data to update any changes from the call
          if (leadId) {
            queryClient.invalidateQueries({ queryKey: ['lead', leadId] });
            // Also invalidate the leads list to update any lead scores or statuses
            queryClient.invalidateQueries({ queryKey: ['leads'] });
          }
        },
        onError: (error) => {
          console.error('Call error:', error);
          setActiveCall(prev => prev ? { 
            ...prev, 
            status: 'failed',
            error: error.message 
          } : null);
          
          // Clear active call after a delay
          setTimeout(() => setActiveCall(null), 5000);
          setIsAnalyzing(false);
          
          // Notify that call has ended with error
          notifyCallStatusChange('ended');
          
          // Show toast with error message
          toast.error(`Call failed: ${error.message}`);
        }
      });
      
      // If we get a call SID back, update the active call
      if (callResult?.callSid) {
        setActiveCall(prev => prev ? { 
          ...prev, 
          twilioCallSid: callResult.callSid 
        } : null);
      }
    } catch (error) {
      console.error('Error initiating call:', error);
      setIsInitializing(false);
      setIsAnalyzing(false);
      
      const errorMessage = error instanceof Error ? error.message : 'Unknown error';
      setActiveCall(prev => prev ? { 
        ...prev, 
        status: 'failed',
        error: errorMessage
      } : null);
      
      // Notify that call has ended with error
      notifyCallStatusChange('ended');
      
      toast.error(`Failed to initiate call: ${errorMessage}`);
      setTimeout(() => setActiveCall(null), 5000);
    }
  }, [phoneNumber, fromNumber, contactName, queryClient, leadId, notifyCallStatusChange, isGloballyAnswered]);

  // Handle hanging up
  const handleHangup = useCallback(() => {
    try {
      CallService.disconnectAll();
      setActiveCall(prev => prev ? { ...prev, status: 'completed' } : null);
      // Keep analyzing state active
      setIsAnalyzing(true);
      // Notify that call has ended and analysis started
      notifyCallStatusChange('analyzing');
      setTimeout(() => setActiveCall(null), 2000);
      
      // Refresh lead data
      if (leadId) {
        queryClient.invalidateQueries({ queryKey: ['lead', leadId] });
        queryClient.invalidateQueries({ queryKey: ['leads'] });
      }
    } catch (error) {
      console.error('Error hanging up call:', error);
      toast.error('Failed to hang up call');
      // Notify that call has ended with error
      notifyCallStatusChange('ended');
    }
  }, [queryClient, leadId, notifyCallStatusChange]);

  // Handle playing recording
  const handlePlayRecording = useCallback((recordingId: number, recordingUrl: string) => {
    if (audioRef.current) {
      if (currentlyPlaying === recordingId) {
        // Stop playing
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        setCurrentlyPlaying(null);
      } else {
        // Start playing new recording
        audioRef.current.src = recordingUrl;
        audioRef.current.play().catch(error => {
          console.error('Error playing audio:', error);
          toast.error('Failed to play recording');
        });
        setCurrentlyPlaying(recordingId);
      }
    }
  }, [currentlyPlaying]);

  // Handle audio ended
  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      const handleEnded = () => setCurrentlyPlaying(null);
      audio.addEventListener('ended', handleEnded);
      return () => audio.removeEventListener('ended', handleEnded);
    }
  }, []);

  // Handle retry for call history
  const handleRetryHistory = useCallback(() => {
    refetchHistory();
  }, [refetchHistory]);

  return (
    <div className="space-y-6">
      {/* Audio Player */}
      <audio ref={audioRef} className="hidden" controls />

      {/* Active Call or Call Button */}
      {activeCall ? (
        <ActiveCall 
          call={activeCall}
          onHangup={handleHangup}
        />
      ) : (
        <div className="flex flex-col items-center gap-4">
          <div className="relative">
            {fromNumber && (
              <div className="absolute -inset-1 bg-gradient-to-r from-violet-600 to-indigo-600 rounded-lg blur opacity-75 group-hover:opacity-100 transition duration-1000 group-hover:duration-200 animate-tilt"></div>
            )}
            <Button
              className={cn(
                "relative px-8 py-6 bg-gradient-to-br from-indigo-50 to-white dark:from-gray-900 dark:to-gray-800 border border-indigo-100 dark:border-gray-700 shadow-xl hover:shadow-2xl transition-all duration-300 hover:scale-105 text-gray-900 dark:text-white",
                !fromNumber && "opacity-70 hover:scale-100 hover:shadow-xl",
                isInitializing && "opacity-70 cursor-not-allowed",
                className
              )}
              size="lg"
              onClick={handleCall}
              disabled={!fromNumber || isInitializing}
            >
              <div className="flex items-center gap-3">
                <div className="relative">
                  <Phone className={cn("h-5 w-5 text-indigo-600 dark:text-indigo-400", isInitializing && "animate-pulse")} />
                  <Sparkles className="absolute -top-1 -right-1 h-3 w-3 text-yellow-500 animate-pulse" />
                </div>
                <span className="font-medium">
                  {isInitializing ? 'Initializing...' : fromNumber ? 'Start Call' : 'Select a Number'}
                </span>
              </div>
            </Button>
          </div>
          <div className="flex items-center gap-2 text-xs text-muted-foreground">
            <Brain className="h-3 w-3" />
            <span>AI-powered call analysis</span>
          </div>
        </div>
      )}

      {/* Call History */}
      <div className="space-y-4 relative">
        <div className="flex items-center justify-between px-4">
          <h3 className="text-lg font-semibold mr-4">Call History</h3>
          <div className="text-xs text-muted-foreground">
            Powered by AI Analysis
          </div>
        </div>
        {/* Frosted glass effect overlay */}
        <div className="absolute top-[60px] left-0 right-0 h-8 bg-gradient-to-b from-background to-transparent pointer-events-none z-10" />
        
        {isLoadingHistory ? (
          <div className="flex items-center justify-center py-8 bg-muted/5 rounded-lg border border-dashed mx-4">
            <div className="flex flex-col items-center">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary mb-2"></div>
              <p className="text-muted-foreground">Loading call history...</p>
            </div>
          </div>
        ) : historyError ? (
          <div className="flex flex-col items-center justify-center py-8 bg-muted/5 rounded-lg border border-dashed mx-4 text-center">
            <AlertCircle className="h-8 w-8 text-destructive mb-2" />
            <p className="text-muted-foreground">Failed to load call history</p>
            <Button 
              variant="outline" 
              size="sm" 
              className="mt-2"
              onClick={handleRetryHistory}
            >
              Retry
            </Button>
          </div>
        ) : callHistory && callHistory.length > 0 ? (
          <CallHistory 
            calls={callHistory}
            onPlayRecording={handlePlayRecording}
            currentlyPlaying={currentlyPlaying}
            activeCallId={activeCallId}
            isAnalyzing={isAnalyzing}
            callsBeingAnalyzed={callsBeingAnalyzed}
            className="h-[calc(90vh-470px)] lg:h-[calc(100vh-500px)] px-4"
          />
        ) : (
          <div className="text-center text-muted-foreground py-8 bg-muted/5 rounded-lg border border-dashed mx-4">
            <Phone className="h-8 w-8 mx-auto mb-2 opacity-50" />
            <p>No calls yet</p>
            <p className="text-sm">Start your first AI-powered call</p>
          </div>
        )}
      </div>
    </div>
  );
} 