/**
 * Utility functions for interacting with the TMS Leads API through our server proxy
 */
import { axiosInstance } from '@/lib/api';
import { toast } from 'sonner';

/**
 * Helper function to check if the response indicates a token invalidation
 * If so, it will disconnect the integration and refresh the page
 * @param response The API response to check
 * @returns True if the response indicates a token invalidation, false otherwise
 */
function handleTokenInvalidation(response: any): boolean {
  if (!response.success && response.code === 'TMS_TOKEN_INVALID') {
    // Show a toast message to inform the user
    toast.error('TMS Leads Integration Disconnected', {
      description: response.description || 'Your TMS Leads token is no longer valid. The integration has been disconnected.',
      duration: 5000
    });
    
    // Wait a moment for the toast to be visible, then refresh the page
    setTimeout(() => {
      window.location.reload();
    }, 2000);
    
    return true;
  }
  return false;
}

/**
 * Get the current balance from TMS Leads
 * @returns Promise with the balance response
 */
export async function getBalance(): Promise<{ success: boolean; balance?: number; message?: string; code?: string; description?: string }> {
  try {
    const response = await axiosInstance.get('/integrations/tms-leads/balance');
    
    // Check for token invalidation
    if (handleTokenInvalidation(response.data)) {
      return response.data;
    }
    
    return response.data;
  } catch (error: any) {
    console.error('Error fetching TMS Leads balance:', error);
    return { 
      success: false, 
      message: error.response?.data?.message || error.message || 'Unknown error occurred' 
    };
  }
}

/**
 * Get orders from TMS Leads
 * @returns Promise with the orders response
 */
export async function getOrders(): Promise<{ success: boolean; orders?: any[]; message?: string; code?: string; description?: string }> {
  try {
    const response = await axiosInstance.get('/integrations/tms-leads/orders');
    
    // Check for token invalidation
    if (handleTokenInvalidation(response.data)) {
      return response.data;
    }
    
    return response.data;
  } catch (error: any) {
    console.error('Error fetching TMS Leads orders:', error);
    return { 
      success: false, 
      message: error.response?.data?.message || error.message || 'Unknown error occurred' 
    };
  }
}

/**
 * Get available prices from TMS Leads
 * @returns Promise with the prices response
 */
export async function getPrices(): Promise<{ success: boolean; prices?: any[]; message?: string; code?: string; description?: string }> {
  try {
    const response = await axiosInstance.get('/integrations/tms-leads/prices');
    
    // Check for token invalidation
    if (handleTokenInvalidation(response.data)) {
      return response.data;
    }
    
    return response.data;
  } catch (error: any) {
    console.error('Error fetching TMS Leads prices:', error);
    return { 
      success: false, 
      message: error.response?.data?.message || error.message || 'Unknown error occurred' 
    };
  }
}

/**
 * Create a new order in TMS Leads
 * @param orderData The order data to submit
 * @returns Promise with the order creation response
 */
export async function createOrder(orderData: {
  type: string;
  quantity: number;
  locations: Array<{ state?: string; county?: string; city?: string; }>;
  group_leads?: boolean;
  min_emp?: number | null;
  max_emp?: number | null;
  lead_type?: 'health' | 'life';
}): Promise<{ success: boolean; order?: any; message?: string; code?: string; description?: string }> {
  try {
    const response = await axiosInstance.post('/integrations/tms-leads/orders', orderData);
    
    // Check for token invalidation
    if (handleTokenInvalidation(response.data)) {
      return response.data;
    }
    
    return response.data;
  } catch (error: any) {
    console.error('Error creating TMS Leads order:', error);
    return { 
      success: false, 
      message: error.response?.data?.message || error.message || 'Unknown error occurred' 
    };
  }
}

/**
 * Create a support ticket in TMS Leads
 * @param ticketData The ticket data to submit
 * @returns Promise with the ticket creation response
 */
export async function createSupportTicket(ticketData: {
  subject: string;
  message: string;
  content?: string;
  tms_order_id?: string | number;
}): Promise<{ success: boolean; message?: string; code?: string; description?: string }> {
  try {
    // Ensure we're sending the expected fields
    const payload = {
      subject: ticketData.subject,
      content: ticketData.content || ticketData.message,
      tms_order_id: ticketData.tms_order_id
    };
    
    // Send the request with the correct payload structure
    const response = await axiosInstance.post('/integrations/tms-leads/support', payload);
    
    // Check for token invalidation
    if (handleTokenInvalidation(response.data)) {
      return response.data;
    }
    
    return response.data;
  } catch (error: any) {
    console.error('Error creating TMS Leads support ticket:', error);
    return { 
      success: false, 
      message: error.response?.data?.message || error.message || 'Unknown error occurred' 
    };
  }
}

/**
 * Validate a TMS Leads token
 * @param token The token to validate
 * @param settings Optional settings to save with the token
 * @returns Promise with the validation response
 */
export async function validateToken(token: string, settings?: Record<string, any>): Promise<{ success: boolean; balance?: number; message?: string; code?: string; description?: string }> {
  try {
    const response = await axiosInstance.post('/integrations/tms-leads/validate', { token, settings });
    
    // Check for token invalidation
    if (handleTokenInvalidation(response.data)) {
      return response.data;
    }
    
    return response.data;
  } catch (error: any) {
    console.error('Error validating TMS Leads token:', error);
    return { 
      success: false, 
      message: error.response?.data?.message || error.message || 'Unknown error occurred' 
    };
  }
}

/**
 * Get the TMS Leads token and settings for the current user
 * @returns Promise with the token and settings
 */
export async function getTokenAndSettings(): Promise<{ success: boolean; token?: string; settings?: Record<string, any>; message?: string; code?: string; description?: string }> {
  try {
    const response = await axiosInstance.get('/integrations/tms-leads/token');
    
    // Check for token invalidation
    if (handleTokenInvalidation(response.data)) {
      return response.data;
    }
    
    return response.data;
  } catch (error: any) {
    console.error('Error fetching TMS Leads token:', error);
    return { 
      success: false, 
      message: error.response?.data?.message || error.message || 'Unknown error occurred' 
    };
  }
}

/**
 * Update the TMS Leads integration settings
 * @param settings The settings to update
 * @returns Promise with the update response
 */
export async function updateSettings(settings: Record<string, any>): Promise<{ success: boolean; message?: string; code?: string; description?: string }> {
  try {
    const response = await axiosInstance.put('/integrations/tms-leads/settings', { settings });
    
    // Check for token invalidation
    if (handleTokenInvalidation(response.data)) {
      return response.data;
    }
    
    return response.data;
  } catch (error: any) {
    console.error('Error updating TMS Leads settings:', error);
    return { 
      success: false, 
      message: error.response?.data?.message || error.message || 'Unknown error occurred' 
    };
  }
}

/**
 * Disconnect the TMS Leads integration
 * @returns Promise with the disconnect response
 */
export async function disconnectIntegration(): Promise<{ success: boolean; message?: string; code?: string; description?: string }> {
  try {
    const response = await axiosInstance.delete('/integrations/tms-leads/token');
    return response.data;
  } catch (error: any) {
    console.error('Error disconnecting TMS Leads integration:', error);
    return { 
      success: false, 
      message: error.response?.data?.message || error.message || 'Unknown error occurred' 
    };
  }
}

/**
 * Get a count of available leads from TMS Leads
 * @param options Filter options for the leads count
 * @returns Promise with the count response
 */
export async function getLeadsCount(options?: {
  startDate?: string;
  endDate?: string;
  status?: string[];
}): Promise<{
  success: boolean;
  count?: number;
  message?: string;
  code?: string;
  description?: string;
}> {
  try {
    const response = await axiosInstance.post('/integrations/tms-leads/leads/count', options || {});
    
    // Check for token invalidation
    if (handleTokenInvalidation(response.data)) {
      return response.data;
    }
    
    return response.data;
  } catch (error: any) {
    console.error('Error fetching TMS Leads count:', error);
    return { 
      success: false, 
      message: error.response?.data?.message || error.message || 'Unknown error occurred' 
    };
  }
}

/**
 * Import leads from TMS Leads
 * @param options Import options
 * @returns Promise with the import response
 */
export async function importLeads(options: {
  startDate?: string;
  endDate?: string;
  status?: string[];
  importAll?: boolean;
}): Promise<{
  success: boolean;
  importedCount?: number;
  message?: string;
  code?: string;
  description?: string;
}> {
  try {
    const response = await axiosInstance.post('/integrations/tms-leads/leads/import', options);
    
    // Check for token invalidation
    if (handleTokenInvalidation(response.data)) {
      return response.data;
    }
    
    return response.data;
  } catch (error: any) {
    console.error('Error importing TMS Leads:', error);
    return { 
      success: false, 
      message: error.response?.data?.message || error.message || 'Unknown error occurred' 
    };
  }
} 