import { useWebSocket } from '@/contexts/WebSocketContext';
import { aiReceptionistKeys } from '@/services/ai-receptionist';
import { SocketEvents } from '@/services/websocket.service';
import { useAuthStore } from '@/store/authStore';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';
import { toast } from 'sonner';

export function WebSocketAIReceptionistNotification() {
  const { on } = useWebSocket();
  const queryClient = useQueryClient();
  const currentUser = useAuthStore((state) => state.user);
  // Track recently shown notifications to prevent duplicates
  const recentNotifications = useRef<Set<string>>(new Set());
  const NOTIFICATION_DEBOUNCE_MS = 2000; // 2 seconds

  useEffect(() => {
    if (!currentUser) {
      console.log('[WebSocketAIReceptionistNotification] No current user, skipping setup');
      return;
    }

    console.log(`[WebSocketAIReceptionistNotification] Setting up listener for user ${currentUser.id}`);

    const unsubscribe = on<{
      callId: string;
      phoneNumber: string;
      summary?: string;
      leadScore?: number;
      duration?: number;
      status?: string;
    }>(SocketEvents.AI_RECEPTIONIST_CALL_COMPLETED, (data) => {
      console.log('[WebSocketAIReceptionistNotification] Received AI receptionist call completed event:', data);

      // Create a unique key for this notification
      const notificationKey = `${data.callId}-${Date.now()}`;

      // If we've shown this notification recently, skip it
      if (recentNotifications.current.has(notificationKey)) {
        console.log('[WebSocketAIReceptionistNotification] Skipping duplicate notification:', notificationKey);
        return;
      }

      // Show a notification
      toast.info(`Receptionist Call Completed`, {
        description: `Call with ${data.phoneNumber} has been completed${data.summary ? ' and transcribed' : ''}`
      });

      // Add to recent notifications
      recentNotifications.current.add(notificationKey);

      // Remove from recent notifications after debounce period
      setTimeout(() => {
        recentNotifications.current.delete(notificationKey);
      }, NOTIFICATION_DEBOUNCE_MS);

      // Invalidate the relevant queries to refresh the data
      console.log('[WebSocketAIReceptionistNotification] Invalidating AI receptionist queries');
      
      // First, invalidate all AI receptionist queries to ensure everything is refreshed
      queryClient.invalidateQueries({ 
        queryKey: aiReceptionistKeys.all,
        refetchType: 'all'
      });
      
      // Then specifically target the ones we know need to be updated
      queryClient.invalidateQueries({ queryKey: aiReceptionistKeys.calls() });
      
      // Force an immediate refetch of the metrics
      queryClient.invalidateQueries({ 
        queryKey: aiReceptionistKeys.metrics(),
        refetchType: 'active',
        exact: false
      });
      
      queryClient.invalidateQueries({ queryKey: aiReceptionistKeys.activeCalls() });
      
      // If we have a specific call ID, also invalidate that call's data
      if (data.callId) {
        queryClient.invalidateQueries({ queryKey: aiReceptionistKeys.call(data.callId) });
      }
    });

    return () => {
      console.log('[WebSocketAIReceptionistNotification] Cleaning up listener');
      unsubscribe();
    };
  }, [on, currentUser, queryClient]);

  return null;
} 