import { LeadStatus } from '@/types';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface LeadFilter {
  search: string;
  type: 'all' | 'aged' | 'prime';
  status: LeadStatus | 'all';
  sortBy: 'leadScore' | 'lastContacted' | 'createdAt' | 'updatedAt' | 'name';
  sortOrder: 'asc' | 'desc';
}

// Unified selected lead interface that includes all lead information
interface SelectedLead {
  id: number | null;
  name: string | null;
  email: string | null;
  phone: string | null;
  // Location information (previously in separate object)
  city: string | null;
  state: string | null;
  county: string | null;
  zip: string | null;
  // Additional fields that might be useful for templates
  businessName: string | null;
  title: string | null;
}

interface LeadStore {
  filter: LeadFilter;
  selectedLead: SelectedLead;
  setFilter: (filter: Partial<LeadFilter>) => void;
  setSelectedLead: (lead: Partial<SelectedLead>) => void;
  resetFilter: () => void;
  resetSelectedLead: () => void;
  // Name utility functions
  getFirstName: () => string;
  getLastName: () => string;
}

const DEFAULT_FILTER: LeadFilter = {
  search: '',
  type: 'all',
  status: 'all',
  sortBy: 'createdAt',
  sortOrder: 'desc',
};

const DEFAULT_SELECTED_LEAD: SelectedLead = {
  id: null,
  name: null,
  email: null,
  phone: null,
  city: null,
  state: null,
  county: null,
  zip: null,
  businessName: null,
  title: null,
};

export const useLeadStore = create<LeadStore>()(
  persist(
    (set, get) => ({
      filter: DEFAULT_FILTER,
      selectedLead: DEFAULT_SELECTED_LEAD,
      setFilter: (newFilter) =>
        set((state) => ({
          filter: { ...state.filter, ...newFilter },
        })),
      setSelectedLead: (lead) =>
        set((state) => ({
          selectedLead: { ...state.selectedLead, ...lead },
        })),
      resetFilter: () => set({ filter: DEFAULT_FILTER }),
      resetSelectedLead: () => set({ selectedLead: DEFAULT_SELECTED_LEAD }),
      // Name utility functions
      getFirstName: () => {
        const { selectedLead } = get();
        if (!selectedLead.name) return '';
        
        // Extract first name (everything before the first space)
        const nameParts = selectedLead.name.trim().split(' ');
        return nameParts[0] || '';
      },
      getLastName: () => {
        const { selectedLead } = get();
        if (!selectedLead.name) return '';
        
        // Extract last name (everything after the first space)
        const nameParts = selectedLead.name.trim().split(' ');
        return nameParts.length > 1 ? nameParts[nameParts.length - 1] : '';
      },
    }),
    {
      name: 'lead-filters',
      // Only persist these specific values
      partialize: (state) => ({
        filter: {
          type: state.filter.type,
          status: state.filter.status,
          sortBy: state.filter.sortBy,
          sortOrder: state.filter.sortOrder,
        },
        selectedLead: state.selectedLead,
      }),
    }
  )
); 