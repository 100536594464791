import { Button } from '@/components/ui/button';
import { CallService } from '@/services/call.service';
import { AlertCircle, Asterisk, Hash, Info } from 'lucide-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'sonner';

// Import or define TwilioCall interface
interface TwilioCall {
  parameters: Record<string, string | undefined>;
  customParameters: Map<string, string>;
  direction: 'INCOMING' | 'OUTGOING';
  status(): string;
  sendDigits(digits: string): void;
  [key: string]: any; // Allow any other properties that might exist
}

interface DialPadProps {
  className?: string;
  onClose?: () => void;
}

export function DialPad({ className, onClose }: DialPadProps) {
  const digits = [
    '1', '2', '3',
    '4', '5', '6',
    '7', '8', '9',
    '*', '0', '#'
  ];
  
  const [lastDigit, setLastDigit] = useState<string | null>(null);
  const [canSendDigits, setCanSendDigits] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [dtmfStatus, setDtmfStatus] = useState<'checking' | 'available' | 'unavailable' | 'error'>('checking');
  const checkTimerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  // Check call status on mount and periodically
  useEffect(() => {
    const checkConnection = () => {
      try {
        const isInCall = CallService.isInCall();
        const connection = CallService.getActiveConnection();
        
        if (!isInCall || !connection) {
          setCanSendDigits(false);
          setDtmfStatus('unavailable');
          setErrorMessage("No active call detected");
          return;
        }
        
        // Create a type-safe way to check for alternative methods
        const hasAltMethods = () => {
          // Using type assertion to avoid TypeScript errors
          const conn = connection as any;
          return typeof conn.send_digit === 'function' || 
                 typeof conn.sendDtmf === 'function';
        };
        
        // Per Twilio docs, sendDigits is the standard method
        // https://www.twilio.com/docs/voice/sdks/javascript/twiliocall#callsenddigitsdigits
        if (typeof connection.sendDigits === 'function') {
          setCanSendDigits(true);
          setDtmfStatus('available');
          setErrorMessage(null);
        } 
        // Check for alternative methods
        else if (hasAltMethods()) {
          setCanSendDigits(true);
          setDtmfStatus('available');
          setErrorMessage(null);
        } 
        // No DTMF method available
        else {
          setCanSendDigits(false);
          setDtmfStatus('unavailable');
          setErrorMessage("This call doesn't support sending digits");
          
          // Log the connection methods for debugging - use any to avoid type issues
          const conn = connection as any;
          const methodNames = Object.getOwnPropertyNames(conn)
            .filter(prop => typeof conn[prop] === 'function');
          console.log('[DialPad] Available methods on connection:', methodNames);
        }
      } catch (err) {
        console.error('[DialPad] Error checking call status:', err);
        setCanSendDigits(false);
        setDtmfStatus('error');
        setErrorMessage("Error accessing call features");
      }
    };
    
    // Initial check
    checkConnection();
    
    // Set up periodic checking
    const intervalId = setInterval(checkConnection, 3000);
    
    return () => {
      clearInterval(intervalId);
      if (checkTimerRef.current) {
        clearTimeout(checkTimerRef.current);
      }
    };
  }, []);

  const sendDigit = useCallback((digit: string) => {
    // Visual feedback immediately
    setLastDigit(digit);
    
    try {
      // Check if there's an active call
      if (!CallService.isInCall()) {
        toast.error('No active call to send digit to');
        setCanSendDigits(false);
        setDtmfStatus('unavailable');
        setErrorMessage("No active call detected");
        setTimeout(() => setLastDigit(null), 300);
        return;
      }
      
      // Try to send the digit
      const success = CallService.sendDigit(digit);
      
      if (!success) {
        // Get more details about the failure
        const connection = CallService.getActiveConnection();
        
        if (!connection) {
          setErrorMessage("Call connection not found");
        } 
        else if (connection.status && connection.status() !== 'open') {
          setErrorMessage(`Call not ready (status: ${connection.status()})`);
        } 
        else {
          setErrorMessage("Failed to send digit to call");
        }
        
        toast.error('Failed to send digit');
        setDtmfStatus('error');
        setCanSendDigits(false);
      } else {
        // Success! Clear any error states
        setErrorMessage(null);
        setDtmfStatus('available');
        setCanSendDigits(true);
      }
    } catch (err) {
      console.error('[DialPad] Error sending digit:', err);
      setErrorMessage("Unexpected error sending digit");
      setDtmfStatus('error');
      toast.error('Error sending digit');
    } finally {
      // Always clear the visual feedback after a delay
      setTimeout(() => setLastDigit(null), 300);
    }
  }, []);

  return (
    <div className={`bg-background rounded-lg shadow-lg border p-4 ${className}`}>
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-medium">Dial Pad</h3>
        <Button variant="ghost" size="sm" onClick={onClose}>×</Button>
      </div>

      {errorMessage && (
        <div className="text-sm text-amber-500 dark:text-amber-400 mb-3 p-2 bg-amber-50 dark:bg-amber-950/20 rounded-md flex items-center gap-2">
          <AlertCircle className="h-4 w-4 shrink-0" />
          <p>{errorMessage}</p>
        </div>
      )}

      {dtmfStatus === 'checking' && (
        <div className="text-sm text-blue-500 dark:text-blue-400 mb-3 p-2 bg-blue-50 dark:bg-blue-950/20 rounded-md flex items-center gap-2">
          <Info className="h-4 w-4 shrink-0" />
          <p>Checking digit sending capability...</p>
        </div>
      )}

      <div className="grid grid-cols-3 gap-2">
        {digits.map((digit) => (
          <Button
            key={digit}
            variant="outline"
            className={`h-12 w-full text-lg font-medium hover:bg-muted ${lastDigit === digit ? 'bg-primary/20 text-primary' : ''}`}
            onClick={() => sendDigit(digit)}
            disabled={!canSendDigits || dtmfStatus !== 'available'}
          >
            {digit === '*' ? <Asterisk className="h-4 w-4" /> : 
             digit === '#' ? <Hash className="h-4 w-4" /> : 
             digit}
          </Button>
        ))}
      </div>
    </div>
  );
}