import { api } from '@/lib/api';
import { useQuery } from '@tanstack/react-query';

interface SearchNumbersParams {
  areaCode?: string;
  state?: string;
  city?: string;
}

interface AvailablePhoneNumber {
  phoneNumber: string;
  friendlyName: string;
  locality: string | null;
  region: string | null;
  price: string;
}

interface AvailableNumbersResponse {
  numbers: AvailablePhoneNumber[];
  message?: string;
}

export const useAvailableNumbers = (params: SearchNumbersParams) => {
  // Only enable area code search when it's exactly 3 digits
  const shouldSearchAreaCode = params.areaCode && params.areaCode.length === 3;

  return useQuery<AvailableNumbersResponse>({
    queryKey: ['availableNumbers', params],
    queryFn: () => {
      if (!shouldSearchAreaCode && !params.state) {
        return Promise.resolve({
          numbers: [],
          message: params.areaCode 
            ? 'Please enter a complete 3-digit area code'
            : 'Either area code or state is required'
        });
      }
      return api.did.searchNumbers(params);
    },
    enabled: !!(shouldSearchAreaCode || params.state),
    staleTime: 10_000, // Consider data stale after 10 seconds
    gcTime: 30_000, // Keep in cache for 30 seconds
    refetchOnMount: 'always' as const, // Always refetch on mount
    refetchOnWindowFocus: true, // Refetch when window regains focus
  });
};

export const useUserNumbers = () => {
  return useQuery({
    queryKey: ['userNumbers'],
    queryFn: () => api.did.listNumbers()
  });
}; 