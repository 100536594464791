import { TeamInvitationsList } from '@/components/teams/TeamInvitationsList';
import { TeamInviteDialog } from '@/components/teams/TeamInviteDialog';
import { TeamMembersList } from '@/components/teams/TeamMembersList';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useTeamInvitations } from '@/hooks/useTeamInvitations';
import { useTeams } from '@/hooks/useTeams';
import { useAuthStore } from '@/store/authStore';
import { updateTeamSchema } from '@/types/team';
import { useRouter, useSearch } from '@tanstack/react-router';
import { AlertTriangle, UserPlus, XCircle } from 'lucide-react';
import { useEffect, useState } from 'react';
import { toast } from 'sonner';
import { ZodError } from 'zod';

export function TeamSettings() {
  const { 
    currentTeam, 
    teams, 
    deleteTeam, 
    isDeletingTeam,
    updateTeam,
    isUpdatingTeam
  } = useTeams();
  const { invitations } = useTeamInvitations();
  const router = useRouter();
  const search = useSearch({ from: '/settings' });
  const [teamName, setTeamName] = useState(currentTeam?.name || '');
  const [validationError, setValidationError] = useState<string | null>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [inviteDialogOpen, setInviteDialogOpen] = useState(false);
  const currentUserId = useAuthStore(state => state.user?.id);
  
  // Update form values when currentTeam changes
  useEffect(() => {
    if (currentTeam) {
      setTeamName(currentTeam.name || '');
      setValidationError(null);
    }
  }, [currentTeam]);
  
  // Default to the "general" tab
  const defaultTab = "general";
  
  const hasInvitations = invitations && invitations.length > 0;
  
  // Handle saving team settings
  const handleSaveSettings = () => {
    if (!currentTeam) return;
    
    try {
      // Validate the team name
      updateTeamSchema.parse({ name: teamName });
      
      // Clear any previous validation errors
      setValidationError(null);
      
      // Update the team
      updateTeam(
        {
          teamId: currentTeam.id,
          data: { name: teamName.trim() }
        }
      );
    } catch (error) {
      if (error instanceof ZodError) {
        setValidationError(error.errors[0].message);
        toast.error('Validation Error', {
          description: error.errors[0].message
        });
      } else {
        setValidationError('An unexpected error occurred');
        toast.error('Error', {
          description: 'An unexpected error occurred while saving'
        });
      }
    }
  };

  // Handle team deletion
  const handleDeleteTeam = () => {
    if (!currentTeam) return;
    
    deleteTeam(currentTeam.id, {
      onSuccess: () => {
        setDeleteDialogOpen(false);
        // Navigate to the dashboard after successful deletion
        router.navigate({ to: '/' });
      }
    });
  };

  if (!currentTeam) {
    return (
      <Card>
        <CardHeader>
          <CardTitle>No Team Selected</CardTitle>
          <CardDescription>
            Please select a team from the sidebar to manage its settings.
          </CardDescription>
        </CardHeader>
      </Card>
    );
  }

  const isAdmin = currentTeam.role === 'admin';
  const isOwner = currentTeam.userId === currentUserId;

  return (
    <div className="space-y-8">
      <div className="flex items-center justify-between pb-6 mb-6 border-b">
        <div>
          <h2 className="text-2xl font-semibold tracking-tight">{currentTeam.name}</h2>
          <p className="mt-1 text-sm text-gray-500">
            Manage your team settings and member access
          </p>
        </div>
        {isAdmin && (
          <Button 
            onClick={() => setInviteDialogOpen(true)}
            className="flex items-center gap-2"
            size="sm"
          >
            <UserPlus className="h-4 w-4" />
            Invite Member
          </Button>
        )}
      </div>
      
      <Tabs defaultValue={defaultTab} className="w-full">
        <TabsList className="mb-8 bg-muted/50 p-1">
          <TabsTrigger value="general" className="flex items-center gap-2 relative">
            General
          </TabsTrigger>
          <TabsTrigger value="members" className="flex items-center gap-2 relative">
            Members
          </TabsTrigger>
          <TabsTrigger value="invitations" className="flex items-center gap-2 relative">
            Invitations
            {hasInvitations && (
              <span className="absolute -top-1 -right-1 flex h-4 w-4 items-center justify-center rounded-full bg-blue-100 text-xs font-medium text-blue-600">
                {invitations.length}
              </span>
            )}
          </TabsTrigger>
        </TabsList>
        
        <TabsContent value="general" className="space-y-8">
          <Card>
            <CardHeader>
              <CardTitle className="text-lg">Team Information</CardTitle>
              <CardDescription>
                Update your team's basic information and settings
              </CardDescription>
            </CardHeader>
            <CardContent className="space-y-6">
              <div className="grid grid-cols-4 items-start gap-6">
                <Label htmlFor="name" className="text-right text-sm font-medium pt-2">
                  Team Name
                </Label>
                <div className="col-span-3 space-y-1">
                  <div className="relative">
                    <Input
                      id="name"
                      value={teamName}
                      onChange={(e) => {
                        setTeamName(e.target.value);
                        setValidationError(null);
                      }}
                      className={`max-w-md pr-8 ${validationError ? 'border-red-500 focus-visible:ring-red-500' : ''}`}
                      disabled={!isAdmin}
                      placeholder="Enter team name"
                    />
                    {validationError && (
                      <XCircle className="absolute right-2 top-2 h-5 w-5 text-red-500" />
                    )}
                  </div>
                  {validationError ? (
                    <p className="text-xs text-red-500">{validationError}</p>
                  ) : !isAdmin ? (
                    <p className="text-xs text-muted-foreground">
                      Only team admins can modify team settings
                    </p>
                  ) : null}
                </div>
              </div>
              {currentTeam.isPersonal && currentTeam.userId === currentUserId && (
                <div className="grid grid-cols-4 items-start gap-6">
                  <div className="text-right">
                    <span className="text-sm font-medium text-amber-600">Personal Team</span>
                  </div>
                  <div className="col-span-3">
                    <div className="rounded-lg bg-amber-50 border border-amber-200/60 p-3">
                      <p className="text-sm text-amber-700">
                        This is your personal workspace. It's created automatically and can't be deleted.
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </CardContent>
            <CardFooter className="flex justify-between border-t bg-muted/10 px-6 py-4">
              <div className="text-sm text-muted-foreground">
                Last updated {new Date(currentTeam.updatedAt).toLocaleDateString()}
              </div>
              <Button 
                onClick={handleSaveSettings} 
                disabled={!isAdmin || isUpdatingTeam}
                size="sm"
              >
                {isUpdatingTeam ? "Saving..." : "Save Changes"}
              </Button>
            </CardFooter>
          </Card>

          {isAdmin && (
            <Card className="border-red-100">
              <CardHeader>
                <CardTitle className="text-lg text-red-600 flex items-center gap-2">
                  <AlertTriangle className="h-5 w-5" />
                  Danger Zone
                </CardTitle>
                <CardDescription className="text-red-600/80">
                  Irreversible actions that affect your team
                </CardDescription>
              </CardHeader>
              <CardContent>
                <div className="rounded-lg border border-red-200 bg-red-50/50">
                  <div className="px-4 py-3 border-b border-red-200/60">
                    <h3 className="text-sm font-medium text-red-900">Delete Team</h3>
                    <p className="mt-1 text-sm text-red-700">
                      Permanently remove this team and all of its data. This action cannot be undone.
                    </p>
                  </div>
                  <div className="px-4 py-3 bg-white/50">
                    <Button 
                      variant="destructive" 
                      onClick={() => setDeleteDialogOpen(true)}
                      disabled={currentTeam.isPersonal && currentTeam.userId === currentUserId}
                      size="sm"
                    >
                      Delete Team
                    </Button>
                  </div>
                </div>
                {currentTeam.isPersonal && currentTeam.userId === currentUserId && (
                  <div className="mt-4 rounded-lg bg-amber-50 border border-amber-200 p-3">
                    <p className="text-sm text-amber-700">
                      <strong>Note:</strong> Personal teams cannot be deleted. Every user must have at least one team.
                    </p>
                  </div>
                )}
              </CardContent>
            </Card>
          )}
        </TabsContent>
        
        <TabsContent value="members" className="space-y-6">
          <TeamMembersList />
        </TabsContent>
        
        <TabsContent value="invitations" className="space-y-6">
          <TeamInvitationsList />
        </TabsContent>
      </Tabs>
      
      <TeamInviteDialog 
        open={inviteDialogOpen} 
        onOpenChange={setInviteDialogOpen} 
      />
      
      <Dialog open={deleteDialogOpen} onOpenChange={setDeleteDialogOpen}>
        <DialogContent className="sm:max-w-md">
          <DialogHeader>
            <DialogTitle className="flex items-center gap-2">
              <AlertTriangle className="h-5 w-5 text-red-500" />
              Delete Team
            </DialogTitle>
            <DialogDescription className="pt-3">
              Are you sure you want to delete <span className="font-medium text-foreground">{currentTeam.name}</span>? 
              This action cannot be undone and will:
            </DialogDescription>
          </DialogHeader>
          <div className="rounded-lg border border-red-100 bg-red-50 p-4">
            <ul className="list-disc space-y-2 pl-4 text-sm text-red-900">
              <li>Permanently delete all team data</li>
              <li>Remove access for all team members</li>
              <li>Cancel all pending invitations</li>
              <li>Delete all team settings and configurations</li>
            </ul>
          </div>
          <DialogFooter className="gap-2 sm:gap-0">
            <Button
              variant="outline"
              onClick={() => setDeleteDialogOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant="destructive"
              onClick={handleDeleteTeam}
              disabled={isDeletingTeam}
            >
              {isDeletingTeam ? "Deleting..." : "Delete Team"}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
} 