import { useEffect, useRef, useState } from "react";


// Mock AI Workflow Automation Component
export function AIWorkflowAutomation() {
  const [currentStep, setCurrentStep] = useState(0);
  const steps = [
    { title: "Lead Scoring", description: "Prioritizes leads based on conversion probability" },
    { title: "Personalized Outreach", description: "Creates tailored communication for each lead" },
    { title: "AI Receptionist", description: "Handles incoming calls and schedules appointments" },
    { title: "Action Items", description: "Creates follow-up tasks based on conversation analysis and lead potential" },
    { title: "Follow-up Scheduling", description: "Sets optimal timing for follow-up communications" },
  ];
  
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  
  useEffect(() => {
    // Auto-advance through workflow steps
    intervalRef.current = setInterval(() => {
      setCurrentStep((prev) => (prev + 1) % steps.length);
    }, 3000);
    
    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, []);
  
  return (
    <div className="min-h-[500px] w-full bg-gray-50 flex flex-col p-4 sm:p-6 overflow-hidden">
      {/* Workflow Title */}
      <div className="mb-4 sm:mb-6 flex flex-col sm:flex-row items-start sm:items-center justify-between gap-2">
        <div className="flex items-center gap-2">
          <h3 className="text-base sm:text-lg font-semibold text-gray-800">AI Workflow Automation</h3>
          <div className="px-2 py-0.5 bg-blue-100 text-blue-800 text-xs font-medium rounded-full">
            New
          </div>
        </div>
        <div className="flex items-center gap-2 text-sm text-gray-500">
          <span className="font-medium">Step {currentStep + 1}</span>
          <span>of</span>
          <span className="font-medium">{steps.length}</span>
        </div>
      </div>
      
      {/* Progress Bar */}
      <div className="w-full h-1.5 sm:h-2 bg-gray-200 rounded-full mb-4 sm:mb-6">
        <div 
          className="h-full bg-primary rounded-full transition-all duration-500 ease-in-out"
          style={{ width: `${(currentStep + 1) / steps.length * 100}%` }}
        />
      </div>
      
      {/* Workflow Steps */}
      <div className="flex-1 relative">
        <div className="absolute left-0 top-0 w-full h-full">
          {steps.map((step, index) => (
            <div 
              key={index}
              className={`absolute top-0 left-0 w-full h-full transition-all duration-500 ease-in-out flex flex-col ${
                index === currentStep 
                  ? "opacity-100 translate-x-0" 
                  : index < currentStep 
                    ? "opacity-0 -translate-x-full" 
                    : "opacity-0 translate-x-full"
              }`}
            >
              {/* Step Number and Title */}
              <div className="flex items-center mb-3 sm:mb-4">
                <div className="h-6 w-6 sm:h-8 sm:w-8 rounded-full bg-primary text-white flex items-center justify-center mr-2 sm:mr-3 text-sm sm:text-base font-medium">
                  {index + 1}
                </div>
                <h4 className="text-base sm:text-lg font-medium text-gray-800">{step.title}</h4>
              </div>
              
              {/* Step Description */}
              <p className="text-sm sm:text-base text-gray-600 mb-4 sm:mb-6">{step.description}</p>
              
              {/* Mock UI Elements */}
              <div className="flex-1 bg-white rounded-lg border border-gray-200 p-3 sm:p-4 shadow-sm">
                {index === 0 && (
                  <div className="space-y-3">
                    <div className="h-6 sm:h-8 bg-gray-100 rounded w-3/4 animate-pulse"></div>
                    <div className="flex flex-col sm:flex-row gap-2">
                      <div className="h-10 bg-gray-100 rounded w-full sm:w-1/2 animate-pulse"></div>
                      <div className="h-10 bg-gray-100 rounded w-full sm:w-1/2 animate-pulse"></div>
                    </div>
                    <div className="h-20 sm:h-24 bg-gray-100 rounded w-full animate-pulse"></div>
                  </div>
                )}
                
                {index === 1 && (
                  <div className="space-y-3">
                    <div className="flex gap-2">
                      <div className="h-10 w-10 sm:h-12 sm:w-12 bg-gray-100 rounded-full animate-pulse"></div>
                      <div className="flex-1 space-y-2">
                        <div className="h-3 sm:h-4 bg-gray-100 rounded w-3/4 animate-pulse"></div>
                        <div className="h-3 sm:h-4 bg-gray-100 rounded w-1/2 animate-pulse"></div>
                      </div>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                      <div className="h-8 bg-gray-100 rounded animate-pulse"></div>
                      <div className="h-8 bg-gray-100 rounded animate-pulse"></div>
                      <div className="h-8 bg-gray-100 rounded animate-pulse"></div>
                      <div className="h-8 bg-gray-100 rounded animate-pulse"></div>
                    </div>
                  </div>
                )}
                
                {index === 2 && (
                  <div className="space-y-3">
                    <div className="flex justify-between">
                      <div className="h-3 sm:h-4 bg-gray-100 rounded w-1/4 animate-pulse"></div>
                      <div className="h-3 sm:h-4 bg-gray-100 rounded w-1/4 animate-pulse"></div>
                    </div>
                    <div className="h-28 sm:h-32 bg-gray-100 rounded-lg animate-pulse"></div>
                    <div className="grid grid-cols-3 gap-2">
                      <div className="h-5 sm:h-6 bg-green-100 rounded animate-pulse"></div>
                      <div className="h-5 sm:h-6 bg-yellow-100 rounded animate-pulse"></div>
                      <div className="h-5 sm:h-6 bg-red-100 rounded animate-pulse"></div>
                    </div>
                  </div>
                )}
                
                {index === 3 && (
                  <div className="space-y-3">
                    <div className="h-5 sm:h-6 bg-gray-100 rounded w-1/2 animate-pulse"></div>
                    <div className="h-20 sm:h-24 bg-gray-100 rounded animate-pulse"></div>
                    <div className="flex justify-end gap-2">
                      <div className="h-8 w-16 sm:w-20 bg-gray-100 rounded animate-pulse"></div>
                      <div className="h-8 w-16 sm:w-20 bg-primary/20 rounded animate-pulse"></div>
                    </div>
                  </div>
                )}
                
                {index === 4 && (
                  <div className="space-y-3">
                    <div className="grid grid-cols-4 sm:grid-cols-7 gap-1">
                      {[...Array(7)].map((_, i) => (
                        <div key={i} className={`h-8 bg-gray-100 rounded animate-pulse ${i >= 4 ? 'hidden sm:block' : ''}`}></div>
                      ))}
                    </div>
                    <div className="h-3 sm:h-4 bg-gray-100 rounded w-1/4 animate-pulse"></div>
                    <div className="space-y-2">
                      <div className="h-10 bg-blue-50 rounded animate-pulse"></div>
                      <div className="h-10 bg-gray-100 rounded animate-pulse"></div>
                      <div className="h-10 bg-gray-100 rounded animate-pulse"></div>
                    </div>
                  </div>
                )}
              </div>
              
              {/* Status Indicator */}
              <div className="mt-3 sm:mt-4 flex items-center">
                <div className="h-1.5 w-1.5 sm:h-2 sm:w-2 rounded-full bg-green-500 mr-2"></div>
                <span className="text-xs sm:text-sm text-gray-500">Processing automatically...</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}