import { axiosInstance } from '@/lib/api';
import { MetadataHistory, MetadataSuggestion, MetadataValue } from '@/types/metadata';

// Create a metadata API service
export const metadataApi = {
  /**
   * Get metadata for a lead
   */
  getLeadMetadata: async (leadId: number): Promise<Record<string, MetadataValue>> => {
    const response = await axiosInstance.get(`/metadata/leads/${leadId}/metadata`);
    return response.data.metadata || {};
  },

  /**
   * Update metadata for a lead
   */
  updateLeadMetadata: async (
    leadId: number,
    metadata: Record<string, MetadataValue>
  ): Promise<Record<string, MetadataValue>> => {
    const response = await axiosInstance.put(`/metadata/leads/${leadId}/metadata`, { metadata });
    return response.data.metadata;
  },

  /**
   * Delete a metadata field from a lead
   */
  deleteMetadataField: async (leadId: number, key: string): Promise<Record<string, MetadataValue>> => {
    const response = await axiosInstance.delete(`/metadata/leads/${leadId}/metadata/${key}`);
    return response.data.metadata;
  },

  /**
   * Get metadata suggestions for the current user
   */
  getMetadataSuggestions: async (): Promise<MetadataSuggestion[]> => {
    const response = await axiosInstance.get('/metadata/suggestions');
    return response.data.suggestions || [];
  },

  /**
   * Get metadata history for the current user
   */
  getMetadataHistory: async (): Promise<MetadataHistory[]> => {
    const response = await axiosInstance.get('/metadata/history');
    return response.data.history || [];
  },

  /**
   * Get popular metadata fields for the current user
   */
  getPopularMetadataFields: async (): Promise<Array<{ key: string; useCount: number; section?: string }>> => {
    const response = await axiosInstance.get('/metadata/popular');
    return response.data.popularFields || [];
  },

  /**
   * Delete a suggestion from the user's history
   */
  deleteSuggestion: async (key: string): Promise<void> => {
    await axiosInstance.delete(`/metadata/suggestions/${key}`);
  }
};

/**
 * Infer the type of a value
 */
export function inferValueType(value: any): 'text' | 'number' | 'boolean' {
  if (typeof value === 'boolean') return 'boolean';
  if (typeof value === 'number' || !isNaN(Number(value))) return 'number';
  return 'text';
}

/**
 * Create a metadata value object
 */
export function createMetadataValue(
  value: string | number | boolean,
  section?: string,
  order?: number
): MetadataValue {
  return {
    value,
    type: inferValueType(value),
    section,
    order
  };
} 