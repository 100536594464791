import { ArticleLayout } from "@/components/help/ArticleLayout";

export function AIReceptionistArticle() {
  return (
    <ArticleLayout>
      <h1 className="text-2xl font-bold mb-4 mt-0">AI Receptionist</h1>
      
      <h2 className="text-xl font-bold mt-8 mb-4">What is the AI Receptionist?</h2>
      <p className="mb-4">
        The LeadGPT AI Receptionist is a powerful automated assistant that handles incoming calls when you're unavailable. It uses advanced natural language processing to engage with leads, answer questions, and schedule appointments, ensuring no opportunity is missed.
      </p>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Key Benefits</h2>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>24/7 Availability:</strong> Never miss an important call, even outside business hours</li>
        <li className="mb-1"><strong>Lead Qualification:</strong> Automatically collect key information and qualify leads</li>
        <li className="mb-1"><strong>Appointment Scheduling:</strong> Allow leads to book meetings directly in your calendar</li>
        <li className="mb-1"><strong>Consistent Experience:</strong> Deliver a professional interaction every time</li>
        <li className="mb-1"><strong>Time Savings:</strong> Reduce time spent on initial call screening and routine inquiries</li>
        <li className="mb-1"><strong>Lead Creation:</strong> Automatically create new leads in your system with collected information</li>
        <li className="mb-1"><strong>Conversation Analysis:</strong> Get insights from transcribed and analyzed conversations</li>
      </ul>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Accessing the AI Receptionist</h2>
      <p className="mb-4">
        To access the AI Receptionist, click on the "Receptionist" option in the sidebar navigation. This will take you to the AI Receptionist dashboard, which provides an overview of your receptionist's activity and settings.
      </p>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Setting Up Your AI Receptionist</h2>
      <p className="mb-4">
        When you first access the AI Receptionist, you'll need to complete a setup wizard if you haven't already configured it. Follow these steps:
      </p>
      
      <h3 className="text-lg font-bold mt-6 mb-3">1. Assistant Template Creation</h3>
      <p className="mb-4">
        Select or create an assistant template that defines how your AI Receptionist interacts with callers:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1">Introduction and greeting style</li>
        <li className="mb-1">Questions to ask callers</li>
        <li className="mb-1">Information to collect</li>
        <li className="mb-1">Call handling logic</li>
      </ul>
      <p className="mb-4">
        You can choose from pre-built templates or create a custom template tailored to your specific needs.
      </p>
      
      <h3 className="text-lg font-bold mt-6 mb-3">2. Phone Number Selection</h3>
      <p className="mb-4">
        Select a phone number for your AI Receptionist:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Use an existing number:</strong> Select from phone numbers you've already purchased</li>
        <li className="mb-1"><strong>Purchase a new number:</strong> Buy a new local or toll-free number</li>
      </ul>
      <p className="mb-4 text-sm italic">
        Tip: For best results, use a local number that matches the area code of your target market, as this can increase answer rates by up to 35%.
      </p>
      
      <h3 className="text-lg font-bold mt-6 mb-3">3. Basic Configuration</h3>
      <ol className="list-decimal pl-6 mb-4">
        <li className="mb-1">Navigate to the AI Receptionist section in the main menu</li>
        <li className="mb-1">Click "Enable AI Receptionist" to activate the feature</li>
        <li className="mb-1">Set your business hours to define when the AI should automatically answer</li>
      </ol>
      
      <h3 className="text-lg font-bold mt-6 mb-3">4. Call Forwarding Rules</h3>
      <p className="mb-4">
        Set up call forwarding rules that determine when calls should be directed to the AI Receptionist:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Always:</strong> All calls go to the AI Receptionist first</li>
        <li className="mb-1"><strong>When Busy:</strong> Calls are forwarded when you're on another call</li>
        <li className="mb-1"><strong>When Unavailable:</strong> Calls are forwarded when you don't answer within a set number of rings</li>
        <li className="mb-1"><strong>Custom Schedule:</strong> Calls are forwarded during specific days or times</li>
      </ul>
      
      <h3 className="text-lg font-bold mt-6 mb-3">5. Personality and Voice</h3>
      <p className="mb-4">
        Customize how your AI Receptionist interacts with leads:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1">Select a voice type (male/female)</li>
        <li className="mb-1">Choose a communication style (friendly, professional, casual, formal)</li>
        <li className="mb-1">Set a name for your AI Receptionist</li>
        <li className="mb-1">Create a brief introduction the AI will use when answering</li>
      </ul>
      
      <h3 className="text-lg font-bold mt-6 mb-3">6. Business Information</h3>
      <p className="mb-4">
        Provide essential details about your business for the AI to use:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1">Company name and description</li>
        <li className="mb-1">Products or services offered</li>
        <li className="mb-1">Pricing information</li>
        <li className="mb-1">Frequently asked questions and answers</li>
        <li className="mb-1">Special offers or promotions</li>
      </ul>
      
      <h3 className="text-lg font-bold mt-6 mb-3">7. Lead Qualification Criteria</h3>
      <p className="mb-4">
        Define the questions your AI should ask to qualify leads:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1">Budget range</li>
        <li className="mb-1">Timeline for decision</li>
        <li className="mb-1">Specific needs or requirements</li>
        <li className="mb-1">How they heard about your business</li>
        <li className="mb-1">Custom qualification questions</li>
      </ul>
      
      <h2 className="text-xl font-bold mt-8 mb-4">AI Receptionist Dashboard</h2>
      <p className="mb-4">
        Once set up, the AI Receptionist dashboard provides a comprehensive view of your receptionist's activity and performance.
      </p>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Overview Tab</h3>
      <p className="mb-4">
        The Overview tab displays key metrics and status information:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Receptionist Status:</strong> Whether the receptionist is active or inactive</li>
        <li className="mb-1"><strong>Call Metrics:</strong> Total calls handled, average call duration, and conversion rate</li>
        <li className="mb-1"><strong>Recent Calls:</strong> List of recent calls with outcomes and actions taken</li>
        <li className="mb-1"><strong>Real-Time Status:</strong> Shows when a call is currently in progress</li>
      </ul>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Call Logs Tab</h3>
      <p className="mb-4">
        The Call Logs tab provides a detailed history of all calls handled by the AI Receptionist:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Date and Time:</strong> When the call occurred</li>
        <li className="mb-1"><strong>Caller Information:</strong> Phone number and caller ID</li>
        <li className="mb-1"><strong>Duration:</strong> How long the call lasted</li>
        <li className="mb-1"><strong>Outcome:</strong> Whether a lead was created, callback scheduled, etc.</li>
        <li className="mb-1"><strong>Transcript:</strong> Full transcript of the conversation</li>
        <li className="mb-1"><strong>Recording:</strong> Audio recording of the call (if enabled)</li>
      </ul>
      <p className="mb-4">
        You can filter call logs by date range, outcome, or search for specific callers.
      </p>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Settings Tab</h3>
      <p className="mb-4">
        The Settings tab allows you to configure your AI Receptionist:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>General Settings:</strong> Enable/disable the receptionist, adjust call forwarding, configure voicemail and notifications</li>
        <li className="mb-1"><strong>Assistant Templates:</strong> Manage templates, test with simulated calls, and select voice options</li>
        <li className="mb-1"><strong>Phone Numbers:</strong> View and manage numbers, purchase new ones, and configure number settings</li>
      </ul>
      
      <h2 className="text-xl font-bold mt-8 mb-4">How It Works</h2>
      <ol className="list-decimal pl-6 mb-4">
        <li className="mb-1">When a call comes in that you don't answer, the AI Receptionist will pick up</li>
        <li className="mb-1">It greets the caller and identifies itself as your assistant</li>
        <li className="mb-1">The AI engages in natural conversation to understand the caller's needs</li>
        <li className="mb-1">Based on the conversation, it can provide information, answer questions, or schedule appointments</li>
        <li className="mb-1">The entire conversation is recorded, transcribed, and added to the lead's profile</li>
        <li className="mb-1">You receive a notification with a summary of the call and any action items</li>
      </ol>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Customizing Assistant Templates</h2>
      <h3 className="text-lg font-bold mt-6 mb-3">Template Components</h3>
      <p className="mb-4">
        Assistant templates determine how your AI Receptionist interacts with callers. Template components include:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Introduction:</strong> How the AI introduces itself to callers</li>
        <li className="mb-1"><strong>Questions:</strong> What information the AI should collect from callers</li>
        <li className="mb-1"><strong>Responses:</strong> How the AI should respond to common questions</li>
        <li className="mb-1"><strong>Logic Flow:</strong> Decision trees that determine the conversation flow</li>
        <li className="mb-1"><strong>Closing:</strong> How the AI should end the conversation</li>
      </ul>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Creating a Custom Template</h3>
      <ol className="list-decimal pl-6 mb-4">
        <li className="mb-1">Go to the Settings tab and select "Assistant Templates"</li>
        <li className="mb-1">Click "Create New Template"</li>
        <li className="mb-1">Fill in the template details (name, description, introduction script, questions, response options, and logic flow)</li>
        <li className="mb-1">Save and activate your template</li>
      </ol>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Template Best Practices</h3>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Keep it conversational:</strong> Use natural language that sounds human</li>
        <li className="mb-1"><strong>Be concise:</strong> Keep questions and responses brief and to the point</li>
        <li className="mb-1"><strong>Collect essential information:</strong> Focus on gathering the most important details</li>
        <li className="mb-1"><strong>Provide clear options:</strong> Make it easy for callers to understand their choices</li>
        <li className="mb-1"><strong>Test thoroughly:</strong> Use the template testing feature to ensure it works as expected</li>
      </ul>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Advanced Features</h2>
      <h3 className="text-lg font-bold mt-6 mb-3">Custom Workflows</h3>
      <p className="mb-4">
        Create specialized conversation flows for different scenarios:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1">Define different scripts for various products or services</li>
        <li className="mb-1">Create specific handling procedures for different lead sources</li>
        <li className="mb-1">Set up emergency handling protocols</li>
      </ul>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Analytics and Reporting</h3>
      <p className="mb-4">
        Track the performance of your AI Receptionist:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1">Call handling statistics</li>
        <li className="mb-1">Lead qualification success rate</li>
        <li className="mb-1">Appointment booking conversion rate</li>
        <li className="mb-1">Common questions and conversation topics</li>
        <li className="mb-1">Caller satisfaction estimations</li>
        <li className="mb-1">Information completeness metrics</li>
      </ul>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Conversation Analysis</h3>
      <p className="mb-4">
        The system automatically analyzes conversations to identify:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>Common Questions:</strong> Frequently asked questions from callers</li>
        <li className="mb-1"><strong>Objections:</strong> Concerns or objections raised during calls</li>
        <li className="mb-1"><strong>Interest Signals:</strong> Indications of high interest from potential clients</li>
        <li className="mb-1"><strong>Improvement Opportunities:</strong> Areas where the AI could be more effective</li>
      </ul>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Best Practices</h2>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1">Regularly review AI conversations to refine responses</li>
        <li className="mb-1">Keep your business information up-to-date</li>
        <li className="mb-1">Use the AI Receptionist as a complement to, not replacement for, personal interactions</li>
        <li className="mb-1">Set clear expectations for when leads can expect to hear from you personally</li>
        <li className="mb-1">Test your AI Receptionist regularly to ensure it's functioning correctly</li>
        <li className="mb-1">Use local numbers that match your target market's area code</li>
        <li className="mb-1">Customize templates to match your specific business needs</li>
        <li className="mb-1">Review call logs and transcripts to identify improvement opportunities</li>
      </ul>
      
      <h2 className="text-xl font-bold mt-8 mb-4">Troubleshooting</h2>
      <h3 className="text-lg font-bold mt-6 mb-3">Common Issues and Solutions</h3>
      <ul className="list-disc pl-6 mb-4">
        <li className="mb-1"><strong>AI not answering calls:</strong> Check that the receptionist is enabled and call forwarding rules are set correctly</li>
        <li className="mb-1"><strong>Poor call quality:</strong> Ensure the caller has a good connection and consider adjusting the AI's speaking rate</li>
        <li className="mb-1"><strong>Incorrect information collection:</strong> Review and update your template questions and logic flow</li>
        <li className="mb-1"><strong>Missed lead opportunities:</strong> Adjust your template to better identify and capture potential leads</li>
        <li className="mb-1"><strong>Caller confusion:</strong> Simplify your scripts and make sure the AI clearly identifies itself as an assistant</li>
      </ul>
      
      <h3 className="text-lg font-bold mt-6 mb-3">Need More Help?</h3>
      <p className="mb-4">
        If you need assistance setting up your AI Receptionist, contact our support team or schedule a walkthrough session with one of our specialists.
      </p>
    </ArticleLayout>
  );
}