import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { PhoneInput } from '@/components/ui/phone-input';
import { toast } from '@/components/ui/use-toast';
import { useUpdateProfile } from '@/services/settings';
import { useAuthStore } from '@/store/authStore';
import { useState } from 'react';

interface ProfileFormData {
  name: string;
  email: string;
  phone: string;
  avatar?: File;
}

export function ProfileSettings() {
  const user = useAuthStore((state) => state.user);
  const setUser = useAuthStore((state) => state.setUser);
  const updateProfile = useUpdateProfile();
  const [formData, setFormData] = useState<ProfileFormData>({
    name: user?.name || '',
    email: user?.email || '',
    phone: user?.phone || '',
  });
  const [avatarPreview, setAvatarPreview] = useState<string | null>(user?.profilePhotoPath || null);
  const [isAvatarUploading, setIsAvatarUploading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      setIsAvatarUploading(formData.avatar !== undefined);
      const updatedUser = await updateProfile.mutateAsync({
        ...formData,
        phone: formData.phone || null, // Convert empty string to null
      });
      setUser(updatedUser);
      setFormData(prev => ({ ...prev, avatar: undefined })); // Clear the avatar file after upload
      
      // If we got back a profile photo path, use it
      if (updatedUser.profilePhotoPath) {
        setAvatarPreview(updatedUser.profilePhotoPath);
      }
      
      setIsAvatarUploading(false);
      toast({
        title: 'Profile updated',
        description: 'Your profile has been updated successfully.',
      });
    } catch (error) {
      setIsAvatarUploading(false);
      toast({
        title: 'Error',
        description: error instanceof Error ? error.message : 'Failed to update profile. Please try again.',
        variant: 'destructive',
      });
    }
  };

  const handleAvatarChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      // Create a preview URL for the selected image
      const previewUrl = URL.createObjectURL(file);
      setAvatarPreview(previewUrl);
      setFormData(prev => ({ ...prev, avatar: file }));
    }
  };

  const handlePhoneChange = (value: string) => {
    setFormData((prev) => ({ ...prev, phone: value }));
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Profile Settings</CardTitle>
        <CardDescription>Update your personal information</CardDescription>
      </CardHeader>
      <CardContent>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="flex items-center gap-6">
            <div className="relative">
              {avatarPreview ? (
                <img
                  src={avatarPreview}
                  alt="User avatar"
                  className="h-20 w-20 rounded-full object-cover"
                />
              ) : (
                <img
                  src={`https://ui-avatars.com/api/?background=c7d2fe&color=3730a3&name=${encodeURIComponent(formData.name)}`}
                  alt="User avatar"
                  className="h-20 w-20 rounded-full bg-gray-100"
                />
              )}
              <input
                type="file"
                id="avatar"
                accept="image/*"
                className="hidden"
                onChange={handleAvatarChange}
                disabled={isAvatarUploading || updateProfile.isPending}
              />
              <Button
                type="button"
                variant="outline"
                size="sm"
                className="absolute -bottom-2 -right-2"
                onClick={() => document.getElementById('avatar')?.click()}
                disabled={isAvatarUploading || updateProfile.isPending}
              >
                {isAvatarUploading ? 'Uploading...' : 'Change'}
              </Button>
            </div>
          </div>

          <div className="grid gap-4">
            <div className="grid gap-2">
              <Label htmlFor="name">Name</Label>
              <Input
                id="name"
                value={formData.name}
                onChange={e => setFormData(prev => ({ ...prev, name: e.target.value }))}
                placeholder="Your name"
                required
                disabled={updateProfile.isPending}
              />
            </div>

            <div className="grid gap-2">
              <Label htmlFor="email">Email</Label>
              <Input
                id="email"
                type="email"
                value={formData.email}
                onChange={e => setFormData(prev => ({ ...prev, email: e.target.value }))}
                placeholder="Your email"
                required
                disabled={updateProfile.isPending}
              />
            </div>

            <PhoneInput
              id="phone"
              label="Phone Number"
              value={formData.phone}
              onChange={handlePhoneChange}
              placeholder="+1 (555) 000-0000"
              disabled={updateProfile.isPending}
            />
          </div>

          <Button type="submit" disabled={updateProfile.isPending || isAvatarUploading}>
            {updateProfile.isPending || isAvatarUploading ? 'Saving...' : 'Save Changes'}
          </Button>
        </form>
      </CardContent>
    </Card>
  );
} 