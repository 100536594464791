import { useAuthStore } from '@/store/authStore';
import { createRootRoute, Outlet, redirect } from '@tanstack/react-router';

export const Route = createRootRoute({
  beforeLoad: ({ location }) => {
    const token = useAuthStore.getState().token;
    const isAuthRoute = location.pathname === '/auth';
    const isLandingRoute = location.pathname === '/landing';
    const isRootRoute = location.pathname === '/';
    const isResetPasswordRoute = location.pathname === '/reset-password';
    const isInvitationRoute = location.pathname.startsWith('/invitation/');
    const isPrivacyRoute = location.pathname === '/privacy';
    const isTermsRoute = location.pathname === '/terms';
    const isHipaaRoute = location.pathname === '/hipaa';
    const isSecurityRoute = location.pathname === '/security';
    const isBlogRoute = location.pathname === '/blog';
    const isPublicRoute = isAuthRoute || isLandingRoute || isRootRoute || isResetPasswordRoute || isInvitationRoute || isPrivacyRoute || isTermsRoute || isHipaaRoute || isSecurityRoute || isBlogRoute;

    // Allow access to public pages if not authenticated
    if (!token && isPublicRoute) {
      return;
    }

    // Redirect to landing page if not authenticated and trying to access protected route
    if (!token && !isPublicRoute) {
      throw redirect({
        to: '/',
        search: {
          redirect: location.pathname === '/' ? '/dashboard' : location.pathname,
        },
      });
    }

    // Redirect to dashboard if trying to access auth page while authenticated
    if (token && isAuthRoute) {
      throw redirect({
        to: '/dashboard',
      });
    }

    // Redirect to dashboard if trying to access landing page while authenticated
    if (token && (isLandingRoute || isRootRoute)) {
      throw redirect({
        to: '/dashboard',
      });
    }
  },
  component: () => <Outlet />,
});