import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

// Define the onboarding steps
export enum OnboardingStep {
  WELCOME = 'welcome',
  DATE_RANGE = 'date_range',
  TIMEFRAME = 'timeframe',
  METRICS_FILTER = 'metrics_filter',
  CHART_TABS = 'chart_tabs',
  TEAM_PERSONAL = 'team_personal',
  LEADERBOARD = 'leaderboard',
  COMPLETION = 'completion',
}

// Define the onboarding context type
interface OnboardingContextType {
  isOnboarding: boolean;
  currentStep: OnboardingStep;
  completedSteps: Set<OnboardingStep>;
  startOnboarding: () => void;
  endOnboarding: () => void;
  nextStep: () => void;
  prevStep: () => void;
  goToStep: (step: OnboardingStep) => void;
  skipOnboarding: () => void;
  resetOnboarding: () => void;
  switchToTeamView: () => void;
}

// Create the context with a default value
const OnboardingContext = createContext<OnboardingContextType | undefined>(undefined);

// Define the order of steps
const STEP_ORDER: OnboardingStep[] = [
  OnboardingStep.WELCOME,
  OnboardingStep.DATE_RANGE,
  OnboardingStep.TIMEFRAME,
  OnboardingStep.METRICS_FILTER,
  OnboardingStep.CHART_TABS,
  OnboardingStep.TEAM_PERSONAL,
  OnboardingStep.LEADERBOARD,
  OnboardingStep.COMPLETION,
];

// Local storage keys
const ONBOARDING_COMPLETED_KEY = 'metrics_onboarding_completed';
const ONBOARDING_STEP_KEY = 'metrics_onboarding_step';
const ONBOARDING_COMPLETED_STEPS_KEY = 'metrics_onboarding_completed_steps';

interface OnboardingProviderProps {
  children: ReactNode;
}

export function OnboardingProvider({ children }: OnboardingProviderProps) {
  // State for tracking onboarding status
  const [isOnboarding, setIsOnboarding] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<OnboardingStep>(OnboardingStep.WELCOME);
  const [completedSteps, setCompletedSteps] = useState<Set<OnboardingStep>>(new Set());

  // Load onboarding state from localStorage on mount
  useEffect(() => {
    const onboardingCompleted = localStorage.getItem(ONBOARDING_COMPLETED_KEY) === 'true';
    const savedStep = localStorage.getItem(ONBOARDING_STEP_KEY) as OnboardingStep;
    const savedCompletedSteps = localStorage.getItem(ONBOARDING_COMPLETED_STEPS_KEY);

    // If onboarding was never completed, check if there's a saved step
    if (!onboardingCompleted && savedStep) {
      setCurrentStep(savedStep);
      
      // If there are saved completed steps, restore them
      if (savedCompletedSteps) {
        try {
          const parsedSteps = JSON.parse(savedCompletedSteps);
          setCompletedSteps(new Set(parsedSteps));
        } catch (e) {
          console.error('Failed to parse completed steps from localStorage', e);
        }
      }
      
      // Auto-start onboarding if there was a saved step
      setIsOnboarding(true);
    }
  }, []);

  // Save current step and completed steps to localStorage whenever they change
  useEffect(() => {
    if (isOnboarding) {
      localStorage.setItem(ONBOARDING_STEP_KEY, currentStep);
      localStorage.setItem(
        ONBOARDING_COMPLETED_STEPS_KEY, 
        JSON.stringify([...completedSteps])
      );
    }
  }, [isOnboarding, currentStep, completedSteps]);

  // Start the onboarding process
  const startOnboarding = () => {
    setIsOnboarding(true);
    setCurrentStep(OnboardingStep.WELCOME);
    setCompletedSteps(new Set());
  };

  // End the onboarding process
  const endOnboarding = () => {
    setIsOnboarding(false);
    localStorage.setItem(ONBOARDING_COMPLETED_KEY, 'true');
  };

  // Move to the next step
  const nextStep = () => {
    const currentIndex = STEP_ORDER.indexOf(currentStep);
    
    // Mark current step as completed
    const newCompletedSteps = new Set(completedSteps);
    newCompletedSteps.add(currentStep);
    setCompletedSteps(newCompletedSteps);
    
    // If this is the last step, end onboarding
    if (currentIndex === STEP_ORDER.length - 1) {
      endOnboarding();
      return;
    }
    
    // Otherwise, move to the next step
    const nextStepValue = STEP_ORDER[currentIndex + 1];
    setCurrentStep(nextStepValue);
    
    // If the next step is the leaderboard, switch to team view
    if (nextStepValue === OnboardingStep.LEADERBOARD) {
      switchToTeamView();
    }
  };

  // Move to the previous step
  const prevStep = () => {
    const currentIndex = STEP_ORDER.indexOf(currentStep);
    if (currentIndex > 0) {
      setCurrentStep(STEP_ORDER[currentIndex - 1]);
    }
  };

  // Go to a specific step
  const goToStep = (step: OnboardingStep) => {
    setCurrentStep(step);
    
    // If going to the leaderboard step, switch to team view
    if (step === OnboardingStep.LEADERBOARD) {
      switchToTeamView();
    }
  };

  // Switch to team view for leaderboard
  const switchToTeamView = () => {
    const teamTabTrigger = document.querySelector('.team-personal-tabs [value="team"]') as HTMLElement;
    if (teamTabTrigger) {
      teamTabTrigger.click();
    }
  };

  // Skip the onboarding process
  const skipOnboarding = () => {
    endOnboarding();
  };

  // Reset the onboarding process
  const resetOnboarding = () => {
    localStorage.removeItem(ONBOARDING_COMPLETED_KEY);
    localStorage.removeItem(ONBOARDING_STEP_KEY);
    localStorage.removeItem(ONBOARDING_COMPLETED_STEPS_KEY);
    setCurrentStep(OnboardingStep.WELCOME);
    setCompletedSteps(new Set());
    setIsOnboarding(true);
  };

  // Create the context value
  const contextValue: OnboardingContextType = {
    isOnboarding,
    currentStep,
    completedSteps,
    startOnboarding,
    endOnboarding,
    nextStep,
    prevStep,
    goToStep,
    skipOnboarding,
    resetOnboarding,
    switchToTeamView,
  };

  return (
    <OnboardingContext.Provider value={contextValue}>
      {children}
    </OnboardingContext.Provider>
  );
}

// Custom hook for using the onboarding context
export function useOnboarding() {
  const context = useContext(OnboardingContext);
  if (context === undefined) {
    throw new Error('useOnboarding must be used within an OnboardingProvider');
  }
  return context;
} 