import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { useWebSocket } from '@/contexts/WebSocketContext';
import { ConnectionStatus, SocketEvents } from '@/services/websocket.service';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import { formatDistanceToNow } from 'date-fns';
import { AlertTriangle, Bell, MessageSquare, Phone, User } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';
import { create } from 'zustand';

// Notification types
interface Notification {
  id: string;
  type: 'call' | 'message' | 'user' | 'lead';
  title: string;
  description: string;
  timestamp: string;
  read: boolean;
  metadata?: {
    leadId?: number;
    conversationId?: number;
    userId?: number;
    callId?: number;
  };
}

// Store for notification state
interface NotificationStore {
  unreadCount: number;
  setUnreadCount: (count: number) => void;
  incrementUnreadCount: () => void;
  decrementUnreadCount: () => void;
  resetUnreadCount: () => void;
}

export const useNotificationStore = create<NotificationStore>((set) => ({
  unreadCount: 0,
  setUnreadCount: (count) => set({ unreadCount: count }),
  incrementUnreadCount: () => set((state) => ({ unreadCount: state.unreadCount + 1 })),
  decrementUnreadCount: () => set((state) => ({ unreadCount: Math.max(0, state.unreadCount - 1) })),
  resetUnreadCount: () => set({ unreadCount: 0 }),
}));

export function WebSocketNotifications() {
  const { on, connectionStatus, connectionError } = useWebSocket();
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const navigate = useNavigate();
  const [hasUnread, setHasUnread] = useState(false);
  const isSubscribedRef = useRef<boolean>(false);
  const unsubscribeFunctionsRef = useRef<(() => void)[]>([]);
  const queryClient = useQueryClient();
  
  // Use the notification store
  const { unreadCount, setUnreadCount, incrementUnreadCount, resetUnreadCount } = useNotificationStore();

  // Check connection status and set up subscriptions
  useEffect(() => {
    console.log('[WebSocketNotifications] Connection status:', connectionStatus);
    
    // If we're already subscribed, don't resubscribe
    if (isSubscribedRef.current) {
      return;
    }
    
    // If we're not connected, don't try to subscribe
    if (connectionStatus === ConnectionStatus.ERROR || connectionStatus === ConnectionStatus.DISCONNECTED) {
      console.log('[WebSocketNotifications] Not connected, not setting up event subscriptions');
      
      // If there's a connection error, add it as a notification
      if (connectionError) {
        const notification: Notification = {
          id: `connection-error-${Date.now()}`,
          type: 'user',
          title: 'Connection Error',
          description: connectionError,
          timestamp: new Date().toISOString(),
          read: false
        };
        
        setNotifications([notification]);
      }
      
      return;
    }
    
    // If we're connected, set up subscriptions
    if (connectionStatus === ConnectionStatus.CONNECTED) {
      console.log('[WebSocketNotifications] Connected, setting up event subscriptions');
      
      // Clean up any existing subscriptions first
      cleanupSubscriptions();
      
      // Set up new subscriptions
      const unsubscribeFunctions = setupEventSubscriptions();
      unsubscribeFunctionsRef.current = unsubscribeFunctions;
      isSubscribedRef.current = true;
    }
    
    // Cleanup function
    return () => {
      cleanupSubscriptions();
    };
  }, [connectionStatus, connectionError]);

  // Cleanup subscriptions
  const cleanupSubscriptions = () => {
    if (unsubscribeFunctionsRef.current.length > 0) {
      console.log('[WebSocketNotifications] Cleaning up existing subscriptions');
      unsubscribeFunctionsRef.current.forEach(unsubscribe => unsubscribe());
      unsubscribeFunctionsRef.current = [];
    }
    isSubscribedRef.current = false;
  };

  // Setup all event subscriptions
  const setupEventSubscriptions = () => {
    console.log('[WebSocketNotifications] Setting up event subscriptions');
    const unsubscribeFunctions: (() => void)[] = [];
    
    // Handle incoming call notifications
    const unsubscribeCall = on<{ 
      from: string; 
      to: string; 
      timestamp: string;
      leadId?: number;
      leadName?: string;
      callSid: string;
    }>(
      SocketEvents.INBOUND_CALL,
      (data) => {
        let description = `Call from ${data.from}`;
        
        // Add lead information if available
        if (data.leadName) {
          description = `Call from ${data.leadName} (${data.from})`;
        }
        
        const notification: Notification = {
          id: `call-${Date.now()}`,
          type: 'call',
          title: 'Incoming Call',
          description,
          timestamp: data.timestamp,
          read: false,
          metadata: {
            leadId: data.leadId
          }
        };
        
        addNotification(notification);
        
        // Twilio will handle the incoming call sound
      }
    );
    unsubscribeFunctions.push(unsubscribeCall);
    
    // Handle new message notifications
    const unsubscribeMessage = on<{ 
      from: string; 
      body: string; 
      timestamp: string; 
      conversationId: number;
      isNewConversation?: boolean;
    }>(
      SocketEvents.NEW_MESSAGE,
      (data) => {
        const notification: Notification = {
          id: `message-${Date.now()}`,
          type: 'message',
          title: data.isNewConversation ? 'New Conversation' : 'New Message',
          description: `${data.from}: ${data.body.substring(0, 30)}${data.body.length > 30 ? '...' : ''}`,
          timestamp: data.timestamp,
          read: false,
          metadata: {
            conversationId: data.conversationId
          }
        };
        
        addNotification(notification);
        
        // Play sound for new message
        playNotificationSound('message');
      }
    );
    unsubscribeFunctions.push(unsubscribeMessage);
    
    // Handle user online notifications
    const unsubscribeUserOnline = on<{ userId: number; name: string; timestamp: string }>(
      SocketEvents.USER_ONLINE,
      (data) => {
        const notification: Notification = {
          id: `user-${data.userId}-${Date.now()}`,
          type: 'user',
          title: 'User Online',
          description: `${data.name} is now online`,
          timestamp: data.timestamp,
          read: false,
          metadata: {
            userId: data.userId
          }
        };
        
        addNotification(notification);
      }
    );
    unsubscribeFunctions.push(unsubscribeUserOnline);
    
    // Handle lead viewed notifications
    const unsubscribeLeadViewed = on<{ userId: number; userName: string; leadId: number; timestamp: string }>(
      SocketEvents.LEAD_VIEWED,
      (data) => {
        const notification: Notification = {
          id: `lead-${data.leadId}-${Date.now()}`,
          type: 'lead',
          title: 'Lead Viewed',
          description: `${data.userName} is viewing lead #${data.leadId}`,
          timestamp: data.timestamp,
          read: false,
          metadata: {
            leadId: data.leadId,
            userId: data.userId
          }
        };
        
        addNotification(notification);
      }
    );
    unsubscribeFunctions.push(unsubscribeLeadViewed);
    
    // Handle call status update notifications
    const unsubscribeCallStatus = on<{ 
      callId: number; 
      callSid: string; 
      status: string; 
      timestamp: string;
      duration?: number;
      recordingUrl?: string;
    }>(
      SocketEvents.CALL_STATUS_UPDATE,
      (data) => {
        // Only notify for important status changes
        if (['completed', 'failed', 'busy', 'no-answer', 'canceled'].includes(data.status)) {
          const statusText = {
            'completed': 'ended',
            'failed': 'failed',
            'busy': 'was busy',
            'no-answer': 'was not answered',
            'canceled': 'was canceled'
          }[data.status] || data.status;
          
          let description = `Call ${statusText}`;
          if (data.duration && data.status === 'completed') {
            const minutes = Math.floor(data.duration / 60);
            const seconds = data.duration % 60;
            description += ` (${minutes}:${seconds.toString().padStart(2, '0')})`;
          }
          
          const notification: Notification = {
            id: `call-status-${data.callId}-${Date.now()}`,
            type: 'call',
            title: 'Call Status Update',
            description,
            timestamp: data.timestamp,
            read: false,
            metadata: {
              callId: data.callId
            }
          };
          
          addNotification(notification);
          
          // Twilio will handle call ended sounds
        }
      }
    );
    unsubscribeFunctions.push(unsubscribeCallStatus);
    
    // Subscribe to team invitation updates
    const unsubscribeTeamInvitations = on<{
      invitationId: number;
      teamId: number;
      teamName: string;
      action: 'created' | 'accepted' | 'declined' | 'canceled';
    }>(SocketEvents.TEAM_INVITATION_UPDATE, (data) => {
      console.log('[WebSocketNotifications] Team invitation update:', data);
      
      // Invalidate team invitations query to refresh the data
      queryClient.invalidateQueries({ queryKey: ['teams', 'invitations'] });
    });
    unsubscribeFunctions.push(unsubscribeTeamInvitations);

    // Subscribe to call history updates
    const unsubscribeCallHistory = on<{
      leadId: number;
      callId: number;
      action: 'created' | 'updated' | 'deleted';
    }>(SocketEvents.CALL_HISTORY_UPDATE, (data) => {
      console.log('[WebSocketNotifications] Call history update:', data);
      
      // Invalidate call history queries
      if (data.leadId) {
        queryClient.invalidateQueries({ queryKey: ['calls', data.leadId] });
      }
      
      // Also invalidate the specific lead data
      queryClient.invalidateQueries({ queryKey: ['leads', data.leadId] });
    });
    unsubscribeFunctions.push(unsubscribeCallHistory);

    // Subscribe to lead updates
    const unsubscribeLeadUpdates = on<{
      leadId: number;
      action: 'created' | 'updated' | 'deleted';
    }>(SocketEvents.LEAD_UPDATE, (data) => {
      console.log('[WebSocketNotifications] Lead update:', data);
      
      // Invalidate specific lead query
      queryClient.invalidateQueries({ queryKey: ['leads', data.leadId] });
      
      // Also invalidate the leads list
      queryClient.invalidateQueries({ queryKey: ['leads'] });
    });
    unsubscribeFunctions.push(unsubscribeLeadUpdates);

    // Subscribe to conversation updates
    const unsubscribeConversationUpdates = on<{
      conversationId: number;
      leadId?: number;
      action: 'created' | 'updated' | 'new-message';
    }>(SocketEvents.CONVERSATION_UPDATE, (data) => {
      console.log('[WebSocketNotifications] Conversation update:', data);
      
      // Invalidate conversation queries
      queryClient.invalidateQueries({ queryKey: ['conversations', data.conversationId] });
      
      // If we have a lead ID, also invalidate lead-specific conversation queries
      if (data.leadId) {
        queryClient.invalidateQueries({ queryKey: ['conversations', 'lead', data.leadId] });
      }
    });
    unsubscribeFunctions.push(unsubscribeConversationUpdates);

    console.log('[WebSocketNotifications] Event subscriptions set up');
    return unsubscribeFunctions;
  };

  // Play notification sounds - keep only for message notifications
  const playNotificationSound = (type: 'call' | 'message' | 'callEnded') => {
    // Only play sounds for messages, as Twilio handles call sounds
    if (type !== 'message') return;
    
    try {
      const audio = new Audio();
      audio.src = '/sounds/message.mp3';
      
      audio.play().catch(error => {
        console.error('Error playing notification sound:', error);
      });
    } catch (error) {
      console.error('Error creating audio element:', error);
    }
  };

  // Add a new notification
  const addNotification = (notification: Notification) => {
    setNotifications((prev) => {
      // Keep only the 10 most recent notifications
      const updated = [notification, ...prev].slice(0, 10);
      return updated;
    });
    
    // Increment unread count in the store
    incrementUnreadCount();
  };

  // Mark all notifications as read
  const markAllAsRead = () => {
    setNotifications((prev) =>
      prev.map((notification) => ({ ...notification, read: true }))
    );
    
    // Reset unread count in the store
    resetUnreadCount();
  };

  // Handle notification click
  const handleNotificationClick = (notification: Notification) => {
    // Mark this notification as read
    setNotifications(prev => 
      prev.map(n => n.id === notification.id ? { ...n, read: true } : n)
    );
    
    // Update unread count if this was unread
    if (!notification.read) {
      useNotificationStore.getState().decrementUnreadCount();
    }
    
    // Navigate based on notification type and metadata
    if (notification.type === 'lead' || notification.type === 'call' || notification.type === 'message') {
      // For now, navigate to the leads page since we don't have specific routes
      navigate({ to: '/leads' });
      
      // In the future, when you have specific routes, you can use:
      // navigate({ to: '/leads/$leadId', params: { leadId: notification.metadata.leadId.toString() } });
    }
  };

  // Get icon for notification type
  const getNotificationIcon = (type: Notification['type']) => {
    switch (type) {
      case 'call':
        return <Phone className="h-4 w-4 text-blue-500" />;
      case 'message':
        return <MessageSquare className="h-4 w-4 text-green-500" />;
      case 'user':
        return <User className="h-4 w-4 text-purple-500" />;
      case 'lead':
        return <User className="h-4 w-4 text-orange-500" />;
      default:
        return <Bell className="h-4 w-4 text-gray-500" />;
    }
  };

  // Handle view all button click
  const handleViewAll = () => {
    // Mark all as read
    setNotifications(prev => 
      prev.map(n => ({ ...n, read: true }))
    );
    
    setUnreadCount(0);
    
    // Navigate to dashboard
    navigate({ to: '/dashboard' });
  };

  // Render connection error if not connected
  if (connectionStatus === ConnectionStatus.ERROR) {
    return (
      <div className="w-full">
        <Card className="border-2 border-yellow-500">
          <CardHeader className="bg-yellow-50 pb-2">
            <CardTitle className="text-sm font-medium flex items-center">
              <AlertTriangle className="h-4 w-4 text-yellow-500 mr-2" />
              Connection Error
            </CardTitle>
          </CardHeader>
          <CardContent className="pt-3">
            <p className="text-sm text-gray-700">
              {connectionError || 'Unable to connect to notification service. Please log in to receive real-time updates.'}
            </p>
          </CardContent>
        </Card>
      </div>
    );
  }

  return (
    <>
      {/* Notification indicator - only show when not in popover */}
      {unreadCount > 0 && false && (
        <button
          onClick={handleViewAll}
          className="fixed bottom-4 left-4 z-40 p-3 bg-primary text-primary-foreground rounded-full shadow-lg hover:bg-primary/90 transition-all"
        >
          <Bell className="h-5 w-5" />
          <span className="absolute -top-1 -right-1 h-4 w-4 bg-red-500 rounded-full" />
        </button>
      )}
      
      <Card className="border-0 shadow-none">
        <CardHeader className="pb-2">
          <div className="flex items-center justify-between">
            <CardTitle className="text-sm font-medium">Recent Notifications</CardTitle>
            <div className="flex items-center">
              <Bell className="h-4 w-4 text-gray-500" />
              {unreadCount > 0 && (
                <Badge variant="destructive" className="ml-2">
                  {unreadCount}
                </Badge>
              )}
            </div>
          </div>
          <div className="flex justify-between items-center">
            {unreadCount > 0 && (
              <Button
                onClick={markAllAsRead}
                variant="ghost"
                size="sm"
                className="text-xs text-blue-500 hover:text-blue-700 p-0 h-auto"
              >
                Mark all as read
              </Button>
            )}
            <Button
              onClick={() => navigate({ to: '/dashboard' })}
              variant="ghost"
              size="sm"
              className="text-xs text-blue-500 hover:text-blue-700 p-0 h-auto ml-auto"
            >
              View all
            </Button>
          </div>
        </CardHeader>
        <CardContent>
          <div className="space-y-3 max-h-[300px] overflow-y-auto">
            {notifications.length === 0 ? (
              <div className="text-center text-sm text-gray-500 py-4">
                No notifications yet
              </div>
            ) : (
              notifications.map((notification) => (
                <div
                  key={notification.id}
                  className={`flex items-start space-x-3 p-2 rounded-md ${
                    notification.read ? 'bg-white' : 'bg-blue-50'
                  } cursor-pointer hover:bg-gray-50 transition-colors`}
                  onClick={() => handleNotificationClick(notification)}
                >
                  <div className="mt-0.5">
                    {getNotificationIcon(notification.type)}
                  </div>
                  <div className="flex-1 space-y-1">
                    <div className="flex items-center justify-between">
                      <p className="text-sm font-medium">{notification.title}</p>
                      <span className="text-xs text-gray-500">
                        {formatDistanceToNow(new Date(notification.timestamp), {
                          addSuffix: true,
                        })}
                      </span>
                    </div>
                    <p className="text-xs text-gray-600">{notification.description}</p>
                  </div>
                </div>
              ))
            )}
          </div>
        </CardContent>
      </Card>
    </>
  );
} 