import { DateRangePicker } from '@/components/ui/date-range-picker';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { endOfMonth, endOfWeek, format, startOfMonth, startOfWeek } from 'date-fns';
import { useState } from 'react';
import { DateRange } from 'react-day-picker';

export type DateRangeValue = 'today' | 'week' | 'month' | 'custom';

interface DateRangeSelectorProps {
  mode: 'export' | 'dashboard';
  initialValue?: DateRangeValue;
  onChange?: (value: DateRangeValue) => void;
  onCustomDateChange?: (startDate: Date, endDate: Date) => void;
  initialCustomRange?: DateRange;
  onDateRangeChange?: (range: DateRange | undefined) => void;
}

export function DateRangeSelector({
  mode = 'dashboard',
  initialValue = 'today',
  onChange,
  onCustomDateChange,
  initialCustomRange,
  onDateRangeChange,
}: DateRangeSelectorProps) {
  const [selectedValue, setSelectedValue] = useState<DateRangeValue>(initialValue);
  const [customRange, setCustomRange] = useState<DateRange | undefined>(initialCustomRange);
  const today = new Date();

  const predefinedRanges = {
    today: { from: today, to: today },
    week: { from: startOfWeek(today), to: endOfWeek(today) },
    month: { from: startOfMonth(today), to: endOfMonth(today) },
  };

  const handleValueChange = (newValue: DateRangeValue) => {
    setSelectedValue(newValue);
    if (onChange) onChange(newValue);

    if (newValue !== 'custom') {
      const range = predefinedRanges[newValue];
      if (onDateRangeChange) onDateRangeChange(range);
      if (onCustomDateChange && range.from && range.to) {
        onCustomDateChange(range.from, range.to);
      }
      if (customRange) setCustomRange(undefined); // Clear custom range when switching
    } else {
      if (onDateRangeChange) onDateRangeChange(customRange);
    }
  };

  const handleCustomRangeChange = (range: DateRange | undefined) => {
    setCustomRange(range);
    if (range?.from && range?.to) {
      if (onDateRangeChange) onDateRangeChange(range);
      if (onCustomDateChange) onCustomDateChange(range.from, range.to);
    }
  };

  const getCustomDisplay = () => {
    if (customRange?.from && customRange?.to) {
      return `${format(customRange.from, 'MMM d, yyyy')} - ${format(customRange.to, 'MMM d, yyyy')}`;
    }
    return 'Custom Range';
  };

  return (
    <div className={`flex flex-col ${mode === 'dashboard' ? 'sm:flex-row' : ''} items-start ${mode === 'dashboard' ? 'sm:items-center' : ''} gap-2`}>
      <Select
        value={selectedValue}
        onValueChange={handleValueChange}
      >
        <SelectTrigger className="w-[180px]">
          <SelectValue placeholder="Select date range" />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="today">Today</SelectItem>
          <SelectItem value="week">This Week</SelectItem>
          <SelectItem value="month">This Month</SelectItem>
          <SelectItem value="custom">Custom Range</SelectItem>
        </SelectContent>
      </Select>

      {selectedValue === 'custom' && (
        <DateRangePicker
          value={customRange}
          onChange={handleCustomRangeChange}
          align="start"
          placeholder="Select custom date range"
          numberOfMonths={2}
          className="w-full sm:w-auto overflow-hidden"
        />
      )}
    </div>
  );
}